import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function AlertsDialog(props) {
  const {
    alerts, isOpen, closeAlertDialog
  } = props;

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={closeAlertDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Проблемы, требующие решения
      </DialogTitle>
      <DialogContent sx={{
        paddingBottom: 0, display: 'flex', gap: 1, flexDirection: 'column'
      }}
      >
        {
          alerts.map(({ title, description, solution }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Alert key={`${title}_${index}`} severity="warning">
              <AlertTitle>{title}</AlertTitle>
              {description}
              <ButtonGroup
                color="inherit"
                variant="text"
                aria-label="Basic button group"
                sx={{ display: 'block', marginTop: 1 }}
              >
                {
                  solution.map((s, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Button onClick={() => console.log('>>>> ', s.type)} key={i} color="inherit" size="small">{s.label}</Button>
                  ))
                }
              </ButtonGroup>
            </Alert>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAlertDialog}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}

AlertsDialog.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    solution: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    solutionType: PropTypes.arrayOf(PropTypes.string.isRequired),
  })),
  isOpen: PropTypes.bool,
  closeAlertDialog: PropTypes.func.isRequired,
};

AlertsDialog.defaultProps = {
  alerts: [],
  isOpen: false,
};
