import React from 'react';
import {
  IconButton, Toolbar, Tooltip, Typography
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Тулбар над таблицей, отображает кнопки (фильтр) и кол-во выбранных элементов таблицы
function EnhancedTableToolbar(props) {
  const {
    selected, openDisableMenu, toogleFilters, isFiltersOpen
  } = props;
  const numSelected = selected.length;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity
          ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Выбрано:
          {' '}
          {numSelected}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          {isFiltersOpen ? 'Фильтры' : ''}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Не показывать">
          <IconButton
            aria-haspopup="true"
            onClick={openDisableMenu}
          >
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Фильтры">
          <IconButton onClick={toogleFilters}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  openDisableMenu: PropTypes.func.isRequired,
  toogleFilters: PropTypes.func.isRequired,
  isFiltersOpen: PropTypes.bool.isRequired,
};

export default EnhancedTableToolbar;
