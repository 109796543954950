import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useGetPaymentResourceIdQuery, usePatchPaymentResourceMutation } from "../redux/paymentResource.api";
// eslint-disable-next-line boundaries/element-types

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function PaymentResourceEditBll({ children }) {
  const params = useParams();
  const [data, setData] = useState();

  const { data: dataPaymentResourceId } = useGetPaymentResourceIdQuery({
    id: params.tarifPlanId
  });

  useEffect(() => {
    setData({
      dataPaymentResourceId
    });
  }, [dataPaymentResourceId]);

  const [putPaymentResource] = usePatchPaymentResourceMutation();

  const putPaymentResourceParams = useCallback(
    ({ body, id }) => putPaymentResource({
      body,
      id
    }),
    []
  );

  const props = {
    data,
    putPaymentResourceParams,
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

PaymentResourceEditBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PaymentResourceEditBll;
