import * as React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Chip,
  DialogContentText
} from '@mui/material';

const TagsFormDelete = React.forwardRef((props, ref) => {
  const {
    customContent,
    tags,
  } = props;
  const [tagsEdited, setTagsEdited] = React.useState(
    tags.map((tag) => ({
      ...tag,
      selected: false,
    }))
  );
  React.useImperativeHandle(ref, () => ({
    state: {
      selectedTags: tagsEdited
        .filter((tag) => tag.selected)
        .map((tag) => tag.name)
        .join(',')
    },
  }));

  const toggleSelected = (chekedTag) => {
    setTagsEdited((prev) => {
      const index = prev.findIndex((tag) => tag.id === chekedTag.id);
      const newTagsEdited = [...prev];
      newTagsEdited[index] = {
        ...newTagsEdited[index],
        selected: !newTagsEdited[index].selected,
      };
      return newTagsEdited;
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {customContent}
      <DialogContentText>
        Выберите метки, которые хотите удалить:
      </DialogContentText>
      <Box
        sx={{
          display: 'flex',
          flexDirection: tagsEdited.length > 15 ? 'column' : 'row',
          gap: '10px',
          paddingLeft: '10px',
          flexWrap: 'wrap',
          maxHeight: tagsEdited.length > 34 ? '500px' : '250px',
        }}
      >
        {tagsEdited.map((tag) => (
          <Box key={tag.id}>
            <Chip
              key={tag.id}
              label={tag.name}
              color={tag.selected ? 'primary' : 'default'}
              size="small"
              sx={{ marginRight: '5px' }}
              icon={tag.selected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              onClick={() => toggleSelected(tag)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
});

TagsFormDelete.propTypes = {
  customContent: PropTypes.node,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

TagsFormDelete.defaultProps = {
  customContent: null,
};

export default TagsFormDelete;
