import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const appUserSettingsResourceEnum = {
  appUserSettingId: "appUserSettingId",
  group: "group",
  name: "name",
  value: "value",
  valuelist: "valuelist",
  orderNum: "orderNum",
  type: "type",
  user: "user"
};

export const appUserSettingsResourceTYPEEnum = {
  settingId: "settingId",
  name: "name",

};

export const appUserSettingsResourceUSEREnum = {
  appUserId: "appUserId",
  firstName: "firstName",
  lastName: "lastName",
  company: "company",
  user: "user",
};

// Инициализация стейта в сторе
const appUserSettingsResourceInitialState = {
  [appUserSettingsResourceEnum.appUserSettingId]: NaN,
  [appUserSettingsResourceEnum.group]: null,
  [appUserSettingsResourceEnum.name]: null,
  [appUserSettingsResourceEnum.value]: null,
  [appUserSettingsResourceEnum.valuelist]: null,
  [appUserSettingsResourceEnum.orderNum]: NaN,
  [appUserSettingsResourceEnum.type]: null,
  [appUserSettingsResourceEnum.user]: null,
};
// Типизация стора
export const appUserSettingsResourceType = {
  [appUserSettingsResourceEnum.appUserSettingId]: PropTypes.number,
  [appUserSettingsResourceEnum.group]: PropTypes.string,
  [appUserSettingsResourceEnum.name]: PropTypes.string,
  [appUserSettingsResourceEnum.value]: PropTypes.string,
  [appUserSettingsResourceEnum.valuelist]: PropTypes.string,
  [appUserSettingsResourceEnum.orderNum]: PropTypes.number,
  [appUserSettingsResourceEnum.type]: PropTypes.shape({
    [appUserSettingsResourceTYPEEnum.settingId]: PropTypes.number,
    [appUserSettingsResourceTYPEEnum.name]: PropTypes.string
  }),
  [appUserSettingsResourceEnum.user]: PropTypes.shape({
    [appUserSettingsResourceUSEREnum.appUserId]: PropTypes.number,
    [appUserSettingsResourceUSEREnum.firstName]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.lastName]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.company]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.user]: PropTypes.string,
  }),
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const accountResourceSlice = createSlice({
  name: `${reducerPath.accountResource}/counter`,
  initialState: appUserSettingsResourceInitialState,
  reducers: {}
});

export const accountResourceReducer = accountResourceSlice.reducer;
