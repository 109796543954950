import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box, Button, Grid, IconButton,
  List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, TextField, Typography
} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useConfirm } from 'material-ui-confirm';
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteGeoZone as deleteGeoZoneApi, getGeoZonesNoFormQuery, postGeoZone, putGeoZone
} from '../../shared/api/api';
import style from './CompanyGeoZonesPanel.module.css';
import GeoZonesToolbarWidget from '../../widgets/geo-zones/GeoZonesToolbarWidget';
import NoData from '../../shared/ui/NoData/NoData';
import GeoZonesMapWidget from '../../widgets/geo-zones/GeoZonesMapWidget';
import { coordsToString } from '../../shared/geo-map/lib';
import useDebounce from "../../shared/hooks/useDebounce";
import useShowError from '../../shared/lib/hooks/useShowError';

const debounceDelay = 500;
const PAGE_SIZE = 20;

// Отображает список геозон компании и карту с полигонами зон
function CompanyGeoZonesPanel({ company }) {
  // Список геозон с сервера
  const [geoZones, setGeoZones] = useState([]);
  // Имя геозоны в текстовом поле
  const [nameGeoZone, setNameGeoZone] = useState('');
  // Координаты создаваемой геозоны
  const [coordsGeoZone, setCoordsGeoZone] = useState(null);
  // Выбранная геозона
  const [selectedGeoZone, setSelectedGeoZone] = useState(null);
  // Состояние создания полигона
  const [isCreatePolygon, setIsCreatePolygon] = useState(false);
  // Состояние создания геозоны
  const [isCreateGeoZone, setIsCreateGeoZone] = useState(false);
  // Кол-во изменений
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState({ name: false, polygon: false });

  const showError = useShowError();

  // Новые геозоны (всегда помещаются в начало списка)
  const [addedZones, setAddedZones] = useState([]);
  // Все геозоны, состоящие из уникальных новых и подгруженных
  const [allGeoZones, setAllGeoZones] = useState([]);
  // Номер последней подгруженной страницы
  const [page, setPage] = useState(0);

  const confirm = useConfirm();

  const [search, setSearch] = useState('');

  const [maxItems, setMaxItems] = useState(PAGE_SIZE);

  const [total, setTotal] = useState(0);

  const getGetGeoZonesFunc = (
    searchCurrent = undefined,
    isInitial = false,
    specificPage = undefined
  ) => {
    const requestedPage = specificPage >= 0 ? specificPage : page;
    const query = {
      'companyId.equals': company?.companyId,
      page: requestedPage,
      size: PAGE_SIZE,
      sort: 'name,asc'
    };
    if (searchCurrent) {
      query['name.contains'] = searchCurrent;
    }
    getGeoZonesNoFormQuery(query)
      .then((res) => {
        setTotal(res.total);
        setGeoZones((prev) => {
          prev.splice(requestedPage * PAGE_SIZE, PAGE_SIZE, ...res.geozones);
          return [...prev];
        });
        if (isInitial || (searchCurrent && requestedPage === 0)
          || !geoZones.some((g) => g.geozoneId === selectedGeoZone?.geozoneId)) {
          const selectedZone = allGeoZones[0] || addedZones[0] || geoZones[0];
          setSelectedGeoZone(selectedZone);
          setNameGeoZone(selectedZone?.name || '');
        }
      })
      .catch((error) => { throw error; });
  };

  // Удаление геозоны
  const deleteGeoZone = () => {
    if (!selectedGeoZone) {
      return;
    }
    deleteGeoZoneApi(selectedGeoZone.geozoneId).then(() => {
      // Нельзя просто удалить геозону, чтобы не нарушать порядок страниц
      // Вычисляем страницу, с которой удалена зона:
      let pageToRefresh = Math.floor(geoZones.findIndex((z) => (
        z.geozoneId === selectedGeoZone.geozoneId
      )) / PAGE_SIZE);
      // Запрашиваем эту и все следующие страницы повторно:
      while (pageToRefresh <= page) {
        getGetGeoZonesFunc(search, false, pageToRefresh);
        pageToRefresh += 1;
      }
      setAddedZones((prev) => (
        prev.filter((geoZone) => (
          geoZone.geozoneId !== selectedGeoZone?.geozoneId
        ))
      ));
      setSelectedGeoZone(null);
      setNameGeoZone('');
    }).catch((error) => {
      showError(error.errorMessage);
    });
  };

  // Нажатие на геозону в списке
  const onSelectGeoZone = (geoZone) => {
    setSelectedGeoZone(geoZone);
    setNameGeoZone(geoZone.name);
    setIsCreatePolygon(false);
    setIsCreateGeoZone(false);
    setIsChanged(false);
    setErrors({ name: false, polygon: false });
  };

  // Нажатие на кнопку создание геозоны
  const onClickCreate = () => {
    setSelectedGeoZone(null);
    setCoordsGeoZone(null);
    setNameGeoZone('');
    setIsCreatePolygon((prev) => !prev);
    setIsCreateGeoZone(true);
  };

  useEffect(() => {
    const newAddedZones = [...addedZones].filter((z) => !geoZones.some((gz) => (
      gz.geozoneId === z.geozoneId
    )));
    if (newAddedZones.length !== addedZones.length) {
      setAddedZones(newAddedZones);
    }
    setAllGeoZones([...newAddedZones, ...geoZones]);
  }, [addedZones, geoZones]);

  // Нажатие на кнопку сохранения
  const save = () => {
    // Если выбрана геозона из списка, запрос на put
    if (!nameGeoZone) {
      setErrors((prev) => ({
        ...prev,
        name: true,
      }));
      return;
    }
    if (selectedGeoZone) {
      const body = {
        ...selectedGeoZone,
        name: nameGeoZone,
        area: coordsGeoZone ? coordsToString(coordsGeoZone) : selectedGeoZone.area,
      };
      putGeoZone(body)
        .then((res) => {
          const updatedGeoZone = res.data;
          setGeoZones((prev) => {
            const geoZoneIndex = prev.findIndex((gz) => gz.geozoneId === updatedGeoZone.geozoneId);
            const newGeoZones = [...prev];
            if (geoZoneIndex !== -1) {
              newGeoZones[geoZoneIndex] = updatedGeoZone;
            }
            return newGeoZones;
          });
          setIsChanged(false);
        })
        .catch((error) => { throw error; });
    } else if (isCreateGeoZone) {
      // если геозона не выбрана и состояние создания true, запрос на post
      if (!coordsGeoZone) {
        setErrors((prev) => ({
          ...prev,
          polygon: true,
        }));
        return;
      }
      const body = {
        company: { companyId: company?.companyId },
        name: nameGeoZone,
        area: coordsToString(coordsGeoZone)
      };
      postGeoZone(body)
        .then((newGeoZone) => {
          // Не знаем - на какую страницу попадает новая геозона, поэтому добавляем ее в начало,
          // пока не найдем на загруженной странице
          setAddedZones((prev) => [newGeoZone, ...prev]);
          setSelectedGeoZone(newGeoZone);
          setNameGeoZone(newGeoZone.name);
          setIsCreateGeoZone(false);
          setIsCreatePolygon(false);
          setIsChanged(false);
        })
        .catch((error) => { throw error; });
    }
  };

  // Сброс изменений
  const reset = () => {
    setCoordsGeoZone(null);
    if (!isCreatePolygon) {
      setSelectedGeoZone((prev) => {
        setNameGeoZone(prev?.name || '');
        return JSON.parse(JSON.stringify(prev));
      });
    } else {
      setSelectedGeoZone(allGeoZones[0] || null);
      setNameGeoZone(allGeoZones[0]?.name || '');
    }
    setIsCreatePolygon(false);
    setIsCreateGeoZone(false);
    setErrors({ name: false, polygon: false });
    setIsChanged(false);
  };

  // Обновление кол-ва изменений при изменении полигона
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      polygon: false,
    }));
    if (coordsGeoZone) {
      setIsChanged(true);
    }
  }, [coordsGeoZone]);

  const debouncedSearch = useDebounce((value) => {
    setAddedZones([]);
    setGeoZones([]);
    setPage(0);
    if (value.length >= 3) {
      getGetGeoZonesFunc(value);
    } else {
      getGetGeoZonesFunc();
    }
  }, debounceDelay);

  useEffect(() => {
    debouncedSearch(search);
  }, [search, debouncedSearch]);

  useEffect(() => {
    setMaxItems((page + 1) * PAGE_SIZE);
  }, [page]);

  // Загрузка геозон при смене выбранной компании в дереве
  useEffect(() => {
    if (company) {
      setIsCreateGeoZone(false);
      setIsCreatePolygon(false);
      setErrors({ name: false, polygon: false });
      setIsChanged(false);
      setNameGeoZone('');
      getGetGeoZonesFunc('', true);
    }
  }, [company]);

  useEffect(() => {
    if (maxItems > geoZones.length) {
      if (search.length >= 3) {
        getGetGeoZonesFunc(search);
      } else {
        getGetGeoZonesFunc();
      }
    }
  }, [maxItems]);

  return (
    <Box className={style.companyGeoZonesPanel}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <GeoZonesToolbarWidget
            // onFocusName={onFocusName}
            errors={errors}
            setErrors={setErrors}
            setIsChanged={setIsChanged}
            geoZone={selectedGeoZone}
            nameGeoZone={nameGeoZone}
            onClickCreate={onClickCreate}
            isCreateGeoZone={isCreateGeoZone}
            setNameGeoZone={setNameGeoZone}
            onClickDelete={() => {
              confirm({
                title: 'Удаление',
                confirmationText: 'Да',
                cancellationText: 'Отмена',
                description: `Вы действительно хотите удалить «${selectedGeoZone?.name}»?`
              })
                .then(() => deleteGeoZone())
                .catch(() => { });
            }}
          />
          {selectedGeoZone && selectedGeoZone.polygon === null && (
            <Alert sx={{ width: "100%", marginBottom: '10px' }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Координаты геозоны некорректны либо отсутствуют.
            </Alert>
          )}
          <GeoZonesMapWidget
            setCoordsGeoZone={setCoordsGeoZone}
            isCreatePolygon={isCreatePolygon}
            geoZone={selectedGeoZone}
          />
          {errors.polygon
            ? (
              <Typography variant="body2" gutterBottom sx={{ color: '#D32F2F', paddingLeft: '20px' }}>
                Нарисуйте геозону перед сохранением
              </Typography>
            )
            : null}
          <Box className={style.formButtons}>
            <Button
              disabled={!isChanged && !isCreateGeoZone}
              disableElevation
              onClick={reset}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disabled={!isChanged}
              disableElevation
              onClick={save}
              variant="contained"
            >
              Сохранить
            </Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Paper variant="outlined">
            <Box sx={{ padding: "16px 16px 0" }}>
              <TextField
                value={search}
                onChange={(e) => { setSearch(e.target.value); }}
                id="searchThree"
                label="Поиск"
                variant="outlined"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: search.length >= 3 && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setSearch('');
                        setAddedZones([]);
                        setGeoZones([]);
                        if (page === 0) {
                          getGetGeoZonesFunc('', true);
                        } else {
                          setPage(0);
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                }}
              />
              {!!total && (
              <Typography my={1}>
                {search?.length >= 3 ? 'Найдено: ' : 'Всего: '}
                {total}
              </Typography>
              )}
            </Box>
            {allGeoZones.length
              ? (
                <List className={total ? style.geoZonesFoundList : style.geoZonesList}>
                  {allGeoZones.map((geoZone, index) => index + 1 <= maxItems && (
                    <ListItem
                      key={geoZone.id}
                    >
                      <ListItemButton
                        selected={selectedGeoZone?.id === geoZone.id}
                        onClick={() => onSelectGeoZone(geoZone)}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <InsertPhotoIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={geoZone.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )
              : (
                <NoData
                  text="Нет геозон"
                />
              )}
            {geoZones.length >= maxItems && (
            <Box sx={{ justifyContent: "center", display: "flex", marginBottom: "16px" }}>
              <Button
                variant="contained"
                onClick={() => setPage((prevState) => (
                  page < maxItems / PAGE_SIZE ? prevState + 1 : prevState
                ))}
              >
                Показать еще
              </Button>
            </Box>
            )}

          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

CompanyGeoZonesPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyGeoZonesPanel.defaultProps = {
  company: null,
};

export default CompanyGeoZonesPanel;
