import React, { Children, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
import {
  useGetVehicleFuelEfficiencyParamsMutation
} from "../../redux/vehicleFuelEfficiency.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
// передает объект:
// {data:"массив из апи",getVehicleFuelEfficiencyParams: функцию которая делает запрос }
function VehicleFuelEfficiencyBll({ children }) {
  const [getDataMutation,
    dataMutation] = useGetVehicleFuelEfficiencyParamsMutation();

  const getVehicleFuelEfficiencyParams = useCallback((params) => {
    getDataMutation({
      params
    });
  }, []);

  const props = {
    data: dataMutation,
    getVehicleFuelEfficiencyParams
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

VehicleFuelEfficiencyBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default VehicleFuelEfficiencyBll;
