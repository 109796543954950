import * as React from 'react';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from 'react-redux';
import TruckIcon from '../../../assets/icons/TruckIcon';
import {
  COMMON_PADDING, FIELD_ICO_COLOR, FIELD_TXT_COLOR, FLD_BLOCK_HEIGHT, PANEL_COLOR,
  ROUTE_BLOCK_HEIGHT,
  ROUTE_ICO_COLOR,
} from './constants';
import RouteItem from './RouteItem';
import ButtonWithMenu from './ButtonWithMenu';
import ButtonWithDialog from './ButtonWithDialog';
import chart1 from '../../../assets/images/clipboard-202406071817-nx8qf.png';
import chart2 from '../../../assets/images/clipboard-202406071817-sms3v.png';
import DetailsDialog from './DetailsDialog';
import { logisticTasksBalanceResourceActions } from '../../../entities/logistic/logisticTasks/redux/logisticTasksBalance.slice';

export default function FieldItem(props) {
  const dispatch = useDispatch();
  const {
    bgColor, harvesters, fieldName, area, cropType, availableVehicles,
    id, routes, latest, warehouses, availableHarvesters, sx, ...other
  } = props;
  const warning = !routes?.length || !harvesters?.length;
  const filteredWarehouses = React.useMemo(() => warehouses
    .filter((w) => !routes.some((r) => r.warehouseId === w.id)), [warehouses, routes]);

  const [selectedHarvester, setSelectedHarvester] = React.useState({ item: null, onOk: null });
  const [fieldItemDetails, setFieldItemDetails] = React.useState(null);

  const openHarvesterDialog = (item, onOk, okLabel) => {
    setSelectedHarvester({ item, onOk, okLabel });
  };

  const closeHarvesterDialog = () => {
    setSelectedHarvester({ item: null, onOk: null });
  };

  const openFieldDetailsDialog = () => {
    setFieldItemDetails({
      id, fieldName, area, cropType, ...other
    });
  };

  const closeFieldDetailsDialog = () => {
    setFieldItemDetails(null);
  };

  const addRoute = (item) => {
    dispatch(logisticTasksBalanceResourceActions.addRoute({ warehouseId: item.id, fieldId: id }));
  };

  const addHarvester = (hv) => {
    dispatch(logisticTasksBalanceResourceActions.addHarvesterToField({
      id, hv
    }));
  };

  const removeHarvester = (hv) => {
    dispatch(logisticTasksBalanceResourceActions.removeHarvesterFromField({
      id, hv
    }));
  };

  return (
    <>
      <Box sx={{
        bgcolor: PANEL_COLOR,
        p: `${COMMON_PADDING}px ${latest ? COMMON_PADDING : COMMON_PADDING / 2}px ${COMMON_PADDING}px ${COMMON_PADDING / 2}px`,
        borderRadius: `0 ${latest ? 8 : 0}px ${latest ? 8 : 0}px 0`,
      }}
      >
        <Box
          sx={{
            bgcolor: fieldName ? bgColor : 'transparent',
            borderRadius: '4px',
            height: `${FLD_BLOCK_HEIGHT}px`,
            ...sx,
          }}
        >
          { id !== undefined
            && (
            <>
              <ButtonWithDialog
                title="Текущее состояние"
                content={(
                  <>
                    <img src={chart1} alt="" />
                    <br />
                    <img src={chart2} alt="" />
                  </>
                )}
                onOk={() => console.log('OK')}
                onCancel={() => console.log('Cancel')}
              >
                <NotificationsNoneRoundedIcon sx={{ transform: 'rotate(45deg)', color: (theme) => (warning ? theme.palette.error.main : FIELD_ICO_COLOR) }} />
              </ButtonWithDialog>
              <Typography
                title={`${fieldName} ${area} ${cropType}`}
                sx={{
                  fontSize: '0.75rem', color: FIELD_TXT_COLOR, textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis", margin: '0 6px', lineHeight: 1
                }}
              >
                {fieldName}
                <br />
                {area}
                <br />
                {cropType}
              </Typography>
              <IconButton
                sx={{ margin: '0 3px' }}
                title="Свойства поля"
                id="basic-button"
                aria-controls={fieldItemDetails ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={fieldItemDetails ? 'true' : undefined}
                onClick={openFieldDetailsDialog}
              >
                <SettingsIcon sx={{ color: FIELD_ICO_COLOR }} />
              </IconButton>
              <DetailsDialog type="field" item={fieldItemDetails} closeItemDialog={closeFieldDetailsDialog} />
              <ButtonWithMenu
                sx={{
                  '& .MuiButton-root': {
                    padding: '0 2px',
                    width: '100%',
                    margin: 0,
                    color: 'text.primary',
                    minWidth: 0,
                  },
                  margin: 0,
                  padding: 0,
                }}
                type="button"
                items={harvesters}
                onItemClick={(item) => openHarvesterDialog(item, () => removeHarvester(item), 'Удалить единицу с поля')}
                onRemoveItemClick={(item) => removeHarvester(item)}
                title="Единицы на поле"
              >
                <Box
                  sx={{
                    background: '#ddeec8', borderRadius: '4px', width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.25rem', color: (theme) => (warning ? theme.palette.error.main : 'inherit'), textAlign: 'center'
                    }}
                  >
                    {harvesters?.length || 0}
                  </Typography>
                </Box>
              </ButtonWithMenu>
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonWithMenu
                  items={availableHarvesters}
                  onItemClick={(item) => openHarvesterDialog(item, () => addHarvester(item), 'Добавить единицу на поле')}
                  onAddItemClick={(item) => addHarvester(item)}
                  title="Добавить единицу"
                >
                  <AddCircleIcon sx={{ color: '#7fb63f' }} />
                </ButtonWithMenu>
              </Box>
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonWithMenu
                  closeOnItemClick={filteredWarehouses?.length <= 1}
                  items={filteredWarehouses}
                  onItemClick={(item) => addRoute(item)}
                  title="Добавить маршрут"
                >
                  <TruckIcon sx={{ color: ROUTE_ICO_COLOR, transform: 'scale(-1, 1)' }} />
                </ButtonWithMenu>
              </Box>
            </>
            )}
        </Box>
      </Box>
      <Box sx={{
        bgcolor: PANEL_COLOR,
        p: `${COMMON_PADDING}px ${latest ? COMMON_PADDING : COMMON_PADDING / 2}px ${COMMON_PADDING}px ${COMMON_PADDING / 2}px`,
        borderRadius: `0 ${latest ? 8 : 0}px ${latest ? 8 : 0}px 0`,
        display: 'flex',
        gap: `${COMMON_PADDING}px`,
        mt: `${COMMON_PADDING}px`,
        height: `${ROUTE_BLOCK_HEIGHT + 2 * COMMON_PADDING}px`,
      }}
      >
        {
          routes?.map((route) => (
            <RouteItem
              key={`${id}_${route.warehouseId}`}
              fieldId={id}
              availableVehicles={availableVehicles}
              {...route}
            />
          ))
        }
      </Box>
      <DetailsDialog
        type="harvester"
        item={selectedHarvester?.item}
        onOk={selectedHarvester?.onOk}
        closeItemDialog={closeHarvesterDialog}
        okLabel={selectedHarvester?.okLabel}
      />
    </>
  );
}

FieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  harvesters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, model: PropTypes.string, regNum: PropTypes.string
  })),
  availableHarvesters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, model: PropTypes.string, regNum: PropTypes.string
  })),
  availableVehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, model: PropTypes.string, regNum: PropTypes.string
  })),
  routes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    warehouseId: PropTypes.string.isRequired,
    warehouseIndex: PropTypes.number.isRequired,
  })),
  warehouses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  fieldName: PropTypes.string,
  area: PropTypes.string,
  cropType: PropTypes.string,
  latest: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.any,
};

FieldItem.defaultProps = {
  bgColor: '#bbdf93',
  latest: false,
  harvesters: [],
  availableHarvesters: [],
  availableVehicles: [],
  routes: [],
  warehouses: [],
  fieldName: '',
  area: '',
  cropType: '',
  sx: {},
};
