import {
  Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent,
  DialogTitle, Grid, ListItem, TextField, Typography,
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getDayStringFromDate from '../../../shared/utils/getDayStringFromDate';
import isValidDate from '../../../shared/utils/isValidDate';
import useDebounce from '../../../shared/hooks/useDebounce';

// Отображает диалоговое окно для выбора маршрута по компании и варианту маршрута
function SelectRouteDlg(props) {
  const {
    isOpenRouteDlg,
    setIsOpenRouteDlg,
    selectedRouteVariant,
    selectRoute,
    vehicleRoutes,
    onRouteNameChange,
    loading,
    title,
    content,
    label,
    autoFocus,
    isValid,
  } = props;
  const [route, setRoute] = useState(null);
  const [routes, setRoutes] = useState(vehicleRoutes);

  useEffect(() => {
    setRoutes(vehicleRoutes);
  }, [vehicleRoutes]);

  const [searchRouteName, setSearchRouteName] = useState('');

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpenRouteDlg(false);
      setRoute(null);
    }
  };

  const handleOk = (event, reason) => {
    if (route) {
      selectRoute({ ...route });
      handleClose(event, reason);
    }
  };

  const onInputChangeDebounced = useDebounce((newInputValue) => {
    setSearchRouteName(newInputValue);
  }, 500);

  const onInputChange = (event, newInputValue) => {
    onInputChangeDebounced(newInputValue);
  };

  useEffect(() => {
    if (searchRouteName.length >= 3) {
      onRouteNameChange(searchRouteName);
    } else {
      setRoutes([]);
    }
  }, [searchRouteName, onRouteNameChange]);

  const getDate = React.useCallback((date) => {
    const checkedDate = date && new Date(date);
    if (!isValidDate(checkedDate)) {
      return 'Время не указано.';
    }
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const futureDate = new Date(new Date().setHours(0, 0, 0, 0));
    futureDate.setDate(currentDate.getDate() + 5);
    const day = getDayStringFromDate(currentDate, futureDate, checkedDate);
    const time = checkedDate?.toLocaleTimeString('ru', { hour: "2-digit", minute: "2-digit" });
    return `${day} ${time}`;
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      open={isOpenRouteDlg}
      onClose={handleClose}
    >
      <DialogTitle>{selectedRouteVariant?.name || title}</DialogTitle>
      <DialogContent
        sx={{
          minHeight: '100px',
        }}
      >
        <Box
          sx={{
            p: 1, display: 'flex', flexDirection: 'column', gap: '20px'
          }}
        >
          {content}
          <Autocomplete
            fullWidth
            autoComplete
            autoFocus={autoFocus}
            noOptionsText={searchRouteName.length < 3 ? 'Поиск от 3-х символов' : 'Нет результатов'}
            value={route}
            onChange={(event, newValue) => {
              setRoute(newValue);
            }}
            onInputChange={onInputChange}
            id="route"
            loading={loading}
            isOptionEqualToValue={(option, value) => option.vehicleRouteId === value.vehicleRouteId}
            renderOption={(propsOption, option) => {
              const date = getDate(option.startTime);
              return (
                <ListItem {...propsOption}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <DirectionsCarIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Box
                        component="span"
                        sx={{ fontWeight: 'regular' }}
                      >
                        {option.name}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {date}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }}
            options={routes}
            getOptionLabel={(option) => `${option.name} (${getDate(option.startTime)})`}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={autoFocus}
                label={label}
                placeholder="Введите название"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleOk} disabled={!isValid || !route}>Ок</Button>
      </DialogActions>
    </Dialog>
  );
}

SelectRouteDlg.propTypes = {
  isOpenRouteDlg: PropTypes.bool.isRequired,
  selectRoute: PropTypes.func.isRequired,
  setIsOpenRouteDlg: PropTypes.func.isRequired,
  selectedRouteVariant: PropTypes.objectOf(PropTypes.string),
  vehicleRoutes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]))),
  onRouteNameChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.node,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  isValid: PropTypes.bool,
};

SelectRouteDlg.defaultProps = {
  vehicleRoutes: [],
  selectedRouteVariant: null,
  title: '',
  content: null,
  label: 'Название',
  autoFocus: true,
  isValid: true,
};

export default SelectRouteDlg;
