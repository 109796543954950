// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { usersResourceEnum } from "../redux/usersResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const usersResourceDtoType = {
  [usersResourceEnum.id]: PropTypes.number,
  [usersResourceEnum.login]: PropTypes.string,
  [usersResourceEnum.firstName]: PropTypes.string,
  [usersResourceEnum.lastName]: PropTypes.string,
  [usersResourceEnum.email]: PropTypes.string,
  [usersResourceEnum.imageUrl]: PropTypes.string,
  [usersResourceEnum.activated]: PropTypes.string,
  [usersResourceEnum.langKey]: PropTypes.string,
  [usersResourceEnum.createdBy]: PropTypes.string,
  [usersResourceEnum.createdDate]: PropTypes.string,
  [usersResourceEnum.lastModifiedBy]: PropTypes.string,
  [usersResourceEnum.lastModifiedDate]: PropTypes.string,
  [usersResourceEnum.authorities]: PropTypes.arrayOf(PropTypes.string),
};

export default usersResourceDtoType;
