import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, CircularProgress, IconButton, TextField, Tooltip
} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import style from './PassengersListToolbarWidget.module.css';
import CARGO_TYPE from '../../../shared/constants/cargoTypes';
import VisuallyHiddenInput from '../../../shared/ui/VisuallyHiddenInput/VisuallyHiddenInput';

// Показывает тулбар над списком пассажиров
function PassengersListToolbarWidget(props) {
  const {
    isLoadingImport,
    isSuccessfulImport,
    handleUpload,
    handleSearch,
    searchText: propSearchText,
    cargoType,
  } = props;
  const navigate = useNavigate();

  const isPassenger = useMemo(() => cargoType === 'CARGO_TYPE_PASSENGER', [cargoType]);

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState(propSearchText);

  useEffect(() => {
    setSearchText(propSearchText);
  }, [propSearchText]);

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onReset = () => {
    setSearchText('');
    handleSearch('');
  };

  const titleImport = useMemo(() => {
    switch (true) {
      case isSuccessfulImport:
        return 'Успешно загружено';
      case isLoadingImport:
        return 'Файл загружается...';
      default:
        return 'Загрузить файл';
    }
  }, [isSuccessfulImport, isLoadingImport]);

  const iconImport = useMemo(() => {
    switch (true) {
      case isSuccessfulImport:
        return <DoneIcon color="success" />;
      case isLoadingImport:
        return <CircularProgress size={30} />;
      default:
        return (
          <>
            <UploadFileIcon />
            <VisuallyHiddenInput type="file" onChange={handleUpload} accept=".xlsx" />
          </>
        );
    }
  }, [isSuccessfulImport, isLoadingImport, handleUpload]);

  const location = useLocation();
  const onCreate = () => navigate(`create`, { state: { from: location } });

  return (
    <Box className={style.toolbar}>
      <Box className={style.filters}>
        <TextField
          className={style.searchText}
          label="Поиск"
          placeholder={isPassenger ? 'Ваня Иванов 6а' : 'Кирпич'}
          variant="outlined"
          size="small"
          value={searchText}
          onChange={onChangeSearchText}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          InputProps={{
            endAdornment: (isHovered || isFocused) && searchText
              ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchText('')} size="small">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
              : null
          }}
        />
        <Button variant="text" onClick={onReset}>
          Сбросить
        </Button>
        <Button variant="contained" onClick={() => handleSearch(searchText)}>
          Найти
        </Button>
      </Box>
      <Box className={style.controls}>
        <Tooltip title="Добавить">
          <IconButton
            color="primary"
            onClick={onCreate}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title={titleImport}>
          <IconButton
            color="primary"
            component="label"
            role={undefined}
          >
            {iconImport}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default PassengersListToolbarWidget;

PassengersListToolbarWidget.propTypes = {
  isLoadingImport: PropTypes.bool,
  isSuccessfulImport: PropTypes.bool,
  handleUpload: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  cargoType: CARGO_TYPE.type,
};

PassengersListToolbarWidget.defaultProps = {
  isLoadingImport: false,
  isSuccessfulImport: false,
  cargoType: CARGO_TYPE.default,
};
