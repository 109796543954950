// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import appUserSettingsResourceDtoType from "../../types/appUserSettingsResource-dto.type";

export function appUserSettingsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

appUserSettingsResourceFromDtoService.propTypes = {
  dto: appUserSettingsResourceDtoType,
};

export default appUserSettingsResourceFromDtoService;
