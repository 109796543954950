import React from 'react';
import PropTypes from 'prop-types';
import PolygonVectorLayer from '../../shared/ui/GeoMap/PolygonVectorLayer';
import GeoMapWithTiles from '../../features/geo-map/GeoMapWithTiles';
import style from './GeoZonesMapWidget.module.css';

// Начальные координаты (Воронеж)
const center = [4363577.631173629, 6739789.222450285];
// Начальный масштаб
const zoom = 15;

// Отображает карту с полигоном геозоны
function GeoZonesMapWidget(props) {
  const { isCreatePolygon, geoZone, setCoordsGeoZone } = props;

  return (
    <GeoMapWithTiles center={center} zoom={zoom} styles={style.geoMap} isOffButtonFollowMode>
      <PolygonVectorLayer
        isSelectable
        isChangeable
        setCoordsGeoZone={setCoordsGeoZone}
        isCreatePolygon={isCreatePolygon}
        zIndex={2}
        coordinates={geoZone?.polygon}
      />
    </GeoMapWithTiles>
  );
}

GeoZonesMapWidget.propTypes = {
  isCreatePolygon: PropTypes.bool.isRequired,
  geoZone: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  setCoordsGeoZone: PropTypes.func.isRequired,
};

GeoZonesMapWidget.defaultProps = {
  geoZone: null,
};

export default GeoZonesMapWidget;
