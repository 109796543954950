import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import style from './FileName.module.css';

function FileName({ nameFile = '' }) {
  return (
    <Typography
      className={style.ellipsis}
      variant="p"
      component="h4"
      sx={{ color: 'text.secondary' }}
    >
      Файл:
      {' '}
      {nameFile}
    </Typography>
  );
}

FileName.propTypes = {
  nameFile: PropTypes.string.isRequired,
};

export default FileName;
