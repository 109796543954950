import React from 'react';
import {
  Link as LinkMUI,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Link } from 'react-router-dom';

// Отображает элемент списка маршрутов в таблице
function ListItemRouteTable(props) {
  const {
    link,
    onClick,
    day,
    time,
    name,
    vehicleNum,
  } = props;
  return (
    <ListItem disablePadding>
      <Tooltip title={name} placement="left">
        <LinkMUI
          to={link}
          onClick={onClick}
          component={Link}
          underline="hover"
          color="inherit"
        >
          <DirectionsCarIcon
            color="primary"
            sx={{
              fontSize: '18px',
              alignSelf: 'flex-start',
              paddingRight: '5px',
              position: 'relative',
              bottom: '-3px'
            }}
          />
          <Typography variant="subtitle2" component="span">
            {day}
            {' '}
          </Typography>
          <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
            {time}
            {' '}
            {vehicleNum}
          </Typography>
        </LinkMUI>
      </Tooltip>
    </ListItem>
  );
}

ListItemRouteTable.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  vehicleNum: PropTypes.string,
};

ListItemRouteTable.defaultProps = {
  vehicleNum: '',
};

export default ListItemRouteTable;
