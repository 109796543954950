import {
  Box, Button, FormControl, InputLabel,
  MenuItem, Select, TextField, Typography
} from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import style from './VehicleProposalWidget.module.css';
import {
  getPlatoonRequests, getVehicleRoutes, getVehicleRoutesV2, postPlatoonRequests, putPlatoonRequests
} from '../../../shared/api/api';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import RouteInputControl from '../../../features/vehicles/RouteInputControl';

const roles = [
  { type: 'BOTH', name: 'Без ограничений' },
  { type: 'LEADER', name: 'Только ведущий' },
  { type: 'SATELLITE', name: 'Только ведомый' },
];

const initialProposal = {
  startDateFrom: new Date(),
  startDateTo: new Date(),
  role: roles[0].type,
  routeName: '',
};

const STATUSES = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  REQUEST_ACK: 'REQUEST_ACK',
  COMPLETE: 'COMPLETE',
  ABORTED: 'ABORTED',
};

const proposalStatuses = {
  NOT_CREATED: '',
  [STATUSES.CREATED]: 'Отправлена',
  [STATUSES.ACTIVE]: 'Активна',
  [STATUSES.REQUEST_ACK]: 'Ожидает подтверждения',
  [STATUSES.COMPLETE]: 'Выполнена',
  [STATUSES.ABORTED]: 'Отменена',
};

// Отображает часть аккордина панели "Группа ТС" - Заявка
function VehicleProposalWidget(props) {
  const {
    company, vehicle, setPlatoonGroupId
  } = props;
  // Заявка
  const [proposal, setProposal] = useState({ ...initialProposal });
  // Ошибки в полях ввода
  const [errors, setErrors] = useState({ ...initialProposal });
  // Выбранный в диалоговом окне маршрут
  const [selectedRoute, setSelectedRoute] = useState(null);
  // Текущий статус заявки
  const [proposalStatus, setProposalStatus] = useState(proposalStatuses.NOT_CREATED);
  // Список маршрутов
  const [vehicleRoutes, setVehicleRoutes] = useState([]);

  const [update, setUpdate] = useState(false);

  // Измение в поле ввода Select
  const changeSelect = (event) => {
    setProposal((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  // Изменение в поле ввода даты
  const handleChangeDate = (dateField, newValue) => {
    setProposal((prev) => ({ ...prev, [dateField]: newValue }));
    if (errors[dateField]) {
      setErrors((prev) => ({ ...prev, [dateField]: !newValue }));
    }
  };

  // Отправка заявки
  const clickCreateProposal = () => {
    let isError = false;
    Object.keys(initialProposal).forEach((key) => {
      if (!isError) {
        isError = !(proposal[key]);
      }
      setErrors((prev) => ({ ...prev, [key]: !(proposal[key]) }));
    });
    if (!isError) {
      const body = {
        vehicleId: vehicle?.vehicleId,
        status: STATUSES.CREATED,
        companyId: company.companyId,
        routeId: selectedRoute.vehicleRouteId,
        role: proposal.role,
        startDateFrom: proposal.startDateFrom,
        startDateTo: proposal.startDateTo,
        createdDate: new Date(),
        modifiedDate: new Date(),
      };

      postPlatoonRequests(body).then((res) => {
        setProposalStatus(proposalStatuses[res.status]);
        setProposal((prev) => ({ ...prev, ...res }));
      }).catch((error) => { throw error; });
    }
  };

  // Обновление статуса заявки по кнопке отменить/подтвердить
  const clickUpdateProposal = (newStatus) => {
    const body = {
      ...proposal,
      status: newStatus,
    };

    putPlatoonRequests(body).then((res) => {
      if (res.status === STATUSES.ABORTED) {
        setUpdate((prev) => !prev);
      } else {
        setProposalStatus(proposalStatuses[res.status]);
      }
      // setProposal((prev) => ({ ...prev, ...res }));
    }).catch((error) => { throw error; });
  };

  // Смена имени маршрута в форме при смене выбранного маршрута
  useEffect(() => {
    setProposal((prev) => ({ ...prev, routeName: selectedRoute?.name || '' }));
    if (selectedRoute) {
      setErrors((prev) => ({ ...prev, routeName: !selectedRoute?.name }));
    }
  }, [selectedRoute]);

  // Загрузка заявки
  useEffect(() => {
    getPlatoonRequests({
      companyId: company.companyId,
      vehicleId: vehicle.vehicleId,
      status: {
        paramBody: 'notEquals',
        paramValue: STATUSES.ABORTED,
      }
    }).then((res) => {
      if (res.length) {
        setProposal(res[0]);
        setProposalStatus(proposalStatuses[res[0].status]);
        setPlatoonGroupId(res[0].group?.platoonGroupId);
        getVehicleRoutes({
          vehicleRouteId: res[0].routeId,
        }).then((routes) => {
          setSelectedRoute(routes[0]);
        }).catch((error) => { throw error; });
      } else {
        setProposal({ ...initialProposal });
        setProposalStatus(proposalStatuses.NOT_CREATED);
        setPlatoonGroupId(null);
        setSelectedRoute(null);
      }
    }).catch((error) => { throw error; });
  }, [vehicle, company, setProposalStatus, setPlatoonGroupId, update]);

  // Обновление статуса заявки по таймеру
  useEffect(() => {
    // Таймер обновления
    let timer = null;
    // Период обновления (сек)
    const timerCount = 10;
    if (proposalStatus === proposalStatuses.CREATED) {
      timer = setInterval(() => {
        getPlatoonRequests({ platoonReqId: proposal.platoonReqId }).then((res) => {
          if (res[0].status === STATUSES.REQUEST_ACK) {
            clearInterval(timer);
            setPlatoonGroupId(res[0].group?.platoonGroupId);
            setProposalStatus(proposalStatuses.REQUEST_ACK);
            setProposal(res[0]);
          }
        }).catch((error) => { throw error; });
      }, 1000 * timerCount);
    }

    return () => {
      // Очистка таймера
      if (timer) clearInterval(timer);
    };
  }, [proposalStatus, proposal, setProposalStatus, setPlatoonGroupId]);

  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);
  const onRouteNameChange = useCallback((name) => {
    if (company) {
      setIsLoadingRoutes(true);
      setVehicleRoutes([]);
      const params = [
        { name: 'companyId.equals', value: company.companyId },
        { name: 'name.contains', value: name },
        { name: 'page', value: 0 },
        { name: 'size', value: 50 },
        { name: 'sort', value: 'name,asc' },
      ];
      getVehicleRoutesV2(params)
        .then((res) => {
          setVehicleRoutes(res.routes);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => setIsLoadingRoutes(false));
    }
  }, [company]);

  useEffect(() => {
    setVehicleRoutes([]);
  }, [company]);

  const confirm = useConfirm();
  return (
    <Box className={style.vehicleProposalWidget}>
      <Box className={style.proposal}>
        <Typography sx={{ color: 'text.secondary' }}>
          {proposalStatus ? `Статус: ${proposalStatus}` : ''}
        </Typography>
        <Box className={style.inputsRouteRole}>
          <RouteInputControl
            selectedRoute={selectedRoute}
            selectRoute={setSelectedRoute}
            vehicleRoutes={vehicleRoutes}
            onRouteNameChange={onRouteNameChange}
            loading={isLoadingRoutes}
          />
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel>Роль</InputLabel>
            <Select
              name="role"
              id="role"
              value={proposal.role}
              onChange={changeSelect}
              label="Роль"
            >
              {roles.map((role) => (
                <MenuItem key={role.type} value={role.type}>{role.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Время отправления
          </Typography>
          <Box className={style.datePeriodInput}>
            <DateTimePicker
              label="C"
              value={proposal.startDateFrom}
              onChange={(newDate) => {
                if (newDate > proposal.startDateTo) {
                  handleChangeDate('startDateTo', newDate);
                }
                handleChangeDate('startDateFrom', newDate);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
            <DateTimePicker
              label="По"
              value={proposal.startDateTo}
              minDateTime={proposal.startDateFrom}
              onChange={(newDate) => handleChangeDate('startDateTo', newDate)}
              // onError={(error, value) => handleErrorDate(error, value)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Box>
        </Box>
        <Box className={style.buttons}>
          <Button
            variant="contained"
            disabled={proposalStatus === proposalStatuses.NOT_CREATED
              || proposalStatus === proposalStatuses.ABORTED}
            onClick={() => {
              confirm({
                title: 'Отмена заявки',
                confirmationText: 'Да',
                cancellationText: 'Нет',
                description: `Вы уверены, что хотите отменить заявку?`
              })
                .then(() => clickUpdateProposal(STATUSES.ABORTED))
                .catch(() => { });
            }}
          >
            Отменить
          </Button>
          <Button
            disabled={proposalStatus !== proposalStatuses.NOT_CREATED}
            variant="contained"
            onClick={clickCreateProposal}
          >
            Отправить
          </Button>
          <Button
            disabled={proposalStatus !== proposalStatuses.REQUEST_ACK}
            variant="contained"
            onClick={() => clickUpdateProposal(STATUSES.ACTIVE)}
          >
            Подтвердить
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

VehicleProposalWidget.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  vehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  setPlatoonGroupId: PropTypes.func.isRequired,
};

VehicleProposalWidget.defaultProps = {
  company: null,
  vehicle: null,
};

export default VehicleProposalWidget;
