// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import vehiclesRoutePointsResourceDtoType from "../../types/vehiclesRoutePointsResource-dto.type";

export function vehiclesRoutePointsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

vehiclesRoutePointsResourceFromDtoService.propTypes = {
  dto: vehiclesRoutePointsResourceDtoType
};

export default vehiclesRoutePointsResourceFromDtoService;
