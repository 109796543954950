import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.vehicleFuelEfficiency/api`
export const vehicleEnum = {
  vehicleId: "vehicleId",
  name: "name",
  num: "num",
  token: "token",
  yearCreated: "yearCreated",
  division: "division",
  company: "company",
  model: "model"
};
// Инициализация стейта в сторе
const vehicleInitialState = {
  [vehicleEnum.vehicleId]: NaN,
  [vehicleEnum.name]: null,
  [vehicleEnum.num]: null,
  [vehicleEnum.token]: null,
  [vehicleEnum.yearCreated]: NaN,
  [vehicleEnum.division]: {},
  [vehicleEnum.company]: {},
  [vehicleEnum.model]: {}
};
// Типизация стора
export const vehicleType = {
  [vehicleEnum.vehicleId]: PropTypes.number,
  [vehicleEnum.name]: PropTypes.string,
  [vehicleEnum.num]: PropTypes.string,
  [vehicleEnum.token]: PropTypes.string,
  [vehicleEnum.yearCreated]: PropTypes.number,
  [vehicleEnum.division]: PropTypes.object,
  [vehicleEnum.company]: PropTypes.object,
  [vehicleEnum.model]: PropTypes.object
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const vehicleSlice = createSlice({
  name: `${reducerPath.vehicle}/counter`,
  initialState: vehicleInitialState,
  reducers: {}
});

export const vehicleReducer = vehicleSlice.reducer;
