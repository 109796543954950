import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import {
  Alert, AlertTitle, Box, Button, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTranslation } from 'react-i18next';
import Loading from '../../../shared/ui/Loading/Loading';
import {
  usePostLogisticsResourceMutation
} from "../../../entities/logistic/logisticTasks/redux/logisticTasks.api";
import useAuth from "../../../shared/lib/hooks/useAuth";
import useShowError from "../../../shared/lib/hooks/useShowError";
import style from './CompanyLogisticsPanel.module.css';
import getErrorMessage from '../../../shared/api/getErrorMessage';
import ERROR_CODES from '../../../shared/api/ERROR_CODES';

// Функция проверки файла на размер файла (file <= 5 MB)
const validateFile = (file) => {
  const maxSize = 5 * 1024 * 1024; // 5 * 1024 * 1024 === 5 MB in bytes
  return file.size <= maxSize;
};
// Текст ошибки при обработке файл/ загрузке и тд
const defaultError = "Ошибка при обработке файла, попробуйте еще раз";
// Текст ошибка при обработке размера файла
const sizeError = "Максимально допустимый размер файла 5 MB";
// Отображает панель "Логистики" для ТС
function CompanyLogisticsPanel() {
  const { i18n, t } = useTranslation('translation');
  const [postLogisticsResourceTrigger, {
    isError: isErrorPostLogisticsResource,
    isLoading: isLoadingPostLogisticsResource,
    isSuccess: isSuccessPostLogisticsResource,
    data: dataPostLogisticsResource,
    error: errorPostLogisticsResource
  }] = usePostLogisticsResourceMutation();
  const [csvFile, setCsvFile] = useState(null);
  const [zipFile, setZipFile] = useState(null);
  const fileCsvFileRef = useRef(null);
  const fileZipFileRef = useRef(null);
  const showError = useShowError();
  const auth = useAuth();
  const appUserId = useMemo(() => auth?.user?.appUserId, [auth]);
  const navigate = useNavigate();

  // Фунция для получения текста ошибки по коду ошиибки запроса
  const getErrorTextFromCodeRequest = useCallback((error) => {
    if (!error) {
      return null;
    }
    switch (error.status) {
      case 400:
        return getErrorMessage(ERROR_CODES.CHECK_THE_DATA, error);
      case 403:
        return getErrorMessage(ERROR_CODES.FORBIDDEN, error);
      case 404:
        return getErrorMessage(ERROR_CODES.COMPANY_FOR_LOGISTIC_NOT_FOUND, error);
      default:
        return getErrorMessage(ERROR_CODES.UNKNOWN_ERROR, error);
    }
  }, [i18n?.language]);

  // Обработка файла после нажатия на кнопку "выбрать csv формата"
  const handleCsvFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile?.name?.endsWith('.csv')) {
      showError("Допустимый формат только .csv, выберите другой файл");
      return null;
    }
    if (!validateFile(selectedFile)) {
      showError(sizeError);
      return null;
    }
    if (selectedFile) {
      setCsvFile(selectedFile);
    } else {
      showError(defaultError);
      return null;
    }
    return selectedFile;
  }, [showError, setCsvFile]);

  // Обработка файла после нажатия на кнопку "выбрать zip файл"
  const handleZipFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile?.name?.endsWith('.zip')) {
      showError("Допустимый формат только .zip, выберите другой файл");
      return null;
    }
    if (!validateFile(selectedFile)) {
      showError(sizeError);
      return null;
    }
    if (selectedFile) {
      setZipFile(selectedFile);
    } else {
      showError(defaultError);
      return null;
    }
    return selectedFile;
  }, [showError, setZipFile]);

  // Обработка нажатия на кнопку "выбрать csv формата"
  const handleCsvFileClick = useCallback(() => {
    if (fileCsvFileRef.current.click) {
      fileCsvFileRef.current.click();
    } else {
      showError(defaultError);
    }
  }, [fileCsvFileRef]);

  // Обработка нажатия на кнопку "выбрать zip формата"
  const handleZipFileClick = useCallback(() => {
    if (fileZipFileRef.current.click) {
      fileZipFileRef.current.click();
    } else {
      showError(defaultError);
    }
  }, [fileZipFileRef]);

  // Обработка нажатия на кнопку "Создать задачу"
  const handleFormButtonClick = useCallback(() => {
    if (zipFile && csvFile) {
      const formData = new FormData();
      formData.append('csvFile', csvFile, `${csvFile.name}.csv`);
      formData.append('shapeFiles', zipFile, `${zipFile.name}.zip`);
      postLogisticsResourceTrigger({
        body: formData,
      });
    } else {
      showError(defaultError);
    }
  }, [postLogisticsResourceTrigger, csvFile, appUserId, zipFile]);

  useEffect(() => {
    if (dataPostLogisticsResource) {
      const logisticTaskId = dataPostLogisticsResource?.data?.logisticTaskId;
      if (logisticTaskId) {
        navigate(`/logistics/short-path/${logisticTaskId}/main`);
      }
    }
  }, [dataPostLogisticsResource]);

  return (
    <Box className={style.companyLogisticsPanel}>
      <Accordion sx={{ p: 1, width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "18px" }}>
            Нахождение кратчайшего пути между указанными точками на ограниченной территории
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoadingPostLogisticsResource ? <Loading /> : (
            <Box className={style.formContainer}>
              <Box className={style.formItem}>
                <Typography variant="body1" component="div" sx={{ display: 'flex' }}>
                  Файл со списком контрольных точек (формат CSV)
                </Typography>
                <Box className={style.formItemFile}>
                  <input
                    ref={fileCsvFileRef}
                    accept=".csv"
                    style={{ display: 'none' }}
                    id="raised-button-file2"
                    multiple
                    type="file"
                    onChange={handleCsvFileChange}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="raised-button-file2">
                    <Tooltip title="Точки маршрута." placement="right" arrow>
                      <Button
                        onClick={handleCsvFileClick}
                        variant={csvFile ? "contained" : "outlined"}
                        component="button"
                        sx={{ minWidth: '210px' }}
                      >
                        {csvFile ? "Изменить файл" : "Выбрать файл"}
                      </Button>
                    </Tooltip>
                  </label>
                  {csvFile?.name && <FileName nameFile={csvFile?.name} /> }
                </Box>
              </Box>
              <Box className={style.formItem}>
                <Typography variant="body1" component="div" sx={{ display: 'flex' }}>
                  Файл с периметром территории (формат ArcGIS, zip)
                </Typography>
                <Box className={style.formItemFile}>
                  <input
                    ref={fileZipFileRef}
                    accept=".zip"
                    style={{ display: 'none' }}
                    id="raised-button-file3"
                    multiple
                    type="file"
                    onChange={handleZipFileChange}
                  />
                  <label htmlFor="raised-button-file3">
                    <Tooltip title="Массив файлов" placement="right" arrow>
                      <Button
                        onClick={handleZipFileClick}
                        variant={zipFile ? "contained" : "outlined"}
                        component="button"
                        sx={{ minWidth: '210px' }}
                      >
                        {zipFile ? "Изменить файл" : "Выбрать файл"}
                      </Button>
                    </Tooltip>
                  </label>
                  {zipFile?.name && <FileName nameFile={zipFile?.name} /> }
                </Box>
              </Box>
              {isErrorPostLogisticsResource && (
                <Alert sx={{ width: "100%" }} severity="error">
                  <AlertTitle>{t('error')}</AlertTitle>
                  {getErrorTextFromCodeRequest(errorPostLogisticsResource?.data)}
                </Alert>
              ) }
              {isSuccessPostLogisticsResource && (
                <Alert sx={{ width: "100%" }} severity="success">
                  <AlertTitle>Успешно</AlertTitle>
                  Данные успешно сохранены
                </Alert>
              ) }
              <Box sx={{ marginLeft: "auto" }}>
                <Button
                  onClick={handleFormButtonClick}
                  disabled={!csvFile || !zipFile}
                  variant="contained"
                  component="button"
                >
                  Создать задачу
                </Button>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>

  );
}

export function FileName({ nameFile = "" }) {
  return (
    <Typography
      className={style.ellipsis}
      variant="p"
      component="h4"
      sx={{ color: 'text.secondary' }}
    >
      Файл:
      {' '}
      {nameFile}
    </Typography>
  );
}

FileName.propTypes = {
  nameFile: PropTypes.string.isRequired,
};

export default CompanyLogisticsPanel;
