function convertDurationToSeconds(duration) {
  if (duration) {
    const regex = /PT(?:(-?\d+(?:\.\d+)?H)?(-?\d+(?:\.\d+)?M)?(-?\d+(?:\.\d+)?S)?)?/;
    const matches = duration.match(regex);

    const hours = matches[1] ? parseInt(matches[1], 10) : 0;
    const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
    const seconds = matches[3] ? parseInt(matches[3], 10) : 0;

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  return "ERR";
}

export default convertDurationToSeconds;
