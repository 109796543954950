import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const alertsTypesResourceEnum = {
  alertTypeId: "alertTypeId",
  category: "category",
  code: "code"
};
// Инициализация стейта в сторе
const alertsTypesResourceInitialState = {
  [alertsTypesResourceEnum.alertTypeId]: NaN,
  [alertsTypesResourceEnum.category]: null,
  [alertsTypesResourceEnum.code]: null
};
// Типизация стора
export const alertsTypesResourceType = {
  [alertsTypesResourceEnum.alertTypeId]: PropTypes.number,
  [alertsTypesResourceEnum.category]: PropTypes.string,
  [alertsTypesResourceEnum.code]: PropTypes.string
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const alertsTypesResourceSlice = createSlice({
  name: `${reducerPath.alertsTypesResource}/counter`,
  initialState: alertsTypesResourceInitialState,
  reducers: {}
});

export const alertsTypesResourceReducer = alertsTypesResourceSlice.reducer;
