import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import {
  Alert,
  AlertTitle,
  Box, Button, Typography
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTranslation } from 'react-i18next';
import style from './CompanyLogisticPowerBalancePanel.module.css';
import useShowError from "../../../shared/lib/hooks/useShowError";
import {
  usePostLogisticsResourceImportMutation,
} from "../../../entities/logistic/logisticTasks/redux/logisticTasks.api";
import Loading from '../../../shared/ui/Loading/Loading';
import FileName from '../../../shared/ui/FileName/FileName';
import validateFile from '../../../shared/utils/validateFile';
import getErrorMessage from '../../../shared/api/getErrorMessage';
import getErrorCode from '../../../shared/api/getErrorCode';
import ERROR_CODES from '../../../shared/api/ERROR_CODES';

// Текст ошибки при обработке файл/ загрузке и тд
const defaultError = "Ошибка при обработке файла, попробуйте еще раз";
// Текст ошибка при обработке размера файла
const sizeError = "Максимально допустимый размер файла 5 MB";
// Отображает вкладку задачи логистики Power-Balance
function CompanyLogisticPowerBalancePanel() {
  const { i18n, t } = useTranslation('translation');
  const [postLogisticsImport, {
    isLoading: isLoadingPostLogisticsResource,
    isError: isErrorPostLogisticsResource,
    isSuccess: isSuccessPostLogisticsResource,
    data: dataPostLogisticsResource,
    error: errorPostLogisticsResource
  }] = usePostLogisticsResourceImportMutation();

  // Фунция для получения текста ошибки по коду ошиибки запроса
  const getErrorTextFromCodeRequest = useCallback((error) => {
    if (!error) {
      return null;
    }
    switch (error.status) {
      case 400:
        return getErrorMessage(
          getErrorCode(
            error.status,
            error
          ),
          error
        );
      case 403:
        return getErrorMessage(ERROR_CODES.FORBIDDEN, error);
      case 404:
        return getErrorMessage(ERROR_CODES.COMPANY_FOR_LOGISTIC_NOT_FOUND, error);
      default:
        return getErrorMessage(ERROR_CODES.UNKNOWN_ERROR, error);
    }
  }, [i18n?.language]);

  const [exelFile, setExelFile] = useState(null);
  const fileExelFileRef = useRef(null);
  const showError = useShowError();
  const navigate = useNavigate();

  const handleExelFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile?.name?.endsWith('.xlsx')) {
      showError("Допустимый формат только .xlsx, выберите другой файл");
      return null;
    }
    if (!validateFile(selectedFile)) {
      showError(sizeError);
      return null;
    }
    if (selectedFile) {
      setExelFile(selectedFile);
    } else {
      showError(defaultError);
      return null;
    }
    return selectedFile;
  }, [showError, setExelFile]);

  const handleCsvFileClick = useCallback(() => {
    if (fileExelFileRef.current.click) {
      fileExelFileRef.current.click();
    } else {
      showError(defaultError);
    }
  }, [fileExelFileRef]);

  // Обработка нажатия на кнопку "Создать задачу"
  const handleFormButtonClick = useCallback(() => {
    if (exelFile) {
      const formData = new FormData();
      formData.append('conditionsFile', exelFile, `${exelFile.name}.xlsx`);
      postLogisticsImport({
        body: formData,
        taskType: 'balance-capacity'
      })
        .then((res) => {
          if (res?.data?.data) {
            navigate(`/logistics/power-balance/${res.data.data.logisticTaskId}/main`);
            return;
          }
          if (res?.error?.data) {
            showError(res?.error?.data.errorMessage || 'Ошибка при загрузке файла');
          }
        });
    } else {
      showError(defaultError);
    }
  }, [postLogisticsImport, exelFile]);

  useEffect(() => {
    if (dataPostLogisticsResource) {
      const { logisticTaskId } = dataPostLogisticsResource;
      if (logisticTaskId) {
        navigate(`/logistics/power-balancing/${logisticTaskId}/main`);
      }
    }
  }, [dataPostLogisticsResource]);

  return (
    <Box className={style.companyLogisticsPanel}>
      <Accordion sx={{ p: 1, width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "18px" }}>
            Динамическая балансировка мощностей
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoadingPostLogisticsResource ? <Loading /> : (
            <Box className={style.formContainer}>
              <Box className={style.formItem}>
                <Typography variant="body1" component="div" sx={{ display: 'flex' }}>
                  Описание параметров производства, складирования
                  и транспортных ресурсов (формат XLSX)
                </Typography>
                <Box className={style.formItemFile}>
                  <input
                    ref={fileExelFileRef}
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id="raised-button-file2"
                    multiple
                    type="file"
                    onChange={handleExelFileChange}
                  />
                  <label htmlFor="raised-button-file2">
                    <Button
                      onClick={handleCsvFileClick}
                      variant={exelFile ? "contained" : "outlined"}
                      component="button"
                      sx={{ minWidth: '210px' }}
                    >
                      {exelFile ? "Изменить файл" : "Выбрать файл"}
                    </Button>
                  </label>
                  {exelFile?.name && <FileName nameFile={exelFile?.name} /> }
                </Box>
              </Box>
              {isErrorPostLogisticsResource && (
              <Alert sx={{ width: "100%" }} severity="error">
                <AlertTitle>{t('error')}</AlertTitle>
                {getErrorTextFromCodeRequest(errorPostLogisticsResource?.data)}
              </Alert>
              ) }
              {isSuccessPostLogisticsResource ? dataPostLogisticsResource?.logisticTaskId ? (
                <Alert sx={{ width: "100%" }} severity="success">
                  <AlertTitle>Успешно</AlertTitle>
                  Данные успешно сохранены
                </Alert>
              ) : (
                <Alert sx={{ width: "100%" }} severity="error">
                  <AlertTitle>{t('error')}</AlertTitle>
                  Данные успешно сохранены
                </Alert>
              ) : null }
              <Box sx={{ marginLeft: "auto" }}>
                <Button
                  onClick={handleFormButtonClick}
                  disabled={!exelFile}
                  variant="contained"
                  component="button"
                >
                  Создать задачу
                </Button>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default CompanyLogisticPowerBalancePanel;
