import {
  Alert, AlertTitle, Autocomplete,
  Box, Button, FormControl,
  InputLabel, MenuItem, Select, TextField, Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { shape } from "prop-types";
import style from "../../panels/account/ChangePasswordPanel/ChangePasswordPanel.module.css";
import {
  alertsSettingsResourceType
} from "../../entities/alerts/alertsSettingsResource/redux/alertsSettingsResource.slice";
import {
  alertsChannelsResourceType
} from "../../entities/alerts/alertsChannelsResource/redux/alertsChannelsResource.slice";
// eslint-disable-next-line import/no-named-as-default,boundaries/element-types
import convertToISOTime from "../../shared/utils/convertToISOTime";
import {
  typeMessages,
  typeSeverity
} from "../../entities/alerts/alertsSettingsResource/services/data/alertsSettingsResource-from-dto.service";
import checkFormatTime from "../../shared/utils/checkFormatTime";
import { alertsTypesResourceType } from "../../entities/alerts/alertsTypesResource/redux/alertsTypesResource.slice";
import { divisionsResourceType } from "../../entities/divisions/divisionsResource/redux/divisionsResource.slice";
import { getRuCategory, getUniqueCategories } from "./CreateAlertsSettingsWidget";
import { statuses } from "../../entities/alerts/alertsResource/services/data/alertsResource-enums";

// Виджет редактирования "настройки сообщений"
function EditAlertsSettingsWidget({
  goBack, data, putAlertsSettingsResourceParams
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [severity, setSeverity] = useState({}); // Серьезность
  const [dataAlertsTypesResource, setDataAlertsTypesResource] = useState([]); // Категория сообщений
  const [repeatDuration, setRepeatDuration] = useState('0ч 0мин 0сек');// Интервал повторения
  const [category, setCategory] = useState('');// Категория сообщений
  const [code, setCode] = useState('');// Тип сообщения
  const [channel, setChannel] = useState({});// Информационный канал
  const [dataAlertsChannels, setDataAlertsChannels] = useState(null);// Информационный канал
  const [dataDivisionsResource, setDataDivisionsResource] = useState([]);// Дивизионы
  const [divisionId, setDivisionId] = useState(null);// Дивизион для отправкий канал
  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const defaultStates = useCallback((dataAlertsSettingsResourceIdCurrent) => {
    if (dataAlertsSettingsResourceIdCurrent.severity) {
      setSeverity(dataAlertsSettingsResourceIdCurrent.severity);
    } else {
      setSeverity(typeSeverity.find((e) => e.id === statuses.ALL));
    }
    setRepeatDuration(dataAlertsSettingsResourceIdCurrent.repeatDuration);
    if (dataAlertsSettingsResourceIdCurrent.category) {
      setCategory(dataAlertsSettingsResourceIdCurrent.category);
    } else {
      setCategory(statuses.ALL);
    }
    setCode(dataAlertsSettingsResourceIdCurrent.code);
    setChannel(dataAlertsSettingsResourceIdCurrent?.channel);
    setDivisionId(dataAlertsSettingsResourceIdCurrent.division);
  }, []);

  useEffect(() => {
    const dataAlertsSettingsResourceIdCurrent = data?.dataAlertsSettingsResourceId?.data;
    const dataAlertsChannelsCurrent = data?.dataAlertsChannels?.data;
    const dataAlertsTypesResourceCurrent = data?.dataAlertsTypesResource?.data;
    const dataDivisionsResourceCurrent = data?.dataDivisionsResource?.data;
    if (dataAlertsSettingsResourceIdCurrent) {
      defaultStates(dataAlertsSettingsResourceIdCurrent);
    }
    if (dataAlertsChannelsCurrent) {
      setDataAlertsChannels(dataAlertsChannelsCurrent);
    }
    if (dataAlertsTypesResourceCurrent) {
      const all = {
        alertTypeId: statuses.ALL,
        category: statuses.ALL,
        code: statuses.ALL
      };
      setDataAlertsTypesResource([all, ...dataAlertsTypesResourceCurrent]);
    }
    if (dataDivisionsResourceCurrent) {
      setDataDivisionsResource(dataDivisionsResourceCurrent);
    }
  }, [data]);

  const handleSave = useCallback(() => {
    if (checkFormatTime(repeatDuration)) {
      const newObject = {
        ...data?.dataAlertsSettingsResourceId?.data
      };
      const time = repeatDuration;
      if (severity?.id && (severity?.id !== statuses.ALL)) {
        newObject.severity = severity.id;
      } else {
        delete newObject.severity;
      }
      newObject.repeatDuration = convertToISOTime(time);
      if (category !== statuses.ALL) {
        newObject.category = category;
      } else {
        delete newObject.category;
      }
      newObject.code = code?.id;
      newObject.division = divisionId;
      newObject.channel = channel;
      putAlertsSettingsResourceParams(
        { id: newObject.alertSettingId, body: newObject }
      ).then((e) => {
        if (e?.error) {
          setErr(true);
        } else {
          setIsCorrect(true);
          setIsEditing(false);
          setErr(false);
        }
      });
    }
  }, [severity, repeatDuration, category, code, channel, divisionId]);

  return (
    <Box
      sx={{
        display: "flex", marginBottom: "20px", marginTop: '20px', gap: "20px", flexDirection: 'column'
      }}
    >
      <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
        Редактирование правила отправки сообщения
      </Typography>
      <Box sx={{
        display: "flex", gap: "30px", flexWrap: "wrap", maxWidth: "350px", marginBottom: "30px"
      }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessage-label">Серьезность</InputLabel>
              <Select
                disabled={!isEditing}
                labelId="typeMessage-label"
                id="typeMessage-label"
                value={severity?.id || 0}
                label="Серьезность"
                onChange={((e) => {
                  setSeverity(typeSeverity
                    .filter((item) => e.target.value === item.id)[0]);
                })}
              >
                {typeSeverity?.map((typeSeverityItem) => (
                  <MenuItem
                    key={`alertChannelId-select-item${typeSeverityItem.id}`}
                    value={typeSeverityItem.id}
                  >
                    {typeSeverityItem.ruName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disabled={!isEditing}
            fullWidth
            size="small"
            autoComplete
            value={divisionId?.name || null}
            onChange={(event, newValue) => {
              setDivisionId(newValue);
            }}
            id="divisionId"
            options={dataDivisionsResource}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderInput={(params) => <TextField {...params} label="Подразделение" />}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <TextField
            disabled={!isEditing}
            sx={{ width: "100%" }}
            value={repeatDuration || ''}
            error={!checkFormatTime(repeatDuration || '')}
            onChange={(e) => {
              setRepeatDuration(e.target.value);
            }}
            label="Интервал повторения"
            variant="standard"
            helperText={!checkFormatTime(repeatDuration || '') && "Корректный формат: 0ч 0мин 0сек"}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="category-label">Категория сообщений</InputLabel>
            <Select
              disabled={!isEditing}
              labelId="category-label"
              id="category-label"
              value={category || 0}
              label="Категория сообщений"
              onChange={(e) => {
                setCategory(e.target.value);
                setCode('');
              }}
            >
              {getUniqueCategories(dataAlertsTypesResource)?.map((alertsType, i) => (
                <MenuItem
                  key={`alertsType-select-item${alertsType}-${i + 1}`}
                  value={alertsType}
                >
                  {getRuCategory(alertsType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {category && (category !== statuses.ALL) && (
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessage-label">Тип сообщения</InputLabel>
              <Select
                disabled={!isEditing}
                labelId="typeMessage-label"
                id="typeMessage-label"
                value={code?.id || 0}
                label="Тип сообщения"
                onChange={((e) => {
                  const newCode = typeMessages.find((item) => e.target.value === item.id);
                  const unknownCode = { id: e.target.value, ruName: e.target.value };
                  setCode(newCode || unknownCode);
                })}
              >
                {dataAlertsTypesResource
                  ?.filter((e) => e.category === category).map((typeMessage, i) => (
                    <MenuItem
                      key={`typeMessage-select-item${typeMessage.code}-${i + 1}`}
                      value={typeMessage.code}
                    >
                      {typeMessages.find((item) => item.id === typeMessage.code)?.ruName
                      || typeMessage.code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="typeMessage-label">Информационный канал</InputLabel>
            <Select
              disabled={!isEditing}
              labelId="typeMessage-label"
              id="typeMessage-label"
              value={channel?.alertChannelId || 0}
              label="Информационный канал"
              onChange={((e) => {
                setChannel(dataAlertsChannels
                  .filter((item) => e.target.value === item.alertChannelId)[0]);
              })}
            >
              {dataAlertsChannels?.map((adapterType) => (
                <MenuItem
                  key={`alertChannelId-select-item${adapterType.alertChannelId}`}
                  value={adapterType.alertChannelId}
                >
                  {adapterType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Данные успешно сохранены
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}
      <Box className={style.buttonsPanel}>
        {isEditing && (
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!(
              checkFormatTime(repeatDuration)
                && channel?.alertChannelId)}
          >
            Сохранить
          </Button>
        )}
        {!isEditing
            && (
            <Button
              onClick={() => { setIsEditing(true); }}
              variant="contained"
            >
              Редактировать
            </Button>
            )}
        {isEditing && (
          <Button
            onClick={() => {
              const dataAlertsSettingsResourceIdCurrent = data?.dataAlertsSettingsResourceId?.data;
              if (dataAlertsSettingsResourceIdCurrent) {
                defaultStates(dataAlertsSettingsResourceIdCurrent);
              }
              setIsEditing(false);
            }}
            variant="contained"
          >
            Отмена
          </Button>
        )}
        {!isEditing && (
          <Button
            onClick={goBack}
            variant="contained"
          >
            Назад
          </Button>
        )}
      </Box>
    </Box>
  );
}

EditAlertsSettingsWidget.propTypes = {
  data: PropTypes.shape({
    dataAlertsSettingsResourceId: shape({
      data: PropTypes.shape(alertsSettingsResourceType)
    }),
    dataAlertsChannels: shape({
      data: PropTypes.shape(alertsChannelsResourceType)
    }),
    dataAlertsTypesResource: shape({
      data: PropTypes.arrayOf(PropTypes.shape(alertsTypesResourceType))
    }),
    dataDivisionsResource: shape({
      data: PropTypes.arrayOf(PropTypes.shape(divisionsResourceType))
    }),
  }),
  goBack: PropTypes.func,
  putAlertsSettingsResourceParams: PropTypes.func
};

EditAlertsSettingsWidget.defaultProps = {
  goBack: null,
  putAlertsSettingsResourceParams: null,
  data: null
};

export default EditAlertsSettingsWidget;
