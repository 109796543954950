import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import style from './PendingData.module.css';

function PendingData() {
  return (
    <Box className={style.pending}>
      <CircularProgress />
    </Box>
  );
}

export default PendingData;
