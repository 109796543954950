import PropTypes from 'prop-types';
// тут описываем тип то что приходит с бека в сыром виде
const companyCargosDtoType = {
  compCargoId: PropTypes.number,
  cargoType: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.string,
  comment: PropTypes.string,
  token: PropTypes.string,
  isRegular: PropTypes.bool,
  lastStatus: PropTypes.string,
  startDeliveryTime: PropTypes.string,
  finishDeliveryTime: PropTypes.string,
  cargoLogin: PropTypes.string,
  observerLogin: PropTypes.string,
  cargoMail: PropTypes.string,
  observerMail: PropTypes.string,
  customAugmentation: PropTypes.shape({
    attrs: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  }),
  company: PropTypes.shape({
    companyId: PropTypes.number
  }),
  cargoUser: PropTypes.shape({
    appUserId: PropTypes.number,
    email: PropTypes.string,
    login: PropTypes.string,
    password: PropTypes.string,
    company: PropTypes.shape({
      companyId: PropTypes.number,
      name: PropTypes.string,
      site: PropTypes.string,
      address: PropTypes.string,
      tenantId: PropTypes.string
    })
  }),
  observerUser: PropTypes.shape({
    appUserId: PropTypes.number,
    email: PropTypes.string,
    login: PropTypes.string,
    password: PropTypes.string,
    customAugmentation: PropTypes.shape({
      customAugId: PropTypes.number,
      attrs: PropTypes.arrayOf(
        PropTypes.shape({
          customAugAttrId: PropTypes.number,
          group: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.string
        })
      )
    }),
    company: PropTypes.shape({
      companyId: PropTypes.number,
      name: PropTypes.string,
      site: PropTypes.string,
      address: PropTypes.string,
      tenantId: PropTypes.string
    })
  }),
  regular: PropTypes.bool
};

export default companyCargosDtoType;
