import React from 'react';
import {
  Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

function EnhancedTableHead(props) {
  const {
    // eslint-disable-next-line react/prop-types
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isControls,
    isCustomControls,
    disableCheckbox
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!isControls && !disableCheckbox
          ? (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
          )
          : null}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.style}
          >
            {headCell.disableSort
              ? headCell.label
              : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
          </TableCell>
        ))}
        {isControls
          ? (
            <TableCell />
          )
          : null}
        {isCustomControls
          ? (
            <TableCell
              sx={{
                padding: '0px',
              }}
            />
          )
          : null}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isControls: PropTypes.bool,
  isCustomControls: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  isControls: false,
  isCustomControls: false,
  disableCheckbox: false,
};

export default EnhancedTableHead;
