import {
  Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';
import React from 'react';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import GroupIcon from '@mui/icons-material/Group';
import RouteIcon from '@mui/icons-material/Route';
import Person2Icon from '@mui/icons-material/Person2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// eslint-disable-next-line boundaries/element-types
import useAuth from '../../shared/lib/hooks/useAuth';

export const drawerWidth = 240;

const LinkRouter = React.forwardRef((itemProps, ref) => <NavLink {...itemProps} ref={ref} />);

// Отображает выезжающее меню слева (при клике на иконку в шапке)
function MainMenu({ open, handleDrawer, handleClickOpen }) {
  const { user } = useAuth();
  return (
    <Drawer
      open={open}
      onClose={handleDrawer}
      sx={{ zIndex: 2000 }}
    >
      <Box
        sx={{ width: drawerWidth }}
      >
        <List>
          <ListItemButton
            component={LinkRouter}
            to="companies"
            onClick={() => handleDrawer()}
          >
            <ListItemIcon>
              <RouteIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={user?.authorities.includes("ROLE_ADMIN") ? 'Компании' : 'Компания'} />
          </ListItemButton>
          {user?.authorities.includes("ROLE_ADMIN")
            ? (
              <ListItemButton
                component={LinkRouter}
                to="finances"
                onClick={() => handleDrawer()}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Финансы" />
              </ListItemButton>
            )
            : null}
          {user?.authorities.includes("ROLE_COMPANY_ADMIN")
            ? (
              <ListItemButton
                component={LinkRouter}
                to="company-finances"
                onClick={() => handleDrawer()}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Финансы" />
              </ListItemButton>
            )
            : null}
          {user?.authorities.includes("ROLE_ADMIN")
            ? (
              <ListItemButton
                component={LinkRouter}
                to="users"
                onClick={() => handleDrawer()}
              >
                <ListItemIcon>
                  <GroupIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Пользователи" />
              </ListItemButton>
            )
            : null}
          <Divider />
          <ListItemButton
            component={LinkRouter}
            to="account/info"
            onClick={() => handleDrawer()}
          >
            <ListItemIcon>
              <Person2Icon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Профиль" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleDrawer();
              handleClickOpen();
            }}
          >
            <ListItemIcon>
              <FeedbackIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Обратная связь" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
}
MainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
};

export default MainMenu;
