import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

// Кнопка удаления с иконкой и подписью
function DeleteButton({
  onDelete, name, disabled, title, ...other
}) {
  return (
    <Box>
      <Tooltip title={title || `Удалить ${name}`.trim()}>
        <span>
          <IconButton
            color="primary"
            {...other}
            disabled={disabled}
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

DeleteButton.defaultProps = {
  name: '',
  title: '',
  disabled: false,
};

export default DeleteButton;
