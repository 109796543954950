// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import alertsResourceDtoType from "../../types/alertsResource-dto.type";
import transformAlert from './transformAlert';

function sortByDate(dotaArray) {
  dotaArray.sort((a, b) => {
    // Преобразование дат в объекты типа Date
    const dateA = new Date(a.beginTime);
    const dateB = new Date(b.beginTime);

    // Сравнение дат
    if (dateA < dateB) {
      return 1; // Менять местами
    } if (dateA > dateB) {
      return -1; // Не менять местами
    }
    return 0; // Сохранить текущий порядок
  });

  return dotaArray;
}

export function alertsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const newData = sortByDate(dto.map((e) => transformAlert(e)));
  return { data: newData, status };
}

alertsResourceFromDtoService.propTypes = {
  dto: alertsResourceDtoType,
};

export default alertsResourceFromDtoService;
