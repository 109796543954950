import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const sensorsSourcesResourceEnum = {
  sensorSourceId: "sensorSourceId",
  source: "source",
  comment: "comment"
};
// Инициализация стейта в сторе
const sensorsSourcesResourceInitialState = {
  [sensorsSourcesResourceEnum.sensorSourceId]: NaN,
  [sensorsSourcesResourceEnum.source]: null,
  [sensorsSourcesResourceEnum.comment]: null,
};
// Типизация стора
export const sensorsResourceType = {
  [sensorsSourcesResourceEnum.sensorSourceId]: PropTypes.number,
  [sensorsSourcesResourceEnum.source]: PropTypes.string,
  [sensorsSourcesResourceEnum.comment]: PropTypes.string,
};

// Создание слайса тут пишем инициализированный стейт и редюсеры
export const sensorsSourcesResourceSlice = createSlice({
  name: `${reducerPath.sensorSourceResource}/counter`,
  initialState: sensorsSourcesResourceInitialState,
  reducers: {}
});

export const sensorsSourcesResourceReducer = sensorsSourcesResourceSlice.reducer;
