// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import alertsSettingsResourceFromDtoService, {
  alertsSettingsResourceFromDtoServiceObject
} from "../services/data/alertsSettingsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const alertsSettingsResourceApi = createApi({
  reducerPath: `${reducerPath.alertsSettingsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/alert-settings`),
  endpoints: (builder) => ({
    getAlertsSettingsResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: alertsSettingsResourceFromDtoService
    }),
    getAlertsSettingsResourceId: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: alertsSettingsResourceFromDtoServiceObject
    }),
    putAlertsSettingsResource: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PUT
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: alertsSettingsResourceFromDtoServiceObject
    }),
    postAlertsSettingsResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: alertsSettingsResourceFromDtoServiceObject
    }),
    deleteAlertsSettingsResource: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.DELETE
        }),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
  }),
});

export const {
  useLazyGetAlertsSettingsResourceQuery,
  useGetAlertsSettingsResourceQuery,
  useGetAlertsSettingsResourceIdQuery,
  usePutAlertsSettingsResourceMutation,
  usePostAlertsSettingsResourceMutation,
  useDeleteAlertsSettingsResourceMutation,
} = alertsSettingsResourceApi;
