import * as React from 'react';
import Box from '@mui/material/Box';
import { Alert, Button, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import HarvesterIcon from '../../../assets/icons/HarvesterIcon';
import TruckIcon from '../../../assets/icons/TruckIcon';
import BlockItem from './BlockItem';
import ItemBox from './ItemBox';
import NumberMenuBtn from './NumberMenuBtn';
import WarehouseItem from './WarehouseItem';
import FieldItem from './FieldItem.jsx';
import {
  BLOCK_MIN_WIDTH,
  COMMON_PADDING,
  PANEL_COLOR,
  ROUTE_BLOCK_HEIGHT,
  ROUTE_ICO_COLOR,
  WARNING_SOLUTION,
} from './constants';
import { useAppSelector } from '../../../app/store.js';
import reducerPath from '../../../app/reducerPath.js';
import AlertsDialog from './AlertsDialog.jsx';

export default function PowerBalancingMainWidget() {
  const {
    data: { source },
    solution,
  } = useAppSelector((state) => state[`${reducerPath.logisticTasksBalanceResource}/counter`]);
  const addVehicleId = (items) => items.map((item) => ({ ...item, id: item.regNum }));
  const {
    fields,
    warehouses,
    warehouseQueue,
    warehouseUnloading,
    harvesters,
    vehicles,
  } = React.useMemo(() => {
    const workVehicles = [];
    const availableVehicles = source?.truckDetailsList?.filter((truck) => (
      !(Object.values(solution?.fields || {})
        .some(({ routes }) => Object.values(routes || {})
          .some(({ vehicles: vs }) => {
            if (!vs?.some((v) => truck.regNum === v.regNum)) {
              return false;
            }
            // Добавляем ТС в находящиеся в работе, чтобы отдельно не повторять вложенный цикл
            workVehicles.push(truck);
            return true;
          }))
      )));
    const workHarvesters = [];
    const availableHarvesters = source?.harvesterDetailsList?.filter((hv) => (
      !(Object.values(solution?.fields || {})
        .some(({ harvesters: hvs }) => {
          if (!hvs?.some((h) => hv.regNum === h.regNum)) {
            return false;
          }
          // Добавляем ТС в находящиеся в работе, чтобы отдельно не повторять вложенный цикл
          workHarvesters.push(hv);
          return true;
        }))
    ));
    return ({
      fields: source?.fieldDetailsList.map(({
        fieldName,
        square,
        geozoneName,
        ...rest
      }) => {
        const fieldId = `${fieldName}_${geozoneName}`;
        return {
          fieldName,
          id: fieldId,
          bgColor: '#bbdf93',
          area: `${square} га`,
          square,
          geozoneName,
          harvesters: [],
          routes: solution.fields?.[fieldId]?.routes,
          ...rest,
        };
      }) || [],
      warehouses: source?.warehouseDetailsList.map(({
        name,
        geozoneUnload,
        ...rest
      }) => ({
        name,
        id: `${name}_${geozoneUnload}`,
        geozoneUnload,
        vehicles: undefined,
        bgColor: '#ebe4a7',
        ...rest,
      })) || [],
      harvesters: {
        total: addVehicleId(source?.harvesterDetailsList || []),
        available: addVehicleId(availableHarvesters || []),
        work: addVehicleId(workHarvesters || []),
      },
      vehicles: {
        total: addVehicleId(source?.truckDetailsList || []),
        available: addVehicleId(availableVehicles || []),
        work: addVehicleId(workVehicles || []),
      },
      warehouseQueue: undefined,
      warehouseUnloading: undefined,
    });
  }, [source, solution]);

  const warnings = React.useMemo(() => [
    {
      id: 1,
      title: 'Не хватает комбайнов на полях',
      description: 'Чтобы решить проблему, выберите одну из кнопок',
      solution: [
        { type: WARNING_SOLUTION.SOLVE, label: 'Распределить свободные комбайны' },
        { type: WARNING_SOLUTION.MAKE_OK, label: 'Остановить уборку' },
        { type: WARNING_SOLUTION.IGNORE, label: 'Игнорировать' },
      ]
    },
    {
      id: 2,
      title: 'Не хватает ТС',
      description: 'Чтобы решить проблему, выберите одну из кнопок',
      solution: [
        { type: WARNING_SOLUTION.SOLVE, label: 'Распределить свободные ТС' },
        { type: WARNING_SOLUTION.IGNORE, label: 'Игнорировать' },
      ]
    },
  ], []);

  const [showDetailed, setShowDetailed] = React.useState(false);
  const [showAlerts, setShowAlerts] = React.useState(false);

  const mainPanelWidth = React.useMemo(() => {
    const routesNum = fields.reduce((acc, f) => acc + (f.routes?.length || 1), 0);
    return routesNum * (BLOCK_MIN_WIDTH + COMMON_PADDING)
      + (showDetailed ? 4 : 1) * BLOCK_MIN_WIDTH + COMMON_PADDING;
  }, [fields, showDetailed]);

  const displayAlerts = () => {
    setShowAlerts(true);
  };

  const hideAlerts = () => {
    setShowAlerts(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex', alignItems: 'flex-end', gap: `${COMMON_PADDING}px`, minWidth: `${mainPanelWidth}px`
        }}
        mb={`${COMMON_PADDING}px`}
      >
        <Box sx={{ width: `${showDetailed ? 4 * BLOCK_MIN_WIDTH : BLOCK_MIN_WIDTH}px`, transition: 'width 0.3s ease-out' }}>
          <IconButton sx={{ margin: '0 3px' }} title={showDetailed ? 'Компактнее' : 'Подробнее'} onClick={() => setShowDetailed(!showDetailed)}>
            <KeyboardTabIcon sx={{
              transform: showDetailed ? 'scale(-1, 1)' : 'scale(1, 1)',
              color: (theme) => theme.palette.primary.main,
              transition: 'transform 0.3s',
            }}
            />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {!!warnings?.length && (
            <Alert
              severity="warning"
              sx={{ borderRadius: '4px' }}
              action={(
                <Button color="inherit" size="small" onClick={displayAlerts}>
                  Подробнее
                </Button>
              )}
            >
              Проблем, требующих решения:
              {' '}
              {warnings?.length}
            </Alert>
          )}
        </Box>
      </Box>
      <Box
        elevation={1}
        sx={{ display: 'flex' }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: `${COMMON_PADDING}px`, flexGrow: 1, maxWidth: '100%'
        }}
        >
          <Box sx={{ display: 'flex', maxWidth: '100%' }}>
            <Box>
              <BlockItem label="Производство" icon={<HarvesterIcon sx={{ width: '29px', height: '29px', color: ROUTE_ICO_COLOR }} />} detailed={showDetailed}>
                <NumberMenuBtn type="harvester" color="#8e8e8e" items={harvesters.total} label="всего" detailed={showDetailed} captionLabel="Все единицы" />
                <NumberMenuBtn type="harvester" color="text" items={harvesters.work} label="в работе" detailed={showDetailed} captionLabel="Единицы в работе" />
                <NumberMenuBtn type="harvester" color="error" items={harvesters.maintenance} label="в ремонте" detailed={showDetailed} captionLabel="Единицы в ремонте" />
                <NumberMenuBtn type="harvester" color="#6aa02a" items={harvesters.available} label="доступно" detailed={showDetailed} captionLabel="Доступные единицы" />
              </BlockItem>
              <BlockItem label="TC" sx={{ mt: `${COMMON_PADDING}px`, height: `${ROUTE_BLOCK_HEIGHT + 2 * COMMON_PADDING}px` }} detailed={showDetailed} icon={<TruckIcon sx={{ width: '30px', height: '30px', color: ROUTE_ICO_COLOR }} />}>
                <NumberMenuBtn type="vehicle" color="#8e8e8e" items={vehicles.total} label="всего" detailed={showDetailed} captionLabel="Все TC" />
                <NumberMenuBtn type="vehicle" color="text" items={vehicles.work} label="в работе" detailed={showDetailed} captionLabel="ТС в работе" />
                <NumberMenuBtn type="vehicle" color="error" items={vehicles.maintenance} label="в ремонте" detailed={showDetailed} captionLabel="ТС в ремонте" />
                <NumberMenuBtn type="vehicle" color="#6aa02a" items={vehicles.available} label="доступно" detailed={showDetailed} captionLabel="Доступные ТС" />
              </BlockItem>
            </Box>
            {fields.length ? fields.map(({ routes, ...fld }, i) => {
              const routeEntries = Object.entries(routes || {});
              const solutionField = solution?.fields?.[fld.id];
              const routesArr = routeEntries?.map(([warehouseId, routeData]) => ({
                id: warehouseId,
                warehouseId,
                warehouseIndex: warehouses.findIndex(({ id }) => id === warehouseId) || 0,
                ...routeData,
              })) || [];
              return (
                <ItemBox key={fld.id} routes={routesArr}>
                  <FieldItem
                    latest={i === fields.length - 1}
                    availableHarvesters={harvesters.available}
                    warehouses={warehouses}
                    availableVehicles={vehicles.available}
                    routes={routesArr}
                    {...fld}
                    harvesters={solutionField?.harvesters}
                  />
                </ItemBox>
              );
            }) : (
              <ItemBox><FieldItem id="_" latest /></ItemBox>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex', backgroundColor: PANEL_COLOR, borderRadius: '8px', minWidth: `${mainPanelWidth}px`
            }}
            wrap="nowrap"
          >
            <Box>
              <BlockItem label="Склады" sx={{ height: '100%' }} icon={<WarehouseIcon sx={{ width: '29px', height: '29px', color: ROUTE_ICO_COLOR }} />} detailed={showDetailed}>
                <NumberMenuBtn type="vehicle" color="text" items={warehouseUnloading} label="на разгрузке" detailed={showDetailed} captionLabel="ТС на разгрузке" />
                <NumberMenuBtn type="vehicle" color="error" items={warehouseQueue} label="в очереди" detailed={showDetailed} captionLabel="ТС в очереди" />
              </BlockItem>
            </Box>
            <Box
              sx={{
                display: 'flex', flexDirection: 'column', width: '100%', maxWidth: `calc(100% - ${(showDetailed ? 4 : 1) * BLOCK_MIN_WIDTH + COMMON_PADDING}px)`, gap: `${COMMON_PADDING}px`, transition: 'max-width 0.3s ease-out'
              }}
              py={`${COMMON_PADDING}px`}
              pr={`${COMMON_PADDING}px`}
              pl={`${COMMON_PADDING / 2}px`}
            >
              {warehouses.map(
                (w) => <WarehouseItem key={w.id} warehouse={w} bgColor={w.bgColor} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <AlertsDialog isOpen={showAlerts} alerts={warnings} closeAlertDialog={hideAlerts} />
    </>
  );
}

PowerBalancingMainWidget.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    solution: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    solutionType: PropTypes.arrayOf(PropTypes.string.isRequired),
  })),
  logisticTask: PropTypes.shape({
    data: PropTypes.shape({
      logisticTaskId: PropTypes.number.isRequired,
      companyId: PropTypes.number,
      status: PropTypes.string,
      type: PropTypes.string,
      createdDate: PropTypes.string,
      createdBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      name: PropTypes.string,
      isRunnable: PropTypes.bool,
      isAutostarted: PropTypes.bool,
      startTime: PropTypes.string,
      finishTime: PropTypes.string,
      source: PropTypes.shape({
        fieldDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            fieldName: PropTypes.string,
            geozoneName: PropTypes.string,
            cropType: PropTypes.string,
            square: PropTypes.number,
            cropPlanCapacity: PropTypes.number,
            cropDensity: PropTypes.number,
            harvestingStart: PropTypes.string,
            harvestingFinish: PropTypes.string,
            workdayStart: PropTypes.string,
            workdayFinish: PropTypes.string,
          })
        ),
        harvesterDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            model: PropTypes.string,
            regNum: PropTypes.string,
            volume: PropTypes.number,
            productivity: PropTypes.number,
            availabilityStart: PropTypes.string,
            availabilityFinish: PropTypes.string,
            workdayStart: PropTypes.string,
            workdayFinish: PropTypes.string,
            geozoneName: PropTypes.string,
            cropTypes: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        truckDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            model: PropTypes.string,
            regNum: PropTypes.string,
            bodyVolume: PropTypes.number,
            trailerVolume: PropTypes.number,
            availabilityStart: PropTypes.string,
            availabilityFinish: PropTypes.string,
            workdayStart: PropTypes.string,
            workdayFinish: PropTypes.string,
            geozoneName: PropTypes.string,
            cropTypes: PropTypes.arrayOf(PropTypes.string),
            bodyType: PropTypes.string,
          })
        ),
        warehouseDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            geozoneWait: PropTypes.string,
            geozoneUnload: PropTypes.string,
            queueLength: PropTypes.number,
            unloadSpeed: PropTypes.number,
            dayCropCapacity: PropTypes.number,
            hourCropCapacity: PropTypes.number,
            minUnloadTime: PropTypes.number,
            unloadStart: PropTypes.string,
            unloadFinish: PropTypes.string,
            workdayStart: PropTypes.string,
            workdayFinish: PropTypes.string,
            cropTypes: PropTypes.arrayOf(PropTypes.string),
            vehicleTypes: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        roadNetwork: PropTypes.shape({
          fieldNames: PropTypes.arrayOf(PropTypes.string),
          warehouseNames: PropTypes.arrayOf(PropTypes.string),
          distanceMatrix: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        }),
        balanceCapacityParameters: PropTypes.shape({
          minVolume: PropTypes.number,
          maxVolume: PropTypes.number,
          fieldSnapRadius: PropTypes.number,
          harvesterSnapRadius: PropTypes.number,
          storageSnapRadius: PropTypes.number,
          priorityType: PropTypes.string,
        }),
      }),
      // TODO TBD
      // eslint-disable-next-line react/forbid-prop-types
      solution: PropTypes.any,
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  patchLogisticTask: PropTypes.func,
  patchLogisticTaskSolve: PropTypes.func,
  solveLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  resultPatchLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
};

PowerBalancingMainWidget.defaultProps = {
  warnings: [],
  logisticTask: null,
  solveLogisticTask: null,
  resultPatchLogisticTask: null,
  patchLogisticTask: () => {},
  patchLogisticTaskSolve: () => {},
};
