import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  useRegisterByVkMutation,
} from "../../../entities/vk/vkResource/redux/vkResource.api";
// eslint-disable-next-line import/no-unresolved
import { useAppDispatch } from "../../../app/store";
import useAuth from "../../../shared/lib/hooks/useAuth";
import { authorization, parseVKPayloadFromURL } from "./vkAuthPage";

function VkRegistrationPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { signin } = useAuth();
  const [registerByVKVk, {
    isError: isErrorRegisterByVK,
    isLoading: isLoadingRegisterByVKVK,
    isSuccess: isSuccessRegisterByVK,
    data: dataRegisterByVK,
    error: errorRegisterByVK
  }] = useRegisterByVkMutation();

  // eslint-disable-next-line no-undef
  const paramsFromUrl = parseVKPayloadFromURL(window.location.href);

  const params = {
    token: paramsFromUrl.token,
    uuid: paramsFromUrl.uuid,
    firstName: paramsFromUrl?.user?.first_name,
    lastName: paramsFromUrl?.user?.last_name
  };
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    registerByVKVk(params);
  }, []);

  useEffect(() => {
    if (isErrorRegisterByVK) {
      if (errorRegisterByVK?.data?.status === 403 || errorRegisterByVK.status === 403) {
        setErrorText("Данных в вашем ВК аккаунте недостаточно для того, чтобы зарегистрироваться.");
      } else {
        setErrorText("Зарегистрировать через VK не удалось.");
      }
    }
    if (isSuccessRegisterByVK) {
      authorization(dataRegisterByVK.id_token, dispatch, signin, navigate, setErrorText);
    }
  }, [isErrorRegisterByVK, isSuccessRegisterByVK, dataRegisterByVK, errorRegisterByVK]);

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    }}
    >
      <div>
        {errorText || (
        <>
          {isLoadingRegisterByVKVK ? "Идет регистрация нового пользователя." : null}
          {(!isLoadingRegisterByVKVK) && <CircularProgress />}
        </>
        )}
        {errorText ? (
          <div style={{
            width: "max-content",
            margin: "auto",
            paddingTop: "30px",
          }}
          >
            <Link to="/">
              <Button
                variant="contained"
              >
                Перейти на авторизацию
              </Button>
            </Link>
          </div>
        ) : null}
      </div>

    </div>
  );
}

export default VkRegistrationPage;
