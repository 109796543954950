import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  BLOCK_MIN_WIDTH, COMMON_PADDING, FLD_BLOCK_HEIGHT, PANEL_COLOR
} from './constants';

export default function BlockItem(props) {
  const {
    children, icon, sx, detailed, label, ...other
  } = props;
  return (
    <Box
      sx={{
        backgroundColor: PANEL_COLOR,
        borderRadius: '8px 0 0 8px',
        height: `${FLD_BLOCK_HEIGHT + 2 * COMMON_PADDING}px`,
        width: `${(detailed ? 4 : 1) * BLOCK_MIN_WIDTH + COMMON_PADDING}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'width 0.3s ease-out',
        ...sx,
      }}
      {...other}
      py={`${COMMON_PADDING}px`}
    >
      <Box
        sx={{
          flexGrow: 1, display: 'flex', gap: '6px', width: '100%'
        }}
        pl="13px"
      >
        {icon}
        {detailed && (
        <Typography
          mt="4px"
          sx={{
            flexGrow: 1, fontSize: '1.12rem', whiteSpace: 'nowrap', overflow: 'hidden'
          }}
        >
          {label}
        </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
}

BlockItem.propTypes = {
  icon: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  detailed: PropTypes.bool,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

BlockItem.defaultProps = {
  detailed: false,
  label: '',
  sx: {},
};
