// Фунция для расчета дистанции от точки 1 до точки 2, если точек больше тода
// от точки 1 до точки 2 + точка 2 + точка 3
function calculateDistance(coords) {
  const earthRadius = 6371; // Радиус Земли в километрах

  const calculateDistanceCurrent = (lat1, lon1, lat2, lon2) => {
    const lat1Rad = (lat1 * Math.PI) / 180;
    const lon1Rad = (lon1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;
    const lon2Rad = (lon2 * Math.PI) / 180;

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(lat1Rad)
        * Math.cos(lat2Rad)
        * Math.sin(dLon / 2)
        * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;
    return distance;
  };

  let totalDistance = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < coords.length - 1; i++) {
    const [lat1, lon1] = coords[i];
    const [lat2, lon2] = coords[i + 1];

    const distance = calculateDistanceCurrent(lat1, lon1, lat2, lon2);
    totalDistance += distance;
  }

  return Math.round(totalDistance);
}

export default calculateDistance;
