import {
  Box, Button, Collapse, TextField, Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import style from './TariffPlansFiltersWidget.module.css';

const initialFilters = {
  name: '',
  startDate: null,
  startDateUntil: null,
  finishDate: null,
  finishDateUntil: null,
};

// Отображает поля фильтрации и кнопки применения/сброса фильтров
function TariffPlansFiltersWidget({ isOpenFilters, applyFilters }) {
  const [filters, setFilters] = useState({ ...initialFilters });
  // Изменение в поле ввода даты
  const handleChangeDate = (dateField, newValue) => {
    setFilters((prev) => ({ ...prev, [dateField]: newValue }));
  };
  // Сброс полей фильтров
  const handleResetFilters = () => {
    setFilters({ ...initialFilters });
  };

  return (
    <Collapse in={isOpenFilters}>
      <Box
        className={style.tariffPlansFiltersWidget}
      >
        <Box
          className={style.filters}
        >
          <TextField
            size="small"
            id="name"
            label="Название"
            value={filters.name}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, name: e.target.value }));
            }}
          />
          <Box
            className={style.dates}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary' }}>
                Дата начала
              </Typography>
              <Box className={style.dateTimePeriod}>
                <DatePicker
                  label="C"
                  value={filters.startDate}
                  maxDate={filters.startDateUntil}
                  onChange={(newDate) => {
                    handleChangeDate('startDate', newDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      size="small"
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
                <DatePicker
                  label="По"
                  value={filters.startDateUntil}
                  minDate={filters.startDate}
                  onChange={(newDate) => handleChangeDate('startDateUntil', newDate)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      size="small"
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary' }}>
                Дата окончания
              </Typography>
              <Box className={style.dateTimePeriod}>
                <DatePicker
                  label="C"
                  maxDate={filters.finishDateUntil}
                  value={filters.finishDate}
                  onChange={(newDate) => {
                    handleChangeDate('finishDate', newDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
                <DatePicker
                  label="По"
                  value={filters.finishDateUntil}
                  minDate={filters.finishDate}
                  onChange={(newDate) => handleChangeDate('finishDateUntil', newDate)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={style.filterButtons}>
          <Button
            disableElevation
            onClick={handleResetFilters}
            variant="text"
          >
            Сбросить
          </Button>
          <Button
            disableElevation
            onClick={() => applyFilters(filters)}
            variant="contained"
          >
            Поиск
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
}

TariffPlansFiltersWidget.propTypes = {
  isOpenFilters: PropTypes.bool.isRequired,
  applyFilters: PropTypes.func.isRequired,
};

export default TariffPlansFiltersWidget;
