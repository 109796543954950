import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// eslint-disable-next-line import/prefer-default-export
export const NODES = {
  COMPANIES: {
    NAME: "Компании",
    ICON: PublicIcon,
    TYPE: "COMPANIES"
  },
  COMPANY: {
    NAME: "Company",
    ICON: ApartmentIcon,
    TYPE: "COMPANY",
    VP_NAME: "компанию"
  },
  USER: {
    NAME: "User",
    ICON: PersonIcon,
    TYPE: "USER",
    VP_NAME: "пользователя"
  },
  USERS: {
    NAME: "Пользователи",
    ICON: PeopleAltIcon,
    TYPE: "USERS"
  },
  DIVISION: {
    NAME: "Division",
    ICON: HomeWorkIcon,
    TYPE: "DIVISION",
    VP_NAME: "подразделение"
  },
  DIVISIONS: {
    NAME: "Подразделения",
    ICON: BusinessIcon,
    TYPE: "DIVISIONS"
  },
  VEHICLE: {
    NAME: "Vehicle",
    ICON: DirectionsCarIcon,
    TYPE: "VEHICLE",
    VP_NAME: "ТС"
  },
  VEHICLES: {
    NAME: "Транспортные средства",
    ICON: AirportShuttleIcon,
    TYPE: "VEHICLES"
  },
  PASSENGERS: {
    NAME: "Пассажиры",
    ICON: AirportShuttleIcon,
    TYPE: "PASSENGERS"
  },
  PASSENGER: {
    NAME: "Пассажир",
    ICON: AirportShuttleIcon,
    TYPE: "PASSENGER"
  },
  CARGO: {
    NAME: "Грузы",
    ICON: LocalShippingIcon,
    TYPE: "CARGO"
  },
};
