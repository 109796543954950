import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircle from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';

// Отображает кнопки управления
function DivisionAccessToolbarWidget({ toogleFilters, create }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        paddingRight: '20px'
      }}
    >
      <Tooltip title="Добавить">
        <IconButton
          color="primary"
          onClick={create}
        >
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Фильтры">
        <IconButton
          color="primary"
          onClick={toogleFilters}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

DivisionAccessToolbarWidget.propTypes = {
  toogleFilters: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
};

export default DivisionAccessToolbarWidget;
