import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import {

  useGetAdaptersQuery,
  usePatchAdapterMutation, usePostAdapterMutation
} from "../adaptersResource/redux/adaptersResource.api";
import {
  useDeleteSensorMutation,
  useGetSensorsQuery, usePatchSensorMutation,
  usePostSensorMutation
} from "../sensorsResource/redux/sensorsResource.api";
import { useGetSensorTypesQuery } from "../sensorResourceType/redux/sensorResourceType.api";
import { useGetAdapterTypesQuery } from "../adapterResourceType/redux/adapterResourceType.api";
import { useGetSensorSourcesQuery } from "../sensorsSourcesResource/redux/sensorsSourcesResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function EquipmentBll({ children, vehicleId }) {
  const [data, setData] = useState();

  const { data: dataAdapters } = useGetAdaptersQuery({
    params: {
      'vehicleId.equals': vehicleId
    }
  });
  const { data: dataSensors } = useGetSensorsQuery({
    params: {
      'vehicleId.equals': vehicleId
    }
  });
  const { data: dataAdapterTypes } = useGetAdapterTypesQuery();
  const { data: dataSensorSources } = useGetSensorSourcesQuery({
  });
  const { data: dataSensorTypes } = useGetSensorTypesQuery({});

  useEffect(() => {
    setData({
      dataAdapters, dataSensors, dataAdapterTypes, dataSensorSources, dataSensorTypes
    });
  }, [dataAdapters, dataSensors, dataAdapterTypes, dataSensorSources, dataSensorTypes]);

  const [patchAdapter] = usePatchAdapterMutation();
  const [postAdapter] = usePostAdapterMutation();
  const [deleteSensor] = useDeleteSensorMutation();
  const [postSensor] = usePostSensorMutation();
  const [patchSensor] = usePatchSensorMutation();

  const patchAdapterParams = useCallback(
    ({ id, body }) => patchAdapter({
      id,
      body
    }),
    []
  );

  const postSensorParams = useCallback(
    ({ body }) => postSensor({
      body
    }),
    []
  );

  const postAdapterParams = useCallback(
    ({ body }) => postAdapter({
      body
    }),
    []
  );

  const patchSensorParams = useCallback(
    ({ body, id }) => patchSensor({
      body,
      id
    }),
    []
  );

  const deleteSensorParams = useCallback(
    ({ id }) => deleteSensor({
      id,
    }),
    []
  );

  const props = {
    data,
    patchAdapterParams,
    deleteSensorParams,
    postSensorParams,
    patchSensorParams,
    postAdapterParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data ? Children
        .toArray(children).map((child) => cloneElement(child, props)) : <CircularProgress />}
    </>
  );
}

EquipmentBll.propTypes = {
  vehicleId: PropTypes.number,
  children: PropTypes.element.isRequired,
};

EquipmentBll.defaultProps = {
  vehicleId: null,
};

export default EquipmentBll;
