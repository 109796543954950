import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import {
  useDeleteAlertsSettingsResourceMutation,
  useLazyGetAlertsSettingsResourceQuery,
} from "../redux/alertsSettingsResource.api";
import { useGetAlertsChannelsResourceQuery } from "../../alertsChannelsResource/redux/alertsChannelsResource.api";
import { useGetAlertsTypesResourceQuery } from "../../alertsTypesResource/redux/alertsTypesResource.api";
// eslint-disable-next-line boundaries/element-types
import { getRuCategory } from "../../../../widgets/alerts/CreateAlertsSettingsWidget";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AlertsSettingsResourceBll({
  children, companyId
}) {
  const { data: dataAlertsTypesResource } = useGetAlertsTypesResourceQuery({});

  const [
    fetchAlertsSettingsResource,
    { data: dataAlertsSettingsResource }
  ] = useLazyGetAlertsSettingsResourceQuery();

  const fetchAlertsSettingsResourceParams = useCallback(
    ({ params }) => fetchAlertsSettingsResource({
      params: {
        "companyId.equals": companyId,
        ...params
      },
    }),
    [companyId]
  );

  const { data: dataAlertsChannels } = useGetAlertsChannelsResourceQuery({});

  const [data, setData] = useState(null);

  const [deleteAlertsSettingsResource] = useDeleteAlertsSettingsResourceMutation();

  const deleteAlertsSettingsResourceParams = useCallback(
    ({ id }) => deleteAlertsSettingsResource({
      id,
    }),
    []
  );

  useEffect(() => {
    const newData = {};
    newData.dataAlertsSettingsResource = {
      data:
          dataAlertsTypesResource
            ? dataAlertsSettingsResource?.data?.map((e) => {
              const newObject = { ...e };
              newObject.category = getRuCategory(e.category);
              return newObject;
            })
            : []
    };
    newData.dataAlertsChannels = dataAlertsChannels;
    setData(newData);
  }, [
    dataAlertsSettingsResource, dataAlertsChannels
  ]);

  const props = {
    data,
    deleteAlertsSettingsResourceParams,
    fetchAlertsSettingsResourceParams,
    dataAlertsTypesResource
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AlertsSettingsResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
  companyId: PropTypes.number,
};

AlertsSettingsResourceBll.defaultProps = {
  companyId: NaN,
};

export default AlertsSettingsResourceBll;
