import { IconButton, Snackbar as SnackbarMUI } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";

// Отображает всплывающее сообщение о статусе отправки запроса
function Snackbar({ isOpenSnackbar, closeSnackbar, message }) {
  return (
    <SnackbarMUI
      open={isOpenSnackbar}
      autoHideDuration={2000}
      onClose={closeSnackbar}
      message={message}
      action={(
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={closeSnackbar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    />
  );
}

Snackbar.propTypes = {
  isOpenSnackbar: PropTypes.bool.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default Snackbar;
