import React from 'react';
import {
  AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis
} from "recharts";
import PropTypes from "prop-types";

function CustomizedResponsiveContainer({
  xAxisProps, yAxisProps, tooltipProps, width, height, syncId, data, children
}) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        width={width}
        height={height}
        data={data}
        syncId={syncId}
      >
        <XAxis
          {...xAxisProps}
        />
        <YAxis {...yAxisProps} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          {...tooltipProps}
        />
        <Legend />
        {children}
      </AreaChart>
    </ResponsiveContainer>
  );
}

CustomizedResponsiveContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  yAxisProps: PropTypes.any,
  xAxisProps: PropTypes.shape({
    tickFormatter: PropTypes.func,
    dataKey: PropTypes.string.isRequired,
    interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tick: PropTypes.shape({
      fontSize: PropTypes.string.isRequired,
    })
  }).isRequired,
  tooltipProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    labelFormatter: PropTypes.func,
    formatter: PropTypes.func
  }).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  syncId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    fuelCoef: PropTypes.number.isRequired,
    fuelCoefUp: PropTypes.number.isRequired,
    fuelCoefDown: PropTypes.number.isRequired,
    altUpSum: PropTypes.number.isRequired,
    altDownSum: PropTypes.number.isRequired,
    fuelDiff: PropTypes.number.isRequired,
  })).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

CustomizedResponsiveContainer.defaultProps = {
  yAxisProps: {}
};

export default CustomizedResponsiveContainer;
