import React, { useState, useMemo, useEffect } from 'react';
import {
  Alert, AlertTitle,
  Autocomplete, Box, Button, TextField, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { tariffPlansResourceApi } from '../../../entities/finances/tariffPlansResource/redux/tariffPlansResource.api';
import style from './CompanyTariffPlanPanel.module.css';
import useAuth from '../../../shared/lib/hooks/useAuth';

// Отображает тарифный план по компании (текущий и следующий), комобобокс и кнопки смены тарифа
function CompanyTariffPlanPanel(props) {
  const {
    companyAccount, putCompanyAccountParams, loading, headerPrefix, onChangeCB
  } = props;
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [isChangeTariff, setIsChangeTariff] = useState(false);

  const { user } = useAuth();

  const tariffPlans = tariffPlansResourceApi.useGetTariffPlansQuery();

  const { currentTariffPlan, nextTariffPlan } = useMemo(() => {
    if (user.currentTariffPlan || user.nextTariffPlan) {
      return {
        currentTariffPlan: user.currentTariffPlan,
        nextTariffPlan: user.nextTariffPlan,
      };
    }
    const currentTarifPlanId = companyAccount?.currentPlan?.tarifPlanId;
    const nextTariffPlanId = companyAccount?.nextPlan?.tarifPlanId;
    const currentTariff = tariffPlans?.data?.data?.find(
      (tariff) => tariff.tarifPlanId === currentTarifPlanId
    ) || null;
    const nextTariff = tariffPlans?.data?.data?.find(
      (tariff) => tariff.tarifPlanId === nextTariffPlanId
    ) || null;
    return { currentTariffPlan: currentTariff, nextTariffPlan: nextTariff };
  }, [user, companyAccount, tariffPlans]);

  const optionsTariffPlans = useMemo(
    () => tariffPlans?.data?.data?.filter(
      (tariff) => tariff.tarifPlanId !== currentTariffPlan?.tarifPlanId
    ).sort((a, b) => a?.name?.localeCompare(b?.name)) || [],
    [tariffPlans, currentTariffPlan]
  );

  useEffect(() => {
    if (optionsTariffPlans && nextTariffPlan) {
      setSelectedTariff(nextTariffPlan);
      setIsChangeTariff(false);
    }
  }, [optionsTariffPlans, nextTariffPlan]);

  const updateTariff = () => {
    setIsChangeTariff(false);
    putCompanyAccountParams?.({
      id: companyAccount.companyAccountId,
      body: {
        ...companyAccount,
        nextPlan: selectedTariff
          ? {
            tarifPlanId: selectedTariff.tarifPlanId
          }
          : null
      }
    });
  };

  const resetSelectedTariff = () => {
    setSelectedTariff(nextTariffPlan);
    setIsChangeTariff(false);
  };

  const isErr = !loading && !companyAccount;

  return (
    <Box className={style.companyTariffPlanPanel}>
      <Typography variant="h6" component="div" sx={{ color: 'text.secondary', paddingTop: '20px' }}>
        {headerPrefix ? `${headerPrefix}: ` : ''}
        {currentTariffPlan?.name}
      </Typography>
      <Typography variant="body2" component="div" sx={{ maxWidth: onChangeCB ? null : '500px' }}>
        {currentTariffPlan?.description}
      </Typography>
      <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
        Дата следующего списания:
        {' '}
        {companyAccount?.nextPlanActivateDate
          ? new Date(companyAccount?.nextPlanActivateDate).toLocaleDateString()
          : '–'}
      </Typography>
      <Box className={style.tariffControl}>
        <Autocomplete
          sx={{
            width: (onChangeCB && putCompanyAccountParams) ? '100%' : '400px'
          }}
          size="small"
          value={selectedTariff}
          onChange={(event, newValue) => {
            setIsChangeTariff(true);
            setSelectedTariff(newValue);
            onChangeCB?.(newValue);
          }}
          id="vehicle"
          options={optionsTariffPlans}
          isOptionEqualToValue={(option, value) => option.tarifPlanId === value.tarifPlanId}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Тариф на следующий период" />}
        />
        { !onChangeCB
        && (
        <>
          <Button
            variant="text"
            disabled={!isChangeTariff}
            onClick={resetSelectedTariff}
          >
            Отмена
          </Button>
          <Button
            variant="contained"
            disabled={isErr || !isChangeTariff}
            onClick={updateTariff}
          >
            Сохранить
          </Button>
        </>
        )}
      </Box>
      {selectedTariff
        ? (
          <Box className={style.selectedTariff}>
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
              Описание:
            </Typography>
            <Typography variant="body2" component="div" sx={{ maxWidth: onChangeCB ? null : '500px' }}>
              {selectedTariff.description}
            </Typography>
          </Box>
        )
        : null}
      {isErr
          && (
          <Alert sx={{ width: "max-content" }} severity="error">
            <AlertTitle>Внутренняя ошибка</AlertTitle>
            Нельзя получить платежный аккаунт компании
          </Alert>
          )}

    </Box>
  );
}

CompanyTariffPlanPanel.propTypes = {
  companyAccount: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any)
  ])),
  putCompanyAccountParams: PropTypes.func,
  loading: PropTypes.bool,
  headerPrefix: PropTypes.string,
  onChangeCB: PropTypes.func,
};

CompanyTariffPlanPanel.defaultProps = {
  companyAccount: null,
  putCompanyAccountParams: null,
  loading: null,
  headerPrefix: '',
  onChangeCB: null,
};

export default CompanyTariffPlanPanel;
