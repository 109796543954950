import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import style from './Error.module.css';

function Error({ errorMessage }) {
  return (
    <Box className={style.container}>
      {errorMessage}
    </Box>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string,
};

Error.defaultProps = {
  errorMessage: 'Произошла ошибка, повторите попытку позже.'
};

export default Error;
