import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import style from './VehicleGroupPanel.module.css';
import VehicleProposalWidget from '../../../widgets/vehicles/VehicleProposalWidget/VehicleProposalWidget';
import { getPlatoonGroup } from '../../../shared/api/api';
import VehicleMovementWidget from '../../../widgets/vehicles/VehicleMovementWidget/VehicleMovementWidget';
import VehicleManeuverWidget from '../../../widgets/vehicles/VehicleManeuverWidget/VehicleManeuverWidget';

// Отображает панель "Группа ТС" для ТС
function VehicleGroupPanel(props) {
  const { company, vehicle } = props;

  // Текущий открытый аккордион
  const [expanded, setExpanded] = useState('Proposal');

  // Группа ТС
  const [platoonGroup, setPlatoonGroup] = useState(null);
  // id группы (берется из заявки на вступление в группу)
  const [platoonGroupId, setPlatoonGroupId] = useState(null);

  // Изменение открытого аккордиона
  const changeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Получение группы, когда id известен
  useEffect(() => {
    if (platoonGroupId) {
      getPlatoonGroup(platoonGroupId).then((res) => {
        setPlatoonGroup(res);
      }).catch((error) => { throw error; });
    }
  }, [platoonGroupId]);

  return (
    <Box className={style.vehicleGroupPanel}>
      <Box className={style.accordions}>
        <Accordion square expanded={expanded === 'Proposal'} onChange={changeAccordion('Proposal')}>
          <AccordionSummary>
            <Typography>
              Заявка
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VehicleProposalWidget
              company={company}
              vehicle={vehicle}
              setPlatoonGroupId={setPlatoonGroupId}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'Movement'} onChange={changeAccordion('Movement')}>
          <AccordionSummary>
            <Typography>
              Движение
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VehicleMovementWidget
              vehicle={vehicle}
              platoonGroupId={platoonGroupId}
              platoonGroup={platoonGroup}
              setPlatoonGroup={setPlatoonGroup}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'Maneuver'} onChange={changeAccordion('Maneuver')}>
          <AccordionSummary>
            <Typography>
              История
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VehicleManeuverWidget
              vehicle={vehicle}
              platoonGroupId={platoonGroupId}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

VehicleGroupPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  vehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

VehicleGroupPanel.defaultProps = {
  company: null,
  vehicle: null,
};

export default VehicleGroupPanel;
