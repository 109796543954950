import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const platoonRequestsResourceEnum = {
  platoonReqId: "platoonReqId",
  vehicleId: "vehicleId",
  companyId: "companyId",
  routeId: "routeId",
  role: "role",
  status: "status",
  startDateFrom: "startDateFrom",
  startDateTo: "startDateTo",
  requiredTokens: "requiredTokens",
  adviseTokens: "adviseTokens",
  createdDate: "createdDate",
  modifiedDate: "modifiedDate",
};

// Инициализация стейта в сторе
const platoonRequestsResourceInitialState = {
  [platoonRequestsResourceEnum.platoonReqId]: NaN,
  [platoonRequestsResourceEnum.vehicleId]: NaN,
  [platoonRequestsResourceEnum.companyId]: NaN,
  [platoonRequestsResourceEnum.routeId]: NaN,
  [platoonRequestsResourceEnum.role]: null,
  [platoonRequestsResourceEnum.status]: null,
  [platoonRequestsResourceEnum.startDateFrom]: null,
  [platoonRequestsResourceEnum.startDateTo]: null,
  [platoonRequestsResourceEnum.requiredTokens]: null,
  [platoonRequestsResourceEnum.adviseTokens]: null,
  [platoonRequestsResourceEnum.createdDate]: null,
  [platoonRequestsResourceEnum.modifiedDate]: null
};
// Типизация стора
export const platoonRequestsResourceInitialStateType = {
  [platoonRequestsResourceEnum.platoonReqId]: PropTypes.number,
  [platoonRequestsResourceEnum.vehicleId]: PropTypes.number,
  [platoonRequestsResourceEnum.companyId]: PropTypes.number,
  [platoonRequestsResourceEnum.routeId]: PropTypes.number,
  [platoonRequestsResourceEnum.role]: PropTypes.string,
  [platoonRequestsResourceEnum.status]: PropTypes.string,
  [platoonRequestsResourceEnum.startDateFrom]: PropTypes.string,
  [platoonRequestsResourceEnum.startDateTo]: PropTypes.string,
  [platoonRequestsResourceEnum.requiredTokens]: PropTypes.string,
  [platoonRequestsResourceEnum.adviseTokens]: PropTypes.string,
  [platoonRequestsResourceEnum.createdDate]: PropTypes.string,
  [platoonRequestsResourceEnum.modifiedDate]: PropTypes.string,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const platoonRequestsResourceSlice = createSlice({
  name: `${reducerPath.platoonRequestsResource}/counter`,
  initialState: platoonRequestsResourceInitialState,
  reducers: {}
});

export const platoonRequestsResourceReducer = platoonRequestsResourceSlice.reducer;
