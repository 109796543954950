import React, {
  Children, cloneElement, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { deleteAppUserAccesses, getAppUserAccesses } from "../../../shared/api/api";
import {
  useGetAppUserResourceQuery
} from "../../../entities/account/appUsersResource/redux/appUsersResource.api";
import useAuth from "../../../shared/lib/hooks/useAuth";

const privilegesRu = {
  DIV_PRIV_READ: 'Чтение',
  DIV_PRIV_VEHICLE_ADMIN: 'Администрирование ТС',
  DIV_PRIV_USER_ADMIN: 'Администрирование пользователей',
};

const groupBy = (arr, key) => arr.reduce((group, item) => {
  const field = item.user[key];
  // eslint-disable-next-line no-param-reassign
  group[field] = group[field] ?? [];
  group[field].push(item);
  return group;
}, {});

const privilegesToString = (groupUserAccesses) => {
  const newUserAccesses = [];
  Object.keys(groupUserAccesses).forEach((key) => {
    const row = {
      appUserId: key,
      firstName: key,
      lastName: key,
      privileges: groupUserAccesses[key].map((item) => privilegesRu[item.divPrivilege]).join(', ')
    };
    newUserAccesses.push(row);
  });
  return newUserAccesses;
};

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function DivisionSettingsBll({
  children, divisionId, companyId
}) {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [appUserAccesses, setAppUserAccesses] = useState([]);
  const [update, setUpdate] = useState(false);
  const [filters, setFilters] = useState();
  const { data: dataAppUserResource } = useGetAppUserResourceQuery({
    params: !user.authorities.includes("ROLE_ADMIN") ? {
      // "appUserId.equals": user.id
      "companyId.equals": companyId
    } : {}
  });

  useEffect(() => {
    const arr = dataAppUserResource?.data;
    if (arr?.length) {
      getAppUserAccesses({ divisionId, ...filters }).then((res) => {
        setAppUserAccesses(res);
        setData(privilegesToString(groupBy(res, 'appUserId')).map((e) => {
          const newObj = { ...e };
          const appUser = arr
            .find((currentUser) => Number(currentUser.appUserId) === Number(e.appUserId));
          newObj.firstName = appUser?.firstName;
          newObj.lastName = appUser?.lastName;
          return newObj;
        }));
      })
        .catch(() => { });
    }
  }, [divisionId, update, filters, dataAppUserResource]);

  const onDelete = (appUserId) => {
    const currentAccesses = appUserAccesses.filter(
      (appUserAccess) => appUserAccess.user.appUserId === appUserId
    );
    const deleted = [];
    currentAccesses.forEach((currentAccess) => {
      deleted.push(deleteAppUserAccesses(currentAccess.appUserAccessId));
    });

    Promise.all(deleted).then(() => {
      setUpdate((prev) => !prev);
    });
  };

  const props = {
    data,
    divisionId,
    onDelete,
    setUpdate,
    setFilters,
    companyId
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

DivisionSettingsBll.propTypes = {
  children: PropTypes.element.isRequired,
  divisionId: PropTypes.number,
  companyId: PropTypes.number,
};

DivisionSettingsBll.defaultProps = {
  divisionId: NaN,
  companyId: NaN,
};

export default DivisionSettingsBll;
