import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Box, Button, FormControl, FormControlLabel, IconButton, Input, InputAdornment,
  InputLabel, TextField, FormHelperText, Checkbox,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import style from './PassengersFormWidget.module.css';
import { getCompanyCargo, patchCompanyCargo } from '../../../shared/api/api';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import Loading from '../../../shared/ui/Loading/Loading';
import CARGO_TYPE from '../../../shared/constants/cargoTypes';
import Tags from '../../../shared/ui/Tags/Tags';

const initCompanyCargo = (isPassenger) => {
  const nowDate = new Date();
  const initialCompanyCargo = {
    title: '',
    tags: '',
    comment: '',
    startDeliveryTime: nowDate,
    finishDeliveryTime: nowDate,
    cargoLogin: '',
    cargoPassword: '',
    cargoPasswordConfirm: '',
    cargoMail: '',
    observerLogin: '',
    observerPassword: '',
    observerPasswordConfirm: '',
    observerMail: '',
    ...(!isPassenger ? {} : {
      width: '',
      height: '',
      length: '',
      loadingAddress: '',
      deliveryAddress: '',
      weight: '',
    })
  };
  return initialCompanyCargo;
};

const EMPTY_ERROR = "Поле не может быть пустым";
const WRONG_INTEGER_NUMBER_VALUE = "Поле должно содержать целое число";
const WRONG_NUMBER_VALUE = "Поле должно содержать число";

export const checkNumber = (value, isInteger) => value.trim() !== '' && (isInteger ? !value.match(/^\d+?$/) : !value.match(/^\d+([.,]\d+)?$/));

export function convertFloatNumber(value) {
  return parseFloat(`${value}`.trim().split(',').join('.'));
}

const errorsCompanyCargo = {
  title: false,
  startDeliveryTime: false,
  cargoLogin: false,
  cargoMail: false,
  observerLogin: false,
  observerMail: false,
  width: false,
  height: false,
  length: false,
  loadingAddress: false,
  deliveryAddress: false,
  weight: false,
};

// Отображает форму заполнения инфо о пассажире/грузе
function PassengersFormWidget(props) {
  const { companyId, updateCompanyCargos, cargoType } = props;
  const { compCargoId } = useParams();

  const isPassenger = useMemo(() => cargoType === 'CARGO_TYPE_PASSENGER', [cargoType]);

  const location = useLocation();
  const searchParams = location.state?.from?.search || '';

  const [companyCargo, setCompanyCargo] = useState(null);
  const [companyCargoForm, setCompanyCargoForm] = useState(null);
  const [errorsForm, setErrorsForm] = useState(errorsCompanyCargo);
  const [changesForm, setChangesForm] = useState({});

  const navigate = useNavigate();
  const onClickBack = useCallback(() => {
    navigate(`/companies/${companyId}/${isPassenger ? 'passengers' : 'cargo'}/main${searchParams}`);
  }, [navigate, companyId, searchParams]);

  const [isChangeCargoPassword, setIsChangeCargoPassword] = useState(false);
  const [isChangeObserverPassword, setIsChangeObserverPassword] = useState(false);

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const onClickSave = () => {
    if (
      errorsForm.title
      || errorsForm.startDeliveryTime
      || errorsForm.cargoLogin
      || errorsForm.cargoMail
      || errorsForm.observerLogin
      || errorsForm.observerMail
      || errorsForm.width
      || errorsForm.height
      || errorsForm.length
      || errorsForm.weight
    ) {
      return;
    }
    const dataTrim = { ...changesForm };
    Object.keys(changesForm).forEach((key) => {
      const value = dataTrim[key];
      dataTrim[key] = typeof value === 'string' ? value.trim() : value;
    });
    const id = companyCargo.compCargoId;
    const body = {
      compCargoId: id,
      ...dataTrim,
      width: convertFloatNumber(companyCargoForm.width),
      height: convertFloatNumber(companyCargoForm.height),
      length: convertFloatNumber(companyCargoForm.length),
      weight: companyCargoForm.weight ? convertFloatNumber(companyCargoForm.weight) * 1000 : null,
    };
    if (isChangeCargoPassword) {
      if (!companyCargoForm.cargoPassword) {
        setErrorsForm((prev) => ({ ...prev, cargoPassword: true }));
        return;
      }
      if (errorsForm.cargoPasswordConfirm) {
        return;
      }
    } else {
      delete body.cargoPassword;
    }
    if (isChangeObserverPassword) {
      if (!companyCargoForm.observerPassword) {
        setErrorsForm((prev) => ({ ...prev, observerPassword: true }));
        return;
      }
      if (errorsForm.observerPasswordConfirm) {
        return;
      }
    } else {
      delete body.observerPassword;
    }

    delete body.observerPasswordConfirm;
    delete body.cargoPasswordConfirm;
    setIsLoadingSave(true);
    patchCompanyCargo(id, body)
      .then(() => {
        updateCompanyCargos();
        onClickBack();
      })
      .finally(() => {
        setIsLoadingSave(false);
      });
  };

  useEffect(() => {
    getCompanyCargo(compCargoId).then((res) => {
      setCompanyCargo({ ...res, weight: res.weight / 1000 });
    });
  }, [compCargoId]);

  useEffect(() => {
    if (companyCargo) {
      const newCompanyCargoForm = initCompanyCargo(isPassenger);
      setCompanyCargoForm({
        ...newCompanyCargoForm,
        ...companyCargo,
      });
    } else {
      setCompanyCargoForm(null);
    }
  }, [companyCargo]);

  const handleChangeText = (event, checkEmpty = true) => {
    setChangesForm((prev) => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
    if (errorsForm[event.target.id]) {
      setErrorsForm((prev) => ({ ...prev, [event.target.id]: checkEmpty && event.target.value.trim() === "" }));
    }
    setCompanyCargoForm((prev) => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  const handleChangeTags = (value) => {
    handleChangeText({ target: { id: 'tags', value } });
  };

  const handleChangeDate = (name, date) => {
    setChangesForm((prev) => ({
      ...prev,
      [name]: date
    }));
    setCompanyCargoForm((prev) => ({
      ...prev,
      [name]: date
    }));
  };

  const [showPassword, setShowPassword] = React.useState({
    cargoPassword: false,
    cargoPasswordConfirm: false,
    observerPassword: false,
    observerPasswordConfirm: false,
  });

  const handleClickShowPassword = (name) => setShowPassword((prev) => ({
    ...prev,
    [name]: !prev[name],
  }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className={style.widget}>
      {!companyCargoForm
        ? <Loading />
        : (
          <Box className={style.form}>
            <Typography variant="h6" gutterBottom component="div" sx={{ color: 'text.secondary' }}>
              Редактирование
              {' '}
              {isPassenger ? 'пассажира' : 'груза'}
            </Typography>
            <Box className={style.formHorizontal}>
              <Box className={style.form}>
                <TextField
                  label={isPassenger ? 'ФИО' : 'Название'}
                  variant="standard"
                  required
                  id="title"
                  value={companyCargoForm.title}
                  onChange={handleChangeText}
                  sx={{ width: '500px' }}
                  error={errorsForm.title}
                  helperText={errorsForm.title ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <Box className={style.dateTime}>
                  <DateTimePicker
                    value={companyCargoForm.startDeliveryTime}
                    label="Начало перевозки"
                    required
                    inputProps={{
                      placeholder: 'дд.мм.гггг чч:мм',
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        required
                        sx={{ width: '240px' }}
                      />
                    )}
                    components={{
                      ActionBar: ActionBarDateTimePicker,
                    }}
                    onChange={(date) => handleChangeDate('startDeliveryTime', date)}
                  />
                  <DateTimePicker
                    value={companyCargoForm.finishDeliveryTime}
                    label="Окончание перевозки"
                    inputProps={{
                      placeholder: 'дд.мм.гггг чч:мм',
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        sx={{ width: '240px' }}
                      />
                    )}
                    components={{
                      ActionBar: ActionBarDateTimePicker,
                    }}
                    onChange={(date) => handleChangeDate('finishDeliveryTime', date)}
                  />
                </Box>
                { !isPassenger
                  && (
                  <>
                    <Box className={style.dateTime}>
                      <TextField
                        label="Ширина, см"
                        variant="standard"
                        required={false}
                        id="width"
                        value={companyCargoForm.width}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.width}
                        helperText={errorsForm.width ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Высота, см"
                        variant="standard"
                        required={false}
                        id="height"
                        value={companyCargoForm.height}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.height}
                        helperText={errorsForm.height ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Длина, см"
                        variant="standard"
                        required={false}
                        id="length"
                        value={companyCargoForm.length}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.length}
                        helperText={errorsForm.length ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Вес, кг"
                        variant="standard"
                        required={false}
                        id="weight"
                        value={companyCargoForm.weight}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.weight}
                        helperText={errorsForm.weight ? WRONG_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value)
                          }));
                        }}
                      />
                    </Box>
                    <TextField
                      label="Адрес погрузки"
                      variant="standard"
                      required={false}
                      id="loadingAddress"
                      value={companyCargoForm.loadingAddress}
                      onChange={(evt) => handleChangeText(evt, false)}
                      sx={{ width: '500px' }}
                      error={errorsForm.loadingAddress}
                    />
                    <TextField
                      label="Адрес доставки"
                      variant="standard"
                      required={false}
                      id="deliveryAddress"
                      value={companyCargoForm.deliveryAddress}
                      onChange={(evt) => handleChangeText(evt, false)}
                      sx={{ width: '500px' }}
                      error={errorsForm.deliveryAddress}
                    />
                  </>
                  )}
                <Tags data={companyCargoForm.tags} onChange={handleChangeTags} />
                <TextField
                  id="comment"
                  onChange={handleChangeText}
                  fullWidth
                  multiline
                  rows={3}
                  label="Комментарии"
                  variant="standard"
                  sx={{ maxWidth: '500px' }}
                  value={companyCargoForm.comment}
                />
              </Box>
              <Box className={style.form}>
                <TextField
                  id="cargoLogin"
                  onChange={handleChangeText}
                  label="Логин"
                  required
                  variant="standard"
                  value={companyCargoForm.cargoLogin}
                  sx={{ width: '260px' }}
                  error={errorsForm.cargoLogin}
                  helperText={errorsForm.cargoLogin ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <TextField
                  id="cargoMail"
                  onChange={handleChangeText}
                  required
                  label="Эл. почта"
                  variant="standard"
                  value={companyCargoForm.cargoMail}
                  sx={{ width: '260px' }}
                  error={errorsForm.cargoMail}
                  helperText={errorsForm.cargoMail ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isChangeCargoPassword}
                      onChange={() => {
                        setIsChangeCargoPassword((prev) => !prev);
                      }}
                      name="isChangeCargoPassword"
                    />
                  )}
                  label="Изменить пароль"
                />
                {isChangeCargoPassword ? (
                  <>
                    <FormControl sx={{ width: '260px' }} variant="standard" disabled={!isChangeCargoPassword}>
                      <InputLabel htmlFor="cargoPassword">Пароль</InputLabel>
                      <Input
                        id="cargoPassword"
                        type={showPassword.cargoPassword ? 'text' : 'password'}
                        value={companyCargoForm.cargoPassword}
                        onChange={(event) => {
                          handleChangeText(event);
                          setErrorsForm((prev) => ({
                            ...prev,
                            cargoPasswordConfirm:
                              event.target.value !== companyCargoForm.cargoPasswordConfirm
                          }));
                        }}
                        error={errorsForm.cargoPassword}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                        }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!isChangeCargoPassword}
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('cargoPassword')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.cargoPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      )}
                      />
                      <FormHelperText error>
                        {errorsForm.cargoPassword ? EMPTY_ERROR : null}
                      </FormHelperText>
                    </FormControl>
                    <FormControl sx={{ width: '260px' }} variant="standard" disabled={!isChangeCargoPassword}>
                      <InputLabel htmlFor="cargoPasswordConfirm">Повторите пароль</InputLabel>
                      <Input
                        id="cargoPasswordConfirm"
                        type={showPassword.cargoPasswordConfirm ? 'text' : 'password'}
                        value={companyCargoForm.cargoPasswordConfirm}
                        onChange={handleChangeText}
                        error={errorsForm.cargoPasswordConfirm}
                        helperText={errorsForm.cargoPasswordConfirm ? 'Пароли должны совпадать' : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]:
                              event.target.value !== companyCargoForm.cargoPassword
                          }));
                        }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('cargoPasswordConfirm')}
                              onMouseDown={handleMouseDownPassword}
                              disabled={!isChangeCargoPassword}
                            >
                              {showPassword.cargoPasswordConfirm
                                ? <Visibility />
                                : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      )}
                      />
                      <FormHelperText error>
                        {errorsForm.cargoPasswordConfirm ? 'Пароли должны совпадать' : null}
                      </FormHelperText>
                    </FormControl>
                  </>
                ) : null}
              </Box>
              <Box className={style.form}>
                <TextField
                  id="observerLogin"
                  onChange={handleChangeText}
                  label="Логин наблюдателя"
                  variant="standard"
                  required
                  sx={{ width: '260px' }}
                  error={errorsForm.observerLogin}
                  helperText={errorsForm.observerLogin ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                  value={companyCargoForm.observerLogin}
                />
                <TextField
                  id="observerMail"
                  onChange={handleChangeText}
                  label="Эл. почта наблюдателя"
                  variant="standard"
                  sx={{ width: '260px' }}
                  value={companyCargoForm.observerMail}
                  required
                  error={errorsForm.observerMail}
                  helperText={errorsForm.observerMail ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isChangeObserverPassword}
                      onChange={() => {
                        setIsChangeObserverPassword((prev) => !prev);
                      }}
                      name="isChangeObserverPassword"
                    />
                  )}
                  label="Изменить пароль"
                />
                {isChangeObserverPassword ? (
                  <>
                    <FormControl sx={{ width: '260px' }} variant="standard" disabled={!isChangeObserverPassword}>
                      <InputLabel htmlFor="observerPassword">Пароль наблюдателя</InputLabel>
                      <Input
                        id="observerPassword"
                        type={showPassword.observerPassword ? 'text' : 'password'}
                        value={companyCargoForm.observerPassword}
                        onChange={(event) => {
                          handleChangeText(event);
                          setErrorsForm((prev) => ({
                            ...prev,
                            observerPasswordConfirm:
                              event.target.value !== companyCargoForm.observerPasswordConfirm
                          }));
                        }}
                        error={errorsForm.observerPassword}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                        }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('observerPassword')}
                              onMouseDown={handleMouseDownPassword}
                              disabled={!isChangeObserverPassword}
                            >
                              {showPassword.observerPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                  )}
                      />
                      <FormHelperText error>
                        {errorsForm.observerPassword ? EMPTY_ERROR : null}
                      </FormHelperText>
                    </FormControl>
                    <FormControl sx={{ width: '260px' }} variant="standard" disabled={!isChangeObserverPassword}>
                      <InputLabel htmlFor="observerPasswordConfirm">Повторите пароль</InputLabel>
                      <Input
                        id="observerPasswordConfirm"
                        type={showPassword.observerPasswordConfirm ? 'text' : 'password'}
                        value={companyCargoForm.observerPasswordConfirm}
                        onChange={handleChangeText}
                        error={errorsForm.observerPasswordConfirm}
                        helperText={errorsForm.observerPasswordConfirm ? 'Пароли должны совпадать' : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]:
                              event.target.value !== companyCargoForm.observerPassword
                          }));
                        }}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('observerPasswordConfirm')}
                              onMouseDown={handleMouseDownPassword}
                              disabled={!isChangeObserverPassword}
                            >
                              {showPassword.observerPasswordConfirm
                                ? <Visibility />
                                : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                  )}
                      />
                      <FormHelperText error>
                        {errorsForm.observerPasswordConfirm ? 'Пароли должны совпадать' : null}
                      </FormHelperText>
                    </FormControl>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        )}
      <Box className={style.footerControls}>
        <Button
          onClick={onClickBack}
          variant="contained"
        >
          Назад
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          disabled={isLoadingSave}
        >
          {isLoadingSave ? 'Сохранение...' : 'Сохранить'}
        </Button>
      </Box>
    </Box>
  );
}

PassengersFormWidget.propTypes = {
  companyId: PropTypes.number.isRequired,
  updateCompanyCargos: PropTypes.func.isRequired,
  cargoType: CARGO_TYPE.type,
};

PassengersFormWidget.defaultProps = {
  cargoType: CARGO_TYPE.default,
};

export default PassengersFormWidget;
