// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import convertServerFormatDate from "../../../../../shared/utils/convertServerFormatDate";
import { platoonRequestsResourceInitialStateType } from "../../redux/platoonRequestsResource.slice";

export function platoonRequestsResourceToDtoService(data) {
  const newData = JSON.parse(JSON.stringify(data));
  newData.modifiedDate = convertServerFormatDate(data.modifiedDate);
  Reflect.deleteProperty(newData, 'id');

  return newData;
}

platoonRequestsResourceToDtoService.propTypes = {
  dto: platoonRequestsResourceInitialStateType,
};

export default platoonRequestsResourceToDtoService;
