import React from 'react';
import { Breadcrumbs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useAppSelector } from '../../../../app/store';
import reducerPath from '../../../../app/reducerPath';
import LoadingNodeIcon from '../../../../shared/ui/LoadingNodeIcon/LoadingNodeIcon';

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

const breadcrumbNameMap = {
  reports: 'Все',
};

const UNKNOWN = "Без названия";

function ReportsListWidget() {
  const location = useLocation();
  const { groups, reports, isLoadingList } = useAppSelector((state) => state[`${reducerPath.reportsResource}`]);
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('report');
  const pathnames = reportId ? [...location.pathname.split('/').filter((x) => x), reportId] : location.pathname.split('/').filter((x) => x);

  const linksPathnames = pathnames.slice(2);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {isLoadingList
        ? <LoadingNodeIcon />
        : linksPathnames.map((value, index) => {
          const last = index + 2 === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1 + 2).join('/')}`;
          let name = "";

          if (last && reportId) {
            const report = reports?.find((item) => Number(item.id) === Number(reportId));
            name = report?.name || report?.reportId || UNKNOWN;
          } else {
            name = breadcrumbNameMap[value]
              || groups?.find((item) => Number(item.id) === Number(value))?.name || UNKNOWN;
          }

          return last ? (
            <Typography color="text.primary" key={to}>
              {name}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {name}
            </LinkRouter>
          );
        })}
    </Breadcrumbs>
  );
}

export default ReportsListWidget;
