// eslint-disable-next-line
import i18n from "../../i18n";
import ERROR_CODES from "./ERROR_CODES";

function getErrorMessage(errorCode, error = {}) {
  const { t } = i18n;
  const code = errorCode || error.errorCode;
  return code ? t(code, { ns: 'errorCodes' })
    : (error.detail
    || error.title
    || t(ERROR_CODES.UNKNOWN_ERROR, { ns: 'errorCodes' }));
}

export default getErrorMessage;
