function formatFromTimeISO8601(str, isFull) {
  if (!str) return null;
  let duration = str;
  const isNegative = duration.includes("-");
  if (isNegative) {
    duration = duration.replaceAll("-", "");
  }

  const regex = /PT(?:(-?\d+(?:\.\d+)?H)?(-?\d+(?:\.\d+)?M)?(-?\d+(?:\.\d+)?S)?)?/;
  const matches = duration.match(regex);

  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
  const seconds = matches[3] ? parseInt(matches[3], 10) : 0;

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return "0сек";
  }

  if (isFull) {
    return `${isNegative ? "-" : ''}${hours ? `${hours}ч ` : ''}${minutes ? `${minutes}мин ` : ''}${seconds ? `${seconds}сек` : ''}`.trim();
  }
  let result = `${isNegative ? "-" : ''}`;
  if (hours) {
    result += `${hours}H`;
  }
  if (minutes) {
    result += `${minutes}M`;
  }
  if (seconds) {
    result += `${seconds}S`;
  }
  return result;
}

export default formatFromTimeISO8601;
