import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line boundaries/element-types
import { usePostChangePasswordInitMutation } from "../../../entities/account/accountResource/redux/accountResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function ForgotPasswordBll({ children }) {
  const [postChangePasswordInit] = usePostChangePasswordInitMutation();

  const postChangePasswordInitParams = useCallback(
    ({ body }) => postChangePasswordInit({
      body
    }),
    []
  );

  const props = {
    postChangePasswordInitParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

ForgotPasswordBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ForgotPasswordBll;
