import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import { IconButton, TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect } from 'react';
import EnhancedTableHead from '../EnhancedTableHead/EnhancedTableHead';
import EditButton from '../EditButton/EditButton';
import DeleteButton from '../DeleteButton/DeleteButton';
import NoData from '../NoData/NoData';
// eslint-disable-next-line boundaries/element-types
import {
  errorNullText
} from "../../../entities/platoons/platoonGroupVehiclesResource/services/data/platoonGroupVehiclesResource-from-dto.service";
import style from './ControlledTable.module.css';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const errColor = '#F55353';

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function TableRowHover(props) {
  const {
    children,
    customControls,
    item,
    ...other
  } = props;

  const [hover, setHover] = React.useState(false);

  return (
    <TableRow
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...other}
    >
      {children}
      {customControls
        ? (
          <TableCell
            sx={{
              padding: '0px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              {React.cloneElement(customControls, { item, hover, setHover })}
            </Box>
          </TableCell>
        )
        : null}
    </TableRow>
  );
}

TableRowHover.propTypes = {
  item: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  customControls: PropTypes.element,
};

TableRowHover.defaultProps = {
  customControls: null,
};

/* function TableCellGraph({ row, cell }) {
  const isNegative = !(String(row[cell]).includes('-'));
  const isNull = row[cell] === errorNullText;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ transform: isNegative ? "rotate(180deg)" : "rotate(0deg)", display: "flex" }}>
        {!isNull && (
          <ArrowDownwardIcon sx={{
            color: isNegative ? "#E10050"
              : "#0A891E"
          }}
          />
        )}
      </Box>
      <span style={{
        color: isNull ? "black" : isNegative ? "#E10050"
          : "#0A891E"
      }}
      >
        {row[cell]}
      </span>
    </Box>
  );
}

TableCellGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.any,
  cell: PropTypes.string
};

TableCellGraph.defaultProps = {
  row: null,
  cell: '',
}; */

function TableCellInterval({ row, cell }) {
  if (row.hasNoData || row.isOlderThan5min || !row[cell]) {
    return errorNullText;
  }
  const diffFromAverage = cell === 'intervalNext'
    ? row.diffNextFromAverage : row.diffPrevFromAverage;
  return (
    <Typography sx={{ color: row[cell === 'intervalNext' ? 'nextColor' : 'prevColor'], alignItems: "center" }}>
      {row[cell]}
      {' '}
      (
      {diffFromAverage}
      )
    </Typography>
  );
}

TableCellInterval.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.any,
  cell: PropTypes.string
};

TableCellInterval.defaultProps = {
  row: null,
  cell: '',
};

// Показывает таблицу с заданными заголовками и колонками
function ControlledTable({
  rows,
  headCells,
  displayedCells,
  countRowsPerPage,
  defualtOrderBy,
  selected,
  setSelected,
  controls,
  onEdit,
  onDelete,
  titleDeleteButton,
  checkBoxTrigger,
  order: propOrder,
  orderBy: propOrderBy,
  isControlledSort,
  onRequestSort,
  renderCustomControls,
  disableCheckbox,
  dense,
  onSelectItem,
  onSelectAllItems,
  hover,
  isSelectOnClickRow
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defualtOrderBy || '');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(countRowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    onSelectAllItems(event.target.checked);
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, item) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    onSelectItem(selectedIndex, item);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    setRowsPerPage(countRowsPerPage);
  }, [countRowsPerPage]);

  const getSortedRows = (currentRows) => {
    if (isControlledSort) {
      return currentRows;
    }
    return currentRows.slice().sort(getComparator(order, orderBy));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={isControlledSort ? propOrder : order}
            orderBy={isControlledSort ? propOrderBy : orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={isControlledSort ? onRequestSort : handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            isControls={!!controls}
            disableCheckbox={disableCheckbox}
            isCustomControls={!!renderCustomControls}
          />
          <TableBody>
            {/* Сортировка массива по полю */}
            {getSortedRows(rows)
              // Обрез массива в соответствии с текущей страницей
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRowHover
                    customControls={renderCustomControls(row.item)}
                    item={row.item}
                    sx={{
                      bgcolor: row.rowColor,
                      cursor: (controls || disableCheckbox || !isSelectOnClickRow) ? 'default' : 'pointer',
                    }}
                    hover={hover}
                    onClick={(event) => isSelectOnClickRow && handleClick(event, row.id, row.item)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`TableRow-item-${row.id}-${index + 1}`}
                    selected={isItemSelected}
                  >
                    {!controls && !disableCheckbox
                      ? (
                        <TableCell sx={row.isError && { background: errColor }} padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) => (
                              handleClick(event, row.id, { rowIndex: index, ...row.item })
                            )}
                          />
                        </TableCell>
                      )
                      : null}
                    {displayedCells.map((cell) => (
                      <TableCell
                        sx={row.isError
                          ? { background: errColor }
                          : cell === 'phone'
                            ? { width: '152px' }
                            : cell === "textAlignRight"
                              ? { textAlign: "right", width: "0" }
                              : null}
                        key={cell}
                      >
                        {
                          cell === 'coordinatesWithIco' ? (
                            <IconButton onClick={(event) => {
                              handleClick(event, row.id, { rowIndex: index, ...row.item });
                            }}
                            >
                              <LocationOnIcon
                                fontSize="medium"
                                sx={{
                                  color: row.isError ? "#E10050"
                                    : "#2C98F0"
                                }}
                              />
                            </IconButton>
                          // ) : cell === 'graph'
                          //   ? <TableCellGraph row={row} cell={cell} />
                          ) : (cell === 'intervalPrev' || cell === 'intervalNext')
                            ? <TableCellInterval row={row} cell={cell} />
                            : cell === 'vehicleId'
                              ? (
                                <>
                                  {row[cell]}
                                  {' '}
                                  {(row.isOlderThan2min || row.isOlderThan5min)
                                    && (
                                    <SignalWifiBadIcon
                                      fontSize="12px"
                                      className={style.alarm}
                                      sx={{
                                        color: 'error.dark', position: 'relative', left: '2px', top: '2px'
                                      }}
                                    />
                                    )}
                                </>
                              )
                              : Array.isArray(row[cell])
                                ? row[cell].map((item) => <p>{item}</p>)
                                : cell === 'action' ? (
                                  <>
                                    { row.accelerationIcon && (
                                      <KeyboardArrowRightIcon className={style.accelerationIco} />
                                    )}
                                    { row.decelerationIcon && (
                                      <KeyboardArrowLeftIcon className={style.decelerationIco} sx={{ color: 'error.dark' }} />
                                    ) }
                                    { row.doubleAccelerationIcon && (
                                      <KeyboardDoubleArrowRightIcon
                                        className={style.accelerationIco}
                                      />
                                    ) }
                                    { row.doubleDecelerationIcon && (
                                      <KeyboardDoubleArrowLeftIcon className={style.decelerationIco} sx={{ color: 'error.dark' }} />
                                    ) }
                                  </>
                                ) : cell === 'checkBox' ? (
                                  <Checkbox
                                    onChange={() => {
                                      if (checkBoxTrigger) {
                                        checkBoxTrigger({ e: row, value: !row[cell] });
                                      }
                                    }}
                                    defaultChecked={!!row[cell]}
                                    checked={!!row[cell]}
                                  />
                                ) : row[cell]
                        }
                      </TableCell>
                    ))}
                    {controls
                      ? (
                        <TableCell>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              gap: '10px',
                              justifyContent: 'flex-end'
                            }}
                          >
                            {onEdit
                                          && (
                                          <EditButton
                                            onEdit={() => onEdit && onEdit(row)}
                                          />
                                          )}
                            {onDelete && (
                              <DeleteButton
                                title={titleDeleteButton}
                                onDelete={() => onDelete && onDelete(row)}
                              />
                            )}
                          </Box>
                        </TableCell>
                      )
                      : null}
                  </TableRowHover>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={displayedCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length
        ? <NoData />
        : null}
      {rows.length > countRowsPerPage
        ? (
          <TablePagination
            rowsPerPageOptions={[countRowsPerPage, 15, 30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )
        : null}
    </Box>
  );
}

ControlledTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  headCells: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  displayedCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  countRowsPerPage: PropTypes.number.isRequired,
  defualtOrderBy: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired,
  controls: PropTypes.bool,
  onDelete: PropTypes.func,
  titleDeleteButton: PropTypes.string,
  onEdit: PropTypes.func,
  checkBoxTrigger: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  isControlledSort: PropTypes.bool,
  onRequestSort: PropTypes.func,
  renderCustomControls: PropTypes.func,
  onSelectItem: PropTypes.func,
  onSelectAllItems: PropTypes.func,
  disableCheckbox: PropTypes.bool,
  dense: PropTypes.bool,
  hover: PropTypes.bool,
  isSelectOnClickRow: PropTypes.bool,
};

ControlledTable.defaultProps = {
  defualtOrderBy: '',
  rows: [],
  controls: false,
  onDelete: null,
  titleDeleteButton: 'Удалить',
  onEdit: null,
  checkBoxTrigger: null,
  order: '',
  orderBy: '',
  isControlledSort: false,
  onRequestSort: () => {},
  renderCustomControls: () => {},
  onSelectItem: () => {},
  onSelectAllItems: () => {},
  disableCheckbox: false,
  dense: false,
  hover: false,
  isSelectOnClickRow: true,
};

export default ControlledTable;
