// Функци To DTO берет фронтовые данные и превращает их в данные котоые хочет видеть бек
export function tariffPlansResourceToDtoServiceObject(dto) {
  const newData = { ...dto };

  delete newData.description;

  return newData;
}

export default tariffPlansResourceToDtoServiceObject;
