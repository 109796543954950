import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useDeletePaymentResourceMutation, useLazyGetPaymentResourceQuery } from "../redux/paymentResource.api";
// eslint-disable-next-line boundaries/element-types

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function PaymentResourceBll({ children }) {
  const [data, setData] = useState();

  const [
    fetchPaymentResource,
    { data: dataPaymentResourceId }
  ] = useLazyGetPaymentResourceQuery();

  const [
    deletePaymentResource,
  ] = useDeletePaymentResourceMutation();

  const deletePaymentResourceParams = useCallback(({ id }) => deletePaymentResource({
    id
  }), []);

  const fetchPaymentResourceParams = useCallback(({
    company, dateFrom, dateTo, type
  }) => {
    const newParams = {};
    if (company) {
      newParams["companyId.equals"] = company;
    }

    if (dateFrom) {
      newParams["payDate.greaterThanOrEqual"] = dateFrom;
    }

    if (dateTo) {
      newParams["payDate.lessThan"] = dateTo;
    }

    if (type) {
      newParams["type.equals"] = type;
    }

    return fetchPaymentResource({
      params: {
        ...newParams
      }
    });
  }, []);

  useEffect(() => {
    setData({
      dataPaymentResourceId
    });
  }, [dataPaymentResourceId]);

  const props = {
    data,
    fetchPaymentResourceParams,
    deletePaymentResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { Children
        .toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

PaymentResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PaymentResourceBll;
