// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehicleFromDtoService from "../services/data/vehicle-from-dto.service";
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehicleApi = createApi({
  reducerPath: `${reducerPath.vehicle}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/vehicles`),
  endpoints: (builder) => ({
    getVehiclesResource: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.GET }),
        params,
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleFromDtoService
    }),
    getVehicleId: builder.mutation({
      query: ({ id }) => ({
        ...createHeadRequest({ url: `/${id}`, method: HttpMethods.GET }),
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleFromDtoService
    }),
    getVehicleIdResourceQuery: builder.query({
      query: ({ id }) => ({
        ...createHeadRequest({ url: `/${id}`, method: HttpMethods.GET }),
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleFromDtoService
    }),
  }),
});

export const {
  useGetVehicleIdMutation,
  useGetVehiclesResourceQuery,
  useLazyGetVehiclesResourceQuery,
  useGetVehicleIdResourceQueryQuery
} = vehicleApi;
