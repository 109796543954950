// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// import/no-named-as-default
import companyAccountResourceFromDtoService from "../services/data/companyAccountResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const companyAccountResourceApi = createApi({
  reducerPath: `${reducerPath.companyAccountResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/company-accounts`),
  endpoints: (builder) => ({
    getCompanyAccountResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: companyAccountResourceFromDtoService,
    }),

    getCompanyAccountResourceId: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: companyAccountResourceFromDtoService,
    }),

    postCompanyAccountResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: companyAccountResourceFromDtoService,
    }),
    patchCompanyAccountResource: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PUT
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: companyAccountResourceFromDtoService,
    }),
  }),
});

export const {
  useGetCompanyAccountResourceQuery,
  useLazyGetCompanyAccountResourceQuery,
  usePostCompanyAccountResourceMutation,
  usePatchCompanyAccountResourceMutation,
  useLazyGetCompanyAccountResourceIdQuery
} = companyAccountResourceApi;
