import React, {
  useEffect,
  useState
} from 'react';
import {
  Alert, AlertTitle, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import style from './PaymentEditPanel.module.css';
import { appUsersResourceType } from "../../../entities/account/appUsersResource/redux/appUsersResource.slice";
import { typesPay } from "../PaymentInfoPanel/PaymentInfoPanel";

// Отображает панель изменение пользователя
function PaymentEditPanel({
  data,
  putPaymentResourceParams,
}) {
  const navigate = useNavigate();
  const [status, setStatus] = useState('');

  const [isErr, setIsErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const [comment, setComment] = useState('');
  const [sum, setSum] = useState('');
  useEffect(() => {
    if (data?.dataPaymentResourceId?.data) {
      setStatus(data.dataPaymentResourceId.data?.type);
      setComment(data.dataPaymentResourceId.data?.comment);
      setSum(data.dataPaymentResourceId.data?.sum);
    }
  }, [data]);

  const handleSaveChanges = () => {
    const newData = JSON.parse(JSON.stringify(data?.dataPaymentResourceId?.data));
    newData.type = status;
    newData.sum = sum;
    newData.comment = comment;
    putPaymentResourceParams({
      id: newData.compPaymentId,
      body: newData
    }).then((e) => {
      if (e.error) {
        setIsErr(true);
      } else {
        setIsCorrect(true);
        setTimeout(() => {
          navigate(`/finances/payments`);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setIsErr(false);
  }, [status]);

  return (
    <Box className={style.UsersInfoPanel}>
      <FormControl fullWidth>
        <InputLabel id="category-label">Тип операции</InputLabel>
        <Select
          labelId="category-label"
          id="category-label"
          value={status || 0}
          label="Категория сообщений"
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          {typesPay.map((typesPayCurrent, i) => (
            <MenuItem
              key={`alertsType-select-item${typesPayCurrent.id}-${i + 1}`}
              value={typesPayCurrent.id}
            >
              {typesPayCurrent.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={sum || ''}
        type="number"
        onChange={(e) => {
          setSum(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Сумма"
        variant="outlined"
      />

      <TextField
        value={comment || ''}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Коментарий"
        variant="outlined"
        multiline
        rows={4}
        maxRows={Infinity}
      />

      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Изменения успешно сохранены
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}

      <Box className={style.buttonsPanel}>
        <Button onClick={handleSaveChanges} variant="contained">Сохранить</Button>
        <Button variant="contained" onClick={() => navigate(`/finances/payments`)}>
          {!isErr && isCorrect ? 'Назад' : 'Отмена'}
        </Button>
      </Box>
    </Box>
  );
}

PaymentEditPanel.propTypes = {
  data: PropTypes.shape({
    dataPaymentResourceId: PropTypes.shape({
      data: PropTypes.shape(appUsersResourceType)
    }),
  }),
  putPaymentResourceParams: PropTypes.func,
};

PaymentEditPanel.defaultProps = {
  data: null,
  putPaymentResourceParams: null
};

export default PaymentEditPanel;
