import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
import apiSlice from '../../../../shared/api/apiSlice';

const url = '/company-payments';

// Описываем методы обращения к беку
const companyPaymentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyPayments: builder.query({
      query: ({
        params
      }) => ({
        url,
        method: HttpMethods.GET,
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['company-payments'],
    }),
  }),
});

export default companyPaymentsApi;
