// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import appUsersResourceDtoType from "../../types/appUsersResource-dto.type";

export function appUsersResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

appUsersResourceFromDtoService.propTypes = {
  dto: appUsersResourceDtoType,
};

export default appUsersResourceFromDtoService;
