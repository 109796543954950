import getWeekDay from "./getWeekDay";
import isCurrentDateInRange from "./isCurrentDateInRange";

const getDayStringFromDate = (currentDate, futureDate, date) => (
  isCurrentDateInRange(currentDate, futureDate, date)
    ? getWeekDay(date)
    : date?.toLocaleDateString('ru', {
      day: "2-digit",
      month: "2-digit",
      year: currentDate.getFullYear() === date.getFullYear() ? undefined : "2-digit",
    }));

export default getDayStringFromDate;
