import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import style from './CompanyPassengersPanel.module.css';
import PassengersListWidget from '../../../widgets/passengers/PassengersListWidget/PassengersListWidget';
import PassengersFormWidget from '../../../widgets/passengers/PassengersFormWidget/PassengersFormWidget';
import PassengersFormCreateWidget from '../../../widgets/passengers/PassengersFormCreateWidget/PassengersFormCreateWidget';
import { getCompanyCargos, getVehicleRoutePlacesTrips, postCompanyCargosImport } from '../../../shared/api/api';
import useShowError from '../../../shared/lib/hooks/useShowError';
import PassengersListToolbarWidget from '../../../widgets/passengers/PassengersListToolbarWidget/PassengersListToolbarWidget';
import CARGO_TYPE from '../../../shared/constants/cargoTypes';

const errorCode = 'Validation failed for classes [su.vistar.vistarm.domain.User] during persist time for groups [javax.validation.groups.Default, ]\nList of constraint violations:[\n\tConstraintViolationImpl{interpolatedMessage=\'must match "^(?>[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*)|(?>[_.@A-Za-z0-9-]+)$"\', propertyPath=login, rootBeanClass=class su.vistar.vistarm.domain.User, messageTemplate=\'{javax.validation.constraints.Pattern.message}\'}\n]';

const DELETED_STATUS = 'CARGO_STATUS_DELETED';

const sizesTable = [15, 50, 100];

// Отображает панель работы с пассажирами компании
function CompanyPassengersPanel(props) {
  const {
    companyId, toogleSelectedNode, treeSelectedKeys, cargoType
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const pageQuery = Math.max(Number(searchParams.get('page')), 0) || 0;
  const size = Number(searchParams.get('size'));
  const sizeQuery = sizesTable.includes(size) ? size : 15;
  const sortQuery = searchParams.get('sort') || 'title';
  const orderQuery = searchParams.get('order') || 'asc';
  const searchTextQuery = searchParams.get('search') || '';

  const [currentCompanyId, setCurrentCompanyId] = useState(companyId);
  const [totalCount, setTotalCount] = useState(0);

  const handleChangePage = (event, value) => {
    setSearchParams((prevParams) => {
      prevParams.set("page", value);
      return prevParams;
    });
  };

  const changeSortParams = (property) => {
    const isAsc = sortQuery === property && orderQuery === 'asc';
    setSearchParams((prevParams) => {
      prevParams.set("order", isAsc ? 'desc' : 'asc');
      prevParams.set("sort", property);
      return prevParams;
    });
  };

  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const [update, setUpdate] = useState(false);
  const showError = useShowError();

  // Изменение кол-ва отображаемых строк на странице
  const handleChangeRowsPerPage = (event) => {
    setSearchParams((prevParams) => {
      prevParams.set("page", 0);
      prevParams.set("size", parseInt(event.target.value, 10));
      return prevParams;
    });
  };

  const [companyCargos, setCompanyCargos] = useState(null);
  const [isLoadingCargos, setIsLoadingCargos] = useState(false);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(false);
  const [places, setPlaces] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);

  useEffect(() => {
    setCurrentCompanyId(companyId);
    setCompanyCargos(null);
  }, [companyId]);

  const updateCompanyCargos = useCallback(() => {
    setCompanyCargos(null);
    setUpdate((prev) => !prev);
  }, []);

  const [isSuccessfulImport, setIsSuccessfulImport] = useState(false);
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setIsLoadingImport(true);
    postCompanyCargosImport(currentCompanyId, formData)
      .then(() => {
        updateCompanyCargos();
        setIsSuccessfulImport(true);
        setTimeout(() => {
          setIsSuccessfulImport(false);
        }, 1000);
      })
      .catch((err) => {
        if (err.data === errorCode) {
          showError("Ошибка в содержимом файла. Избегайте использования пробелов и специальных символов. Кириллица не допускается для логина.");
        } else {
          showError('Произошла ошибка при загрузке из файла');
        }

        setIsSuccessfulImport(false);
      })
      .finally(() => {
        setIsLoadingImport(false);
        e.target.value = null;
      });
  };

  const search = (value) => {
    setSearchParams((prevParams) => {
      prevParams.set("search", value.trim());
      prevParams.set("page", 0);
      return prevParams;
    });
    setCompanyCargos(null);
  };

  useEffect(() => {
    setCompanyCargos(null);
    setSelected([]);
    setSelectedCargos([]);
    const text = searchTextQuery ? { 'text.contains': searchTextQuery } : {};
    const body = {
      'companyId.equals': currentCompanyId,
      'cargoType.equals': cargoType,
      'lastStatus.notEquals': DELETED_STATUS,
      page: pageQuery,
      size: sizeQuery,
      sort: `${sortQuery},${orderQuery}`,
      ...text,
    };
    setIsLoadingCargos(true);
    getCompanyCargos(body)
      .then((res) => {
        const cargoIds = res.cargos.map((cargo) => ({ cargoIds: cargo.compCargoId }));
        if (!cargoIds.length) {
          setCompanyCargos(res.cargos);
          setTotalCount(res?.totalCount || 0);
          return;
        }
        const statuses = [
          'VROUTE_STATUS_PLANNED',
          'VROUTE_STATUS_ACTIVE',
          // 'VROUTE_STATUS_CLOSED',
        ];
        const routeStatuses = statuses.map((status) => ({ routeStatuses: status }));
        const params = [
          ...cargoIds,
          { maxRoutesCount: 5 },
          ...routeStatuses,
        ];
        setIsLoadingPlaces(true);
        getVehicleRoutePlacesTrips(params)
          .then((resPlaces) => {
            setPlaces(resPlaces);
            setCompanyCargos(res.cargos);
            setTotalCount(res?.totalCount || 0);
          })
          .catch(() => {
            showError('Ошибка при загрузке данных');
          })
          .finally(() => {
            setIsLoadingPlaces(false);
          });
      })
      .catch(() => {
        showError('Ошибка при загрузке данных');
      })
      .finally(() => {
        setIsLoadingCargos(false);
      });
  }, [
    currentCompanyId, pageQuery, sizeQuery, update,
    orderQuery, sortQuery, searchTextQuery, cargoType
  ]);

  return (
    <Box className={style.panel}>
      <Routes>
        <Route
          path="/"
          element={(
            <Box className={style.listPanel}>
              <PassengersListToolbarWidget
                handleUpload={handleUpload}
                handleSearch={search}
                isLoadingImport={isLoadingImport}
                searchText={searchTextQuery}
                isSuccessfulImport={isSuccessfulImport}
                cargoType={cargoType}
              />
              <PassengersListWidget
                companyId={currentCompanyId}
                isLoadingCargos={isLoadingCargos}
                isLoadingPlaces={isLoadingPlaces}
                totalCount={totalCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                companyCargos={companyCargos}
                places={places}
                rowsPerPage={sizeQuery}
                order={orderQuery}
                orderBy={sortQuery}
                changeSortParams={changeSortParams}
                page={pageQuery}
                updateCompanyCargos={updateCompanyCargos}
                toogleSelectedNode={toogleSelectedNode}
                treeSelectedKeys={treeSelectedKeys}
                selected={selected}
                setSelected={setSelected}
                selectedCargos={selectedCargos}
                setSelectedCargos={setSelectedCargos}
                sizesTable={sizesTable}
                cargoType={cargoType}
              />
            </Box>
          )}
        />
        <Route
          path="edit/:compCargoId"
          element={(
            <PassengersFormWidget
              companyId={currentCompanyId}
              updateCompanyCargos={updateCompanyCargos}
              cargoType={cargoType}
            />
          )}
        />
        <Route
          path="create"
          element={(
            <PassengersFormCreateWidget
              companyId={currentCompanyId}
              updateCompanyCargos={updateCompanyCargos}
              cargoType={cargoType}
            />
          )}
        />
      </Routes>
    </Box>
  );
}

CompanyPassengersPanel.propTypes = {
  companyId: PropTypes.number,
  toogleSelectedNode: PropTypes.func.isRequired,
  treeSelectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  cargoType: CARGO_TYPE.type,
};

CompanyPassengersPanel.defaultProps = {
  companyId: null,
  cargoType: CARGO_TYPE.default,
};

export default CompanyPassengersPanel;
