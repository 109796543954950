import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PropTypes from 'prop-types';

// Кнопка активации с иконкой и подписью
function ActivateButton({
  name, ...other
}) {
  return (
    <Box>
      <Tooltip title={`Активировать ${name}`.trim()}>
        <span>
          <IconButton
            color="primary"
            {...other}
          >
            <PlayCircleFilledWhiteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}

ActivateButton.propTypes = {
  name: PropTypes.string,
};

ActivateButton.defaultProps = {
  name: '',
};

export default ActivateButton;
