import {
  Autocomplete, Box, Button, Divider, Grid, TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function FiltersAlertsWidget({
  rows, setFilteredRows, severities, isFiltersOpen, alertCategories
}) {
  const defaultFilters = {
    vehicle: '',
    description: '',
    severity: null,
    startTime: '',
    finishTime: '',
    category: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  // проверка строки на соответсвие полям фильтра
  const checkRow = (row, rowsfilters) => {
    const rowsfiltersKeys = Object.keys(rowsfilters);
    for (let i = 0; i < rowsfiltersKeys.length; i += 1) {
      const filter = rowsfiltersKeys[i];
      const value = filters[filter];
      if (filter === 'startTime' || filter === 'finishTime') {
        if (filter === 'startTime') {
          const startTime = new Date(value);
          if (row.beginTime.getTime() - startTime.getTime() < 0) return false;
        } else {
          const finishTime = new Date(value);
          if (finishTime.getTime() - row.beginTime.getTime() < 0) return false;
        }
      }

      if (filter === 'vehicle' || filter === 'description' || filter === 'severity') {
        if (!row[filter].toLowerCase().includes(value.toLowerCase())) return false;
      }

      if (filter === 'category') {
        if (`${row.сategory}` !== value) {
          return false;
        }
      }
    }
    return true;
  };

  const handleFilterRows = () => {
    let newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        newFilters = { ...newFilters, [key]: filters[key] };
      }
    });

    const newRows = rows.filter((row) => checkRow(row, newFilters));
    setFilteredRows({ rows: newRows, filters: newFilters });
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
    setFilteredRows({ rows, filters: {} });
  };

  const changeTextField = (event) => {
    setFilters((prev) => ({ ...prev, [event.target.id]: event.target.value }));
  };

  const changeComboBox = (event, field, newValue) => {
    setFilters((prev) => ({ ...prev, [field]: newValue || null }));
  };

  useEffect(() => {
    // const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    // const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -8);

    setFilters({
      vehicle: '',
      description: '',
      severity: null,
      startTime: '',
      // finishTime: localISOTime,
      finishTime: '',
      category: '',
    });
  }, [rows]);

  const isDisp = isFiltersOpen ? { display: 'flex' } : { display: 'none' };

  return (
    <Box sx={{ ...isDisp }}>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={6}>
          <Box sx={{
            display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'
          }}
          >
            <TextField
              size="small"
              fullWidth
              id="startTime"
              label="Дата"
              onChange={changeTextField}
              type="datetime-local"
              value={filters.startTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ max: filters.finishTime }}
            />
            -
            <TextField
              size="small"
              fullWidth
              sx={{ min: filters.startTime }}
              min={filters.startTime}
              value={filters.finishTime}
              onChange={changeTextField}
              id="finishTime"
              label=""
              type="datetime-local"
              inputProps={{ min: filters.startTime }}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            sx={{ width: '100%' }}
            size="small"
            value={filters.severity || null}
            onChange={(event, newValue) => changeComboBox(event, 'severity', newValue)}
            id="severity"
            options={severities}
            renderInput={(params) => <TextField {...params} label="Серьезность" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            sx={{ width: '100%' }}
            size="small"
            value={filters.category || null}
            onChange={(event, newValue) => changeComboBox(event, 'category', newValue)}
            id="category"
            options={Object.keys(alertCategories).map((key) => alertCategories[key])}
            renderInput={(params) => <TextField {...params} label="Категория" />}
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            size="small"
            id="description"
            label="Текст сообщения"
            value={filters.description}
            onChange={changeTextField}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            id="vehicle"
            label="Транспортное средство"
            value={filters.vehicle}
            onChange={changeTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'right', gap: "30px" }}>
            <Button
              disableElevation
              onClick={handleResetFilters}
              variant="text"
            >
              Сбросить
            </Button>
            <Button
              disableElevation
              onClick={handleFilterRows}
              variant="contained"
            >
              Поиск
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ pt: 1 }} />
    </Box>
  );
}

FiltersAlertsWidget.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  setFilteredRows: PropTypes.func.isRequired,
  severities: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFiltersOpen: PropTypes.bool.isRequired,
  alertCategories: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default FiltersAlertsWidget;
