import React, {
  useCallback, useEffect, useState, useMemo
} from 'react';
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import GeoMapWithTiles from "../../../features/geo-map/GeoMapWithTiles";
import style from "./StatePanel.module.css";
import RouteVectorLayer from "../../../shared/ui/GeoMap/RouteVectorLayer";
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import AlertsResourceBll from "../../../entities/alerts/alertsResource/AlertsResourceBll/AlertsResourceBll";
import { alertsResourceType } from "../../../entities/alerts/alertsResource/redux/alertsResource.slice";
import TrackPointsResourceBll
  from "../../../entities/vehicles/trackPointsResource/TrackPointsResourceBll/TrackPointsResourceBll";
import { platoonGroupsResourceType } from "../../../entities/vehicles/trackPointsResource/redux/trackPointsResource.slice";
// eslint-disable-next-line boundaries/element-types
import { transformCoords } from "../VehicleMapPanel/VehicleMapPanel";
import MarkerVectorLayer from "../../../shared/ui/GeoMap/MarkerVectorLayer";
import { vehicleType } from "../../../entities/vehicles/vehicle/redux/vehicle.slice";
import convertFormatDate from "../../../shared/utils/convertFormatDate";
import isCurrentDateInRange from "../../../shared/utils/isCurrentDateInRange";
import calculateSpeed from "../../../shared/utils/calculateSpeed";
import splitRoutes from "../../../shared/utils/splitRoutes";
import { followModeActions, FollowModeEnum } from "../../../entities/map/followMode/redux/followMode.slice";
import { useAppDispatch } from "../../../app/store";
import { DEFAULT_ZOOM } from '../../../entities/map/zoomController/redux/zoomController.slice';

const alertsHeadCells = [
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Сообщение',
  },
  {
    id: 'beginTime',
    numeric: false,
    disablePadding: false,
    label: 'Отправлено',
  },
  {
    id: 'severity',
    numeric: false,
    disablePadding: false,
    label: 'Серьезность',
  },
  {
    id: 'coordinatesWithIco',
    numeric: false,
    disablePadding: false,
    label: 'Координаты',
  },
];

const alertsDisplayedCells = [
  'description',
  'beginTime',
  'severity',
  'coordinatesWithIco',
];

const problems = {
  top: "top",
  left: "left",
  right: "right",
  bottom: "bottom"
};

function StatePanel({ data, vehicleId }) {
  const vehicle = data?.dataVehicleIdResource?.data;
  const [alerts, setAlerts] = useState(null);
  // eslint-disable-next-line no-shadow
  const setAlertsProps = useCallback((data) => setAlerts(data), []);
  const [activeCoordinates, setActiveCoordinates] = useState(null);
  const [activeRoute, setActiveRoute] = useState(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(followModeActions.changeMode({ [FollowModeEnum.isActive]: true }));
  }, []);
  useEffect(() => {
    setActiveCoordinates(null);
  }, [vehicleId]);
  return (
    <Box>
      <Box sx={{
        display: "flex", gap: "40px", marginTop: "40px", position: "relative"
      }}
      >
        <TrackPointsResourceBll vehicleId={vehicleId}>
          <HazardInformation
            activeCoordinates={activeCoordinates}
            alerts={alerts}
            vehicle={vehicle}
            activeRoute={activeRoute}
          />
        </TrackPointsResourceBll>
        {/* <TrafficLight /> */}
      </Box>
      <AlertsResourceBll vehicleId={vehicleId} setActiveRoute={setActiveRoute}>
        <Alerts setActiveCoordinates={setActiveCoordinates} setAlertsProps={setAlertsProps} />
      </AlertsResourceBll>
    </Box>
  );
}

StatePanel.propTypes = {
  vehicleId: PropTypes.number,
  data: PropTypes.shape({
    dataVehicleIdResource: PropTypes.shape({
      data: PropTypes.shape(vehicleType)
    })
  })
};

StatePanel.defaultProps = {
  vehicleId: null,
  data: null
};

function Alerts({
  data, setAlertsProps, setActiveCoordinates
}) {
  const alertsArr = data?.dataAlertsResource?.data;

  useEffect(() => {
    if (alertsArr) {
      setAlertsProps(alertsArr);
    }
  }, [data]);

  return (
    <Box sx={{ marginTop: "50px" }}>
      <Typography sx={{
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
        marginBottom: "10px"
      }}
      >
        Последние сообщения
      </Typography>
      <Box>
        <ControlledTable
          disableCheckbox
          isSelectOnClickRow={false}
          rows={alertsArr?.map((e, i) => {
            const newData = JSON.parse(JSON.stringify(e));
            newData.id = i;
            newData.beginTime = convertFormatDate(e.beginTime).toFormat;
            return newData;
          })}
          headCells={alertsHeadCells}
          displayedCells={alertsDisplayedCells}
          countRowsPerPage={5}
          selected={[]}
          setSelected={(e) => {
            setActiveCoordinates(
              {
                description: alertsArr[e[0]]?.description,
                latitude: alertsArr[e[0]]?.latitude,
                longitude: alertsArr[e[0]]?.longitude,
              }
            );
          }}
        />
      </Box>
    </Box>
  );
}

Alerts.propTypes = {
  data: PropTypes.shape({
    dataAlertsResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(alertsResourceType)
      )
    })
  }),
  setAlertsProps: PropTypes.func,
  setActiveCoordinates: PropTypes.func,
};

Alerts.defaultProps = {
  data: null,
  setAlertsProps: null,
  setActiveCoordinates: null,
};

function divideByTenMillion(number) {
  return number / 10000000;
}

function calculateDirection({
  clonProps, clatProps, plonProps, platProps
}) {
  const clon = clonProps ? divideByTenMillion(clonProps) : null;
  const clat = clatProps ? divideByTenMillion(clatProps) : null;
  const plon = plonProps ? divideByTenMillion(plonProps) : null;
  const plat = platProps ? divideByTenMillion(platProps) : null;
  if ((plat && plon && clat && clon)
  ) {
    // Вектор текущего движения
    const vlon = clon - plon;
    const vlat = clat - plat;
    const length = Math.sqrt(vlat * vlat + vlon * vlon);
    const sinA = vlon / length;
    const a = Math.asin(sinA);
    const signCosA = Math.sign(vlat / length);
    let degree = (180 / Math.PI) * a;

    if (signCosA === -1) {
      degree = 180 - degree;
    }
    if (degree < 0) {
      degree += 360;
    }
    return Math.round(degree * 100) / 100;
  }
  return undefined;
}

function calculateDenmSide({
  clonProps, clatProps, plonProps, platProps, dlatProps, dlonProps
}) {
  const clon = clonProps ? divideByTenMillion(clonProps) : null;
  const clat = clatProps ? divideByTenMillion(clatProps) : null;
  const plon = plonProps ? divideByTenMillion(plonProps) : null;
  const plat = platProps ? divideByTenMillion(platProps) : null;
  const dlat = dlatProps ? divideByTenMillion(dlatProps) : null;
  const dlon = dlonProps ? divideByTenMillion(dlonProps) : null;

  if ((plat && plon && clat && clon && dlat && dlon)
  ) {
    // Вектор текущего движения
    const vlon = clon - plon;
    const vlat = clat - plat;
    const newDlat = dlat;
    const newDlon = dlon;
    // Вектор направления на опасность
    const vDClat = newDlat - clat;
    const vDClon = newDlon - clon;
    const sinA = ((vDClat * vlon + vDClon * vlat)
        / Math.sqrt(vDClat * vDClat + vDClon * vDClon)
        / Math.sqrt(vlat * vlat + vlon * vlon));
    const signCosA = Math.sign((vDClat * vlat + vDClon * vlon));
    const a = Math.asin(sinA);

    let degrees = (180 / Math.PI) * a;
    if (signCosA === -1) {
      degrees = 180 - degrees;
    }

    if (degrees > 180) {
      degrees -= 360;
    }

    const res = [];

    if (degrees >= -45 && degrees <= 45) {
      res.push(problems.top);
    } else if (degrees > 45 && degrees <= 135) {
      res.push(problems.left);
    } else if (degrees > -135 && degrees <= -45) {
      res.push(problems.right);
    } else {
      res.push(problems.bottom);
    }

    return { denmSide: res, direction: degrees };
  }
  return { denmSide: [], direction: 0 };
}

function HazardInformation({
  data, vehicle, alerts, activeCoordinates, activeRoute
}) {
  const trackPoints = useMemo(
    () => data?.dataTrackPointsResource?.data,
    [data?.dataTrackPointsResource]
  );
  const [vehicleProblem, setVehicleProblem] = useState([]);
  const [direction, setDirection] = useState(null);
  const [speed, setSpeed] = useState(null);

  const [date, setDate] = useState(null);

  useEffect(() => {
    const dateNow = new Date();
    setDate(() => convertFormatDate(dateNow).toFormat);
  });

  useEffect(() => {
    setVehicleProblem(() => []);
    if (trackPoints?.length >= 2 && alerts?.length) {
      const coordinatesNow = trackPoints[0];
      const coordinatesPrev = trackPoints[1];

      alerts?.forEach((alertsNow) => {
        if (alertsNow.alertType?.alertTypeId === 100 || alertsNow.alertType?.alertTypeId === 101) {
          // новые вычисления
          if (isCurrentDateInRange(alertsNow.beginTime, alertsNow.endTime)) {
            // координаты опасности (из активного сообщения)
            const { dLat, dLon } = { dLat: alertsNow.latitude, dLon: alertsNow.longitude };
            if (dLat && dLon) {
              // текущие координаты ТС;
              const {
                cLat,
                cLon
              } = { cLat: coordinatesNow.latitude, cLon: coordinatesNow.longitude };
              // предыдущие координаты ТС
              const {
                pLat,
                pLon
              } = { pLat: coordinatesPrev.latitude, pLon: coordinatesPrev.longitude };

              const { denmSide } = calculateDenmSide({
                clonProps: cLon,
                clatProps: cLat,
                plonProps: pLon,
                platProps: pLat,
                dlatProps: dLat,
                dlonProps: dLon
              });
              setVehicleProblem(denmSide);
            }
          }
        }
      });
    }
  }, [trackPoints, alerts]);

  const [trackPointsMain, setTrackPointsMain] = useState([]);

  useEffect(() => {
    setVehicleProblem(() => []);
    if (trackPoints?.length >= 2) {
      const coordinatesNow = trackPoints[0];
      const coordinatesPrev = trackPoints[1];

      const startLatitude = coordinatesPrev.latitude;
      const startLongitude = coordinatesPrev.longitude;
      const startDate = coordinatesPrev.date;
      const finishLatitude = coordinatesNow.latitude;
      const finishLongitude = coordinatesNow.longitude;
      const finishDate = coordinatesNow.date;

      setSpeed(Math.round(calculateSpeed(
        startLatitude,
        startLongitude,
        startDate,
        finishLatitude,
        finishLongitude,
        finishDate
      )));

      const directionVal = calculateDirection({
        clonProps: finishLongitude,
        clatProps: finishLatitude,
        plonProps: startLongitude,
        platProps: startLatitude,
      });
      setDirection(directionVal);
    }
    setTrackPointsMain(trackPoints?.length ? JSON.parse(JSON.stringify(trackPoints)) : []);
  }, [trackPoints]);

  return (
    <>
      <Box sx={{ width: "360px", height: "400px" }}>
        <GeoMapWithTiles
          styles={style.geoMap}
          center={activeCoordinates
            ? transformCoords(activeCoordinates.longitude, activeCoordinates.latitude)
            : undefined}
          zoom={activeCoordinates ? DEFAULT_ZOOM : undefined}
        >
          <RouteVectorLayer
            coordinates={trackPointsMain?.length && splitRoutes(trackPointsMain)
              .map((currentCoordinates) => currentCoordinates.map(
                (point) => transformCoords(point.longitude, point.latitude)
              ))}
            vehicleId={trackPoints?.[0]?.vehicleId}
            isManyRoutesMode
            isFollowReverseMode
          />
          <MarkerVectorLayer
            text={`${vehicle?.num}${activeRoute ? `(${activeRoute})` : ""}`}
            coordinates={trackPoints?.map((e) => transformCoords(e.longitude, e.latitude))[0] || []}
          />

          {activeCoordinates && (
            <MarkerVectorLayer
              text={activeCoordinates.description}
              coordinates={[
                ...transformCoords(activeCoordinates.longitude, activeCoordinates.latitude)
              ]}
            />
          )}

        </GeoMapWithTiles>
      </Box>
      <InfoVehicle
        vehicleProblem={vehicleProblem}
        vehicle={vehicle}
        direction={direction}
        date={date}
        speed={speed}
      />
    </>
  );
}

HazardInformation.propTypes = {
  activeRoute: PropTypes.string,
  data: PropTypes.shape({
    dataTrackPointsResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(platoonGroupsResourceType)
      )
    })
  }),
  alerts: PropTypes.arrayOf(
    PropTypes.shape(alertsResourceType)
  ),
  vehicle: PropTypes.shape(vehicleType),
  activeCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    description: PropTypes.string,
  })
};

HazardInformation.defaultProps = {
  activeRoute: null,
  data: null,
  alerts: null,
  vehicle: null,
  activeCoordinates: null
};

function InfoVehicle({
  vehicleProblem, vehicle, date, direction, speed
}) {
  return (
    <Box sx={{ display: "flex", gap: "50px" }}>
      <Box sx={{
        display: "flex",
        alignItems: "center"
      }}
      >
        <Box sx={{ position: "relative", marginLeft: "30px", marginRight: "30px" }}>

          {vehicleProblem.includes(problems.top) && (
            <img
              style={{
                position: "absolute",
                top: "-28px",
                left: "50%",
                transform: "translate(-50%, 0)"
              }}
              src="/img/problemY.png"
              alt="ошибка по оси Y"
            />
          )}
          {vehicleProblem.includes(problems.bottom) && (
            <img
              style={{
                position: "absolute",
                bottom: "-28px",
                left: "50%",
                transform: "translate(-50%, 0) rotate(180deg)",
              }}
              src="/img/problemY.png"
              alt="ошибка по оси Y"
            />
          )}
          {vehicleProblem.includes(problems.left) && (
            <img
              style={{
                position: "absolute",
                left: "-30px",
                top: 0
              }}
              src="/img/problemX.png"
              alt="ошибка по оси X"
            />
          )}
          {vehicleProblem.includes(problems.right) && (
            <img
              style={{
                position: "absolute",
                right: "-30px",
                top: 0,
                transform: "rotate(180deg)",
              }}
              src="/img/problemX.png"
              alt="ошибка по оси Y"
            />
          )}

          <img src="/img/carBlue.png" alt="авто" />
        </Box>
      </Box>
      <Box>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Обновление:
          {date}
        </Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Скорость:
          {' '}
          {speed === Infinity ? 0 : speed || 0}
          {' '}
          км/ч
        </Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>Топливо: данных нет</Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          Направление движения:
          {direction ? ` ${direction}°` : " нет данных"}
        </Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)", marginTop: "20px" }}>
          Номер авто:
          {vehicle?.num}
        </Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>Пробег: данных нет</Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)", marginTop: "20px" }}>Комментарий:</Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
          {vehicle?.model.name}
        </Typography>
      </Box>
    </Box>
  );
}

InfoVehicle.propTypes = {
  vehicleProblem: PropTypes.arrayOf(PropTypes.string),
  vehicle: PropTypes.shape(vehicleType),
  date: PropTypes.string,
  direction: PropTypes.number,
  speed: PropTypes.number
};

InfoVehicle.defaultProps = {
  vehicle: null,
  vehicleProblem: null,
  date: null,
  direction: null,
  speed: null
};

export default StatePanel;
