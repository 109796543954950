import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Tab, Tabs,
} from '@mui/material';
import React from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import LogisticsMainPanel from '../../../panels/logistics/LogisticsMainPanel/LogisticsMainPanel';

function useRouteMatch(patterns) {
  const { pathname } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function LogisticsMainRoutes() {
  return (
    <Routes>
      <Route
        path=""
        element={<LogisticsMainPanel />}
      />
    </Routes>
  );
}

const pageUrl = 'logistics/short-path';

const tabs = [
  { path: 'main', name: 'Кратчайший путь', element: <LogisticsMainRoutes /> },
];

const createRoute = (tabPath, id) => `/${pageUrl}/${id}/${tabPath}/*`;

// Отображает страницу "Логистика"
function LogisticsView() {
  const params = useParams();
  const logisticTaskId = parseInt(params.logisticTaskId, 10);
  const routeMatch = useRouteMatch(tabs.map((tab) => createRoute(tab.path, logisticTaskId)));
  const currentTab = routeMatch?.pattern?.path;

  if (Number.isNaN(logisticTaskId)) {
    return (
      <Alert severity="error" sx={{ margin: '30px' }}>
        <AlertTitle>Ошибка</AlertTitle>
        Задача логистики не определена
      </Alert>
    );
  }

  if (!currentTab) {
    return <Navigate to={tabs[0].path} />;
  }
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        paddingBottom: '40px',
      }}
    >
      <Box
        sx={{
          borderBottom: 1, borderColor: 'divider', pt: '20px', marginBottom: '10px'
        }}
      >
        <Tabs value={currentTab}>
          {tabs.map((tab) => (
            <Tab
              key={tab.path}
              label={tab.name}
              value={createRoute(tab.path, logisticTaskId)}
              to={tab.path}
              component={Link}
            />
          ))}
        </Tabs>
      </Box>
      <Routes>
        {tabs.map((tab) => (
          <Route key={tab.path} path={`${tab.path}/*`} element={tab.element} />
        ))}
      </Routes>
    </Container>
  );
}

export default LogisticsView;
