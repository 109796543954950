import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box, Button, CircularProgress, TextField
} from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import style from './CompanyBalanceInfoPanel.module.css';
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import {
  CompanyAccountResourceType
} from "../../../entities/account/companyAccountResource/redux/companyAccountResource.slice";
import { getCompanies } from "../../../shared/api/api";
import { tariffPlansResourceType } from "../../../entities/finances/tariffPlansResource/redux/tariffPlansResource.slice";

const alertsHeadCells = [
  {
    id: 'nameCompany',
    numeric: false,
    disablePadding: false,
    label: 'Имя компании',
    style: { maxWidth: "100px" }
  },
  {
    id: 'accountId',
    numeric: false,
    disablePadding: false,
    label: 'Номер аккаунта',
  },
  {
    id: 'nameCounterparty',
    numeric: false,
    disablePadding: false,
    label: 'Имя контрагента',
  },
  {
    id: 'textAlignRight',
    numeric: false,
    disablePadding: false,
    label: 'Баланс',
  },
  {
    id: 'currentTariffPlan',
    numeric: false,
    disablePadding: false,
    label: 'Текущий тарифный план',
  },
  {
    id: 'nextTariffPlan',
    numeric: false,
    disablePadding: false,
    label: 'Тарифный план следующий',
    style: { maxWidth: "100px" }
  },

];

const alertsDisplayedCells = [
  "nameCompany",
  'accountId',
  'nameCounterparty',
  'textAlignRight',
  'currentTariffPlan',
  'nextTariffPlan',
];

// Отображает панель "Баланс компаний"
function CompanyBalanceInfoPanel({
  dataCompanyAccount,
  dataTariffPlansResource,
  fetchCompanyAccountResourceParams
}) {
  const navigate = useNavigate();

  const [company, setCompany] = useState();
  const [tariffNow, setTariffNow] = useState();
  const [tariffNext, setTariffNext] = useState();
  const [companiesApi, setCompaniesApi] = useState([]);
  const [balanceFrom, setBalanceFrom] = useState();
  const [balanceTo, setBalanceTo] = useState();
  const [rows, setRows] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);
    if (dataTariffPlansResource?.data) {
      getCompanies().then((companies) => {
        setCompaniesApi(companies);
        const data = dataCompanyAccount?.data;

        if (data?.length) {
          setRows(data.map((e) => ({
            nameCompany: companies
              ?.find(((companyCurrent) => companyCurrent.companyId
                    === e.company.companyId)).name,
            accountId: e.companyAccountId,
            nameCounterparty: e.respName || '-',
            textAlignRight: e.balance,
            currentTariffPlan: dataTariffPlansResource.data
              .find((plan) => plan?.tarifPlanId === e.currentPlan?.tarifPlanId)?.name || '-',
            nextTariffPlan: dataTariffPlansResource.data
              .find((plan) => plan?.tarifPlanId === e.nextPlan?.tarifPlanId)?.name || '-'
          })));
        }
      }).finally(() => {
        setIsLoader(false);
      });
    }
  }, [dataCompanyAccount, dataTariffPlansResource]);

  const findButtonHandler = useCallback(() => {
    setIsLoader(true);

    const params = {};

    if (balanceFrom) {
      params['balance.greaterThanOrEqual'] = balanceFrom;
    }

    if (balanceTo) {
      params['balance.lessThan'] = balanceTo;
    }

    if (company) {
      params['companyId.equals'] = company?.companyId;
    }

    if (tariffNow) {
      params['currentPlanId.equals'] = tariffNow?.tarifPlanId;
    }

    if (tariffNext) {
      params['nextPlanId.equals'] = tariffNext?.tarifPlanId;
    }

    // companyId.equals

    fetchCompanyAccountResourceParams({ params }).then((companyAccounts) => {
      setRows(companyAccounts.data.data.map((e) => ({
        nameCompany: companiesApi
          ?.find(((companyCurrent) => companyCurrent.companyId
                === e.company.companyId)).name,
        accountId: e.companyAccountId,
        nameCounterparty: e.respName || '-',
        textAlignRight: e.balance,
        currentTariffPlan: dataTariffPlansResource.data
          .find((plan) => plan?.tarifPlanId === e.currentPlan?.tarifPlanId)?.name || '-',
        nextTariffPlan: dataTariffPlansResource.data
          .find((plan) => plan?.tarifPlanId === e.nextPlan?.tarifPlanId)?.name || '-'
      })));
    }).finally(() => {
      setIsLoader(false);
    });
  }, [company, setRows, companiesApi, balanceFrom, balanceTo, tariffNow, tariffNext]);

  const findAllButtonHandler = useCallback(() => {
    setCompany(null);
    setBalanceFrom(null);
    setBalanceTo(null);
    setTariffNext(null);
    setTariffNext(null);
    setIsLoader(true);
    fetchCompanyAccountResourceParams().then((companyAccounts) => {
      setRows(companyAccounts.data.data.map((e) => ({
        nameCompany: companiesApi
          ?.find(((companyCurrent) => companyCurrent.companyId
                === e.company.companyId)).name,
        accountId: e.companyAccountId,
        nameCounterparty: e.respName || '-',
        textAlignRight: e.balance,
        currentTariffPlan: dataTariffPlansResource.data
          .find((plan) => plan?.tarifPlanId === e.currentPlan?.tarifPlanId)?.name || '-',
        nextTariffPlan: dataTariffPlansResource.data
          .find((plan) => plan?.tarifPlanId === e.nextPlan?.tarifPlanId)?.name || '-'
      })));
    }).finally(() => {
      setIsLoader(false);
    });
  }, [company, setRows, companiesApi, balanceFrom, balanceTo, tariffNow, tariffNext]);
  return (
    <Box className={style.UsersInfoPanel}>
      <Box className={style.search}>
        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          id="company"
          options={companiesApi}
          value={company || {
            companyId: null, name: '',
          }}
          onChange={(_, newValue) => {
            setCompany(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Компания"
            />
          )}
        />
        <TextField
          value={balanceFrom || ''}
          type="number"
          onChange={(e) => {
            setBalanceFrom(e.target.value);
          }}
          sx={{ width: "100%" }}
          label="Баланс от"
          variant="outlined"
        />

        <TextField
          value={balanceTo || ''}
          type="number"
          onChange={(e) => {
            setBalanceTo(e.target.value);
          }}
          sx={{ width: "100%" }}
          label="Баланс до"
          variant="outlined"
        />

      </Box>
      {dataTariffPlansResource?.data.length && (
      <Box className={style.search}>
        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          id="company"
          options={dataTariffPlansResource?.data}
          value={tariffNow || { tarifPlanId: null }}
          onChange={(_, newValue) => {
            setTariffNow(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.tarifPlanId === value.tarifPlanId}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Текущий тарифный план"
            />
          )}
        />
        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          id="company"
          options={dataTariffPlansResource?.data}
          value={tariffNext || {
            tarifPlanId: null, name: '',
          }}
          onChange={(_, newValue) => {
            setTariffNext(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.tarifPlanId === value.tarifPlanId}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Следующий тарифный план"
            />
          )}
        />
      </Box>
      )}
      <Box className={style.groupButton}>
        <Button
          disableElevation
          onClick={findAllButtonHandler}
          variant="text"
        >
          Сбросить
        </Button>
        <Button
          onClick={findButtonHandler}
          variant="contained"
        >
          Поиск
        </Button>
        <Link
          style={{
            display: 'flex',
            width: '97.69px'
          }}
          to="create"
        >
          <Button sx={{ width: '300px' }} variant="contained">Создать</Button>
        </Link>
      </Box>

      {isLoader ? (
        <Box sx={{ width: "max-content", margin: "auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ControlledTable
          rows={rows}
          headCells={alertsHeadCells}
          displayedCells={alertsDisplayedCells}
          countRowsPerPage={10}
          selected={[]}
          controls
          setSelected={() => {}}
          onEdit={(e) => {
            navigate(`edit/${e?.accountId}`);
          }}
        />
      )}

    </Box>
  );
}

CompanyBalanceInfoPanel.propTypes = {
  dataCompanyAccount: CompanyAccountResourceType,
  dataTariffPlansResource: tariffPlansResourceType,
  fetchCompanyAccountResourceParams: PropTypes.func
};

CompanyBalanceInfoPanel.defaultProps = {
  dataCompanyAccount: null,
  dataTariffPlansResource: null,
  fetchCompanyAccountResourceParams: null
};

export default CompanyBalanceInfoPanel;
