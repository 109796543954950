import { DateTimePicker as MUIDateTimePicker, DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import ActionBarDateTimePicker from '../ActionBarDateTimePicker/ActionBarDateTimePicker';
import isValidDate from '../../utils/isValidDate';

function DateTimePicker(props) {
  const {
    label,
    value,
    onChange,
    name,
    onError,
    variant,
    error,
    helperText,
    width,
    required,
    fullWidth,
    dateOnly,
    ...other
  } = props;
  const Component = useMemo(() => (dateOnly ? MUIDatePicker : MUIDateTimePicker), [dateOnly]);
  return (
    <Component
      required={required}
      {...other}
      label={label}
      value={value}
      onChange={(date) => {
        const newDate = isValidDate(date)
          ? new Date(date.setSeconds(0))
          : date;
        onChange(newDate, name);
      }}
      inputProps={{
        placeholder: 'дд.мм.гггг чч:мм',
      }}
      onError={onError}
      renderInput={(params) => (
        <TextField
          variant={variant}
          id={name}
          required={required}
          {...params}
          sx={{ width: !fullWidth ? `${width}px` : undefined, input: { color: (theme) => (error ? theme.palette.error.main : theme.palette.common) } }}
          fullWidth={fullWidth}
          error={error || params.error}
          helperText={error ? helperText : params.error ? 'Некорректная дата/время' : null}
        />
      )}
      components={{
        ActionBar: ActionBarDateTimePicker,
      }}
    />
  );
}

DateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  width: PropTypes.number,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dateOnly: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  label: '',
  variant: 'standard',
  onError: undefined,
  value: null,
  helperText: null,
  error: false,
  width: 220,
  required: false,
  fullWidth: false,
  dateOnly: false,
};

export default DateTimePicker;
