import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line boundaries/element-types
import {
  useGetAppUserResourceIdQuery,
  usePatchAppUserResourceMutation
} from "../appUsersResource/redux/appUsersResource.api";
// eslint-disable-next-line boundaries/element-types
import {
  useGetUserResourceIdQuery,
  usePutUserResourceMutation
} from "../usersResource/redux/usersResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AccountMainBll({ children, idUser, login }) {
  const [data, setData] = useState();

  const { data: dataAppUserResourceId } = useGetAppUserResourceIdQuery({
    id: idUser
  });

  const { data: dataUserResourceId } = useGetUserResourceIdQuery({
    id: login
  });

  useEffect(() => {
    setData({
      dataAppUserResourceId, dataUserResourceId
    });
  }, [dataAppUserResourceId, dataUserResourceId]);

  const [patchAppUserResource] = usePatchAppUserResourceMutation();
  const [putUserResource] = usePutUserResourceMutation();

  const putUserResourceParams = useCallback(
    ({ id, body }) => putUserResource({
      id,
      body
    }),
    []
  );

  const patchAppUserResourceParams = useCallback(
    ({ id, body }) => patchAppUserResource({
      id,
      body
    }),
    []
  );

  const props = {
    data,
    patchAppUserResourceParams,
    putUserResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

AccountMainBll.propTypes = {
  idUser: PropTypes.number,
  login: PropTypes.string,
  children: PropTypes.element.isRequired,
};

AccountMainBll.defaultProps = {
  idUser: null,
  login: null,
};

export default AccountMainBll;
