import PropTypes from 'prop-types';
import {
  Avatar, Box, ListItemAvatar, ListItemText
} from '@mui/material';
import React, { useMemo } from 'react';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import style from './PointCard.module.css';
// eslint-disable-next-line boundaries/element-types
import { typesPoint } from "../../../../features/vehicleRoute/PointForm/PointForm";

// Карточка точки маршрута, отображает нп и промежуток времени прибытия-отправления
function PointCard({ point }) {
  const coords = useMemo(() => {
    if (point.lat && point.lon) {
      return [point.lat / 10000000, point.lon / 10000000];
    }
    return null;
  }, [point]);

  return (
    <Box className={style.pointCard}>
      <ListItemAvatar>
        <Avatar>
          <InsertPhotoIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={point.type === typesPoint.POINT ? coords?.length
          ? coords.join().replace(",", ", ")
          : "Нет данных о точке"
          : point.type === typesPoint.POINT_GEOZONE ? (point.geoZone?.name || "Геозона не указана") : point.geoName?.name || 'Населенный пункт не указан'}
        secondary={`${point.arriveTime?.toLocaleString('ru-RU').slice(0, -3) || ''} – ${(point.leaveTimeUntil || point.leaveTime)?.toLocaleString('ru-RU').slice(0, -3) || ''}`}
      />
    </Box>
  );
}

PointCard.propTypes = {
  point: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  // buttons: PropTypes.element.isRequired,
};

export default PointCard;
