import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {
  BLOCK_MIN_WIDTH, COMMON_PADDING
} from './constants';

export default function ItemBox(props) {
  const {
    routes, children, ...other
  } = props;
  return (
    <Box
      sx={{
        minWidth: `${(routes?.length || 1) * BLOCK_MIN_WIDTH + COMMON_PADDING * (routes?.length || 1)}px`,
        flexGrow: 1,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

ItemBox.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

ItemBox.defaultProps = {
  routes: [],
};
