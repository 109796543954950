import { Box, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import style from './NoData.module.css';

function NoData({ text }) {
  return (
    <Box className={style.noData}>
      <Typography variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

NoData.propTypes = {
  text: PropTypes.string,
};

NoData.defaultProps = {
  text: 'Нет данных для отображения.'
};

export default NoData;
