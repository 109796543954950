import { CircularProgress } from '@mui/material';
import React from 'react';

function LoadingNodeIcon() {
  return (
    <CircularProgress
      size={14}
      thickness={6}
      sx={{ backgroundColor: 'white' }}
      color="inherit"
    />
  );
}

export default LoadingNodeIcon;
