import {
  colors,
} from '@mui/material';

const colorsOfRoutes = [
  colors.blue[600],
  colors.red[400],
  colors.teal[400],
  colors.orange[400],
  colors.pink[400],

  colors.purple[400],
  colors.indigo[400],
  colors.deepPurple[400],
  colors.lightBlue[400],
  colors.lime[500],

  colors.cyan[800],
  colors.blue[900],
  colors.teal[800],
  colors.pink[700],
  colors.brown[400],
];

export default colorsOfRoutes;
