import React from 'react';
import { useParams } from 'react-router-dom';
import LogisticTaskEditorWidget from '../../../widgets/logistics/LogisticTaskEditorWidget/LogisticTaskEditorWidget';
import LogisticTaskBll from '../../../entities/logistic/logisticTasks/LogisticTaskBll/LogisticTaskBll';

function LogisticsMainPanel() {
  const params = useParams();
  const logisticTaskId = parseInt(params.logisticTaskId, 10);

  return (
    <LogisticTaskBll
      logisticTaskId={logisticTaskId}
    >
      <LogisticTaskEditorWidget />
    </LogisticTaskBll>
  );
}

export default LogisticsMainPanel;
