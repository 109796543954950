// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { platoonGroupsResourceEnum } from "../redux/platoonGroupsResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const platoonGroupsResourceDtoType = {
  [platoonGroupsResourceEnum.platoonGroupId]: PropTypes.number,
  [platoonGroupsResourceEnum.name]: PropTypes.string,
  [platoonGroupsResourceEnum.status]: PropTypes.string,
  [platoonGroupsResourceEnum.companyId]: PropTypes.number,
  [platoonGroupsResourceEnum.routeId]: PropTypes.number,
  [platoonGroupsResourceEnum.startDateFrom]: PropTypes.string,
  [platoonGroupsResourceEnum.startDateTo]: PropTypes.string,
  [platoonGroupsResourceEnum.intervalDuration]: {},
  [platoonGroupsResourceEnum.adviseTokens]: PropTypes.string,
  [platoonGroupsResourceEnum.createdDate]: PropTypes.string,
  [platoonGroupsResourceEnum.modifiedDate]: PropTypes.string,
};

export default platoonGroupsResourceDtoType;
