// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import platoonGroupActivitiesResourceFromDtoService from "../services/data/platoonGroupActivitiesResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const platoonGroupActivitiesResourceApi = createApi({
  reducerPath: `${reducerPath.platoonGroupActivitiesResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.platoonGroupsResourceUrl}/platoon-group-activities`),
  endpoints: (builder) => ({
    getPlatoonGroupActivitiesResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: platoonGroupActivitiesResourceFromDtoService
    }),
    patchPlatoonGroups: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: `/1051`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    })
  }),
});

export const {
  useLazyGetPlatoonGroupActivitiesResourceQuery,
  useGetPlatoonGroupActivitiesResourceQuery,
} = platoonGroupActivitiesResourceApi;
