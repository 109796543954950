// eslint-disable-next-line boundaries/no-unknown-files
import React from 'react';
import { createSvgIcon } from "@mui/material";

const GasPump = createSvgIcon(
  <svg
    strokeWidth={0}
    fill="currentColor"
  >
    <path d="M21.36 7.14625L21.3734 7.13083L16.4134 1.39583L15 3.03L17.8134 6.28292C16.56 6.83792 15.6667 8.22542 15.6667 9.875C15.6667 12.0025 17.16 13.7292 19 13.7292C19.48 13.7292 19.92 13.6058 20.3334 13.4054V24.5208C20.3334 25.3687 19.7334 26.0625 19 26.0625C18.2667 26.0625 17.6667 25.3687 17.6667 24.5208V17.5833C17.6667 15.8875 16.4667 14.5 15 14.5H13.6667V3.70833C13.6667 2.0125 12.4667 0.625 11 0.625H3.00004C1.53337 0.625 0.333374 2.0125 0.333374 3.70833V28.375H13.6667V16.8125H15.6667V24.5208C15.6667 26.6483 17.16 28.375 19 28.375C20.84 28.375 22.3334 26.6483 22.3334 24.5208V9.875C22.3334 8.81125 21.96 7.84 21.36 7.14625ZM11 11.4167H3.00004V3.70833H11V11.4167ZM19 11.4167C18.2667 11.4167 17.6667 10.7229 17.6667 9.875C17.6667 9.02708 18.2667 8.33333 19 8.33333C19.7334 8.33333 20.3334 9.02708 20.3334 9.875C20.3334 10.7229 19.7334 11.4167 19 11.4167Z" />
  </svg>,
  'GasPump',
);

export default GasPump;
