import { useContext, useEffect, useState } from "react";

import OLVectorLayer from "ol/layer/Vector";
import { Stroke, Style } from "ol/style";
import { containsCoordinate } from 'ol/extent';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import VectorSource from 'ol/source/Vector';
import PropTypes from 'prop-types';
import GeoMapContext from "../../lib/geo-map/GeoMapContext";
import { useAppSelector } from "../../../app/store";
import reducerPath from "../../../app/reducerPath";

// Задание стилей для векторного слоя
const vectorStyle = new Style({
  stroke: new Stroke({
    color: '#0e97fa',
    width: 8,
  }),
});

// Создает и отображает линию на карте
function RouteVectorLayer(props) {
  const {
    zIndex, coordinates, vehicleId, isManyRoutesMode
  } = props;
  const isAuthenticated = useAppSelector((state) => state[`${reducerPath.followMode}/counter`]).isActive;
  const zoomController = useAppSelector((state) => state[`${reducerPath.zoomController}/counter`]).zoom;
  const [routeId, setRouteId] = useState(vehicleId);

  // текущая карта
  const { map } = useContext(GeoMapContext);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!map) return undefined;
    const layers = map?.getLayers()?.getArray();

    let currentVector = null;
    layers?.forEach((layer) => {
      // Проверяем тип слоя
      if (layer instanceof OLVectorLayer) {
        // Если слой является векторным слоем, сохраняем его
        currentVector = layer;
      }
    });

    const currentLineLayer = currentVector?.getSource()?.getFeatures()[0]?.getGeometry();
    if (isManyRoutesMode) {
      map.removeLayer(currentVector);
      if (coordinates?.length) {
        const lineFeature = [];

        coordinates.forEach((currentCoordinates) => {
          lineFeature.push(new Feature(new LineString(currentCoordinates)));
        });

        const source = new VectorSource({
          features: lineFeature
        });

        // создание векторного слоя
        const newVectorLayer = new OLVectorLayer({
          source,
          style: vectorStyle,
        });

        map.addLayer(newVectorLayer);
        newVectorLayer.setZIndex(zIndex);
        if (isAuthenticated) {
          map.getView().setCenter(
            coordinates[coordinates.length - 1][coordinates[coordinates.length - 1].length - 1]
          );
        }
      }
    } else if (routeId === vehicleId && currentLineLayer) {
      if (coordinates?.length) {
        const extent = map.getView().calculateExtent(map.getSize());
        const coords = coordinates[1] || coordinates[0];
        // Входит ли предпоследняя точка в область видимости
        const isContainsCoordinate = containsCoordinate(extent, coords);
        // Входит ли самая новая точка в область видимости
        const isContainsLastCoords = containsCoordinate(extent, coordinates[0]);

        const curCenter = map.getView().getCenter();
        const isMatchCenter = curCenter[0] === (coordinates[1]?.[0] || coordinates[0]?.[0])
            && curCenter[1] === (coordinates[1]?.[1] || coordinates[0]?.[1]);

        // Если предпоследняя точка входила в область видимости,
        // но последняя не входит или карта была центрирована по предпоследней
        // центрируем карту по последней точке
        if (isAuthenticated) {
          map.getView().setCenter(coordinates[coordinates.length - 1]);
        }
        if (isContainsCoordinate && (!isContainsLastCoords || isMatchCenter) && isAuthenticated) {
          // Задание центра в соответствии с координатами самой новой точки
          map.getView().setCenter(coordinates[0]);
        }
        currentLineLayer.setCoordinates(coordinates);
      } else {
        currentLineLayer.setCoordinates([]);
      }
    } else {
      if (currentVector) {
        map.removeLayer(currentVector);
      }
      if (coordinates?.length) {
        setRouteId(vehicleId);
        const lineFeature = new Feature(new LineString(coordinates));

        const source = new VectorSource({
          features: [lineFeature]
        });
        // создание векторного слоя
        const newVectorLayer = new OLVectorLayer({
          source,
          style: vectorStyle,
        });

        map.addLayer(newVectorLayer);
        newVectorLayer.setZIndex(zIndex);
        map.getView().setCenter(coordinates[0]);
      }
    }
    // TODO следующая строка, вероятно, должна срабатывать только при маунте (см. ниже)
    map.getView().setZoom(zoomController);
  }, [map, coordinates, zIndex, vehicleId, routeId, isAuthenticated]);

  // useEffect(() => {
  //   map.getView().setZoom(zoomController);
  // }, []);

  return null;
}

RouteVectorLayer.propTypes = {
  zIndex: PropTypes.number,
  coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  vehicleId: PropTypes.number,
  isManyRoutesMode: PropTypes.bool,
};

RouteVectorLayer.defaultProps = {
  zIndex: 4,
  coordinates: [],
  vehicleId: null,
  isManyRoutesMode: false
};

export default RouteVectorLayer;
