// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehiclesRoutePointsResourceFromDtoService from "../services/data/vehiclesRoutePointsResource-from-dto.service";
import config from "../../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehiclesRoutePointsResourceApi = createApi({
  reducerPath: `${reducerPath.vehicleRoutePointsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/vehicle-route-points`),
  endpoints: (builder) => ({
    getVehicleRoutePointsResource: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.GET }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: vehiclesRoutePointsResourceFromDtoService
    }),
  }),
});

export const {
  useGetVehicleRoutePointsResourceQuery,
  useLazyGetVehicleRoutePointsResourceQuery,
} = vehiclesRoutePointsResourceApi;
