// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import adapterResourceTypeDtoType from "../../types/adapterResourceType-dto.type";

export function adapterResourceTypeFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

adapterResourceTypeFromDtoService.propTypes = {
  dto: adapterResourceTypeDtoType,
};

export default adapterResourceTypeFromDtoService;
