import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import DrawIcon from '@mui/icons-material/Draw';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import { useConfirm } from 'material-ui-confirm';

function GeometryItemControls(props) {
  const {
    setGeometries,
    id,
    isCreated,
    isSelected,
    isStartDraw,
    stopDraw,
    onDeselect,
    isBlockDraw,
    disabledControls,
  } = props;

  const confirm = useConfirm();

  const onClickDelete = () => {
    confirm({
      title: 'Стереть?',
      confirmationText: 'Да',
      cancellationText: 'Отмена',
      description: `Вы действительно хотите стереть линию?`
    })
      .then(() => {
        setGeometries((prev) => {
          const newGeometries = [...prev];
          newGeometries[id] = {
            ...prev[id],
            isDelete: true,
            isCreated: false,
            initialCoords: [],
          };
          return newGeometries;
        });
      })
      .catch(() => { });
  };

  const onClickDraw = () => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: true,
      }));
      newGeometries[id] = {
        ...prev[id],
        isStartDraw: true,
        isCanceledDraw: false,
        disabledControls: false,
      };
      return newGeometries;
    });
  };

  const onClickEdit = () => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: true,
      }));
      newGeometries[id] = {
        ...prev[id],
        isSelected: true,
        isCanceledModify: false,
        disabledControls: false,
      };
      return newGeometries;
    });
  };

  const onClickCancelDraw = () => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: false,
      }));
      newGeometries[id] = {
        ...prev[id],
        isCanceledDraw: true,
        isStartDraw: false,
      };
      return newGeometries;
    });
  };

  const onClickDoneDraw = () => {
    stopDraw(id);
  };

  const onClickDoneEdit = () => {
    onDeselect(id);
  };

  const onClickCancelEdit = () => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: false,
      }));
      newGeometries[id] = {
        ...prev[id],
        isSelected: false,
        isCanceledModify: true,
      };
      return newGeometries;
    });
  };

  if (isSelected) {
    return (
      <Box>
        <Tooltip title="Готово">
          <span>
            <IconButton
              disabled={disabledControls}
              color="primary"
              size="small"
              onClick={onClickDoneEdit}
            >
              <DoneIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Отмена">
          <span>
            <IconButton
              disabled={disabledControls}
              color="primary"
              size="small"
              onClick={onClickCancelEdit}
            >
              <BlockIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  }

  if (isStartDraw) {
    return (
      <Box>
        <Tooltip title="Готово">
          <span>
            <IconButton
              disabled={disabledControls || isBlockDraw}
              color="primary"
              size="small"
              onClick={onClickDoneDraw}
            >
              <DoneIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Отмена">
          <span>
            <IconButton
              disabled={disabledControls}
              color="primary"
              size="small"
              onClick={onClickCancelDraw}
            >
              <BlockIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  }
  return (
    <Box>
      {isCreated
        ? (
          <>
            <Tooltip title="Изменить">
              <span>
                <IconButton
                  disabled={disabledControls}
                  color="primary"
                  size="small"
                  onClick={onClickEdit}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Стереть">
              <span>
                <IconButton
                  disabled={disabledControls}
                  color="primary"
                  size="small"
                  onClick={onClickDelete}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )
        : (
          <Tooltip title="Нарисовать">
            <span>
              <IconButton
                disabled={disabledControls}
                color="primary"
                size="small"
                onClick={onClickDraw}
              >
                <DrawIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}
    </Box>
  );
}

GeometryItemControls.propTypes = {
  setGeometries: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isCreated: PropTypes.bool,
  isSelected: PropTypes.bool,
  isStartDraw: PropTypes.bool,
  stopDraw: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  isBlockDraw: PropTypes.bool,
  disabledControls: PropTypes.bool,
};

GeometryItemControls.defaultProps = {
  isCreated: false,
  isSelected: false,
  isStartDraw: false,
  isBlockDraw: false,
  disabledControls: false,
};

export default GeometryItemControls;
