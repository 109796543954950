import { useContext, useEffect } from "react";
import OLVectorLayer from "ol/layer/Vector";
import { Vector } from 'ol/source';
import * as olInteraction from 'ol/interaction';
import {
  Circle as CircleStyle, Fill, Stroke, Style, Text
} from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import PropTypes from 'prop-types';
import Point from "ol/geom/Point";
import { fromLonLat, toLonLat } from "ol/proj";
import GeoMapContext from "../../lib/geo-map/GeoMapContext";
// eslint-disable-next-line boundaries/element-types,import/no-cycle
import { findCenter } from "../../../widgets/vehicles/VehicleRouteFormWidget";

// Задание стилей для векторного слоя
const vectorStyle = (props = {}) => {
  const {
    strokeColor = '#0e97fa',
    strokeWidth = 4,
    fillColor = 'rgba(0, 153, 255, 0.2)'
  } = props;
  return new Style({
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth
    }),
    fill: new Fill({
      color: fillColor
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: [0, 153, 255, 1],
      }),
      stroke: new Stroke({
        color: [255, 255, 255, 1],
        width: 5
      })
    }),
    text: new Text({
      font: '14px roboto',
      overflow: true,
      fill: new Fill({
        color: 'black'
      }),
      stroke: new Stroke({
        color: 'white',
        width: 3
      }),
      offsetY: -20
    })
  });
};

// Задание стилей для векторного слоя с числом внутри
const vectorStyleRoutePointNumberInside = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#fff',
    }),
    stroke: new Stroke({
      color: '#1876d2',
      width: 1,
    }),
    radius: 14, // Adjust the radius of the circle as needed
  }),

  text: new Text({
    font: '13px roboto',
    overflow: true,
    fill: new Fill({
      color: 'black', // Text color
    }),
  })
});

let drawVector = null;
let draw = null;
let modify = null;

const onModify = (map, select, handleModify) => {
  // редактирование полигона
  modify = new olInteraction.Modify({
    features: select.getFeatures()
  });
  map.addInteraction(modify);

  modify.on('modifyend', () => {
    const newCoords = select.getFeatures().getArray()[0].getGeometry().getCoordinates();
    if (handleModify) {
      handleModify(newCoords[0]);
    }
  });
};

// Создает и отображает полигон с возможностью редактирования
function PolygonVectorLayer(props) {
  const {
    zIndex,
    isCreatePolygon,
    coordinates,
    setCoordsGeoZone,
    text,
    isChangeable,
    isSelectable,
    strokeColor,
    fillColor,
    strokeWidth,
    numeraryText,
    numerary,
  } = props;

  // текущая карта
  const { map } = useContext(GeoMapContext);
  // текущий слой

  // Создание и отображение полигона
  useEffect(() => {
    if (!map) return undefined;

    // source для слоя по умолчанию, если координаты полигона не переданы
    let source = new Vector({
      projection: map.getView().projection
    });

    // source для слоя по умолчанию, если координаты не переданы
    let source2 = new Vector({
      projection: map.getView().projection
    });

    // если переданы координаты
    if (coordinates?.length) {
      const center = findCenter(coordinates?.map((e) => {
        const cords = toLonLat(e);
        return { lat: cords[1], lon: cords[0] };
      }));
      // Маркер ТС
      const markerFeature = new Feature({
        geometry: new Point(fromLonLat([center.lon, center.lat])),
        tooltipText: "",
      });
      source2 = new VectorSource({
        features: [markerFeature]
      });
    }

    // если переданы координаты для полигона
    if (coordinates?.length) {
      const polygon = new Polygon([coordinates]);
      const polygonFeature = new Feature(polygon);
      source = new VectorSource({
        features: [polygonFeature]
      });

      const extent = polygon.getExtent();

      // Задание просматриваемой области в соответствии с координатами границ
      const size = 100;
      map.getView().fit(extent, { padding: [size, size, size, size] });

      // Задание центра в соответствии с координатами границ полигона
      // map.getView().setCenter([(minx + (maxx - minx) / 2), (miny + (maxy - miny) / 2)], 7);
    }

    // создание векторного слоя полигона
    const newVectorLayer = new OLVectorLayer({
      source,
      style: () => {
        const style = vectorStyle({ strokeColor, fillColor, strokeWidth });
        style.getText().setText(text);
        return style;
      }
    });
    map.addLayer(newVectorLayer);
    newVectorLayer.setZIndex(zIndex);
    let newVectorLayer2;

    if (numerary) {
      // создание векторного слоя полигона
      newVectorLayer2 = new OLVectorLayer({
        source: source2,
        style: () => {
          const style = vectorStyleRoutePointNumberInside;
          style.getText().setText(numeraryText);
          return style;
        }
      });
      map.addLayer(newVectorLayer2);
      newVectorLayer2.setZIndex(zIndex + 1);
    }

    // выбор полигона
    const select = new olInteraction.Select({
      layers: [newVectorLayer],
    });

    if (isSelectable) {
      map.addInteraction(select);
    }

    if (isChangeable) {
      onModify(map, select, (coords) => {
        setCoordsGeoZone(coords);
      });
      // редактирование полигона
    }

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
        map.removeLayer(newVectorLayer2);
        map.removeInteraction(modify);
      }
    };
  }, [
    map,
    coordinates,
    zIndex,
    setCoordsGeoZone,
    isChangeable,
    text,
    fillColor,
    isSelectable,
    strokeColor,
    strokeWidth
  ]);

  // Рисование полигона
  useEffect(() => {
    if (!map) return () => {};
    if (isCreatePolygon) {
      // source для слоя по умолчанию, если координаты полигона не переданы
      const source = new Vector({
        projection: map.getView().projection
      });
      // создание векторного слоя полигона
      drawVector = new OLVectorLayer({
        source,
        style: vectorStyle()
      });
      map.addLayer(drawVector);
      drawVector.setZIndex(zIndex);
      // выбор полигона
      const select = new olInteraction.Select({
        layers: [drawVector],
      });
      map.addInteraction(select);

      onModify(map, select, (coords) => {
        setCoordsGeoZone(coords);
      });

      draw = new olInteraction.Draw({
        type: 'Polygon',
        stopClick: true,
        source: drawVector.getSource()
      });

      map.addInteraction(draw);
      draw.on('drawend', (e) => {
        const newCoords = e.feature.getGeometry().getCoordinates();
        if (setCoordsGeoZone) {
          setCoordsGeoZone(newCoords[0]);
        }
        map.removeInteraction(draw);
      });
    }

    return () => {
      if (map) {
        map.removeLayer(drawVector);
        map.removeInteraction(draw);
        map.removeInteraction(modify);
      }
    };
  }, [map, isCreatePolygon, setCoordsGeoZone, zIndex]);

  return null;
}

PolygonVectorLayer.propTypes = {
  zIndex: PropTypes.number,
  isCreatePolygon: PropTypes.bool,
  coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  setCoordsGeoZone: PropTypes.func,
  text: PropTypes.string,
  isChangeable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  numeraryText: PropTypes.string,
  numerary: PropTypes.bool
};

PolygonVectorLayer.defaultProps = {
  zIndex: 0,
  coordinates: [],
  setCoordsGeoZone: null,
  text: '',
  isChangeable: false,
  isCreatePolygon: false,
  isSelectable: false,
  strokeColor: '#0e97fa',
  strokeWidth: 4,
  fillColor: 'rgba(0, 153, 255, 0.2)',
  numeraryText: null,
  numerary: null
};

export default PolygonVectorLayer;
