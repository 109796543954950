import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export default function ButtonWithMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    items, onItemClick, onRemoveItemClick, onAddItemClick,
    children, title, type, closeOnItemClick, ...other
  } = props;
  const withAdd = React.useMemo(() => !!onAddItemClick || null, [onAddItemClick]);
  const withRemove = React.useMemo(() => !!onRemoveItemClick || null, [onRemoveItemClick]);
  const isButton = React.useMemo(() => type === 'button', [type]);
  const ButtonClass = React.useMemo(() => (isButton ? Button : IconButton), [isButton]);
  const singleItem = React.useMemo(() => items?.length <= 1, [items]);

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = (item) => {
    onItemClick(item);
    if (closeOnItemClick) {
      handleClose();
    }
  };
  const handleAdd = (item, evt) => {
    evt.stopPropagation();
    onAddItemClick(item);
    if (singleItem) {
      handleClose();
    }
  };
  const handleRemove = (item, evt) => {
    evt.stopPropagation();
    onRemoveItemClick(item);
    if (singleItem) {
      handleClose();
    }
  };
  return (
    <Box sx={{ position: 'relative' }} {...other}>
      <ButtonClass
        sx={{ margin: '0 3px' }}
        title={title}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
      >
        {children}
        <ArrowDropDownIcon sx={{
          position: 'absolute',
          bottom: isButton ? -3 : 3,
          right: -2,
          width: 16,
          height: 16,
          color: (theme) => theme.palette.text.primary,
        }}
        />
      </ButtonClass>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                hover
                key={row.id}
                sx={{ border: 0, cursor: 'pointer' }}
                onClick={() => handleItemClick(row)}
              >
                <TableCell>{row.model || row.name}</TableCell>
                <TableCell>{row.regNum}</TableCell>
                { withAdd && (
                  <TableCell align="right" sx={{ lineHeight: 1 }}>
                    <ListItemIcon sx={{ minWidth: 0 }} onClick={(evt) => handleAdd(row, evt)} title="Добавить">
                      <AddIcon size="large" sx={{ color: (theme) => theme.palette.primary.main, fontSize: '1rem' }} />
                    </ListItemIcon>
                  </TableCell>
                )}
                { withRemove && (
                  <TableCell align="right" sx={{ lineHeight: 1 }}>
                    <ListItemIcon sx={{ minWidth: 0 }} onClick={(evt) => handleRemove(row, evt)} title="Удалить">
                      <CloseIcon size="large" sx={{ color: (theme) => theme.palette.error.main, fontSize: '1rem' }} />
                    </ListItemIcon>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Menu>
    </Box>
  );
}

ButtonWithMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    model: PropTypes.string,
    regNum: PropTypes.string,
  })),
  title: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  onAddItemClick: PropTypes.func,
  onRemoveItemClick: PropTypes.func,
  type: PropTypes.oneOf(['icon', 'button']),
  closeOnItemClick: PropTypes.bool,
};

ButtonWithMenu.defaultProps = {
  title: null,
  onAddItemClick: null,
  onRemoveItemClick: null,
  items: [],
  type: 'icon',
  closeOnItemClick: false,
};
