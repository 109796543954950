// eslint-disable-next-line boundaries/no-unknown-files
const reducerPath = {
  vehicleFuelEfficiency: "vehicleFuelEfficiency",
  vehicle: "vehicle",
  vehicleRoutes: "vehicleRoutes",
  adaptersResource: "adaptersResource",
  sensorResourceType: "sensorResourceType",
  sensorResource: "sensorResource",
  sensorSourceResource: "sensorSourceResource",
  adapterResourceType: "adapterResourceType",
  platoonGroupsResource: "platoonGroupsResource",
  platoonRequestsResource: "platoonRequestsResource",
  platoonGroupVehiclesResource: "platoonGroupVehiclesResource",
  platoonGroupActivitiesResource: "platoonGroupActivitiesResource",
  platoonGroupParamsResource: "platoonGroupParamsResource",
  vehicleRoutePointsResource: "vehicleRoutePointsResource",
  alertsResource: "alertsResource",
  trackPointsResource: "trackPointsResource",
  appUsersResource: "appUsersResource",
  appUsersSettingsResource: "appUsersSettingsResource",
  accountResource: "accountResource",
  companyAccountResource: "companyAccountResource",
  usersResource: "usersResource",
  alertsSettingsResource: 'alertsSettingsResource',
  tariffPlansResource: "tariffPlansResource",
  alertsChannelsResource: "alertsChannelsResource",
  alertsTypesResource: 'alertsTypesResource',
  divisionsResource: "divisionsResource",
  tabsPanelState: 'tabsPanelState',
  vehicleWeekMileages: 'vehicleWeekMileages',
  vehicleMonthMileages: 'vehicleMonthMileages',
  companyTabsPanelState: 'companyTabsPanelState',
  apiSlice: "apiSlice",
  companyPayments: "companyPayments",
  followMode: "followMode",
  zoomController: "zoomController",
  vkResource: "vkResource",
  tokenResource: "tokenResource",
  logisticsResource: "logisticsResource",
  companyCargos: "companyCargos",
  logisticTasksResource: "logisticTasksResource",
  logisticTasksBalanceResource: "logisticTasksBalanceResource",
  reportsResource: "reportsResource",
  fullScreenMap: "fullScreenMap",
};

export default reducerPath;
