// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehicleMonthMileagesFromDtoService from "../services/data/vehicle-month-mileages-from-dto.service";
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehicleMonthMileagesResourceApi = createApi({
  reducerPath: `${reducerPath.vehicleMonthMileages}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/vehicle-month-mileages`),
  endpoints: (builder) => ({
    getVehicleMonthMileagesResource: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.GET }),
        params,
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleMonthMileagesFromDtoService
    }),
    postVehicleMonthMileagesResource: builder.mutation({
      query: ({ body }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.POST }),
        body,
        validateStatus: checkStatus,
      }),

      transformResponse: vehicleMonthMileagesFromDtoService
    }),
    patchVehicleMonthMileagesResource: builder.mutation({
      query: ({ body, id }) => ({
        ...createHeadRequest({ url: `/${id}`, method: HttpMethods.PATCH }),
        body,
        validateStatus: checkStatus,
      }),

      transformResponse: vehicleMonthMileagesFromDtoService
    }),
  }),
});

export const {
  usePostVehicleMonthMileagesResourceMutation,
  useLazyGetVehicleMonthMileagesResourceQuery,
  usePatchVehicleMonthMileagesResourceMutation,
  useGetVehicleMonthMileagesResourceQuery,
} = vehicleMonthMileagesResourceApi;
