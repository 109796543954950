/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  Box,
  Button,
  List, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import style from './ReportsListWidget.module.css';
import { useGetReportsGroupsQuery, useGetReportsQuery } from '../../../../entities/reports/reportsResource/redux/reportsResource.api';
import { useAppSelector } from '../../../../app/store';
import reducerPath from '../../../../app/reducerPath';
import NoData from '../../../../shared/ui/NoData/NoData';
import Loading from '../../../../shared/ui/Loading/Loading';

const ITEMS_TYPES = {
  FOLDER: "folder",
  REPORT: "report",
};

const COUNT_COLUMNS = 3;
const EMPTY_TEXT = "-";

const cutArray = (array, size = 3) => {
  const ceilSize = Math.ceil(array.length / size);
  const chunks = [];
  for (let i = 0; i < array.length; i += ceilSize) {
    chunks.push(array.slice(i, i + ceilSize));
  }
  return chunks;
};

const sortByString = (array, field) => array?.slice()?.sort((a, b) => (`${a[field]}`).localeCompare(b[field]));

function ReportsListWidget({ companyId, level = 2 }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const repots = useGetReportsQuery({
    params: {
      // 'companyId.equals': companyId,
      page: 0,
      size: 100,
      ...(groupId ? { 'groupId.equals': groupId } : { 'groupId.specified': false })
    }
  });
  const repotsGroups = useGetReportsGroupsQuery({
    params: {
      // 'companyId.equals': companyId,
      page: 0,
      size: 100,
      ...(groupId ? { 'parentId.equals': groupId } : { 'parentId.specified': false })
    }
  });

  const { isLoadingList } = useAppSelector((state) => state[`${reducerPath.reportsResource}`]);

  const pathnames = location.pathname.split('/').filter((x) => x);
  const last = level === pathnames.length - 1;
  const nextLevel = level + 1;

  const items = useMemo(() => {
    const newItems = [];
    sortByString(repotsGroups?.data, 'name')?.forEach((item) => {
      newItems.push({
        id: item.reportGroupId,
        type: ITEMS_TYPES.FOLDER,
        name: item.name || EMPTY_TEXT,
      });
    });
    sortByString(repots?.data, 'name')?.forEach((item) => {
      newItems.push({
        id: item.reportId,
        type: ITEMS_TYPES.REPORT,
        name: item.name || item.reportId || EMPTY_TEXT,
      });
    });
    return newItems;
  }, [repotsGroups?.data, repots?.data]);

  const columns = useMemo(() => (
    items && items.length ? cutArray(items, COUNT_COLUMNS) : []
  ), [items]);

  return (
    <>
      {last && (
        <>
          <Box className={style.listBox}>
            {columns?.map((column, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <List className={style.list} key={i}>
                {column.map((item) => (
                  <ListItemButton
                    key={item.id}
                    component={RouterLink}
                    to={item.type === ITEMS_TYPES.FOLDER ? `${item.id}` : `?report=${item.id}`}
                  >
                    <ListItemIcon>
                      {item.type === ITEMS_TYPES.FOLDER ? <FolderIcon /> : <DescriptionIcon color="primary" />}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{
                        style: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            ))}
            {repotsGroups.isSuccess
              && repots.isSuccess
              && repotsGroups?.data
              && repots?.data
              && !columns?.length && <NoData />}
            {isLoadingList && <Loading />}
          </Box>
          <Box className={style.buttons}>
            {level !== 2 && <Button variant="contained" onClick={() => navigate(-1)}>Назад</Button>}
          </Box>
        </>
      )}
      <Routes>
        {items?.map((item) => (
          <Route
            key={item.id}
            path={item.type === ITEMS_TYPES.FOLDER ? `:groupId/*` : `report/${item.id}`}
            element={<ReportsListWidget level={nextLevel} companyId={companyId} />}
          />
        ))}
      </Routes>
    </>
  );
}

export default ReportsListWidget;
