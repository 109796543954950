import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, AlertTitle, Box, Typography
} from '@mui/material';
import { getConfig } from '../../shared/api/api';
import Loading from '../../shared/ui/Loading/Loading';
import { setConfig } from '../../shared/api/config';

function ApiConfig({ children }) {
  const [isConfig, setIsConfig] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isConfigError, setIsConfigError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const isDev = process.env.NODE_ENV === 'development';
    if (!isDev) {
      setIsConfigLoading(true);
      getConfig()
        .then((res) => {
          setConfig(res);
          setIsConfig(true);
          setIsConfigError(false);
          setError(null);
        })
        .catch((er) => {
          setIsConfig(false);
          setIsConfigError(true);
          setError(er);
        })
        .finally(() => {
          setIsConfigLoading(false);
        });
    } else {
      setIsConfig(true);
    }
  }, []);

  if (isConfigLoading) {
    return (
      <Loading />
    );
  }

  if (isConfigError) {
    return (
      <Alert severity="error" sx={{ margin: '20px' }}>
        <AlertTitle>Ошибка</AlertTitle>
        <Typography variant="body2" gutterBottom>
          Ошибка получения данных конфигурации,
          обратитесь к&nbsp;администратору или попробуйте позже.
        </Typography>
        <Typography variant="body2" gutterBottom>
          {' '}
          Код ошибки:
        </Typography>
        <Box sx={{ maxWidth: '100%', whiteSpace: 'nowrap', overflowX: 'auto' }}>
          <Typography variant="caption" gutterBottom>
            <pre>
              {JSON.stringify(error, null, 2)}
            </pre>
          </Typography>
        </Box>
      </Alert>
    );
  }

  if (!isConfig) {
    return null;
  }

  return children;
}

export default ApiConfig;

ApiConfig.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
