// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehicleRoutesFromDtoService from "../services/data/vehicleRoutes-from-dto.service";
import config from "../../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehiclesRoutesApi = createApi({
  reducerPath: `${reducerPath.vehicleRoutes}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/vehicle-routes`),
  endpoints: (builder) => ({
    getVehiclesRouteResource: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({
          url: ``,
          method: HttpMethods.GET,
        }),
        params,
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleRoutesFromDtoService
    }),

    getVehicleRouteId: builder.mutation({
      query: ({ id }) => ({
        ...createHeadRequest({ url: `/${id}`, method: HttpMethods.GET }),
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleRoutesFromDtoService
    }),

    postVehicleRouteFile: builder.mutation({
      query: ({ body }) => ({
        ...createHeadRequest({
          customHeader: {
            url: `/import`,
            method: HttpMethods.POST,
            headers: {
              // 'Content-Type': 'multipart/form-data'
            },
          }
        }),
        body: body.file,
        params: {
          // fileName: body.fileName,
          companyId: body.companyId,
        },
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleRoutesFromDtoService
    }),

    postVehicleRoute: builder.mutation({
      query: ({ body }) => ({
        ...createHeadRequest({ url: `/import-logistic`, method: HttpMethods.POST }),
        validateStatus: checkStatus,
        body
      }),
      transformResponse: vehicleRoutesFromDtoService
    }),

    postVehicleRouteCopy: builder.mutation({
      query: ({ id, params }) => ({
        ...createHeadRequest({ url: `/${id}/copy`, method: HttpMethods.POST }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: vehicleRoutesFromDtoService
    }),
  }),
});

export const {
  useGetVehicleRouteIdMutation,
  useGetVehiclesRouteResourceQuery,
  useLazyGetVehiclesRouteResourceQuery,
  usePostVehicleRouteFileMutation,
  usePostVehicleRouteMutation,
  usePostVehicleRouteCopyMutation,
} = vehiclesRoutesApi;
