// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import convertFormatDate from "../../../../../shared/utils/convertFormatDate";
import vehicleFuelEfficiencyDtoType from "../../types/vehicleFuelEfficiency-dto.type";

export function vehicleFuelEfficiencyFromDtoService(dto, meta) {
  const data = dto.map((e) => {
    const startDate = e.startDate ? convertFormatDate(e.startDate) : '';
    return {
      date: JSON.stringify(startDate),
      fuelCoef: e.fuelCoef ? Number(e.fuelCoef) : NaN,
      fuelCoefUp: e.fuelCoefUp ? Number(e.fuelCoefUp) : NaN,
      fuelCoefDown: e.fuelCoefDown ? Number(e.fuelCoefDown) : NaN,
      altUpSum: e.altUpSum ? Number(e.altUpSum) : NaN,
      altDownSum: e.altDownSum ? Number(e.altDownSum) : NaN,
      fuelDiff: e.fuelDiff ? e.fuelDiff : 0,
      fuelDiffMinus: e.fuelDiffMinus ? e.fuelDiffMinus : 0,
      gas: +e.fuelDiffMinus < 0 ? e.fuelDiffMinus : e.fuelDiff ? e.fuelDiff : NaN
    };
  });
  const { status } = meta.response;

  return { data, status };
}

vehicleFuelEfficiencyFromDtoService.propTypes = {
  dto: vehicleFuelEfficiencyDtoType,
};

export default vehicleFuelEfficiencyFromDtoService;
