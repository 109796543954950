// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import {
  platoonGroupParamsResourceEnum as platoonGroupParamsResourceInitialState
} from "../redux/platoonGroupParamsResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const platoonGroupParamsResourceDtoType = {
  [platoonGroupParamsResourceInitialState.platoonGroupId]: PropTypes.number,
};

export default platoonGroupParamsResourceDtoType;
