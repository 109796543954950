// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import trackPointsResourceFromDtoService from "../services/data/trackPointsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
import { subscribe, unsubscribe } from '../../../../shared/api/socket';
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const trackPointsResourceApi = createApi({
  reducerPath: `${reducerPath.trackPointsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.vstorageUrl}/track-points`),
  endpoints: (builder) => ({
    getTrackPointsResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params: {
          ...params,
          size: "20000"
        },
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: trackPointsResourceFromDtoService
    }),
    getFileHistory: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: `/export`, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
    }),
    getTrackPointsResourceSocket: builder.query({
      query: ({
        authToken, params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET, authToken
        }),
        params,
        validateStatus: checkStatus,
      }),
      async onCacheEntryAdded(queryParams, cache) {
        const { cacheDataLoaded, cacheEntryRemoved, updateCachedData } = cache;
        const topic = `/topic/track-points/vehicle/${queryParams.params['vehicleId.equals']}`;
        await cacheDataLoaded;
        const subscriber = await subscribe(
          topic,
          (message) => {
            updateCachedData((draft) => {
              draft.data.unshift(message);
            });
          }
        );
        await cacheEntryRemoved;
        unsubscribe(subscriber, topic);
      },
      providesTags: ['TAG'],
      transformResponse: trackPointsResourceFromDtoService
    }),

  }),
});

export const {
  useGetTrackPointsResourceQuery,
  useLazyGetTrackPointsResourceQuery,
  useGetTrackPointsResourceSocketQuery,
  useLazyGetFileHistoryQuery
} = trackPointsResourceApi;
