function calculateSpeed(
  startLatitude,
  startLongitude,
  startDate,
  finishLatitude,
  finishLongitude,
  finishDate
) {
  // Преобразование даты и времени в миллисекунды
  // (возможно, вам потребуется использовать другой формат даты)
  const startTime = new Date(startDate).getTime();
  const finishTime = new Date(finishDate).getTime();

  // Расчет разницы во времени в секундах
  const timeDifferenceSeconds = Math.abs((finishTime - startTime) / 1000);
  const R = 6371000; // Радиус Земли в метрах

  const phi1 = (startLatitude / 1e7) * (Math.PI / 180); // Широта первой точки в радианах
  const lambda1 = (startLongitude / 1e7) * (Math.PI / 180); // Долгота первой точки в радианах
  const phi2 = (finishLatitude / 1e7) * (Math.PI / 180); // Широта второй точки в радианах
  const lambda2 = (finishLongitude / 1e7) * (Math.PI / 180); // Долгота второй точки в радианах

  const deltaPhi = phi2 - phi1; // Разница широт
  const deltaLambda = lambda2 - lambda1; // Разница долгот

  const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2)
      + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  // Расчет скорости в километрах в час
  return Math.round((distance / 1000) / (timeDifferenceSeconds / 3600));
  // Возвращаем скорость (возможно, вам потребуется отформатировать ее перед выводом)
}

export default calculateSpeed;
