import React from 'react';
import { SvgIcon } from '@mui/material';

function DriverAppIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0119109)">
          <rect width="48" height="48" fill="#2A659B" />
          <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM1.05353 24C1.05353 36.673 11.327 46.9465 24 46.9465C36.673 46.9465 46.9465 36.673 46.9465 24C46.9465 11.327 36.673 1.05353 24 1.05353C11.327 1.05353 1.05353 11.327 1.05353 24Z" fill="#FBF8F8" />
          <path d="M42.1979 12L25.007 41.8174L22.0071 41.8174L42.1979 12Z" fill="white" />
          <path d="M42.4624 12.5039L29 41.5048L32 41.5048L42.4624 12.5039Z" fill="white" />
          <path d="M41.3304 12.5118L17.0392 41.146L14.0001 41.1115L27.6865 26.6686L41.3304 12.5118Z" fill="white" />
          <path d="M40.6148 17.7289L12 25.6254L23.4621 15.7684L14.2961 3.99973L40.6148 17.7289Z" fill="#FBF8F8" />
          <path d="M34.4934 17.2977L22 21.1885L25.4967 15.798L23.254 10.0002L34.4934 17.2977Z" fill="#D9D9D9" />
          <path d="M20.6291 42.4666L28.45 42.1858L27.3604 46.4586L20.6291 42.4666Z" fill="#2A659B" />
          <rect x="23" y="39" width="5" height="1" fill="#2A659B" />
          <rect x="25" y="36" width="5" height="1" fill="#2A659B" />
          <rect x="26" y="33" width="5" height="1" fill="#2A659B" />
          <rect x="29" y="30" width="5" height="1" fill="#2A659B" />
          <rect x="30" y="27" width="5" height="1" fill="#2A659B" />
          <rect x="33" y="24" width="3" height="1" fill="#2A659B" />
          <rect x="34" y="22" width="3" height="1" fill="#2A659B" />
          <rect x="35" y="20" width="3" height="1" fill="#2A659B" />
        </g>
        <defs>
          <clipPath id="clip0119109">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DriverAppIcon;
