import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";

import {
  useGetCompanyAccountResourceQuery, useLazyGetCompanyAccountResourceIdQuery,
  useLazyGetCompanyAccountResourceQuery,
  usePatchCompanyAccountResourceMutation, usePostCompanyAccountResourceMutation
} from "../redux/companyAccountResource.api";
// eslint-disable-next-line boundaries/element-types
import { useGetTariffPlansQuery } from "../../../finances/tariffPlansResource/redux/tariffPlansResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function CompanyAccountResourceGetBll({ children }) {
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const { data: dataCompanyAccount } = useGetCompanyAccountResourceQuery({});

  const [
    getCompanyAccountResource,
  ] = useLazyGetCompanyAccountResourceQuery();

  const [
    getCompanyAccountResourceId,
  ] = useLazyGetCompanyAccountResourceIdQuery();

  const { data: dataTariffPlansResource } = useGetTariffPlansQuery({
    authToken: token,
  });

  const [
    postCompanyAccountResource,
  ] = usePostCompanyAccountResourceMutation();

  const [
    patchCompanyAccountResource,
  ] = usePatchCompanyAccountResourceMutation();

  const fetchPatchCompanyAccountResourceParams = useCallback((body) => patchCompanyAccountResource(
    { body, id: body.companyAccountId }
  ), []);

  const fetchPostCompanyAccountResourceParams = useCallback((body) => postCompanyAccountResource(
    { body }
  ), []);

  const fetchCompanyAccountResourceParams = useCallback((params) => getCompanyAccountResource(
    { ...params }
  ), []);

  const fetchGetCompanyAccountResourceIdParams = useCallback((id) => getCompanyAccountResourceId(
    { id }
  ), []);

  const props = {
    dataCompanyAccount,
    fetchCompanyAccountResourceParams,
    fetchPostCompanyAccountResourceParams,
    dataTariffPlansResource,
    fetchGetCompanyAccountResourceIdParams,
    fetchPatchCompanyAccountResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

CompanyAccountResourceGetBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CompanyAccountResourceGetBll;
