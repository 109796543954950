import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircle from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Отображает кнопки управления над списком тарифов
function TariffPlansToolbarWidget({ toogleFilters }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
      }}
    >
      <Tooltip title="Добавить">
        <IconButton
          color="primary"
          onClick={() => navigate(`create`)}
        >
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Фильтры">
        <IconButton
          color="primary"
          onClick={toogleFilters}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

TariffPlansToolbarWidget.propTypes = {
  toogleFilters: PropTypes.func.isRequired,
};

export default TariffPlansToolbarWidget;
