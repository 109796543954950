// Проверка на статусы
// TODO: Когда будет переводить запросы на RTK quety тут будет много проверок
// checkStatus будет использовать в всех запросах
const checkStatus = (response) => {
  if (!response.ok) {
    return response;
  }
  return response;
};

export default checkStatus;
