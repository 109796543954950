// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import {
  appUserSettingsResourceEnum,
  appUserSettingsResourceTYPEEnum, appUserSettingsResourceUSEREnum
} from "../redux/appUserSettingsResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const appUserSettingsResourceDtoType = {
  [appUserSettingsResourceEnum.appUserSettingId]: PropTypes.number,
  [appUserSettingsResourceEnum.group]: PropTypes.string,
  [appUserSettingsResourceEnum.name]: PropTypes.string,
  [appUserSettingsResourceEnum.value]: PropTypes.string,
  [appUserSettingsResourceEnum.valuelist]: PropTypes.string,
  [appUserSettingsResourceEnum.orderNum]: PropTypes.number,
  [appUserSettingsResourceEnum.type]: PropTypes.shape({
    [appUserSettingsResourceTYPEEnum.settingId]: PropTypes.number,
    [appUserSettingsResourceTYPEEnum.name]: PropTypes.string
  }),
  [appUserSettingsResourceEnum.user]: PropTypes.shape({
    [appUserSettingsResourceUSEREnum.appUserId]: PropTypes.number,
    [appUserSettingsResourceUSEREnum.firstName]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.lastName]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.company]: PropTypes.string,
    [appUserSettingsResourceUSEREnum.user]: PropTypes.string,
  }),
};

export default appUserSettingsResourceDtoType;
