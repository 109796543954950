import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function Icon({ icon, style = {} }) {
  return (
    <Box
      component={icon}
      color="inherit"
      sx={{
        mr: 1, height: '16px', width: '16px', ...style
      }}
    />
  );
}

Icon.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
};

Icon.defaultProps = {
  style: {},
};

export default Icon;
