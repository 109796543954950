// eslint-disable-next-line boundaries/no-unknown-files
import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { ConfirmProvider } from 'material-ui-confirm';
import { Layout } from './layout/Layout';
import LoginView from '../views/account/LoginView/LoginView';
import CompanyView from '../views/companies/CompanyView/CompanyView';
import RequireAuth from './providers/RequireAuth';
import AuthProvider from './providers/AuthProvider';
import ForgotPasswordView from "../views/account/ForgotPasswordView/ForgotPasswordView";
import AccountView from "../views/account/AccountView/AccountView";
import UserView from "../views/users/UserView/UserView";
import FinanceView from "../views/finances/FinanceView/FinanceView";
import ForgotPasswordBll from "../shared/ui/ForgotPassword/AccountMain";
import PrivateRoute from './providers/PrivateRoute';
import CompanyFinanceView from '../views/companies/CompanyFinanceView/CompanyFinanceView';
import VkAuthPage from "../views/account/Vk/vkAuthPage";
import VkRegistrationPage from "../views/account/Vk/vkRegistrationPage";
import AccountActivateView from '../views/account/AccountActivateView/AccountActivateView';
import ResetPasswordView from '../views/account/ResetPasswordView/ResetPasswordView';
import ApiConfig from './providers/ApiConfig';
import LogisticsView from '../views/logistics/LogisticsView/LogisticsView';
import QuickStartView from '../views/quickStart/QuickStartView/QuickStartView';
import DeliveryView from '../views/logistics/DeliveryView/DeliveryView';
import CompanyPassengersQrListView from '../views/companies/CompanyPassengersQrListView/CompanyPassengersQrListView';
import Toaster from '../shared/ui/Toaster/Toaster';
import PowerBalancingView from '../views/logistics/PowerBalancingView/PowerBalancingView';
import CompanyWizardView from '../views/companies/CompanyWizardView/CompanyWizardView';

const localeMap = {
  ru: ruLocale,
};

const theme = createTheme(
  ruRU,
);

function App() {
  return (
    <>
      <Toaster />
      <ApiConfig>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <LocalizationProvider
              adapterLocale={localeMap.ru}
              dateAdapter={AdapterDateFns}
            >
              <AuthProvider>
                <Routes>
                  <Route path="/passengers-qr-list" element={<CompanyPassengersQrListView />} />
                  <Route
                    path="/activate/*"
                    element={(
                      <Routes>
                        <Route path="account-activate" element={<AccountActivateView />} />
                        <Route path="reset-password" element={<ResetPasswordView />} />
                      </Routes>
                  )}
                  />
                  <Route path="/" element={<LoginView />} />
                  <Route path="/impersonize" element={<LoginView impersonize />} />
                  <Route path="/vk" element={<VkAuthPage />} />
                  <Route path="/vk/registration" element={<VkRegistrationPage />} />
                  <Route
                    path="forgotPassword"
                    element={(
                      <ForgotPasswordBll>
                        <ForgotPasswordView />
                      </ForgotPasswordBll>
                  )}
                  />
                  <Route
                    path="/*"
                    element={(
                      <RequireAuth>
                        <Routes>
                          <Route element={<Layout />}>
                            <Route path="quick-start" element={<QuickStartView />} />
                            <Route
                              path="wizard"
                              element={(
                                <PrivateRoute
                                  roles={['ROLE_COMPANY_ADMIN']}
                                >
                                  <CompanyWizardView />
                                </PrivateRoute>
                            )}
                            />
                            <Route path="companies/:id/*" element={<CompanyView />} />
                            <Route path="companies" element={<CompanyView />} />
                            <Route path="account/*" element={<AccountView />} />
                            <Route
                              path="logistics/*"
                              element={(
                                <Routes>
                                  <Route path="short-path/:logisticTaskId/*" element={<LogisticsView />} />
                                  <Route path="delivery/:logisticTaskId/*" element={<DeliveryView />} />
                                  <Route path="power-balancing/:logisticTaskId/*" element={<PowerBalancingView />} />
                                </Routes>
                          )}
                            />
                            <Route
                              path="users/*"
                              element={(
                                <PrivateRoute
                                  roles={['ROLE_ADMIN']}
                                >
                                  <UserView />
                                </PrivateRoute>
                            )}
                            />
                            <Route
                              path="finances/*"
                              element={(
                                <PrivateRoute
                                  roles={['ROLE_ADMIN']}
                                >
                                  <FinanceView />
                                </PrivateRoute>
                            )}
                            />
                            <Route
                              path="company-finances/*"
                              element={(
                                <PrivateRoute
                                  roles={['ROLE_COMPANY_ADMIN']}
                                >
                                  <CompanyFinanceView />
                                </PrivateRoute>
                            )}
                            />
                          </Route>
                        </Routes>
                      </RequireAuth>
                  )}
                  />
                </Routes>
              </AuthProvider>
            </LocalizationProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </ApiConfig>
    </>
  );
}

export default App;
