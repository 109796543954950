// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import adaptersResourceFromDtoService from "../services/data/adaptersResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";
import config from '../../../../shared/api/config';

// Описываем методы обращения к беку
export const adaptersResourceApi = createApi({
  reducerPath: `${reducerPath.adaptersResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/adapters`),
  endpoints: (builder) => ({
    putAdapter: builder.mutation({
      query: ({
        id, body
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PUT
        }),
        body,
        validateStatus: checkStatus,
      }),
      transformResponse: adaptersResourceFromDtoService
    }),
    patchAdapter: builder.mutation({
      query: ({
        id, body
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PUT,
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: adaptersResourceFromDtoService
    }),
    getAdapters: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: adaptersResourceFromDtoService,
      providesTags: ['TAG']
    }),
    postAdapter: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
      transformResponse: adaptersResourceFromDtoService
    }),
    getAdapter: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      transformResponse: adaptersResourceFromDtoService
    }),
    deleteAdapter: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.DELETE
        }),
        validateStatus: checkStatus,
      }),
      transformResponse: adaptersResourceFromDtoService,
      invalidatesTags: ['TAG']
    }),
    getAdaptersCount: builder.mutation({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: `/count`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: adaptersResourceFromDtoService
    }), // +
  }),
});

export const {
  useGetAdaptersQuery,
  useGetAdapterMutation,
  useDeleteAdapterMutation,
  useGetAdaptersCountMutation,
  usePostAdapterMutation,
  usePatchAdapterMutation,
  usePutAdapterMutation,
} = adaptersResourceApi;
