import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Area } from 'recharts';
import { Box, Typography } from "@mui/material";
import CustomizedDot from "./CustomizedDot/CustomizedDot";
import CustomizedResponsiveContainer from "./CustomizedResponsiveContainer/CustomizedResponsiveContainer";
import isFloat from "../../../../../shared/utils/isFloat";
import ErrsMessage from "./ErrsMessage/ErrsMessage";

const constants = {
  syncId: "LineChartTC",
  defaultInterval: "auto",
  defaultFontSizeTick: 8,
  xAxisDataKey: 'date',
  tooltipLabel: "date",
  area: {
    type: "monotone",
    fillOpacity: 1
  },
  err: {
    length: "Данных для отображения графика недостаточно",
    null: "данных нет",
    dateNull: 'Данных по дате нет'
  }
};

const INFINITE_FUEL_COEF_SUBST = 1000000;

export const dataType = {
  day: 0,
  month: 1,
  year: 2
};

export const filterCoefficientType = {
  format1: 0,
  format2: 1,
  format3: 2,
  format4: 3
};

function LineChart({
  width = "auto",
  height = 200,
  data,
  dataTypeValue = dataType.day
}) {
  const xAxisProps = {
    tickFormatter: (e) => {
      const date = JSON.parse(e);
      return date ? dataTypeValue === dataType.day ? `${date.hours}:${date.minutes}` : `${date.day}/${date.month}` : constants.err.null;
    },
    dataKey: constants.xAxisDataKey,
    interval: constants.defaultInterval,
    tick: { fontSize: `${constants.defaultFontSizeTick}px` },
  };
  const tooltipProps = {
    label: constants.tooltipLabel,
    formatter: (e) => (e ? isFloat(e) ? e.toFixed(1) : e : constants.err.null),
    labelFormatter: (e, s) => {
      const date = JSON.parse(e);
      return `${date ? `Дата : ${date.day}-${date.month}-${date.year} ${date.hours}:${date.minutes}`
        : constants.err.dateNull} ${s.length === 3 ? s[0]?.payload?.gas
        ? `(${s[0]?.payload?.gas?.toFixed(1) > 0 ? 'Заправка' : "Слив"}: ${s[0]?.payload?.gas?.toFixed(1)} л.)`
        : ''
        : ''} `;
    },
  };

  const fuelProps = useMemo(() => {
    const safeData = data?.map(({
      fuelCoef, fuelCoefUp, fuelCoefDown, ...rest
    }) => ({
      fuelCoef: +fuelCoef === Infinity ? INFINITE_FUEL_COEF_SUBST
        : Number.isNaN(+fuelCoef) ? 0 : fuelCoef,
      fuelCoefUp: +fuelCoefUp === Infinity ? INFINITE_FUEL_COEF_SUBST
        : Number.isNaN(+fuelCoefUp) ? 0 : fuelCoefUp,
      fuelCoefDown: +fuelCoefDown === Infinity ? INFINITE_FUEL_COEF_SUBST
        : Number.isNaN(+fuelCoefDown) ? 0 : fuelCoefDown,
      ...rest,
    }));
    const res = safeData?.reduce((acc, { fuelCoef, fuelCoefUp, fuelCoefDown }) => {
      const maxValue = Math.max(acc.maxFuel, fuelCoef, fuelCoefUp, fuelCoefDown);
      return {
        maxFuel: maxValue,
        step: Math.ceil(maxValue / 20),
      };
    }, { maxFuel: 0, step: 0.5 });
    return {
      ...res,
      safeData,
      ticks: new Array(Math.ceil(res.maxFuel / (res.step || 1)) * 2 + 1)
        .fill(1).map((_, i) => Math.ceil(i * 0.5 * (res.step || 1)))
    };
  }, [data]);

  const customizedResponsiveContainerProps = {
    width,
    height,
    syncId: constants.syncId,
    xAxisProps,
    tooltipProps,
    data: fuelProps.safeData.map((e) => ({
      ...e,
      // eslint-disable-next-line no-unsafe-optional-chaining
      altUpSum: typeof e?.altUpSum === "number" ? e?.altUpSum / 100 || 0 : e?.altUpSum || 0,
      // eslint-disable-next-line no-unsafe-optional-chaining
      altDownSum: typeof e?.altDownSum === "number" ? e?.altDownSum / 100 || 0 : e?.altDownSum || 0,
    })),
  };

  return (
    <Box sx={{ width }}>
      <Typography variant="subtitle2">
        Топливная эффективность (л/100 км)
      </Typography>
      {fuelProps?.safeData ? fuelProps?.safeData.length ? (
        <CustomizedResponsiveContainer
          {...customizedResponsiveContainerProps}
          height={customizedResponsiveContainerProps.height * 2}
          yAxisProps={{
            domain: [0, (dataMax) => Math.ceil(dataMax)],
            allowDataOverflow: true,
            allowDecimals: true,
            ticks: fuelProps.ticks,
            minTickGap: 0,
          }}
        >
          <Area
            type={constants.area.type}
            dataKey="fuelCoef"
            name="Коэффициент топливной эффективности"
            stroke="#B00020"
            fill="transparent"
            fillOpacity={constants.area.fillOpacity}
          />
          <Area
            type={constants.area.type}
            dataKey="fuelCoefUp"
            name="Верхней среднее отклонение коэффициента"
            stroke="#1976D2"
            fill="transparent"
            fillOpacity={constants.area.fillOpacity}
          />
          <Area
            type={constants.area.type}
            dataKey="fuelCoefDown"
            name="Нижнее среднее отклонение коэффициента"
            stroke="#43A047"
            fill="transparent"
            fillOpacity={constants.area.fillOpacity}
            dot={<CustomizedDot />}
          />
        </CustomizedResponsiveContainer>
      ) : <ErrsMessage value={constants.err.length} />
        : <ErrsMessage value={constants.err.null} />}
      <Typography variant="subtitle2">
        Профиль набора высоты и спуска, м
      </Typography>
      {data ? data.length ? (
        <CustomizedResponsiveContainer
          {...customizedResponsiveContainerProps}
        >
          <Area
            type={constants.area.type}
            dataKey="altUpSum"
            name="Суммарный набор высоты"
            stroke="#1976D2"
            fill="transparent"
            fillOpacity={constants.area.fillOpacity}
          />
          <Area
            type={constants.area.type}
            dataKey="altDownSum"
            name="Суммарный спуск"
            stroke="#43A047"
            fill="transparent"
            fillOpacity={constants.area.fillOpacity}
          />
        </CustomizedResponsiveContainer>
      ) : <ErrsMessage value={constants.err.length} />
        : <ErrsMessage value={constants.err.null} />}
    </Box>
  );
}

LineChart.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dataTypeValue: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    fuelCoef: PropTypes.number.isRequired,
    fuelCoefUp: PropTypes.number.isRequired,
    fuelCoefDown: PropTypes.number.isRequired,
    altUpSum: PropTypes.number.isRequired,
    altDownSum: PropTypes.number.isRequired,
    fuelDiff: PropTypes.number.isRequired,
    fuelDiffMinus: PropTypes.number.isRequired,
  })).isRequired
};

export default memo(LineChart);
