import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import style from './CompanyFinancePanel.module.css';
// eslint-disable-next-line boundaries/element-types
import CompanyFinanceViewAdmin from "../../../views/companies/CompanyFinanceViewAdmin/CompanyFinanceViewAdmin";

// Отображает секции аккордеона "Баланс компаний"
function CompanyFinancePanel({
  onChangeCB, companyAccount, isFetching
}) {
  return (
    <Box className={style.companyFinanceViewAdmin}>
      <CompanyFinanceViewAdmin
        companyAccount={companyAccount}
        isFetching={isFetching}
        onChangeCB={onChangeCB}
      />
    </Box>
  );
}

CompanyFinancePanel.propTypes = {
  companyAccount: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  onChangeCB: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

CompanyFinancePanel.defaultProps = {
  companyAccount: null,
  isFetching: false,
};

export default CompanyFinancePanel;
