import { createApi } from '@reduxjs/toolkit/query/react';
import createHeadRequest from "../../../../shared/http/createHeadRequest";
import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
import config from "../../../../shared/api/config";
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const reportsResourceApi = createApi({
  reducerPath: `${reducerPath.reportsResource}/api`,
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.reportsUrl}`),
  // baseQuery: (...arg) => baseQueryWithReAuth(...arg, `/api`),
  endpoints: (builder) => ({
    getReportsGroups: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: `/report-groups`, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
    }),
    getReports: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: `/reports`, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
    }),
    getReport: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/reports/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportQuery,
  useGetReportsGroupsQuery,
  useLazyGetReportsQuery,
  useLazyGetReportQuery,
} = reportsResourceApi;
