// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { vehicleRouteEnum } from "../redux/vehiclesRoutes.slice";

// тут описываем тип то что приходит с бека в сыром виде
const vehicleRoutesDtoType = {
  [vehicleRouteEnum.vehicleRouteId]: PropTypes.number,
  [vehicleRouteEnum.name]: PropTypes.string,
  [vehicleRouteEnum.status]: PropTypes.string,
  [vehicleRouteEnum.isAutostarted]: PropTypes.bool,
  [vehicleRouteEnum.isAutoShifted]: PropTypes.bool,
  [vehicleRouteEnum.startTime]: PropTypes.string,
  [vehicleRouteEnum.startTimeUntil]: PropTypes.string,
  [vehicleRouteEnum.finishTime]: PropTypes.string,
  [vehicleRouteEnum.finishTimeUntil]: PropTypes.string,
  [vehicleRouteEnum.isRoundTrip]: PropTypes.bool,
  [vehicleRouteEnum.company]: {},
  [vehicleRouteEnum.vehicle]: {},
  [vehicleRouteEnum.routePoints]: {}
};

export default vehicleRoutesDtoType;
