import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

export default function ButtonWithDialog(props) {
  const [open, setOpen] = React.useState(false);

  const {
    onOk, onCancel, children, title, type, content, ...other
  } = props;
  const isButton = React.useMemo(() => type === 'button', [type]);
  const ButtonClass = React.useMemo(() => (isButton ? Button : IconButton), [isButton]);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleOk = () => {
    if (onOk) {
      onOk();
    }
    setOpen(false);
  };
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };
  return (
    <Box sx={{ position: 'relative' }} {...other}>
      <ButtonClass
        sx={{ margin: '0 3px' }}
        title={title}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        {children}
      </ButtonClass>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content || 'В разработке'}
          </DialogContentText>
        </DialogContent>
        {
          (onOk || onCancel) && (
          <DialogActions>
            {onCancel && <Button onClick={handleOk}>Отмена</Button>}
            {onOk && <Button onClick={handleClose} autoFocus>Да</Button>}
          </DialogActions>
          )
        }
      </Dialog>
    </Box>
  );
}

ButtonWithDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  content: PropTypes.node,
  title: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.oneOf(['icon', 'button'])
};

ButtonWithDialog.defaultProps = {
  title: null,
  content: null,
  onOk: null,
  onCancel: null,
  type: 'icon',
};
