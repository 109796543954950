import React, {
  Children, cloneElement, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import {
  deleteAppUserAccesses, getAppUserAccesses, getAppUsersV2, postAppUserAccesses
} from "../../../shared/api/api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function DivisionAccessFormBll({
  children, divisionId, appUserId, companyId, data, setUpdate
}) {
  const [appUserAccesses, setAppUserAccesses] = useState(null);
  const [appUsers, setAppUsers] = useState([]);
  const [allAppUsers, allSetAppUsers] = useState([]);

  useEffect(() => {
    if (appUserId) {
      getAppUserAccesses({ divisionId, userId: appUserId }).then((res) => {
        setAppUserAccesses(res);
      })
        .catch(() => { });
    } else {
      setAppUserAccesses(null);
    }
    if (data) {
      getAppUsersV2([
        { name: 'companyId.equals', value: companyId },
        { name: 'role.in', value: 'ROLE_USER' },
      ])
        .then((res) => {
          setAppUsers(res.filter((appUser) => !data.some(
            (appUserAccess) => Number(appUserAccess.appUserId) === appUser.appUserId
          )));
          allSetAppUsers(res);
        }).catch(() => { });
    }
  }, [divisionId, appUserId, companyId, data]);

  const onSave = (savedData) => {
    const { accesses } = savedData;
    const body = {
      division: { divisionId },
      user: { appUserId: appUserId || savedData.selectedUser?.appUserId }
    };

    const currentAccesses = {
      DIV_PRIV_READ: appUserAccesses?.find((appUserAccess) => appUserAccess.divPrivilege === 'DIV_PRIV_READ'),
      DIV_PRIV_VEHICLE_ADMIN: appUserAccesses?.find((appUserAccess) => appUserAccess.divPrivilege === 'DIV_PRIV_VEHICLE_ADMIN'),
      DIV_PRIV_USER_ADMIN: appUserAccesses?.find((appUserAccess) => appUserAccess.divPrivilege === 'DIV_PRIV_USER_ADMIN'),
    };
    const created = [];
    const deleted = [];

    Object.keys(accesses).forEach((key) => {
      if (currentAccesses[key] && !accesses[key]) {
        deleted.push(deleteAppUserAccesses(currentAccesses[key].appUserAccessId));
      }
      if (!currentAccesses[key] && accesses[key]) {
        created.push(postAppUserAccesses({
          divPrivilege: key,
          ...body
        }));
      }
    });

    Promise.all([...created, ...deleted]).then(() => {
      setUpdate((prev) => !prev);
    });
  };

  const props = {
    appUserAccesses,
    appUsers,
    divisionId,
    appUserId,
    allAppUsers,
    onSave,
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

DivisionAccessFormBll.propTypes = {
  children: PropTypes.element.isRequired,
  divisionId: PropTypes.number,
  appUserId: PropTypes.number,
  companyId: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  setUpdate: PropTypes.func
};

DivisionAccessFormBll.defaultProps = {
  divisionId: NaN,
  appUserId: NaN,
  companyId: NaN,
  data: null,
  setUpdate: null
};

export default DivisionAccessFormBll;
