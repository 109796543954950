/* eslint-disable react/prop-types */
import React from 'react';
import {
  Autocomplete as AutocompleteMUI,
  CircularProgress,
  TextField,
} from '@mui/material';

function Autocomplete(props) {
  const {
    value,
    onChange,
    onInputChange,
    id,
    values = [],
    loading,
    label,
    autoFocus,
    fullWidth = false,
    size = "small",
    required = false,
  } = props;
  return (
    <AutocompleteMUI
      fullWidth={fullWidth}
      autoComplete
      autoFocus={autoFocus}
      size={size}
      value={value}
      onChange={(e, newValue) => onChange(id, newValue)}
      onInputChange={onInputChange}
      id={id}
      name={id}
      loading={loading}
      isOptionEqualToValue={(option, valueData) => option.id === valueData.id}
      options={values}
      sx={{ width: 300 }}
      getOptionLabel={(option) => `${option.name}`}
      // filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={label}
          required={Boolean(required)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default Autocomplete;
