import React from 'react';
import { Toaster as ToasterRHT } from 'react-hot-toast';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function Toaster() {
  return (
    <ToasterRHT
      position="bottom-left"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        style: {
          padding: '16px',
          borderRadius: '4px',
        },
        error: {
          icon: <ErrorOutlineIcon color="error" />
        },
      }}
    />
  );
}

export default Toaster;
