import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const times = {
  min: 5,
  med: 10,
  max: 15,
  hideMax: 60 * 24 * 365 * 70
};

function DisableAlertsMenu({ isOpenDisableMenu, closeDisableMenu, selectMenuItem }) {
  const handleselectMenuItem = (time) => {
    selectMenuItem(time);
    closeDisableMenu();
  };

  return (
    <Menu
      anchorEl={isOpenDisableMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // id={"primary-search-account-menu"}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(isOpenDisableMenu)}
      onClose={closeDisableMenu}
    >
      <MenuItem onClick={() => handleselectMenuItem(times.min)}>
        Не показывать
        {' '}
        {times.min}
        {' '}
        мин
      </MenuItem>
      <MenuItem onClick={() => handleselectMenuItem(times.med)}>
        Не показывать
        {' '}
        {times.med}
        {' '}
        мин
      </MenuItem>
      <MenuItem onClick={() => handleselectMenuItem(times.max)}>
        Не показывать
        {' '}
        {times.max}
        {' '}
        мин
      </MenuItem>
      <MenuItem onClick={() => handleselectMenuItem(times.hideMax)}>Скрыть</MenuItem>
    </Menu>
  );
}

DisableAlertsMenu.propTypes = {
  isOpenDisableMenu: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  closeDisableMenu: PropTypes.func.isRequired,
  selectMenuItem: PropTypes.func.isRequired,
};

DisableAlertsMenu.defaultProps = {
  isOpenDisableMenu: null,
};

export default DisableAlertsMenu;
