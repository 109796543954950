// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import tariffPlansResourceDtoType from "../../types/tariffPlansResource-dto.type";
import createDescription from "./createDescription";

export function tariffPlansResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  const data = dto.map((tariffPlan) => ({
    ...tariffPlan,
    startDate: tariffPlan.startDate ? new Date(tariffPlan.startDate).toLocaleDateString() : '',
    finishDate: tariffPlan.finishDate ? new Date(tariffPlan.finishDate).toLocaleDateString() : '',
    description: createDescription(tariffPlan),
    isPublic: tariffPlan.isPublic ? 'Да' : '-',
    isDefault: !!tariffPlan.isDefault,
    checkBox: !!tariffPlan.isDefault,
  }));

  const totalCount = Number(meta.response.headers.get('X-Total-Count'));
  return { data, totalCount, status };
}

export function tariffPlansResourceFromDtoServiceObject(dto, meta) {
  const { status } = meta.response;

  return {
    data: {
      ...dto,
      description: createDescription(dto),
      isPublic: dto.isPublic ? 'Да' : 'Нет',
    },
    status
  };
}

tariffPlansResourceFromDtoService.propTypes = {
  dto: tariffPlansResourceDtoType,
};

export default tariffPlansResourceFromDtoService;
