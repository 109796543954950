function degToRad(degrees) {
  return degrees * (Math.PI / 180);
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371; // Earth's radius in kilometers
  const dLat = degToRad(lat2 / 10000000 - lat1 / 10000000);
  const dLon = degToRad(lon2 / 10000000 - lon1 / 10000000);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(degToRad(lat1 / 10000000))
      * Math.cos(degToRad(lat2 / 10000000)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceKm = earthRadiusKm * c;
  return distanceKm;
}

function calculateTotalDistance(points) {
  let totalDistance = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < points.length - 1; i++) {
    const { latitude: lat1, longitude: lon1 } = points[i];
    const { latitude: lat2, longitude: lon2 } = points[i + 1];
    totalDistance += calculateDistance(lat1, lon1, lat2, lon2);
  }

  return totalDistance;
}

export default calculateTotalDistance;
