import { Container } from '@mui/material';
import React, { useMemo } from 'react';
import {
  Route, Routes
} from "react-router-dom";
import TabsPanels from "../../../shared/ui/TabsPanels/TabsPanels";
import UsersInfoPanel from "../../../panels/users/UsersInfoPanel/UsersInfoPanel";
import UserEditPanel from "../../../panels/users/UserEditPanel/UserEditPanel";
import AppUserBll from "../../../entities/appUser/appUsersResource/AppUserBll/AppUserBll";
import AppUserIEditBll from "../../../entities/appUser/appUsersResource/AppUserIEditBll/AppUserIEditBll";
import AppUserICreateBll from "../../../entities/appUser/appUsersResource/AppUserICreateBll/AppUserICreateBll";
import UserCreatePanel from "../../../panels/users/UserCreatePanel/UserCreatePanel";

function UserView() {
  const tabsUserInfo = useMemo(() => ([{
    id: 1,
    name: 'Глобальные пользователи',
    component: (
      <AppUserBll>
        <UsersInfoPanel />
      </AppUserBll>

    )
  }]), []);

  const tabsEditUser = useMemo(() => ([{
    id: 1,
    name: 'Редактирование пользователя',
    component: (
      <AppUserIEditBll>
        <UserEditPanel />
      </AppUserIEditBll>
    )
  }]), []);

  const tabsCreateUser = useMemo(() => ([{
    id: 1,
    name: 'Создание пользователя',
    component: (
      <AppUserICreateBll>
        <UserCreatePanel />
      </AppUserICreateBll>
    )
  }]), []);

  return (
    <Container
      component="main"
      maxWidth="xl"
    >
      <Routes>
        <Route>
          <Route path="" element={<TabsPanels tabs={tabsUserInfo} />} />
          <Route
            path="edit/:userId"
            element={<TabsPanels tabs={tabsEditUser} />}
          />
          <Route
            path="create"
            element={<TabsPanels tabs={tabsCreateUser} />}
          />
        </Route>
      </Routes>
    </Container>

  );
}

export default UserView;
