import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const FollowModeEnum = {
  isActive: "isActive",
};
// Инициализация стейта в сторе
const followModeInitialState = {
  [FollowModeEnum.isActive]: false,
};
// Типизация стора
export const divisionsResourceType = {
  [FollowModeEnum.isActive]: PropTypes.bool,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const followModeSlice = createSlice({
  name: `${reducerPath.followMode}/counter`,
  initialState: followModeInitialState,
  reducers: {
    changeMode(state, action) {
      return { ...action.payload };
    },
  }
});

export const followModeActions = followModeSlice.actions;

export const followModeReducer = followModeSlice.reducer;
