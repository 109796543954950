import replaceSpaces from "./replaceSpaces";

const datesPeriodToString = (date1, date2) => {
  const overDate = new Date();
  overDate.setFullYear(overDate.getFullYear() + 30);
  const isOverYearDate2 = date2?.getFullYear() === 2099;
  const options = {
    month: 'numeric',
    day: 'numeric',
  };

  const date1MatchYear = date1?.getFullYear() === new Date().getFullYear();
  const date2MatchYear = date2?.getFullYear() === new Date().getFullYear() || isOverYearDate2;

  if (!date1MatchYear || !date2MatchYear) {
    options.year = '2-digit';
  }

  const start = replaceSpaces(`c ${date1.toLocaleString('ru', options)}`);
  if (!date2 || isOverYearDate2) {
    return start;
  }

  const finish = replaceSpaces(`по ${date2.toLocaleString('ru', options)}`);

  return `${start} ${finish}`;
};

export default datesPeriodToString;
