import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { Checkbox, ListItemText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelect(props) {
  const {
    items, onChange, value, name
  } = props;

  return (
    <FormControl sx={{ width: '100%' }} size="small">
      <Select
        multiple
        displayEmpty
        value={value}
        name={name}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0 || selected.length === items.length) {
            return 'Все';
          }
          return selected.map((item) => item.name).join(', ');
        }}
        MenuProps={MenuProps}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item}
          >
            <Checkbox
              checked={
                Boolean(value.find((selectedItem) => selectedItem.value === item.value))
              }
            />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultipleSelect;
