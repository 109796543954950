import "rc-tree/assets/index.css";
import './CompanyTreeWidget.css';
import React, { useEffect, useState } from 'react';
import Tree from 'rc-tree';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useConfirm } from "material-ui-confirm";
import LoadingNodeIcon from "../../shared/ui/LoadingNodeIcon/LoadingNodeIcon";
import IconTree from "../../shared/ui/IconTree/IconTree";
import {
  getAppUsersV2, getCompanies, getDivisions, getVehicles
} from "../../shared/api/api";
import { NODES } from "../../entities/tree/config";

export function generateTreeNodes(key, data) {
  const arr = data.map((item, i) => ({
    data: item.data ? { ...item.data } : { ...item },
    title: `${item.title || item.name}`,
    key: item.key || `${key}-${i}`,
    nodeType: item.nodeType,
    icon: <IconTree icon={NODES[item.nodeType].ICON} />,
    isLeaf: item.nodeType === NODES.USER.TYPE
      || item.nodeType === NODES.VEHICLE.TYPE
      || item.nodeType === NODES.PASSENGERS.TYPE
      || item.nodeType === NODES.CARGO.TYPE
  }));
  return arr;
}

export function getNewTreeData(treeData, curKey, child) {
  const loop = (data) => {
    // if (level < 1 || curKey.length - 3 > level * 2) return;
    data.forEach((item) => {
      if (curKey.indexOf(item.key) === 0) {
        if (item.children) {
          loop(item.children);
        } else {
          // eslint-disable-next-line no-param-reassign
          item.children = child;
        }
      }
    });
  };
  loop(treeData);
  // setLeaf(treeData, curKey, level);
}

// проверяет вхождение key
const isKeyIncluded = (key1, key2) => {
  const subKeys1 = key1.split('-');
  const subKeys2 = key2.split('-');
  let i = 0;
  while (i < subKeys2.length && subKeys1[i] === subKeys2[i]) {
    i += 1;
  }
  return i === subKeys2.length;
};

// Возвращает узел из дерева по kurKey
export const getNode = (tree, kurKey) => {
  const loop = (data) => {
    for (let i = 0; i < data.length; i += 1) {
      if (isKeyIncluded(kurKey, data[i].key)) {
        if (data[i].key === kurKey) {
          return data[i];
        } if (data[i].children) {
          return loop(data[i].children);
        }
      }
    }
    return null;
  };
  return loop(tree);
};

// Возвращает адресную строку для выбранного узла дерева
export const getUrlForNode = (openNodes, tabs) => {
  const urls = [];
  const divisionsUrls = [];
  const vehiclesUrls = [];

  let tabUrl = '';
  openNodes.forEach((node) => {
    switch (node.nodeType) {
      case NODES.COMPANY.TYPE:
        urls.push(`companies/${node.data.id}`);
        tabUrl = tabs.company;
        break;
      case NODES.DIVISIONS.TYPE:
        urls.push(`divisions`);
        tabUrl = tabs.divisions;
        break;
      case NODES.DIVISION.TYPE:
        divisionsUrls.push(`${node.data.id}`);
        tabUrl = tabs.division;
        break;
      case NODES.VEHICLE.TYPE:
        vehiclesUrls.push(`vehicles/${node.data.id}`);
        tabUrl = tabs.vehicle;
        break;
      case NODES.USERS.TYPE:
        urls.push(`users`);
        tabUrl = tabs.users;
        break;
      case NODES.USER.TYPE:
        urls.push(`${node.data.id}`);
        tabUrl = tabs.user;
        break;
      case NODES.PASSENGERS.TYPE:
        urls.push(`passengers`);
        tabUrl = tabs.passengers;
        break;
      case NODES.CARGO.TYPE:
        urls.push(`cargo`);
        tabUrl = tabs.cargo;
        break;
      default:
        break;
    }
  });

  const divisionsUrl = divisionsUrls.length ? `/${divisionsUrls.join('-')}` : '';
  const vehiclesUrl = vehiclesUrls.length ? `/${vehiclesUrls.join('-')}` : '';

  return `/${urls.join('/')}${divisionsUrl}${vehiclesUrl}/${tabUrl}`;
};

function CompanyTreeWidget(props) {
  const {
    data, treeData, setTreeData, setSelectedData, toogleSelectedNode,
    treeSelectedKeys, setTreeSelectedKeys, isFormChanged, setIsFormChanged,
    setExpandedKeys, expandedKeys
  } = props;

  const location = useLocation();

  const [keysNodes, setKeysNodes] = useState({ paths: [], node: {} });
  const [params, setParams] = useState(location.pathname);

  const confirm = useConfirm();
  const onSelect = (selectedKeys) => {
    // Если на onClick нет узлов, значит клик на уже выбранный узел => переключение не требуется
    if (selectedKeys.length !== 0) {
      if (isFormChanged) {
        confirm({
          title: 'Отменить изменения?',
          confirmationText: 'Да',
          cancellationText: 'Нет',
          description: `Есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?`
        })
          .then(() => {
            toogleSelectedNode(selectedKeys);
            setIsFormChanged(false);
          });
      } else {
        toogleSelectedNode(selectedKeys);
      }
    }
  };

  const onLoad = (loadedKeys) => {
    // Если загружаемый узел есть в загружаемых из адресной строки
    if (keysNodes.node?.key?.indexOf(loadedKeys[loadedKeys.length - 1]) === 0) {
      let id = 0;
      let { key } = keysNodes.node;
      const path = keysNodes.paths[0];
      let node = {};
      const reg = /^\d+$/;
      // получение нового узла
      switch (path) {
        case 'divisions':
          key += '-0';
          node = getNode(treeData, key);
          setKeysNodes({ paths: keysNodes.paths.slice(1), node });
          break;
        case 'users':
          key += '-1';
          node = getNode(treeData, key);
          setKeysNodes({ paths: keysNodes.paths.slice(1), node });
          break;
        case 'passengers':
          key += '-2';
          node = getNode(treeData, key);
          setKeysNodes({ paths: keysNodes.paths.slice(2, 2), node });
          break;
        case 'cargo':
          key += '-3';
          node = getNode(treeData, key);
          setKeysNodes({ paths: keysNodes.paths.slice(2, 2), node });
          break;
        case 'vehicles':
          id = Number(keysNodes.paths[1]);
          node = !Number.isNaN(id) && keysNodes.node.children.find((child) => child.data.id === id);
          setKeysNodes({ paths: [], node: node || keysNodes.node });
          break;

        case path.match(reg)?.input:
          id = Number(keysNodes.paths[0]);
          node = !Number.isNaN(id) && keysNodes.node.children.find((child) => child.data.id === id);
          setKeysNodes({ paths: keysNodes.paths.slice(1), node: node || keysNodes.node });
          break;

        default:
          setKeysNodes({ paths: [], node: keysNodes.node });
          break;
      }
    }
  };

  const onExpand = (keys) => {
    setExpandedKeys(keys);
  };

  const switcherIcon = (obj) => {
    if (obj.isLeaf) {
      return true;
    }
    let newIcon = null;

    if (obj.loading) {
      newIcon = LoadingNodeIcon;
    } else if (obj.expanded) {
      newIcon = ExpandMoreIcon;
    } else {
      newIcon = ChevronRightIcon;
    }

    return (
      <IconTree
        icon={newIcon}
        style={{ cursor: 'pointer', backgroundColor: 'white' }}
      />
    );
  };

  const onLoadData = (treeNode) => {
    const getData = () => new Promise((resolve, reject) => {
      let newData = [];
      let divisions = null;
      let vehicles = null;
      switch (treeNode.nodeType) {
        case NODES.COMPANIES.TYPE:
          getCompanies().then((res) => {
            newData = res.map((item) => ({ ...item, nodeType: NODES.COMPANY.TYPE }));
            resolve(newData);
          }).catch((error) => reject(error));
          break;
        case NODES.COMPANY.TYPE:
          newData = [
            {
              ...treeNode.data,
              company: { ...treeNode.data },
              name: NODES.DIVISIONS.NAME,
              nodeType: NODES.DIVISIONS.TYPE
            },
            {
              ...treeNode.data,
              company: { ...treeNode.data },
              name: NODES.USERS.NAME,
              nodeType: NODES.USERS.TYPE
            },
            {
              ...treeNode.data,
              company: { ...treeNode.data },
              name: NODES.PASSENGERS.NAME,
              nodeType: NODES.PASSENGERS.TYPE
            },
            {
              ...treeNode.data,
              company: { ...treeNode.data },
              name: NODES.CARGO.NAME,
              nodeType: NODES.CARGO.TYPE
            }
          ];
          resolve(newData);
          break;
        case NODES.DIVISIONS.TYPE:
          getDivisions({ companyId: treeNode.data.companyId }).then((res) => {
            const divisionsRoots = [];
            newData = res.map((item) => {
              const newItem = {
                ...item,
                nodeType: NODES.DIVISION.TYPE,
              };
              if (!item.parent) {
                divisionsRoots.push(newItem);
              }
              return newItem;
            });
            resolve(divisionsRoots);
          }).catch((error) => reject(error));
          break;
        case NODES.DIVISION.TYPE:
          divisions = getDivisions({
            companyId: treeNode.data.company.companyId,
            parentId: treeNode.data.divisionId
          });
          vehicles = getVehicles({
            companyId: treeNode.data.company.companyId,
            divisionId: treeNode.data.divisionId
          });
          Promise.all([divisions, vehicles]).then((responses) => {
            newData = [];
            responses.forEach((res, i) => {
              res.forEach((item) => {
                newData.push({
                  ...item,
                  nodeType: i < 1 ? NODES.DIVISION.TYPE : NODES.VEHICLE.TYPE
                });
              });
            });
            resolve(newData);
          }).catch((error) => reject(error));
          break;
        case NODES.USERS.TYPE:
          getAppUsersV2([
            { name: 'companyId.equals', value: treeNode.data.companyId },
            { name: 'role.in', value: 'ROLE_COMPANY_ADMIN' },
            { name: 'role.in', value: 'ROLE_USER' },
          ]).then((res) => {
            newData = res.sort(({ name: a }, { name: b }) => a.localeCompare(b))
              .map((item) => ({ ...item, nodeType: NODES.USER.TYPE }));
            resolve(newData);
          }).catch((error) => reject(error));
          break;
        default:
          resolve();
          break;
      }
    });

    return getData().then((resultData) => {
      const newTreeData = [...treeData];
      getNewTreeData(newTreeData, treeNode.key, generateTreeNodes(treeNode.key, resultData), 2);
      setTreeData(newTreeData);
    }).catch((error) => {
      throw error;
    });
  };

  useEffect(() => {
    if (location.pathname === params) setParams(location.pathname);
  }, [location, params]);

  useEffect(() => {
    if (data) {
      const newTreeData = data.map((item, i) => {
        const existingCompanyData = treeData?.find(
          (t) => t.id === item.id && t.nodeType === NODES.COMPANY.TYPE
        ) || {};

        return {
          ...existingCompanyData,
          data: { ...item, nodeType: NODES.COMPANY.TYPE },
          title: item.name,
          key: `${i}`,
          nodeType: NODES.COMPANY.TYPE,
          icon: <IconTree icon={NODES.COMPANY.ICON} />,
          id: item.id
        };
      });
      setTreeData(newTreeData);
      const reg = /[/-]/;
      const keys = params.split(reg);
      const index = keys.indexOf("companies");
      const id = keys[index + 1];
      const newNode = (id && newTreeData.find((node) => node.id === Number(id))) || {};
      const newPaths = keys.slice(index + 2, -1);
      if (keysNodes?.paths?.length > 0 && keysNodes?.paths?.length < newPaths.length) {
        // Игнорируем дублирующее обновление данных в процессе разворачивания дерева,
        // чтобы избежать ошибочного рендеринга дерева
        return;
      }
      setKeysNodes({
        paths: newPaths,
        node: newNode,
      });
    }
  }, [data, setTreeData, params]);

  useEffect(() => {
    if (keysNodes.node.key && keysNodes.paths.length > 0) {
      setExpandedKeys((prev) => [...prev, keysNodes.node.key]);
    }
    if (keysNodes.node.key && keysNodes.paths.length === 0) {
      setTreeSelectedKeys([keysNodes.node.key]);
      setSelectedData({ ...keysNodes.node.data, key: keysNodes.node.key });
    }
  }, [keysNodes, setSelectedData, setTreeSelectedKeys, setExpandedKeys]);

  return (
    <Tree
      multiple={false}
      showLine
      onSelect={onSelect}
      loadData={onLoadData}
      treeData={treeData}
      switcherIcon={switcherIcon}
      onExpand={onExpand}
      onLoad={onLoad}
      selectedKeys={treeSelectedKeys}
      expandedKeys={expandedKeys}
    />
  );
}

CompanyTreeWidget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.oneOf([null]),
  ])),
  treeData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  setTreeData: PropTypes.func.isRequired,
  setSelectedData: PropTypes.func.isRequired,
  treeSelectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTreeSelectedKeys: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
  toogleSelectedNode: PropTypes.func.isRequired,
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  setExpandedKeys: PropTypes.func.isRequired,
};

CompanyTreeWidget.defaultProps = {
  data: null,
  expandedKeys: null,
};

export default CompanyTreeWidget;
