import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../shared/ui/Header/Header';
import MainMenu from '../../menu/MainMenu/MainMenu';
import FeedbackDlg from '../../views/FeedbackDlg/FeedbackDlg';
import FeedbackBll from '../../views/FeedbackDlg/FeedbackBll';
import Snackbar from '../../shared/snackbar/ui/Snackbar';

// Отображает общие компоненты, не относящиеся к конкрентному view
export function Layout() {
  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const [openFeedbackDlg, setOpenFeedbackDlg] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');

  const closeSnackbar = () => {
    setIsOpenSnackbar(false);
  };

  const openSnackbar = (message) => {
    setMessageSnackbar(message);
    setIsOpenSnackbar(true);
  };

  const handleClickOpen = () => {
    setOpenFeedbackDlg(true);
  };

  const handleClose = () => {
    setOpenFeedbackDlg(false);
  };

  return (
    <>
      <Header
        handleDrawer={handleDrawer}
      />
      <MainMenu
        open={open}
        handleDrawer={handleDrawer}
        handleClickOpen={handleClickOpen}
      />
      <Outlet />
      <Snackbar
        isOpenSnackbar={isOpenSnackbar}
        closeSnackbar={closeSnackbar}
        message={messageSnackbar}
      />
      <FeedbackBll
        openSnackbar={openSnackbar}
      >
        <FeedbackDlg
          openFeedbackDlg={openFeedbackDlg}
          handleClose={handleClose}
        />
      </FeedbackBll>
    </>
  );
}

export default Layout;
