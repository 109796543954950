// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import alertsSettingsResourceDtoType from "../../types/alertsSettingsResource-dto.type";
import { statuses, statusesRu } from "../../../alertsResource/services/data/alertsResource-enums";
import formatFromTimeISO8601 from "../../../../../shared/utils/formatFromTimeISO8601";

export const typeMessages = [
  { id: "VEHICLE_LEAVED", ruName: "АВТОМОБИЛЬ УШЕЛ" },
  { id: "VEHICLE_ARRIVED", ruName: "АВТОМОБИЛЬ ПРИБЫЛ" },
  { id: "VEHICLE_ROUTE_STARTED", ruName: "МАРШРУТ ТРАНСПОРТНОГО СРЕДСТВА НАЧАЛСЯ" },
  { id: "VEHICLE_ROUTE_CLOSED", ruName: "МАРШРУТ ДЛЯ АВТОМОБИЛЯ ЗАКРЫТ" },
  { id: "VEHICLE_LATE_TO_LEAVE", ruName: "АВТОМОБИЛЬ ПОЗДНЕЕ УЕЗЖАЕТ" },
  { id: "VEHICLE_LATE_TO_ARRIVE", ruName: "АВТОМОБИЛЬ ОПАЗДЫВАЕТ" },
  { id: "DENM_SIGNAL_VIOLATION", ruName: "НАРУШЕНИЕ СИГНАЛА" },
  { id: "DENM_COLLISION_RISK", ruName: "РИСК СТОЛКНОВЕНИЯ" }
];

export const typeSeverity = [
  { id: statuses.ALL, ruName: statusesRu[statuses.ALL] },
  { id: statuses.ERROR, ruName: statusesRu[statuses.ERROR] },
  { id: statuses.CRITICAL, ruName: statusesRu[statuses.CRITICAL] },
  { id: statuses.INFO, ruName: statusesRu[statuses.INFO] },
  { id: statuses.WARNING, ruName: statusesRu[statuses.WARNING] }];

export function alertsSettingsResourceFromDtoServiceObject(dto, meta) {
  const { status } = meta.response;
  const newData = { ...dto };
  // eslint-disable-next-line prefer-destructuring
  newData.severity = typeSeverity
    .filter((e) => e.id === dto.severity)[0];

  newData.repeatDuration = formatFromTimeISO8601(dto.repeatDuration, true);
  const newCode = typeMessages.find((e) => e.id === dto.code);
  newData.code = newCode || { id: dto.code, ruName: dto.code };

  return { data: newData, status };
}

export function alertsSettingsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const newData = dto.map((e) => alertsSettingsResourceFromDtoServiceObject(e, meta).data);

  return { data: newData, status };
}

alertsSettingsResourceFromDtoService.propTypes = {
  dto: alertsSettingsResourceDtoType,
};

export default alertsSettingsResourceFromDtoService;
