// Описание головы запроса
// Будет использоваться при каждом запросе на бек
const createHeadRequest = ({
  url, method, authToken, customHeader
}) => {
  const header = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  return customHeader || header;
};

export default createHeadRequest;
