import React from 'react';
import {
  Alert,
  AlertTitle,
  Box, Container, Typography
} from '@mui/material';
import { useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { green, red } from '@mui/material/colors';
import { useGetAccountActivateQuery } from '../../../entities/account/accountResource/redux/accountResource.api';
import Loading from '../../../shared/ui/Loading/Loading';

function AccountActivateView() {
  const [searchParams] = useSearchParams();

  const key = searchParams.get('key');
  const params = key ? { key } : {};
  const accountActivate = useGetAccountActivateQuery({ params });

  const isActivate = key && !accountActivate.isError && accountActivate.status === 'fulfilled';

  return (
    <Container
      component="main"
      maxWidth="sm"
    >
      <Box
        sx={{
          marginTop: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: isActivate ? green[500] : red[500] }}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ВИСТАР-М
        </Typography>
        {accountActivate.isLoading
          ? <Loading />
          : !isActivate
            ? (
              <Alert severity="error" sx={{ width: '100%', marginTop: 8 }}>
                <AlertTitle>Ошибка</AlertTitle>
                Не удалось активировать аккаунт
              </Alert>
            )
            : (
              <Alert severity="success" sx={{ width: '100%', marginTop: 8 }}>
                <AlertTitle>Успешно</AlertTitle>
                Ваш аккаунт успешно активирован
              </Alert>
            )}
      </Box>
    </Container>
  );
}

export default AccountActivateView;
