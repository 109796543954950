import {
  Autocomplete, Box, Button, TextField
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import {
  getCompanies, getCompany, getDivisions, postDivision, putDivisions,
} from '../../../shared/api/api';
import style from './DivisionFormWidget.module.css';
import useAuth from "../../../shared/lib/hooks/useAuth";

const initialDivision = {
  name: '',
  company: null,
  parent: null,
};
const initialDivisionErrors = {
  name: false,
  company: false,
};

// Отображает форму с инфо о ТС
function DivisionFormWidget(props) {
  const {
    selectedDivision, company, readOnly, updateTree, setIsFormChanged, isFormChanged
  } = props;
  const [division, setDivision] = useState({ ...initialDivision, ...selectedDivision });
  const [companies, setCompanies] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [errors, setErrors] = useState({ ...initialDivisionErrors });

  const changeTextField = (event) => {
    setDivision((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    if (errors[event.target.id]) {
      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
    }
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  const resetForm = useCallback((divisionInfo) => {
    setIsFormChanged(false);
    setErrors({ ...initialDivision });
    setDivision({ ...initialDivision, ...divisionInfo });
  }, [setIsFormChanged]);

  const confirm = useConfirm();
  const handleResetForm = (divisionInfo) => {
    confirm({
      title: 'Отменить изменения?',
      confirmationText: 'Да',
      cancellationText: 'Нет',
      description: `Вы уверены, что хотите отменить изменения в форме?`
    })
      .then(() => {
        resetForm(divisionInfo);
      });
  };

  const saveForm = useCallback(() => {
    let isError = false;
    Object.keys(initialDivisionErrors).forEach((key) => {
      if (!isError) {
        isError = !(division[key]);
      }
      setErrors((prev) => ({ ...prev, [key]: !(division[key]) }));
    });
    if (!isError) {
      const body = {
        name: division.name,
        company: division.company,
        parent: division.parent,
      };
      if (selectedDivision.divisionId) {
        putDivisions({ ...body, divisionId: selectedDivision.divisionId }).then((res) => {
          updateTree(selectedDivision.key, division);
          resetForm(res);
        }).catch((error) => { throw error; });
      } else {
        postDivision(body).then((res) => {
          updateTree(selectedDivision.key, { ...res, ...division }, true);
          resetForm(res);
        }).catch((error) => { throw error; });
      }
    }
  }, [division, resetForm, updateTree, selectedDivision]);
  const { user } = useAuth();
  useEffect(() => {
    resetForm(selectedDivision);
    if (user?.authorities.includes("ROLE_ADMIN")) {
      getCompanies().then((data) => {
        setCompanies(data);
      }).catch((error) => { throw error; });
    } else {
      getCompany(company.companyId).then((data) => {
        setCompanies([data]);
      }).catch((error) => { throw error; });
    }

    getDivisions({ companyId: company.companyId }).then((data) => {
      setDivisions(data.filter((divisionData) => (
        divisionData.divisionId !== selectedDivision.divisionId
      )));
    }).catch((error) => { throw error; });
  }, [company, selectedDivision, resetForm]);

  return (
    <Box className={style.divisionFormWidget}>
      <TextField
        fullWidth
        id="name"
        label="Наименование"
        value={division.name}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.name)}
        helperText={errors.name ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <Autocomplete
        readOnly={readOnly}
        disablePortal
        id="company"
        options={companies}
        value={division.company}
        onChange={(event, newValue) => {
          if (errors.company) {
            setErrors((prev) => ({ ...prev, company: newValue === null }));
          }
          setDivision((prev) => ({ ...prev, company: newValue }));
          if (!isFormChanged) {
            setIsFormChanged(true);
          }
        }}
        isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors.company)}
            helperText={errors.company ? "Поле не может быть пустым" : null}
            label="Компания"
          />
        )}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <Autocomplete
        readOnly={readOnly}
        disablePortal
        id="parent"
        options={divisions}
        value={division.parent}
        onChange={(event, newValue) => {
          setDivision((prev) => ({ ...prev, parent: newValue }));
          if (!isFormChanged) {
            setIsFormChanged(true);
          }
        }}
        isOptionEqualToValue={(option, value) => option.divisionId === value.divisionId}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Подразделение"
          />
        )}
      />
      {readOnly
        ? null
        : (
          <div className={style.buttonts}>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => handleResetForm(selectedDivision)}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => saveForm()}
              variant="contained"
            >
              Сохранить
            </Button>
          </div>
        )}
    </Box>
  );
}

DivisionFormWidget.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  selectedDivision: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  readOnly: PropTypes.bool,
  updateTree: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
};

DivisionFormWidget.defaultProps = {
  selectedDivision: null,
  readOnly: true,
  company: null,
};

export default DivisionFormWidget;
