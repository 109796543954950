import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import tariffPlansResourceFromDtoService, {
  tariffPlansResourceFromDtoServiceObject
} from "../services/data/tariffPlansResource-from-dto.service";
// eslint-disable-next-line import/no-named-as-default
import tariffPlansResourceToDtoServiceObject from "../services/data/tariffPlansResource-to-dto.service";
import apiSlice from '../../../../shared/api/apiSlice';

const url = '/tarif-plans';

// Описываем методы обращения к беку
export const tariffPlansResourceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTariffPlans: builder.query({
      query: (params) => ({
        url,
        method: HttpMethods.GET,
        validateStatus: checkStatus,
        params: params?.params
      }),
      transformResponse: (dto, meta) => tariffPlansResourceFromDtoService(dto, meta),
      providesTags: ['tarif-plans']
    }),
    getTariffPlanResourceId: builder.query({
      query: ({
        id
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.GET,
        validateStatus: checkStatus,
      }),
      providesTags: ['tarif-plans', 'tarif-plan'],
      transformResponse: tariffPlansResourceFromDtoServiceObject,
    }),
    putTariffPlanResource: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.PUT,
        body: tariffPlansResourceToDtoServiceObject(body),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['tarif-plans'],
      transformResponse: tariffPlansResourceFromDtoServiceObject,
    }),
    putTariffPlanResourceNoProvider: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.PUT,
        body: tariffPlansResourceToDtoServiceObject(body),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['tarif-plan'],
      transformResponse: tariffPlansResourceFromDtoServiceObject,
    }),
    postTariffPlanResource: builder.mutation({
      query: ({
        body
      }) => ({
        url,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['tarif-plans'],
      transformResponse: tariffPlansResourceFromDtoServiceObject,
    }),
    deleteTariffPlanResource: builder.mutation({
      query: ({
        id
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.DELETE,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['tarif-plans']
    }),
  }),
});

export const {
  useGetTariffPlansQuery,
  useLazyGetTariffPlansQuery,
  useGetTariffPlanResourceIdQuery,
  usePutTariffPlanResourceMutation,
  usePutTariffPlanResourceNoProviderMutation,
  usePostTariffPlanResourceMutation,
  useDeleteTariffPlanResourceMutation,
} = tariffPlansResourceApi;
