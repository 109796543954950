import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, Box, Button, Container, IconButton, Stack, TextField, Typography
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom';
import CompanyTariffPlanPanel from '../../../panels/companies/CompanyTariffPlanPanel/CompanyTariffPlanPanel';
import useAuth from '../../../shared/lib/hooks/useAuth';
import companyAccountsApi from '../../../entities/companies/companyAccounts/redux/companyAccounts.api';
import style from './CompanyWizardView.module.css';
import { getCompany, postDivision, postVehicle } from '../../../shared/api/api';
import useShowError from '../../../shared/lib/hooks/useShowError';

const initialDivision = {
  name: "Мой автопарк",
  vehicles: [
    {
      name: "",
      num: "",
      model: {
        vehicleModelId: 2,
        name: "КаМАЗ 43502",
        engine:
          "6,7л, дизельный с турбонаддувом, с промежуточным охлаждением наддувочного воздуха",
        yearFrom: 2012,
        yearTo: null,
        length: 7490,
        width: 2500,
        outfittedWeight: 7325,
        fullWeight: 12700,
        carryingCapacity: 19700,
        isTrallerEnable: true,
        brand: {
          vehicleBrandId: 2,
          name: "KAMAZ",
        },
      },
    },
  ],
};
const initialDivisionErrors = {};
const initialVehicleErrors = [];

function checkRequiredText(val) {
  return val?.trim() === "" && 'Поле не может быть пустым';
}

// Отображает страницу "Быстрое добавление автомобилей в автопарк" по компнаии
function CompanyWizardView() {
  const navigate = useNavigate();
  const showError = useShowError();
  const { companyAccount } = useAuth();
  const [division, setDivision] = useState(JSON.parse(JSON.stringify(initialDivision)));
  const [errors, setErrors] = useState({ ...initialDivisionErrors });
  const [vehicleErrors, setVehicleErrors] = useState([...initialVehicleErrors]);
  const [tariffPlan, setTariffPlan] = useState(null);
  const [company, setCompany] = useState(null);
  const [isAddVehicleDisabled, setIsAddVehicleDisabled] = useState(false);
  const [isExtraVehicles, setIsExtraVehicles] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    if (companyAccount?.company?.companyId) {
      getCompany(companyAccount.company.companyId).then((data) => {
        setCompany(data);
      }).catch((error) => showError(error));
    }
  }, [companyAccount]);

  const [putCompanyAccount] = companyAccountsApi.usePutCompanyAccountMutation();

  const putCompanyAccountParams = useCallback(
    ({ id, body }) => putCompanyAccount({
      id,
      body
    }),
    [putCompanyAccount]
  );

  const validateDivisionName = (event) => {
    setErrors(
      (prev) => ({ ...prev, [event.target.id]: checkRequiredText(event.target.value) })
    );
  };

  const changeDivisionName = (event) => {
    setDivision((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    if (errors[event.target.id]) {
      validateDivisionName(event);
    }
  };

  const validateRegNum = (e, i) => {
    setVehicleErrors((prev) => {
      const newError = checkRequiredText(e.target.value);
      const newErrors = [...prev];
      newErrors[i] = newErrors[i] || {};
      newErrors[i].num = newError;
      return newErrors;
    });
  };

  const handleRegNumChange = (e, i) => {
    setDivision((prev) => {
      const vehicles = [...prev.vehicles];
      vehicles[i].num = e.target.value;
      return { ...prev, vehicles };
    });
    if (vehicleErrors[i]?.num) {
      validateRegNum(e, i);
    }
  };

  // const handleTrackerIdChange = (e, i) => {
  //   setDivision((prev) => {
  //     const vehicles = [...prev.vehicles];
  //     vehicles[i].trackerId = e.target.value;
  //     return { ...prev, vehicles };
  //   });
  // };

  const addVehicle = () => {
    const currentLength = division.vehicles.length;
    setDivision((prev) => {
      const vehicles = [...prev.vehicles];
      vehicles.push(JSON.parse(JSON.stringify(initialDivision)).vehicles[0]);
      return { ...prev, vehicles };
    });
    validateRegNum({ target: { value: initialDivision.vehicles[0].num } }, currentLength);
  };

  const removeVehicle = (i) => {
    setDivision((prev) => {
      const vehicles = [...prev.vehicles];
      vehicles.splice(i, 1);
      return { ...prev, vehicles };
    });
    setVehicleErrors((prev) => {
      const newErrors = [...prev];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const getFormErrors = () => Object.values(errors || {})?.filter((e) => e).length > 0
      || vehicleErrors?.filter(
        (e) => Object.values(e || {})?.filter((err) => err)?.length > 0
      )?.length > 0;

  useEffect(() => {
    const vehicleLength = division?.vehicles?.length;
    const extraVehicles = tariffPlan?.vehicleCount && vehicleLength > tariffPlan?.vehicleCount;
    const ehoughVehicles = extraVehicles || vehicleLength === tariffPlan?.vehicleCount;
    setIsAddVehicleDisabled((vehicleLength > 0
      ? !!checkRequiredText(division?.vehicles?.[vehicleLength - 1].num) : false)
      || ehoughVehicles);
    setIsExtraVehicles(extraVehicles);
    setIsFormInvalid(extraVehicles || getFormErrors());
  }, [division?.vehicles, tariffPlan?.vehicleCount, errors, vehicleErrors]);

  const handleCancel = () => {
    navigate(`/companies/${company.companyId}/main`);
  };

  const handleSave = () => {
    const savePromises = [];
    if (isFormInvalid) {
      return;
    }
    if (tariffPlan) {
      savePromises.push(putCompanyAccountParams({
        id: companyAccount.companyAccountId,
        body: {
          ...companyAccount,
          nextPlan: tariffPlan
            ? {
              tarifPlanId: tariffPlan.tarifPlanId
            }
            : null
        }
      }));
    }
    if (division.name) {
      savePromises.push(postDivision({
        name: division.name,
        company,
      }).then((divisionRes) => {
        const vehiclePromises = (division?.vehicles || [])
          .filter((vehicle) => vehicle?.num)
          .map((v) => postVehicle({
            name: v.num,
            num: v.num,
            division: divisionRes,
            company,
            model: v.model,
          }).catch((error) => { throw error; }));
        return Promise.allSettled(vehiclePromises).catch((error) => { throw error; });
      }));
      Promise.allSettled(savePromises).then((results) => {
        if (!results.find((r) => r.status !== 'fulfilled')) {
          navigate(`/companies/${company.companyId}/main`);
        }
      }).catch((error) => showError(error.errorMessage || 'Ошибка сохранения'));
    }
  };

  return (
    <Container
      component="main"
    >
      <Box className={style.divisionFormWidget}>
        <Typography variant="h6" sx={{ my: '40px' }}>
          Быстрое добавление автомобилей в автопарк
        </Typography>
        <TextField
          fullWidth
          size="small"
          id="name"
          label="Название подразделения"
          value={division.name}
          onChange={changeDivisionName}
          required
          InputProps={{
            readOnly: false
          }}
          error={!!errors.name}
          helperText={errors.name}
          onBlur={validateDivisionName}
        />
        <Typography variant="h6" component="div" sx={{ color: 'text.secondary', my: '20px' }}>
          Транспортные средства
        </Typography>
        {
          division.vehicles.map((vehicle, i) => (
            <Stack
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              direction="row"
              spacing={1}
              sx={{ my: 0.5, alignItems: 'flex-start' }}
            >
              <TextField
                autoFocus
                required
                fullWidth
                id={`num_${i}`}
                size="small"
                value={vehicle.num}
                label="Госномер ТС"
                error={!!vehicleErrors[i]?.num}
                helperText={vehicleErrors[i]?.num}
                onChange={(e) => handleRegNumChange(e, i)}
                onBlur={(e) => validateRegNum(e, i)}
              />
              {/* <TextField
                fullWidth
                id="trackerId"
                size="small"
                value={vehicle.trackerId}
                label="Идентификатор трекера"
                onChange={(e) => handleTrackerIdChange(e, i)}
              /> */}
              <>
                <IconButton
                  disabled={division.vehicles.length < 2}
                  onClick={() => removeVehicle(i)}
                  color="primary"
                  title="Удалить транспортное средство"
                >
                  <RemoveCircleIcon />
                </IconButton>
                <IconButton
                  disabled={isAddVehicleDisabled}
                  onClick={i === division.vehicles.length - 1 ? addVehicle : null}
                  color="primary"
                  sx={{ visibility: i === division.vehicles.length - 1 ? 'visible' : 'hidden' }}
                  title="Добавить транспортное средство"
                >
                  <AddCircleIcon />
                </IconButton>
              </>
            </Stack>
          ))
        }
        { isExtraVehicles && (
          <Alert sx={{ mt: 1 }} severity="error">
            Число ТС не должно превышать
            {' '}
            {tariffPlan?.vehicleCount}
          </Alert>
        )}
        {!companyAccount?.isFetching
          ? (
            <CompanyTariffPlanPanel
              companyAccount={companyAccount}
              putCompanyAccountParams={putCompanyAccountParams}
              loading={!companyAccount}
              headerPrefix="Тарифный план"
              onChangeCB={(value) => setTariffPlan(value)}
            />
          ) : null}
        <Stack gap={1} direction="row" spacing={1} sx={{ my: 3, justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            onClick={handleCancel}
            variant="text"
          >
            Отмена
          </Button>
          <Button
            disabled={isFormInvalid}
            onClick={handleSave}
            variant="contained"
          >
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}

export default CompanyWizardView;
