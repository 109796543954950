import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';

const OPTIONS_TYPE = {
  NEW: "new"
};

const filter = createFilterOptions();

export default function Tags({ data, onChange, tagOptions }) {
  const [tags, setTags] = React.useState([]);
  const [tagOptionsVal, setTagOptionsVal] = React.useState([]);
  const [tagName, setTagName] = React.useState('');
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [selectedAddTag, setSelectedAddTag] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [enableEnterOnInput, setEnableEnterOnInput] = React.useState(true);

  React.useEffect(() => {
    const value = tags.map((t) => t.name).join(', ');
    if (value !== data) {
      onChange(value);
    }
  }, [tags]);

  React.useEffect(() => {
    setTagOptionsVal(tagOptions?.split(',').map((d, i) => (d.trim() ? { id: i + 1, name: d.trim() }
      : undefined)).filter((d) => d?.name) || []);
  }, [tagOptions]);

  React.useEffect(() => {
    setTags(data?.split(',').map((d, i) => (d.trim() ? { id: i + tagOptionsVal.length, name: d.trim() }
      : undefined)).filter((d) => d?.name) || []);
  }, []);

  React.useEffect(() => {
    if (selectedTag === null) {
      setTagName("");
    }
  }, [selectedTag, selectedAddTag]);

  const handleChange = (event, value, reason) => {
    let lastItem;
    let isExists;
    switch (reason) {
      case "clear":
        setTags([]);
        break;
      case "removeOption":
        setTags(value);
        break;
      case "selectOption":
        lastItem = value[value.length - 1];
        if (typeof lastItem === "string") {
          setSelectedTag(null);
          break;
        }
        if (lastItem.type) {
          setSelectedAddTag(lastItem);
          setOpenDialog(true);
          break;
        }
        isExists = tags.some(
          (tagItem) => tagItem.id === lastItem.id
        );
        if (isExists) {
          setSelectedTag(null);
        } else {
          setSelectedTag(lastItem);
          setTags(value);
        }
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.target.value?.length
        && (evt.key === 'Tab' || evt.key === ','
          || (enableEnterOnInput && evt.key === 'Enter') || evt.type === 'blur')) {
      setTags([...tags, { id: tags.length, name: evt.target.value }]);
      handleChange(null, evt.target.value, 'selectOption');
      setTagName('');
      evt.preventDefault();
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: '500px' }}>
        <Autocomplete
          id="tags-combo-box"
          options={tagOptionsVal}
          value={tags}
          multiple
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }

            return option.name;
          }}
          inputValue={tagName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Тэги"
              variant="outlined"
              onKeyDown={handleKeyDown}
              onBlur={handleKeyDown}
            />
          )}
          onInputChange={(event, value) => {
            setTagName(value);
          }}
          onClose={(event, reason) => {
            if (reason === "blur") {
              setSelectedTag(null);
            }
          }}
          onChange={handleChange}
          freeSolo
          handleHomeEndKeys
          filterSelectedOptions
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.push({
                type: OPTIONS_TYPE.NEW,
                value: params.inputValue,
                name: `Добавить "${params.inputValue}"`
              });
            }
            return filtered;
          }}
          onHighlightChange={(evt, option) => {
            if (option?.value) {
              setEnableEnterOnInput(false);
            }
          }}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => {
          setSelectedAddTag(null);
          setOpenDialog(false);
          setEnableEnterOnInput(true);
        }}
      >
        <DialogContent>
          Вы хотите добавить тэг
          {' '}
          {selectedAddTag?.value ?? ''}
          ?
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setSelectedAddTag(null);
              setOpenDialog(false);
              setEnableEnterOnInput(true);
            }}
          >
            Нет
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let maxId = 1;
              // eslint-disable-next-line no-restricted-syntax
              for (const item in tags) {
                if (item.id > maxId) {
                  maxId = item.id;
                }
              }
              setTags([...tags, { id: maxId + 1, name: selectedAddTag.value }]);
              setOpenDialog(false);
              setEnableEnterOnInput(true);
            }}
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Tags.propTypes = {
  data: PropTypes.string,
  tagOptions: PropTypes.string,
  onChange: PropTypes.func,
};

Tags.defaultProps = {
  data: null,
  tagOptions: null,
  onChange: () => {},
};
