import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line boundaries/element-types
import {
  useDeleteAppUserResourceMutation,
  useLazyGetAppUserResourceQuery
} from "../../../account/appUsersResource/redux/appUsersResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AppUserBll({ children }) {
  const [data, setData] = useState();

  const [
    fetchUserResource,
    { data: dataUserResourceId }
  ] = useLazyGetAppUserResourceQuery();

  const [
    deleteAppUserResource,
  ] = useDeleteAppUserResourceMutation();

  const deleteAppUserResourceParams = useCallback(({ id }) => deleteAppUserResource({
    id
  }), []);

  const fetchUserResourceParams = useCallback(({ firstName, lastName }) => {
    const newParams = {};
    if (firstName) {
      newParams["firstName.equals"] = firstName;
    }

    if (lastName) {
      newParams["lastName.equals"] = lastName;
    }
    return fetchUserResource({
      params: {
        ...newParams,
        'companyId.specified': false,
      }
    });
  }, []);

  useEffect(() => {
    setData({
      dataUserResourceId
    });
  }, [dataUserResourceId]);

  const props = {
    data,
    fetchUserResourceParams,
    deleteAppUserResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { Children
        .toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AppUserBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppUserBll;
