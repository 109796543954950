import apiConfig from "./api-config";

const isDev = process.env.NODE_ENV === 'development';
const config = {};

const setConfig = (newConfig) => {
  const REACT_APP_API = isDev ? '/api' : newConfig?.REACT_APP_API || apiConfig.REACT_APP_API;
  const REACT_APP_WS = isDev ? `/ws` : newConfig?.REACT_APP_WS || apiConfig.REACT_APP_WS;
  const REACT_APP_VK_APP_ID = newConfig?.REACT_APP_VK_APP_ID || apiConfig.REACT_APP_VK_APP_ID;

  config.baseUrl = `${REACT_APP_API}/v1/core`;
  config.platoonGroupsResourceUrl = `${REACT_APP_API}/v1/platoon`;
  config.socketUrl = `${REACT_APP_WS}/v1/core`;
  config.vstorageUrl = `${REACT_APP_API}/v1/vstorage`;
  config.REACT_APP_VK_APP_ID = isDev ? 51798451 : Number(REACT_APP_VK_APP_ID);
  config.logisticUrl = `${REACT_APP_API}/v1/logistic`;
  config.reportsUrl = `${REACT_APP_API}/v1/reports`;
};

setConfig();

export { setConfig };

export default config;
