import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledMenu from '../../../shared/ui/StyledMenu/StyledMenu';

// Отображает элементы управления записью о пассажире
function PassangerControls(props) {
  const {
    onDelete,
    onEdit,
    hover,
    setHover,
    onAddTags,
    onChangeTags,
    onDeleteTags,
    onAddRoute,
    onChangeRoute,
    countRoutes,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setHover(false);
  };

  return (
    <Box sx={hover ? { visibility: 'visible' } : { visibility: 'hidden' }}>
      <IconButton onClick={handleClick} size="small">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          <EditIcon color="primary" />
          Редактировать
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onAddTags();
            handleClose();
          }}
          disableRipple
        >
          <AddIcon color="primary" />
          Добавить метки
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChangeTags();
            handleClose();
          }}
          disableRipple
        >
          <AutorenewIcon color="primary" />
          Заменить метки
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteTags();
            handleClose();
          }}
          disableRipple
        >
          <CloseIcon color="primary" />
          Удалить метки
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onAddRoute();
            handleClose();
          }}
          disableRipple
        >
          <AddIcon color="primary" />
          Назначить маршрут
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChangeRoute();
            handleClose();
          }}
          disableRipple
          disabled={countRoutes < 1}
        >
          <AutorenewIcon color="primary" />
          Изменить маршрут
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onDelete();
            handleClose();
          }}
          disableRipple
        >
          <DeleteIcon color="error" />
          Удалить
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

PassangerControls.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAddTags: PropTypes.func.isRequired,
  onChangeTags: PropTypes.func.isRequired,
  onDeleteTags: PropTypes.func.isRequired,
  onAddRoute: PropTypes.func.isRequired,
  onChangeRoute: PropTypes.func.isRequired,
  hover: PropTypes.bool,
  setHover: PropTypes.func,
  countRoutes: PropTypes.number,
};

PassangerControls.defaultProps = {
  setHover: () => {},
  hover: null,
  countRoutes: 0,
};

export default PassangerControls;
