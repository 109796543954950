// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehicleFuelEfficiencyFromDtoService from "../services/data/vehicleFuelEfficiency-from-dto.service";
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehicleFuelEfficiencyApi = createApi({
  reducerPath: `${reducerPath.vehicleFuelEfficiency}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.vstorageUrl}/fuel-10-profiles`),
  endpoints: (builder) => ({
    getVehicleFuelEfficiencyParams: builder.mutation({
      query: ({ params }) => ({
        ...createHeadRequest({ url: "/", method: HttpMethods.GET }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: vehicleFuelEfficiencyFromDtoService
    }),
  }),
});

export const {
  useGetVehicleFuelEfficiencyParamsMutation
} = vehicleFuelEfficiencyApi;
