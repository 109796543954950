import React from 'react';
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
// Показ текста с ошибкой
function ErrsMessage({ value }) {
  return (
    <Typography
      sx={{
        p: 1,
      }}
    >
      {value}
    </Typography>
  );
}

ErrsMessage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ErrsMessage;
