import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import RouteIcon from '@mui/icons-material/Route';
import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useConfirm } from "material-ui-confirm";
import IconButtonBadge from '../../shared/ui/IconButtonBadge/IconButtonBadge';

function RoutesToolbarWidget(props) {
  const {
    toogleFilters,
    toggleTableMode,
    createVehicleRoute,
    postVehicleRouteFile,
  } = props;

  const confirm = useConfirm();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const type = selectedFile.name?.split('.');

      // Проверяем размер файла (не более 2 МБ)
      if (selectedFile.size > 2 * 1024 * 1024) {
        confirm({
          title: 'Ошибка',
          confirmationText: 'Да',
          cancellationText: 'Отмена',
          description: `Лимит превышен. Файл должен быть не более 2 МБ.`
        })
          .then(() => {})
          .catch(() => { });
      } else if (type[type.length - 1]?.toLowerCase() === 'gpx' || type[type.length - 1]?.toLowerCase() === 'kml') {
        const formData = new FormData();
        formData.append('file', selectedFile);
        postVehicleRouteFile(formData, selectedFile.name);
      } else {
        confirm({
          title: 'Ошибка',
          confirmationText: 'Да',
          cancellationText: 'Отмена',
          description: `Ошибка формата. Файл должен быть .gpx или .kml`
        })
          .then(() => {})
          .catch(() => { });
      }
    }
  };

  return (
    <Box sx={{
      width: '100%', display: 'flex', gap: '20px', justifyContent: 'right', paddingTop: '10px'
    }}
    >
      <IconButtonBadge
        title="Новый маршрут"
        onClick={createVehicleRoute}
      >
        <RouteIcon />
      </IconButtonBadge>
      <IconButtonBadge
        title="Импорт маршрута"
        sx={{ position: "relative" }}
      >
        <>
          <input
            style={{
              position: "absolute",
              opacity: "0",
              width: "41px",
              height: "40px",
              left: "-9px",
              zIndex: "999",
              top: "-8px",
            }}
            type="file"
            accept=".gpx, .kml"
            onChange={handleFileChange}
          />
          <FileOpenIcon />
        </>
      </IconButtonBadge>
      <Tooltip title="Табличный вид">
        <IconButton
          onClick={toggleTableMode}
        >
          <TableChartIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Фильтры">
        <IconButton
          onClick={toogleFilters}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

RoutesToolbarWidget.propTypes = {
  toogleFilters: PropTypes.func.isRequired,
  createVehicleRoute: PropTypes.func.isRequired,
  postVehicleRouteFile: PropTypes.func.isRequired,
  toggleTableMode: PropTypes.func.isRequired,
};

export default RoutesToolbarWidget;
