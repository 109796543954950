import React, { useLayoutEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

// Отображает страницу c qr-кодами пассажиров
function CompanyPassengersQrListView() {
  const [sizeQR, setSizeQR] = React.useState(null);
  const blockRef = useRef(null);

  const cargos = JSON.parse(localStorage.getItem("cargos"));
  const qrOptions = JSON.parse(localStorage.getItem("qrOptions"));

  useLayoutEffect(() => {
    if (blockRef.current) {
      const element = blockRef.current;
      const style = window.getComputedStyle(element);
      const width = element.offsetWidth
        - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight);
      setSizeQR(width);
    }
  }, [blockRef.current]);
  return (
    <Box
      sx={{
        margin: '5mm',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {cargos?.map((cargo) => (
          <Box
            key={cargo.uid}
            ref={blockRef}
            sx={{
              width: `${qrOptions?.sizeQR || 5}cm`,
              boxSizing: 'content-box',
              padding: '5mm',
              borderRight: '1px dotted gray',
              borderBottom: '1px dotted gray'
            }}
          >
            {sizeQR && (
            <QRCodeSVG
              value={`${cargo.token}`}
              size={sizeQR}
            />
            )}
            <Box>
              {qrOptions?.isName && (
                <Typography variant="subtitle2" sx={{ fontSize: '14px', wordWrap: 'break-word' }} w>
                  {cargo.title}
                </Typography>
              )}
              {qrOptions?.isTags && (
                <Typography variant="body2" sx={{ fontSize: '14px', wordWrap: 'break-word' }}>
                  {cargo.tags}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default CompanyPassengersQrListView;
