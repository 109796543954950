import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteButton from '../../../shared/ui/DeleteButton/DeleteButton';

// Кнопки для управления точкой
function PointButtons({ setPoints, point, onClickComment }) {
  // Перемстить точку выше
  const moveUp = (id) => {
    setPoints((prev) => {
      const newPoints = [...prev];
      const i = newPoints.findIndex((p) => p.id === id);
      if (i > 0) {
        const curPoint = newPoints[i];
        newPoints[i] = newPoints[i - 1];
        newPoints[i - 1] = curPoint;
        return newPoints;
      }
      return prev;
    });
  };

  // Перемстить точку ниже
  const moveDowm = (id) => {
    setPoints((prev) => {
      const newPoints = [...prev];
      const i = newPoints.findIndex((p) => p.id === id);
      if (i !== newPoints.length - 1) {
        const curPoint = newPoints[i];
        newPoints[i] = newPoints[i + 1];
        newPoints[i + 1] = curPoint;
        return newPoints;
      }
      return prev;
    });
  };

  // Удаление точки
  const deletePoint = (id) => {
    setPoints((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <Tooltip title="Комментарий">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onClickComment(point.id);
            }}
          >
            <EditNoteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Наверх">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              moveUp(point.id);
            }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Вниз">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              moveDowm(point.id);
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <DeleteButton
        onDelete={() => deletePoint(point.id)}
      />
    </Box>
  );
}

PointButtons.propTypes = {
  point: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  setPoints: PropTypes.func.isRequired,
  onClickComment: PropTypes.func.isRequired,
};

export default PointButtons;
