// eslint-disable-next-line no-unused-vars
import {
  Children, cloneElement, useCallback, useEffect, useState,
} from "react";
import PropTypes from "prop-types";
import {
  useDeleteTariffPlanResourceMutation,
  useLazyGetTariffPlansQuery,
} from "../redux/tariffPlansResource.api";

const transformFiltersToReqParams = (curFilters) => {
  let bodyFilters = {};
  Object.keys(curFilters).forEach((key) => {
    if (typeof curFilters[key] === 'string') {
      bodyFilters = {
        ...bodyFilters,
        [`${key}.contains`]: curFilters[key].trim()
      };
    } else {
      switch (key) {
        case 'startDate':
          bodyFilters = {
            ...bodyFilters,
            [`startDate.greaterThanOrEqual`]: curFilters[key].toISOString()
          };
          break;
        case 'startDateUntil':
          bodyFilters = {
            ...bodyFilters,
            [`startDate.lessThanOrEqual`]: curFilters[key].toISOString()
          };
          break;
        case 'finishDate':
          bodyFilters = {
            ...bodyFilters,
            [`finishDate.greaterThanOrEqual`]: curFilters[key].toISOString()
          };
          break;
        case 'finishDateUntil':
          bodyFilters = {
            ...bodyFilters,
            [`finishDate.lessThanOrEqual`]: curFilters[key].toISOString()
          };
          break;

        default:
          break;
      }
    }
  });
  return bodyFilters;
};

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function TariffPlansResourceBll({ children }) {
  // Текущая страница
  const [page, setPage] = useState(0);
  // Общее кол-во тарифов
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState();

  // Смена страницы
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleFilters = (curFilters) => {
    setFilters(transformFiltersToReqParams(curFilters));
  };

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [getGetTariffPlans, tariffPlansResource] = useLazyGetTariffPlansQuery();
  const [deleteTariffPlanResource,
    { status: deleteStatus }] = useDeleteTariffPlanResourceMutation();

  const refresh = useCallback(() => {
    getGetTariffPlans({
      params: {
        ...filters,
        page,
        size: rowsPerPage,
      }
    });
  }, [filters, page, rowsPerPage, getGetTariffPlans]);

  useEffect(() => {
    getGetTariffPlans({
      params: {
        ...filters,
        page,
        size: rowsPerPage,
      }
    });
  }, [filters, page, rowsPerPage, getGetTariffPlans]);

  useEffect(() => {
    setTotalCount(tariffPlansResource.data?.totalCount
      ? Number(tariffPlansResource.data?.totalCount)
      : 0);
  }, [tariffPlansResource]);

  const props = {
    tariffPlansResource,
    handleChangePage,
    page,
    handleChangeRowsPerPage,
    handleFilters,
    totalCount,
    rowsPerPage,
    deleteTariffPlan: deleteTariffPlanResource,
    deleteStatus,
    refresh
  };

  return Children.toArray(children).map((child) => cloneElement(child, props));
}

TariffPlansResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TariffPlansResourceBll;
