// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { vehicleEnum } from "../redux/vehicle.slice";

// тут описываем тип то что приходит с бека в сыром виде
const vehicleDtoType = {
  [vehicleEnum.vehicleId]: PropTypes.number,
  [vehicleEnum.name]: PropTypes.string,
  [vehicleEnum.num]: PropTypes.string,
  [vehicleEnum.token]: PropTypes.string,
  [vehicleEnum.yearCreated]: PropTypes.number,
  [vehicleEnum.division]: {},
  [vehicleEnum.company]: {},
  [vehicleEnum.model]: {}
};

export default vehicleDtoType;
