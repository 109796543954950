import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const sensorsResourceEnum = {
  sensorId: "sensorId",
  serialNumber: "serialNumber",
  token: "token",
  data: "data",
  type: "type",
  vehicle: "vehicle",
  source: "source"
};
// Инициализация стейта в сторе
const adaptersResourceInitialState = {
  [sensorsResourceEnum.sensorId]: NaN,
  [sensorsResourceEnum.serialNumber]: null,
  [sensorsResourceEnum.token]: null,
  [sensorsResourceEnum.data]: null,
  [sensorsResourceEnum.type]: {},
  [sensorsResourceEnum.vehicle]: {},
  [sensorsResourceEnum.source]: {}
};
// Типизация стора
export const sensorsResourceType = {
  [sensorsResourceEnum.sensorId]: PropTypes.number,
  [sensorsResourceEnum.serialNumber]: PropTypes.string,
  [sensorsResourceEnum.token]: PropTypes.string,
  [sensorsResourceEnum.data]: PropTypes.string,
  [sensorsResourceEnum.type]: PropTypes.any,
  [sensorsResourceEnum.vehicle]: PropTypes.any,
  [sensorsResourceEnum.source]: PropTypes.any,
};

// Создание слайса тут пишем инициализированный стейт и редюсеры
export const sensorsResourceSlice = createSlice({
  name: `${reducerPath.sensorResource}/counter`,
  initialState: adaptersResourceInitialState,
  reducers: {}
});

export const sensorsResourceReducer = sensorsResourceSlice.reducer;
