import { statuses, statusesRu } from "./alertsResource-enums";

const switchSeverity = (str) => {
  switch (str) {
    case statuses.ERROR:
      return statusesRu.ERROR;
    case statuses.CRITICAL:
      return statusesRu.CRITICAL;
    case statuses.INFO:
      return statusesRu.INFO;
    case statuses.WARNING:
      return statusesRu.WARNING;
    default:
      return statusesRu.INFO;
  }
};

function transformAlert(alert) {
  const newaAert = {
    ...alert,
    isError: alert.severity === statuses.ERROR
      || alert.severity === statuses.CRITICAL,
    severity: switchSeverity(alert.severity),
  };
  return newaAert;
}

export default transformAlert;
