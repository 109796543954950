import {
  useContext, useEffect, useRef
} from "react";
import OLVectorLayer from "ol/layer/Vector";
import {
  Stroke, Style, Fill, Text, Icon
} from "ol/style";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import CircleStyle from "ol/style/Circle";
import VectorSource from 'ol/source/Vector';
import * as olInteraction from 'ol/interaction';
import PropTypes from 'prop-types';
import GeoMapContext from "../../lib/geo-map/GeoMapContext";

// Задание стилей для маркера
const markerStyle = (props = {}) => {
  const {
    color, radius, widthStrokeCircle, selected, src
  } = props;
  if (selected) {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src,
      }),
      text: new Text({
        font: 'bold 13px roboto',
        offsetY: -11,
        textBaseline: 'bottom',
        fill: new Fill({
          color: '#fff'
        }),
      })
    });
  }
  if (src) {
    return new Style({
      image: new Icon({
        src,
      }),
      text: new Text({
        font: 'bold 13px roboto',
        overflow: true,
        offsetY: 1,
        fill: new Fill({
          color: '#fff'
        }),
      })
    });
  }
  return new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: color || '#1876d2',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: widthStrokeCircle || 1,
      }),
      radius: radius || 14,
    }),

    text: new Text({
      font: 'bold 13px roboto',
      overflow: true,
      offsetY: 1,
      fill: new Fill({
        color: '#fff'
      }),
    })
  });
};

const getCurrentVectorLayer = (map, id) => {
  const layers = map?.getLayers()?.getArray();
  const currentVector = layers?.find((layer) => layer instanceof OLVectorLayer && layer.get('id') === id);
  return currentVector;
};

// Отображает маркер на карте
function MarkerVectorLayerV2(props) {
  const {
    zIndex,
    initialCoords,
    color,
    id,
    text,
    radius,
    widthStrokeCircle,
    tooltipText,
    isChangeable,
    isStartDraw,
    onStopDraw,
    onStopModify,
    selected,
    src,
  } = props;
  // текущая карта
  const { map } = useContext(GeoMapContext);

  const modify = useRef(null);
  const draw = useRef(null);

  useEffect(() => {
    if (!map) return () => {};
    let currentVector = getCurrentVectorLayer(map, id);

    if (!currentVector) {
      const source = new VectorSource();

      currentVector = new OLVectorLayer({
        source,
        id
      });
      map.addLayer(currentVector);
    }

    let feature = currentVector?.getSource()?.getFeatureById(id);

    if (initialCoords?.length) {
      if (!feature) {
        feature = new Feature(new Point(initialCoords));
        feature?.setId(id);
        feature?.set('tooltipText', tooltipText);
        currentVector?.getSource()?.addFeature(feature);
      }
    } else if (feature) {
      currentVector?.getSource()?.removeFeature(feature);
    }

    currentVector.setStyle(() => {
      const style = markerStyle({
        color, radius, widthStrokeCircle, src, selected
      });
      style.getText().setText(text);
      return style;
    });
    currentVector.setZIndex(zIndex);

    if (isStartDraw) {
      draw.current = new olInteraction.Draw({
        source: currentVector?.getSource(),
        type: 'Point',
      });
      map.addInteraction(draw.current);

      draw.current.on('drawend', (e) => {
        const { feature: drawFeature } = e;
        const newCoords = drawFeature?.getGeometry()?.getCoordinates();
        drawFeature?.set('tooltipText', tooltipText);
        drawFeature?.setId(id);
        onStopDraw(id, newCoords);
        map.once('rendercomplete', () => {
          map.removeInteraction(draw.current);
        });
      });
    }

    if (isChangeable) {
      modify.current = new olInteraction.Modify({
        source: currentVector?.getSource(), hitDetection: true
      });
      map.addInteraction(modify.current);

      modify.current.on('modifyend', (e) => {
        const newCoords = e?.features?.getArray()?.[0]?.getGeometry()?.getCoordinates();
        onStopModify(id, newCoords);
      });
    }

    return () => {
      map.removeInteraction(modify.current);
      map.removeInteraction(draw.current);
      map.removeLayer(currentVector);
    };
  }, [
    map,
    initialCoords,
    zIndex,
    color,
    id,
    text,
    radius,
    widthStrokeCircle,
    tooltipText,
    isChangeable,
    isStartDraw,
    onStopDraw,
    onStopModify,
    selected,
    src,
  ]);

  return null;
}

MarkerVectorLayerV2.propTypes = {
  zIndex: PropTypes.number,
  initialCoords: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  color: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  text: PropTypes.string,
  radius: PropTypes.number,
  widthStrokeCircle: PropTypes.number,
  tooltipText: PropTypes.string,
  isChangeable: PropTypes.bool,
  selected: PropTypes.bool,
  src: PropTypes.string,
  isStartDraw: PropTypes.bool,
  onStopDraw: PropTypes.func,
  onStopModify: PropTypes.func,
};

MarkerVectorLayerV2.defaultProps = {
  zIndex: 4,
  initialCoords: [],
  id: crypto.randomUUID(),
  color: '',
  text: '',
  src: null,
  radius: 11,
  widthStrokeCircle: 1,
  tooltipText: '',
  isChangeable: false,
  isStartDraw: false,
  selected: false,
  onStopDraw: () => {},
  onStopModify: () => {},
};

export default MarkerVectorLayerV2;
