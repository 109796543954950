// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import alertsResourceFromDtoService from "../services/data/alertsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
import { subscribe, unsubscribe } from '../../../../shared/api/socket';
import transformAlert from '../services/data/transformAlert';
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const alertsResourceApi = createApi({
  reducerPath: `${reducerPath.alertsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/alerts`),
  endpoints: (builder) => ({
    getAlertsResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      async onCacheEntryAdded(queryParams, cache) {
        const { cacheDataLoaded, cacheEntryRemoved, updateCachedData } = cache;
        const topic = `/topic/alerts/vehicle/${queryParams.params['vehicleId.equals']}`;
        await cacheDataLoaded;
        const subscriber = await subscribe(
          topic,
          (message) => {
            const data = transformAlert(message);
            updateCachedData((draft) => {
              draft.data.unshift(data);
            });
          }
        );
        await cacheEntryRemoved;
        unsubscribe(subscriber, topic);
      },
      providesTags: ['TAG'],
      transformResponse: alertsResourceFromDtoService
    }),
  }),
});

export const {
  useGetAlertsResourceQuery,
} = alertsResourceApi;
