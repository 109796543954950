import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-cycle
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
export const tariffPlansResourceEnum = {
};

// Инициализация стейта в сторе
const tariffPlansResourceInitialState = {
};

// Типизация стора
export const tariffPlansResourceType = {
};

// Создание слайса тут пишем инициализированный стейт и редюсеры
export const tariffPlansResourceSlice = createSlice({
  name: `${reducerPath.tariffPlansResource}/counter`,
  initialState: tariffPlansResourceInitialState,
  reducers: {}
});

export const tariffPlansResourceReducer = tariffPlansResourceSlice.reducer;
