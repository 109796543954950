import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Config, Connect, ConnectEvents
} from '@vkontakte/superappkit';
import config from '../../../shared/api/config';

function objectToURL(obj) {
  const baseUrl = `https://${window.location.host}/vk`;
  const payload = encodeURIComponent(JSON.stringify(obj));
  return `${baseUrl}?payload=${payload}`;
}

function VkAuth() {
  Config.init({
    appId: Number(config.REACT_APP_VK_APP_ID), // Идентификатор приложения
  });

  const divRef = useRef(null);

  const oneTapButton = Connect.buttonOneTapAuth({
    // Обязательный параметр в который нужно добавить обработчик событий приходящих из SDK
    callback(e) {
      const { type } = e;

      if (!type) {
        return false;
      }

      // eslint-disable-next-line default-case
      switch (type) {
        case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS: // = 'VKSDKOneTapAuthLoginSuccess'
          // eslint-disable-next-line no-restricted-globals,no-undef
          location.assign(objectToURL(e.payload));
          return false;
        case ConnectEvents.OneTapAuthEventsSDK.FULL_AUTH_NEEDED:
          break;
        case ConnectEvents.OneTapAuthEventsSDK.PHONE_VALIDATION_NEEDED:
          break;
        case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN:
          // eslint-disable-next-line no-console
          console.log("SHOW_LOGIN");
          // eslint-disable-next-line no-undef
          return Connect.redirectAuth({ url: `https://${window.location.host}/vk` });
        case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN_OPTIONS:
          // eslint-disable-next-line no-console
          console.log("SHOW_LOGIN_OPTIONS");
          // eslint-disable-next-line no-undef
          return Connect.redirectAuth({ url: `https://${window.location.host}/vk` });
      }

      return false;
    },
    // Не обязательный параметр с настройками отображения OneTap
    options: {
      showAlternativeLogin: false, // Отображение кнопки "Войти другим способом"
      displayMode: 'default', // Режим отображения кнопки 'default' | 'name_phone' | 'phone_name'
    },
  });

  useEffect(() => {
    if (divRef.current) {
      divRef.current.appendChild(oneTapButton.getFrame());
    }
  }, []);

  return (
    <span>
      <div ref={divRef} />
    </span>
  );
}

export default VkAuth;
