import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line boundaries/element-types
// eslint-disable-next-line boundaries/element-types
import {
  useLazyGetTrackPointsResourceQuery
} from "../trackPointsResource/redux/trackPointsResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function VehicleMapPanelBll({
  children, vehicleId
}) {
  const [data, setData] = useState(null);
  const [
    fetchTrackPoints,
    { data: dataTrackPointsResource }
  ] = useLazyGetTrackPointsResourceQuery();

  const getTrackPointsResourceParams = useCallback(
    ({ params }) => fetchTrackPoints({
      params: {
        "vehicleId.equals": vehicleId,
        ...params
      }
    }),
    [vehicleId]
  );

  useEffect(() => {
    const newData = {};
    newData.dataTrackPointsResource = {
      ...dataTrackPointsResource,
      data: dataTrackPointsResource?.data?.length
        ? JSON.parse(JSON.stringify(dataTrackPointsResource?.data)).reverse()
        : []
    };
    setData(newData);
  }, [
    dataTrackPointsResource,
  ]);

  const props = {
    data,
    getTrackPointsResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

VehicleMapPanelBll.propTypes = {
  children: PropTypes.element.isRequired,
  vehicleId: PropTypes.number,
};

VehicleMapPanelBll.defaultProps = {
  vehicleId: NaN,
};

export default VehicleMapPanelBll;
