import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const adaptersResourceEnum = {
  adapterId: "adapterId",
  serialNumber: "serialNumber",
  token: "token",
  data: "data",
  type: "type",
  vehicle: "vehicle"
};
// Инициализация стейта в сторе
export const adaptersResourceInitialState = {
  [adaptersResourceEnum.adapterId]: NaN,
  [adaptersResourceEnum.serialNumber]: null,
  [adaptersResourceEnum.token]: null,
  [adaptersResourceEnum.data]: null,
  [adaptersResourceEnum.type]: {},
  [adaptersResourceEnum.vehicle]: {}
};
// Типизация стора
export const adaptersResourceType = {
  [adaptersResourceEnum.adapterId]: PropTypes.number,
  [adaptersResourceEnum.serialNumber]: PropTypes.string,
  [adaptersResourceEnum.token]: PropTypes.string,
  [adaptersResourceEnum.data]: PropTypes.string,
  [adaptersResourceEnum.type]: PropTypes.any,
  [adaptersResourceEnum.vehicle]: PropTypes.any
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const adaptersResourceSlice = createSlice({
  name: `${reducerPath.adaptersResource}/counter`,
  initialState: adaptersResourceInitialState,
  reducers: {}
});

export const adaptersResourceReducer = adaptersResourceSlice.reducer;
