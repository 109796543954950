import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";
import { usePostChangePasswordMutation } from "../redux/accountResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AccountResourceBll({ children }) {
  const [postChangePassword] = usePostChangePasswordMutation();

  const postChangePasswordParams = useCallback(
    ({ id, body }) => postChangePassword({
      id,
      body
    }),
    []
  );

  const props = {
    postChangePasswordParams,
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Children
        .toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AccountResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AccountResourceBll;
