// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import appUserSettingsResourceFromDtoService from "../services/data/appUserSettingsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const appUserSettingsResourceApi = createApi({
  reducerPath: `${reducerPath.appUsersSettingsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/app-user-settings`),
  endpoints: (builder) => ({
    getAppUserSettingsResourceId: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appUserSettingsResourceFromDtoService
    }),
    getAppUserSettingsResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appUserSettingsResourceFromDtoService
    }),
    patchAppUserSettingsResource: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    postAppUserSettingsResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
  }),
});

export const {
  useGetAppUserSettingsResourceIdQuery,
  usePatchAppUserSettingsResourceMutation,
  useGetAppUserSettingsResourceQuery,
  usePostAppUserSettingsResourceMutation
} = appUserSettingsResourceApi;
