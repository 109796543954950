import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import TabPanel from '../TabPanel/TabPanel';
import NoData from '../NoData/NoData';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabsPanels({ tabs }) {
  const [value, setValue] = React.useState(0);
  const [newTabs, setNewTabs] = React.useState(tabs);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tabs.length && tabs.length - 1 < value) {
      setValue(tabs.length - 1);
    }
  }, [tabs, value]);

  useEffect(() => {
    setNewTabs(tabs);
  }, [tabs]);

  return newTabs.length
    ? (
      <Box sx={{ flex: 1, pt: '20px', overflowX: 'auto' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {newTabs.map((tab, index) => (
              <Tab key={tab.name} label={tab.name} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {newTabs.map((tab, index) => (
          <TabPanel key={tab.name} value={value} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    )
    : <NoData />;
}

TabsPanels.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]))).isRequired,
};

export default TabsPanels;
