import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";

export const AUTH_FIELDS = {
  TOKEN_ACCESS: "token"
};

export const initialAuthState = {
  [AUTH_FIELDS.TOKEN_ACCESS]: null,
};

export const clearStorage = () => {
  window.sessionStorage.clear();
  window.localStorage.removeItem('token');
  window.sessionStorage.removeItem('token');
  window.localStorage.removeItem('isAuthVk');
  window.sessionStorage.removeItem('isAuthVk');
};

export const authSlice = createSlice({
  name: `${reducerPath.tokenResource}/counter`,
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
