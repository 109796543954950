const PREDEFINED_INTERVALS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  DAY: 'DAY',
  WEEK: 'WEEK',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM: 'CUSTOM',
};

export default PREDEFINED_INTERVALS;
