import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
export const usersResourceEnum = {
  id: "id",
  login: "login",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  imageUrl: "imageUrl",
  activated: "activated",
  langKey: "langKey",
  createdBy: "createdBy",
  createdDate: "createdDate",
  lastModifiedBy: "lastModifiedBy",
  lastModifiedDate: "lastModifiedDate",
  authorities: 'authorities'
};

export const usersResourceUSEREnum = {
  id: "id",
  login: "login",
};

// Инициализация стейта в сторе
const usersResourceInitialState = {
  [usersResourceEnum.id]: NaN,
  [usersResourceEnum.login]: null,
  [usersResourceEnum.firstName]: null,
  [usersResourceEnum.lastName]: null,
  [usersResourceEnum.email]: null,
  [usersResourceEnum.imageUrl]: null,
  [usersResourceEnum.activated]: null,
  [usersResourceEnum.langKey]: null,
  [usersResourceEnum.createdBy]: null,
  [usersResourceEnum.createdDate]: null,
  [usersResourceEnum.lastModifiedBy]: null,
  [usersResourceEnum.lastModifiedDate]: null,
  [usersResourceEnum.authorities]: null,
};
// Типизация стора
export const usersResourceType = {
  [usersResourceEnum.id]: PropTypes.number,
  [usersResourceEnum.login]: PropTypes.string,
  [usersResourceEnum.firstName]: PropTypes.string,
  [usersResourceEnum.lastName]: PropTypes.string,
  [usersResourceEnum.email]: PropTypes.string,
  [usersResourceEnum.imageUrl]: PropTypes.string,
  [usersResourceEnum.activated]: PropTypes.string,
  [usersResourceEnum.langKey]: PropTypes.string,
  [usersResourceEnum.createdBy]: PropTypes.string,
  [usersResourceEnum.createdDate]: PropTypes.string,
  [usersResourceEnum.lastModifiedBy]: PropTypes.string,
  [usersResourceEnum.lastModifiedDate]: PropTypes.string,
  [usersResourceEnum.authorities]: PropTypes.arrayOf(PropTypes.string),
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const usersResourceSlice = createSlice({
  name: `${reducerPath.usersResource}/counter`,
  initialState: usersResourceInitialState,
  reducers: {}
});

export const usersResourceReducer = usersResourceSlice.reducer;
