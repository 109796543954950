/* eslint-disable react/prop-types */
import React, {
  useEffect, useMemo, useState
} from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import style from './ReportCardWidget.module.css';
import { useGetReportQuery } from '../../../../entities/reports/reportsResource/redux/reportsResource.api';
import { useLazyGetDivisionsResourceQuery } from '../../../../entities/divisions/divisionsResource/redux/divisionsResource.api';
import Loading from '../../../../shared/ui/Loading/Loading';
import DateTimePicker from '../../../../shared/ui/DateTimePicker/DateTimePicker';
import Error from '../../../../shared/ui/Error/Error';
import Autocomplete from '../../../../shared/ui/Autocomplete/Autocomplete';
import isValidDate from '../../../../shared/utils/isValidDate';
import useShowError from '../../../../shared/lib/hooks/useShowError';
import getErrorMessage from '../../../../shared/api/getErrorMessage';
import getErrorCode from '../../../../shared/api/getErrorCode';
import { useLazyGetAppUserResourceQuery } from '../../../../entities/account/appUsersResource/redux/appUsersResource.api';
import { postReportGenerate } from '../../../../shared/api/api';
import PREDEFINED_INTERVALS from '../../../../shared/constants/predefinedIntervals';

const REPORT_PARAMS = {
  TEXT: 'TEXT',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  NUMERIC: 'NUMERIC',
  VEHICLE: 'VEHICLE',
  USER: 'USER',
  DIVISION: 'DIVISION',
  INTERVAL: 'INTERVAL',
  DATE_INTERVAL: 'DATE_INTERVAL',
};

const EMPTY_NAME = "Без названия";
const EMPTY_ERROR = "Поле не может быть пустым";

function DivisionsAutocomplete(props) {
  const {
    label,
    value,
    id,
    onChange,
    companyId,
    required,
  } = props;

  const [getDivisions, divisions] = useLazyGetDivisionsResourceQuery();

  useEffect(() => {
    getDivisions({
      params: {
        'companyId.equals': companyId
      }
    });
  }, [getDivisions, companyId]);

  const divisionsData = useMemo(() => divisions?.data?.data?.map((division, i) => ({
    id: i,
    divisionId: division.divisionId,
    name: division.name,
  })) || [], [divisions?.data]);

  return (
    <Autocomplete
      id={id}
      label={label}
      value={value}
      values={divisionsData}
      loading={divisions?.isLoading}
      onChange={onChange}
      required={required}
    />
  );
}

function AppUsersAutocomplete(props) {
  const {
    label,
    value,
    id,
    onChange,
    companyId,
  } = props;

  const [getUsers, users] = useLazyGetAppUserResourceQuery();

  useEffect(() => {
    getUsers({
      params: {
        'companyId.equals': companyId
      }
    });
  }, [getUsers, companyId]);

  const values = useMemo(() => users?.data?.data?.map((item, i) => ({
    id: i,
    divisionId: item.appUserId,
    name: `${item.firstName || ""} ${item.lastName || ""}`.trim() || "Имя не указано",
  })) || [], [users?.data]);

  return (
    <Autocomplete
      id={id}
      label={label}
      value={value}
      values={values}
      loading={users?.isLoading}
      onChange={onChange}
    />
  );
}

function IntervalComponent(props) {
  const {
    label,
    value: [valueFrom, valueTo],
    required,
    id,
    onChange,
    dateOnly,
  } = props;

  const [selectedInterval, setSelectedInterval] = useState(valueFrom && valueTo
    ? null : PREDEFINED_INTERVALS.TODAY);
  const [from, setFrom] = useState(valueFrom);
  const [to, setTo] = useState(valueTo);
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);

  useEffect(() => {
    if (isValidDate(from) && isValidDate(to) && !fromError && !toError) {
      onChange(id, `${from.toISOString()}-${to.toISOString()}`);
    } else {
      onChange(id, '');
    }
  }, [from, to, fromError, toError]);

  useEffect(() => {
    let fromDate;
    let toDate;
    switch (selectedInterval) {
      case PREDEFINED_INTERVALS.TODAY: {
        fromDate = new Date();
        fromDate.setHours(0, 0, 0, 0);
        toDate = new Date();
        if (dateOnly) {
          toDate.setHours(0, 0, 0, 0);
        }
        break;
      }
      case PREDEFINED_INTERVALS.YESTERDAY: {
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate.setHours(0, 0, 0, 0);
        toDate = new Date();
        if (dateOnly) {
          toDate.setDate(toDate.getDate() - 1);
        }
        toDate.setHours(0, 0, 0, 0);
        break;
      }
      case PREDEFINED_INTERVALS.DAY: {
        fromDate = new Date();
        fromDate.setHours(fromDate.getHours() - 24);
        toDate = new Date();
        if (dateOnly) {
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
        }
        break;
      }
      case PREDEFINED_INTERVALS.WEEK: {
        fromDate = new Date();
        fromDate.setHours(fromDate.getHours() - 24 * (dateOnly ? 6 : 7));
        toDate = new Date();
        if (dateOnly) {
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);
        }
        break;
      }
      case PREDEFINED_INTERVALS.CURRENT_MONTH: {
        toDate = new Date();
        fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1, 0, 0, 0, 0);
        if (dateOnly) {
          toDate.setHours(0, 0, 0, 0);
        }
        break;
      }
      case PREDEFINED_INTERVALS.LAST_MONTH: {
        const today = new Date();
        fromDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0, 0);
        toDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
        if (dateOnly) {
          toDate.setDate(toDate.getDate() - 1);
        }
        break;
      }

      default:
        break;
    }
    if (fromDate && toDate) {
      setFrom(new Date(fromDate));
      setTo(new Date(toDate));
      setFromError(false);
      setToError(false);
    }
  }, [selectedInterval]);

  const collectFromDate = (newDate) => {
    if (isValidDate(newDate)) {
      setFromError(false);
      if (newDate.getTime() !== from.getTime()) {
        setSelectedInterval(PREDEFINED_INTERVALS.CUSTOM);
        setFrom(newDate);
      }
    } else {
      setFromError(true);
    }
  };

  const collectToDate = (newDate) => {
    if (isValidDate(newDate)) {
      setToError(false);
      if (newDate.getTime() !== to.getTime()) {
        setSelectedInterval(PREDEFINED_INTERVALS.CUSTOM);
        setTo(newDate);
      }
    } else {
      setToError(true);
    }
  };

  return (
    <>
      <FormControl sx={{ minWidth: 300, marginTop: "20px", marginBottom: "10px" }}>
        <InputLabel size="small" id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedInterval}
          label={label}
          onChange={(e) => {
            setSelectedInterval(e.target.value);
          }}
          size="small"
        >
          <MenuItem value={PREDEFINED_INTERVALS.TODAY}>Сегодня</MenuItem>
          <MenuItem value={PREDEFINED_INTERVALS.YESTERDAY}>Вчера</MenuItem>
          { dateOnly ? null : <MenuItem value={PREDEFINED_INTERVALS.DAY}>Сутки</MenuItem> }
          <MenuItem value={PREDEFINED_INTERVALS.WEEK}>Неделя</MenuItem>
          <MenuItem value={PREDEFINED_INTERVALS.CURRENT_MONTH}>Текущий месяц</MenuItem>
          <MenuItem value={PREDEFINED_INTERVALS.LAST_MONTH}>Прошедший месяц</MenuItem>
          <MenuItem value={PREDEFINED_INTERVALS.CUSTOM}>Выбранный период</MenuItem>
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom: "10px",
          flexWrap: "wrap",
          flexDirection: "column",
          maxWidth: "300px",
          alignItems: "flex-end"
        }}
        fullWidth
      >
        <DateTimePicker
          label="C"
          value={from}
          maxDateTime={to}
          maxDate={to}
          name={id}
          onChange={collectFromDate}
          required={required}
          error={fromError}
          dateOnly={dateOnly}
        />
        <DateTimePicker
          label="По"
          value={to}
          minDateTime={from}
          minDate={from}
          name={id}
          onChange={collectToDate}
          required={required}
          error={toError}
          dateOnly={dateOnly}
        />
      </Box>
    </>
  );
}

const renderControl = (control, datas, handlers) => {
  const {
    type,
    label,
    value,
    id,
    isError,
    error,
    isRequired,
  } = control;
  const { companyId } = datas;
  const {
    handleChangeText,
    handleChangeDate,
    handleChangeAutocomplete,
    handleChangeRange,
  } = handlers;
  switch (type) {
    case REPORT_PARAMS.TEXT:
      return (
        <TextField
          id={id}
          label={label}
          value={value}
          required={Boolean(isRequired)}
          onChange={handleChangeText}
          variant="standard"
          fullWidth
          error={isError}
          helperText={isError ? error || EMPTY_ERROR : null}
        />
      );
    case REPORT_PARAMS.DATETIME:
      return (
        <DateTimePicker
          label={label}
          value={value}
          name={id}
          onChange={handleChangeDate}
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.DATE:
      return (
        <DateTimePicker
          label={label}
          value={value}
          name={id}
          onChange={handleChangeDate}
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.INTERVAL:
      return (
        <IntervalComponent
          id={id}
          label={label}
          value={value}
          onChange={handleChangeRange}
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.DATE_INTERVAL:
      return (
        <IntervalComponent
          id={id}
          label={label}
          value={value}
          onChange={handleChangeRange}
          required={Boolean(isRequired)}
          dateOnly
        />
      );
    case REPORT_PARAMS.NUMERIC:
      return (
        <TextField
          id={id}
          type="number"
          label={label}
          value={value}
          onChange={handleChangeText}
          variant="standard"
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.VEHICLE:
      return (
        <Autocomplete
          id={id}
          label={label}
          value={value}
          onChange={handleChangeAutocomplete}
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.USER:
      return (
        <AppUsersAutocomplete
          id={id}
          label={label}
          value={value}
          onChange={handleChangeAutocomplete}
          companyId={companyId}
          required={Boolean(isRequired)}
        />
      );
    case REPORT_PARAMS.DIVISION:
      return (
        <DivisionsAutocomplete
          id={id}
          label={label}
          value={value}
          onChange={handleChangeAutocomplete}
          companyId={companyId}
          required={Boolean(isRequired)}
        />
      );
    default:
      return null;
  }
};

const fileType = ['PDF', 'XLSX', 'DOCX', 'HTML'].map((label) => ({
  value: label,
  label,
}));

const createControlForParam = (param) => {
  const {
    type,
    name = EMPTY_NAME,
    code,
    defaultValue,
    isRequired,
  } = param;
  const nowDate = new Date();
  const id = crypto.randomUUID();
  const isoRegexp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)-(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
  switch (type) {
    case REPORT_PARAMS.TEXT:
      return {
        ...param,
        id,
        label: name,
        value: (typeof defaultValue === 'string' && defaultValue.trim()) || "",
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.NUMERIC:
      return {
        ...param,
        id,
        label: name,
        value: (typeof defaultValue === 'number' && defaultValue) || "",
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.DATETIME:
      return {
        ...param,
        id,
        label: name,
        value: (typeof defaultValue === 'string' && isValidDate(defaultValue) && new Date(defaultValue)) || nowDate,
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.DATE:
      return {
        ...param,
        id,
        label: name,
        value: (typeof defaultValue === 'string' && isValidDate(defaultValue) && new Date(defaultValue)) || nowDate,
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.INTERVAL:
    case REPORT_PARAMS.DATE_INTERVAL:
      return {
        ...param,
        id,
        label: name,
        value: (typeof defaultValue === 'string' && isoRegexp.test(defaultValue) && defaultValue.length === 49)
          ? [new Date(defaultValue.slice(0, 24)), new Date(defaultValue.slice(-24))]
          : [],
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.USER:
      return {
        ...param,
        id,
        label: name,
        value: null,
        type,
        isError: false,
        code,
        isRequired,
      };
    case REPORT_PARAMS.DIVISION:
      return {
        ...param,
        id,
        label: name,
        value: null,
        type,
        isError: false,
        code,
        isRequired,
      };
    default:
      return null;
  }
};

const initControlsFields = (listParams) => {
  const newControls = [];
  listParams?.forEach((param) => {
    const newControl = createControlForParam(param);
    if (newControl) {
      newControls.push(newControl);
    }
  });
  return newControls.sort((a, b) => a.orderNum - b.orderNum);
};

const toDtoValue = (value) => {
  switch (value.type) {
    case REPORT_PARAMS.TEXT:
      return value.value;
    case REPORT_PARAMS.NUMERIC:
      return Number(value.value);
    case REPORT_PARAMS.DATETIME:
      return isValidDate(value.value) ? value.value.toISOString() : null;
    case REPORT_PARAMS.DATE:
      return isValidDate(value.value) ? value.value.toISOString() : null;
    case REPORT_PARAMS.INTERVAL:
    case REPORT_PARAMS.DATE_INTERVAL:
      return value.value;
    case REPORT_PARAMS.USER:
      return value.value?.appUserId;
    case REPORT_PARAMS.DIVISION:
      return value.value?.divisionId;
    default:
      return null;
  }
};

const isValidField = (item) => {
  switch (item.type) {
    case REPORT_PARAMS.TEXT:
    case REPORT_PARAMS.NUMERIC:
      return item.isRequired ? !!item.value?.trim() : true;
    case REPORT_PARAMS.DATETIME:
    case REPORT_PARAMS.DATE:
      return item.isRequired ? !!isValidDate(item.value) : true;
    case REPORT_PARAMS.INTERVAL:
    case REPORT_PARAMS.DATE_INTERVAL:
      return item.isRequired ? !!item.value?.trim() : true;
    case REPORT_PARAMS.DIVISION:
    case REPORT_PARAMS.USER:
    case REPORT_PARAMS.VEHICLE:
      return item.isRequired ? !!item.value : true;
    default:
      return false;
  }
};

function ReportCardWidget({ companyId }) {
  const [searchParams] = useSearchParams();
  const reportId = parseInt(searchParams.get('report'), 10);

  const report = useGetReportQuery({ id: reportId });

  const [selectedType, setSelectedType] = React.useState(fileType[0].value);
  const [controls, setControls] = useState(initControlsFields(report?.data?.reportParams));

  useEffect(() => {
    setControls(initControlsFields(report?.data?.reportParams));
  }, [report?.data]);

  // eslint-disable-next-line no-unused-vars
  const [isChangeForm, setIsChangeForm] = React.useState(false);
  const [isLoadingFile, setLoadingFile] = React.useState(false);

  const navigate = useNavigate();

  const handleChangeType = (event) => {
    setIsChangeForm(true);
    setSelectedType(event.target.value);
  };

  const handleChangeValueControl = (id, value) => {
    setIsChangeForm(true);
    setControls((prev) => {
      const newControls = [...prev];
      const index = newControls.findIndex((control) => control.id === id);
      newControls[index] = {
        ...newControls[index],
        value,
      };
      return newControls;
    });
  };

  const handleChangeText = (event) => {
    handleChangeValueControl(event.target.id, event.target.value);
  };

  const handleChangeDate = (date, name) => {
    handleChangeValueControl(name, date);
  };

  const handleChangeAutocomplete = (id, value) => {
    handleChangeValueControl(id, value);
  };

  const handleChangeRange = (id, value) => {
    handleChangeValueControl(id, value);
  };

  const isValidForm = () => {
    let isValid = true;
    const newControls = [...controls];
    controls.forEach((item, index) => {
      const isError = !isValidField(item);
      if (isError) {
        isValid = false;
      }
      newControls[index] = {
        ...newControls[index],
        isError,
      };
    });

    setControls(newControls);
    return isValid;
  };

  const showError = useShowError();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValidForm()) {
      return;
    }
    const params = controls.reduce((prev, item) => ({
      ...prev,
      [item.code]: toDtoValue(item),
    }), {});
    const body = {
      docType: selectedType,
      reportId,
      params,
      reportTemplate: {
        reportTemplateId: report?.data?.reportTemplates?.[0]?.reportTemplateId,
      }
    };
    setLoadingFile(true);
    postReportGenerate(body)
      .then((response) => {
        const octetStreamMime = 'application/octet-stream';
        const cd = response.headers["content-disposition"];
        const regex = /filename[^;=\n]*\*=UTF-8''((['"]).*?\2|[^;\n]*)/;
        const match = regex.exec(cd);
        let fileName = match[1];
        fileName = decodeURIComponent(fileName).replace(/"/g, "");
        const contentType = response.headers["content-type"] || octetStreamMime;

        try {
          const blob = new Blob([response.data], { type: contentType });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          }
        } catch (exc) {
        // eslint-disable-next-line no-console
          console.error(exc);
          showError('При сохранении файла отчетов произошла ошибка.');
        }
      })
      .catch(() => showError("Не удалось построить отчет."))
      .finally(() => {
        setIsChangeForm(false);
        setLoadingFile(false);
      });
  };

  if (report?.isLoading) {
    return <Loading />;
  }

  if (report?.isError) {
    const errorMessage = report?.error?.data?.errorMessage
      || getErrorMessage(getErrorCode(report?.error?.data?.status));
    return (
      <>
        <Error errorMessage={errorMessage} />
        <Box className={style.buttons}>
          <Button variant="contained" onClick={() => navigate(-1)}>Назад</Button>
        </Box>
      </>
    );
  }

  return (
    <form className={style.card} onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ color: 'text.secondary' }} gutterBottom>
        {report?.data?.name || report?.data?.reportId || EMPTY_NAME}
      </Typography>
      <Box className={style.body}>
        {controls?.map((control) => (
          <Box key={control.id}>
            {renderControl(
              control,
              { companyId },
              {
                handleChangeText, handleChangeDate, handleChangeAutocomplete, handleChangeRange
              },
            )}
          </Box>
        ))}
        {!report?.data?.reportParams?.length && (
          <Error errorMessage="Пустой шаблон" />
        )}
        <Box className={style.horizontalBox}>
          <FormControl variant="standard" sx={{ minWidth: 140 }}>
            <InputLabel id="fileType-label">Тип документа</InputLabel>
            <Select
              labelId="fileType-label"
              id="fileType"
              value={selectedType}
              onChange={handleChangeType}
              label="Тип документа"
            >
              {fileType.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={style.buttons}>
          <Button variant="contained" onClick={() => navigate(-1)} disabled={isLoadingFile}>Назад</Button>
          <Button variant="contained" type="submit" disabled={isLoadingFile}>
            {isLoadingFile ? "Загрузка..." : "Построить отчет" }
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default ReportCardWidget;
