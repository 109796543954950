import React, {
  useEffect,
  useState
} from 'react';
import {
  Alert, AlertTitle, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import style from './CompanyBalanceCreatePanel.module.css';
import { getCompanies } from "../../../shared/api/api";
import {
  CompanyAccountResourceType
} from "../../../entities/account/companyAccountResource/redux/companyAccountResource.slice";

export function checkStringLength(str, min, max) {
  return str.length >= min && str.length <= max;
}

export const types = [
  { id: 1, value: "Юридическое лицо", type: "AT_COMPANY" },
  { id: 2, value: "ИП", type: "AT_IP" },
  { id: 3, value: "Физическое лицо", type: "AT_PERSON" }
];

// Отображает панель создания Баланс компаний
function CompanyBalanceCreatePanel({ dataCompanyAccount, fetchPostCompanyAccountResourceParams }) {
  const [isErr, setIsErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [errors, setErrors] = useState({});
  // тип
  const [type, setType] = useState(null);
  // название контрагента
  const [nameCounterparty, setNameCounterparty] = useState();
  // адрес контрагента
  const [counterpartyAddress, setCounterpartyAddress] = useState();
  // инн контрагента
  const [counterpartyINN, setCounterpartyINN] = useState();
  // кпп контрагента
  const [counterpartyKPP, setCounterpartyKPP] = useState();
  // огрн контрагента
  const [counterpartyOGRN, setCounterpartyOGRN] = useState();
  // ОКТМО контрагента
  const [counterpartyOKTMO, setCounterpartyOKTMO] = useState();
  // телефон контрагента
  const [counterpartyPhone, setCounterpartyPhone] = useState();
  // имя ответственного лица контрагента
  const [counterpartyNameMain, setCounterpartyNameMain] = useState();
  // рассчетный счет контрагента
  const [counterpartyBill, setCounterpartyBill] = useState();
  // наименование банка контрагента
  const [counterpartyBankName, setCounterpartyBankName] = useState();
  // БИК контрагента
  const [counterpartyBik, setCounterpartyBik] = useState();
  // корр.счет контрагента
  const [counterpartyBankNumber, setCounterpartyBankNumber] = useState();
  // Компания
  const [company, setCompany] = useState();
  // компания с бека
  const [companiesApi, setCompaniesApi] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (dataCompanyAccount?.data) {
      getCompanies().then((companies) => {
        setCompaniesApi(companies
          .filter((e) => !dataCompanyAccount.data
            .find((item) => item.company.companyId === e.companyId)));
      });
    }
  }, [dataCompanyAccount]);

  const handleSaveChanges = () => {
    const data = {
      balance: 0,
      type: types.find((e) => e.id === type).type,
      agentName: String(nameCounterparty),
      address: String(counterpartyAddress),
      inn: String(counterpartyINN),
      kpp: String(counterpartyKPP),
      ogrn: String(counterpartyOGRN),
      oktmo: String(counterpartyOKTMO),
      phone: String(counterpartyPhone),
      respName: String(counterpartyNameMain),
      rs: String(counterpartyBill),
      bankName: String(counterpartyBankName),
      bik: String(counterpartyBik),
      kors: String(counterpartyBankNumber),
      company,
    };
    if (fetchPostCompanyAccountResourceParams) {
      fetchPostCompanyAccountResourceParams(data).then((e) => {
        if (e?.error) {
          setIsErr(true);
        } else {
          setIsCorrect(true);
        }
      });
    }
  };

  useEffect(() => {
    if (nameCounterparty) {
      if (!checkStringLength(nameCounterparty, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          nameCounterparty: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          nameCounterparty: false
        }));
      }
    }

    if (counterpartyAddress) {
      if (!checkStringLength(counterpartyAddress, 1, 512)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyAddress: "Ошибка, максимальная длина поля – 512 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyAddress: false
        }));
      }
    }

    if (counterpartyINN) {
      if (!checkStringLength(counterpartyINN, type === 2 ? 12 : 10, type === 2 ? 12 : 10)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyINN: `${
            type === 2
              ? "ИНН индивидуального предпринимателья представляет собой последовательность из 12 арабских цифр"
              : "ИНН юридического лица представляет собой последовательность из 10 арабских цифр"}`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyINN: false
        }));
      }
    }

    if (counterpartyKPP) {
      if (!checkStringLength(counterpartyKPP, 9, 9)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyKPP: "КПП представляет собой код из 9 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyKPP: false
        }));
      }
    }

    if (counterpartyOGRN) {
      if (!checkStringLength(counterpartyOGRN, type === 2 ? 15 : 13, type === 2 ? 15 : 13)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyOGRN: `${type === 2 ? "ОГРНИП представляет собой код из 15 арабских цифр"
            : "ОГРН представляет собой код из 13 арабских цифр"}`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyOGRN: false
        }));
      }
    }

    if (counterpartyOKTMO) {
      if (!checkStringLength(counterpartyOKTMO, 8, 8)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyOKTMO: `ОКТМО представляет собой код из 8 арабских цифр`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyOKTMO: false
        }));
      }
    }

    if (counterpartyPhone) {
      if (!checkStringLength(counterpartyPhone, 1, 16)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyPhone: "Ошибка, максимальная длина поля – 16 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyPhone: false
        }));
      }
    }

    if (counterpartyNameMain) {
      if (!checkStringLength(counterpartyNameMain, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyNameMain: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyNameMain: false
        }));
      }
    }

    if (counterpartyBill) {
      if (!checkStringLength(counterpartyBill, 20, 20)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBill: "Номер расчетного счета состоит из 20 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBill: false
        }));
      }
    }

    if (counterpartyBankName) {
      if (!checkStringLength(counterpartyBankName, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankName: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankName: false
        }));
      }
    }

    if (counterpartyBik) {
      if (!checkStringLength(counterpartyBik, 9, 9)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBik: "БИК состоит из 9 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBik: false
        }));
      }
    }

    if (counterpartyBankNumber) {
      if (!checkStringLength(counterpartyBankNumber, 20, 20)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankNumber: "Корр.счет состоит из 20 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankNumber: false
        }));
      }
    }
  }, [nameCounterparty, counterpartyAddress, counterpartyINN,
    counterpartyKPP, counterpartyOGRN, counterpartyOKTMO, counterpartyPhone,
    counterpartyNameMain, counterpartyBill, counterpartyBankName, counterpartyBik,
    counterpartyBankNumber, type]);

  const isError = (errors.nameCounterparty
    || errors.counterpartyAddress
    || errors.counterpartyINN
    || errors.counterpartyKPP
    || errors.counterpartyOGRN
    || errors.counterpartyOKTMO
    || errors.counterpartyPhone
    || errors.counterpartyNameMain
    || errors.counterpartyBill
    || errors.counterpartyBankName
    || errors.counterpartyBik
    || errors.counterpartyBankNumber
  || !nameCounterparty
  || !counterpartyAddress
  || (type !== 3 ? !counterpartyINN : false)
  || (type === 1 ? !counterpartyKPP : false)
  || (type !== 3 ? !counterpartyOGRN : false)
  || !counterpartyOKTMO
  || !counterpartyPhone
  || !counterpartyNameMain
  || !counterpartyBill
  || !counterpartyBankName
  || !counterpartyBik
  || !counterpartyBankNumber
  || !company
  );

  useEffect(() => {
    setIsCorrect(false);
    setIsErr(false);
  }, [nameCounterparty,
    counterpartyAddress,
    counterpartyINN,
    counterpartyKPP,
    counterpartyOGRN,
    counterpartyOKTMO,
    counterpartyPhone,
    counterpartyNameMain,
    counterpartyBill,
    counterpartyBankName,
    counterpartyBik,
    counterpartyBankNumber]);

  useEffect(() => {
    setCounterpartyBankNumber(null);
    setCounterpartyBik(null);
    setCounterpartyBankName(null);
    setCounterpartyBill(null);
    setCounterpartyNameMain(null);
    setCounterpartyPhone(null);
    setCounterpartyOKTMO(null);
    setCounterpartyOGRN(null);
    setCounterpartyKPP(null);
    setCounterpartyINN(null);
    setCounterpartyAddress(null);
    setNameCounterparty(null);
    setCompany(null);
  }, [type]);
  return (
    <Box className={style.UsersInfoPanel}>

      <Box sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        width: "100%",
        gap: "30px"
      }}
      >
        <FormControl fullWidth>
          <InputLabel id="category-label">Тип</InputLabel>
          <Select
            labelId="category-label"
            id="category-label"
            value={type || 0}
            label="Тип"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            {types.map((typeCurrent, i) => (
              <MenuItem
                key={`typeCurrent-select-item${typeCurrent.id}-${i + 1}`}
                value={typeCurrent.id}
              >
                {typeCurrent.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {type !== null && (
      <Box sx={{
        display: "flex",
        width: "100%",
        gap: "50px",
        flexWrap: "wrap",
      }}
      >
        {/* Название + Адреса */}
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          width: "100%",
          gap: "30px"
        }}
        >
          <TextField
            value={nameCounterparty || ''}
            type="text"
            onChange={(e) => {
              setNameCounterparty(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="ФИО контрагента"
            variant="standard"
            error={Boolean(errors.nameCounterparty)}
            helperText={errors.nameCounterparty}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

          <TextField
            value={counterpartyAddress || ''}
            type="text"
            onChange={(e) => {
              setCounterpartyAddress(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Адрес контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyAddress)}
            helperText={errors.counterpartyAddress}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

          <TextField
            value={counterpartyPhone || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyPhone(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Телефон контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyPhone)}
            helperText={errors.counterpartyPhone}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

          <TextField
            value={counterpartyNameMain || ''}
            type="text"
            onChange={(e) => {
              setCounterpartyNameMain(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Имя ответственного лица контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyNameMain)}
            helperText={errors.counterpartyNameMain}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

        </Box>
        {/* Реквизиты ЮЛ/ИП/ФЛ */}
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          width: "100%",
          gap: "30px"
        }}
        >
          <Autocomplete
            style={{ width: "100%" }}
            disablePortal
            id="company"
            options={companiesApi}
            value={company}
            onChange={(_, newValue) => {
              setCompany(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Компания"
              />
            )}
          />

          {type !== 3
                  && (
                  <TextField
                    value={counterpartyINN || ''}
                    type="number"
                    onChange={(e) => {
                      setCounterpartyINN(e.target.value);
                    }}
                    sx={{ width: "100%" }}
                    label="ИНН контрагента"
                    variant="standard"
                    error={Boolean(errors.counterpartyINN)}
                    helperText={errors.counterpartyINN}
                    onBlur={(event) => {
                      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                    }}
                  />
                  )}

          {type === 1 && (
          <TextField
            value={counterpartyKPP || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyKPP(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="КПП контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyKPP)}
            helperText={errors.counterpartyKPP}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />
          )}
          {type !== 3
                  && (
                  <TextField
                    value={counterpartyOGRN || ''}
                    type="number"
                    onChange={(e) => {
                      setCounterpartyOGRN(e.target.value);
                    }}
                    sx={{ width: "100%" }}
                    label={`${type === 1 ? "ОГРН" : "ОГРНИП"} контрагента`}
                    variant="standard"
                    error={Boolean(errors.counterpartyOGRN)}
                    helperText={errors.counterpartyOGRN}
                    onBlur={(event) => {
                      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                    }}
                  />
                  )}

          <TextField
            value={counterpartyOKTMO || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyOKTMO(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="ОКТМО контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyOKTMO)}
            helperText={errors.counterpartyOKTMO}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

        </Box>
        {/* Банковские реквизиты (начинаем с названия банка) */}
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          width: "100%",
          gap: "30px"
        }}
        >

          <TextField
            value={counterpartyBill || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyBill(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Рассчетный счет контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyBill)}
            helperText={errors.counterpartyBill}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />
          <TextField
            value={counterpartyBankName || ''}
            type="text"
            onChange={(e) => {
              setCounterpartyBankName(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Наименование банка контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyBankName)}
            helperText={errors.counterpartyBankName}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

          <TextField
            value={counterpartyBik || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyBik(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="БИК контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyBik)}
            helperText={errors.counterpartyBik}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

          <TextField
            value={counterpartyBankNumber || ''}
            type="number"
            onChange={(e) => {
              setCounterpartyBankNumber(e.target.value);
            }}
            sx={{ width: "100%" }}
            label="Корр.счет контрагента"
            variant="standard"
            error={Boolean(errors.counterpartyBankNumber)}
            helperText={errors.counterpartyBankNumber}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />

        </Box>

      </Box>
      )}

      {/* <CompanyFinancePanel */}
      {/*    company={selectedNodeData?.companyId ? selectedNodeData : null} */}
      {/* /> */}

      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Аккаунт успешно создан
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}
      <Box className={style.buttonsPanel}>
        <Button onClick={handleSaveChanges} disabled={isError} variant="contained">Создать</Button>
        <Button variant="contained" onClick={() => navigate(`/finances/company-balance`)}>
          {!isErr && isCorrect ? 'Назад' : 'Отмена'}
        </Button>
      </Box>
    </Box>
  );
}

CompanyBalanceCreatePanel.propTypes = {
  dataCompanyAccount: CompanyAccountResourceType,
  fetchPostCompanyAccountResourceParams: PropTypes.func
};

CompanyBalanceCreatePanel.defaultProps = {
  dataCompanyAccount: null,
  fetchPostCompanyAccountResourceParams: null
};

export default CompanyBalanceCreatePanel;
