import React, { useCallback, useMemo, useState } from 'react';
import {
  Box, Button, FormControl, IconButton, Input, InputAdornment,
  InputLabel, TextField, FormHelperText, Alert, AlertTitle,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import DateTimePicker from '../../../shared/ui/DateTimePicker/DateTimePicker';
import style from './PassengersFormCreateWidget.module.css';
import Loading from '../../../shared/ui/Loading/Loading';
import isValidDate from '../../../shared/utils/isValidDate';
import { usePostCompanyCargoMutation } from '../../../entities/companies/companyCargos/redux/companyCargos.api';
import apiErrors from '../../../shared/api/apiErrors';
import CARGO_TYPE from '../../../shared/constants/cargoTypes';
import { checkNumber, convertFloatNumber } from '../PassengersFormWidget/PassengersFormWidget';
import Tags from '../../../shared/ui/Tags/Tags';

const initCompanyCargo = (isPassenger) => {
  const nowDate = new Date();
  const initialCompanyCargo = {
    title: '',
    tags: '',
    comment: '',
    startDeliveryTime: nowDate,
    finishDeliveryTime: null,
    cargoLogin: '',
    cargoPassword: '',
    cargoPasswordConfirm: '',
    cargoMail: '',
    observerLogin: '',
    observerPassword: '',
    observerPasswordConfirm: '',
    observerMail: '',
    ...(!isPassenger ? {} : {
      width: '',
      height: '',
      length: '',
      loadingAddress: '',
      deliveryAddress: '',
      weight: '',
    })
  };
  return initialCompanyCargo;
};

const EMPTY_ERROR = "Поле не может быть пустым";
const WRONG_NUMBER_VALUE = "Поле должно содержать число";
const WRONG_INTEGER_NUMBER_VALUE = "Поле должно содержать целое число";

const errorsCompanyCargo = {
  title: false,
  startDeliveryTime: false,
  cargoLogin: false,
  observerLogin: false,
  width: false,
  height: false,
  length: false,
  weight: false,
};

function isValidEmail(email) {
  const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return regex.test(email);
}

// Отображает форму добавления пассажира/груза
function PassengersFormCreateWidget(props) {
  const { companyId, updateCompanyCargos, cargoType } = props;

  const isPassenger = useMemo(() => cargoType === 'CARGO_TYPE_PASSENGER', [cargoType]);

  const location = useLocation();
  const searchParams = location.state?.from?.search || '';

  const [companyCargoForm, setCompanyCargoForm] = useState(initCompanyCargo(isPassenger));
  const [errorsForm, setErrorsForm] = useState(errorsCompanyCargo);
  const [hasChangesForm, setHasChangesForm] = useState(false);

  const navigate = useNavigate();
  const onClickBack = useCallback(() => {
    navigate(`/companies/${companyId}/${isPassenger ? 'passengers' : 'cargo'}/main${searchParams}`);
  }, [navigate, companyId, searchParams]);

  const [postCompanyCargo, saveResult] = usePostCompanyCargoMutation();

  const notRequiredFields = [
    'width',
    'height',
    'length',
    'weight',
  ];

  const isValidForm = () => {
    const newErrorsForm = {};
    const requiredFields = Object.keys(errorsCompanyCargo)
      .filter((f) => !notRequiredFields.includes(f));
    requiredFields.forEach((key) => {
      const item = companyCargoForm[key];
      const cargoField = typeof item === 'string' ? item.trim() : item;
      newErrorsForm[key] = errorsForm[key] || !cargoField;
    });
    if (
      newErrorsForm.title
      || newErrorsForm.startDeliveryTime
      || !isValidDate(companyCargoForm.startDeliveryTime)
      || (companyCargoForm.finishDeliveryTime && !isValidDate(companyCargoForm.finishDeliveryTime))
      || newErrorsForm.cargoLogin
      || newErrorsForm.observerLogin
      || errorsForm.cargoPasswordConfirm
      || errorsForm.observerPasswordConfirm
      || errorsForm.cargoMail
      || errorsForm.observerMail
      || errorsForm.width
      || errorsForm.height
      || errorsForm.length
      || errorsForm.weight
      || errorsForm.loadingAddress
      || errorsForm.deliveryAddress
    ) {
      setErrorsForm((prev) => ({ ...prev, ...newErrorsForm }));
      return false;
    }
    return true;
  };

  const onClickSave = () => {
    if (isValidForm()) {
      const body = {
        cargoType,
        title: companyCargoForm.title.trim(),
        tags: companyCargoForm.tags.trim(),
        comment: companyCargoForm.comment,
        startDeliveryTime: companyCargoForm.startDeliveryTime.toISOString(),
        finishDeliveryTime: companyCargoForm.finishDeliveryTime
          ? companyCargoForm.finishDeliveryTime.toISOString()
          : '2099-01-01T00:00:00Z',
        cargoLogin: companyCargoForm.cargoLogin.trim(),
        observerLogin: companyCargoForm.observerLogin.trim(),
        cargoMail: companyCargoForm.cargoMail.trim(),
        observerMail: companyCargoForm.observerMail.trim(),
        cargoPassword: companyCargoForm.cargoPassword.trim(),
        observerPassword: companyCargoForm.observerPassword.trim(),
        isRegular: false,
        lastStatus: 'CARGO_STATUS_WAITING',
        company: {
          companyId
        },
        width: convertFloatNumber(companyCargoForm.width),
        height: convertFloatNumber(companyCargoForm.height),
        length: convertFloatNumber(companyCargoForm.length),
        weight: companyCargoForm.weight ? convertFloatNumber(companyCargoForm.weight) * 1000 : null,
        loadingAddress: companyCargoForm.loadingAddress?.trim(),
        deliveryAddress: companyCargoForm.deliveryAddress?.trim(),
      };
      postCompanyCargo({ body })
        .then(() => updateCompanyCargos())
        .finally(() => setHasChangesForm(false));
    }
  };

  const handleChangeText = (event, checkEmpty = true) => {
    setHasChangesForm(true);
    if (errorsForm[event.target.id]) {
      setErrorsForm((prev) => ({ ...prev, [event.target.id]: checkEmpty && event.target.value.trim() === "" }));
    }
    setCompanyCargoForm((prev) => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  const handleChangeTags = (value) => {
    handleChangeText({ target: { id: 'tags', value } });
  };

  const handleChangeDate = (date, name) => {
    setHasChangesForm(true);
    setCompanyCargoForm((prev) => ({
      ...prev,
      [name]: date
    }));
    setErrorsForm((prev) => ({
      ...prev,
      [name]: date === null
    }));
  };

  const [showPassword, setShowPassword] = React.useState({
    cargoPassword: false,
    cargoPasswordConfirm: false,
    observerPassword: false,
    observerPasswordConfirm: false,
  });

  const handleClickShowPassword = (name) => setShowPassword((prev) => ({
    ...prev,
    [name]: !prev[name],
  }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkEmail = (event) => {
    setErrorsForm((prev) => (
      {
        ...prev,
        [event.target.id]:
          event.target.value.trim() !== "" && !isValidEmail(event.target.value)
      }
    ));
  };

  return (
    <Box className={style.widget}>
      {!companyCargoForm
        ? <Loading />
        : (
          <Box className={style.form}>
            <Typography variant="h6" gutterBottom component="div" sx={{ color: 'text.secondary' }}>
              Добавление
              {' '}
              {isPassenger ? 'пассажира' : 'груза'}
            </Typography>
            <Box className={style.formHorizontal}>
              <Box className={style.form}>
                <TextField
                  label={isPassenger ? 'ФИО' : 'Название'}
                  variant="standard"
                  required
                  id="title"
                  value={companyCargoForm.title}
                  onChange={handleChangeText}
                  sx={{ width: '500px' }}
                  error={errorsForm.title}
                  helperText={errorsForm.title ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <Box className={style.dateTime}>
                  <DateTimePicker
                    label="Начало перевозки"
                    name="startDeliveryTime"
                    required
                    value={companyCargoForm.startDeliveryTime}
                    onChange={handleChangeDate}
                    error={errorsForm.startDeliveryTime}
                    helperText={errorsForm.startDeliveryTime ? EMPTY_ERROR : null}
                    onBlur={(event) => {
                      setErrorsForm((prev) => ({
                        ...prev,
                        [event.target.id]: event.target.value === null
                      }));
                    }}
                    width={240}
                  />
                  <DateTimePicker
                    label="Окончание перевозки"
                    name="finishDeliveryTime"
                    value={companyCargoForm.finishDeliveryTime}
                    onChange={handleChangeDate}
                    onBlur={(event) => {
                      setErrorsForm((prev) => ({
                        ...prev,
                        [event.target.id]: event.target.value === null
                      }));
                    }}
                    width={240}
                  />
                </Box>
                { !isPassenger
                  && (
                  <>
                    <Box className={style.dateTime}>
                      <TextField
                        label="Ширина, см"
                        variant="standard"
                        required={false}
                        id="width"
                        value={companyCargoForm.width}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.width}
                        helperText={errorsForm.width ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Высота, см"
                        variant="standard"
                        required={false}
                        id="height"
                        value={companyCargoForm.height}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.height}
                        helperText={errorsForm.height ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Длина, см"
                        variant="standard"
                        required={false}
                        id="length"
                        value={companyCargoForm.length}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.length}
                        helperText={errorsForm.length ? WRONG_INTEGER_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value, true)
                          }));
                        }}
                      />
                      <TextField
                        label="Вес, кг"
                        variant="standard"
                        required={false}
                        id="weight"
                        value={companyCargoForm.weight}
                        onChange={(evt) => handleChangeText(evt, false)}
                        sx={{ width: '110px' }}
                        error={errorsForm.weight}
                        helperText={errorsForm.weight ? WRONG_NUMBER_VALUE : null}
                        onBlur={(event) => {
                          setErrorsForm((prev) => ({
                            ...prev,
                            [event.target.id]: checkNumber(event.target.value)
                          }));
                        }}
                      />
                    </Box>
                    <TextField
                      label="Адрес погрузки"
                      variant="standard"
                      required={false}
                      id="loadingAddress"
                      value={companyCargoForm.loadingAddress}
                      onChange={(evt) => handleChangeText(evt, false)}
                      sx={{ width: '500px' }}
                      error={errorsForm.loadingAddress}
                    />
                    <TextField
                      label="Адрес доставки"
                      variant="standard"
                      required={false}
                      id="deliveryAddress"
                      value={companyCargoForm.deliveryAddress}
                      onChange={(evt) => handleChangeText(evt, false)}
                      sx={{ width: '500px' }}
                      error={errorsForm.deliveryAddress}
                    />
                  </>
                  )}
                <Tags data={companyCargoForm.tags} onChange={handleChangeTags} />
                <TextField
                  id="comment"
                  onChange={handleChangeText}
                  fullWidth
                  multiline
                  rows={3}
                  label="Комментарии"
                  variant="standard"
                  sx={{ maxWidth: '500px' }}
                  value={companyCargoForm.comment}
                />
              </Box>
              <Box className={style.form}>
                <TextField
                  id="cargoLogin"
                  onChange={handleChangeText}
                  label="Логин"
                  required
                  variant="standard"
                  value={companyCargoForm.cargoLogin}
                  sx={{ width: '260px' }}
                  error={errorsForm.cargoLogin}
                  helperText={errorsForm.cargoLogin ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <TextField
                  id="cargoMail"
                  onChange={handleChangeText}
                  label="Эл. почта"
                  variant="standard"
                  value={companyCargoForm.cargoMail}
                  sx={{ width: '260px' }}
                  error={errorsForm.cargoMail}
                  helperText={errorsForm.cargoMail ? 'Некорректный e-mail' : null}
                  onBlur={checkEmail}
                />
                <FormControl sx={{ width: '260px' }} variant="standard">
                  <InputLabel htmlFor="cargoPassword">Пароль</InputLabel>
                  <Input
                    id="cargoPassword"
                    type={showPassword.cargoPassword ? 'text' : 'password'}
                    value={companyCargoForm.cargoPassword}
                    onChange={(event) => {
                      handleChangeText(event);
                      setErrorsForm((prev) => ({
                        ...prev,
                        cargoPasswordConfirm:
                          event.target.value !== companyCargoForm.cargoPasswordConfirm,
                      }));
                    }}
                    // error={errorsForm.cargoPassword}
                    // onBlur={(event) => {
                    //   setErrorsForm((prev) => (
                    //     {
                    //       ...prev,
                    //       [event.target.id]: event.target.value.trim() === "",
                    //     }
                    //   ));
                    // }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('cargoPassword')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.cargoPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                  <FormHelperText error>
                    {errorsForm.cargoPassword ? EMPTY_ERROR : null}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ width: '260px' }} variant="standard">
                  <InputLabel htmlFor="cargoPasswordConfirm">Повторите пароль</InputLabel>
                  <Input
                    id="cargoPasswordConfirm"
                    type={showPassword.cargoPasswordConfirm ? 'text' : 'password'}
                    value={companyCargoForm.cargoPasswordConfirm}
                    onChange={(event) => {
                      handleChangeText(event);
                      setErrorsForm((prev) => ({
                        ...prev,
                        cargoPasswordConfirm:
                          event.target.value !== companyCargoForm.cargoPassword,
                      }));
                    }}
                    error={errorsForm.cargoPasswordConfirm}
                    helperText={errorsForm.cargoPasswordConfirm ? `Пароли ${isPassenger ? 'пассажира' : 'груза'} должны совпадать` : null}
                    // onBlur={(event) => {
                    //   setErrorsForm((prev) => ({
                    //     ...prev,
                    //     [event.target.id]:
                    //         event.target.value !== companyCargoForm.cargoPassword
                    //   }));
                    // }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('cargoPasswordConfirm')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.cargoPasswordConfirm
                            ? <Visibility />
                            : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                  <FormHelperText error>
                    {errorsForm.cargoPasswordConfirm ? 'Пароли пассажира должны совпадать' : null}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={style.form}>
                <TextField
                  id="observerLogin"
                  onChange={handleChangeText}
                  label="Логин наблюдателя"
                  variant="standard"
                  required
                  sx={{ width: '260px' }}
                  error={errorsForm.observerLogin}
                  helperText={errorsForm.observerLogin ? EMPTY_ERROR : null}
                  onBlur={(event) => {
                    setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                  value={companyCargoForm.observerLogin}
                />
                <TextField
                  id="observerMail"
                  onChange={handleChangeText}
                  label="Эл. почта наблюдателя"
                  variant="standard"
                  sx={{ width: '260px' }}
                  value={companyCargoForm.observerMail}
                  error={errorsForm.observerMail}
                  helperText={errorsForm.observerMail ? 'Некорректный e-mail' : null}
                  onBlur={checkEmail}
                />
                <FormControl sx={{ width: '260px' }} variant="standard">
                  <InputLabel htmlFor="observerPassword">Пароль наблюдателя</InputLabel>
                  <Input
                    id="observerPassword"
                    type={showPassword.observerPassword ? 'text' : 'password'}
                    value={companyCargoForm.observerPassword}
                    onChange={(event) => {
                      handleChangeText(event);
                      setErrorsForm((prev) => ({
                        ...prev,
                        observerPasswordConfirm:
                          event.target.value !== companyCargoForm.observerPasswordConfirm
                      }));
                    }}
                    // error={errorsForm.observerPassword}
                    // onBlur={(event) => {
                    //   setErrorsForm((prev) => (
                    //     {
                    //       ...prev,
                    //       [event.target.id]: event.target.value.trim() === ""
                    //     }
                    //   ));
                    // }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('observerPassword')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.observerPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  )}
                  />
                  <FormHelperText error>
                    {errorsForm.observerPassword ? EMPTY_ERROR : null}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ width: '260px' }} variant="standard">
                  <InputLabel htmlFor="observerPasswordConfirm">Повторите пароль</InputLabel>
                  <Input
                    id="observerPasswordConfirm"
                    type={showPassword.observerPasswordConfirm ? 'text' : 'password'}
                    value={companyCargoForm.observerPasswordConfirm}
                    onChange={handleChangeText}
                    error={errorsForm.observerPasswordConfirm}
                    helperText={errorsForm.observerPasswordConfirm ? 'Пароли наблюдателя должны совпадать' : null}
                    onBlur={(event) => {
                      setErrorsForm((prev) => ({
                        ...prev,
                        [event.target.id]:
                              event.target.value !== companyCargoForm.observerPassword
                      }));
                    }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('observerPasswordConfirm')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.observerPasswordConfirm
                            ? <Visibility />
                            : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  )}
                  />
                  <FormHelperText error>
                    {errorsForm.observerPasswordConfirm ? 'Пароли наблюдателя должны совпадать' : null}
                  </FormHelperText>
                </FormControl>

              </Box>
            </Box>
          </Box>
        )}
      {!hasChangesForm && saveResult?.status === 'fulfilled'
        ? (
          <Alert severity="success">
            <AlertTitle>Успешно</AlertTitle>
            Данные успешно сохранены.
          </Alert>
        )
        : null}
      {!hasChangesForm && saveResult?.status === 'rejected'
        ? (
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {apiErrors[saveResult.error?.data?.errorKey]?.message || 'Ошибка при сохранении данных.'}
          </Alert>
        )
        : null}
      <Box className={style.footerControls}>
        <Button
          onClick={onClickBack}
          variant="contained"
        >
          Назад
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          disabled={saveResult?.status === 'pending'}
        >
          {saveResult?.status === 'pending' ? 'Сохранение...' : 'Добавить'}
        </Button>
      </Box>
    </Box>
  );
}

PassengersFormCreateWidget.propTypes = {
  companyId: PropTypes.number.isRequired,
  updateCompanyCargos: PropTypes.func.isRequired,
  cargoType: CARGO_TYPE.type,
};

PassengersFormCreateWidget.defaultProps = {
  cargoType: CARGO_TYPE.default,
};

export default PassengersFormCreateWidget;
