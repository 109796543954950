import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import PropTypes from 'prop-types';

// Отображает окно с выбором причины и текстом обращения
function FeedbackDlg(props) {
  const {
    openFeedbackDlg, handleClose, send, reasons
  } = props;
  const [text, setText] = useState('');
  const [selectedReason, setSelectedReason] = useState(reasons[0] || '');
  const clearInputs = () => {
    setText('');
    setSelectedReason(reasons[0]);
  };
  useEffect(() => {
    if (reasons.length) {
      setSelectedReason(reasons[0]);
    }
  }, [reasons]);

  return (
    <Dialog open={openFeedbackDlg} onClose={handleClose} fullWidth>
      <DialogTitle>Обратная связь</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            pt: '20px',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <FormControl variant="standard">
            <InputLabel>Причина обращения</InputLabel>
            <Select
              id="reason"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
              label="Причина обращения"
            >
              {reasons.map((reason) => (
                <MenuItem key={reason.id} value={reason}>{reason.info}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            multiline
            margin="dense"
            value={text}
            onChange={(e) => setText(e.target.value)}
            label="Текст обращения"
            fullWidth
            variant="outlined"
            rows={5}
            inputProps={{ maxLength: 256 }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: '20px' }}>
        <Button onClick={handleClose}>Отмена</Button>
        <Button
          variant="contained"
          onClick={() => {
            send(selectedReason, text, clearInputs);
            handleClose();
          }}
        >
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FeedbackDlg.propTypes = {
  openFeedbackDlg: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  send: PropTypes.func,
  reasons: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

FeedbackDlg.defaultProps = {
  send: null,
  reasons: [],
};
export default FeedbackDlg;
