// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const accountResourceApi = createApi({
  reducerPath: `${reducerPath.accountResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}`),
  endpoints: (builder) => ({
    postResetPassword: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: `/account/reset-password/init`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
    }),
    postChangePassword: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: `/account/change-password`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
    }),
    postChangePasswordInit: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: `/account/reset-password/init`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
    }),
    postAccountResetFinish: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: `/account/reset-password/finish`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
    }),
    getAccountActivate: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({
          url: `/activate`, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
    }),
  }),
});

export const {
  usePostChangePasswordMutation,
  usePostChangePasswordInitMutation,
  usePostResetPasswordMutation,
  usePostAccountResetFinishMutation,
  useLazyGetAccountActivateQuery,
  useGetAccountActivateQuery,
} = accountResourceApi;
