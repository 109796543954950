import { Box, IconButton, Typography } from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { orange, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useConfirm } from "material-ui-confirm";
import {
  getAlerts, getAlertTypes, getVehicles, putAlert,
} from '../../../shared/api/api';
import ControlledTable from '../../../shared/ui/ControlledTable/ControlledTable';
// eslint-disable-next-line boundaries/element-types
import DisableAlertsMenu from '../../../menu/DisableAlertsMenu/DisableAlertsMenu';
import FiltersAlertsWidget from '../../../widgets/alerts/FiltersAlertsWidget';
import EnhancedTableToolbar from '../../../shared/ui/EnhancedTableToolbar/EnhancedTableToolbar';
import NoData from '../../../shared/ui/NoData/NoData';
import { subscribe, unsubscribe } from '../../../shared/api/socket';
import FiltersSettingsWidget from "../../../widgets/settings/FiltersSettingsWidget";
import {
  alertsSettingsResourceType
} from "../../../entities/alerts/alertsSettingsResource/redux/alertsSettingsResource.slice";
import EditAlertsSettingsWidget from "../../../widgets/alerts/EditAlertsSettingsWidget";
import AlertsSettingsEditResourceBll
  from "../../../entities/alerts/AlertsSettingsEditResourceBll/AlertsSettingsEditResourceBll";
import AlertsSettingsCreateResourceBll
  from "../../../entities/alerts/alertsSettingsResource/AlertsSettingsCreateResourceBll/AlertsSettingsCreateResourceBll";
import CreateAlertsSettingsWidget from "../../../widgets/alerts/CreateAlertsSettingsWidget";
import useAuth from "../../../shared/lib/hooks/useAuth";
import { alertsTypesResourceType } from "../../../entities/alerts/alertsTypesResource/redux/alertsTypesResource.slice";

const severities = {
  INFO: 'Информация',
  WARNING: 'Предупреждение',
  ERROR: 'Важно',
  CRITICAL: 'Критично',
};
const severitiesNames = [
  severities.INFO,
  severities.WARNING,
  severities.ERROR,
  severities.CRITICAL,
];

const alertCategories = {
  VEHICLE: 'ТС',
  VEHICLE_ROUTE: 'Маршрут',
};

const rowsColors = {
  ERROR: red[50],
  WARNING: orange[50],
  CRITICAL: red[50],
};

let alertsSubscriber = null;

const displayedCells = [
  'beginTimeStr',
  'severity',
  'сategory',
  'vehicle',
  'description',
];

const headCells = [
  {
    id: 'beginTime',
    numeric: false,
    disablePadding: false,
    label: 'Дата',
  },
  {
    id: 'severity',
    numeric: false,
    disablePadding: false,
    label: 'Серьезность',
  },
  {
    id: 'сategory',
    numeric: false,
    disablePadding: false,
    label: 'Категория',
  },
  {
    id: 'vehicle',
    numeric: false,
    disablePadding: false,
    label: 'ТС',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Текст',
  },
];

const displayedCellsSettings = [
  'severity',
  'repeatDuration',
  'category',
  'code',
  'channel'
];

const headCellsSettings = [
  {
    id: 'severity',
    numeric: false,
    disablePadding: false,
    label: 'Серьезность',
  },
  {
    id: 'repeatDuration',
    numeric: false,
    disablePadding: false,
    label: 'Интервал повторения',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Категория сообщений',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Тип сообщения',
  },
  {
    id: 'channel',
    numeric: false,
    disablePadding: false,
    label: 'Информационный канал',
  },
];

// Панель с таблицей сообщений по компании
function CompanyAlertsPanel(props) {
  const { user } = useAuth();
  const {
    selectedData, data, deleteAlertsSettingsResourceParams, fetchAlertsSettingsResourceParams,
    dataAlertsTypesResource
  } = props;
  const [alerts, setAlerts] = useState(null);
  const [alertsTypes, setAlertsTypes] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isFiltersSettingsOpen, setIsFiltersSettingsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState({
    rows: [],
    filters: {},
  });
  const [rowsSettings, setRowsSettings] = useState([]);
  const [filteredRowsSettings, setFilteredRowsSettings] = useState({
    rows: [],
    filters: {},
  });
  const [isEditId, setIsEditId] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [dataAlertsChannels, setDataAlertsChannels] = useState();

  const toogleFilters = () => {
    setIsFiltersOpen((prev) => !prev);
  };

  const toogleFiltersSettings = () => {
    setIsFiltersSettingsOpen((prev) => !prev);
  };

  const togleSettings = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const togleSettingsCreate = useCallback(() => {
    setIsCreate((prev) => !prev);
  }, []);

  const [isOpenDisableMenu, setIsOpenDisableMenu] = useState(null);

  const closeDisableMenu = () => {
    setIsOpenDisableMenu(null);
  };
  const openDisableMenu = (event) => {
    setIsOpenDisableMenu(event.currentTarget);
  };

  const disableAlert = (time) => {
    const reqs = selectedAlerts.map((alertId) => {
      const alert = alerts.find((item) => item.alertId === alertId);
      const curDate = new Date();
      const suspendTime = curDate.setMinutes(curDate.getMinutes() + time);
      // console.log(curDate.getHours()+":"+curDate.getMinutes())
      const body = {
        ...alert,
        suspendTime: new Date(suspendTime),
      };
      return putAlert(body);
    });
    Promise.all(reqs).then(() => {
      setAlerts((prev) => {
        const newAlerts = [];
        prev.forEach((alert) => {
          if (selectedAlerts.indexOf(alert.alertId) === -1) {
            newAlerts.push(alert);
          }
        });
        return newAlerts;
      });
      setSelectedAlerts([]);
    }).catch((error) => { throw error; });
  };

  useEffect(() => {
    if (selectedData) {
      setSelectedAlerts([]);
      getAlertTypes().then((res) => {
        setAlertsTypes(res);
      }).catch((error) => { throw error; });
      getVehicles({ companyId: selectedData.companyId }).then((res) => {
        setVehicles(res);
      }).catch((error) => { throw error; });
    }
  }, [selectedData]);

  useEffect(() => {
    const topic = `/topic/alerts/company/${selectedData.companyId}`;
    if (selectedData) {
      getAlerts({
        companyId: selectedData.companyId,
        // endTime: {paramBody: 'greaterThan', paramValue: curDate},
        // suspendTime: {paramBody: 'lessThan', paramValue: curDate},
      }).then((res) => {
        setAlerts(res);
        subscribe(topic, (newAlert) => {
          setAlerts((prev) => [
            {
              ...newAlert,
              beginTime: new Date(newAlert.beginTime),
              endTime: newAlert.endTime ? new Date(newAlert.endTime) : null,
              suspendTime: newAlert.suspendTime ? new Date(newAlert.suspendTime) : null,
            },
            ...prev
          ]);
        }).then((subscriber) => {
          alertsSubscriber = subscriber;
        }).catch((error) => { throw error; });
      }).catch((error) => { throw error; });
    }

    return () => {
      unsubscribe(alertsSubscriber, topic);
    };
  }, [selectedData]);

  useEffect(() => {
    if (alerts && alertsTypes && vehicles) {
      const curDate = new Date();
      const newRows = alerts?.slice()
        .filter((alert) => (alert.endTime ? alert.endTime > curDate : true)
          && (alert.suspendTime ? alert.suspendTime < curDate : true))
        .sort((a, b) => {
          const compareSeverity = severitiesNames.indexOf(b.severity)
            - severitiesNames.indexOf(a.severity);
          if (compareSeverity !== 0) return compareSeverity;
          return a.beginTime < b.beginTime ? 1 : -1;
        })
        .map((alert) => {
          const alertType = alertsTypes
            ?.find((item) => item.alertTypeId === alert.alertType.alertTypeId);
          const vehicle = vehicles
            ?.find((item) => item.vehicleId === alert.vehicle.vehicleId);

          return {
            id: alert.alertId,
            beginTime: alert.beginTime,
            severity: severities[alert.severity],
            category: alertCategories[alertType?.category],
            code: alertType?.code,
            vehicle: `${vehicle?.name} ${vehicle?.num}`,
            description: alert.description,
            rowColor: rowsColors[alert.severity] || 'white',
            beginTimeStr: `${alert.beginTime.toLocaleDateString('ru')} ${alert.beginTime.toLocaleTimeString('ru').slice(0, -3)}`,
          };
        });

      setRows(newRows);
      setFilteredRows({ rows: newRows, filters: {} });
    }
  }, [alerts, alertsTypes, vehicles]);

  useEffect(() => {
    setFilteredRowsSettings({
      rows: rowsSettings.map((e) => {
        const newObj = { ...e };
        newObj.severity = e.severity || 'Все';
        newObj.code = e.code || 'Все';
        return newObj;
      }),
      filters: {}
    });
  }, [rowsSettings]);

  useEffect(() => {
    const dataAlertsSettingsResourceCurrent = data?.dataAlertsSettingsResource?.data;
    const dataAlertsChannelsCurrent = data?.dataAlertsChannels?.data;
    if (dataAlertsSettingsResourceCurrent) {
      setRowsSettings(dataAlertsSettingsResourceCurrent.map((e) => ({
        id: e.alertSettingId,
        severity: e.severity?.ruName,
        repeatDuration: e.repeatDuration,
        category: e.category,
        code: e.code?.ruName,
        channel: e.channel.name,
      })));
    }
    if (dataAlertsChannelsCurrent) {
      setDataAlertsChannels(dataAlertsChannelsCurrent);
    }
  }, [data]);

  const goBack = useCallback(() => {
    setIsEditId(null);
    setIsCreate(false);
  }, []);

  const isAdmin = useMemo(() => user?.authorities?.includes("ROLE_ADMIN")
      || user?.authorities?.includes("ROLE_COMPANY_ADMIN"), [user]);
  const confirm = useConfirm();
  return (isEditId || isCreate) ? isEditId ? (
    <AlertsSettingsEditResourceBll companyId={selectedData?.companyId} alertId={isEditId}>
      <EditAlertsSettingsWidget
        goBack={goBack}
      />
    </AlertsSettingsEditResourceBll>
  ) : isCreate && (
  <AlertsSettingsCreateResourceBll companyId={selectedData?.companyId}>
    <CreateAlertsSettingsWidget
      goBack={goBack}
      companyId={selectedData?.companyId}
    />
  </AlertsSettingsCreateResourceBll>
  ) : (
    <div>
      {rows && alertsTypes
        && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Box sx={{
              display: "flex",
              justifyContent: "end",
              gap: "10px",
              alignItems: "center",
            }}
            >
              {isSettingsOpen ? (
                <EnhancedTableToolbar
                  selected={selectedAlerts}
                  openDisableMenu={openDisableMenu}
                  toogleFilters={toogleFiltersSettings}
                  isFiltersOpen={isFiltersSettingsOpen}
                />
              ) : (
                <EnhancedTableToolbar
                  selected={selectedAlerts}
                  openDisableMenu={openDisableMenu}
                  toogleFilters={toogleFilters}
                  isFiltersOpen={isFiltersOpen}
                />
              ) }
              {isAdmin && (
              <>
                <IconButton
                  aria-haspopup="true"
                  onClick={togleSettings}
                  color={isSettingsOpen ? "primary" : "default"}
                >
                  <SettingsIcon />
                </IconButton>
                {isSettingsOpen && (
                <IconButton
                  aria-haspopup="true"
                  onClick={togleSettingsCreate}
                  color="default"
                >
                  <AddCircleIcon />
                </IconButton>
                )}
              </>
              )}
            </Box>
            {isSettingsOpen ? (
              <div>
                <FiltersSettingsWidget
                  isFiltersOpen={isFiltersSettingsOpen}
                  rows={rowsSettings}
                  setFilteredRows={setFilteredRowsSettings}
                  severities={severitiesNames}
                  alertCategories={alertCategories}
                  data={{ dataAlertsChannels }}
                  fetchAlertsSettingsResourceParams={fetchAlertsSettingsResourceParams}
                  selectedData={selectedData?.companyId}
                  dataAlertsTypesResource={dataAlertsTypesResource}
                />
                <Typography variant="h6" gutterBottom component="div" sx={{ color: 'text.secondary' }}>
                  Настройки правил отправки сообщений
                </Typography>
                {filteredRowsSettings.rows.length
                  ? (
                    <ControlledTable
                      rows={filteredRowsSettings.rows}
                      headCells={headCellsSettings}
                      displayedCells={displayedCellsSettings}
                      countRowsPerPage={5}
                      selected={[]}
                      setSelected={() => {}}
                      controls
                      onEdit={(e) => {
                        setIsEditId(e.id);
                      }}
                      onDelete={(e) => {
                        confirm({
                          title: 'Удаление',
                          confirmationText: 'Да',
                          cancellationText: 'Отмена',
                          description: `Вы действительно хотите удалить?`
                        })
                          .then(() => deleteAlertsSettingsResourceParams({ id: e.id }))
                          .catch(() => { });
                      }}
                    />
                  )
                  : <NoData />}
              </div>
            ) : (
              <>
                <FiltersAlertsWidget
                  isFiltersOpen={isFiltersOpen}
                  rows={rows}
                  setFilteredRows={setFilteredRows}
                  severities={severitiesNames}
                  alertCategories={alertCategories}
                />
                {filteredRows.rows.length
                  ? (
                    <ControlledTable
                      rows={filteredRows.rows}
                      headCells={headCells}
                      displayedCells={displayedCells}
                      countRowsPerPage={5}
                      selected={selectedAlerts}
                      setSelected={setSelectedAlerts}
                    />
                  )
                  : <NoData />}
              </>
            )}
          </Box>
        )}
      <DisableAlertsMenu
        isOpenDisableMenu={isOpenDisableMenu}
        closeDisableMenu={closeDisableMenu}
        selectMenuItem={disableAlert}
      />
    </div>
  );
}

CompanyAlertsPanel.propTypes = {
  data: PropTypes.shape({
    dataAlertsSettingsResource: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(alertsSettingsResourceType)),
    }),
    dataAlertsChannels: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(alertsSettingsResourceType)),
    }),
  }),
  selectedData: PropTypes.shape({
    companyId: PropTypes.number,
    nodeType: PropTypes.string,
  }),
  deleteAlertsSettingsResourceParams: PropTypes.func,
  fetchAlertsSettingsResourceParams: PropTypes.func,
  dataAlertsTypesResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(alertsTypesResourceType))
  }),
};

CompanyAlertsPanel.defaultProps = {
  selectedData: null,
  data: null,
  deleteAlertsSettingsResourceParams: null,
  fetchAlertsSettingsResourceParams: null,
  dataAlertsTypesResource: null,
};

export default CompanyAlertsPanel;
