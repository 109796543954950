// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import convertFormatDate from "../../../../../shared/utils/convertFormatDate";
import { convertStatus } from "./platoonRequestsResource-enums";
import platoonGroupsResourceDtoType from "../../types/platoonGroupsResource-dto.type";

export function platoonRequestsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  // eslint-disable-next-line array-callback-return
  const data = dto.map((e, i) => {
    const newData = JSON.parse(JSON.stringify(e));
    const {
      day, month, year, hours, minutes
    } = convertFormatDate(e.modifiedDate);
    newData.modifiedDate = `${day}.${month}.${year} ${hours}:${minutes}`;
    newData.status = convertStatus(e.status);
    newData.id = i;

    return newData;
  });

  return { data, status };
}

platoonRequestsResourceFromDtoService.propTypes = {
  dto: platoonGroupsResourceDtoType,
};

export default platoonRequestsResourceFromDtoService;
