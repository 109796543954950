import React, {
  useMemo, useState, useEffect, useRef
} from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import AuthContext from '../../shared/lib/auth/AuthContext';
// eslint-disable-next-line boundaries/element-types
import {
  tariffPlansResourceApi
} from '../../entities/finances/tariffPlansResource/redux/tariffPlansResource.api';
// eslint-disable-next-line boundaries/element-types
import companyAccountsApi from '../../entities/companies/companyAccounts/redux/companyAccounts.api';
import { useAppDispatch } from "../store";

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }) {
  const dispatch = useAppDispatch();
  const requestBalanceIntervalRef = useRef();

  const [user, setUser] = useState(undefined);
  const [insufficientBalanceToast, setInsufficientBalanceToast] = useState(false);
  const [checkBalanceTrigger, setCheckBalanceTrigger] = useState(true);

  const {
    data: tariffPlans,
    refetch: refetchTariffPlans,
    isUninitialized: isTariffPlansUninitialized,
  } = tariffPlansResourceApi.useGetTariffPlansQuery({}, {
    skip: !user
  });

  const {
    data: companyAccount,
    refetch: refetchCompanyAccount,
    isUninitialized: isCompanyAccountUninitialized,
  } = companyAccountsApi.useGetCompanyAccountsQuery({
    params: user?.company?.companyId ? { 'companyId.equals': user.company.companyId } : undefined
  }, { skip: !user?.company?.companyId });

  const { currentTariffPlan, nextTariffPlan } = useMemo(() => {
    const companyAcc = companyAccount?.[0];
    const currentTarifPlanId = companyAcc?.currentPlan?.tarifPlanId;
    const nextTariffPlanId = companyAcc?.nextPlan?.tarifPlanId;
    const currentTariff = tariffPlans?.data?.find(
      (tariff) => tariff.tarifPlanId === currentTarifPlanId
    ) || null;
    const nextTariff = tariffPlans?.data?.find(
      (tariff) => tariff.tarifPlanId === nextTariffPlanId
    ) || null;
    return { currentTariffPlan: currentTariff, nextTariffPlan: nextTariff };
  }, [companyAccount, tariffPlans]);

  const checkBalance = (trigger = false) => {
    clearTimeout(requestBalanceIntervalRef.current);
    requestBalanceIntervalRef.current = setTimeout(() => {
      // Запрашиваем баланс каждые 15 минут
      dispatch(tariffPlansResourceApi.util.invalidateTags(['companyAccounts']));
      if (trigger) {
        setCheckBalanceTrigger(!checkBalanceTrigger);
      }
    }, 900000);
  };

  useEffect(() => {
    const account = companyAccount?.[0];
    if (account) {
      const { balance, notifyLimit } = account;
      const limit = notifyLimit || 0;
      if (balance < limit) {
        if (!insufficientBalanceToast) {
          toast.error(
            <Stack
              useFlexGap
              direction="row"
              alignItems="center"
              marginRight="12px"
            >
              <Typography variant="span">Исчерпан баланс компании</Typography>
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  toast.dismiss('insufficientBalance');
                  setInsufficientBalanceToast(false);
                  checkBalance(true);
                }}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>,
            { duration: Infinity, id: 'insufficientBalance' }
          );
          setInsufficientBalanceToast(true);
        }
      } else if ('insufficientBalance') {
        toast.dismiss('insufficientBalance');
        setInsufficientBalanceToast(false);
      }
      checkBalance();
    }
    return () => {
      clearTimeout(requestBalanceIntervalRef.current);
    };
  }, [companyAccount?.[0]?.balance, companyAccount?.[0]?.notifyLimit, checkBalanceTrigger]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    if (!isTariffPlansUninitialized) {
      refetchTariffPlans();
    }
    if (!isCompanyAccountUninitialized) {
      refetchCompanyAccount();
    }
    callback();
  };

  const signout = (callback) => {
    setUser(null);
    callback();
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = useMemo(() => ({
    user: user ? { ...user, currentTariffPlan, nextTariffPlan } : undefined,
    companyAccount: companyAccount?.[0],
    isCompanyAdmin: user?.authorities.includes('ROLE_COMPANY_ADMIN'),
    signin,
    signout,
  }), [user, currentTariffPlan, nextTariffPlan, companyAccount]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
