// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import appPaymentResourceFromDtoService from "../services/data/appPaymentResourceFromDtoService-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const paymentResourceApi = createApi({
  reducerPath: `${reducerPath.companyPayments}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/company-payments`),

  endpoints: (builder) => ({
    getPaymentResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appPaymentResourceFromDtoService,
    }),
    getPaymentResourceId: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appPaymentResourceFromDtoService,
    }),
    patchPaymentResource: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    postPaymentResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    deletePaymentResource: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.DELETE
        }),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
  }),
});

export const {
  useDeletePaymentResourceMutation,
  useLazyGetPaymentResourceQuery,
  useGetPaymentResourceQuery,
  useGetPaymentResourceIdQuery,
  usePatchPaymentResourceMutation,
  usePostPaymentResourceMutation
} = paymentResourceApi;
