import {
  Alert, AlertTitle, Box, TablePagination
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useConfirm } from 'material-ui-confirm';
import Loading from '../../../shared/ui/Loading/Loading';
import ControlledTable from '../../../shared/ui/ControlledTable/ControlledTable';
import TariffPlansFiltersWidget from '../../../widgets/finances/TariffPlansFiltersWidget/TariffPlansFiltersWidget';
import TariffPlansToolbarWidget from '../../../widgets/finances/TariffPlansToolbarWidget/TariffPlansToolbarWidget';
import {
  usePutTariffPlanResourceNoProviderMutation
} from "../../../entities/finances/tariffPlansResource/redux/tariffPlansResource.api";

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Название',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Дата начала',
  },
  {
    id: 'finishDate',
    numeric: false,
    disablePadding: false,
    label: 'Дата окончания',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Описание',
  },
  {
    id: 'isPublic',
    numeric: false,
    disablePadding: false,
    label: 'Публичный',
  },
  {
    id: 'checkBox',
    label: 'План по-умолчанию',
  },
];

const displayedCells = [
  'name',
  'startDate',
  'finishDate',
  'description',
  'isPublic',
  'checkBox'
];

// Отображает таблицу со списком тарифов, тулбар с кнопакми и фильтрацией
function TariffPlansPanel(props) {
  const navigate = useNavigate();
  const {
    tariffPlansResource, handleChangePage, page, totalCount, handleFilters,
    handleChangeRowsPerPage, rowsPerPage, deleteTariffPlan, refresh
  } = props;
  const { data: tariffPlans, status } = tariffPlansResource;
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [putTariffPlanResource] = usePutTariffPlanResourceNoProviderMutation();
  const [tariffPlansCurrent, setTariffPlans] = useState(tariffPlans);

  useEffect(() => {
    const tariffPlansData = {
      ...tariffPlans,
      data: tariffPlans?.data?.map((e) => {
        const newObj = { ...e };
        newObj.checkBox = e.isDefault;
        return newObj;
      })
    };
    setTariffPlans(tariffPlansData);
  }, [tariffPlans]);

  const putTariffPlanResourceParams = useCallback(
    ({ id, body }) => putTariffPlanResource({
      id,
      body
    }),
    [putTariffPlanResource]
  );

  const toogleFilters = () => {
    setIsOpenFilters((prev) => !prev);
  };

  const applyFilters = (filters) => {
    let newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        newFilters = {
          ...newFilters,
          [key]: filters[key],
        };
      }
    });
    handleFilters(newFilters);
  };
  const confirm = useConfirm();

  const handleSave = useCallback(({ e, value }) => {
    const newData = {};
    newData.isDefault = value;
    newData.cargoPerDay = e.cargoPerDay;
    newData.cargoPerMonth = e.cargoPerMonth;
    newData.finishDate = new Date(e.finishDate)?.toISOString();
    newData.funcFuelCoef = e.funcFuelCoef;
    newData.funcGroupMvmnt = e.funcGroupMvmnt;
    newData.funcRouteMonitor = e.funcRouteMonitor;
    newData.historyDays = e.historyDays;
    newData.isPublic = e.isPublic === "Да";
    newData.name = e.name;
    newData.payPerDay = e.payPerDay;
    newData.payPerDayActiveVehicle = e.payPerDayActiveVehicle;
    newData.payPerDayVehicle = e.payPerDayVehicle;
    newData.payPerMonth = e.payPerMonth;
    newData.payPerMonthVehicle = e.payPerMonthVehicle;
    newData.privateRouteCount = e.privateRouteCount;
    newData.publicRouteCount = e.publicRouteCount;
    newData.startDate = new Date(e.startDate)?.toISOString();
    newData.tarifPlanId = e.tarifPlanId;
    newData.vehicleActiveCount = e.vehicleActiveCount;
    newData.vehicleCount = e.vehicleCount;

    putTariffPlanResourceParams({
      id: e?.tarifPlanId,
      body: newData
    }).then((el) => {
      if (el?.error) {
        confirm({
          title: 'Ошибка',
          confirmationText: 'Ок',
          description: `Произошла ошибка при редактировании "План по-умолчанию", тарифа: "${e.name}".`,
          hideCancelButton: true,
        });
        if (refresh) {
          refresh();
        }
      }
      setTariffPlans((prev) => ({
        ...prev,
        data: prev?.data?.map((eCurrent) => {
          const newObj = { ...eCurrent };

          if (eCurrent.tarifPlanId === e.tarifPlanId) {
            newObj.checkBox = true;
            newObj.isDefault = true;
          } else {
            newObj.checkBox = false;
            newObj.isDefault = false;
          }

          return newObj;
        })
      }));
    });
  }, [refresh]);

  return (
    <Box>
      <TariffPlansToolbarWidget
        toogleFilters={toogleFilters}
      />
      <TariffPlansFiltersWidget
        isOpenFilters={isOpenFilters}
        applyFilters={applyFilters}
      />
      {status === 'pending' ? <Loading /> : null}
      {status === 'rejected' ? (
        <Alert severity="error">
          <AlertTitle>Ошибка</AlertTitle>
          Ошибка при загрузке данных
        </Alert>
      ) : null}
      {status === 'fulfilled' ? (
        <ControlledTable
          rows={tariffPlansCurrent?.data || []}
          headCells={headCells}
          displayedCells={displayedCells}
          checkBoxTrigger={(e) => {
            handleSave(e);
          }}
          countRowsPerPage={rowsPerPage}
          selected={[]}
          setSelected={() => { }}
          controls
          onEdit={(row) => navigate(`edit/${row.tarifPlanId}`)}
          onDelete={(row) => {
            confirm({
              title: 'Удаление',
              confirmationText: 'Да',
              cancellationText: 'Отмена',
              description: `Вы действительно хотите удалить «${row?.name}»?`
            })
              .then(() => {
                deleteTariffPlan({ id: row.tarifPlanId });
              })
              .catch(() => { });
          }}
        />
      ) : null}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          padding: '20px',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 15, 30]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}

TariffPlansPanel.propTypes = {
  tariffPlansResource: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  handleChangePage: PropTypes.func,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  handleFilters: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  deleteTariffPlan: PropTypes.func,
  refresh: PropTypes.func,
};

TariffPlansPanel.defaultProps = {
  tariffPlansResource: null,
  handleChangePage: null,
  page: null,
  totalCount: 0,
  handleFilters: null,
  handleChangeRowsPerPage: null,
  rowsPerPage: 0,
  deleteTariffPlan: null,
  refresh: null
};

export default TariffPlansPanel;
