// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import alertsChannelsResourceDtoType from "../../types/alertsChannelsResource-dto.type";

export function alertsChannelsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  return { data: dto, status };
}

alertsChannelsResourceFromDtoService.propTypes = {
  dto: alertsChannelsResourceDtoType,
};

export default alertsChannelsResourceFromDtoService;
