/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";
import { logisticTasksApi } from "./logisticTasks.api";

export const LogisticTasksBalanceEnum = {
  data: "data",
  initialData: "initialData",
  solution: "solution",
};

// Инициализация стейта в сторе
const logisticTasksBalanceResourceInitialState = {
  [LogisticTasksBalanceEnum.data]: {},
  [LogisticTasksBalanceEnum.initialData]: {},
  [LogisticTasksBalanceEnum.solution]: {},
};

// Создание слайса тут пишем инициализированный стейт и редюсеры
export const logisticTasksBalanceSlice = createSlice({
  name: `${reducerPath.logisticTasksBalanceResource}/counter`,
  initialState: logisticTasksBalanceResourceInitialState,
  reducers: {
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    addRoute(state, action) {
      const fields = state.solution.fields || {};
      const { fieldId, warehouseId } = action.payload;
      return {
        ...state,
        solution: {
          ...state.solution,
          fields: {
            ...fields,
            [fieldId]: {
              ...fields[fieldId],
              routes: {
                ...fields[fieldId]?.routes,
                [warehouseId]: {
                  vehicles: [],
                },
              }
            }
          },
        }
      };
    },
    removeRoute(state, action) {
      const fields = state.solution.fields || {};
      const { fieldId, warehouseId } = action.payload;
      delete fields[fieldId]?.routes?.[warehouseId];
    },
    addVehicleToRoute(state, action) {
      const fields = state.solution.fields || {};
      const { fieldId, warehouseId, vehicle } = action.payload;
      fields[fieldId]?.routes?.[warehouseId].vehicles.push({ ...vehicle });
    },
    removeVehicleFromRoute(state, action) {
      const fields = state.solution.fields || {};
      const { fieldId, warehouseId, vehicle } = action.payload;
      const vehicleIndex = fields[fieldId]?.routes?.[warehouseId].vehicles
        .findIndex((v) => v.regNum === vehicle.regNum);
      fields[fieldId]?.routes?.[warehouseId].vehicles.splice(vehicleIndex, 1);
    },
    addHarvesterToField(state, action) {
      const fields = state.solution.fields || {};
      const { id: fieldId, hv: harvester } = action.payload;
      return {
        ...state,
        solution: {
          ...state.solution,
          fields: {
            ...fields,
            [fieldId]: {
              ...fields[fieldId],
              harvesters: [
                ...(fields[fieldId]?.harvesters || []),
                { ...harvester },
              ]
            }
          },
        }
      };
    },
    removeHarvesterFromField(state, action) {
      const fields = state.solution.fields || {};
      const { id: fieldId, hv: harvester } = action.payload;
      const hvIndex = fields[fieldId]?.harvesters
        .findIndex((h) => h.regNum === harvester.regNum);
      fields[fieldId]?.harvesters.splice(hvIndex, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(logisticTasksApi.endpoints.getLogisticTaskId.matchFulfilled, (state, action) => {
        if (action.payload?.type === 'BALANCE_CAPACITY') {
          state.initialData = action.payload;
          state.data = action.payload;
        }
      });
  }
});

export const logisticTasksBalanceResourceActions = logisticTasksBalanceSlice.actions;
export const logisticTasksBalanceResourceReducer = logisticTasksBalanceSlice.reducer;
