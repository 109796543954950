// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import vehicleRoutesDtoType from "../../types/vehicleRoutes-dto.type";

export function vehicleRoutesFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

vehicleRoutesFromDtoService.propTypes = {
  dto: vehicleRoutesDtoType
};

export default vehicleRoutesFromDtoService;
