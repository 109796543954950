import {
  Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText,
  InputAdornment, InputLabel, OutlinedInput, TextField, Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import style from './VehicleMovementWidget.module.css';
import overtakingImg from '../../../assets/images/overtaking.jpg';
import {
  getPlatoonGroupActivities, getPlatoonGroupVehicles, patchPlatoonGroup,
  postPlatoonGroupActivities, putPlatoonGroupVehicles
} from '../../../shared/api/api';
import NoData from '../../../shared/ui/NoData/NoData';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import formatFromTimeISO8601 from '../../../shared/utils/formatFromTimeISO8601';
import addTimeIntervalISO8601 from '../../../shared/utils/addTimeIntervalISO8601';

const NO_DATA = 'нет данных';

// Отображает часть аккордина панели "Группа ТС" - Движение
function VehicleMovementWidget({
  vehicle, platoonGroupId, platoonGroup, setPlatoonGroup
}) {
  const [platoonGroupVehicle, setPlatoonGroupVehicle] = useState(null);
  const [lastManoeuvre, setLastManoeuvre] = useState(null);

  const [isPlan, setIsPlan] = useState(false);
  const [planDate, setPlanDate] = useState(new Date());
  const [interval, setInterval] = useState(1);

  const clickLeaveGroup = () => {
    if (platoonGroupVehicle) {
      putPlatoonGroupVehicles({
        ...platoonGroupVehicle,
        status: 'CANCEL',
      }).then((res) => {
        setPlatoonGroupVehicle(res);
      }).catch((error) => { throw error; });
    }
  };

  const changeTextField = (event) => {
    setInterval(event.target.value);
  };

  // Добавление в PlatoonGroupActivities
  const clickAddManeuver = (e, data, manoeuvre, modifiedDate = new Date()) => {
    const params = {
      manoeuvre,
      vehicleId: vehicle.vehicleId,
      status: "REQUEST_ACK",
      data,
      msg: '',
      isPlanned: isPlan,
      modifiedDate,
      group: {
        platoonGroupId,
      }
    };
    postPlatoonGroupActivities(params).then(() => {
      // setUpdate((prev) => !prev);
    }).catch((error) => { throw error; });
  };

  // Обновление интервала в группе
  const clickAddInterval = (e, value) => {
    const body = {
      platoonGroupId,
      intervalDuration: addTimeIntervalISO8601(platoonGroup.intervalDuration, `PT${value}M`)
    };
    patchPlatoonGroup(body).then((res) => {
      setPlatoonGroup(res);
    }).catch((error) => { throw error; });
  };

  useEffect(() => {
    if (vehicle && platoonGroupId) {
      getPlatoonGroupVehicles({
        vehicleId: vehicle.vehicleId,
        groupId: platoonGroupId,
        status: {
          paramBody: 'notEquals',
          paramValue: 'CANCEL',
        }
      }).then((res) => {
        if (res.length) {
          setPlatoonGroupVehicle(res[0]);
        } else {
          setPlatoonGroupVehicle(null);
        }
      }).catch((error) => { throw error; });

      getPlatoonGroupActivities(
        {
          vehicleId: vehicle.vehicleId,
          manoeuvre: 'INFO_MSG',
          groupId: platoonGroupId,
        },
        {
          page: 0,
          size: 1,
          sort: 'modifiedDate,desc'
        }
      ).then((res) => {
        if (res.length) {
          setLastManoeuvre(res[0]);
        } else {
          setLastManoeuvre(null);
        }
      }).catch((error) => { throw error; });
    }
  }, [vehicle, platoonGroupId]);

  return platoonGroupVehicle ? (
    <Box className={style.vehicleMovementWidget}>
      <Box className={style.movementInfo}>
        <Box>
          <Typography sx={{ color: 'text.secondary' }}>
            Обновлено:
            {' '}
            {platoonGroupVehicle.modifiedDate?.toLocaleString() || NO_DATA}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Расстояние до ведущего:
            {' '}
            {formatFromTimeISO8601(platoonGroupVehicle.intervalLeader, true) || NO_DATA}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Интервал до предыдущего ТС:
            {' '}
            {formatFromTimeISO8601(platoonGroupVehicle.intervalPrev, true) || NO_DATA}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Интервал до следующего ТС:
            {' '}
            {formatFromTimeISO8601(platoonGroupVehicle.intervalNext, true) || NO_DATA}
          </Typography>
          {platoonGroup
            ? (
              <Typography sx={{ color: 'text.secondary' }}>
                Интервал группы:
                {' '}
                {formatFromTimeISO8601(platoonGroup.intervalDuration, true) || NO_DATA}
              </Typography>
            )
            : null}
          {lastManoeuvre?.msg
            ? (
              <Typography sx={{ mt: '30px', color: 'text.secondary', maxWidth: '700px' }}>
                Сообщение:
                {' '}
                {lastManoeuvre.msg}
              </Typography>
            )
            : null}
        </Box>
        <Box className={style.nextManeuver}>
          <Typography sx={{ color: 'text.secondary' }}>
            Следующий маневр: Обгон
          </Typography>
          <img width="54" src={overtakingImg} alt="overtakingImg" />
        </Box>
      </Box>
      {
        platoonGroupVehicle?.role === 'LEADER' || platoonGroupVehicle?.role === 'BOTH'
          ? (
            <>
              <Box className={style.maneuverButtons}>
                <Button variant="contained" onClick={(e) => clickAddInterval(e, 5)}>Интервал +5М</Button>
                <Button variant="contained" onClick={(e) => clickAddInterval(e, 15)}>Интервал +15М</Button>
                <Button variant="contained" onClick={(e) => clickAddInterval(e, -5)}>Интервал -5М</Button>
                <Button variant="contained" onClick={(e) => clickAddInterval(e, -15)}>Интервал -15М</Button>
              </Box>
              <Box className={style.customManeuver}>
                <FormControl error={!interval || interval === '0'} sx={{ mt: 1, mb: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="interval">Интервал движения</InputLabel>
                  <OutlinedInput
                    label="Интервал движения"
                    type="number"
                    value={interval}
                    onChange={changeTextField}
                    id="interval"
                    endAdornment={<InputAdornment sx={{ color: 'inherit' }} position="end">мин</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      // 'aria-label': 'weight',
                      // min: 0,
                      step: "1"
                    }}
                  />
                  {!interval || interval === '0'
                    ? (
                      <FormHelperText>Некорректное значение</FormHelperText>
                    )
                    : null}
                </FormControl>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      checked={isPlan}
                      onChange={(event) => setIsPlan(event.target.checked)}
                    />
                  )}
                  label="Запланировать на"
                />
                <DateTimePicker
                  disabled={!isPlan}
                  // label="Дата и время"
                  value={planDate}
                  onChange={(newDate) => setPlanDate(newDate)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      // size="small"
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
                <Button variant="contained" onClick={(e) => clickAddManeuver(e, interval, 'INTERVAL_SET', isPlan && planDate ? planDate : undefined)}>
                  Уст интервал
                </Button>
              </Box>
            </>
          )
          : null
      }
      <Box className={style.buttons}>
        <Button variant="contained" onClick={(e) => clickAddManeuver(e, 0, 'OVERTAKE')}>Обгон</Button>
        <Button variant="contained" color="secondary" onClick={clickLeaveGroup}>Выйти из группы</Button>
      </Box>
    </Box>
  )
    : <NoData />;
}

VehicleMovementWidget.propTypes = {
  vehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  platoonGroupId: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  platoonGroup: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  setPlatoonGroup: PropTypes.func.isRequired,
};

VehicleMovementWidget.defaultProps = {
  vehicle: null,
  platoonGroupId: null,
  platoonGroup: null,
};

export default VehicleMovementWidget;
