import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../shared/lib/hooks/useAuth';
import NoAccess from '../../shared/ui/NoAccess/NoAccess';

// Оборачивает приватный роут. В случае, если в ролях текущего
// авторизованного пользователя нет переданных компоненту ролей,
// вместо дочерних компоненнтов возвращает компонент с "отсутствием доступа".
function PrivateRoute({ children, roles, isEvery = true }) {
  const auth = useAuth();
  const funcName = isEvery ? 'every' : 'some';
  if (auth.user) {
    if (!roles?.[funcName]?.((role) => auth.user?.authorities.includes(role))) {
      return (
        <NoAccess />
      );
    }
  }
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  isEvery: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  roles: [],
  isEvery: true,
};

export default PrivateRoute;
