import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const adapterResourceTypeEnum = {
  adapterTypeId: "adapterTypeId",
  model: "model",
  className: "className",
  gateType: "gateType",
  gatewayHost: "gatewayHost",
  gatewayPort: "gatewayPort",
};

// Инициализация стейта в сторе
const adapterResourceTypeInitialState = {
  [adapterResourceTypeEnum.adapterTypeId]: NaN,
  [adapterResourceTypeEnum.model]: "",
  [adapterResourceTypeEnum.className]: "",
  [adapterResourceTypeEnum.gateType]: "",
  [adapterResourceTypeEnum.gatewayHost]: "",
  [adapterResourceTypeEnum.gatewayPort]: "",
};
// Типизация стора
export const adapterResourceType = {
  [adapterResourceTypeEnum.adapterTypeId]: PropTypes.number,
  [adapterResourceTypeEnum.model]: PropTypes.string,
  [adapterResourceTypeEnum.className]: PropTypes.string,
  [adapterResourceTypeEnum.gateType]: PropTypes.string,
  [adapterResourceTypeEnum.gatewayHost]: PropTypes.string,
  [adapterResourceTypeEnum.gatewayPort]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const adapterResourceTypeSlice = createSlice({
  name: `${reducerPath.adapterResourceType}/counter`,
  initialState: adapterResourceTypeInitialState,
  reducers: {}
});

export const adapterResourceTypeReducer = adapterResourceTypeSlice.reducer;
