import convertDurationToSeconds from "./convertDurationToSeconds";
import convertSecondsToTimeString from "./convertSecondsToTimeString";

const PlatoonGroupVehicleActions = Object.freeze({
  PGV_ACTION_CONTINUE: "PGV_ACTION_CONTINUE",
  PGV_ACTION_ACCELERATE: "PGV_ACTION_ACCELERATE",
  PGV_ACTION_DECELERATION: "PGV_ACTION_DECELERATION",
  PGV_ACTION_DOUBLE_ACCELERATE: "PGV_ACTION_DOUBLE_ACCELERATE",
  PGV_ACTION_DOUBLE_DECELERATION: "PGV_ACTION_DOUBLE_DECELERATION",
  PGV_ACTION_TRIPLE_ACCELERATE: "PGV_ACTION_TRIPLE_ACCELERATE",
  PGV_ACTION_TRIPLE_DECELERATION: "PGV_ACTION_TRIPLE_DECELERATION",
});

function processGroupData(vehicles) {
  let res = vehicles.map(({
    id,
    simplePoint,
    num,
    intervalPrev,
    intervalNext,
    modifiedDate,
    action,
    ...rest
  }) => {
    const prevIntervalSeconds = convertDurationToSeconds(intervalPrev);
    const nextIntervalSeconds = convertDurationToSeconds(intervalNext);
    const now = new Date();
    const modifiedTime = new Date(simplePoint ? undefined : modifiedDate);
    const fromNow = now - modifiedTime;
    const isOlderThan2min = fromNow > 120000;
    const isOlderThan5min = fromNow > 300000;
    return ({
      id,
      simplePoint,
      num,
      intervalPrev: intervalPrev ? convertSecondsToTimeString(prevIntervalSeconds, false) : null,
      intervalNext: intervalNext ? convertSecondsToTimeString(nextIntervalSeconds, false) : null,
      hasNoData: intervalPrev === null && intervalNext === null,
      isOlderThan2min,
      isOlderThan5min,
      prevIntervalSeconds,
      nextIntervalSeconds,
      action,
      modifiedDate,
      ...rest,
    });
  });
  res = res.sort((a, b) => (
    convertDurationToSeconds(b.intervalLeader)
    - convertDurationToSeconds(a.intervalLeader)
  ))
    .sort((a, b) => {
      if (a.hasNoData && !b.hasNoData) {
        return -1;
      }
      if (!a.hasNoData && b.hasNoData) {
        return 1;
      }
      if (a.isOlderThan5min && !b.isOlderThan5min) {
        return -1;
      }
      if (!a.isOlderThan5min && b.isOlderThan5min) {
        return 1;
      }
      return 0;
    });
  const {
    allIntervals,
    numberOfIntervals,
  } = res.reduce((acc, item) => {
    if (item.hasNoData || item.isOlderThan5min || item.simplePoint) {
      return acc;
    }
    // TODO IRL не может быть отрицательных интевалов?! Нужно убрать Math.abs:
    return {
      allIntervals: acc.allIntervals
        + Math.abs(Number.isNaN(+item.prevIntervalSeconds) ? 0 : +item.prevIntervalSeconds),
      numberOfIntervals: acc.numberOfIntervals + (item.prevIntervalSeconds ? 1 : 0),
    };
  }, { allIntervals: 0, numberOfIntervals: 0 });
  const averageInterval = allIntervals / numberOfIntervals;
  return res.map((v) => {
    const hasAverage = !Number.isNaN(averageInterval)
      && Number.isFinite(averageInterval);
    const averageData = {
      // averageIntervalSec: hasAverage ? averageInterval : 0,
      averageIntervalTxt: hasAverage
        ? convertSecondsToTimeString(Math.round(averageInterval), false) : null,
    };
    if (v.hasNoData || v.isOlderThan5min || v.simplePoint) {
      return {
        ...v,
        ...averageData,
      };
    }
    const prevIntervalRel = hasAverage
      ? Math.abs((v.prevIntervalSeconds - averageInterval) / averageInterval) : 1;
    const nextIntervalRel = hasAverage
      ? Math.abs((v.nextIntervalSeconds - averageInterval) / averageInterval) : 1;
    return ({
      ...v,
      prevColor: prevIntervalRel > 0.05 ? 'error.dark' : '#0B8108',
      nextColor: nextIntervalRel > 0.05 ? 'error.dark' : '#0B8108',
      decelerationIcon: v.action === PlatoonGroupVehicleActions.PGV_ACTION_DECELERATION,
      doubleDecelerationIcon: (
        v.action === PlatoonGroupVehicleActions.PGV_ACTION_DOUBLE_DECELERATION
      ) || v.action === PlatoonGroupVehicleActions.PGV_ACTION_TRIPLE_DECELERATION,
      accelerationIcon: v.action === PlatoonGroupVehicleActions.PGV_ACTION_ACCELERATE,
      doubleAccelerationIcon: (
        v.action === PlatoonGroupVehicleActions.PGV_ACTION_DOUBLE_ACCELERATE
      ) || v.action === PlatoonGroupVehicleActions.PGV_ACTION_TRIPLE_ACCELERATE,
      ...averageData,
      diffPrevFromAverage: convertSecondsToTimeString(
        hasAverage ? Math.round(v.prevIntervalSeconds - averageInterval) : 0,
        true
      ),
      diffNextFromAverage: convertSecondsToTimeString(
        hasAverage ? Math.round(v.nextIntervalSeconds - averageInterval) : 0,
        true
      ),
    });
  });
}

export default processGroupData;
