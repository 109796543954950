import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import {
  useGetAlertsSettingsResourceIdQuery,
  usePutAlertsSettingsResourceMutation
} from "../alertsSettingsResource/redux/alertsSettingsResource.api";
import { useGetAlertsChannelsResourceQuery } from "../alertsChannelsResource/redux/alertsChannelsResource.api";
import { useGetAlertsTypesResourceQuery } from "../alertsTypesResource/redux/alertsTypesResource.api";
// eslint-disable-next-line boundaries/element-types
import { useLazyGetDivisionsResourceQuery } from "../../divisions/divisionsResource/redux/divisionsResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AlertsSettingsEditResourceBll({
  children, alertId, companyId
}) {
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const { data: dataAlertsTypesResource } = useGetAlertsTypesResourceQuery({});

  const [
    fetchDivisionsResource,
    { data: dataDivisionsResource }
  ] = useLazyGetDivisionsResourceQuery();

  const fetchAlertsSettingsResourceParams = useCallback(
    () => fetchDivisionsResource({
      params: {
        "companyId.equals": companyId,
      },
    }),
    [companyId, fetchDivisionsResource, token]
  );

  useEffect(() => {
    fetchAlertsSettingsResourceParams();
  }, [fetchAlertsSettingsResourceParams]);

  const { data: dataAlertsSettingsResourceId } = useGetAlertsSettingsResourceIdQuery(
    {
      id: alertId
    },
  );

  const { data: dataAlertsChannels } = useGetAlertsChannelsResourceQuery({});

  const [data, setData] = useState(null);

  const [putAlertsSettingsResource] = usePutAlertsSettingsResourceMutation();

  const putAlertsSettingsResourceParams = useCallback(
    ({ id, body }) => putAlertsSettingsResource({
      id,
      body
    }),
    []
  );

  useEffect(() => {
    const newData = {};
    newData.dataAlertsSettingsResourceId = dataAlertsSettingsResourceId;
    newData.dataAlertsChannels = dataAlertsChannels;
    newData.dataAlertsTypesResource = dataAlertsTypesResource;
    newData.dataDivisionsResource = dataDivisionsResource;

    setData(newData);
  }, [
    dataAlertsSettingsResourceId,
    dataAlertsChannels,
    dataAlertsTypesResource,
    dataDivisionsResource
  ]);

  const props = {
    data,
    putAlertsSettingsResourceParams,
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AlertsSettingsEditResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
  alertId: PropTypes.number,
  companyId: PropTypes.number,
};

AlertsSettingsEditResourceBll.defaultProps = {
  alertId: NaN,
  companyId: NaN,

};

export default AlertsSettingsEditResourceBll;
