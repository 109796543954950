// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import divisionsResourceDtoType from "../../types/divisionsResource-dto.type";

export function divisionsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  return { data: dto, status };
}

divisionsResourceFromDtoService.propTypes = {
  dto: divisionsResourceDtoType,
};

export default divisionsResourceFromDtoService;
