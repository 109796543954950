import React, {
  useEffect, useState
} from 'react';
import {
  Alert, AlertTitle, Box, Button, TextField
} from '@mui/material';
import PropTypes, { shape } from "prop-types";
import style from './AccountMainPanel.module.css';
import { appUsersResourceType } from "../../../entities/account/appUsersResource/redux/appUsersResource.slice";
import { usersResourceType } from "../../../entities/account/usersResource/redux/usersResource.slice";

// Отображает панель "Моя учётная запись"
function AlertsPanel({ data, patchAppUserResourceParams, putUserResourceParams }) {
  const [isEdit, setIsEdit] = useState(false);
  const [dataAppUserResourceId, setDataAppUserResourceId] = useState(null);
  const [dataUserResourceId, setDataUserResourceId] = useState(null);
  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const isAdmin = dataUserResourceId?.authorities?.includes("ROLE_ADMIN");

  const user = data?.dataAppUserResourceId?.data;
  const userForAdmin = data?.dataUserResourceId?.data;

  useEffect(() => {
    if (user) {
      setDataAppUserResourceId(user);
    }
    if (userForAdmin) {
      setDataUserResourceId(userForAdmin);
    }
  }, [data?.dataAppUserResourceId, data?.dataUserResourceId]);
  const handleAppUserResource = () => {
    patchAppUserResourceParams({
      id: dataAppUserResourceId.appUserId,
      body: dataAppUserResourceId
    }).then((e) => {
      if (isAdmin) {
        putUserResourceParams({
          id: dataUserResourceId.appUserId,
          body: dataUserResourceId
        }).then(() => {
          if (e?.error) {
            setErr(true);
          } else {
            setIsCorrect(true);
            setIsEdit(false);
          }
        });
      } else if (e?.error) {
        setErr(true);
      } else {
        setIsCorrect(true);
        setIsEdit(false);
      }
    });
  };

  const clearHelpers = () => {
    setErr(false);
    setIsCorrect(false);
  };

  return (
    <Box className={style.AccountMainPanel}>
      <TextField
        onChange={(e) => {
          const newData = JSON.parse(JSON.stringify(dataAppUserResourceId));
          newData.firstName = e.target.value;
          setDataAppUserResourceId(newData);
          clearHelpers();
        }}
        value={dataAppUserResourceId?.firstName || ''}
        disabled={!isEdit}
        sx={{ width: "100%" }}
        label="Имя"
        variant="standard"
      />
      <TextField
        onChange={(e) => {
          const newData = JSON.parse(JSON.stringify(dataAppUserResourceId));
          newData.lastName = e.target.value;
          setDataAppUserResourceId(newData);
          clearHelpers();
        }}
        value={dataAppUserResourceId?.lastName || ''}
        disabled={!isEdit}
        sx={{ width: "100%" }}
        label="Фамилия"
        variant="standard"
      />

      <TextField
        onChange={(e) => {
          const newData = JSON.parse(JSON.stringify(dataUserResourceId));
          newData.email = e.target.value;
          setDataUserResourceId(newData);
          clearHelpers();
        }}
        value={dataUserResourceId?.email || ''}
        disabled={!isEdit && isAdmin}
        sx={{ width: "100%" }}
        label="Email"
        variant="standard"
      />
      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Данные успешно сохранены
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}
      <Box className={style.buttonsPanel}>
        {isEdit && (
        <Button
          onClick={handleAppUserResource}
          variant="contained"
        >
          Сохранить
        </Button>
        )}
        {!isEdit && (
        <Button
          onClick={() => {
            setIsEdit(true);
          }}
          variant="contained"
        >
          Редактировать
        </Button>
        )}
        {isEdit && (
        <Button
          onClick={() => {
            setIsEdit(false);
            setDataAppUserResourceId(user);
            setDataUserResourceId(userForAdmin);
          }}
          variant="contained"
        >
          Отмена
        </Button>
        )}
      </Box>
    </Box>
  );
}

AlertsPanel.propTypes = {
  data: PropTypes.shape({
    dataAppUserResourceId: shape({
      data: PropTypes.shape(appUsersResourceType)
    }),
    dataUserResourceId: shape({
      data: PropTypes.shape(usersResourceType)
    })
  }),
  patchAppUserResourceParams: PropTypes.func.isRequired,
  putUserResourceParams: PropTypes.func.isRequired,
};

AlertsPanel.defaultProps = {
  data: null,
};

export default AlertsPanel;
