export function convertStringToDate(dateString) {
  const parts = dateString.split(' ');
  const dateParts = parts[0].split('.');
  const timeParts = parts[1] ? parts[1].split(':') : undefined;

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Месяцы в JavaScript начинаются с 0
  const year = parseInt(dateParts[2], 10);
  const hour = timeParts ? parseInt(timeParts[0], 10) : 0;
  const minute = timeParts ? parseInt(timeParts[1], 10) : 0;

  const date = new Date(year, month, day, hour, minute);
  return date;
}

function convertServerFormatDate(str) {
  const newStr = JSON.parse(JSON.stringify(convertStringToDate(str)));
  const newDate = new Date(newStr);
  const isoString = newDate.toISOString();
  const formattedString = `${isoString.slice(0, 19)}Z`;
  return formattedString;
}

export default convertServerFormatDate;
