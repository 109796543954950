import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
export const appUsersResourceEnum = {
  appUserId: "appUserId",
  firstName: "firstName",
  lastName: "lastName",
  company: "company",
  user: "user",
  isPlanAdmin: "isPlanAdmin"
};

export const appUsersResourceUSEREnum = {
  id: "id",
  login: "login",
  email: "email"
};

// Инициализация стейта в сторе
const appUsersResourceInitialState = {
  [appUsersResourceEnum.appUserId]: NaN,
  [appUsersResourceEnum.firstName]: null,
  [appUsersResourceEnum.lastName]: null,
  [appUsersResourceEnum.company]: null,
  [appUsersResourceEnum.user]: null,
};
// Типизация стора
export const appUsersResourceType = {
  [appUsersResourceEnum.appUserId]: PropTypes.number,
  [appUsersResourceEnum.firstName]: PropTypes.string,
  [appUsersResourceEnum.lastName]: PropTypes.string,
  [appUsersResourceEnum.company]: PropTypes.string,
  [appUsersResourceEnum.isPlanAdmin]: PropTypes.bool,
  [appUsersResourceEnum.user]: PropTypes.shape({
    [appUsersResourceUSEREnum.id]: PropTypes.number,
    [appUsersResourceUSEREnum.login]: PropTypes.string,
    [appUsersResourceUSEREnum.email]: PropTypes.string
  }),
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const appUsersResourceSlice = createSlice({
  name: `${reducerPath.appUsersResource}/counter`,
  initialState: appUsersResourceInitialState,
  reducers: {}
});

export const appUsersResourceReducer = appUsersResourceSlice.reducer;
