const transformRoute = (vehicleRoute) => ({
  ...vehicleRoute,
  id: vehicleRoute.vehicleRouteId,
  unicId: crypto.randomUUID(),
  startTime: vehicleRoute.startTime ? new Date(vehicleRoute.startTime) : null,
  startTimeUntil: vehicleRoute.startTimeUntil ? new Date(vehicleRoute.startTimeUntil) : null,
  finishTime: vehicleRoute.finishTime ? new Date(vehicleRoute.finishTime) : null,
  finishTimeUntil: vehicleRoute.finishTimeUntil
    ? new Date(vehicleRoute.finishTimeUntil) : null,
  routePoints: vehicleRoute.routePoints
    ?.map((p, j) => ({
      ...p,
      id: j,
      arriveTime: p.arriveTime ? new Date(p.arriveTime) : null,
      arriveTimeUntil: p.arriveTimeUntil ? new Date(p.arriveTimeUntil) : null,
      leaveTime: p.leaveTime ? new Date(p.leaveTime) : null,
      leaveTimeUntil: p.leaveTimeUntil ? new Date(p.leaveTimeUntil) : null,
      stopTime: p.stopTime ? new Date(p.stopTime) : null,
      stopTimeUntil: p.stopTimeUntil ? new Date(p.stopTimeUntil) : null,
      backArriveTime: p.backArriveTime ? new Date(p.backArriveTime) : null,
      backArriveTimeUntil: p.backArriveTimeUntil ? new Date(p.backArriveTimeUntil) : null,
      backLeaveTime: p.backLeaveTime ? new Date(p.backLeaveTime) : null,
      backLeaveTimeUntil: p.backLeaveTimeUntil ? new Date(p.backLeaveTimeUntil) : null,
      backStopTime: p.backStopTime ? new Date(p.backStopTime) : null,
      backStopTimeUntil: p.backStopTimeUntil ? new Date(p.backStopTimeUntil) : null,
      comment: p.comment || "",
    }))
    ?.sort((a, b) => a.orderNum - b.orderNum)
});

export default transformRoute;
