import React from 'react';
import {
  Box, Checkbox, FormControl, FormControlLabel, FormLabel,
  Menu, Radio, RadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';

// Контрол переключания слоев карты
function GeoMapControl(props) {
  const {
    underLayers,
    anchorControl,
    closeControl,
    underLayer,
    changeUnderLayer,
    handleChangeCheckbox,
    overLayers,
  } = props;

  const open = Boolean(anchorControl);

  return (
    <Menu
      id="map-controls"
      anchorEl={anchorControl}
      open={open}
      onClose={closeControl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{
        pl: '20px', pr: '10px', display: 'flex', flexDirection: 'column'
      }}
      >
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Подложка</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={underLayer}
            onChange={changeUnderLayer}
          >
            {underLayers.map((mapLayer) => (
              <FormControlLabel
                key={mapLayer.title}
                value={mapLayer.title}
                control={<Radio size="small" />}
                label={mapLayer.title}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Слои</FormLabel>
          {overLayers?.map((item) => (
            <FormControlLabel
              key={item.id}
              control={(
                <Checkbox
                  checked={item.isShow}
                  onChange={() => handleChangeCheckbox(item.id)}
                />
              )}
              label={item.title}
            />
          ))}
        </FormControl>
      </Box>
    </Menu>
  );
}

GeoMapControl.propTypes = {
  overLayers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  underLayers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  anchorControl: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  closeControl: PropTypes.func.isRequired,
  underLayer: PropTypes.string.isRequired,
  changeUnderLayer: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
};

GeoMapControl.defaultProps = {
  anchorControl: null,
};

export default GeoMapControl;
