import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const platoonGroupActivitiesResourceEnum = {

};

// Инициализация стейта в сторе
const platoonGroupActivitiesResourceInitialState = {

};
// Типизация стора
export const platoonGroupsResourceType = {

};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const platoonGroupActivitiesResourceSlice = createSlice({
  name: `${reducerPath.platoonGroupActivitiesResource}/counter`,
  initialState: platoonGroupActivitiesResourceInitialState,
  reducers: {}
});

export const platoonGroupActivitiesResourceReducer = platoonGroupActivitiesResourceSlice.reducer;
