import {
  Box,
  Container, Tab, Tabs
} from '@mui/material';
import React from 'react';
import {
  Link, Navigate, Route, Routes, matchPath, useLocation
} from 'react-router-dom';
import TariffPlansPanel from '../../../panels/finances/TariffPlansPanel/TariffPlansPanel';
import TariffPlansResourceBll from '../../../entities/finances/tariffPlansResource/TariffPlansResourceBll/TariffPlansResourceBll';
import TariffCreateMainPanel from "../../../panels/finances/TariffCreateMainPanel/TariffCreateMainPanel";
import TariffEditMainPanel from "../../../panels/finances/TariffEditMainPanel/TariffEditMainPanel";
import TariffPlansResourceEditBll
  from "../../../entities/finances/tariffPlansResource/TariffPlansResourceBll/TariffPlansResourceEditBll";
import TariffPlansResourceCreateBll
  from "../../../entities/finances/tariffPlansResource/TariffPlansResourceBll/TariffPlansResourceCreateBll";
import PaymentResourceBll from "../../../entities/payments/paymentResource/PaymentResourceBll/PaymentResourceBll";
import PaymentInfoPanel from "../../../panels/payment/PaymentInfoPanel/PaymentInfoPanel";
import PaymentResourceEditBll
  from "../../../entities/payments/paymentResource/PaymentResourceEditBll/PaymentResourceEditBll";
import PaymentEditPanel from "../../../panels/payment/PaymentEditPanel/PaymentEditPanel";
import PaymentResourceCreateBll
  from "../../../entities/payments/paymentResource/PaymentResourceCreateBll/PaymentResourceCreateBll";
import PaymentCreatePanel from "../../../panels/payment/PaymentCreatePanel/PaymentCreatePanel";
import CompanyBalanceCreatePanel from "../../../panels/finances/CompanyBalanceCreatePanel/CompanyBalanceCreatePanel";
import CompanyBalanceEditPanel from "../../../panels/finances/CompanyBalanceEditPanel/CompanyBalanceEditPanel";
import CompanyBalanceInfoPanel from "../../../panels/finances/CompanyBalanceInfoPanel/CompanyBalanceInfoPanel";
import CompanyAccountResourceGetBll
  from "../../../entities/account/companyAccountResource/CompanyAccountResourceGetBll/CompanyAccountResourceGetBll";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function TariffPlansRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={(
          <TariffPlansResourceBll>
            <TariffPlansPanel />
          </TariffPlansResourceBll>
                          )}
      />
      <Route
        path="create"
        element={(
          <TariffPlansResourceCreateBll>
            <TariffCreateMainPanel />
          </TariffPlansResourceCreateBll>
                          )}
      />
      <Route
        path="edit/:tarifPlanId"
        element={(
          <TariffPlansResourceEditBll>
            <TariffEditMainPanel />
          </TariffPlansResourceEditBll>
                          )}
      />
    </Routes>
  );
}

function PaymentsRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={(
          <PaymentResourceBll>
            <PaymentInfoPanel />
          </PaymentResourceBll>
                          )}
      />
      <Route
        path="create"
        element={(
          <PaymentResourceCreateBll>
            <PaymentCreatePanel />
          </PaymentResourceCreateBll>
                          )}
      />
      <Route
        path="edit/:tarifPlanId"
        element={(
          <PaymentResourceEditBll>
            <PaymentEditPanel />
          </PaymentResourceEditBll>
                          )}
      />
    </Routes>
  );
}

function CompanyBalanceRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={(
          <CompanyAccountResourceGetBll>
            <CompanyBalanceInfoPanel />
          </CompanyAccountResourceGetBll>
                          )}
      />
      <Route
        path="create"
        element={(
          <CompanyAccountResourceGetBll>
            <CompanyBalanceCreatePanel />
          </CompanyAccountResourceGetBll>
                          )}
      />
      <Route
        path="edit/:tarifPlanId"
        element={(
          <CompanyAccountResourceGetBll>
            <CompanyBalanceEditPanel />
          </CompanyAccountResourceGetBll>
                          )}
      />
    </Routes>
  );
}

const pageUrl = 'finances';

const tabs = [
  { path: 'tariff-plans', name: 'Тарифные планы', element: <TariffPlansRoutes /> },
  { path: 'payments', name: 'Счета и платежи', element: <PaymentsRoutes /> },
  { path: 'company-balance', name: 'Баланс компаний', element: <CompanyBalanceRoutes /> },
];

const createRoute = (path) => `/${pageUrl}/${path}/*`;

// Отображает страницу "Финансы"
function FinanceView() {
  const routeMatch = useRouteMatch(tabs.map((tab) => createRoute(tab.path)));
  const currentTab = routeMatch?.pattern?.path;
  if (!currentTab) {
    return <Navigate to={tabs[0].path} />;
  }
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        paddingBottom: '40px'
      }}
    >
      <Box
        sx={{
          borderBottom: 1, borderColor: 'divider', pt: '20px', marginBottom: '10px'
        }}
      >
        <Tabs value={currentTab}>
          {tabs.map((tab) => (
            <Tab
              key={tab.path}
              label={tab.name}
              value={createRoute(tab.path)}
              to={tab.path}
              component={Link}
            />
          ))}
        </Tabs>
      </Box>
      <Routes>
        {tabs.map((tab) => (
          <Route key={tab.path} path={`${tab.path}/*`} element={tab.element} />
        ))}
      </Routes>
    </Container>
  );
}

export default FinanceView;
