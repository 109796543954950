import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import config from './config';
import { getAccount } from "./api";
// eslint-disable-next-line boundaries/element-types
import { clearStorage } from "../../entities/auth/tokenResource/redux/tokenResource";

let stompClient = null;
const topicsForSubscription = [];
const windowReconnectHandlers = {};

const reconnect = (connectCb) => {
  stompClient?.disconnect();
  stompClient = null;
  setTimeout(() => connectCb(), 3000);
};

const connect = () => {
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
  const url = `${config.socketUrl}?access_token=${token}`;

  const socket = new SockJS(url);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });

  const headers = {};
  stompClient.connect(headers, () => {
    console.log('ws connected');
  }, (err) => {
    console.error('connection error', err);
    // Проверяем авторизацию при получении специфической ошибки от сокета
    if (err.type === 'close' && err.code === 1002) {
      getAccount().then(() => {
        reconnect(connect);
      }).catch((error) => {
        if (error.status === 401) {
          clearStorage();
          window.location.href = '/';
        } else {
          reconnect(connect);
        }
      });
    } else {
      reconnect(connect);
    }
  });
};

const checkConnect = () => new Promise((resolve, reject) => {
  if (stompClient === null) {
    connect();
  }
  let i = 0;
  const connectInterval = setInterval(() => {
    i += 1;
    if (stompClient?.connected) {
      clearInterval(connectInterval);
      resolve(stompClient);
    } else if (i > 200) {
      clearInterval(connectInterval);
      reject(new Error('NOT_CONNECTED'));
    }
  }, 300);
});

export const unsubscribe = (subscriber, topic) => {
  const index = topicsForSubscription.indexOf(topic);
  if (index !== -1) {
    topicsForSubscription.splice(index, 1);
  }
  if (subscriber !== null) {
    subscriber.unsubscribe();
  }
  if (stompClient !== null) {
    if (stompClient.connected && topicsForSubscription.length === 0) {
      stompClient.disconnect();
      stompClient = null;
    }
  }
};

function handleRestoreConnection(_subscribe, topic, callback) {
  return () => {
    window.removeEventListener('online', windowReconnectHandlers[topic]?.on);
    _subscribe(topic, callback);
  };
}

export const subscribe = async (topic, callback) => {
  topicsForSubscription.push(topic);
  let connectedclient;
  try {
    connectedclient = await checkConnect();
  } catch (err) {
    console.error('subscription error', err);
    unsubscribe(null, topic);
    setTimeout(() => subscribe(topic, callback));
    return null;
  }
  const subscriber = topicsForSubscription.includes(topic)
    ? connectedclient.subscribe(topic, (data) => {
      callback(JSON.parse(data.body));
    })
    : null;
  const reconnectHandler = handleRestoreConnection(subscribe, topic, callback);
  if (windowReconnectHandlers[topic]) {
    window.removeEventListener('offline', windowReconnectHandlers[topic].off);
    window.removeEventListener('online', windowReconnectHandlers[topic].on);
  }
  windowReconnectHandlers[topic] = {
    on: reconnectHandler,
    off: () => {
      unsubscribe(subscriber, topic);
      window.addEventListener('online', reconnectHandler);
    }
  };
  window.addEventListener('offline', windowReconnectHandlers[topic].off);
  return subscriber;
};
