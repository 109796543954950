import React, {
  useEffect,
  useState
} from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useConfirm } from "material-ui-confirm";
import style from './UsersInfoPanel.module.css';
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import { appUsersResourceType } from "../../../entities/account/appUsersResource/redux/appUsersResource.slice";

const alertsHeadCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Имя',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Фамилия',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Почта',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Телефон',
  },
  {
    id: 'editCompany',
    numeric: false,
    disablePadding: false,
    label: 'Создание и редактирование компаний',
    style: { maxWidth: "100px", bgColor: "red" }
  },
  {
    id: 'editUsers',
    numeric: false,
    disablePadding: false,
    label: 'Создание и редактирование пользователей',
    style: { maxWidth: "100px" }
  },
  {
    id: 'workWithBill',
    numeric: false,
    disablePadding: false,
    label: 'Создание и отправка счетов',
    style: { maxWidth: "100px" }
  },
  {
    id: 'changeTariff',
    numeric: false,
    disablePadding: false,
    label: 'Смена тарифного плана',
    style: { maxWidth: "100px", bgColor: "red" }
  },

];

const alertsDisplayedCells = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'editCompany',
  "editUsers",
  "workWithBill",
  "changeTariff",
];

// Отображает панель "Пользователи"
function UsersInfoPanel({ data, fetchUserResourceParams, deleteAppUserResourceParams }) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    fetchUserResourceParams({ firstName, lastName });
  }, []);

  const rows = data?.dataUserResourceId?.data.map((e) => {
    const newDAta = JSON.parse(JSON.stringify(e));
    newDAta.id = e.appUserId;
    newDAta.editUsers = e.isUserAdmin !== null ? e.isUserAdmin ? 'да' : 'нет' : '-';
    newDAta.workWithBill = e.isBillAdmin !== null ? e.isBillAdmin ? 'да' : 'нет' : '-';
    newDAta.changeTariff = e.isPlanAdmin !== null ? e.isPlanAdmin ? 'да' : 'нет' : '-';
    newDAta.editCompany = e.isCompanyAdmin !== null ? e.isCompanyAdmin ? 'да' : 'нет' : '-';
    newDAta.phone = e.phone !== null ? e.phone : '-';
    newDAta.email = e.user?.email !== null ? e.user?.email : '-';
    return newDAta;
  });

  return (
    <Box className={style.UsersInfoPanel}>
      <Box className={style.search}>
        <TextField
          value={firstName || ''}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          sx={{ width: "100%" }}
          label="Имя"
          variant="outlined"
        />
        <TextField
          value={lastName || ''}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          sx={{ width: "100%" }}
          label="Фамилия"
          variant="outlined"
        />
        <TextField
          value={email || ''}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          sx={{ width: "100%" }}
          label="Почта"
          variant="outlined"
          disabled
        />
      </Box>
      <Box className={style.groupButton}>
        <Button
          disableElevation
          onClick={() => {
            setLastName('');
            setFirstName('');
            fetchUserResourceParams({ firstName: '', lastName: '' });
          }}
          variant="text"
        >
          Сбросить
        </Button>
        <Button
          onClick={() => {
            fetchUserResourceParams({ firstName, lastName });
          }}
          variant="contained"
        >
          Поиск
        </Button>
        <Link
          style={{
            display: 'flex',
            width: '97.69px'
          }}
          to="/users/create"
        >
          <Button sx={{ width: '300px' }} variant="contained">Создать</Button>
        </Link>
      </Box>

      <ControlledTable
        rows={rows}
        headCells={alertsHeadCells}
        displayedCells={alertsDisplayedCells}
        countRowsPerPage={5}
        selected={[]}
        controls
        setSelected={() => {}}
        onEdit={(e) => {
          if (data?.dataUserResourceId?.data) {
            navigate(`/users/edit/${e.appUserId}`);
          }
        }}
        onDelete={(row) => {
          confirm({
            title: 'Удаление',
            confirmationText: 'Да',
            cancellationText: 'Отмена',
            description: `Вы действительно хотите удалить пользователя «${row?.firstName}»?`
          })
            .then(() => { deleteAppUserResourceParams({ id: row.appUserId }); })
            .catch(() => { });
        }}
      />
    </Box>
  );
}

UsersInfoPanel.propTypes = {
  data: PropTypes.shape({
    dataUserResourceId: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(appUsersResourceType))
    }),
  }),
  fetchUserResourceParams: PropTypes.func,
  deleteAppUserResourceParams: PropTypes.func,
};

UsersInfoPanel.defaultProps = {
  data: null,
  fetchUserResourceParams: null,
  deleteAppUserResourceParams: null
};

export default UsersInfoPanel;
