// eslint-disable-next-line boundaries/no-unknown-files
import React from 'react';
import { createSvgIcon } from "@mui/material";

const TruckIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 18"
    strokeWidth={0}
    fill="currentColor"
  >
    <path d="m 11.139752,0
      v 3.6664587 2.333455
      h 1.581525
      v -2.333455
      h 17.5536
      v 9.8333753
      h -3.000006
      c 0,0.155316 -0.0046,0.311134 -0.02394,0.462632 -0.01892,0.151494 -0.03822,0.298741 -0.06764,0.445479 -0.02857,0.146745 -0.06764,0.292525 -0.111034,0.433568 -0.04764,0.141032 -0.09578,0.278241 -0.152459,0.41308 -0.05671,0.134352 -0.11864,0.265385 -0.187708,0.392116 -0.06911,0.127674 -0.144351,0.252529 -0.225349,0.372095 -0.081,0.119604 -0.16628,0.234885 -0.257779,0.345919 -0.09196,0.110995 -0.188209,0.217743 -0.290171,0.319203 -0.101961,0.101961 -0.208207,0.198208 -0.319202,0.29017 -0.111034,0.09196 -0.229172,0.176782 -0.348776,0.257779 -0.119605,0.081 -0.241564,0.156281 -0.369238,0.225851 -0.127673,0.06907 -0.260597,0.133889 -0.395927,0.190564 -0.134854,0.05671 -0.272063,0.105282 -0.413079,0.149139 -0.141032,0.04764 -0.283955,0.08146 -0.430712,0.110996 -0.146745,0.02857 -0.296347,0.05096 -0.448339,0.06764
      C 23.082029,17.994982 22.929068,18 22.773752,18
      c -0.155317,0 -0.308278,-0.0046 -0.459772,-0.02394 -0.151532,-0.01892 -0.301598,-0.03822 -0.448339,-0.06764 -0.146745,-0.02857 -0.289669,-0.06764 -0.430708,-0.110996 -0.141031,-0.04764 -0.281098,-0.09243 -0.41594,-0.149139 -0.134855,-0.05671 -0.264922,-0.121496 -0.392116,-0.190564 -0.127674,-0.06911 -0.252027,-0.144853 -0.372095,-0.225851 -0.119566,-0.081 -0.234885,-0.16628 -0.345919,-0.257779 -0.111034,-0.09196 -0.217743,-0.188209 -0.319203,-0.29017 -0.101498,-0.101498 -0.198672,-0.208208 -0.29017,-0.319203 -0.09196,-0.111034 -0.176743,-0.226315 -0.257779,-0.345919 -0.081,-0.119605 -0.156282,-0.244421 -0.225349,-0.372095 -0.06907,-0.127673 -0.13389,-0.25774 -0.190565,-0.392116 -0.05671,-0.134854 -0.105281,-0.272064 -0.149139,-0.41308 -0.04764,-0.141031 -0.08146,-0.286811 -0.110995,-0.433568 -0.02857,-0.146745 -0.05096,-0.293491 -0.06764,-0.445479 -0.01892,-0.151494 -0.02394,-0.307312 -0.02394,-0.462632
      H 11.72778
      c 0,0.155316 -0.0046,0.311134 -0.02394,0.462632 -0.01892,0.151494 -0.03822,0.298741 -0.06768,0.445479 -0.02857,0.146745 -0.06764,0.292525 -0.110995,0.433568 -0.04764,0.141032 -0.09578,0.278241 -0.152459,0.41308 -0.05671,0.134352 -0.11864,0.265385 -0.187708,0.392116 -0.06907,0.127674 -0.144351,0.252529 -0.225851,0.372095 -0.081,0.119604 -0.16628,0.234885 -0.257779,0.345919 -0.09196,0.110995 -0.188209,0.217743 -0.29017,0.319203 -0.101498,0.101961 -0.208208,0.198208 -0.319203,0.29017 -0.1109954,0.09196 -0.2291715,0.176782 -0.348776,0.257779 -0.119566,0.081 -0.2415642,0.156281 -0.3692376,0.225851 -0.1276735,0.06907 -0.2605975,0.133889 -0.3959305,0.190564 -0.1348544,0.05671 -0.2720637,0.105282 -0.4130798,0.149139 -0.1410314,0.04764 -0.2839546,0.08146 -0.4307077,0.110996
      C 7.9875181,17.93699 7.8379159,17.95938 7.6859239,17.97606 7.5344349,17.994982 7.3814739,18 7.226154,18 7.0708379,18 6.9178769,17.995368 6.7663829,17.976064 6.6148889,17.957147 6.4647848,17.937843 6.3180434,17.908425 6.1712981,17.879855 6.0283748,17.840785 5.8873357,17.797429 5.7463042,17.749788 5.6062379,17.705004 5.4713951,17.64829 5.3365794,17.591577 5.2060104,17.526794 5.0783254,17.457726 4.950652,17.38862 4.8257969,17.312873 4.7062309,17.231875 4.5866263,17.150878 4.4713458,17.065595 4.360312,16.974096 4.2493168,16.882134 4.1425683,16.785887 4.0411091,16.683926 3.9396112,16.582428 3.8429006,16.475718 3.7509387,16.364723 3.6589767,16.253689 3.5741957,16.138408 3.4931982,16.018804 3.4122006,15.899199 3.3369169,15.774383 3.2673471,15.646709 3.1982791,15.519036 3.1334579,15.388969 3.0767828,15.254593 3.0200691,15.119739 2.9715014,14.982529 2.9276438,14.841513 2.8800028,14.700482 2.846183,14.554702 2.8166486,14.407945 2.7880794,14.2612 2.7656874,14.114454 2.7490091,13.962466 2.7300917,13.810972 2.7250728,13.655154 2.7250728,13.499834
      H -0.27488619
      V 5.9999137
      L 4.2250753,0
      Z

      M 8.7286854,2.2499789
      H 4.9787039
      L 2.0373463,5.9999137
      H 8.7286854
      Z

      M 22.774914,11.249902
      c -0.155316,0 -0.307312,0.01892 -0.454058,0.04764 -0.146745,0.02857 -0.287313,0.07478 -0.421658,0.131959 -0.134854,0.05671 -0.264419,0.124353 -0.384024,0.20535 -0.119604,0.081 -0.229171,0.173423 -0.331132,0.275384 -0.101498,0.101498 -0.194387,0.214385 -0.275384,0.33399 -0.08053,0.119604 -0.151494,0.245849 -0.208208,0.381167 -0.05671,0.134854 -0.101961,0.275384 -0.131496,0.421654 -0.02857,0.146745 -0.04764,0.298278 -0.04764,0.454057 0,0.155316 0.0097,0.307312 0.04764,0.454054 0.02857,0.146745 0.07482,0.29017 0.131496,0.424519 0.05671,0.134854 0.127673,0.261562 0.208208,0.381167 0.081,0.119604 0.173422,0.232491 0.275384,0.333989 0.101498,0.101498 0.211528,0.19153 0.331132,0.272527 0.119605,0.081 0.248707,0.151494 0.384024,0.208208 0.134855,0.05671 0.275384,0.101961 0.421658,0.131997 0.146746,0.02857 0.298742,0.04764 0.454058,0.04764 0.155316,0 0.307312,-0.0097 0.454053,-0.04764 0.146745,-0.02857 0.287313,-0.07482 0.421658,-0.131997 0.134855,-0.05671 0.26442,-0.12721 0.384024,-0.208208 0.119605,-0.081 0.229172,-0.170566 0.331133,-0.272527 0.101498,-0.101498 0.194386,-0.214385 0.275384,-0.333989 0.081,-0.119566 0.151494,-0.24585 0.208207,-0.381167 0.05671,-0.134855 0.09911,-0.278241 0.128639,-0.424519 0.02857,-0.146746 0.04764,-0.29824 0.04764,-0.454054 0,-0.155779 -0.01892,-0.307312 -0.04764,-0.454057 -0.02857,-0.146745 -0.07193,-0.287313 -0.128639,-0.421654 -0.05671,-0.134816 -0.12721,-0.2611 -0.208207,-0.381167 -0.081,-0.119605 -0.173423,-0.232492 -0.275384,-0.33399 -0.101498,-0.101498 -0.211528,-0.194386 -0.331133,-0.275384 -0.119604,-0.08053 -0.248706,-0.148637 -0.384024,-0.20535 -0.134854,-0.05671 -0.27492,-0.101962 -0.421658,-0.131959 -0.146745,-0.02857 -0.298741,-0.04764 -0.454053,-0.04764
      z

      m -15.5462313,0
      c -0.155316,0 -0.3073119,0.01892 -0.4540533,0.04764 -0.1467453,0.02857 -0.2873135,0.07478 -0.4216583,0.131959 -0.1348543,0.05671 -0.2644195,0.124353 -0.384024,0.20535 -0.1196046,0.081 -0.2291714,0.173423 -0.3311325,0.275384 -0.1014979,0.101498 -0.1943864,0.214385 -0.2753839,0.33399 -0.080534,0.119604 -0.151494,0.245849 -0.2082077,0.381167 -0.056714,0.134854 -0.1019612,0.275384 -0.1319588,0.421654 -0.028569,0.146745 -0.047641,0.298278 -0.047641,0.454057 0,0.155316 0.00965,0.307312 0.047641,0.454054 0.028569,0.146745 0.07482,0.29017 0.1319588,0.424519 0.056714,0.134854 0.1272101,0.261562 0.2082077,0.381167 0.080997,0.119604 0.1734227,0.232491 0.2753839,0.333989 0.1014979,0.101498 0.2115279,0.19153 0.3311325,0.272527 0.1196045,0.081 0.2487064,0.151494 0.384024,0.208208 0.1348544,0.05671 0.275384,0.101961 0.4216583,0.131997 0.1467453,0.02857 0.2987411,0.04764 0.4540533,0.04764 0.1553161,0 0.307312,-0.0097 0.4540573,-0.04764 0.1467453,-0.02857 0.2873134,-0.07482 0.4216543,-0.131997 0.1348544,-0.05671 0.2644195,-0.12721 0.3840241,-0.208208 0.119566,-0.081 0.2291713,-0.170566 0.3311325,-0.272527 0.1014978,-0.101498 0.1943863,-0.214385 0.2753839,-0.333989 0.080997,-0.119566 0.151494,-0.24585 0.2082077,-0.381167 0.056714,-0.134855 0.099104,-0.278241 0.1286386,-0.424519 0.028569,-0.146746 0.047641,-0.29824 0.047641,-0.454054 0,-0.155779 -0.018918,-0.307312 -0.047641,-0.454057
      C 9.4032119,12.900302 9.3598176,12.759734 9.3031425,12.625393 9.2464288,12.490577 9.1759323,12.364293 9.0949348,12.244226 9.0139372,12.124621 8.921512,12.011734 8.8195509,11.910236 8.7175897,11.808738 8.608023,11.71585 8.4884184,11.634852 8.3688138,11.554318 8.2397119,11.486215 8.1043943,11.429502 7.96954,11.372788 7.8294737,11.32754 7.68274,11.297543 7.5359947,11.268974 7.3839988,11.249902 7.2286827,11.249902
      Z"
    />
  </svg>,
  'Truck',
);

export default TruckIcon;
