function isCurrentDateInRange(dataStart, dataEnd, date = new Date()) {
  const currentDate = date;

  // Преобразуем даты начала и конца в объекты типа Date
  const startDate = new Date(dataStart);
  const endDate = new Date(dataEnd);

  // Проверяем, находится ли текущая дата между началом и концом интервала
  return currentDate >= startDate && currentDate <= endDate;
}

export default isCurrentDateInRange;
