import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { usePostAlertsSettingsResourceMutation } from "../redux/alertsSettingsResource.api";
import { useGetAlertsChannelsResourceQuery } from "../../alertsChannelsResource/redux/alertsChannelsResource.api";
import { useGetAlertsTypesResourceQuery } from "../../alertsTypesResource/redux/alertsTypesResource.api";
// eslint-disable-next-line boundaries/element-types
import { useLazyGetDivisionsResourceQuery } from "../../../divisions/divisionsResource/redux/divisionsResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AlertsSettingsCreateResourceBll({
  children, companyId
}) {
  const [data, setData] = useState(null);
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const { data: dataAlertsTypesResource } = useGetAlertsTypesResourceQuery({});

  const [
    fetchDivisionsResource,
    { data: dataDivisionsResource }
  ] = useLazyGetDivisionsResourceQuery();

  const fetchAlertsSettingsResourceParams = useCallback(
    () => fetchDivisionsResource({
      authToken: token,
      params: {
        "companyId.equals": companyId,
      },
    }),
    [companyId, fetchDivisionsResource, token]
  );

  useEffect(() => {
    fetchAlertsSettingsResourceParams();
  }, [fetchAlertsSettingsResourceParams]);

  const { data: dataAlertsChannels } = useGetAlertsChannelsResourceQuery({});

  const [postAlertsSettingsResource] = usePostAlertsSettingsResourceMutation();

  const postAlertsSettingsResourceParams = useCallback(
    ({ body }) => postAlertsSettingsResource({
      body
    }),
    []
  );

  useEffect(() => {
    const newData = {};
    newData.dataAlertsChannels = dataAlertsChannels;
    newData.dataAlertsTypesResource = dataAlertsTypesResource;
    newData.dataDivisionsResource = dataDivisionsResource;
    setData(newData);
  }, [
    dataAlertsChannels, dataAlertsTypesResource, dataDivisionsResource
  ]);

  const props = {
    data,
    postAlertsSettingsResourceParams
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AlertsSettingsCreateResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
  companyId: PropTypes.number
};

AlertsSettingsCreateResourceBll.defaultProps = {
  companyId: NaN,
};

export default AlertsSettingsCreateResourceBll;
