import {
  Box,
  Button,
  Chip,
  Step,
  StepLabel,
  Stepper,
  TextField,
  alpha,
  StepIcon,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
  InputAdornment,
  StepContent,
  FormHelperText,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import * as Extent from "ol/extent";
import { blue, green, red } from '@mui/material/colors';
import { fromLonLat, toLonLat } from 'ol/proj';
import { useNavigate } from "react-router-dom";
import GeoMapWithTiles from '../../../features/geo-map/GeoMapWithTiles';
import style from './LogisticTaskEditorWidget.module.css';
import LineVectorLayer from '../../../shared/ui/GeoMap/LineVectorLayer';
import MarkerVectorLayer from '../../../shared/ui/GeoMap/MarkerVectorLayer';
import PolygonVectorLayer from '../../../shared/ui/GeoMap/PolygonVectorLayer';
import Loading from '../../../shared/ui/Loading/Loading';
import { extractCoordinates, parsePolygon } from '../../../shared/geo-map/lib';
import GeometryItemControls from '../../../features/logistics/logisticTask/GeometryItemControls/GeometryItemControls';
import DateTimePicker from '../../../shared/ui/DateTimePicker/DateTimePicker';

const geometriesInitial = [
  {
    id: 0,
    label: 'Старт',
    isStartDraw: false,
    isSelected: false,
    isCreated: false,
    pointName: 'A',
    disabledControls: false,
    coords: [],
    color: blue[500],
  },
  {
    id: 1,
    label: 'Финиш',
    isStartDraw: false,
    isSelected: false,
    isCreated: false,
    pointName: 'C',
    disabledControls: false,
    coords: [],
    color: red[400],
  },
];

const EMPTY_ERROR = "Поле не может быть пустым";

function LogisticTaskEditorWidget(props) {
  const navigate = useNavigate();
  const {
    logisticTask, getLogisticTaskSolution, solutionLogisticTask,
    solveLogisticTask, patchLogisticTaskSolve,
    postRouteRequest, postRouteResult
  } = props;
  const mainAreaCoords = useMemo(() => (
    parsePolygon(logisticTask.data?.source?.track, '(((')
  ), [logisticTask.data]);

  const extent = useMemo(() => {
    if (mainAreaCoords) {
      return Extent.boundingExtent(mainAreaCoords);
    }
    return null;
  }, [mainAreaCoords]);

  const points = useMemo(() => (
    logisticTask.data?.source?.controlPoints?.map((p, i) => ({
      id: i,
      coordinates: fromLonLat([p.lon, p.lat])
    }))
  ), [logisticTask.data]);

  const obstacles = useMemo(() => (
    logisticTask.data?.source?.obstacles?.map((obstacle, i) => ({
      id: i,
      coordinates: parsePolygon(obstacle),
    }))
  ), [logisticTask.data]);

  const solution = useMemo(() => (
    logisticTask?.data?.solution?.routePoints?.map((el, i) => ({
      id: i,
      coordinates: fromLonLat([el.lon, el.lat]),
      time: `${new Date(el.arriveTime)?.toLocaleTimeString()?.slice(0, -3)} – ${new Date(el.leaveTime)?.toLocaleTimeString()?.slice(0, -3)}`
    }))
  ), [logisticTask.data]);

  const [name, setName] = useState("");
  const [timeToWork, setTimeToWork] = useState('1');
  const [speed, setSpeed] = useState('10');
  const [startTime, setStartTime] = useState(new Date());

  const [errorsForm, setErrorsForm] = useState({
    name: false,
    speed: false,
    timeToWork: false,
    startTime: false,
  });

  const [shortcut, setShortcut] = useState(null);

  const [geometries, setGeometries] = useState([...geometriesInitial]);

  const onDeselect = useCallback((id) => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: false,
      }));
      newGeometries[id] = {
        ...prev[id],
        isSelected: false,
        isCanceledModify: false,
      };

      return newGeometries;
    });
  }, []);

  const updateCoords = useCallback((id, newCoords, other) => {
    setGeometries((prev) => {
      const newGeometries = [...prev];
      newGeometries[id] = {
        ...prev[id],
        coords: newCoords,
        ...other
      };
      return newGeometries;
    });
  }, []);

  // const [hoverStep, setHoverStep] = useState(null);

  const setInitialCoords = useCallback((index, newCoords) => {
    setGeometries((prev) => {
      const newGeometries = [...prev];
      newGeometries[index] = {
        ...prev[index],
        isCreated: true,
        isStartDraw: false,
        isDelete: false,
        isCanceledDraw: false,
        isCanceledModify: false,
        initialCoords: newCoords,
        coords: newCoords,
      };
      return newGeometries;
    });
  }, []);

  useEffect(() => {
    if (logisticTask?.data?.source?.name) {
      setName(logisticTask?.data?.source?.name);
    }

    if (logisticTask?.data?.source?.startTime) {
      setStartTime(new Date(logisticTask?.data?.source?.startTime));
    }

    if (logisticTask?.data?.source?.speed) {
      setSpeed(`${logisticTask?.data?.source?.speed}`);
    }

    if (logisticTask?.data?.source?.timeToWork) {
      setTimeToWork(`${logisticTask?.data?.source?.timeToWork}`);
    }

    const startTrack = extractCoordinates(logisticTask?.data?.source?.startOfTrack)
      .map((coords) => fromLonLat(coords));
    const filishTrack = extractCoordinates(logisticTask?.data?.source?.finishOfTrack)
      .map((coords) => fromLonLat(coords));

    if (startTrack.length) {
      setInitialCoords(0, startTrack);
    }

    if (startTrack.length) {
      setInitialCoords(1, filishTrack);
    }

    if (logisticTask?.data?.solution) {
      setShortcut(logisticTask?.data?.solution
        ?.trackPoints?.map((el) => fromLonLat([el.lon, el.lat])));
    }
  }, [logisticTask?.data?.source]);

  const stopDraw = useCallback((index, newCoords) => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: false,
      }));
      newGeometries[index] = {
        ...prev[index],
        isCreated: true,
        isStartDraw: false,
        isDelete: false,
        isCanceledDraw: false,
        isCanceledModify: false,
        coords: newCoords,
      };
      return newGeometries;
    });
    setErrorsForm((prev) => ({
      ...prev,
      [index]: false,
    }));
  }, []);

  const onSelect = useCallback((id) => {
    setGeometries((prev) => {
      const newGeometries = prev.map((geometry) => ({
        ...geometry,
        disabledControls: true,
      }));
      newGeometries[id] = {
        ...prev[id],
        isSelected: true,
        isCanceledModify: false,
        disabledControls: false,
      };

      return newGeometries;
    });
  }, []);

  const handleChangeDate = (newValue) => {
    setStartTime(newValue);
    setErrorsForm((prev) => ({
      ...prev,
      startTime: newValue === null
    }));
  };

  const isValidForm = () => {
    const errors = {
      name: name.trim() === '',
      startTime: startTime === null,
      speed: speed.trim() === '',
      timeToWork: timeToWork.trim() === '',
    };
    geometries.forEach((geometry) => {
      errors[geometry.id] = !geometry.isCreated;
    });
    setErrorsForm((prev) => ({
      ...prev,
      ...errors,
    }));
    const errorsKeys = Object.keys(errors);
    for (let i = 0; i < errorsKeys.length; i += 1) {
      if (errors[errorsKeys[i]]) {
        return false;
      }
    }
    return true;
  };

  const onClickCreateShortcut = () => {
    if (isValidForm()) {
      const body = {
        source: {
          name,
          startTime,
          speed,
          timeToWork,
          startOfTrack: `LINESTRING(${geometries[0].coords.map((coord) => toLonLat(coord)).map((e) => `${e[0]} ${e[1]}`).toString()})`,
          finishOfTrack: `LINESTRING(${geometries[1].coords.map((coord) => toLonLat(coord)).map((e) => `${e[0]} ${e[1]}`).toString()})`
        }
      };
      getLogisticTaskSolution({ body }).then(() => {
        patchLogisticTaskSolve();
      });
    }
  };

  // Функция создания маршрута
  const onClickCreateRoute = useCallback(() => {
    postRouteRequest({
      body: {
        routePoints: solveLogisticTask?.data?.solution?.routePoints
          || logisticTask?.data?.solution?.routePoints,
        trackPoints: solveLogisticTask?.data?.solution?.trackPoints
          || logisticTask?.data?.solution?.trackPoints,
        nameOfRoute: name,
        companyId: solveLogisticTask?.data?.companyId || logisticTask?.data?.companyId,
        totalTime: solveLogisticTask?.data?.solution?.totalTime
          || logisticTask?.data?.solution?.totalTime
      }
    });
  }, [solveLogisticTask, name, postRouteRequest]);

  useEffect(() => {
    if (solveLogisticTask?.data) {
      setShortcut(solveLogisticTask?.data?.solution
        ?.trackPoints?.map((el) => fromLonLat([el.lon, el.lat])));
    }
  }, [solveLogisticTask]);

  useEffect(() => {
    if (postRouteResult?.isSuccess) {
      if (postRouteResult?.data?.data?.company?.companyId
          && postRouteResult?.data?.data?.vehicleRouteId) {
        navigate(
          `/companies/${postRouteResult?.data?.data?.company?.companyId}/routes/edit/${postRouteResult?.data?.data?.vehicleRouteId}`
        );
      }
    }
  }, [postRouteResult]);

  if (logisticTask.isLoading) {
    return (
      <Loading />
    );
  }

  if (logisticTask.isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Ошибка</AlertTitle>
        Ошибка при загрузке данных
      </Alert>
    );
  }

  return (
    <Box className={style.form}>
      <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
        Построение оптимального маршрута движения по ограниченной территории
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px', width: '100%' }}>
        <Box sx={{ width: '100%', position: "relative" }}>
          {solveLogisticTask?.status === "pending" && (
          <Box className={style.loaderMap}>
            <CircularProgress />
          </Box>
          )}
          <GeoMapWithTiles
            styles={style.geoMap}
            extent={extent}
            isOffButtonFollowMode
          >
            {geometries.map((geometry) => (
              <LineVectorLayer
                key={geometry.id}
                id={geometry.id}
                isStartDraw={geometry.isStartDraw}
                stopDraw={stopDraw}
                isSelected={geometry.isSelected}
                isCreated={geometry.isCreated}
                isCanceledDraw={geometry.isCanceledDraw}
                isCanceledModify={geometry.isCanceledModify}
                zIndex={10}
                isChangeable
                isSelectable
                isDelete={geometry.isDelete}
                onSelect={onSelect}
                onDeselect={onDeselect}
                color={geometry.color}
                updateCoords={updateCoords}
                initialCoords={geometry.initialCoords}
              />
            ))}
            <LineVectorLayer
              zIndex={3}
              coordinates={shortcut}
              width={2}
            />
            <PolygonVectorLayer
              zIndex={2}
              coordinates={mainAreaCoords}
              strokeColor={green[500]}
              fillColor={alpha(green[500], 0.2)}
            />
            {obstacles?.map((obstacle) => (
              <PolygonVectorLayer
                key={obstacle.id}
                zIndex={10}
                coordinates={obstacle.coordinates}
                strokeColor={red[400]}
                strokeWidth={0}
                fillColor={red[400]}
              />
            ))}

            {solution
              ? solution.map((pointMarker, i) => (
                <MarkerVectorLayer
                  zIndex={11}
                  key={`point-number-${i + 1}`}
                  coordinates={pointMarker.coordinates}
                  point
                  numerary
                  text={`${i + 1}`}
                  tooltipText={pointMarker.time}
                />
              )) : points?.map((pointMarker) => (
                <MarkerVectorLayer
                  zIndex={9}
                  key={pointMarker.id}
                  coordinates={pointMarker.coordinates}
                  point
                />
              ))}
          </GeoMapWithTiles>
        </Box>
        <Box sx={{
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          justifyContent: 'space-between'
        }}
        >
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
          >
            <TextField
              id="name"
              size="small"
              label="Название маршрута/задачи"
              value={name}
              variant="standard"
              onChange={(event) => {
                setName(event.target.value);
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
              sx={{ maxWidth: '400px' }}
              error={errorsForm.name}
              helperText={errorsForm.name ? EMPTY_ERROR : null}
              onBlur={(event) => {
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
            />
            <Stepper orientation="vertical">
              {geometries.map((geometry) => (
                <Step key={geometry.id} active>
                  <StepLabel
                    icon={(
                      <StepIcon icon={geometry.pointName} sx={{ color: geometry.color }} />
                    )}
                    // onMouseEnter={() => setHoverStep(geometry.id)}
                    // onMouseLeave={() => setHoverStep(null)}
                  >
                    <Box
                      sx={{
                        height: '30px',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      {geometry.label}
                      {!geometry.isCreated
                        ? (
                          <Chip
                            color="default"
                            label="не указано"
                            size="small"
                            sx={{
                              fontWeight: 'normal'
                            }}
                          />
                        )
                        : (
                          <Chip
                            color="success"
                            label="указано"
                            size="small"
                            sx={{
                              fontWeight: 'normal'
                            }}
                          />
                        )}
                      <GeometryItemControls
                        setGeometries={setGeometries}
                        id={geometry.id}
                        isStartDraw={geometry.isStartDraw}
                        isCreated={geometry.isCreated}
                        isSelected={geometry.isSelected}
                        stopDraw={stopDraw}
                        onDeselect={onDeselect}
                        disabledControls={geometry.disabledControls}
                        isBlockDraw={geometry.coords?.length < 2}
                      />
                    </Box>
                  </StepLabel>
                  {errorsForm[geometry.id]
                    ? (
                      <StepContent>
                        <FormHelperText error>
                          Укажите
                          {' '}
                          {geometry.label}
                          {' '}
                          на карте
                        </FormHelperText>
                      </StepContent>
                    )
                    : null}
                </Step>
              ))}
            </Stepper>
            <DateTimePicker
              label="Начало движения"
              name="startTime"
              value={startTime}
              onChange={handleChangeDate}
              error={errorsForm.startTime}
              helperText={errorsForm.startTime ? EMPTY_ERROR : null}
              onBlur={(event) => {
                setErrorsForm((prev) => ({
                  ...prev,
                  [event.target.id]: event.target.value === null
                }));
              }}
            />
            <TextField
              id="speed"
              size="small"
              fullWidth
              label="Cредняя скорость"
              value={speed}
              variant="standard"
              onChange={(event) => {
                setSpeed(event.target.value);
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">км/ч</InputAdornment>
              }}
              InputLabelProps={{ shrink: true, error: false }}
              error={errorsForm.speed}
              helperText={errorsForm.speed ? EMPTY_ERROR : null}
              onBlur={(event) => {
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
            />
            <TextField
              id="timeToWork"
              size="small"
              label="Время нахождения в каждой точке"
              fullWidth
              value={timeToWork}
              variant="standard"
              onChange={(event) => {
                setTimeToWork(event.target.value);
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">мин</InputAdornment>
              }}
              InputLabelProps={{ shrink: true, error: false }}
              error={errorsForm.timeToWork}
              helperText={errorsForm.timeToWork ? EMPTY_ERROR : null}
              onBlur={(event) => {
                setErrorsForm((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
              }}
            />
          </Box>
          <Box className={style.mainBtns}>
            <Button
              variant="contained"
              disabled={solutionLogisticTask.isLoading || solveLogisticTask?.status === "pending"}
              onClick={onClickCreateShortcut}
            >
              {solutionLogisticTask.isLoading ? 'Загрузка...' : 'Проложить путь'}
            </Button>
            <Button
              variant="contained"
              disabled={!shortcut || solveLogisticTask?.status === "pending"}
              onClick={onClickCreateRoute}
            >
              Создать маршрут для ТС
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

LogisticTaskEditorWidget.propTypes = {
  logisticTask: PropTypes.shape({
    data: PropTypes.shape({
      source: PropTypes.shape({
        track: PropTypes.string,
        controlPoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        })),
        obstacles: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
        startOfTrack: PropTypes.string,
        finishOfTrack: PropTypes.string,
        speed: PropTypes.number,
        timeToWork: PropTypes.number,
        startTime: PropTypes.string,
      }),
      companyId: PropTypes.number,
      solution: PropTypes.shape({
        trackPoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        })),
        routePoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
          arriveTime: PropTypes.string,
          leaveTime: PropTypes.string,
          orderNum: PropTypes.number,
        })),
        totalTime: PropTypes.number,
      })
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  getLogisticTaskSolution: PropTypes.func.isRequired,
  solutionLogisticTask: PropTypes.shape({
    data: PropTypes.shape({
      source: PropTypes.shape({
        track: PropTypes.string,
        controlPoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        })),
        obstacles: PropTypes.arrayOf(PropTypes.string),
      }),
      solution: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    status: PropTypes.string,
  }),
  patchLogisticTaskSolve: PropTypes.func.isRequired,
  solveLogisticTask: PropTypes.shape({
    status: PropTypes.string,
    data: {}
  }).isRequired,
  postRouteRequest: PropTypes.func.isRequired,
  postRouteResult: PropTypes.shape({
    isSuccess: {},
    data: {}
  }).isRequired,
};

LogisticTaskEditorWidget.defaultProps = {
  logisticTask: null,
  solutionLogisticTask: null,
};

export default LogisticTaskEditorWidget;
