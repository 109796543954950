import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const alertsResourceEnum = {
  beginTime: "beginTime"

};

// Инициализация стейта в сторе
const alertsResourceInitialState = {
  [alertsResourceEnum.beginTime]: null
};
// Типизация стора
export const alertsResourceType = {
  [alertsResourceEnum.beginTime]: PropTypes.string
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const alertsResourceSlice = createSlice({
  name: `${reducerPath.alertsResource}/counter`,
  initialState: alertsResourceInitialState,
  reducers: {}
});

export const alertsResourceReducer = alertsResourceSlice.reducer;
