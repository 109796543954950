// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import vehicleWeekMileagesFromDtoService from "../services/data/vehicle-week-mileages-from-dto.service";
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vehicleWeekMileagesResourceApi = createApi({
  reducerPath: `${reducerPath.vehicleWeekMileages}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/vehicle-week-mileages`),
  endpoints: (builder) => ({
    getVehicleWeekMileagesResource: builder.query({
      query: ({ params }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.GET }),
        params,
        validateStatus: checkStatus,
      }),
      transformResponse: vehicleWeekMileagesFromDtoService
    }),
    postVehicleWeekMileagesResource: builder.mutation({
      query: ({ body }) => ({
        ...createHeadRequest({ url: ``, method: HttpMethods.POST }),
        body,
        validateStatus: checkStatus,
      }),

      transformResponse: vehicleWeekMileagesFromDtoService
    }),
    patchVehicleWeekMileagesResource: builder.mutation({
      query: ({ body, id }) => ({
        ...createHeadRequest({ url: `/${id}`, method: HttpMethods.PATCH }),
        body,
        validateStatus: checkStatus,
      }),

      transformResponse: vehicleWeekMileagesFromDtoService
    }),
  }),
});

export const {
  usePostVehicleWeekMileagesResourceMutation,
  useLazyGetVehicleWeekMileagesResourceQuery,
  usePatchVehicleWeekMileagesResourceMutation,
  useGetVehicleWeekMileagesResourceQuery,
} = vehicleWeekMileagesResourceApi;
