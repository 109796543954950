import React, { useEffect, useState } from 'react';
import {
  Box, Button, Chip, FormControl, InputLabel, MenuItem,
  Paper, Select, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import style from './CompanyPaymentsPanel.module.css';
import companyPaymentsApi from '../../../entities/companies/companyPayments/redux/companyPayments.api';

const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

const monthsRu = [
  { id: 0, name: 'Январь' },
  { id: 1, name: 'Февраль' },
  { id: 2, name: 'Март' },
  { id: 3, name: 'Апрель' },
  { id: 4, name: 'Май' },
  { id: 5, name: 'Июнь' },
  { id: 6, name: 'Июль' },
  { id: 7, name: 'Август' },
  { id: 8, name: 'Сентябрь' },
  { id: 9, name: 'Октябрь' },
  { id: 10, name: 'Ноябрь' },
  { id: 11, name: 'Декабрь' },
];
const createMonthCalendar = (currentDate) => {
  const currentMonth = currentDate.getMonth();
  const currentYear = new Date(currentDate).getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay();

  // Дни недели, относящиеся к соседним месяцам
  const blankCells = new Array((firstDayOfWeek + 6) % 7).fill({ day: null });

  const daysOfMonthsCells = Array.from(Array(daysInMonth)).map((_, index) => ({
    day: index + 1,
  }));

  const allCells = [...blankCells, ...daysOfMonthsCells];

  const countRows = Math.ceil(allCells.length / 7);
  // Разделение всех ячеек построчно
  const slicedCells = Array.from(Array(countRows)).map((_, index) => ({
    rowId: index,
    cells: allCells.slice(index * 7, (index * 7) + 7).map((cell, i) => ({ ...cell, cellId: i }))
  }));
  return slicedCells;
};

const createYearsList = (currentYear, countYears) => {
  const newYears = [];
  for (let i = currentYear - countYears; i <= currentYear; i += 1) {
    newYears.push(i);
  }
  return newYears;
};

const countYearsForSelected = 50;
const dateNow = new Date();
const yearNow = new Date(dateNow).getFullYear();
const monthNow = dateNow.getMonth();
const years = createYearsList(yearNow, countYearsForSelected);

// Отображает календарь с платежами и кнопки изменения показанного периода (год и месяц)
function CompanyPaymentsPanel({ companyAccount }) {
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [selectedMonth, setSelectedMonth] = useState(monthNow);
  const [cellsCalendar, setCellsCalendar] = useState(
    createMonthCalendar(new Date(yearNow, monthNow))
  );
  const [selectedDate, setSelectedDate] = useState(new Date(yearNow, monthNow));
  const [payments, setPayments] = useState([]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const applyDate = (y, m) => {
    const newDate = new Date(y, m);
    if (selectedDate.getTime() !== newDate.getTime()) {
      setPayments([]);
      setSelectedDate(newDate);
    }
  };

  const companyPayments = companyPaymentsApi.useGetCompanyPaymentsQuery({
    params: {
      'companyAccount.equals': companyAccount?.companyAccountId,
      'payDate.greaterThanOrEqual': selectedDate.toISOString(),
      'payDate.lessThan': new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1).toISOString(),
    }
  });

  useEffect(() => {
    const result = [];
    companyPayments?.data?.forEach((payment) => {
      const day = new Date(payment.payDate).getDate();
      const signPay = (Number(payment.sum) < 0) ? 'dec' : 'inc';
      result[day] = {
        ...result[day],
        [signPay]: Number(result[day]?.[signPay] || 0) + Math.abs(Number(payment.sum)),
      };
    });
    setPayments(result);
  }, [companyPayments?.data]);

  useEffect(() => {
    setCellsCalendar(createMonthCalendar(selectedDate));
  }, [selectedDate]);

  return (
    <Box className={style.companyPaymentsPanel}>
      <Box className={style.constrols}>
        <FormControl
          size="small"
          sx={{ minWidth: 120 }}
        >
          <InputLabel>Год</InputLabel>
          <Select
            label="Год"
            value={selectedYear}
            onChange={handleChange}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          sx={{ minWidth: 120 }}
        >
          <InputLabel>Месяц</InputLabel>
          <Select
            label="Месяц"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {monthsRu.map((monthRu) => (
              <MenuItem
                key={monthRu.id}
                value={monthRu.id}
              >
                {monthRu.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={() => applyDate(selectedYear, selectedMonth)}
        >
          Показать
        </Button>
      </Box>
      <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
        Показанный период:
        {' '}
        {selectedDate.toLocaleString('ru', { month: 'long' })}
        {' '}
        {selectedDate.getFullYear()}
        {' '}
        г.
      </Typography>
      <TableContainer component={Paper}>
        <Table className={style.table}>
          <TableHead>
            <TableRow
              className={style.tableHead}
            >
              {daysOfWeek.map((dayOfWeek) => (
                <TableCell
                  key={dayOfWeek}
                  className={style.cellTableHead}
                  align="center"
                >
                  <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
                    {dayOfWeek}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cellsCalendar.map((row) => (
              <TableRow key={row.rowId}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={cell.cellId}
                    className={style.cellTable}
                  >
                    <Box className={style.cellBox}>
                      <Box className={style.payments}>
                        {cell.day && payments[cell.day]?.inc
                          ? (
                            <Chip
                              label={`+${payments[cell.day].inc}`}
                              color="success"
                              size="small"
                            />
                          )
                          : null}
                        {cell.day && payments[cell.day]?.dec
                          ? (
                            <Chip
                              label={`-${payments[cell.day].dec}`}
                              color="warning"
                              size="small"
                            />
                          )
                          : null}
                      </Box>
                      <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
                        {cell.day || ''}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

CompanyPaymentsPanel.propTypes = {
  companyAccount: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any)
  ])),
};

CompanyPaymentsPanel.defaultProps = {
  companyAccount: null,
};

export default CompanyPaymentsPanel;
