import {
  Box, Divider, IconButton, ListItem, TablePagination, Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import style from './CompanyRoutesWidget.module.css';
import VehicleRoute from '../../entities/vehicles/VehiclesRoute/VehicleRoute';
import CopyButton from '../../shared/ui/CopyButton/CopyButton';
import DeleteButton from '../../shared/ui/DeleteButton/DeleteButton';
import Loading from '../../shared/ui/Loading/Loading';
import EditButton from '../../shared/ui/EditButton/EditButton';
import NoData from '../../shared/ui/NoData/NoData';
import CancelButton from '../../shared/ui/CancelButton/CancelButton';
import RouteActivateControl from '../../features/vehicles/RouteActivateControl/RouteActivateControl';
import ActivateButton from "../../shared/ui/ActivateButton/ActivateButton";
import { ROUTE_STATUSES } from '../../entities/vehicles/VehiclesRoute/statuses';

// eslint-disable-next-line no-undef
const createRandomId = () => crypto.randomUUID();

// Выбирает отображаемые точки маршрута в соответсии с выбранной точкой
const selectDisplayedPoints = (selectIndex, arr) => {
  // Индекс последней точки
  const lastIndex = arr.length - 1;
  // Элемент между отображаемыми точками
  const insertedItem = { collapsePoint: true };
  // Диапазон отображаемых точек
  const range = 4;
  // Максимальное кол-во отображаемых точек
  const maxCount = 7;
  let result = [];
  // Если кол-во точек меньше максимального, просто возвращаем все точки
  if (arr.length <= maxCount) return arr;
  // Если выбранная точка меньше диапазона (вставка в конец)
  if (selectIndex < range) {
    result = [
      ...arr.slice(0, 5),
      { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 5 },
      arr[lastIndex],
    ];
  } else if (lastIndex - range < selectIndex) {
    // Если выбранная точка больше отображаемого диапазона (вставка в начало)
    result = [
      arr[0],
      { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 5 },
      ...arr.slice(lastIndex - range),
    ];
  } else {
    // Если выбранная точка не попадает в диапазон (вставка в середину)
    result = [
      arr[0],
      { ...insertedItem, id: createRandomId(), countPoints: selectIndex - 2 },
      arr[selectIndex - 1],
      arr[selectIndex],
      arr[selectIndex + 1],
      { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 2 - selectIndex },
      arr[lastIndex],
    ];
  }
  return result;
};

// Показ маршрутов
function CompanyRoutesWidget(props) {
  const {
    editRoute,
    copyRoute,
    totalCount,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    vehicleRoutes,
    onClickDelete,
    cancelRoute,
    activateRoute,
    planRoute,
    onClickActivateRoute,
  } = props;

  const [displayedRoutes, setDisplayedRoutes] = useState(null);

  // Изменение отображаемых точек при смене точек маршрутов
  useEffect(() => {
    let newDisplayedRoutes = null;
    if (vehicleRoutes) {
      newDisplayedRoutes = [];
      vehicleRoutes.forEach((route) => {
        let isBack = false;
        let newPoints = [];
        if (route.routePoints.length) {
          // Ищем первую точку, до которой не доехало ТС
          let carIndex = route.routePoints.findIndex((item) => !item.isAttended);
          const carItem = { car: true, id: createRandomId() };
          if (carIndex === -1) {
            // Все точки пройдены, ищем первую пройденную точку для обратного маршрута
            carIndex = route.routePoints.findIndex((item) => item.isBackAttended);
            isBack = true;
            if (carIndex === -1) {
              // Пройденной на обратном пути точки нет, машина в конечной точке
              carIndex = route.routePoints.length;
            }
          }
          const points = route.routePoints.slice();
          // Добавляем по индексу точки новую точку ТС в массив отображаемых точек
          points.splice(carIndex, 0, carItem);
          // points[carIndex + 1] = { ...points[carIndex + 1], nextPoint: true };

          // Получаем отображаемые точки
          newPoints = selectDisplayedPoints(carIndex, points);
        } else {
          newPoints = [
            { id: createRandomId(), name: 'Не выбрано' },
            { id: createRandomId(), name: 'Не выбрано' },
          ];
        }
        newDisplayedRoutes.push({ ...route, isBack, points: newPoints });
      });
    }
    setDisplayedRoutes(newDisplayedRoutes);
  }, [vehicleRoutes]);

  return (
    <Box className={style.companyRoutesWidget}>
      {!displayedRoutes || !vehicleRoutes
        ? <Loading />
        : (
          <Box>
            {!displayedRoutes.length
              ? <NoData />
              : displayedRoutes.map((displayedRoute) => (
                <ListItem
                  sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                  key={displayedRoute.unicId}
                >
                  <VehicleRoute
                    vehicleRoute={displayedRoute}
                    buttons={(
                      <>
                        <DeleteButton
                          onDelete={() => onClickDelete(displayedRoute)}
                        />
                        <CopyButton
                          onCopy={() => copyRoute(displayedRoute)}
                          title="Маршрут на основе этого"
                        />
                        <EditButton
                          onEdit={() => editRoute(displayedRoute)}
                        />
                        {displayedRoute.status === ROUTE_STATUSES.VROUTE_STATUS_ACTIVE && (
                          <CancelButton
                            onClick={() => cancelRoute(displayedRoute)}
                          />
                        )}
                        {displayedRoute.status === ROUTE_STATUSES.VROUTE_STATUS_CLOSED
                          ? (
                            <RouteActivateControl
                              vehicleRoute={displayedRoute}
                              activateRoute={planRoute}
                              title="Перепланировать маршрут"
                            >
                              <Box>
                                <Tooltip title="Перепланировать">
                                  <span>
                                    <IconButton
                                      color="primary"
                                    >
                                      <AlarmOnIcon color="primary" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </RouteActivateControl>
                          )
                          : displayedRoute.status !== ROUTE_STATUSES.VROUTE_STATUS_ACTIVE && (
                            <RouteActivateControl
                              vehicleRoute={displayedRoute}
                              activateRoute={activateRoute}
                              onClick={(showControl) => (
                                onClickActivateRoute(displayedRoute, showControl)
                              )}
                            >
                              <ActivateButton />
                            </RouteActivateControl>
                          )}
                      </>
                    )}
                  />
                  <Divider flexItem />
                </ListItem>
              ))}
            <Box className={style.pagination}>
              <TablePagination
                rowsPerPageOptions={[5, 15, 30]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        )}
    </Box>
  );
}

CompanyRoutesWidget.propTypes = {
  editRoute: PropTypes.func.isRequired,
  copyRoute: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    nameVehicle: PropTypes.string,
    numVehicle: PropTypes.string,
    startTime: PropTypes.instanceOf(Date),
    status: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  }),

  vehicleRoutes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]))),
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  cancelRoute: PropTypes.func.isRequired,
  activateRoute: PropTypes.func.isRequired,
  planRoute: PropTypes.func.isRequired,
  onClickActivateRoute: PropTypes.func.isRequired,
};

CompanyRoutesWidget.defaultProps = {
  filters: null,
  vehicleRoutes: null,
};

export default CompanyRoutesWidget;
