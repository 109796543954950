import React, { useEffect, useState } from 'react';
import {
  Alert, AlertTitle, Box,
  Button, FormControl, IconButton, Input, InputAdornment, InputLabel
} from '@mui/material';
import PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import style from './ChangePasswordPanel.module.css';

// Отображает панель "Моя учётная запись"
function ChangePasswordPanel({ postChangePasswordParams }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isErr, setErr] = useState(false);
  const [isErrRepeat, setErrRepeat] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    repeatWewPassword: "",
  });

  useEffect(() => {
    setErr(false);
    setIsCorrect(false);
    setErrRepeat(false);
  }, [data]);

  const handleChangePassword = () => {
    if (data.repeatWewPassword === data.newPassword) {
      postChangePasswordParams({
        body: data
      }).then((e) => {
        if (e?.error) {
          setErr(true);
        } else {
          setIsCorrect(true);
          setIsEdit(false);
        }
      });
    } else {
      setErrRepeat(true);
    }
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewRepeatPassword, setShowNewRepeatPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className={style.AccountMainPanel}>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <InputLabel htmlFor="standard-old-password">Старый пароль</InputLabel>
        <Input
          id="standard-old-password"
          type={showOldPassword ? 'text' : 'password'}
          onChange={(e) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.currentPassword = e.target.value;
            setData(newData);
          }}
          value={data.currentPassword}
          disabled={!isEdit}
          sx={{ width: "100%" }}
          label="Старый пароль"
          variant="standard"
          error={isErr}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowOldPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
                )}
        />

      </FormControl>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <InputLabel htmlFor="standard-new-password">Новый пароль</InputLabel>
        <Input
          id="standard-new-password"
          type={showNewPassword ? 'text' : 'password'}
          onChange={(e) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.newPassword = e.target.value;
            setData(newData);
          }}
          value={data.newPassword || ''}
          disabled={!isEdit}
          sx={{ width: "100%" }}
          label="Новый пароль"
          variant="standard"
          error={isErrRepeat}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
                )}
        />

      </FormControl>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <InputLabel htmlFor="standard-new-password">Новый пароль</InputLabel>
        <Input
          id="standard-new-password"
          type={showNewRepeatPassword ? 'text' : 'password'}
          onChange={(e) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.repeatWewPassword = e.target.value;
            setData(newData);
          }}
          value={data.repeatWewPassword || ''}
          disabled={!isEdit}
          sx={{ width: "100%" }}
          label="Повторите пароль"
          variant="standard"
          error={isErrRepeat}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewRepeatPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
              >
                {showNewRepeatPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
                )}
        />

      </FormControl>
      {!isErr && isCorrect
          && (
          <Alert sx={{ width: "100%" }} severity="success">
            <AlertTitle>Успешно</AlertTitle>
            Ваш пароль был успешно изменен.
            Пожалуйста, используйте ваш новый пароль для доступа к
            {' '}
            <strong>vistar.su</strong>
          </Alert>
          )}
      {isErrRepeat
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Пароли не совпадают
            </Alert>
            )}
      {isErr
          && (
          <Alert sx={{ width: "100%" }} severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            Неверно указан старый пароль
          </Alert>
          )}
      <Box className={style.buttonsPanel}>
        {isEdit && (
        <Button
          onClick={handleChangePassword}
          variant="contained"
        >
          Сохранить
        </Button>
        )}
        {!isEdit && <Button onClick={() => { setIsEdit(true); }} variant="contained">Редактировать</Button>}
        {isEdit && (
        <Button
          onClick={() => {
            setIsEdit(false); setData({
              currentPassword: "",
              newPassword: ""
            });
          }}
          variant="contained"
        >
          Отмена
        </Button>
        )}
      </Box>
    </Box>
  );
}

ChangePasswordPanel.propTypes = {
  postChangePasswordParams: PropTypes.func.isRequired,
};

export default ChangePasswordPanel;
