import React from 'react';
import {
  Box,
  Divider,
  Fab,
  MenuItem,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import StyledMenu from '../../../shared/ui/StyledMenu/StyledMenu';

function SelectedItemsToolbar(props) {
  const {
    selected,
    resetSelected,
    onOpenAddTags,
    onOpenChangeTags,
    onDeleteTags,
    onAddRoute,
    onChangeRoute,
    onOpenQR,
    onOpenDownloadQR,
  } = props;
  const countSelected = selected.length;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpenTagsMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        flex: 1,
        zIndex: 300,
        display: 'flex',
        position: 'sticky',
        top: '10px',
      }}
    >
      <Zoom
        in={!!countSelected}
      >
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          open={isOpenTagsMenu}
          size="medium"
          onClose={handleClose}
          onClick={handleClick}
          sx={{
            zIndex: 300,
          }}
        >
          Действия
          <KeyboardArrowDownIcon fontSize="small" color="inherit" />
        </Fab>
      </Zoom>
      <Zoom
        in={!!countSelected}
      >
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          size="medium"
          onClose={handleClose}
          sx={{
            zIndex: 300,
            marginLeft: '20px',
          }}
        >
          Выбрано:
          {' '}
          {countSelected}
          <CloseIcon fontSize="small" color="inherit" onClick={() => resetSelected()} />
        </Fab>
      </Zoom>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={isOpenTagsMenu}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onOpenAddTags();
            handleClose();
          }}
          disableRipple
        >
          <AddIcon color="primary" />
          Добавить метки
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenChangeTags();
            handleClose();
          }}
          disableRipple
        >
          <AutorenewIcon color="primary" />
          Заменить метки
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteTags();
            handleClose();
          }}
          disableRipple
        >
          <CloseIcon color="primary" />
          Удалить метки
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onAddRoute();
            handleClose();
          }}
          disableRipple
        >
          <AddIcon color="primary" />
          Назначить маршрут
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChangeRoute();
            handleClose();
          }}
          disableRipple
        >
          <AutorenewIcon color="primary" />
          Изменить маршрут
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onOpenQR();
            handleClose();
          }}
          disableRipple
        >
          <QrCodeIcon color="primary" />
          Печать QR-кода
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenDownloadQR();
            handleClose();
          }}
          disableRipple
        >
          <FileDownloadIcon color="primary" />
          Выгрузка QR-кода
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

SelectedItemsToolbar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetSelected: PropTypes.func.isRequired,
  onOpenAddTags: PropTypes.func.isRequired,
  onOpenChangeTags: PropTypes.func.isRequired,
  onDeleteTags: PropTypes.func.isRequired,
  onAddRoute: PropTypes.func.isRequired,
  onChangeRoute: PropTypes.func.isRequired,
  onOpenQR: PropTypes.func.isRequired,
  onOpenDownloadQR: PropTypes.func.isRequired,
};

export default SelectedItemsToolbar;
