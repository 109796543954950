import React, {
  Children, cloneElement, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useGetAlertsResourceQuery } from "../redux/alertsResource.api";
// eslint-disable-next-line boundaries/element-types
import {
  useGetVehiclesRouteResourceQuery
} from "../../../vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AlertsResourceBll({
  children, vehicleId, setActiveRoute
}) {
  const { data: dataAlertsResource } = useGetAlertsResourceQuery(
    {
      params: {
        "vehicleId.equals": vehicleId
      }
    },
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    const newData = {};
    newData.dataAlertsResource = dataAlertsResource;
    setData(newData);
  }, [
    dataAlertsResource,
  ]);

  const { data: dataVehiclesRouteResource } = useGetVehiclesRouteResourceQuery({
    params: { "vehicleId.equals": 64102, "status.equals": "VROUTE_STATUS_ACTIVE" }
  });

  const props = {
    data,
  };

  setActiveRoute(dataVehiclesRouteResource?.data[0]?.name);

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

AlertsResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
  vehicleId: PropTypes.number,
  setActiveRoute: PropTypes.func.isRequired
};

AlertsResourceBll.defaultProps = {
  vehicleId: NaN,
};

export default AlertsResourceBll;
