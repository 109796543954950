const setPadStart = (e) => e.toString().padStart(2, '0');

function convertFormatDate(str) {
  if (str) {
    const newStr = JSON.parse(JSON.stringify(str));
    const newDate = new Date(newStr);
    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth();
    const newDay = newDate.getDate();
    const newHours = newDate.getHours();
    const newMinutes = newDate.getMinutes();
    const newSeconds = newDate.getSeconds();

    const name = setPadStart(newDate);
    const year = setPadStart(newYear);
    const month = setPadStart(newMonth + 1);
    const day = setPadStart(newDay);
    const hours = setPadStart(newHours);
    const minutes = setPadStart(newMinutes);
    const seconds = setPadStart(newSeconds);

    return {
      name,
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      toFormat: `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
    };
  }
  return '';
}

export default convertFormatDate;
