import React, {
  useCallback, useMemo
} from 'react';
import { Container } from '@mui/material';
import TabsPanels from '../../../shared/ui/TabsPanels/TabsPanels';
import CompanyTariffPlanPanel from '../../../panels/companies/CompanyTariffPlanPanel/CompanyTariffPlanPanel';
import useAuth from '../../../shared/lib/hooks/useAuth';
import companyAccountsApi from '../../../entities/companies/companyAccounts/redux/companyAccounts.api';
import CompanyPaymentsPanel from '../../../panels/companies/CompanyPaymentsPanel/CompanyPaymentsPanel';

// Отображает страницу "Финансы" по компнаии
function CompanyFinanceView() {
  const { companyAccount } = useAuth();

  const [putCompanyAccount] = companyAccountsApi.usePutCompanyAccountMutation();

  const putCompanyAccountParams = useCallback(
    ({ id, body }) => putCompanyAccount({
      id,
      body
    }),
    [putCompanyAccount]
  );

  const tabsFinance = useMemo(() => ([
    {
      name: 'Тарифный план',
      component: (
        <CompanyTariffPlanPanel
          companyAccount={companyAccount}
          putCompanyAccountParams={putCompanyAccountParams}
          loading={!companyAccount}
        />
      )
    },
    {
      name: 'Платежи',
      component: (
        <CompanyPaymentsPanel
          companyAccount={companyAccount}
        />
      )
    },
  ]), [companyAccount, putCompanyAccountParams]);

  return (
    <Container
      component="main"
      maxWidth="xl"
    >
      <TabsPanels tabs={tabsFinance} />
    </Container>
  );
}

export default CompanyFinanceView;
