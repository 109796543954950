import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import {
  BLOCK_MIN_WIDTH, COMMON_PADDING,
  ROUTE_BLOCK_HEIGHT,
  ROUTE_ICO_COLOR,
  WAREHOUSE_HEIGHT,
} from './constants';
import ButtonWithMenu from './ButtonWithMenu';
import DetailsDialog from './DetailsDialog';
import { logisticTasksBalanceResourceActions } from '../../../entities/logistic/logisticTasks/redux/logisticTasksBalance.slice';

export default function RouteItem(props) {
  const dispatch = useDispatch();
  const {
    vehicles,
    toField,
    toWarehouse,
    warehouseIndex,
    warehouseId,
    availableVehicles,
    fieldId,
    sx,
  } = props;

  const [grown, setGrown] = React.useState(true);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = React.useState(false);

  const [selectedVehicle, setSelectedVehicle] = React.useState({ item: null, onOk: null });

  const openVehicleDialog = (item, onOk, okLabel) => {
    setSelectedVehicle({ item, onOk, okLabel });
  };

  const closeVehicleDialog = () => {
    setSelectedVehicle({ item: null, onOk: null });
  };

  const removeRoute = () => {
    setGrown(false);
    setTimeout(() => {
      dispatch(logisticTasksBalanceResourceActions.removeRoute({ warehouseId, fieldId }));
    }, 300);
  };

  const addVehicle = (vehicle) => {
    dispatch(logisticTasksBalanceResourceActions.addVehicleToRoute({
      fieldId, warehouseId, vehicle
    }));
  };

  const removeVehicle = (vehicle) => {
    dispatch(logisticTasksBalanceResourceActions.removeVehicleFromRoute({
      fieldId, warehouseId, vehicle
    }));
  };

  const openRemoveDialog = () => {
    setIsRemoveDialogOpened(true);
  };

  const closeRemoveDialog = () => {
    setIsRemoveDialogOpened(false);
  };

  return (
    <>
      <Dialog
        open={isRemoveDialogOpened}
        onClose={closeRemoveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Вы уверены, что хотите удалить маршрут?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Все транспортные средства этого маршрута окажутся незаняты!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} autoFocus>Отмена</Button>
          <Button onClick={removeRoute}>Да</Button>
        </DialogActions>
      </Dialog>
      <Grow appear in={grown} timeout={300} easing="ease-in" style={{ transformOrigin: 'top center' }}>
        <Box
          sx={{
            bgcolor: '#d3dbe3',
            borderRadius: '4px',
            height: `${ROUTE_BLOCK_HEIGHT}px`,
            flexGrow: 1,
            minWidth: `${BLOCK_MIN_WIDTH}px`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            ...sx,
          }}
        >
          <IconButton sx={{ position: 'absolute', top: '-6px', right: '-5px' }} title="Удалить маршрут" onClick={openRemoveDialog}>
            <CloseIcon sx={{ color: ROUTE_ICO_COLOR, fontSize: '1rem' }} />
          </IconButton>
          <Box
            sx={{
              background: '#eef0f3', border: 'solid 1px', borderColor: ROUTE_ICO_COLOR, margin: '18px 2px 0', position: 'relative', borderRadius: '4px'
            }}
            py="3px"
          >
            <Typography
              sx={{
                fontSize: '1.25rem', textAlign: 'center', color: (theme) => (toField.inQueue > 1 ? theme.palette.error.main : 'inherit'), lineHeight: 1.2
              }}
              title="В очереди на поле"
            >
              {toField.inQueue || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.25rem', textAlign: 'center', lineHeight: 1.2
              }}
              title="В пути на поле"
            >
              {toField.onWay || 0}
            </Typography>
          </Box>
          <Box>
            <Box sx={{
              margin: '0 2px', position: 'relative', borderRadius: '4px'
            }}
            >
              <ButtonWithMenu
                sx={{
                  '& .MuiButton-root': {
                    padding: '0 2px',
                    width: '100%',
                    margin: 0,
                    color: 'text.primary',
                    minWidth: 0,
                  },
                  margin: 0,
                  padding: 0,
                }}
                type="button"
                items={vehicles}
                onItemClick={(item) => openVehicleDialog(item, () => removeVehicle(item), 'Удалить ТС с маршрута')}
                onRemoveItemClick={(item) => removeVehicle(item)}
                title="ТС на маршруте"
              >
                <Box
                  sx={{ width: '100%' }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.25rem', textAlign: 'center', color: (theme) => (!vehicles?.length ? theme.palette.error.main : 'inherit')
                    }}
                  >
                    {vehicles?.length || 0}
                  </Typography>
                </Box>
              </ButtonWithMenu>
            </Box>
            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
              <ButtonWithMenu
                sx={{ margin: '0 3px' }}
                items={availableVehicles}
                onItemClick={(item) => openVehicleDialog(item, () => addVehicle(item), 'Добавить ТС на маршрут')}
                onAddItemClick={(item) => addVehicle(item)}
                title="Добавить ТС на маршрут"
              >
                <AddCircleIcon sx={{ color: '#81a7cf' }} />
              </ButtonWithMenu>
            </Box>
          </Box>
          <Box
            sx={{
              background: '#eef0f3', border: 'solid 1px', borderColor: ROUTE_ICO_COLOR, margin: '0 2px 6px', position: 'relative', borderRadius: '4px'
            }}
            py="3px"
          >
            <Box
              sx={{
                fontSize: '1.25rem', textAlign: 'center', lineHeight: 1.3
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.25rem', textAlign: 'center', lineHeight: 1.2
                }}
                title="В пути на склад"
              >
                {toWarehouse.onWay || 0}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.25rem', textAlign: 'center', color: (theme) => (toWarehouse.inQueue > 1 ? theme.palette.error.main : 'inherit'), lineHeight: 1.2
                }}
                title="В очереди на складе"
              >
                {toWarehouse.inQueue || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            background: ROUTE_ICO_COLOR, position: 'absolute', left: '50%', top: `-${COMMON_PADDING * 3 + 10}px`, width: '2px', height: `${COMMON_PADDING * 3 + 29}px`, marginLeft: '-1px'
          }}
          />
          <KeyboardArrowUpIcon sx={{
            color: ROUTE_ICO_COLOR, position: 'absolute', left: '50%', top: `-${COMMON_PADDING * 3 + 20}px`, marginLeft: '-12px'
          }}
          />
          <Box sx={{
            background: ROUTE_ICO_COLOR, position: 'absolute', left: '50%', bottom: `-${COMMON_PADDING * 3 + 10 + (WAREHOUSE_HEIGHT + COMMON_PADDING) * warehouseIndex}px`, width: '2px', height: `${COMMON_PADDING * 3 + 17 + (WAREHOUSE_HEIGHT + COMMON_PADDING) * warehouseIndex}px`, marginLeft: '-1px'
          }}
          />
          <KeyboardArrowDownIcon sx={{
            color: ROUTE_ICO_COLOR, position: 'absolute', left: '50%', bottom: `-${COMMON_PADDING * 3 + 20 + (WAREHOUSE_HEIGHT + COMMON_PADDING) * warehouseIndex}px`, marginLeft: '-12px'
          }}
          />
          <DetailsDialog
            type="vehicle"
            item={selectedVehicle?.item}
            onOk={selectedVehicle?.onOk}
            closeItemDialog={closeVehicleDialog}
            okLabel={selectedVehicle?.okLabel}
          />
        </Box>
      </Grow>
    </>
  );
}

RouteItem.propTypes = {
  // id: PropTypes.string.isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, name: PropTypes.string
  })),
  availableVehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, name: PropTypes.string
  })),
  toField: PropTypes.shape({
    onWay: PropTypes.number,
    inQueue: PropTypes.number,
  }),
  toWarehouse: PropTypes.shape({
    onWay: PropTypes.number,
    inQueue: PropTypes.number,
  }),
  warehouseIndex: PropTypes.number.isRequired,
  warehouseId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.any,
};

RouteItem.defaultProps = {
  vehicles: [],
  availableVehicles: [],
  toField: {
    onWay: 0,
    inQueue: 0,
  },
  toWarehouse: {
    onWay: 0,
    inQueue: 0,
  },
  sx: {},
};
