import {
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  deleteVehicleRoute, getVehicleRoutesV2, patchVehicleRoute, putVehicleRouteReeplan
} from '../../../shared/api/api';
import FiltersRoutesWidget from '../../../widgets/vehicles/FiltersRoutesWidget';
import RoutesToolbarWidget from '../../../widgets/vehicles/RoutesToolbarWidget';
import style from './CompanyRoutesPanel.module.css';
import VehicleRouteFormWidget from '../../../widgets/vehicles/VehicleRouteFormWidget';
import CompanyRoutesWidget from '../../../widgets/companies/CompanyRoutesWidget';
import CompanyRoutesTablePanel from "./CompanyRoutesTablePanel";
import {
  usePostVehicleRouteCopyMutation,
  usePostVehicleRouteFileMutation
} from "../../../entities/vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.api";
import transformRoute from '../../../entities/vehicles/VehiclesRoute/vehiclesRoutes/services/data/transformRoute';
import useShowError from '../../../shared/lib/hooks/useShowError';
import Loading from '../../../shared/ui/Loading/Loading';
import { ROUTE_STATUSES, statuses as statusesRoute } from '../../../entities/vehicles/VehiclesRoute/statuses';

// Список статусов для комбобокса
export const statuses = statusesRoute;

const initialFilters = { status: [statuses[0], statuses[1]] };

// Панель маршрутов с точками и тулбаром, фильтрацией
function CompanyRoutesPanel({ companyId }) {
  const [
    postRouteCopyRequest,
    { isRouteCopyLoading }
  ] = usePostVehicleRouteCopyMutation();

  // Список маршрутов
  const [vehicleRoutes, setVehicleRoutes] = useState(null);

  const [openCloneRoute, setOpenCloneRoute] = useState(null);
  const [isCopyRoutePoints, setIsCopyRoutePoints] = useState(true);
  const [isCopyRouteTrack, setIsCopyRouteTrack] = useState(true);
  const [isCopyPassengers, setIsCopyPassengers] = useState(true);
  const [isCopyCargos, setIsCopyCargos] = useState(true);

  const [company, setCompany] = useState(companyId);

  // Состояние панели фильтрации (скрыта/показана)
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [tableMode, setTableMode] = useState(false);

  const toggleTableMode = () => {
    setTableMode((prev) => !prev);
  };

  const [filters, setFilters] = useState(initialFilters);

  // Количество маршрутов
  const [totalCount, setTotalCount] = useState(0);
  // Строк на странице
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Текущая страница
  const [page, setPage] = useState(0);

  const [update, setUpdate] = useState(false);

  // Смена страницы
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  // Изменение кол-ва отображаемых строк на странице
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
    setCompany(companyId);
    setVehicleRoutes(null);
  }, [companyId]);

  // Обновление vehicleRoutes
  useEffect(() => {
    setVehicleRoutes(null);
    let paramsFilters = [];
    if (filters) {
      if (filters.vehicleName) {
        paramsFilters = [
          ...paramsFilters,
          { name: 'vehicleName.contains', value: filters.vehicleName.trim() },
        ];
      }
      if (filters.vehicleNum) {
        paramsFilters = [
          ...paramsFilters,
          { name: 'vehicleNum.contains', value: filters.vehicleNum.trim() },
        ];
      }
      if (filters.name) {
        paramsFilters = [
          ...paramsFilters,
          { name: 'name.contains', value: filters.name.trim() },
        ];
      }
      if (filters.status) {
        if (Array.isArray(filters.status)) {
          paramsFilters = [
            ...paramsFilters,
            ...filters.status.map((statusItem) => ({
              name: 'status.in', value: statusItem.value,
            }))
          ];
        } else {
          paramsFilters = [
            ...paramsFilters,
            {
              name: 'status.in', value: filters?.status?.value,
            }
          ];
        }
      }
      if (filters.startTime) {
        paramsFilters = [
          ...paramsFilters,
          { name: 'startTime.greaterThanOrEqual', value: filters.startTime.toISOString() },
        ];
      }
      if (filters.finishTime) {
        paramsFilters = [
          ...paramsFilters,
          { name: 'finishTime.lessThanOrEqual', value: filters.finishTime.toISOString() },
        ];
      }
    }

    const params = [
      ...paramsFilters,
      { name: 'companyId.equals', value: company },
      { name: 'page', value: page },
      { name: 'size', value: rowsPerPage },
      { name: 'sort', value: 'startTime,asc' },
    ];
    getVehicleRoutesV2(params).then((res) => {
      setVehicleRoutes(res.routes);
      setTotalCount(res?.totalCount || 0);
    });
  }, [company, page, filters, rowsPerPage, update]);

  // Переключает состояние панели фильтрации
  const toogleFilters = () => {
    setIsFiltersOpen((prev) => !prev);
  };

  const confirm = useConfirm();
  const navigate = useNavigate();

  // Открывает форму маршрута
  const openRouteForm = () => {
    navigate(`create`);
  };
  const showError = useShowError();

  // Открытие формы для редактирования
  const editRoute = useCallback((vehicleRoute) => {
    navigate(`edit/${vehicleRoute.vehicleRouteId}`, { state: vehicleRoute });
  }, [navigate]);

  // Открытие формы для создание маршрута на основе выбранного
  const copyRoute = (vehicleRoute) => {
    setOpenCloneRoute(vehicleRoute);
  };

  const closeCloneRouteDialog = () => {
    setOpenCloneRoute(null);
    setIsCopyRoutePoints(true);
    setIsCopyRouteTrack(true);
    setIsCopyPassengers(true);
    setIsCopyCargos(true);
  };

  const handleCloseCloneRouteDialog = () => {
    closeCloneRouteDialog();
  };

  const handleOkCloneRouteDialog = () => {
    postRouteCopyRequest({
      id: openCloneRoute.id,
      params: {
        isCopyRoutePoints,
        isCopyRouteTrack,
        isCopyPassengers,
        isCopyCargos,
        status: ROUTE_STATUSES.VROUTE_STATUS_PLANNED,
      },
    }).then((res) => {
      closeCloneRouteDialog();
      const { data } = res.data;
      const newRoute = transformRoute(data);
      setUpdate((prev) => !prev);
      editRoute(newRoute);
    }).catch((error) => {
      showError('Ошибка при дублировании маршрута:', error);
    });
  };

  // Обновление запроса тела запроса фильтрации
  const handleFilter = (newFilters) => {
    setPage(0);
    setFilters(newFilters);
  };

  const [postVehicleRouteFileTriger, { isLoading }] = usePostVehicleRouteFileMutation();
  const postVehicleRouteFile = useCallback((selectedFile, fileName) => {
    if (selectedFile) {
      postVehicleRouteFileTriger({
        body: {
          fileName,
          file: selectedFile,
          companyId,
        }
      })
        .unwrap() // Извлеките успешный результат, если он есть
        .then((response) => {
          // передаю vehicleRoute
          const { data } = response;
          const newRoute = transformRoute(data);
          setUpdate((prev) => !prev);
          editRoute(newRoute);
        })
        .catch((error) => {
          showError('Ошибка при загрузке файла');
          // eslint-disable-next-line no-console
          console.error('Ошибка при загрузке файла:', error);
        });
    }
  }, [companyId, editRoute, showError, postVehicleRouteFileTriger]);

  // Отмена маршрута
  const cancelRoute = (vehicleRoute) => {
    const newStatus = ROUTE_STATUSES.VROUTE_STATUS_CLOSED;
    const body = {
      vehicleRouteId: vehicleRoute.vehicleRouteId,
      status: newStatus,
    };
    patchVehicleRoute(body).then((res) => {
      const isStatus = Boolean(filters.status.find((field) => field.value === newStatus));
      if (isStatus) {
        setVehicleRoutes((prev) => {
          const routeIndex = prev.findIndex(
            (route) => route.vehicleRouteId === vehicleRoute.vehicleRouteId
          );
          const newVehicleRoutes = [...prev];
          newVehicleRoutes[routeIndex] = { ...prev[routeIndex], status: res?.data?.status };
          return newVehicleRoutes;
        });
      } else {
        setUpdate((prev) => !prev);
      }
    }).catch(() => {
      showError('Ошибка при отмене маршрута');
    });
  };

  const onClickDelete = (vehicleRoute) => {
    confirm({
      title: 'Удаление',
      confirmationText: 'Да',
      cancellationText: 'Отмена',
      description: `Вы действительно хотите удалить «${vehicleRoute.name}»?`
    })
      .then(() => {
        deleteVehicleRoute(vehicleRoute.vehicleRouteId)
          .then(() => {
            setUpdate((prev) => !prev);
          }).catch(() => {
            showError('Ошибка при удалении маршрута');
          });
      })
      .catch(() => {});
  };

  const replanRoute = (vehicleRoute, newStatus) => {
    const params = {
      vehicleRouteId: vehicleRoute.vehicleRouteId,
      status: newStatus,
      startDate: vehicleRoute.startTime,
      startDateUntil: vehicleRoute.startTimeUntil
    };
    putVehicleRouteReeplan(params).then(() => {
      setUpdate((prev) => !prev);
    });
  };

  const activateRoute = (vehicleRoute) => {
    replanRoute(vehicleRoute, ROUTE_STATUSES.VROUTE_STATUS_ACTIVE);
  };

  const planRoute = (vehicleRoute) => {
    replanRoute(vehicleRoute, ROUTE_STATUSES.VROUTE_STATUS_PLANNED);
  };

  const onClickActivateRoute = (route, showControl) => {
    if (!route.vehicle) {
      confirm({
        title: 'Невозможно активировать маршрут',
        confirmationText: 'Ок',
        description: 'Нельзя активировать маршрут при отсутствии назначенного транспортного средства.',
        hideCancelButton: true,
      })
        .then(() => { })
        .catch(() => { });
    } else {
      showControl();
    }
  };

  const updateRoutes = () => setUpdate((prev) => !prev);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={(
            <Box className={style.companyRoutesPanel}>
              <RoutesToolbarWidget
                toogleFilters={toogleFilters}
                toggleTableMode={toggleTableMode}
                createVehicleRoute={openRouteForm}
                postVehicleRouteFile={postVehicleRouteFile}
              />
              <FiltersRoutesWidget
                isFiltersOpen={isFiltersOpen}
                handleFilter={handleFilter}
                initialFilters={initialFilters}
                updatedFilters={filters}
                statuses={statuses}
              />
              {!vehicleRoutes || isLoading
                ? <Loading />
                : !tableMode
                  ? (
                    <CompanyRoutesWidget
                      editRoute={editRoute}
                      copyRoute={copyRoute}
                      vehicleRoutes={vehicleRoutes}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      totalCount={totalCount}
                      onClickDelete={onClickDelete}
                      cancelRoute={cancelRoute}
                      activateRoute={activateRoute}
                      planRoute={planRoute}
                      onClickActivateRoute={onClickActivateRoute}
                    />
                  )
                  : (
                    <CompanyRoutesTablePanel
                      vehicleRoutes={vehicleRoutes}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      totalCount={totalCount}
                      editRoute={editRoute}
                      statuses={statuses}
                      copyRoute={copyRoute}
                      onClickDelete={onClickDelete}
                      cancelRoute={cancelRoute}
                      activateRoute={activateRoute}
                      planRoute={planRoute}
                      onClickActivateRoute={onClickActivateRoute}
                    />
                  )}
            </Box>
          )}
        />
        <Route
          path="edit/:vehicleRouteId"
          element={(
            <VehicleRouteFormWidget
              isEdit
              companyId={companyId}
              updateRoutes={updateRoutes}
            />
          )}
        />
        <Route
          path="copy/:vehicleRouteId"
          element={(
            <VehicleRouteFormWidget
              isCopy
              companyId={companyId}
              updateRoutes={updateRoutes}
            />
          )}
        />
        <Route
          path="create"
          element={(
            <VehicleRouteFormWidget
              isCreate
              companyId={companyId}
              updateRoutes={updateRoutes}
            />
          )}
        />
      </Routes>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={!!openCloneRoute}
        onClose={handleCloseCloneRouteDialog}
      >
        <DialogTitle>Создать маршрут на основе этого</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              p: 1, display: 'flex', flexDirection: 'column'
            }}
          >
            <Typography sx={{ mb: 1 }}>
              Будут скопированы следующие данные:
            </Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isCopyRoutePoints}
                  onChange={() => setIsCopyRoutePoints(!isCopyRoutePoints)}
                />
              )}
              label="Населенные пункты и точки"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isCopyRouteTrack}
                  onChange={() => setIsCopyRouteTrack(!isCopyRouteTrack)}
                />
              )}
              label="Трек передвижения"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isCopyPassengers}
                  onChange={() => setIsCopyPassengers(!isCopyPassengers)}
                />
              )}
              label="Пассажиры"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isCopyCargos}
                  onChange={() => setIsCopyCargos(!isCopyCargos)}
                />
              )}
              label="Грузы"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={isRouteCopyLoading} onClick={handleCloseCloneRouteDialog}>
            Отмена
          </Button>
          <Button disabled={isRouteCopyLoading} onClick={handleOkCloneRouteDialog}>Ок</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CompanyRoutesPanel.propTypes = {
  companyId: PropTypes.number,
};

CompanyRoutesPanel.defaultProps = {
  companyId: null,
};

export default CompanyRoutesPanel;
