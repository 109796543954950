import { Box, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import {
  Link, Route, Routes, useLocation
} from 'react-router-dom';
import NoData from '../../../../shared/ui/NoData/NoData';
import {
  tabsRoutes, tabsPanelStateEnum, companyTabsPanelStateSlice
} from "../redux/companyTabsPanels.slice";
import reducerPath from '../../../../app/reducerPath';
// eslint-disable-next-line boundaries/element-types
import { NODES } from '../../../tree/config';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getCurrentTab = (nodeType, obj, value) => {
  switch (nodeType) {
    case NODES.USER.TYPE:
      return value !== undefined ? { user: value } : obj.user;
    case NODES.VEHICLE.TYPE:
      return value !== undefined ? { vehicle: value } : obj.vehicle;
    case NODES.DIVISION.TYPE:
      return value !== undefined ? { division: value } : obj.division;
    case NODES.COMPANY.TYPE:
      return value !== undefined ? { company: value } : obj.company;
    default:
      return value !== undefined ? { company: value } : obj.company;
  }
};

const transformUrlForTab = (nodeType, pathname) => {
  switch (nodeType) {
    case NODES.USER.TYPE:
      return `${pathname.split('/').slice(3, 5).join('/')}/`;
    case NODES.USERS.TYPE:
      return `${pathname.split('/').slice(3, 4).join('/')}/`;
    case NODES.VEHICLE.TYPE:
      return `${pathname.split('/').slice(3, 7).join('/')}/`;
    case NODES.DIVISION.TYPE:
      return `${pathname.split('/').slice(3, 5).join('/')}/`;
    case NODES.DIVISIONS.TYPE:
      return `${pathname.split('/').slice(3, 4).join('/')}/`;
    case NODES.COMPANY.TYPE:
      return '';
    default:
      return '';
  }
};

export const nodesTypes = {
  [NODES.COMPANY.TYPE]: tabsPanelStateEnum.company,
  [NODES.DIVISION.TYPE]: tabsPanelStateEnum.division,
  [NODES.DIVISIONS.TYPE]: tabsPanelStateEnum.divisions,
  [NODES.VEHICLE.TYPE]: tabsPanelStateEnum.vehicle,
  [NODES.USER.TYPE]: tabsPanelStateEnum.user,
  [NODES.USERS.TYPE]: tabsPanelStateEnum.users,
  [NODES.PASSENGERS.TYPE]: tabsPanelStateEnum.passengers,
  [NODES.CARGO.TYPE]: tabsPanelStateEnum.cargo,
};

const nodesRoutes = {
  [NODES.COMPANY.TYPE]: '',
  [NODES.DIVISION.TYPE]: 'divisions/:id/',
  [NODES.DIVISIONS.TYPE]: 'divisions/',
  [NODES.VEHICLE.TYPE]: 'divisions/:id/vehicles/:id/',
  [NODES.USER.TYPE]: 'users/:id/',
  [NODES.USERS.TYPE]: 'users/',
  [NODES.PASSENGERS.TYPE]: 'passengers/',
  [NODES.CARGO.TYPE]: 'cargo/',
};

function matchTabByUrl(tabsNames, pathname) {
  const paths = pathname.split('/');
  const patterns = Object.keys(tabsNames);
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = tabsNames[patterns[i]];
    if (paths.indexOf(pattern) > -1) {
      return pattern;
    }
  }

  return tabsNames[patterns[0]];
}

function CompanyTabsPanels({ tabs, nodeType }) {
  const [newTabs, setNewTabs] = React.useState(tabs);
  const dispatch = useDispatch();
  const valueStore = useSelector((state) => state[`${reducerPath.companyTabsPanelState}/counter`]);
  const value = useMemo(() => getCurrentTab(
    nodeType,
    valueStore
  ), [valueStore, nodeType]);
  const setValue = useCallback((state) => {
    const newStateStore = { ...valueStore, ...getCurrentTab(nodeType, {}, state) };
    dispatch(companyTabsPanelStateSlice.actions.setValue(
      newStateStore
    ));
  }, [nodeType]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setNewTabs(tabs);
  }, [tabs]);

  const { pathname } = useLocation();

  let currentTab = matchTabByUrl(tabsRoutes[nodesTypes[nodeType]], pathname) || value;
  if (!newTabs?.map((t) => t.path).includes(currentTab)) {
    // При несоответствии доступных табов текущему, препятствуем консольной ошибке:
    currentTab = false;
  }
  return newTabs.length
    ? (
      <Box sx={{ flex: 1, pt: '20px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
          >
            {newTabs.map((tab, index) => (
              <Tab
                key={tab.name}
                label={tab.name}
                value={tab.path}
                {...a11yProps(index)}
                to={`${transformUrlForTab(nodeType, pathname)}${tab.path}`}
                component={Link}
              />
            ))}
          </Tabs>
        </Box>
        <Routes>
          {newTabs.map((tab) => (
            <Route
              key={tab.path}
              path={`${nodesRoutes[nodeType]}${tab.path}/*`}
              element={tab.component}
            />
          ))}
        </Routes>
      </Box>
    )
    : <NoData />;
}

CompanyTabsPanels.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]))).isRequired,
  nodeType: PropTypes.string
};

CompanyTabsPanels.defaultProps = {
  nodeType: null,
};

export default CompanyTabsPanels;
