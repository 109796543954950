import React, {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import {
  Alert,
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './PassengersListWidget.module.css';
import { getCompanyCargosQRcodes, getVehicleRoutesV2, patchCompanyCargo } from '../../../shared/api/api';
import ControlledTable from '../../../shared/ui/ControlledTable/ControlledTable';
import Loading from '../../../shared/ui/Loading/Loading';
import companyCargosDtoType from '../../../entities/companies/companyCargos/types/companyCargos-dto.type';
import datesPeriodToString from '../../../shared/utils/datesPeriodToString';
import ListItemRouteTable from '../../../entities/vehicles/routes/ListItemRouteTable';
import PassangerControls from '../../../features/passengers/PassangerControls/PassangerControls';
import useShowError from '../../../shared/lib/hooks/useShowError';
import FormDialog from '../../../shared/ui/FormDialog/FormDialog';
import { usePatchCompanyCargoBatchMutation } from '../../../entities/companies/companyCargos/redux/companyCargos.api';
import SelectedItemsToolbar from '../../../features/passengers/SelectedItemsToolbar/SelectedItemsToolbar';
import TagsFormDelete from '../../../features/passengers/TagsFormDelete/TagsFormDelete';
import SelectRouteDlg from '../../../entities/vehicles/routes/SelectRouteDlg';
import ChangeRouteForm from '../../../features/passengers/ChangeRouteForm/ChangeRouteForm';
import getDayStringFromDate from '../../../shared/utils/getDayStringFromDate';
import QrForm from '../../../features/passengers/QrForm/QrForm';
import CARGO_TYPE from '../../../shared/constants/cargoTypes';

const columnNames = {
  title: 'titleTags',
  comment: 'comment',
  startDeliveryTime: 'startDeliveryTime',
  upcomingRoutes: 'upcomingRoutes',
  dimentions: 'dimentions',
  weight: 'weight',
  addresses: 'addresses',
};

const headCells = (type = 'CARGO_TYPE_PASSENGER') => [
  {
    id: columnNames.title,
    numeric: false,
    disablePadding: false,
    label: type === 'CARGO_TYPE_PASSENGER' ? 'ФИО' : 'Название',
  },
  ...(type === 'CARGO_TYPE_CARGO' ? [
    {
      id: columnNames.dimentions,
      numeric: false,
      disablePadding: false,
      label: 'Габариты, см',
      disableSort: true,
    },
    {
      id: columnNames.weight,
      numeric: false,
      disablePadding: false,
      label: 'Вес, кг',
    },
    {
      id: columnNames.addresses,
      numeric: false,
      disablePadding: false,
      label: 'Адреса',
      disableSort: true,
    },
  ] : []),
  {
    id: columnNames.comment,
    numeric: false,
    disablePadding: false,
    label: 'Комментарии',
  },
  {
    id: columnNames.startDeliveryTime,
    numeric: false,
    disablePadding: false,
    label: 'Дата перевозки',
  },
  {
    id: columnNames.upcomingRoutes,
    numeric: false,
    disablePadding: false,
    label: 'Ближайшие маршруты',
    disableSort: true,
  },
];

const displayedCells = (type) => [
  columnNames.title,
  ...(type === 'CARGO_TYPE_CARGO' ? [
    columnNames.dimentions,
    columnNames.weight,
    columnNames.addresses,
  ] : []),
  columnNames.comment,
  columnNames.startDeliveryTime,
  columnNames.upcomingRoutes,
];

const DELETED_STATUS = 'CARGO_STATUS_DELETED';

const createTags = (tagsString) => {
  if (!tagsString) {
    return [];
  }
  return tagsString.split(',').map((tag, i) => ({ name: tag.trim(), id: i }));
};

const createUniqueTags = (tagsString) => {
  if (!tagsString) {
    return [];
  }
  const tags = tagsString.split(',').map((tag) => tag.trim());
  const uniqueTags = [...new Set(tags)];
  return uniqueTags.map((tag, i) => ({ name: tag, id: i }));
};

// Отображает список пассажиров
function PassengersListWidget(props) {
  const {
    companyCargos,
    rowsPerPage,
    totalCount,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    updateCompanyCargos,
    order,
    orderBy,
    changeSortParams,
    places,
    companyId,
    treeSelectedKeys,
    toogleSelectedNode,
    selected,
    setSelected,
    selectedCargos,
    setSelectedCargos,
    isLoadingCargos,
    isLoadingPlaces,
    sizesTable,
    cargoType,
  } = props;
  const confirm = useConfirm();
  const [companyCargosTableData, setCompanyCargosTableData] = useState(null);

  const isPassenger = useMemo(() => cargoType === 'CARGO_TYPE_PASSENGER', [cargoType]);

  const resetSelected = () => {
    setSelected([]);
    setSelectedCargos([]);
  };

  const [patchCompanyCargoBatch, resultBatch] = usePatchCompanyCargoBatchMutation();

  const onRequestSort = (e, tableProperty) => {
    const cargoProperty = Object.keys(columnNames)
      .find((key) => columnNames[key] === tableProperty);
    changeSortParams(cargoProperty);
  };

  const transformCargosToTableData = useCallback(() => {
    setCompanyCargosTableData(null);
    if (companyCargos) {
      const newTableData = companyCargos.map((companyCargo) => {
        const companyCargosPlaces = places?.find((place) => (
          companyCargo.compCargoId === place.cargo.compCargoId
        ))?.tickets || [];

        const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        const futureDate = new Date(new Date().setHours(0, 0, 0, 0));
        futureDate.setDate(currentDate.getDate() + 5);
        return {
          ...companyCargo,
          id: companyCargo.compCargoId,
          tickets: companyCargosPlaces,
          item: {
            ...companyCargo,
            tickets: companyCargosPlaces,
          },
          title: companyCargo.title,
          [columnNames.startDeliveryTime]:
          datesPeriodToString(companyCargo.startDeliveryTime, companyCargo.finishDeliveryTime),
          [columnNames.upcomingRoutes]: companyCargosPlaces?.length
            ? (
              <List disablePadding>
                {companyCargosPlaces.map((place) => (
                  <ListItemRouteTable
                    key={place.id}
                    link={`/companies/${companyId}/routes/edit/${place.routeId}`}
                    onClick={() => {
                      const prevKey = treeSelectedKeys?.[0]?.split('-')?.[0];
                      toogleSelectedNode([prevKey], true);
                    }}
                    day={getDayStringFromDate(currentDate, futureDate, place.routeStartTime)}
                    time={place.routeStartTime?.toLocaleTimeString('ru', { hour: "2-digit", minute: "2-digit" })}
                    name={place.routeName}
                    vehicleNum={place.vehicleNum}
                  />
                ))}
              </List>
            )
            : '-',
          [columnNames.title]: (
            <div>
              {companyCargo.title}
              {' '}
              {createTags(companyCargo.tags).map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  color="primary"
                  size="small"
                  sx={{ marginRight: '5px' }}
                />
              ))}
            </div>),
          [columnNames.dimentions]: (
            <div>
              {[companyCargo.width, companyCargo.length, companyCargo.height].filter((d) => d).join('×')}
            </div>),
          [columnNames.weight]: (
            <div>
              {companyCargo.weight / 1000}
            </div>),
          [columnNames.addresses]: (
            <List sx={{
              padding: 0, display: 'flex', flexDirection: 'column', gap: 1.5
            }}
            >
              {
                [
                  { id: 1, label: 'Адрес погрузки', text: companyCargo.loadingAddress },
                  { id: 2, label: 'Адрес доставки', text: companyCargo.deliveryAddress },
                ].map((item) => (
                  !!item.text && (
                  <ListItem alignItems="flex-start" key={item.id} sx={{ padding: 0 }}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={(
                        <Typography
                          component="div"
                          variant="caption"
                          color="text.secondary"
                        >
                          {item.label}
                        </Typography>
                      )}
                      secondary={(
                        <Typography
                          variant="body2"
                          color="text.primary"
                        >
                          {item.text}
                        </Typography>
                      )}
                    />
                  </ListItem>
                  )
                ))
              }
            </List>
          )
        };
      });
      setCompanyCargosTableData(newTableData);
    }
  }, [companyCargos, places, companyId, toogleSelectedNode, treeSelectedKeys]);

  useEffect(() => {
    transformCargosToTableData();
  }, [transformCargosToTableData]);

  const showError = useShowError();
  const navigate = useNavigate();

  const onDelete = (row) => {
    confirm({
      title: 'Удаление',
      confirmationText: 'Да',
      cancellationText: 'Отмена',
      description: `Вы действительно хотите удалить «${row?.title}»?`
    })
      .then(async () => {
        try {
          await patchCompanyCargo(
            row.compCargoId,
            { compCargoId: row.compCargoId, lastStatus: DELETED_STATUS }
          );
          updateCompanyCargos();
        } catch (error) {
          showError('Произошла ошибка при удалении');
        }
      })
      .catch(() => { });
  };

  const location = useLocation();
  const onEdit = (row) => navigate(`edit/${row.compCargoId}`, { state: { from: location } });
  const [selectedItem, setSelectedItem] = useState(null);

  const [isOpenAddTags, setIsOpenAddTags] = React.useState(false);
  const onOpenAddTags = () => {
    setIsOpenAddTags(true);
  };
  const closeAddTags = () => {
    setIsOpenAddTags(false);
  };

  useEffect(() => {
    if (!isOpenAddTags) {
      setSelectedItem(null);
    }
  }, [isOpenAddTags]);

  const [isOpenChangeTags, setIsOpenChangeTags] = React.useState(false);
  const onOpenChangeTags = () => {
    setIsOpenChangeTags(true);
  };
  const closeChangeTags = () => {
    setIsOpenChangeTags(false);
  };

  useEffect(() => {
    if (!isOpenChangeTags) {
      setSelectedItem(null);
    }
  }, [isOpenChangeTags]);

  const [isOpenDeleteTags, setIsOpenDeleteTags] = React.useState(false);
  const onOpenDeleteTags = () => {
    setIsOpenDeleteTags(true);
  };
  const closeDeleteTags = () => {
    setIsOpenDeleteTags(false);
  };

  useEffect(() => {
    if (!isOpenDeleteTags) {
      setSelectedItem(null);
    }
  }, [isOpenDeleteTags]);

  const [isOpenQR, setIsisOpenQR] = React.useState(false);
  const onOpenQR = () => {
    setIsisOpenQR(true);
  };
  const onCloseQR = () => {
    setIsisOpenQR(false);
  };

  useEffect(() => {
    if (!isOpenQR) {
      setSelectedItem(null);
    }
  }, [isOpenQR]);

  const [isOpenDownloadQR, setIsOpenDownloadQR] = React.useState(false);
  const onOpenDownloadQR = () => {
    setIsOpenDownloadQR(true);
  };
  const onCloseDownloadQR = () => {
    setIsOpenDownloadQR(false);
  };

  useEffect(() => {
    if (!isOpenDownloadQR) {
      setSelectedItem(null);
    }
  }, [isOpenDownloadQR]);

  const changeTags = (newTags, updateType) => {
    let cargoIds = [];
    if (selectedItem) {
      cargoIds = [selectedItem.compCargoId];
    } else {
      cargoIds = selected;
    }
    const body = {
      cargoIds,
      tagsUpdateType: updateType,
      tags: newTags,
    };
    patchCompanyCargoBatch({ body })
      .then((res) => {
        if (res.error) {
          if (res.error.data.status) {
            showError('Произошла ошибка на стороне сервера, попробуйте позже.');
            return;
          }
          showError('При сохранении данных произошла ошибка.');
          return;
        }
        resetSelected();
        updateCompanyCargos();
      });
  };

  const confirmFormRef = useRef(null);

  const onChangeRoute = (route, oldRoute = null) => {
    let cargoIds = [];
    if (selectedItem) {
      cargoIds = [selectedItem.compCargoId];
    } else {
      cargoIds = selected;
    }
    const oldRouteId = oldRoute ? { oldRouteId: oldRoute.routeId } : {};
    const body = {
      cargoIds,
      vehicleRouteId: route.vehicleRouteId,
      vrpStatus: 'VEHICLE_ROUTE_PLACE_RESERVED',
      ...oldRouteId,
    };
    patchCompanyCargoBatch({ body })
      .then((res) => {
        if (res.error) {
          if (res.error.data.status) {
            showError('Произошла ошибка на стороне сервера, попробуйте позже.');
            return;
          }
          showError('При сохранении данных произошла ошибка.');
          return;
        }
        resetSelected();
        updateCompanyCargos();
      });
  };

  const [isOpenRouteDlg, setIsOpenRouteDlg] = useState(false);
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);
  const [vehicleRoutes, setVehicleRoutes] = useState([]);

  const [isOpenRouteChangeDlg, setIsOpenChangeDlg] = useState(false);

  useEffect(() => {
    if (!isOpenRouteDlg) {
      setSelectedItem(null);
    }
  }, [isOpenRouteDlg]);

  useEffect(() => {
    if (!isOpenRouteChangeDlg) {
      setSelectedItem(null);
    }
  }, [isOpenRouteChangeDlg]);

  const onRouteNameChange = useCallback((name) => {
    if (companyId) {
      setIsLoadingRoutes(true);
      setVehicleRoutes([]);
      const params = [
        { name: 'companyId.equals', value: companyId },
        { name: 'name.contains', value: name },
        { name: 'status.in', value: 'VROUTE_STATUS_ACTIVE' },
        { name: 'status.in', value: 'VROUTE_STATUS_PLANNED' },
        { name: 'page', value: 0 },
        { name: 'size', value: 50 },
        { name: 'sort', value: 'name,asc' },
      ];
      getVehicleRoutesV2(params)
        .then((res) => {
          setVehicleRoutes(res.routes);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => setIsLoadingRoutes(false));
    }
  }, [companyId]);

  const getCommonRoutes = useCallback(() => {
    if (companyCargosTableData) {
      const commonUniqueRoutes = [];
      const cargos = selectedItem
        ? [selectedItem]
        : companyCargosTableData.filter(
          (cargo) => selected.includes(cargo.compCargoId)
        );
      // Собираем все внутренние массивы в один
      const allItems = cargos.reduce((acc, obj) => acc.concat(obj.tickets), []);
      // Удаляем дубликаты из массива
      const uniqueTickets = Array.from(new Set(allItems?.map((item) => item.routeId))).map(
        (routeId) => allItems.find((item) => item.routeId === routeId)
      );

      if (!selectedItem) {
        // Находим только общие для всех cargos
        for (let i = 0; i < uniqueTickets.length; i += 1) {
          let j = 0;
          const uniqueTicket = uniqueTickets[i];
          for (j; j < cargos.length; j += 1) {
            const cargo = cargos[j];
            if (!cargo.tickets.find((ticket) => ticket.routeId === uniqueTicket.routeId)) {
              break;
            }
          }
          if (j === cargos.length) {
            commonUniqueRoutes.push(uniqueTicket);
          }
        }
      }
      return selectedItem ? uniqueTickets : commonUniqueRoutes;
    }
    return [];
  }, [companyCargosTableData, selected, selectedItem]);

  const commonRoutes = useMemo(() => getCommonRoutes(), [getCommonRoutes]);

  const [oldRoute, setOldRoute] = React.useState(commonRoutes[0] || null);

  useEffect(() => {
    setOldRoute(commonRoutes[0] || null);
  }, [commonRoutes]);

  const onSelectCargo = (index, item) => {
    if (index === -1) {
      setSelectedCargos((prev) => {
        const newSelected = [...prev];
        newSelected.push(item);
        return newSelected.sort((a, b) => a.rowIndex - b.rowIndex);
      });
    } else {
      setSelectedCargos((prev) => {
        const newSelected = prev.filter((el) => item.compCargoId !== el.compCargoId);
        return newSelected;
      });
    }
  };

  const onSelectAllCargos = (checked) => {
    setSelectedCargos(checked ? companyCargos : []);
  };

  const selectedPassengersAlert = useMemo(() => {
    if (selectedItem || selected.length === 1) {
      return (
        <Alert severity="info">
          {selectedItem?.title || selectedCargos[0]?.title}
        </Alert>
      );
    }
    return (
      <Alert severity="info">
        {selected.length <= 5
          ? (
            <List disablePadding>
              {selectedCargos?.map((item, i) => (
                <ListItem disablePadding key={item.compCargoId}>
                  {i + 1}
                  {'. '}
                  {item.title}
                </ListItem>
              ))}
            </List>
          )
          : `Для ${selected.length} ${isPassenger ? 'пассажиров' : 'грузов'}.`}
      </Alert>
    );
  }, [selected.length, selectedCargos, selectedItem]);

  const downloadQR = () => {
    const qrOptions = confirmFormRef.current?.state?.qrOptions;
    const cargoIds = (selectedItem ? [selectedItem] : selectedCargos).map((cargo) => cargo.compCargoId).join(',');
    const body = {
      type: 'PNG',
      names: Boolean(qrOptions?.isName),
      tags: Boolean(qrOptions?.isTags),
      cargoIds,
    };
    getCompanyCargosQRcodes(body);
  };

  return (
    <Box className={style.list}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={resultBatch.status === 'pending'}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="body1">
            Сохранение...
          </Typography>
        </Box>
      </Backdrop>
      <SelectRouteDlg
        isOpenRouteDlg={isOpenRouteDlg}
        onRouteNameChange={onRouteNameChange}
        setIsOpenRouteDlg={setIsOpenRouteDlg}
        title="Назначить маршрут"
        vehicleRoutes={vehicleRoutes}
        content={selectedPassengersAlert}
        selectRoute={(route) => onChangeRoute(route)}
        loading={isLoadingRoutes}
      />
      <SelectRouteDlg
        isOpenRouteDlg={isOpenRouteChangeDlg}
        onRouteNameChange={onRouteNameChange}
        setIsOpenRouteDlg={setIsOpenChangeDlg}
        title="Изменить маршрут"
        vehicleRoutes={vehicleRoutes}
        label="Новый маршрут"
        content={(
          <ChangeRouteForm
            routes={commonRoutes}
            route={oldRoute}
            customContent={selectedPassengersAlert}
            setRoute={setOldRoute}
          />
        )}
        autoFocus={false}
        selectRoute={(route) => {
          if (oldRoute) {
            onChangeRoute(route, oldRoute);
          }
        }}
        isValid={!!(oldRoute)}
        loading={isLoadingRoutes}
      />
      <FormDialog
        open={isOpenAddTags}
        handleClose={closeAddTags}
        onSave={(text) => changeTags(text, 'ADD')}
        label="Список добавляемых меток"
        helperText="Укажите значения через запятую"
        dialogContent={selectedPassengersAlert}
        title="Добавить метки"
      />
      <FormDialog
        open={isOpenQR}
        handleClose={onCloseQR}
        onSave={() => {
          if (confirmFormRef.current) {
            const { qrOptions } = confirmFormRef.current.state;
            localStorage.setItem("qrOptions", JSON.stringify(qrOptions));
            const cargos = (selectedItem ? [selectedItem] : selectedCargos).map((cargo, i) => ({
              uid: i,
              title: cargo.title,
              tags: cargo.tags,
              token: cargo.token,
            }));
            localStorage.setItem("cargos", JSON.stringify(cargos));
            window.open("/passengers-qr-list", "_blank", "noreferrer");
          }
        }}
        dialogContent={(
          <QrForm
            ref={confirmFormRef}
            customContent={selectedPassengersAlert}
            cargos={selectedItem ? [selectedItem] : selectedCargos}
            isChangedSize
          />
        )}
        title="Печать QR-кода"
        saveTitle="Печать"
        cancelTitle="Отмена"
        disableTextField
      />
      <FormDialog
        open={isOpenDownloadQR}
        handleClose={onCloseDownloadQR}
        onSave={downloadQR}
        dialogContent={(
          <QrForm
            ref={confirmFormRef}
            customContent={selectedPassengersAlert}
            cargos={selectedItem ? [selectedItem] : selectedCargos}
          />
        )}
        title="Выгрузка QR-кода"
        saveTitle="Скачать"
        cancelTitle="Отмена"
        disableTextField
      />
      <FormDialog
        open={isOpenChangeTags}
        handleClose={closeChangeTags}
        onSave={(text) => changeTags(text, 'CHANGE')}
        label="Список новых меток"
        helperText="Укажите значения через запятую"
        dialogContent={selectedPassengersAlert}
        title="Заменить метки"
      />
      <FormDialog
        open={isOpenDeleteTags}
        handleClose={closeDeleteTags}
        onSave={() => {
          if (confirmFormRef.current) {
            const { selectedTags } = confirmFormRef.current.state;
            changeTags(selectedTags, 'DELETE');
          }
        }}
        dialogContent={(
          <TagsFormDelete
            ref={confirmFormRef}
            customContent={selectedPassengersAlert}
            tags={createUniqueTags((selectedItem ? [selectedItem] : selectedCargos).map((item) => item.tags).join(','))
              .sort((a, b) => (`${a.name}`).localeCompare(b.name))}
          />
        )}
        title="Удалить метки"
        disableTextField
      />
      {isLoadingCargos || isLoadingPlaces || !companyCargosTableData
        ? <Loading />
        : (
          <>
            <SelectedItemsToolbar
              selected={selected}
              resetSelected={resetSelected}
              onOpenAddTags={onOpenAddTags}
              onOpenChangeTags={onOpenChangeTags}
              onDeleteTags={onOpenDeleteTags}
              onAddRoute={() => setIsOpenRouteDlg(true)}
              onChangeRoute={() => setIsOpenChangeDlg(true)}
              onOpenQR={onOpenQR}
              onOpenDownloadQR={onOpenDownloadQR}
            />
            <TablePagination
              rowsPerPageOptions={sizesTable}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              sx={{
                backgroundColor: 'white',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Divider />
            <ControlledTable
              rows={companyCargosTableData || []}
              headCells={headCells(cargoType)}
              displayedCells={displayedCells(cargoType)}
              countRowsPerPage={rowsPerPage}
              selected={selected}
              setSelected={setSelected}
              onSelectItem={onSelectCargo}
              onSelectAllItems={onSelectAllCargos}
              isControlledSort
              hover
              isSelectOnClickRow={false}
              renderCustomControls={(item) => (
                <PassangerControls
                  onEdit={() => onEdit(item)}
                  onDelete={() => onDelete(item)}
                  onAddTags={() => {
                    setSelectedItem(item);
                    onOpenAddTags();
                  }}
                  onChangeTags={() => {
                    setSelectedItem(item);
                    onOpenChangeTags();
                  }}
                  onDeleteTags={() => {
                    setSelectedItem(item);
                    onOpenDeleteTags();
                  }}
                  onAddRoute={() => {
                    setSelectedItem(item);
                    setIsOpenRouteDlg(true);
                  }}
                  onChangeRoute={() => {
                    setSelectedItem(item);
                    setIsOpenChangeDlg(true);
                  }}
                  countRoutes={item.tickets?.length}
                />
              )}
              dense={companyCargosTableData.length > 15}
              order={order}
              orderBy={columnNames[orderBy]}
              onRequestSort={onRequestSort}
            />
            <TablePagination
              rowsPerPageOptions={sizesTable}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
    </Box>
  );
}

PassengersListWidget.propTypes = {
  companyCargos: PropTypes.arrayOf(PropTypes.shape(companyCargosDtoType)),
  updateCompanyCargos: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  changeSortParams: PropTypes.func,
  treeSelectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  places: PropTypes.arrayOf(PropTypes.shape({})),
  toogleSelectedNode: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
  selectedCargos: PropTypes.arrayOf(PropTypes.shape(companyCargosDtoType)),
  setSelectedCargos: PropTypes.func.isRequired,
  isLoadingCargos: PropTypes.bool,
  isLoadingPlaces: PropTypes.bool,
  sizesTable: PropTypes.arrayOf(PropTypes.number).isRequired,
  cargoType: CARGO_TYPE.type,
};

PassengersListWidget.defaultProps = {
  companyCargos: null,
  order: '',
  orderBy: '',
  places: null,
  changeSortParams: () => {},
  selectedCargos: null,
  isLoadingCargos: false,
  isLoadingPlaces: false,
  cargoType: CARGO_TYPE.default,
};

export default PassengersListWidget;
