import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from "prop-types";
import { useConfirm } from 'material-ui-confirm';
import ControlledTable from '../../../shared/ui/ControlledTable/ControlledTable';
import DivisionAccessToolbarWidget from '../../../widgets/divisions/DivisionAccessToolbarWidget/DivisionAccessToolbarWidget';
import DivisionAccessFiltersWidget from '../../../widgets/divisions/DivisionAccessFiltersWidget/DivisionAccessFiltersWidget';
import DivisionAccessFormWidget from '../../../widgets/divisions/DivisionAccessFormWidget/DivisionAccessFormWidget';
import DivisionAccessFormBll from '../../../widgets/divisions/DivisionAccessFormWidget/DivisionAccessFormBll';

const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Имя',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Фамилия',
  },
  {
    id: 'privileges',
    numeric: false,
    disablePadding: false,
    label: 'Доступ',
  },
];

const displayedCells = [
  'firstName',
  'lastName',
  'privileges',
];

// Отображает панель доступа пользователей к подразделению (таблица и кнопки управления)
function DivisionAccessPanel(props) {
  const {
    data, divisionId, companyId, onDelete, setUpdate, setFilters
  } = props;
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const toogleFilters = () => {
    setIsOpenFilters((prev) => !prev);
  };

  const applyFilters = (filters) => {
    const newFilters = {};
    if (filters.access && filters.access !== 'ALL') {
      newFilters.divPrivilege = filters.access;
    }
    if (filters.firstName.trim()) {
      newFilters.firstName = filters.firstName.trim();
    }
    if (filters.lastName.trim()) {
      newFilters.lastName = filters.lastName.trim();
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    setEditedUser(null);
    setIsCreate(false);
  }, [data]);

  const onCancel = () => {
    setEditedUser(null);
    setIsCreate(false);
    setFilters(null);
  };

  const confirm = useConfirm();

  return (
    <Box>
      {!editedUser && !isCreate
        ? (
          <>
            <DivisionAccessToolbarWidget
              toogleFilters={toogleFilters}
              create={() => setIsCreate(true)}
            />
            <DivisionAccessFiltersWidget
              isOpenFilters={isOpenFilters}
              applyFilters={applyFilters}
            />
          </>
        )
        : null}
      {editedUser || isCreate
        ? (
          <DivisionAccessFormBll
            data={data}
            divisionId={divisionId}
            appUserId={editedUser}
            companyId={companyId}
            setUpdate={setUpdate}
          >
            <DivisionAccessFormWidget
              onCancel={onCancel}
            />
          </DivisionAccessFormBll>
        )
        : (
          <ControlledTable
            rows={data || []}
            headCells={headCells}
            displayedCells={displayedCells}
            countRowsPerPage={5}
            selected={[]}
            setSelected={() => { }}
            controls
            onEdit={(row) => setEditedUser(Number(row.appUserId))}
            onDelete={(row) => {
              confirm({
                title: 'Удаление',
                confirmationText: 'Да',
                cancellationText: 'Отмена',
                description: `Вы действительно хотите закрыть доступ к подразделению для пользователя?`
              })
                .then(() => onDelete(Number(row.appUserId)))
                .catch(() => { });
            }}
          />
        )}
    </Box>
  );
}

DivisionAccessPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  divisionId: PropTypes.number,
  companyId: PropTypes.number,
  onDelete: PropTypes.func,
  setUpdate: PropTypes.func,
  setFilters: PropTypes.func,
};

DivisionAccessPanel.defaultProps = {
  data: [],
  divisionId: NaN,
  companyId: NaN,
  onDelete: null,
  setUpdate: null,
  setFilters: null,
};

export default DivisionAccessPanel;
