import {
  Box, IconButton, Tooltip, Typography
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PropTypes from 'prop-types';
import PersonIcon from '@mui/icons-material/Person';
import { useConfirm } from 'material-ui-confirm';
import IconButtonBadge from '../../shared/ui/IconButtonBadge/IconButtonBadge';
import { NODES } from '../../entities/tree/config';

function InfoToolbarWidget(props) {
  const {
    selectedData, remove, add, isAdmin, isCompanyAdmin, isVehicleAdmin, isUserAdmin,
  } = props;

  const confirm = useConfirm();
  return (
    <Box sx={{
      padding: '0px 20px 0px 20px', display: 'flex', alignItems: 'end', gap: '10px', justifyContent: 'space-between'
    }}
    >
      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'left' }}>
        {selectedData
          ? (
            <Typography variant="subtitle1" color="gray">
              {selectedData.name ? '' : `Создать ${NODES[selectedData.nodeType]?.VP_NAME}:`}
            </Typography>
          )
          : null}
      </Box>
      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center'
      }}
      >
        <IconButtonBadge
          title="Создать ТС"
          disabled={!(
            selectedData
            && (isAdmin || isCompanyAdmin || isVehicleAdmin)
            && (selectedData.nodeType === NODES.DIVISION.TYPE
              || selectedData.nodeType === NODES.VEHICLE.TYPE)
          )}
          onClick={() => add(NODES.VEHICLE.TYPE)}
        >
          <DirectionsCarIcon />
        </IconButtonBadge>
        {!isAdmin && !isCompanyAdmin
          ? null
          : (
            <IconButtonBadge
              title="Создать подразделение"
              disabled={!(
                selectedData
                && (selectedData.nodeType === NODES.DIVISIONS.TYPE
                  || selectedData.nodeType === NODES.DIVISION.TYPE)
              )}
              onClick={() => add(NODES.DIVISION.TYPE)}
            >
              <HomeWorkIcon />
            </IconButtonBadge>
          )}
        {!isAdmin && !isCompanyAdmin && !isUserAdmin && selectedData?.nodeType !== NODES.USERS.TYPE
          ? null
          : (
            <IconButtonBadge
              title="Новый пользователь"
              disabled={!(
                selectedData
                && (selectedData.nodeType === NODES.USERS.TYPE
                  || selectedData.nodeType === NODES.USER.TYPE)
              )}
              onClick={() => add(NODES.USER.TYPE)}
            >
              <PersonIcon />
            </IconButtonBadge>
          )}
        <Tooltip title="Удалить">
          <span>
            <IconButton
              disabled={!(
                selectedData?.id
                && (
                  (selectedData.nodeType === NODES.DIVISION.TYPE && (isAdmin || isCompanyAdmin))
                  || (selectedData.nodeType === NODES.VEHICLE.TYPE
                    && (isVehicleAdmin || isAdmin || isCompanyAdmin))
                  || (selectedData.nodeType === NODES.COMPANY.TYPE && isAdmin)
                  || (selectedData.nodeType === NODES.USER.TYPE && (isAdmin || isCompanyAdmin))
                ))}
              onClick={(e) => {
                confirm({
                  title: 'Удаление',
                  confirmationText: 'Да',
                  cancellationText: 'Отмена',
                  description: `Вы действительно хотите удалить «${selectedData?.name}»?`
                })
                  .then(() => remove(e))
                  .catch(() => {});
              }}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

InfoToolbarWidget.propTypes = {
  selectedData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  remove: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isCompanyAdmin: PropTypes.bool,
  isVehicleAdmin: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
};

InfoToolbarWidget.defaultProps = {
  selectedData: null,
  isAdmin: false,
  isCompanyAdmin: false,
  isVehicleAdmin: false,
  isUserAdmin: false,
};

export default InfoToolbarWidget;
