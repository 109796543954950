// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import alertsTypesResourceDtoType from "../../types/alertsTypesResource-dto.type";

export function alertsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  return { data: dto, status };
}

alertsResourceFromDtoService.propTypes = {
  dto: alertsTypesResourceDtoType,
};

export default alertsResourceFromDtoService;
