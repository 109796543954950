// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import appUsersResourceFromDtoService from "../services/data/appUsersResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const appUsersResourceApi = createApi({
  reducerPath: `${reducerPath.appUsersResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/app-users`),
  endpoints: (builder) => ({
    getAppUserResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appUsersResourceFromDtoService,
    }),
    getAppUserResourceId: builder.query({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: appUsersResourceFromDtoService,
    }),
    patchAppUserResource: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    postAppUserResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    deleteAppUserResource: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.DELETE
        }),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),

  }),
});

export const {
  useDeleteAppUserResourceMutation,
  useLazyGetAppUserResourceQuery,
  useGetAppUserResourceQuery,
  useGetAppUserResourceIdQuery,
  usePatchAppUserResourceMutation,
  usePostAppUserResourceMutation
} = appUsersResourceApi;
