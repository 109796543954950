import {
  Box, IconButton, TablePagination, Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { format } from 'date-fns';
import { red } from '@mui/material/colors';
import style from './RouteInfoPanel.module.css';
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import isValidDate from '../../../shared/utils/isValidDate';
import Loading from '../../../shared/ui/Loading/Loading';
import RouteActivateControl from "../../../features/vehicles/RouteActivateControl/RouteActivateControl";
import StyledMenu from '../../../shared/ui/StyledMenu/StyledMenu';
import CarIcon from '../../../shared/ui/CarIcon/CarIcon';
import { ROUTE_STATUSES, getStatus } from '../../../entities/vehicles/VehiclesRoute/statuses';
import {
  daysOfWeek, extractValueFromString, months, moreFunctions
} from '../../../widgets/schedule/ScheduleWidget/ScheduleWidget';

const createRandomId = () => crypto.randomUUID();

const alertsHeadCells = [
  {
    id: 'nameNP',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'numberVehicle',
    numeric: false,
    disablePadding: false,
    label: 'Номер ТС',
  },
  {
    id: 'dataStart',
    numeric: false,
    disablePadding: false,
    label: 'Дата отправления',
    style: { maxWidth: "100px" }
  },
  {
    id: 'dataFinish',
    numeric: false,
    disablePadding: false,
    label: 'Дата прибытия',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'nameRoute',
    numeric: false,
    disablePadding: false,
    label: 'Название маршрута',
  },
  {
    id: 'nextPoint',
    numeric: false,
    disablePadding: false,
    label: 'Следующий пункт',
  },
  {
    id: 'schedule',
    numeric: false,
    disablePadding: false,
    label: 'Расписание',
    style: { maxWidth: "100px" }
  },
  {
    id: 'functions',
    numeric: false,
    disablePadding: false,
    label: '',
    style: { maxWidth: "100px" }
  },

];

const alertsDisplayedCells = [
  "iconVehicle",
  "numberVehicle",
  "dataStart",
  "dataFinishContent",
  "status",
  "nameRoute",
  "nextPoint",
  "schedule",
  "functions"
];

function mapWeekDays(days) {
  const weekDaysMap = daysOfWeek.reduce((acc, day) => ({ ...acc, [day.id]: day.value }), {});
  return days.split(',').map((d) => weekDaysMap[d]).join(', ');
}

function mapMonthDays(days, order, defDays) {
  const weekDaysMap = daysOfWeek.reduce((acc, day) => ({ ...acc, [day.id]: day.value }), {});
  if (order) {
    const moreFunc = moreFunctions.reduce((acc, m) => ({
      ...acc, [m.id]: { m: m.short, f: m.shortFm, md: m.shortMd }
    }), {});
    if (defDays !== 'DAY_OF_MONTH') {
      const weekDay = weekDaysMap[defDays];
      return `${weekDay}(${moreFunc[order][(
        defDays === 'WEN' || defDays === 'FRI' || defDays === 'SAT'
          ? 'f' : defDays === 'SUN'
            ? 'md' : 'm'
      )]})`;
    }
    return `${moreFunc[order].m} день месяца`;
  }
  return days?.split(',').map((d) => +d).sort((a, b) => a - b).join(', ');
}

function mapMonth(mth, order, defDays) {
  const monthMap = months.reduce((acc, m) => ({ ...acc, [m.id]: m.short }), {});
  const weekDay = (order && defDays) ? ` - ${mapMonthDays(null, order, defDays)}` : '';
  return `${mth.split(',').map((month) => monthMap[month]).join(', ')}${weekDay}`;
}

function parseSchedule(value) {
  if (!value) {
    return '-';
  }
  const type = extractValueFromString(value, 'TYPE');
  const defEachN = extractValueFromString(value, "EACH_N");
  const defDays = extractValueFromString(value, "DAYS");
  const defMonth = extractValueFromString(value, "MONTH");
  const defMonthDays = extractValueFromString(value, "MONTH_DAYS");
  const order = extractValueFromString(value, "ORDER");
  let until = extractValueFromString(value, "UNTIL") || '';
  if (until) {
    until = `, до ${format(new Date(until), 'dd.MM.yyyy')}`;
  }
  switch (type) {
    case 'EVERYDAY':
      return `${defEachN > 1 ? `Каждый ${defEachN} день` : 'Ежедневно'}${until}`;
    case 'EVERYWEEK':
      return `${mapWeekDays(defDays)}${defEachN > 1 ? ` каждую ${defEachN} неделю` : ''}${until}`;
    case 'EVERYMONTH':
      return `${mapMonthDays(defMonthDays, order, defDays)}${defEachN > 1 ? ` каждый ${defEachN} месяц` : ''}${until}`;
    case 'EVERYYEAR':
      return `${mapMonth(defMonth, order, defDays)}${until}`;
    case 'NORETRY':
      return 'Один раз';
    default:
      return value;
  }
}

function StatusOfRoute({ status }) {
  if (!status) {
    return '-';
  }

  return (
    <Typography variant="body2" component="div" sx={{ color: status.color }}>
      {status.name}
    </Typography>
  );
}

function formatDate(date) {
  // Получаем компоненты даты
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = String(date.getFullYear()).slice(-2); // Берем только последние две цифры года
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Формируем строку в нужном формате
  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

// Панель маршрутов с точками и тулбаром, фильтрацией
function CompanyRoutesTablePanel(props) {
  const {
    vehicleRoutes,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    copyRoute,
    onClickDelete,
    cancelRoute,
    activateRoute,
    editRoute,
    planRoute,
    onClickActivateRoute,
  } = props;

  const [displayedRoutes, setDisplayedRoutes] = useState(null);

  const selectDisplayedPoints = (selectIndex, arr) => {
    // Индекс последней точки
    const lastIndex = arr.length - 1;
    // Элемент между отображаемыми точками
    const insertedItem = { collapsePoint: true };
    // Диапазон отображаемых точек
    const range = 4;
    // Максимальное кол-во отображаемых точек
    const maxCount = 7;
    let result = [];
    // Если кол-во точек меньше максимального, просто возвращаем все точки
    if (arr.length <= maxCount) return arr;
    // Если выбранная точка меньше диапазона (вставка в конец)
    if (selectIndex < range) {
      result = [
        ...arr.slice(0, 5),
        { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 5 },
        arr[lastIndex],
      ];
    } else if (lastIndex - range < selectIndex) {
      // Если выбранная точка больше отображаемого диапазона (вставка в начало)
      result = [
        arr[0],
        { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 5 },
        ...arr.slice(lastIndex - range),
      ];
    } else {
      // Если выбранная точка не попадает в диапазон (вставка в середину)
      result = [
        arr[0],
        { ...insertedItem, id: createRandomId(), countPoints: selectIndex - 2 },
        arr[selectIndex - 1],
        arr[selectIndex],
        arr[selectIndex + 1],
        { ...insertedItem, id: createRandomId(), countPoints: lastIndex - 2 - selectIndex },
        arr[lastIndex],
      ];
    }
    return result;
  };

  // Изменение отображаемых точек при смене точек маршрутов
  useEffect(() => {
    let newDisplayedRoutes = null;
    if (vehicleRoutes) {
      newDisplayedRoutes = [];
      vehicleRoutes.forEach((route) => {
        let isBack = false;
        let newPoints = [];
        if (route.routePoints.length) {
          // Ищем первую точку, до которой не доехало ТС
          let carIndex = route.routePoints.findIndex((item) => !item.isAttended);
          const carItem = { car: true, id: createRandomId() };
          if (carIndex === -1) {
            // Все точки пройдены, ищем первую пройденную точку для обратного маршрута
            carIndex = route.routePoints.findIndex((item) => item.isBackAttended);
            isBack = true;
            if (carIndex === -1) {
              // Пройденной на обратном пути точки нет, машина в конечной точке
              carIndex = route.routePoints.length;
            }
          }
          const points = route.routePoints.slice();
          // Добавляем по индексу точки новую точку ТС в массив отображаемых точек
          points.splice(carIndex, 0, carItem);
          // points[carIndex + 1] = { ...points[carIndex + 1], nextPoint: true };

          // Получаем отображаемые точки
          newPoints = selectDisplayedPoints(carIndex, points);
        } else {
          newPoints = [
            { id: createRandomId(), name: 'Не выбрано' },
            { id: createRandomId(), name: 'Не выбрано' },
          ];
        }
        newDisplayedRoutes.push({ ...route, isBack, points: newPoints });
      });
    }
    setDisplayedRoutes(newDisplayedRoutes);
  }, [vehicleRoutes]);

  const rows = useMemo(() => displayedRoutes?.map((route) => {
    const { isBack } = route;
    let delayTime = null;
    const nowDate = new Date();
    const carIndex = route.points.findIndex((p) => p.car);
    const nextPoint = isBack
      ? route.points[carIndex - 1]
      : route.points[carIndex + 1];
    const arriveTimeUntil = isBack
      ? nextPoint?.backArriveTimeUntil
      : nextPoint?.arriveTimeUntil;
    if (arriveTimeUntil) {
      delayTime = nowDate.getTime() - new Date(arriveTimeUntil).getTime();
    }
    const nextPointName = nextPoint?.geoName?.name
      || nextPoint?.geoZone?.name
      || nextPoint?.geoName?.geonameId
      || nextPoint?.name;

    return ({
      iconVehicle: (
        <>
          <CarIcon />
          <Typography variant="subtitle" component="div" noWrap sx={{ width: '100%', maxWidth: '200px', fontSize: '0.75rem' }}>
            {route.vehicle?.name || ''}
          </Typography>
        </>
      ),
      dataStart: isValidDate(route.startTime) ? formatDate(route.startTime) : '-',
      dataFinish: isValidDate(route.finishTime) ? formatDate(route.finishTime) : '-',
      dataFinishContent: (
        <>
          { isValidDate(route.finishTime) ? formatDate(route.finishTime) : '-' }
          {delayTime > 0
            ? (
              <Typography variant="subtitle2" component="div" color={red[500]}>
                +
                {Math.round(delayTime / 1000 / 60)}
                {' '}
                мин
              </Typography>
            )
            : null}
        </>
      ),
      status: (
        <StatusOfRoute
          status={getStatus(route.status)}
        />
      ),
      numberVehicle: route.vehicle?.num || '-',
      nameRoute: route.name || '-',
      nameNP: route.vehicle?.name || '-',
      schedule: parseSchedule(route.schedule) || '-',
      item: route,
      nextPoint: nextPointName || '-',
    });
  }), [displayedRoutes]);

  return (
    <Box className={style.UsersInfoPanel}>
      {!vehicleRoutes
        ? <Loading />
        : (
          <ControlledTable
            rows={rows}
            headCells={alertsHeadCells}
            displayedCells={alertsDisplayedCells}
            countRowsPerPage={rowsPerPage}
            selected={[]}
            setSelected={() => {}}
            disableCheckbox
            renderCustomControls={(item) => (
              <FunctionComponent
                copyRoute={copyRoute}
                onClickDelete={onClickDelete}
                cancelRoute={cancelRoute}
                activateRoute={activateRoute}
                planRoute={planRoute}
                displayedRoute={item}
                editRoute={editRoute}
                onClickActivateRoute={onClickActivateRoute}
              />
            )}
          />
        )}
      <TablePagination
        rowsPerPageOptions={[5, 15, 30]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

function FunctionComponent({
  copyRoute,
  onClickDelete,
  cancelRoute,
  activateRoute,
  displayedRoute,
  editRoute,
  planRoute,
  onClickActivateRoute,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} size="small">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {displayedRoute.status === ROUTE_STATUSES.VROUTE_STATUS_CLOSED
          ? (
            <RouteActivateControl
              vehicleRoute={displayedRoute}
              activateRoute={planRoute}
              title="Перепланировать маршрут"
            >
              <MenuItem>
                <AlarmOnIcon color="primary" />
                Перепланировать
              </MenuItem>
            </RouteActivateControl>
          )
          : (
            <RouteActivateControl
              vehicleRoute={displayedRoute}
              activateRoute={activateRoute}
              onClick={(showControl) => onClickActivateRoute(displayedRoute, showControl)}
            >
              <MenuItem>
                <PlayCircleIcon color="primary" />
                Активировать
              </MenuItem>
            </RouteActivateControl>
          )}
        <MenuItem
          onClick={() => {
            cancelRoute(displayedRoute);
            handleClose();
          }}
        >
          <DoDisturbIcon color="primary" />
          Отменить
        </MenuItem>
        <MenuItem
          onClick={() => {
            editRoute(displayedRoute);
            handleClose();
          }}
        >
          <ModeEditIcon color="primary" />
          Редактировать
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyRoute(displayedRoute);
            handleClose();
          }}
        >
          <ContentCopyIcon color="primary" />
          Маршрут на основе этого
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            onClickDelete(displayedRoute);
            handleClose();
          }}
          disableRipple
        >
          <DeleteIcon color="primary" />
          Удалить маршрут
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

FunctionComponent.propTypes = {
  copyRoute: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  cancelRoute: PropTypes.func.isRequired,
  activateRoute: PropTypes.func.isRequired,
  planRoute: PropTypes.func.isRequired,
  editRoute: PropTypes.func.isRequired,
  displayedRoute: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])).isRequired,
  onClickActivateRoute: PropTypes.func.isRequired,
};

const statusesProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

StatusOfRoute.propTypes = {
  status: statusesProps,
};

StatusOfRoute.defaultProps = {
  status: null,
};

CompanyRoutesTablePanel.propTypes = {
  vehicleRoutes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]))),
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  copyRoute: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  cancelRoute: PropTypes.func.isRequired,
  activateRoute: PropTypes.func.isRequired,
  planRoute: PropTypes.func.isRequired,
  editRoute: PropTypes.func.isRequired,
  onClickActivateRoute: PropTypes.func.isRequired,
};

CompanyRoutesTablePanel.defaultProps = {
  vehicleRoutes: null,
};

export default CompanyRoutesTablePanel;
