import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const platoonGroupsResourceEnum = {
  platoonGroupId: "platoonGroupId",
  name: "name",
  status: "status",
  companyId: "companyId",
  routeId: "routeId",
  startDateFrom: "startDateFrom",
  startDateTo: "startDateTo",
  intervalDuration: "intervalDuration",
  requiredTokens: "requiredTokens",
  adviseTokens: "adviseTokens",
  createdDate: "createdDate",
  modifiedDate: "modifiedDate"
};

// Инициализация стейта в сторе
const platoonGroupsResourceInitialState = {
  [platoonGroupsResourceEnum.platoonGroupId]: NaN,
  [platoonGroupsResourceEnum.name]: null,
  [platoonGroupsResourceEnum.status]: null,
  [platoonGroupsResourceEnum.companyId]: NaN,
  [platoonGroupsResourceEnum.routeId]: NaN,
  [platoonGroupsResourceEnum.startDateFrom]: null,
  [platoonGroupsResourceEnum.startDateTo]: null,
  [platoonGroupsResourceEnum.intervalDuration]: {},
  [platoonGroupsResourceEnum.adviseTokens]: null,
  [platoonGroupsResourceEnum.createdDate]: null,
  [platoonGroupsResourceEnum.modifiedDate]: null,
};
// Типизация стора
export const platoonGroupsResourceType = {
  [platoonGroupsResourceEnum.platoonGroupId]: PropTypes.number,
  [platoonGroupsResourceEnum.name]: PropTypes.string,
  [platoonGroupsResourceEnum.status]: PropTypes.string,
  [platoonGroupsResourceEnum.companyId]: PropTypes.number,
  [platoonGroupsResourceEnum.routeId]: PropTypes.number,
  [platoonGroupsResourceEnum.startDateFrom]: PropTypes.string,
  [platoonGroupsResourceEnum.startDateTo]: PropTypes.string,
  [platoonGroupsResourceEnum.intervalDuration]: PropTypes.string,
  [platoonGroupsResourceEnum.adviseTokens]: PropTypes.string,
  [platoonGroupsResourceEnum.createdDate]: PropTypes.string,
  [platoonGroupsResourceEnum.modifiedDate]: PropTypes.string,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const platoonGroupsResourceSlice = createSlice({
  name: `${reducerPath.platoonGroupsResource}/counter`,
  initialState: platoonGroupsResourceInitialState,
  reducers: {}
});

export const platoonGroupsResourceReducer = platoonGroupsResourceSlice.reducer;
