// Координаты крайних точек России:
// export const DEFAULT_COORDINATES = [
//   [8963074.7, 12323160], [21172274, 9921970], [5316111, 5042649], [2281695, 7299587]
// ];
// Пустая карта будет иметь в центре Воронеж:
// export const DEFAULT_COORDINATES = [[4363353, 6739671]];
// Пустая карта будет иметь в центре Москву:
// export const DEFAULT_COORDINATES = [[4186726, 7507380]];
// Пустая карта будет включать всю Россию:
export const DEFAULT_EXTENT = [2281695, 5042649, 21172274, 12323160];
// Геометрический центр России:
export const DEFAULT_CENTER = [
  10491862.007266033, 9991826.670600642
];
export const DEFAULT_ZOOM = 3;
