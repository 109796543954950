import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import style from './Loading.module.css';

function Loading() {
  return (
    <Box className={style.container}>
      <CircularProgress />
    </Box>
  );
}

export default Loading;
