import React, { useState } from 'react';
import {
  Alert, AlertTitle,
  Box, Button, Container, TextField, Typography
} from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PropTypes from "prop-types";

function validateEmail(email) {
  // Регулярное выражение для проверки email
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Проверка совпадения с регулярным выражением
  return regex.test(email);
}

function ForgotPasswordView({ postChangePasswordInitParams }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isErr, setIsErr] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);

  const onSubmitForm = () => {
    if (validateEmail(email)) {
      postChangePasswordInitParams({ body: email }).then((e) => {
        if (e?.error) {
          setIsErr('Неверный email.');
        } else {
          setIsSuccess(true);
          setTimeout(() => {
            navigate('/');
          }, 2000);
        }
      });
    } else {
      setIsErr('Неверный формат email.');
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <Box
        sx={{
          marginTop: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Восстановить пароль
        </Typography>
        <Box noValidate sx={{ mt: 1, width: "100%" }}>
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
              setIsErr(null);
              setIsSuccess(null);
            }}
            value={email}
            id="email"
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            required
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Box sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mb: isErr ? 1 : 0
          }}
          >

            <Link to="/" variant="body2">
              Войти
            </Link>
          </Box>
          {isErr && (
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {isErr}
          </Alert>
          )}
          {isSuccess && (
          <Alert severity="success">
            <AlertTitle>Успешно</AlertTitle>
            На почту
            {' '}
            <strong>{email}</strong>
            {' '}
            отправлено сообщение с информацией.
          </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={onSubmitForm}
            disabled={!email}
          >
            Отправить
          </Button>
          <Typography sx={{ textAlign: "center" }} component="p" variant="p">
            Для восстановления пароля введите адрес своей электронной почты.
          </Typography>
        </Box>
      </Box>
    </Container>

  );
}

ForgotPasswordView.propTypes = {
  postChangePasswordInitParams: PropTypes.func,
};

ForgotPasswordView.defaultProps = {
  postChangePasswordInitParams: null,
};

export default ForgotPasswordView;
