// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import vehicleDtoType from "../../types/vehicle-dto.type";

export function vehicleFromDtoService(dto, meta) {
  const { status } = meta.response;
  let data = null;
  // eslint-disable-next-line no-undef
  if (Array.isArray(dto)) {
    data = dto.slice().sort((a, b) => a.name.localeCompare(b.name));
  } else {
    data = dto;
  }

  return { data, status };
}

vehicleFromDtoService.propTypes = {
  dto: vehicleDtoType
};

export default vehicleFromDtoService;
