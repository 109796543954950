import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

// Кнопка редактирования с иконкой и подписью
function EditButton({ onEdit, title }) {
  return (
    <Box>
      <Tooltip title={title}>
        <IconButton
          color="primary"
          onClick={onEdit}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

EditButton.propTypes = {
  onEdit: PropTypes.func.isRequired,
  title: PropTypes.string,
};

EditButton.defaultProps = {
  title: 'Редактировать'
};

export default EditButton;
