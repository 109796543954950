import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const trackPointsResourceEnum = {
  trackPointId: "trackPointId",
  vehicleId: "vehicleId",
  latitude: "latitude",
  longitude: "longitude",
  altitude: "altitude",
  yawRate: "yawRate",
  curvature: "curvature",
  date: "date"
};
// Инициализация стейта в сторе
const trackPointsResourceInitialState = {
  [trackPointsResourceEnum.trackPointId]: NaN,
  [trackPointsResourceEnum.vehicleId]: NaN,
  [trackPointsResourceEnum.latitude]: NaN,
  [trackPointsResourceEnum.longitude]: NaN,
  [trackPointsResourceEnum.altitude]: NaN,
  [trackPointsResourceEnum.yawRate]: NaN,
  [trackPointsResourceEnum.curvature]: NaN,
  [trackPointsResourceEnum.date]: null,
};
// Типизация стора
export const platoonGroupsResourceType = {
  [trackPointsResourceEnum.trackPointId]: PropTypes.number,
  [trackPointsResourceEnum.vehicleId]: PropTypes.number,
  [trackPointsResourceEnum.latitude]: PropTypes.number,
  [trackPointsResourceEnum.longitude]: PropTypes.number,
  [trackPointsResourceEnum.altitude]: PropTypes.number,
  [trackPointsResourceEnum.yawRate]: PropTypes.number,
  [trackPointsResourceEnum.curvature]: PropTypes.number,
  [trackPointsResourceEnum.date]: PropTypes.string,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const trackPointsResourceSlice = createSlice({
  name: `${reducerPath.trackPointsResource}/counter`,
  initialState: trackPointsResourceInitialState,
  reducers: {}
});

export const trackPointsResourceReducer = trackPointsResourceSlice.reducer;
