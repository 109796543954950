export const statuses = {
  CREATED: "CREATED",
  ACTIVE: "ACTIVE",
  REQUEST_ACK: "REQUEST_ACK",
  COMPLETE: "COMPLETE",
  ABORTED: "ABORTED",
  CANCELED: "CANCELED",
};

export const statusesRu = {
  [statuses.CREATED]: "Заявка принята",
  [statuses.ACTIVE]: "ТС следует в группе",
  [statuses.REQUEST_ACK]: "Ожидает подтверждения",
  [statuses.COMPLETE]: "Завершена",
  [statuses.ABORTED]: "Отклонена",
  [statuses.CANCELED]: "Закрыта"
};

export const convertStatus = ((value) => {
  switch (value) {
    case statuses.CREATED:
      return statusesRu.CREATED;
    case statuses.ACTIVE:
      return statusesRu.ACTIVE;
    case statuses.REQUEST_ACK:
      return statusesRu.REQUEST_ACK;
    case statuses.COMPLETE:
      return statusesRu.COMPLETE;
    case statuses.ABORTED:
      return statusesRu.ABORTED;
    default:
      return "";
  }
});
