// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { platoonGroupVehiclesResourceEnum } from "../redux/platoonGroupVehiclesResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const platoonGroupVehiclesResourceDtoType = {
  [platoonGroupVehiclesResourceEnum.platoonGroupId]: PropTypes.number,
};

export default platoonGroupVehiclesResourceDtoType;
