import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const sensorResourceTypeEnum = {
  sensorId: "sensorId",
  dataType: "dataType",
  model: "model",
  className: "className",
  gateType: "gateType",
  gatewayHost: "gatewayHost",
  gatewayPort: "gatewayPort",
};
// Инициализация стейта в сторе
const sensorResourceTypeInitialState = {
  [sensorResourceTypeEnum.sensorId]: NaN,
  [sensorResourceTypeEnum.dataType]: "",
  [sensorResourceTypeEnum.model]: "",
  [sensorResourceTypeEnum.className]: "",
  [sensorResourceTypeEnum.gateType]: "",
  [sensorResourceTypeEnum.gatewayHost]: "",
  [sensorResourceTypeEnum.gatewayPort]: NaN,
};
// Типизация стора
export const sensorResourceType = {
  [sensorResourceTypeEnum.sensorId]: PropTypes.number,
  [sensorResourceTypeEnum.dataType]: PropTypes.string,
  [sensorResourceTypeEnum.model]: PropTypes.string,
  [sensorResourceTypeEnum.className]: PropTypes.string,
  [sensorResourceTypeEnum.gateType]: PropTypes.string,
  [sensorResourceTypeEnum.gatewayHost]: PropTypes.string,
  [sensorResourceTypeEnum.gatewayPort]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const sensorResourceTypeSlice = createSlice({
  name: `${reducerPath.sensorResourceType}/counter`,
  initialState: sensorResourceTypeInitialState,
  reducers: {}
});

export const sensorResourceTypeReducer = sensorResourceTypeSlice.reducer;
