function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Радиус Земли в километрах
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(lat1 * (Math.PI / 180))
        * Math.cos(lat2 * (Math.PI / 180))
        * Math.sin(dLon / 2)
        * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}
// number = 10000000
const number = 1e7;

function splitRoutes(arr) {
  const sortedArr = arr.sort((a, b) => new Date(a.date) - new Date(b.date));
  const routes = [];
  let currentRoute = [sortedArr[0]];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < sortedArr.length; i++) {
    const prevPoint = sortedArr[i - 1];
    const currentPoint = sortedArr[i];
    const distance = calculateDistance(
      prevPoint.latitude / number,
      prevPoint.longitude / number,
      currentPoint.latitude / number,
      currentPoint.longitude / number
    );
    const timeDiff = (new Date(currentPoint.date) - new Date(prevPoint.date)) / (1000 * 60);

    if (distance > 10 || (distance > 1 && timeDiff > 30)) {
      routes.push([...currentRoute]);
      currentRoute = [currentPoint];
    } else {
      currentRoute.push(currentPoint);
    }
  }

  if (currentRoute.length > 0) {
    routes.push(currentRoute);
  }

  return routes;
}

export default splitRoutes;
