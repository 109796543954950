import {
  Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  typeMessages,
  typeSeverity
} from "../../entities/alerts/alertsSettingsResource/services/data/alertsSettingsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import { getRuCategory, getUniqueCategories } from "../alerts/CreateAlertsSettingsWidget";
import { alertsTypesResourceType } from "../../entities/alerts/alertsTypesResource/redux/alertsTypesResource.slice";

function findMissingElements(array1, array2) {
  const missingElements = array1.reduce((acc, item1) => {
    const found = array2.find((item2) => item2.id === item1.code);
    if (!found) {
      acc.push({ id: item1.code, ruName: item1.code });
    }
    return acc;
  }, []);

  return array2.concat(missingElements);
}

// Виджет фильтрации списка "настройки сообщений"
function FiltersSettingsWidget({
  isFiltersOpen, data, fetchAlertsSettingsResourceParams, selectedData, dataAlertsTypesResource
}) {
  const [severity, setSeverity] = useState({});
  const [dataAlertsChannels, setDataAlertsChannels] = useState(null);
  const [channel, setChannel] = useState(null);
  const [category, setCategory] = useState('');
  const [typeMessagesState, setTypeMessagesState] = useState(null);

  useEffect(() => {
    if (data.dataAlertsChannels) {
      setDataAlertsChannels(data.dataAlertsChannels);
    }
  }, [data]);

  useEffect(() => {
    fetchAlertsSettingsResourceParams({
      params: { }
    });
  }, [selectedData]);

  const handleClear = useCallback(() => {
    setSeverity({});
    setChannel(null);
    setCategory('');
    setTypeMessagesState(null);
  }, []);

  const handleSearch = useCallback(() => {
    const params = {};
    if (severity?.id) {
      params["severity.equals"] = severity?.id;
    }

    if (channel?.alertChannelId) {
      params["channelId.equals"] = channel?.alertChannelId;
    }

    if (typeMessagesState?.id) {
      params["code.equals"] = typeMessagesState?.id;
    }

    if (category) {
      params["category.equals"] = category;
    }

    fetchAlertsSettingsResourceParams({
      params
    });
  }, [severity, channel, category, typeMessagesState]);

  const isDisp = isFiltersOpen ? { display: 'flex' } : { display: 'none' };

  return (
    <Box sx={{ ...isDisp }}>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={4}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="adapterTypeId-select-label">Серьезность</InputLabel>
              <Select
                labelId="adapterTypeId-select-label"
                id="adapterTypeId-select"
                value={severity?.id || ''}
                label="Серьезность"
                onChange={((e) => {
                  setSeverity(typeSeverity.filter((item) => item.id === e.target.value)[0]);
                })}
              >
                {typeSeverity.map((severityItem) => (
                  <MenuItem
                    key={`severityItem-select-item${severityItem.id}`}
                    value={severityItem.id}
                  >
                    {severityItem.ruName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessages-select-label">Информационный канал</InputLabel>
              <Select
                labelId="typeMessages-select-label"
                id="typeMessages-select"
                value={channel?.alertChannelId || 0}
                label="Информационный канал"
                onChange={((e) => {
                  setChannel(dataAlertsChannels
                    .filter((item) => e.target.value === item.alertChannelId)[0]);
                })}
              >
                {dataAlertsChannels?.map((adapterType) => (
                  <MenuItem
                    key={`alertChannelId-select-item${adapterType.alertChannelId}`}
                    value={adapterType.alertChannelId}
                  >
                    {adapterType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessages-select-label">Тип сообщений</InputLabel>
              <Select
                labelId="typeMessages-select-label"
                id="typeMessages-select"
                value={typeMessagesState?.id || ''}
                label="Тип сообщений"
                onChange={((e) => {
                  setTypeMessagesState(findMissingElements(
                    dataAlertsTypesResource?.data,
                    typeMessages
                  )
                    .filter((item) => item.id === e.target.value)[0]);
                })}
              >
                {findMissingElements(dataAlertsTypesResource?.data, typeMessages)
                  ?.map((typeMessagesItem) => (
                    <MenuItem
                      key={`severityItem-select-item${typeMessagesItem.id}`}
                      value={typeMessagesItem.id}
                    >
                      {typeMessagesItem.ruName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessages-select-label">Категория</InputLabel>

              <Select
                labelId="category-label"
                id="category-label"
                value={category || 0}
                label="Категория сообщений"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {[0, ...getUniqueCategories(dataAlertsTypesResource?.data)]
                  ?.map((alertsType, i) => (
                    <MenuItem
                      key={`CategoriesType-select-item${alertsType}-${i + 1}`}
                      value={alertsType}
                    >
                      {getRuCategory(alertsType)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'right', gap: "30px" }}>
            <Button
              disableElevation
              onClick={handleClear}
              variant="text"
            >
              Сбросить
            </Button>
            <Button
              disableElevation
              onClick={handleSearch}
              variant="contained"
            >
              Поиск
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ pt: 1 }} />
    </Box>
  );
}

FiltersSettingsWidget.propTypes = {
  isFiltersOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    dataAlertsChannels: PropTypes.shape({})
  }),
  fetchAlertsSettingsResourceParams: PropTypes.func,
  selectedData: PropTypes.number,
  dataAlertsTypesResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(alertsTypesResourceType))
  }).isRequired
};

FiltersSettingsWidget.defaultProps = {
  data: null,
  fetchAlertsSettingsResourceParams: null,
  selectedData: null
};

export default FiltersSettingsWidget;
