// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { adapterResourceTypeEnum } from "../redux/adapterResourceType.slice";

// тут описываем тип то что приходит с бека в сыром виде
const adapterResourceTypeDtoType = {
  [adapterResourceTypeEnum.adapterTypeId]: PropTypes.number,
  [adapterResourceTypeEnum.model]: PropTypes.string,
  [adapterResourceTypeEnum.className]: PropTypes.string,
  [adapterResourceTypeEnum.gateType]: PropTypes.string,
  [adapterResourceTypeEnum.gatewayHost]: PropTypes.string,
  [adapterResourceTypeEnum.gatewayPort]: PropTypes.number,
};

export default adapterResourceTypeDtoType;
