import {
  Box, Button, Collapse, Grid, TextField
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import style from './FiltersRoutesWidget.module.css';
import ActionBarDateTimePicker from '../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import MultipleSelect from '../../shared/ui/MultipleSelect';

// Существующие поля фильтрации
const defaultFilters = {
  name: '',
  vehicleName: '',
  vehicleNum: '',
  startTime: null,
  finishTime: null,
  status: [],
};
// Панель фильтрации с полями фильтрации и кнопками "Сброс", "Поиск"
function FiltersRoutesWidget(props) {
  const {
    isFiltersOpen,
    handleFilter,
    initialFilters,
    statuses,
    updatedFilters
  } = props;
  // Состояние текущих фильтров
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...updatedFilters,
  });

  // Обработка изменений в текстовом поле
  const changeTextField = (event) => {
    setFilters((prev) => ({ ...prev, [event.target.id]: event.target.value }));
  };
  // Обработка изменений в комбобоксе
  const changeSelect = (event) => {
    setFilters((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  // Сброс фильтров при нажатии на кнопку
  const handleResetFilters = () => {
    setFilters({
      ...defaultFilters,
      ...initialFilters,
    });
  };

  return (
    <Collapse in={isFiltersOpen} className={style.collapseItem}>
      <Box className={style.FiltersRoutesWidget}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DateTimePicker
              label="Дата отправления"
              value={filters.startTime}
              onChange={(newDate) => {
                setFilters((prev) => ({ ...prev, startTime: newDate }));
              }}
              inputProps={{
                placeholder: 'дд.мм.гггг чч:мм',
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimePicker
              label="Дата прибытия"
              value={filters.finishTime}
              onChange={(newDate) => {
                setFilters((prev) => ({ ...prev, finishTime: newDate }));
              }}
              inputProps={{
                placeholder: 'дд.мм.гггг чч:мм',
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <MultipleSelect
              name="status"
              items={statuses}
              value={filters.status}
              onChange={changeSelect}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="name"
              label="Название маршрута"
              value={filters.name}
              onChange={changeTextField}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              id="vehicleName"
              label="Название ТС"
              value={filters.vehicleName}
              onChange={changeTextField}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              id="vehicleNum"
              label="Номер ТС"
              value={filters.vehicleNum}
              onChange={changeTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={style.filterButtons}>
              <Button
                disableElevation
                onClick={handleResetFilters}
                variant="text"
              >
                Сбросить
              </Button>
              <Button
                disableElevation
                onClick={() => handleFilter(filters)}
                variant="contained"
              >
                Поиск
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
}

const filtersProps = {
  name: PropTypes.string.isRequired,
  vehicleName: PropTypes.string.isRequired,
  vehicleNum: PropTypes.string.isRequired,
  startTime: PropTypes.instanceOf(Date),
  finishTime: PropTypes.instanceOf(Date),
  status: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

FiltersRoutesWidget.propTypes = {
  isFiltersOpen: PropTypes.bool.isRequired,
  handleFilter: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  updatedFilters: PropTypes.shape(filtersProps).isRequired,
  initialFilters: PropTypes.shape(filtersProps).isRequired,
};

export default FiltersRoutesWidget;
