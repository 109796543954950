import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useSearchParams,
} from 'react-router-dom';
import style from './CompanyReportsPanel.module.css';
import ReportCardWidget from '../../../widgets/companies/reports/ReportCardWidget/ReportCardWidget';
import ReportsBreadcrumbsWidget from '../../../widgets/companies/reports/ReportsBreadcrumbsWidget/ReportsBreadcrumbsWidget';
import ReportsListWidget from '../../../widgets/companies/reports/ReportsListWidget/ReportsListWidget';

// Отображает панель "Отчеты" по компании
function CompanyReportsPanel({ companyId }) {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('report');
  return (
    <Box className={style.panel}>
      <ReportsBreadcrumbsWidget />
      <Box
        sx={reportId ? { display: 'none' } : undefined}
      >
        <ReportsListWidget companyId={companyId} />
      </Box>
      {reportId && <ReportCardWidget companyId={companyId} />}
    </Box>
  );
}

CompanyReportsPanel.propTypes = {
  companyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

CompanyReportsPanel.defaultProps = {
  companyId: null,
};

export default CompanyReportsPanel;
