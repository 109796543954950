import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

const sizesQR = [5, 7, 10].map((size) => ({
  value: size,
  label: `${size}см X ${size}см`
}));

const QrForm = React.forwardRef((props, ref) => {
  const {
    customContent,
    isChangedSize,
  } = props;

  const [sizeQR, setSizeQR] = React.useState(sizesQR[0].value);
  const [isName, setIsName] = React.useState(false);
  const [isTags, setIsTags] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    state: {
      qrOptions: {
        sizeQR,
        isName,
        isTags,
      }
    },
  }));

  const handleChange = (event) => {
    setSizeQR(event.target.value);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {customContent}
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '200px',
          }}
        >
          {isChangedSize && (
            <FormControl variant="standard" sx={{ minWidth: 140 }}>
              <InputLabel id="sizeQR-label">Размер QR-кода</InputLabel>
              <Select
                labelId="sizeQR-label"
                id="sizeQR"
                value={sizeQR}
                onChange={handleChange}
                label="Размер QR-кода"
              >
                {sizesQR.map((size) => (
                  <MenuItem value={size.value}>{size.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControlLabel
            control={
              <Checkbox checked={isName} onChange={(e) => setIsName(e.target.checked)} name="isName" />
            }
            label="Печать ФИО"
          />
          <FormControlLabel
            control={
              <Checkbox checked={isTags} onChange={(e) => setIsTags(e.target.checked)} name="isTags" />
            }
            label="Печать меток"
          />
        </Box>
      </Box>
    </Box>
  );
});

QrForm.propTypes = {
  customContent: PropTypes.node,
  isChangedSize: PropTypes.bool,
};

QrForm.defaultProps = {
  customContent: null,
  isChangedSize: false,
};

export default QrForm;
