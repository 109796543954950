import {
  FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput,
  Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WRONG_NUMBER_VALUE = "Поле должно содержать число";

const checkNumber = (value) => Number.isNaN(value.trim());

const INIT_ERRORS = {
  limit: false,
  notifyLimit: false,
};

// Отображает вкладку "Блокировка и оповещения" с полями редакитрования
function CompanyLimits({
  companyAccount, loading, onChangeCB
}) {
  const [limitsForm, setLimitsForm] = useState({});
  const [errorsForm, setErrorsForm] = useState(INIT_ERRORS);

  useEffect(() => {
    if (companyAccount) {
      setLimitsForm({
        limit: companyAccount.limit,
        notifyLimit: companyAccount.notifyLimit
      });
      setErrorsForm(INIT_ERRORS);
    }
  }, [companyAccount]);

  const handleChangeText = (event) => {
    setLimitsForm((prev) => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
    setErrorsForm((prev) => ({
      ...prev,
      [event.target.id]: checkNumber(event.target.value)
    }));
    switch (event.target.id) {
      case 'limit':
        onChangeCB(
          {
            limit: +event.target.value,
            notifyLimit: +limitsForm.notifyLimit,
          }
        );
        break;
      case 'notifyLimit':
        onChangeCB(
          {
            limit: +limitsForm.limit,
            notifyLimit: +event.target.value,
          }
        );
        break;
      default:
    }
  };

  return (
    <Stack sx={{ pt: 2 }} direction="horizontal" gap="30px">
      <FormControl
        fullWidth
        error={errorsForm.limit}
        sx={{ m: 0, maxWidth: '400px' }}
        onChange={handleChangeText}
        disabled={loading}
      >
        <InputLabel htmlFor="limit">Блокировать при балансе ниже</InputLabel>
        <OutlinedInput
          type="number"
          id="limit"
          size="small"
          label="Блокировать при балансе ниже"
          startAdornment={<InputAdornment position="start">₽</InputAdornment>}
          value={limitsForm.limit}
        />
        {errorsForm.limit && <FormHelperText>{WRONG_NUMBER_VALUE}</FormHelperText>}
      </FormControl>
      <FormControl
        fullWidth
        error={errorsForm.notifyLimit}
        sx={{ m: 0, maxWidth: '400px' }}
        onChange={handleChangeText}
        disabled={loading}
      >
        <InputLabel htmlFor="notifyLimit">Оповещать при балансе ниже</InputLabel>
        <OutlinedInput
          type="number"
          id="notifyLimit"
          size="small"
          label="Оповещать при балансе ниже"
          startAdornment={<InputAdornment position="start">₽</InputAdornment>}
          value={limitsForm.notifyLimit}
        />
        {errorsForm.notifyLimit && <FormHelperText>{WRONG_NUMBER_VALUE}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

CompanyLimits.propTypes = {
  companyAccount: PropTypes.shape({
    limit: PropTypes.number,
    notifyLimit: PropTypes.number,
    companyAccountId: PropTypes.number,
  }),
  onChangeCB: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CompanyLimits.defaultProps = {
  companyAccount: null,
  loading: null,
};

export default CompanyLimits;
