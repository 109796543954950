export const statuses = {
  ERROR: "ERROR",
  CRITICAL: "CRITICAL",
  INFO: "INFO",
  WARNING: "WARNING",
  ALL: "ALL"
};

export const statusesRu = {
  [statuses.ERROR]: "Важно",
  [statuses.CRITICAL]: "Критично",
  [statuses.INFO]: "Информация",
  [statuses.WARNING]: "Предупреждение",
  [statuses.ALL]: "Все",
};
