import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const platoonGroupParamsResourceEnum = {
  platoonGroupId: "platoonGroupId",
};

// Инициализация стейта в сторе
const platoonGroupParamsResourceInitialState = {
  [platoonGroupParamsResourceEnum.platoonGroupId]: NaN,
};
// Типизация стора
export const platoonGroupParamsResourceType = {
  [platoonGroupParamsResourceEnum.platoonGroupId]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const platoonGroupParamsResourceSlice = createSlice({
  name: `${reducerPath.platoonGroupParamsResource}/counter`,
  initialState: platoonGroupParamsResourceInitialState,
  reducers: {}
});

export const platoonGroupParamsResourceReducer = platoonGroupParamsResourceSlice.reducer;
