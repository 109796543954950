import {
  Autocomplete,
  Box, Button, Checkbox, FormControlLabel, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useConfirm } from 'material-ui-confirm';

const privileges = {
  DIV_PRIV_READ: 'DIV_PRIV_READ',
  DIV_PRIV_VEHICLE_ADMIN: 'DIV_PRIV_VEHICLE_ADMIN',
  DIV_PRIV_USER_ADMIN: 'DIV_PRIV_USER_ADMIN',
};

// Отображает форму редактирования доступа к подразделению для пользователя
function DivisionAccessFormWidget(props) {
  const {
    appUsers, appUserAccesses, appUserId, onCancel, onSave, allAppUsers
  } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [accesses, setAccesses] = useState({
    DIV_PRIV_READ: Boolean(appUserAccesses?.find(
      (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_READ
    )),
    DIV_PRIV_VEHICLE_ADMIN: Boolean(appUserAccesses?.find(
      (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_VEHICLE_ADMIN
    )),
    DIV_PRIV_USER_ADMIN: Boolean(appUserAccesses?.find(
      (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_USER_ADMIN
    )),
  });

  const [isChangedForm, setIsChangedForm] = useState(false);
  // Обработка изменений в чекбоксе
  const changeCheckbox = (event) => {
    if (!isChangedForm) {
      setIsChangedForm(true);
    }
    setAccesses((prev) => ({ ...prev, [event.target.id]: event.target.checked }));
  };

  useEffect(() => {
    if (appUserAccesses?.length) {
      setAccesses({
        DIV_PRIV_READ: Boolean(appUserAccesses?.find(
          (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_READ
        )),
        DIV_PRIV_VEHICLE_ADMIN: Boolean(appUserAccesses?.find(
          (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_VEHICLE_ADMIN
        )),
        DIV_PRIV_USER_ADMIN: Boolean(appUserAccesses?.find(
          (appUserAccess) => appUserAccess.divPrivilege === privileges.DIV_PRIV_USER_ADMIN
        )),
      });
    }
  }, [appUserAccesses]);
  const confirm = useConfirm();

  const currentUser = allAppUsers.find((e) => e.appUserId === appUserId);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        pt: '20px',
      }}
    >
      {appUserId
        ? (
          <Typography variant="subtitle2" component="div">
            {currentUser?.firstName}
            {' '}
            {currentUser?.lastName}
          </Typography>
        )
        : (
          <Autocomplete
            size="small"
            disableClearable
            value={selectedUser}
            onChange={(event, newValue) => {
              if (!isChangedForm) {
                setIsChangedForm(true);
              }
              setSelectedUser(newValue);
            }}
            id="status"
            options={appUsers}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Пользователь" />}
          />
        )}
      <FormControlLabel
        control={(
          <Checkbox
            id="DIV_PRIV_READ"
            checked={accesses.DIV_PRIV_READ}
            color="primary"
            onChange={changeCheckbox}
          />
        )}
        label="Чтение"
      />
      <FormControlLabel
        control={(
          <Checkbox
            id="DIV_PRIV_VEHICLE_ADMIN"
            checked={accesses.DIV_PRIV_VEHICLE_ADMIN}
            color="primary"
            onChange={changeCheckbox}
          />
        )}
        label="Администрирование ТС"
      />
      <FormControlLabel
        control={(
          <Checkbox
            id="DIV_PRIV_USER_ADMIN"
            checked={accesses.DIV_PRIV_USER_ADMIN}
            color="primary"
            onChange={changeCheckbox}
          />
        )}
        label="Администрирование пользователей"
      />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          gap: '20px',
        }}
      >
        <Button
          disableElevation
          onClick={(e) => {
            if (isChangedForm) {
              confirm({
                title: 'Отменить изменения?',
                confirmationText: 'Да',
                cancellationText: 'Нет',
                description: `Все несохраненные изменения будут недоступны. Вы действительно хотите отменить изменения?`
              })
                .then(() => onCancel(e))
                .catch(() => { });
            } else {
              onCancel(e);
            }
          }}
          variant="text"
        >
          Отмена
        </Button>
        <Button
          disableElevation
          onClick={() => onSave({ selectedUser, accesses })}
          variant="contained"
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );
}

DivisionAccessFormWidget.propTypes = {
  appUsers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  appUserAccesses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  appUserId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  allAppUsers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

DivisionAccessFormWidget.defaultProps = {
  appUsers: [],
  appUserAccesses: [],
  appUserId: NaN,
  onSave: null,
  allAppUsers: []
};

export default DivisionAccessFormWidget;
