import { SvgIcon } from '@mui/material';
import React from 'react';

function RouteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M24.375 18.75H18.75C17.7188 18.75 16.875 17.9062 16.875 16.875C16.875 15.8438 17.7188 15 18.75 15H24.375C24.375 15 30 8.73047 30 5.625C30 2.51953 27.4805 0 24.375 0C21.2695 0 18.75 2.51953 18.75 5.625C18.75 7.11914 20.0508 9.33984 21.4043 11.25H18.75C15.6504 11.25 13.125 13.7754 13.125 16.875C13.125 19.9746 15.6504 22.5 18.75 22.5H24.375C25.4062 22.5 26.25 23.3438 26.25 24.375C26.25 25.4062 25.4062 26.25 24.375 26.25H10.8691C9.93164 27.7031 8.88867 29.0449 8.09766 30H24.375C27.4746 30 30 27.4746 30 24.375C30 21.2754 27.4746 18.75 24.375 18.75ZM24.375 3.75C25.4121 3.75 26.25 4.58789 26.25 5.625C26.25 6.66211 25.4121 7.5 24.375 7.5C23.3379 7.5 22.5 6.66211 22.5 5.625C22.5 4.58789 23.3379 3.75 24.375 3.75ZM5.625 15C2.51953 15 0 17.5195 0 20.625C0 23.7305 5.625 30 5.625 30C5.625 30 11.25 23.7305 11.25 20.625C11.25 17.5195 8.73047 15 5.625 15ZM5.625 22.5C4.58789 22.5 3.75 21.6621 3.75 20.625C3.75 19.5879 4.58789 18.75 5.625 18.75C6.66211 18.75 7.5 19.5879 7.5 20.625C7.5 21.6621 6.66211 22.5 5.625 22.5Z" />
    </SvgIcon>
  );
}

export default RouteIcon;
