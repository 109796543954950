import PropTypes from 'prop-types';
import { Button, DialogActions } from '@mui/material';
import React from 'react';

function ActionBarDateTimePicker(props) {
  const { onSetToday, onClear } = props;

  return (
    <DialogActions sx={{ justifyContent: 'space-between', pl: '20px', pr: '20px' }}>
      <Button onClick={onClear}> Очистить </Button>
      <Button onClick={onSetToday}> Сегодня </Button>
    </DialogActions>
  );
}

ActionBarDateTimePicker.propTypes = {
  onSetToday: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ActionBarDateTimePicker;
