import React, { useEffect, useState } from 'react';
import {
  Alert, AlertTitle,
  Box, Button, FormControlLabel, FormGroup, FormLabel, styled, Switch, TextField
} from '@mui/material';
import { pink } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import PropTypes, { shape } from "prop-types";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import style from './TariffEditMainPanel.module.css';
import ActionBarDateTimePicker from "../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker";
import { tariffPlansResourceType } from "../../../entities/finances/tariffPlansResource/redux/tariffPlansResource.slice";
import Loading from '../../../shared/ui/Loading/Loading';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

// Отображает панель "Тарифы редактирование"
function TariffEditMainPanel({ data, putTariffPlanResourceParams }) {
  const [finishDate, setFinishDate] = useState(null);
  const [funcGroupMvmnt, setFuncGroupMvmnt] = useState(false);
  const [funcRouteMonitor, setFuncRouteMonitor] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [name, setName] = useState('');
  const [payPerDay, setPayPerDay] = useState('');
  const [cargoPerMonth, setСargoPerMonth] = useState('');
  const [cargoPerDay, setСargoPerDay] = useState('');
  const [payPerDayActiveVehicle, setPayPerDayActiveVehicle] = useState('');
  const [payPerDayVehicle, setPayPerDayVehicle] = useState('');
  const [payPerMonth, setPayPerMonth] = useState('');
  const [payPerMonthVehicle, setPayPerMonthVehicle] = useState('');
  const [privateRouteCount, setPrivateRouteCount] = useState('');
  const [publicRouteCount, setPublicRouteCount] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [vehicleActiveCount, setVehicleActiveCount] = useState('');
  const [vehicleCount, setVehicleCount] = useState('');
  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [historyDays, setHistoryDays] = useState('');
  const [funcFuelCoef, setFuncFuelCoef] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.dataTariffPlansResourceId?.data?.data) {
      const tariff = data.dataTariffPlansResourceId.data?.data;
      setFinishDate(tariff.finishDate ? new Date(tariff.finishDate) : null);
      setFuncGroupMvmnt(tariff.funcGroupMvmnt);
      setFuncRouteMonitor(tariff.funcRouteMonitor);
      setIsPublic(!(tariff.isPublic === "Нет"));
      setName(tariff.name);
      setPayPerDay(tariff.payPerDay);
      setСargoPerMonth(tariff.cargoPerMonth || '');
      setСargoPerDay(tariff.cargoPerDay || '');
      setPayPerDayActiveVehicle(tariff.payPerDayActiveVehicle);
      setPayPerDayVehicle(tariff.payPerDayVehicle);
      setPayPerMonth(tariff.payPerMonth);
      setPayPerMonthVehicle(tariff.payPerMonthVehicle);
      setPrivateRouteCount(tariff.privateRouteCount);
      setPublicRouteCount(tariff.publicRouteCount);
      setStartDate(tariff.startDate ? new Date(tariff.startDate) : null);
      setVehicleActiveCount(tariff.vehicleActiveCount);
      setVehicleCount(tariff.vehicleCount);
      setHistoryDays(tariff.historyDays);
      setFuncFuelCoef(tariff.funcFuelCoef);
      setIsDefault(tariff.isDefault);
    }
  }, [data]);

  const handleSave = () => {
    const newData = { ...data?.dataTariffPlansResourceId?.data?.data };
    newData.name = name;
    newData.finishDate = finishDate?.toISOString();
    newData.funcGroupMvmnt = funcGroupMvmnt;
    newData.funcRouteMonitor = funcRouteMonitor;
    newData.isPublic = isPublic;
    newData.isDefault = isDefault;
    newData.name = name;
    newData.payPerDay = payPerDay;
    newData.payPerDayActiveVehicle = payPerDayActiveVehicle;
    newData.payPerDayVehicle = payPerDayVehicle;
    newData.payPerMonth = payPerMonth;
    newData.payPerMonthVehicle = payPerMonthVehicle;
    newData.privateRouteCount = privateRouteCount;
    newData.publicRouteCount = publicRouteCount;
    newData.startDate = startDate?.toISOString();
    newData.vehicleActiveCount = vehicleActiveCount;
    newData.vehicleCount = vehicleCount;
    newData.historyDays = historyDays;
    newData.funcFuelCoef = funcFuelCoef;
    newData.cargoPerMonth = cargoPerMonth;
    newData.cargoPerDay = cargoPerDay;

    putTariffPlanResourceParams({
      id: newData.tarifPlanId,
      body: newData
    }).then((e) => {
      if (e?.error) {
        setErr(true);
      } else {
        setIsCorrect(true);
        setIsEdit(false);
      }
    });
  };

  return (
    <Box>
      {data?.dataTariffPlansResourceId?.status === 'pending' ? <Loading /> : null}
      {data?.dataTariffPlansResourceId?.status === 'rejected' ? (
        <Alert severity="error">
          <AlertTitle>Ошибка</AlertTitle>
          Ошибка при загрузке данных
        </Alert>
      ) : null}
      {data?.dataTariffPlansResourceId?.status === 'fulfilled' ? (
        <>
          <Box className={style.form}>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Общая информация</FormLabel>
              <Box className={style.group}>
                <TextField
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  label="Название"
                  variant="standard"
                  disabled={!isEdit}
                />
                <DateTimePicker
                  label="Дата начала"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                  disabled={!isEdit}
                />
                <DateTimePicker
                  label="Дата окончания"
                  value={finishDate}
                  onChange={(e) => {
                    setFinishDate(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                  disabled={!isEdit}
                />
              </Box>
              <Box className={style.group}>
                <FormControlLabel
                  control={(
                    <PinkSwitch
                      {...label}
                      onClick={() => {
                        setIsPublic(!isPublic);
                      }}
                      checked={isPublic}
                      disabled={!isEdit}
                    />
                        )}
                  label="Публичный тарифный план"
                />
              </Box>
              <Box className={style.group}>
                <FormControlLabel
                  control={(
                    <PinkSwitch
                      {...label}
                      disabled={!isEdit}
                      onClick={() => {
                        setIsDefault((prev) => !prev);
                      }}
                      checked={isDefault}
                    />
                        )}
                  label="План по-умолчанию"
                />
              </Box>
            </Box>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Ограничения</FormLabel>
              <Box className={style.group}>
                <Box className={style.verticalBox}>
                  <Box className={style.horizontalBox}>
                    <TextField
                      onChange={(e) => {
                        setPublicRouteCount(e.target.value);
                      }}
                      value={publicRouteCount}
                      label="Кол-во публичных маршрутов"
                      variant="standard"
                      disabled={!isEdit}
                    />
                    <TextField
                      onChange={(e) => {
                        setPrivateRouteCount(e.target.value);
                      }}
                      value={privateRouteCount}
                      label="Кол-во приватных маршрутов"
                      variant="standard"
                      disabled={!isEdit}
                    />
                  </Box>
                  <Box className={style.horizontalBox}>
                    <TextField
                      onChange={(e) => {
                        setVehicleCount(e.target.value);
                      }}
                      value={vehicleCount}
                      label="Кол-во ТС"
                      variant="standard"
                      disabled={!isEdit}
                    />
                    <TextField
                      onChange={(e) => {
                        setVehicleActiveCount(e.target.value);
                      }}
                      value={vehicleActiveCount}
                      label="Кол-во активных ТС"
                      variant="standard"
                      disabled={!isEdit}
                    />
                  </Box>
                  <Box className={style.horizontalBox}>
                    <TextField
                      onChange={(e) => {
                        setHistoryDays(e.target.value);
                      }}
                      value={historyDays}
                      label="История движения, дней"
                      variant="standard"
                      disabled={!isEdit}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Доступные функции</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <PinkSwitch
                      {...label}
                      onClick={() => {
                        setFuncRouteMonitor(!funcRouteMonitor);
                      }}
                      checked={funcRouteMonitor}
                      disabled={!isEdit}
                    />
                        )}
                  label="Мониторинг движения"
                />
                <FormControlLabel
                  control={(
                    <PinkSwitch
                      {...label}
                      onClick={() => {
                        setFuncFuelCoef(!funcFuelCoef);
                      }}
                      checked={funcFuelCoef}
                      disabled={!isEdit}
                    />
                        )}
                  label="Топливная эффективность"
                />
                <FormControlLabel
                  control={(
                    <PinkSwitch
                      {...label}
                      onClick={() => {
                        setFuncGroupMvmnt(!funcGroupMvmnt);
                      }}
                      checked={funcGroupMvmnt}
                      disabled={!isEdit}
                    />
                        )}
                  label="Движение в группах / Логистика"
                />
              </FormGroup>
            </Box>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Оплата фиксированная</FormLabel>
              <Box className={style.group}>
                <TextField
                  onChange={(e) => {
                    setPayPerMonth(e.target.value);
                  }}
                  value={payPerMonth}
                  label="В месяц, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
                <TextField
                  onChange={(e) => {
                    setPayPerDay(e.target.value);
                  }}
                  value={payPerDay}
                  label="В день, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
              </Box>
            </Box>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Оплата ТС</FormLabel>
              <Box className={style.group}>
                <TextField
                  onChange={(e) => {
                    setPayPerMonthVehicle(e.target.value);
                  }}
                  value={payPerMonthVehicle}
                  label="В месяц, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
                <TextField
                  onChange={(e) => {
                    setPayPerDayVehicle(e.target.value);
                  }}
                  value={payPerDayVehicle}
                  label="В день, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
                <TextField
                  onChange={(e) => {
                    setPayPerDayActiveVehicle(e.target.value);
                  }}
                  value={payPerDayActiveVehicle}
                  label="За активное ТС в день, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
              </Box>
            </Box>
            <Box className={style.boxTitle}>
              <FormLabel component="legend">Геопозиционирование грузов и пассажиров</FormLabel>
              <Box className={style.group}>
                <TextField
                  onChange={(e) => {
                    setСargoPerMonth(e.target.value);
                  }}
                  value={cargoPerMonth}
                  label="В месяц, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
                <TextField
                  onChange={(e) => {
                    setСargoPerDay(e.target.value);
                  }}
                  value={cargoPerDay}
                  label="В день, руб"
                  variant="standard"
                  disabled={!isEdit}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: "30px" }}>
            {!isErr && isCorrect
                    && (
                    <Alert sx={{ width: "100%" }} severity="success">
                      <AlertTitle>Успешно</AlertTitle>
                      Тариф был успешно изменен.
                    </Alert>
                    )}
            {isErr
                    && (
                    <Alert sx={{ width: "100%" }} severity="error">
                      <AlertTitle>Ошибка</AlertTitle>
                      Произошла ошибка при изменении тарифа
                    </Alert>
                    )}
          </Box>
          <Box className={style.group}>
            {isEdit && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSave}
            >
              Сохранить
            </Button>
            )}
            {!isEdit && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                setIsEdit(true);
                setErr(false);
                setIsCorrect(false);
              }}
            >
              Редактировать
            </Button>
            )}
            {isEdit && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => { setIsEdit(false); }}
            >
              Отменить
            </Button>
            )}
            {!isEdit && (
            <Button
              variant="contained"
              onClick={() => navigate(`/finances/tariff-plans`)}
              sx={{ mt: 3, mb: 2 }}
            >
              Назад
            </Button>
            )}
          </Box>
        </>
      ) : null}
    </Box>
  );
}

TariffEditMainPanel.propTypes = {
  data: PropTypes.shape({
    dataTariffPlansResourceId: shape({
      data: PropTypes.shape(tariffPlansResourceType)
    }),
  }),
  putTariffPlanResourceParams: PropTypes.func,
};

TariffEditMainPanel.defaultProps = {
  data: null,
  putTariffPlanResourceParams: null,
};

export default TariffEditMainPanel;
