import {
  Box, Button, Collapse, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './DivisionAccessFiltersWidget.module.css';

const privilegesRu = {
  ALL: 'Все',
  DIV_PRIV_READ: 'Чтение',
  DIV_PRIV_VEHICLE_ADMIN: 'Администрирование ТС',
  DIV_PRIV_USER_ADMIN: 'Администрирование пользователей',
};

const initialFilters = {
  firstName: '',
  lastName: '',
  access: 'ALL'
};

// Отображает поля фильтрации и кнопки применения/сброса фильтров
function DivisionAccessFiltersWidget({ isOpenFilters, applyFilters }) {
  const [filters, setFilters] = useState({ ...initialFilters });

  // Сброс полей фильтров
  const handleResetFilters = () => {
    setFilters({ ...initialFilters });
  };

  return (
    <Collapse in={isOpenFilters}>
      <Box
        className={style.tariffPlansFiltersWidget}
      >
        <Box
          className={style.filters}
        >
          <TextField
            fullWidth
            size="small"
            id="firstName"
            label="Имя"
            value={filters.firstName}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, firstName: e.target.value }));
            }}
          />
          <TextField
            fullWidth
            size="small"
            id="lastName"
            label="Фамилия"
            value={filters.lastName}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, lastName: e.target.value }));
            }}
          />
          <FormControl size="small" fullWidth>
            <InputLabel>Доступ</InputLabel>
            <Select
              id="access"
              value={filters.access}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, access: e.target.value }));
              }}
              label="Доступ"
            >
              {Object.keys(privilegesRu).map((key) => (
                <MenuItem key={key} value={key}>{privilegesRu[key]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={style.filterButtons}>
            <Button
              disableElevation
              onClick={handleResetFilters}
              variant="text"
            >
              Сбросить
            </Button>
            <Button
              disableElevation
              onClick={() => applyFilters(filters)}
              variant="contained"
            >
              Поиск
            </Button>
          </Box>
        </Box>

      </Box>
    </Collapse>
  );
}

DivisionAccessFiltersWidget.propTypes = {
  isOpenFilters: PropTypes.bool.isRequired,
  applyFilters: PropTypes.func.isRequired,
};

export default DivisionAccessFiltersWidget;
