function convertToISOTime(time) {
  if (time.includes("PT")) {
    return time;
  }

  const regex = /^((\d+)\s*ч)?\s*((\d+)\s*мин)?\s*((\d+)\s*сек)?$/;
  const match = time.match(regex);

  if (!match) {
    throw new Error('Неверный формат времени');
  }

  const hours = match[2] ? parseInt(match[2], 10) : 0;
  const minutes = match[4] ? parseInt(match[4], 10) : 0;
  const seconds = match[6] ? parseInt(match[6], 10) : 0;

  // Форматируем значения часов, минут и секунд в соответствии с ISO 8601
  const isoHours = hours ? `${hours}H` : '';
  const isoMinutes = minutes ? `${minutes}M` : '';
  const isoSeconds = seconds ? `${seconds}S` : '';

  // Объединяем значения в строку формата ISO 8601
  const isoTime = `PT${isoHours}${isoMinutes}${isoSeconds}`;

  return isoTime;
}

export default convertToISOTime;
