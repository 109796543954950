import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.vehicleFuelEfficiency/api`
export const vehicleFuelEfficiencyEnum = {
  vehicleId: "vehicleId",
  date: "date",
  lat: "lat",
  lon: "lon",
  altUpSum: "altUpSum",
  altDownSum: "altDownSum",
  fuelCoef: "fuelCoef",
  fuelCoefUp: "fuelCoefUp",
  fuelCoefDown: "fuelCoefDown",
  fuelDiff: "fuelDiff",
  fuelDiffMinus: "fuelDiffMinus",
  gas: "gas",
};
// Инициализация стейта в сторе
const vehicleFuelEfficiencyInitialState = {
  [vehicleFuelEfficiencyEnum.vehicleId]: NaN,
  [vehicleFuelEfficiencyEnum.date]: "",
  [vehicleFuelEfficiencyEnum.lat]: "",
  [vehicleFuelEfficiencyEnum.lon]: "",
  [vehicleFuelEfficiencyEnum.altUpSum]: "",
  [vehicleFuelEfficiencyEnum.altDownSum]: "",
  [vehicleFuelEfficiencyEnum.fuelCoef]: "",
  [vehicleFuelEfficiencyEnum.fuelCoefUp]: "",
  [vehicleFuelEfficiencyEnum.fuelCoefDown]: "",
  [vehicleFuelEfficiencyEnum.fuelDiff]: "",
  [vehicleFuelEfficiencyEnum.fuelDiffMinus]: "",
  [vehicleFuelEfficiencyEnum.gas]: "",
};
// Типизация стора
export const vehicleFuelEfficiencyType = {
  [vehicleFuelEfficiencyEnum.vehicleId]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.date]: PropTypes.string,
  [vehicleFuelEfficiencyEnum.lat]: PropTypes.string,
  [vehicleFuelEfficiencyEnum.lon]: PropTypes.string,
  [vehicleFuelEfficiencyEnum.altUpSum]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.altDownSum]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoef]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoefUp]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoefDown]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelDiff]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelDiffMinus]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.gas]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const vehicleFuelEfficiencySlice = createSlice({
  name: `${reducerPath.vehicleFuelEfficiency}/counter`,
  initialState: vehicleFuelEfficiencyInitialState,
  reducers: {}
});

export const vehicleFuelEfficiencyReducer = vehicleFuelEfficiencySlice.reducer;
