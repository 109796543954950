import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSearchParams } from 'react-router-dom';
import { usePostAccountResetFinishMutation } from '../../../entities/account/accountResource/redux/accountResource.api';

const MIN_LENGTH = 4;

function ResetPasswordView() {
  const [searchParams] = useSearchParams();

  const key = searchParams.get('key');
  const isFirst = searchParams.get('first') === 'true';

  const [postAccountResetFinish, accountResetFinish] = usePostAccountResetFinishMutation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');

  const [isPasswordError, setIsPasswordError] = React.useState(false);
  const [isPasswordConfirmError, setIsPasswordConfirmError] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const save = (event) => {
    event.preventDefault();
    if (isPasswordError || isPasswordConfirmError) {
      return;
    }
    const body = {
      newPassword: password.trim(),
      key,
    };
    postAccountResetFinish({ body });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
    >
      <Box
        sx={{
          marginTop: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ВИСТАР-М
        </Typography>
        <Typography variant="body1" gutterBottom mt={5}>
          {isFirst
            ? 'Придумайте пароль для входа в\u00A0аккаунт'
            : 'Придумайте новый пароль'}
        </Typography>
        <Box component="form" onSubmit={save} noValidate sx={{ mt: 1 }}>
          <FormControl sx={{ marginTop: 1, marginBottom: 1 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="password">Пароль</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(event) => {
                setIsPasswordError(false);
                setIsPasswordConfirmError(false);
                setPassword(event.target.value);
              }}
              error={isPasswordError}
              onBlur={(event) => {
                setIsPasswordError(event.target.value.trim().length < MIN_LENGTH);
                setIsPasswordConfirmError(event.target.value.trim() !== passwordConfirm.trim());
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              label="Пароль"
            />
            <FormHelperText error>
              {isPasswordError ? `Длина пароля должа быть от ${MIN_LENGTH} символов` : null}
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ marginTop: 1, marginBottom: 1 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="passwordConfirm">Повторите пароль</InputLabel>
            <OutlinedInput
              id="passwordConfirm"
              type={showPasswordConfirm ? 'text' : 'password'}
              value={passwordConfirm}
              onChange={(event) => {
                setIsPasswordConfirmError(false);
                setPasswordConfirm(event.target.value);
              }}
              error={!!(passwordConfirm && password && passwordConfirm.trim() !== password.trim())}
              onBlur={(event) => {
                setIsPasswordConfirmError(event.target.value.trim() !== password.trim());
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle passwordConfirm visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              label="Повторите пароль"
            />
            {passwordConfirm && password && passwordConfirm.trim() !== password.trim()
              ? (
                <FormHelperText error>
                  Пароли должны совпадать
                </FormHelperText>
              )
              : null}
          </FormControl>
          {accountResetFinish.isError
            ? (
              <Alert severity="error" sx={{ width: '100%' }}>
                <AlertTitle>Ошибка</AlertTitle>
                Ошибка при сохранении пароля
              </Alert>
            )
            : null}
          {accountResetFinish.status === 'fulfilled'
            ? (
              <Alert severity="success" sx={{ width: '100%' }}>
                <AlertTitle>Успешно</AlertTitle>
                Пароль успешно сохранен
              </Alert>
            )
            : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              password.trim().length === 0
              || passwordConfirm.trim().length === 0
              || accountResetFinish.isLoading
              || accountResetFinish.status === 'fulfilled'
            }
          >
            {accountResetFinish.isLoading ? 'Сохранение...' : 'Сохранить'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPasswordView;
