// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import convertToISOTime from "../../../../../shared/utils/convertToISOTime";
import { platoonGroupsResourceType } from "../../redux/platoonGroupsResource.slice";

function platoonGroupsResourceToDtoService(dto) {
  const newData = JSON.parse(JSON.stringify(dto));

  const time = dto.intervalDuration;
  newData.intervalDuration = convertToISOTime(time);
  Reflect.deleteProperty(newData, 'intervalDurationNoParsed');
  return newData;
}

platoonGroupsResourceToDtoService.propTypes = {
  dto: platoonGroupsResourceType,
};

export default platoonGroupsResourceToDtoService;
