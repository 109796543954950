import { Badge, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function IconButtonBadge({
  title, children, disabled, onClick
}) {
  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          disabled={disabled}
          onClick={onClick}
        >
          <Badge
            badgeContent="+"
            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
          >
            {children}
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
}

IconButtonBadge.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

IconButtonBadge.defaultProps = {
  disabled: false,
};

export default IconButtonBadge;
