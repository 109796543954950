import React, { useState } from 'react';
import {
  Alert, AlertTitle,
  Box, Button, FormControlLabel, FormGroup, FormLabel, styled, Switch, TextField
} from '@mui/material';
import { pink } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import style from './TariffCreateMainPanel.module.css';
import ActionBarDateTimePicker from "../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

// Отображает панель "Тарифы создание"
function TariffCreateMainPanel({ postTariffPlanResourceParams }) {
  const [finishDate, setFinishDate] = useState(null);
  const [funcGroupMvmnt, setFuncGroupMvmnt] = useState(false);
  const [funcRouteMonitor, setFuncRouteMonitor] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [name, setName] = useState('');
  const [payPerDay, setPayPerDay] = useState('');
  const [cargoPerMonth, setСargoPerMonth] = useState('');
  const [cargoPerDay, setСargoPerDay] = useState('');
  const [payPerDayActiveVehicle, setPayPerDayActiveVehicle] = useState('');
  const [payPerDayVehicle, setPayPerDayVehicle] = useState('');
  const [payPerMonth, setPayPerMonth] = useState('');
  const [payPerMonthVehicle, setPayPerMonthVehicle] = useState('');
  const [privateRouteCount, setPrivateRouteCount] = useState('');
  const [publicRouteCount, setPublicRouteCount] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [vehicleActiveCount, setVehicleActiveCount] = useState('');
  const [vehicleCount, setVehicleCount] = useState('');
  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [historyDays, setHistoryDays] = useState('');
  const [funcFuelCoef, setFuncFuelCoef] = useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    const newData = {};
    newData.name = name;
    newData.finishDate = finishDate?.toISOString();
    newData.funcGroupMvmnt = funcGroupMvmnt;
    newData.funcRouteMonitor = funcRouteMonitor;
    newData.isPublic = isPublic;
    newData.isDefault = isDefault;
    newData.name = name;
    newData.payPerDay = payPerDay;
    newData.payPerDayActiveVehicle = payPerDayActiveVehicle;
    newData.payPerDayVehicle = payPerDayVehicle;
    newData.payPerMonth = payPerMonth;
    newData.payPerMonthVehicle = payPerMonthVehicle;
    newData.privateRouteCount = privateRouteCount;
    newData.publicRouteCount = publicRouteCount;
    newData.startDate = startDate?.toISOString();
    newData.vehicleActiveCount = vehicleActiveCount;
    newData.vehicleCount = vehicleCount;
    newData.historyDays = historyDays;
    newData.funcFuelCoef = funcFuelCoef;
    newData.cargoPerMonth = cargoPerMonth;
    newData.cargoPerDay = cargoPerDay;

    postTariffPlanResourceParams({
      id: newData.tarifPlanId,
      body: newData
    }).then((e) => {
      if (e?.error) {
        setErr(true);
      } else {
        setIsCorrect(true);
      }
    });
  };

  return (
    <Box>
      <Box className={style.form}>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Общая информация</FormLabel>
          <Box className={style.group}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              label="Название"
              variant="standard"
            />
            <DateTimePicker
              label="Дата начала"
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
            <DateTimePicker
              label="Дата окончания"
              value={finishDate}
              onChange={(e) => {
                setFinishDate(e);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Box>
          <Box className={style.group}>
            <FormControlLabel
              control={(
                <PinkSwitch
                  {...label}
                  onClick={() => {
                    setIsPublic(!isPublic);
                  }}
                  checked={isPublic}
                />
                  )}
              label="Публичный тарифный план"
            />
          </Box>
          <Box className={style.group}>
            <FormControlLabel
              control={(
                <PinkSwitch
                  {...label}
                  onClick={() => {
                    setIsDefault((prev) => !prev);
                  }}
                  checked={isDefault}
                />
                  )}
              label="План по-умолчанию"
            />
          </Box>
        </Box>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Ограничения</FormLabel>
          <Box className={style.group}>
            <Box className={style.verticalBox}>
              <Box className={style.horizontalBox}>
                <TextField
                  onChange={(e) => {
                    setPublicRouteCount(e.target.value);
                  }}
                  value={publicRouteCount}
                  label="Кол-во публичных маршрутов"
                  variant="standard"
                />
                <TextField
                  onChange={(e) => {
                    setPrivateRouteCount(e.target.value);
                  }}
                  value={privateRouteCount}
                  label="Кол-во приватных маршрутов"
                  variant="standard"
                />
              </Box>
              <Box className={style.horizontalBox}>
                <TextField
                  onChange={(e) => {
                    setVehicleCount(e.target.value);
                  }}
                  value={vehicleCount}
                  label="Кол-во ТС"
                  variant="standard"
                />
                <TextField
                  onChange={(e) => {
                    setVehicleActiveCount(e.target.value);
                  }}
                  value={vehicleActiveCount}
                  label="Кол-во активных ТС"
                  variant="standard"
                />
              </Box>
              <Box className={style.horizontalBox}>
                <TextField
                  onChange={(e) => {
                    setHistoryDays(e.target.value);
                  }}
                  value={historyDays}
                  label="История движения, дней"
                  variant="standard"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Доступные функции</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={(
                <PinkSwitch
                  {...label}
                  onClick={() => {
                    setFuncRouteMonitor(!funcRouteMonitor);
                  }}
                  checked={funcRouteMonitor}
                />
                  )}
              label="Мониторинг движения"
            />
            <FormControlLabel
              control={(
                <PinkSwitch
                  {...label}
                  onClick={() => {
                    setFuncFuelCoef(!funcFuelCoef);
                  }}
                  checked={funcFuelCoef}
                />
                  )}
              label="Топливная эффективность"
            />
            <FormControlLabel
              control={(
                <PinkSwitch
                  {...label}
                  onClick={() => {
                    setFuncGroupMvmnt(!funcGroupMvmnt);
                  }}
                  checked={funcGroupMvmnt}
                />
                  )}
              label="Движение в группах / Логистика"
            />
          </FormGroup>
        </Box>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Оплата фиксированная</FormLabel>
          <Box className={style.group}>
            <TextField
              onChange={(e) => {
                setPayPerMonth(e.target.value);
              }}
              value={payPerMonth}
              label="В месяц, руб"
              variant="standard"
            />
            <TextField
              onChange={(e) => {
                setPayPerDay(e.target.value);
              }}
              value={payPerDay}
              label="В день, руб"
              variant="standard"
            />
          </Box>
        </Box>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Оплата ТС</FormLabel>
          <Box className={style.group}>
            <TextField
              onChange={(e) => {
                setPayPerMonthVehicle(e.target.value);
              }}
              value={payPerMonthVehicle}
              label="В месяц, руб"
              variant="standard"
            />
            <TextField
              onChange={(e) => {
                setPayPerDayVehicle(e.target.value);
              }}
              value={payPerDayVehicle}
              label="В день, руб"
              variant="standard"
            />
            <TextField
              onChange={(e) => {
                setPayPerDayActiveVehicle(e.target.value);
              }}
              value={payPerDayActiveVehicle}
              label="За активное ТС в день, руб"
              variant="standard"
            />
          </Box>
        </Box>
        <Box className={style.boxTitle}>
          <FormLabel component="legend">Геопозиционирование грузов и пассажиров</FormLabel>
          <Box className={style.group}>
            <TextField
              onChange={(e) => {
                setСargoPerMonth(e.target.value);
              }}
              value={cargoPerMonth}
              label="В месяц, руб"
              variant="standard"
            />
            <TextField
              onChange={(e) => {
                setСargoPerDay(e.target.value);
              }}
              value={cargoPerDay}
              label="В день, руб"
              variant="standard"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        {!isErr && isCorrect
              && (
              <Alert sx={{ width: "100%" }} severity="success">
                <AlertTitle>Успешно</AlertTitle>
                Тариф был успешно создан.
              </Alert>
              )}
        {isErr
              && (
              <Alert sx={{ width: "100%" }} severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                Произошла ошибка при создании тарифа
              </Alert>
              )}
      </Box>
      <Box className={style.group}>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Создать
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate(`/finances/tariff-plans`)}
          sx={{ mt: 3, mb: 2 }}
        >
          Назад
        </Button>
      </Box>
    </Box>
  );
}

TariffCreateMainPanel.propTypes = {
  postTariffPlanResourceParams: PropTypes.func,
};

TariffCreateMainPanel.defaultProps = {
  postTariffPlanResourceParams: null,
};

export default TariffCreateMainPanel;
