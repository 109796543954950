import {
  Box, Button, Menu,
  TextField, Typography
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import style from './RouteActivateControl.module.css';

// Отображает кнопку активации и при нажатии открывает всплывающее окно
// с полями времени отправления и кнопками подтверждения активации
function RouteActivateControl({
  vehicleRoute,
  activateRoute,
  title,
  children,
  onClick,
}) {
  // Якорь всплывающего окна
  const [anchorControl, setAnchorControl] = useState(null);

  const [timePeriod, setTimePeriod] = useState({
    startTime: vehicleRoute?.startTime,
    startTimeUntil: vehicleRoute?.startTimeUntil,
  });

  const onClickOk = () => {
    activateRoute({
      ...vehicleRoute,
      ...timePeriod
    });
    setAnchorControl(null);
  };

  const onClickCancel = () => {
    setTimePeriod({
      startTime: vehicleRoute?.startTime,
      startTimeUntil: vehicleRoute?.startTimeUntil,
    });
    setAnchorControl(null);
  };

  // Изменение в поле ввода даты
  const handleChangeDate = (dateField, newValue) => {
    setTimePeriod((prev) => ({ ...prev, [dateField]: newValue }));
  };

  return (
    <Box>
      <Box
        onClick={(event) => onClick(() => setAnchorControl(event.currentTarget))}
      >
        {children}
      </Box>
      <Menu
        anchorEl={anchorControl}
        open={Boolean(anchorControl)}
        onClose={onClickCancel}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={style.routeActivateControl}>
          <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
          <Typography variant="body2">
            Время отправления
          </Typography>
          <Box className={style.dateTimePeriod}>
            <DateTimePicker
              label="C"
              value={timePeriod.startTime}
              onChange={(newDate) => {
                if (newDate > timePeriod.startTimeUntil) {
                  handleChangeDate('startTimeUntil', newDate);
                }
                handleChangeDate('startTime', newDate);
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
            <DateTimePicker
              label="По"
              value={timePeriod.startTimeUntil}
              minDateTime={timePeriod.startTime}
              onChange={(newDate) => handleChangeDate('startTimeUntil', newDate)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Box>
          <Box className={style.buttonsControl}>
            <Button
              variant="text"
              onClick={onClickCancel}
            >
              Отмена
            </Button>
            <Button
              variant="contained"
              onClick={onClickOk}
            >
              Ок
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

RouteActivateControl.propTypes = {
  vehicleRoute: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  activateRoute: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};

RouteActivateControl.defaultProps = {
  vehicleRoute: null,
  title: "Активировать маршрут",
  onClick: (showControl) => showControl(),
};

export default RouteActivateControl;
