import React, {
  useEffect,
  useState
} from 'react';
import {
  Autocomplete,
  Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography
} from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useConfirm } from "material-ui-confirm";
import { DateTimePicker } from "@mui/x-date-pickers";
import style from './PaymentInfoPanel.module.css';
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import { appUsersResourceType } from "../../../entities/account/appUsersResource/redux/appUsersResource.slice";
import convertFormatDate from "../../../shared/utils/convertFormatDate";
import { getCompanies } from "../../../shared/api/api";
import formatPrice from "../../../shared/utils/formatPrice";

const alertsHeadCells = [
  {
    id: 'payDateCurrent',
    numeric: false,
    disablePadding: false,
    label: 'Дата зачисления',
    style: { maxWidth: "100px" }
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'Имя компании',
  },
  {
    id: 'accountId',
    numeric: false,
    disablePadding: false,
    label: 'Номер аккаунта',
  },
  {
    id: 'typePayCurrent',
    numeric: false,
    disablePadding: false,
    label: 'Тип платежа',
  },
  {
    id: 'textAlignRight',
    numeric: false,
    disablePadding: false,
    label: 'Сумма ',
  },
  {
    id: 'comment',
    numeric: false,
    disablePadding: false,
    label: 'Комментарий',
    style: { width: "400px" }
  },

];

export const typesPay = [
  {
    id: "PT_CHARGE",
    value: "Списание"
  },
  {
    id: "PT_INCOME",
    value: "Зачисление"
  },
  {
    id: "PT_ADJUSTMENT",
    value: "Корректировка"
  }

];

const alertsDisplayedCells = [
  "payDateCurrent",
  'companyName',
  'accountId',
  'typePayCurrent',
  'textAlignRight',
  'comment',
];

function sortByDateAndId(array) {
  array?.sort((a, b) => {
    const dateA = new Date(a.payDate);
    const dateB = new Date(b.payDate);

    if (dateA > dateB) {
      return -1;
    } if (dateA < dateB) {
      return 1;
    }
    // Сортировка по убыванию id, если даты равны
    return b.id - a.id;
  });

  return array;
}

// Отображает панель "Пользователи"
function PaymentInfoPanel({ data, fetchPaymentResourceParams, deletePaymentResourceParams }) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [companyName, setcompanyName] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [typePayCurrent, settypePayCurrent] = useState('');
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchPaymentResourceParams({
      company: companyName, dateFrom, dateTo, type: typePayCurrent
    });

    getCompanies().then((res) => {
      setCompanies(res);
    }).catch((error) => { throw error; });
  }, []);

  const rows = data?.dataPaymentResourceId?.data.map((e) => {
    const newDAta = JSON.parse(JSON.stringify(e));
    newDAta.id = e.compPaymentId;
    newDAta.companyName = e.companyAccount.company.name;
    newDAta.accountId = e.companyAccount.companyAccountId;
    newDAta.typePayCurrent = typesPay.find(
      (typesPayCurrent) => typesPayCurrent.id === e.type
    ).value;
    newDAta.textAlignRight = formatPrice(e.sum);
    newDAta.comment = e.comment ? e.comment?.split('. ').map((item) => `${item}.`) : "-";
    newDAta.payDateCurrent = convertFormatDate(e.payDate).toFormat.substr(0, 10);
    newDAta.rowColor = e.type === typesPay[0].id ? "#f553538f" : e.type === typesPay[1].id ? "#2f7d3182" : "#ffff008a";
    return newDAta;
  });

  return (
    <Box className={style.UsersInfoPanel}>
      <Box className={style.search}>
        <Autocomplete
          fullWidth
          size="medium"
          autoComplete
          value={companyName}
          onChange={(event, newValue) => {
            setcompanyName(newValue);
          }}
          id="route"
          options={companies}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          renderInput={(params) => <TextField {...params} label="Компания" />}
        />

        <Box>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "-24px"
            }}
            variant="body1"
          >
            Дата зачисления платежа
          </Typography>
          <Box sx={{
            display: "flex", alignItems: "center", gap: "10px", minWidth: "424px"
          }}
          >
            <DateTimePicker
              label="с"
              value={dateFrom && new Date(dateFrom)}
              inputFormat="dd/MM/yyyy"
              onChange={(e) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(e) && e) {
                  const adjustedDate = new Date(
                    e.getTime() - (e.getTimezoneOffset() * 60000)
                  ).toISOString();
                  setDateFrom(adjustedDate);
                } else {
                  setDateFrom(null);
                }
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            -
            <DateTimePicker
              label="по"
              value={dateTo && new Date(dateTo)}
              inputFormat="dd/MM/yyyy"
              onChange={(e) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(e) && e) {
                  const adjustedDate = new Date(
                    e.getTime() - (e.getTimezoneOffset() * 60000)
                  ).toISOString();
                  setDateTo(adjustedDate);
                } else {
                  setDateTo(null);
                }
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />

          </Box>
        </Box>

        <FormControl fullWidth>
          <InputLabel id="category-label">Тип операции</InputLabel>
          <Select
            labelId="category-label"
            id="category-label"
            value={typePayCurrent || 0}
            label="Категория сообщений"
            onChange={(e) => {
              settypePayCurrent(e.target.value);
            }}
          >
            {typesPay.map((typesPayCurrent, i) => (
              <MenuItem
                key={`alertsType-select-item${typesPayCurrent.id}-${i + 1}`}
                value={typesPayCurrent.id}
              >
                {typesPayCurrent.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>
      <Box className={style.groupButton}>
        <Button
          disableElevation
          onClick={() => {
            setcompanyName('');
            setDateTo(null);
            setDateFrom(null);
            settypePayCurrent(null);
            fetchPaymentResourceParams({
              companyName: '', accountId: '', dateFrom: "", dateTo: ""
            });
          }}
          variant="text"
        >
          Сбросить
        </Button>
        <Button
          onClick={() => {
            fetchPaymentResourceParams({
              company: companyName.companyId, dateFrom, dateTo, type: typePayCurrent
            });
          }}
          variant="contained"
        >
          Поиск
        </Button>
        <Link
          style={{
            display: 'flex',
            width: '97.69px'
          }}
          to="create"
        >
          <Button sx={{ width: '300px' }} variant="contained">Создать</Button>
        </Link>
      </Box>

      {data?.dataPaymentResourceId?.data ? (
        <ControlledTable
          rows={sortByDateAndId(rows)}
          headCells={alertsHeadCells}
          displayedCells={alertsDisplayedCells}
          countRowsPerPage={10}
          selected={[]}
          controls
          setSelected={() => {}}
          onEdit={(e) => {
            if (data?.dataPaymentResourceId?.data) {
              navigate(`edit/${e.compPaymentId}`);
            }
          }}
          onDelete={(row) => {
            confirm({
              title: 'Удаление',
              confirmationText: 'Да',
              cancellationText: 'Отмена',
              description: `Вы действительно хотите удалить платеж компании «${row?.companyName}» на сумму «${row?.sum}» ?`
            })
              .then(() => { deletePaymentResourceParams({ id: row.compPaymentId }); })
              .catch(() => { });
          }}
        />
      ) : (
        <Box className={style.loaderWrapper}>
          <CircularProgress />
        </Box>
      ) }

    </Box>
  );
}

PaymentInfoPanel.propTypes = {
  data: PropTypes.shape({
    dataPaymentResourceId: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(appUsersResourceType))
    }),
  }),
  fetchPaymentResourceParams: PropTypes.func,
  deletePaymentResourceParams: PropTypes.func,
};

PaymentInfoPanel.defaultProps = {
  data: null,
  fetchPaymentResourceParams: null,
  deletePaymentResourceParams: null
};

export default PaymentInfoPanel;
