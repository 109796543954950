import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Alert, AlertTitle,
  Box,
  Button, CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PropTypes from "prop-types";
import style from './EquipmentPanel.module.css';
import { sensorResourceType } from "../../../entities/equipments/sensorResourceType/redux/sensorResourceType.slice";
import { sensorsResourceType } from "../../../entities/equipments/sensorsResource/redux/sensorsResource.slice";
import {
  adaptersResourceInitialState,
  adaptersResourceType
} from "../../../entities/equipments/adaptersResource/redux/adaptersResource.slice";
import { adapterResourceType } from "../../../entities/equipments/adapterResourceType/redux/adapterResourceType.slice";

function EquipmentPanel({
  data,
  patchAdapterParams,
  deleteSensorParams,
  postSensorParams,
  vehicleId,
  patchSensorParams,
  postAdapterParams
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [adapter, setAdapter] = useState(null);
  const [sensors, setSensors] = useState(null);

  const [isErrSensor, setIsErrSensor] = useState(false);
  const [isCorrectSensor, setIsCorrectSensor] = useState(false);

  const [isErrAdapter, setIsErrAdapter] = useState(false);
  const [isCorrectAdapter, setIsCorrectAdapter] = useState(false);

  const {
    dataSensors, dataSensorTypes, dataAdapters, dataAdapterTypes, dataSensorSources
  } = data;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isWindowAdapterTypes = Boolean(anchorEl);
  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearHelper = (value) => {
    if (value === 'all') {
      setIsErrAdapter(false);
      setIsCorrectAdapter(false);
      setIsErrSensor(false);
      setIsCorrectSensor(false);
    }
    if (value === 'adapter') {
      setIsErrAdapter(false);
      setIsCorrectAdapter(false);
    }
    if (value === 'sensor') {
      setIsErrSensor(false);
      setIsCorrectSensor(false);
    }
  };

  useEffect(() => {
    const adapterFromApi = dataAdapters && dataAdapters.data && dataAdapters.data[0];
    if (dataAdapters?.data) {
      if (adapterFromApi) {
        setAdapter(adapterFromApi);
      } else {
        const customAdapter = JSON.parse(JSON.stringify(adaptersResourceInitialState));
        customAdapter.vehicle.vehicleId = vehicleId;
        setAdapter(customAdapter);
      }
    }
    if (dataSensors?.data) {
      setSensors(dataSensors.data);
    }
  }, [data]);

  const deleteSensor = (sensor) => {
    deleteSensorParams({ id: sensor.sensorId }).then((e) => {
      if (e.error) {
        setIsErrSensor(true);
      } else {
        setIsCorrectSensor(true);
      }
    });
  };

  const updateSensor = (sensor) => {
    patchSensorParams({ id: sensor.sensorId, body: sensor }).then((e) => {
      if (e.error) {
        setIsErrSensor(true);
      } else {
        setIsCorrectSensor(true);
      }
    });
  };

  const createSensor = (sensor) => {
    postSensorParams({ body: sensor }).then((e) => {
      if (e.error) {
        setIsErrSensor(true);
      } else {
        setIsCorrectSensor(true);
      }
    });
  };

  const handleClickPatchDate = () => {
    if (adapter?.adapterId) {
      const adapterCurrent = dataAdapters?.data && dataAdapters?.data[0];
      const adapterId = adapterCurrent?.adapterId;
      dataAdapters.data.forEach((adapterApi) => {
        if (
          (adapterApi.adapterId === adapterId)
            && (
              adapterApi.brand !== adapter.brand
                || adapterApi.serialNumber !== adapter.serialNumber
                || adapterApi.type?.adapterTypeId !== adapter.type?.adapterTypeId
                || adapterApi.token !== adapter.token
            )) {
          patchAdapterParams({ id: adapterId, body: { ...adapter } }).then((e) => {
            if (e.error) {
              setIsErrAdapter(true);
            } else {
              setIsCorrectAdapter(true);
            }
          });
        }
      });
    } else {
      postAdapterParams({ body: adapter }).then((e) => {
        if (e.error) {
          setIsErrAdapter(true);
        } else {
          setIsCorrectAdapter(true);
        }
      });
    }
    sensors.forEach((sensor) => {
      if (sensor.isRemove) {
        deleteSensor(sensor);
      } else if (sensor.sensorId) {
        dataSensors.data.forEach((sensorApi) => {
          if (
            (sensorApi.sensorId === sensor.sensorId)
              && (sensorApi.serialNumber !== sensor.serialNumber
              || sensorApi.type.sensorId !== sensor.type.sensorId)) {
            updateSensor(sensor);
          }
        });
      } else {
        createSensor(sensor);
      }
    });
  };

  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ display: "flex", gap: "50px" }}>
        <Box sx={{ width: "400px" }}>
          <Box sx={{
            minHeight: '40px', mb: 1, display: 'flex', alignItems: 'center'
          }}
          >
            <Typography>
              Адаптер/Трекер
            </Typography>
          </Box>
          {!adapter
            ? <Box><CircularProgress /></Box>
            : (
              <Box className={style.Details}>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    value={adapter?.brand || ' '}
                    onChange={(e) => {
                      const newAdapter = JSON.parse(JSON.stringify(adapter));
                      newAdapter.brand = e.target.value;
                      setAdapter(newAdapter);
                      clearHelper('adapter');
                    }}
                    disabled={!isEditing}
                    sx={{ width: "100%" }}
                    label="Марка"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    value={adapter?.serialNumber || ' '}
                    onChange={(e) => {
                      const newAdapter = JSON.parse(JSON.stringify(adapter));
                      newAdapter.serialNumber = e.target.value;
                      setAdapter(newAdapter);
                      clearHelper('adapter');
                    }}
                    disabled={!isEditing}
                    sx={{ width: "100%" }}
                    label="Модель"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="adapterTypeId-select-label">Протокол</InputLabel>
                    <Select
                      labelId="adapterTypeId-select-label"
                      id="adapterTypeId-select"
                      value={adapter?.type?.adapterTypeId || ''}
                      label="Протокол"
                      disabled={!isEditing}
                      onChange={((e) => {
                        const newAdapter = JSON.parse(JSON.stringify(adapter));
                        newAdapter.type.adapterTypeId = e.target.value;
                        setAdapter(newAdapter);
                        clearHelper('adapter');
                      })}
                    >
                      {dataAdapterTypes?.data
                        .map((adapterType) => (
                          <MenuItem
                            key={`adapterType-select-item${adapterType.adapterTypeId}`}
                            value={adapterType.adapterTypeId}
                          >
                            {adapterType.model}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    value={adapter?.token || ' '}
                    onChange={(e) => {
                      const newAdapter = JSON.parse(JSON.stringify(adapter));
                      newAdapter.token = e.target.value;
                      setAdapter(newAdapter);
                      clearHelper('adapter');
                    }}
                    disabled={!isEditing}
                    sx={{ width: "100%" }}
                    label="Код идентификации"
                    variant="standard"
                  />
                </Box>
              </Box>
            )}
          {!isErrAdapter && isCorrectAdapter
              && (
              <Alert sx={{ width: "100%" }} severity="success">
                <AlertTitle>Успешно</AlertTitle>
                Данные успешно сохранены
              </Alert>
              )}
          {isErrAdapter
              && (
              <Alert sx={{ width: "100%" }} severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                Произошла ошибка
              </Alert>
              )}
        </Box>
        <Box className={style.EquipmentPanel}>
          <Box className={style.EquipmentBlock}>
            <Typography>
              Датчики/Сенсоры
            </Typography>

            <Box sx={{ position: "relative" }}>
              <IconButton
                id="basic-button"
                aria-controls={isWindowAdapterTypes ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isWindowAdapterTypes ? 'true' : undefined}
                onClick={handleToggle}
                sx={{ margin: '0 3px' }}
                disabled={!isEditing}
                color="primary"
              >
                { isWindowAdapterTypes ? <CancelIcon /> : <AddCircleIcon /> }
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isWindowAdapterTypes}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <List>
                  {dataSensorTypes?.data.map((sensorType) => {
                    const labelId = `checkbox-list-label-${sensorType.sensorId}`;
                    return (
                      <ListItem
                        disablePadding
                        key={`sensorType-item-${sensorType.sensorId}`}
                        disabled={!isEditing}
                        onClick={() => {
                          if (isEditing) {
                            setSensors([
                              {
                                source: { },
                                serialNumber: "",
                                type: sensorType,
                                vehicle: { vehicleId }
                              }, ...sensors]);
                          }
                        }}
                      >
                        <ListItemButton role={undefined} dense>
                          <ListItemText id={labelId} primary={sensorType.model} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Menu>
            </Box>
          </Box>
          {!sensors
            ? <Box><CircularProgress /></Box>
            : sensors.map((sensor, index, arr) => !sensor.isRemove && (
              <AccordionCurrent
                clearHelper={clearHelper}
                key={`Accordion-sensors-${!!sensor.sensorId + index}`}
                dataSensorSources={dataSensorSources}
                setSensors={setSensors}
                sensors={arr}
                isEditing={isEditing}
                index={index}
                remove={() => {
                  if (isEditing && arr[index].sensorId) {
                    setSensors((prev) => prev.map((el, i) => {
                      const newData = JSON.parse(JSON.stringify(el));
                      if (i === index) {
                        newData.isRemove = true;
                      }
                      return newData;
                    }));
                  } else {
                    setSensors((prev) => prev.filter((_, i) => i !== index));
                  }
                  clearHelper('sensor');
                }}
              />
            ))}
          {!isErrSensor && isCorrectSensor
              && (
              <Alert sx={{ width: "100%" }} severity="success">
                <AlertTitle>Успешно</AlertTitle>
                Данные успешно сохранены
              </Alert>
              )}
          {isErrSensor
              && (
              <Alert sx={{ width: "100%" }} severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                Произошла ошибка
              </Alert>
              )}
        </Box>
      </Box>
      <Box
        className={style.AccordionGroupButtons}
      >
        {isEditing && (
        <Button
          onClick={() => {
            setIsEditing(false);
            setSensors(dataSensors?.data || []);
            if (dataAdapters?.data.length) {
              setAdapter(dataAdapters.data[0]);
            }
            clearHelper('all');
          }}
          variant="contained"
        >
          ОТМЕНА
        </Button>
        )}
        {!isEditing && (
        <Button
          onClick={() => setIsEditing(true)}
          variant="contained"
        >
          РЕДАКТИРОВАТЬ
        </Button>
        )}
        {isEditing && (
        <Button
          onClick={handleClickPatchDate}
          variant="contained"
        >
          СОХРАНИТЬ
        </Button>
        )}
      </Box>
    </Box>
  );
}

function AccordionCurrent({
  remove, setSensors, sensors, isEditing, index, dataSensorSources, clearHelper
}) {
  return (
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Box
          className={style.AccordionCurrent}
        >
          <Box className={style.AccordionHeader}>
            <BluetoothIcon />
            <Typography sx={{ flexGrow: 1 }}>
              Датчик
              {' '}
              {sensors[index].type.model}
            </Typography>
            <Switch onClick={(e) => e.stopPropagation()} disabled={!isEditing} value defaultChecked color="error" />
          </Box>
          <Box sx={{ opacity: isEditing ? '1' : '0.5' }}>
            <DeleteForeverIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                remove();
              }}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={style.Details}>
          <Box sx={{ width: "100%" }}>
            <TextField
              value={sensors[index].serialNumber}
              onChange={(e) => {
                setSensors(sensors.map((item, i) => {
                  if (i === index) {
                    const newItem = JSON.parse(JSON.stringify(item));
                    newItem.serialNumber = e.target.value;
                    return newItem;
                  }
                  return item;
                }));
                clearHelper('sensor');
              }}
              disabled={!isEditing}
              sx={{ width: "100%" }}
              id="standard-basic"
              label="Марка/Модель"
              variant="standard"
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              value={sensors[index].type.model}
              disabled
              sx={{ width: "100%" }}
              id="standard-basic"
              label="Тип"
              variant="standard"
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="adapterTypeId-select-label">Источник данных</InputLabel>
              <Select
                labelId="adapterTypeId-select-label"
                id="adapterTypeId-select"
                value={sensors[index].source.sensorSourceId || ''}
                label="Источник данных"
                onChange={(e) => {
                  setSensors(sensors.map((item, i) => {
                    if (i === index) {
                      const newItem = JSON.parse(JSON.stringify(item));
                      newItem.source = dataSensorSources
                        ?.data?.filter((sensorSource) => sensorSource
                          .sensorSourceId === e.target.value)[0];
                      return newItem;
                    }
                    return item;
                  }));
                  clearHelper('sensor');
                }}
                disabled={!isEditing}
              >
                {dataSensorSources?.data
                  .map((sensorSource) => (
                    <MenuItem
                      key={`adapterType-select-item${sensorSource.sensorSourceId}`}
                      value={sensorSource.sensorSourceId}
                    >
                      {sensorSource.comment}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

AccordionCurrent.propTypes = {
  setSensors: PropTypes.func,
  remove: PropTypes.func,
  sensors: PropTypes.arrayOf(PropTypes.shape(sensorsResourceType)),
  isEditing: PropTypes.bool,
  index: PropTypes.number,
  dataSensorSources: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape(adapterResourceType)),
  }),
  clearHelper: PropTypes.func.isRequired
};

AccordionCurrent.defaultProps = {
  setSensors: null,
  remove: null,
  sensors: null,
  isEditing: null,
  index: null,
  dataSensorSources: null
};

EquipmentPanel.propTypes = {
  data: PropTypes.shape({
    dataAdapters: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(adaptersResourceType)),
    }),
    dataSensors: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(sensorsResourceType)),
    }),
    dataSensorTypes: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(sensorResourceType)),
    }),
    dataAdapterTypes: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(adapterResourceType)),
    }),
    dataSensorSources: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(adapterResourceType)),
    }),
  }),
  patchAdapterParams: PropTypes.func,
  deleteSensorParams: PropTypes.func,
  postSensorParams: PropTypes.func,
  patchSensorParams: PropTypes.func,
  postAdapterParams: PropTypes.func,
  vehicleId: PropTypes.number,
};

EquipmentPanel.defaultProps = {
  data: null,
  patchAdapterParams: null,
  deleteSensorParams: null,
  postSensorParams: null,
  patchSensorParams: null,
  postAdapterParams: null,
  vehicleId: null,

};

export default EquipmentPanel;
