import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
import apiSlice from '../../../../shared/api/apiSlice';

// Описываем методы обращения к беку
const companyAccountsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyAccounts: builder.query({
      query: ({ params }) => ({
        url: `/company-accounts`,
        method: HttpMethods.GET,
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['companyAccounts'],
    }),
    putCompanyAccount: builder.mutation({
      query: ({ body, id, }) => ({
        url: `company-accounts/${id}`,
        method: HttpMethods.PUT,
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['companyAccounts'],
    }),
  }),
});

export default companyAccountsApi;
