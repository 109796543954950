import { createApi } from "@reduxjs/toolkit/query/react";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";
import reducerPath from "../../../../app/reducerPath";
import config from "../../../../shared/api/config";
import HttpMethods from "../../../../shared/api/HttpMethods";
import checkStatus from "../../../../shared/http/checkStatus";

const TAG = 'company-cargos';
const url = `/${TAG}`;

// Описываем методы обращения к беку
export const companyCargosApi = createApi({
  reducerPath: `${reducerPath.companyCargos}/api`,
  tagTypes: [TAG],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}`),
  endpoints: (builder) => ({
    postCompanyCargo: builder.mutation({
      query: ({
        body,
      }) => ({
        url: `${url}`,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
    }),
    patchCompanyCargoBatch: builder.mutation({
      query: ({
        body,
      }) => ({
        url: `${url}/batch`,
        method: HttpMethods.PATCH,
        body,
        validateStatus: checkStatus,
      }),
    }),
  }),
});

export const {
  usePostCompanyCargoMutation,
  usePatchCompanyCargoBatchMutation,
} = companyCargosApi;
