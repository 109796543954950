import React, {
  Children, cloneElement, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useGetVehicleIdResourceQueryQuery } from "../redux/vehicle.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function VehicleBll({
  children, vehicleId
}) {
  const { data: dataVehicleIdResource } = useGetVehicleIdResourceQueryQuery({
    id: vehicleId
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    const newData = {};
    newData.dataVehicleIdResource = dataVehicleIdResource;
    setData(newData);
  }, [
    dataVehicleIdResource,
  ]);

  const props = {
    data,
    vehicleId
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

VehicleBll.propTypes = {
  children: PropTypes.element.isRequired,
  vehicleId: PropTypes.number,
};

VehicleBll.defaultProps = {
  vehicleId: NaN,
};

export default VehicleBll;
