// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { vehicleFuelEfficiencyEnum } from "../redux/vehicleFuelEfficiency.slice";

// тут описываем тип то что приходит с бека в сыром виде
const vehicleFuelEfficiencyDtoType = {
  [vehicleFuelEfficiencyEnum.vehicleId]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.date]: PropTypes.string,
  [vehicleFuelEfficiencyEnum.lat]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.lon]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.altUpSum]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.altDownSum]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoef]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoefUp]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelCoefDown]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelDiff]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.fuelDiffMinus]: PropTypes.number,
  [vehicleFuelEfficiencyEnum.gas]: PropTypes.number,
};

export default vehicleFuelEfficiencyDtoType;
