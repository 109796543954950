import * as React from 'react';
import PropTypes from 'prop-types';

import {
  Autocomplete,
  Box,
  Grid,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import getDayStringFromDate from '../../../shared/utils/getDayStringFromDate';

function ChangeRouteForm(props) {
  const {
    customContent,
    routes,
    route,
    setRoute,
  } = props;

  const getDate = React.useCallback((date) => {
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const futureDate = new Date(new Date().setHours(0, 0, 0, 0));
    futureDate.setDate(currentDate.getDate() + 5);
    const day = getDayStringFromDate(currentDate, futureDate, date);
    const time = date?.toLocaleTimeString('ru', { hour: "2-digit", minute: "2-digit" });
    return `${day} ${time}`;
  }, []);

  return (
    <>
      {customContent}
      <Autocomplete
        options={routes}
        value={route}
        isOptionEqualToValue={(option, value) => option.routeId === value.routeId}
        onChange={(event, newValue) => {
          setRoute(newValue);
        }}
        getOptionLabel={(option) => `${option.routeName} (${getDate(option.routeStartTime)})`}
        fullWidth
        noOptionsText="Нет общих маршрутов"
        renderInput={(params) => <TextField {...params} label="Заменяемый маршрут" />}
        renderOption={(propsOption, option) => {
          const date = getDate(option.routeStartTime);
          return (
            <ListItem {...propsOption}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <DirectionsCarIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: 'regular' }}
                  >
                    {option.routeName}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {date}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          );
        }}
      />
    </>
  );
}

ChangeRouteForm.propTypes = {
  customContent: PropTypes.node,
  routes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  route: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setRoute: PropTypes.func.isRequired,
};

ChangeRouteForm.defaultProps = {
  route: null,
  customContent: null,
};

export default ChangeRouteForm;
