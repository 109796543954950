const days = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};

function getWeekDay(date) {
  if (!date) {
    return date;
  }
  return days.ru[date.getDay()];
}

export default getWeekDay;
