import {
  Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getVehicles } from '../../../shared/api/api';
import style from './RouteForm.module.css';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import isValidDate from '../../../shared/utils/isValidDate';
import useDebounce from "../../../shared/hooks/useDebounce";

function RouteForm({
  vehicleRoute, setVehicleRoute, companyId, setErrors, errors,
  rememberedDate,
  automaticTimeShift,
}) {
  // ТС для выбора в комбобокс
  const [vehicles, setVehicles] = useState([]);
  // Поле ввода для номера ТС
  const [inputVehicleNum, setInputVehicleNum] = useState('');

  // Обработка изменений в текстовом поле
  const changeTextField = (event) => {
    if (errors[event.target.id]) {
      setErrors((prev) => ({ ...prev, [event.target.id]: false }));
    }
    setVehicleRoute((prev) => ({ ...prev, [event.target.id]: event.target.value }));
  };

  // Обработка изменений в чекбоксе
  const changeCheckbox = (event) => {
    setVehicleRoute((prev) => ({ ...prev, [event.target.id]: event.target.checked }));
  };

  // Изменение в поле ввода даты
  const handleChangeDate = (dateField, newValue) => {
    setVehicleRoute((prev) => {
      // eslint-disable-next-line no-param-reassign
      rememberedDate.current = {
        ...rememberedDate.current,
        name: `${dateField}-route`,
        newDate: newValue,
      };
      return {
        ...prev,
        [dateField]: newValue
      };
    });
  };

  const focusDate = (dateName) => {
    // eslint-disable-next-line no-param-reassign
    rememberedDate.current = {
      ...rememberedDate.current,
      name: `${dateName}-route`,
      prevDate: vehicleRoute[dateName],
      newDate: null,
    };
  };

  const blurDate = () => {
    automaticTimeShift();
  };

  const handleInputChangeDebounced = useDebounce((newInputValue) => {
    setInputVehicleNum(newInputValue);
  }, 500); // Установите нужную вам задержку

  // Загрузка списка ТС при вводе
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputVehicleNum.length < 3) {
      setVehicles(vehicleRoute.vehicle ? [vehicleRoute.vehicle] : []);
      return undefined;
    }

    getVehicles({
      companyId,
      num: { paramBody: 'contains', paramValue: inputVehicleNum }
    }).then((results) => {
      let newOptions = [];

      if (vehicleRoute.vehicle) {
        newOptions = [vehicleRoute.vehicle];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setVehicles(newOptions);
    }).catch((error) => { throw error; });
  }, [vehicleRoute.vehicle, inputVehicleNum, vehicleRoute.vehicleRouteId, companyId]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  return (
    <Box className={style.routeForm}>
      <Box
        className={style.vehicleInput}
        sx={{
          visibility: vehicleRoute.isTemplate ? 'hidden' : 'visible'
        }}
      >
        <DirectionsCarIcon sx={{ color: 'action.active', m: 1 }} />
        <Autocomplete
          fullWidth
          disabled={!!vehicleRoute.isTemplate}
          size="small"
          autoComplete
          includeInputInList
          noOptionsText={inputVehicleNum.length <= 3
            ? 'Введите номер ТС'
            : !(vehicles)
              ? "загрузка..."
              : 'Нет результатов'}
          filterSelectedOptions
          value={vehicleRoute.vehicle}
          onChange={(event, newValue) => {
            setVehicleRoute((prev) => ({ ...prev, vehicle: newValue }));
            setVehicles(newValue ? [newValue, ...vehicles] : vehicles);
          }}
          onInputChange={(event, newInputValue) => {
            if (newInputValue.length >= 3) {
              setVehicles(null);
            }
            handleInputChangeDebounced(newInputValue);
          }}
          id="vehicle"
          options={vehicles || []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.num)}
          filterOptions={(x) => x}
          renderInput={(params) => <TextField {...params} label="Номер ТС" />}
        />
      </Box>
      <Box className={style.routeInputs}>
        <TextField
          fullWidth
          size="small"
          id="name"
          label="Название маршрута"
          value={vehicleRoute.name}
          onChange={changeTextField}
          helperText={errors.name ? 'Заполните поле' : ''}
          error={Boolean(errors.name)}
          required
          onBlur={() => {
            if (!vehicleRoute.name) {
              setErrors((prev) => ({ ...prev, name: true }));
            }
          }}
        />
        <Box className={style.inputWithTitle}>
          <Typography variant="subtitle2" gutterBottom>
            Время отправления
          </Typography>
          <Box className={style.datePeriodInput}>
            <DateTimePicker
              label="C"
              value={vehicleRoute.startTime}
              // onError={(error, value) => handleErrorDate(error, value)}
              onChange={(newDate) => {
                const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
                handleChangeDate('startTime', date);
              }}
              onOpen={() => focusDate('startTime')}
              onAccept={() => blurDate('startTime')}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                  onKeyDown={handleKeyDown}
                  onFocus={() => focusDate('startTime')}
                  onBlur={() => blurDate('startTime')}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
            <DateTimePicker
              label="По"
              value={vehicleRoute.startTimeUntil}
              minDateTime={vehicleRoute.startTime}
              onChange={(newDate) => {
                const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
                handleChangeDate('startTimeUntil', date);
              }}
              // onError={(error, value) => handleErrorDate(error, value)}
              onAccept={() => blurDate('startTimeUntil')}
              onOpen={() => focusDate('startTimeUntil')}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                  onKeyDown={handleKeyDown}
                  onBlur={() => blurDate('startTimeUntil')}
                  onFocus={() => focusDate('startTimeUntil')}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Box>
        </Box>
        <Box className={style.inputWithTitle}>
          <Typography variant="subtitle2" gutterBottom>
            Время прибытия
          </Typography>
          <Box className={style.datePeriodInput}>
            <DateTimePicker
              label="C"
              value={vehicleRoute.finishTime}
              // onError={(error, value) => handleErrorDate(error, value)}
              onChange={(newDate) => {
                const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
                handleChangeDate('finishTime', date);
              }}
              onAccept={() => blurDate('finishTime')}
              onOpen={() => focusDate('finishTime')}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                  onKeyDown={handleKeyDown}
                  onBlur={() => blurDate('finishTime')}
                  onFocus={() => focusDate('finishTime')}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
            <DateTimePicker
              label="По"
              value={vehicleRoute.finishTimeUntil}
              minDateTime={vehicleRoute.finishTime}
              onChange={(newDate) => {
                const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
                handleChangeDate('finishTimeUntil', date);
              }}
              // onError={(error, value) => handleErrorDate(error, value)}
              onAccept={() => blurDate('finishTimeUntil')}
              onOpen={() => focusDate('finishTimeUntil')}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  {...params}
                  onKeyDown={handleKeyDown}
                  onBlur={() => blurDate('finishTimeUntil')}
                  onFocus={() => focusDate('finishTimeUntil')}
                />
              )}
              components={{
                ActionBar: ActionBarDateTimePicker,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={style.routeCheckbox}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={(
              <Checkbox
                id="isAutostarted"
                checked={!!vehicleRoute.isAutostarted}
                color="primary"
                onChange={(e) => {
                  if (vehicleRoute.isAutostarted) {
                    setVehicleRoute((prev) => ({ ...prev, isAutoShifted: false }));
                  }
                  changeCheckbox(e);
                }}
              />
              )}
            label="Автостарт"
          />
          <FormControlLabel
            sx={{ pl: '30px' }}
            control={(
              <Checkbox
                disabled={!vehicleRoute.isAutostarted}
                id="isAutoShifted"
                checked={!!vehicleRoute.isAutoShifted}
                color="primary"
                onChange={changeCheckbox}
              />
            )}
            label="Корректировка времени"
          />
          <FormControlLabel
            control={(
              <Checkbox
                id="isPublic"
                checked={!!vehicleRoute.isPublic}
                color="primary"
                onChange={changeCheckbox}
              />
              )}
            label="Публичный"
          />
          <FormControlLabel
            control={(
              <Checkbox
                id="isTemplate"
                checked={!!vehicleRoute.isTemplate}
                color="primary"
                onChange={changeCheckbox}
              />
              )}
            label="Шаблон"
          />
          <FormControlLabel
            control={(
              <Checkbox
                id="isCycle"
                checked={!!vehicleRoute.isCycle}
                color="primary"
                onChange={changeCheckbox}
              />
              )}
            label="Кольцевой"
          />
        </Box>

        {/* <FormControlLabel
          control={(
            <Checkbox
              id="isRoundTrip"
              checked={!!vehicleRoute.isRoundTrip}
              color="primary"
              onChange={changeCheckbox}
            />
          )}
          label="Туда и обратно"
        /> */}
      </Box>
    </Box>
  );
}

RouteForm.propTypes = {
  vehicleRoute: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])).isRequired,
  setVehicleRoute: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  rememberedDate: PropTypes.shape({
    current: PropTypes.shape({
      name: PropTypes.string,
      prevDate: PropTypes.instanceOf(Date),
      newDate: PropTypes.instanceOf(Date),
    })
  }).isRequired,
  automaticTimeShift: PropTypes.func.isRequired,
};

export default RouteForm;
