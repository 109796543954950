import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../../app/reducerPath";

// Название полей которые в data после функции from dto
export const vehicleRoutePointsResourceEnum = {
};
// Инициализация стейта в сторе
const vehicleRoutePointsResourceInitialState = {
};
// Типизация стора
export const vehicleRoutePointsResourceType = {
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const vehiclesRoutePointsResourceSlice = createSlice({
  name: `${reducerPath.vehicleRoutePointsResource}/counter`,
  initialState: vehicleRoutePointsResourceInitialState,
  reducers: {}
});

export const vehicleRoutePointsResource = vehiclesRoutePointsResourceSlice.reducer;
