// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import convertFormatDate from "../../../../../shared/utils/convertFormatDate";
import platoonGroupActivitiesResourceDtoType from "../../types/platoonGroupActivitiesResource-dto.type";

export function platoonGroupActivitiesResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  const newData = dto.map((e) => {
    const {
      day: dayModifiedDate,
      month: monthModifiedDate,
      year: yearModifiedDate,
      hours: hoursModifiedDate,
      minutes: minutesModifiedDate
    } = convertFormatDate(e.modifiedDate);
    e.modifiedDate = `${dayModifiedDate}.${monthModifiedDate}.${yearModifiedDate} ${hoursModifiedDate}:${minutesModifiedDate}`;
    return e;
  });

  return { data: newData, status };
}

platoonGroupActivitiesResourceFromDtoService.propTypes = {
  dto: platoonGroupActivitiesResourceDtoType,
};

export default platoonGroupActivitiesResourceFromDtoService;
