import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Route, Routes, useNavigate } from 'react-router-dom';
import style from './CompanyPlatoonGroupPanel.module.css';
import CompanyPlatoonGroupsWidget from '../../../widgets/companies/CompanyPlatoonGroupsWidget/CompanyPlatoonGroupsWidget';
import PlatoonBll from "../../../entities/platoons/PlatoonBll/PlatoonBll";
// eslint-disable-next-line boundaries/element-types
import PlatoonGroupsPanel from "../../vehicles/PlatoonGroupsPanel/PlatoonGroupsPanel";

// Отображает панель "Группа ТС" для ТС
function CompanyPlatoonGroupPanel(props) {
  const { company } = props;
  const navigate = useNavigate();

  const toGroup = useCallback((id) => navigate(`edit/${id}`), [navigate]);
  const toPlatoons = useCallback(() => navigate(``), [navigate]);
  const toCreateGroup = useCallback(() => navigate(`create`), [navigate]);

  return (
    <Box className={style.vehicleGroupPanel}>
      <Routes>
        <Route
          path="edit/:platoonGroupId"
          element={(
            <PlatoonBll
              pollMode
              companyId={company.companyId}
            >
              <PlatoonGroupsPanel toPlatoons={toPlatoons} />
            </PlatoonBll>
          )}
        />
        <Route
          path="create"
          element={(
            <PlatoonBll
              companyId={company.companyId}
            >
              <PlatoonGroupsPanel toPlatoons={toPlatoons} />
            </PlatoonBll>
          )}
        />
        <Route
          path="/"
          element={(
            <CompanyPlatoonGroupsWidget
              toGroup={toGroup}
              toCreateGroup={toCreateGroup}
              company={company}
            />
          )}
        />
      </Routes>
    </Box>
  );
}

CompanyPlatoonGroupPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyPlatoonGroupPanel.defaultProps = {
  company: null,
};

export default CompanyPlatoonGroupPanel;
