import {
  Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PropTypes from 'prop-types';
import style from './VehicleManeuverWidget.module.css';
import overtakingImg from '../../../assets/images/overtaking.jpg';
import DeleteButton from '../../../shared/ui/DeleteButton/DeleteButton';
import { getPlatoonGroupActivities } from '../../../shared/api/api';
import NoData from '../../../shared/ui/NoData/NoData';

// Отображает часть аккордина панели "Группа ТС" - История
function VehicleManeuverWidget({
  vehicle, platoonGroupId
}) {
  const [maneuvers, setManeuvers] = useState([]);

  // const removeManeuver = (id) => {
  //   setManeuvers((prev) => prev.filter((maneuver) => maneuver.id !== id));
  // };

  // Загрузка и обновление списка маневров
  useEffect(() => {
    if (vehicle && platoonGroupId) {
      getPlatoonGroupActivities(
        {
          vehicleId: vehicle.vehicleId,
          groupId: platoonGroupId,
        },
        {
          page: 0,
          size: 5,
          sort: 'modifiedDate,desc'
        }
      ).then((res) => {
        setManeuvers(res);
      }).catch((error) => { throw error; });
    }
  }, [vehicle, platoonGroupId]);

  return platoonGroupId
    ? (
      <Box className={style.vehicleManeuverWidget}>
        <Box className={style.maneuverInfo}>
          <Box>
            {!maneuvers.length
              ? (
                <Typography sx={{ color: 'text.secondary' }}>
                  Маневры отсутствуют.
                </Typography>
              )
              : (
                <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
                  {maneuvers.map((maneuver) => (
                    <ListItem
                      key={maneuver.plGrActivityId}
                      secondaryAction={(
                        <DeleteButton
                          edge="end"
                          disabled
                          onDelete={() => { }}
                        />
                        )}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <InsertPhotoIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${maneuver.modifiedDate.toLocaleString().slice(0, -3)} ${maneuver.msg || '(Нет текста сообщения)'}`}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
          </Box>
          <Box className={style.nextManeuver}>
            <Typography sx={{ color: 'text.secondary' }}>
              Следующий маневр: Обгон
            </Typography>
            <img width="54" src={overtakingImg} alt="overtakingImg" />
          </Box>
        </Box>
      </Box>
    )
    : <NoData text="Нет группы для ТС" />;
}

VehicleManeuverWidget.propTypes = {
  vehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  platoonGroupId: PropTypes.number,
};

VehicleManeuverWidget.defaultProps = {
  vehicle: null,
  platoonGroupId: null,
};
export default VehicleManeuverWidget;
