import * as React from 'react';
import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DetailsDialog from './DetailsDialog';

export default function NumberMenuBtn(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const open = Boolean(anchorEl);
  const {
    type, color, items, label, detailed, captionLabel, ...other
  } = props;
  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openItemDialog = (item) => {
    setSelectedItem(item);
  };
  const closeItemDialog = () => {
    setSelectedItem(null);
  };
  if (!items) {
    return null;
  }
  return (
    <>
      <Button
        size="small"
        sx={{
          position: 'relative', minWidth: 'auto', width: '100%', justifyContent: 'flex-end', paddingRight: '12px'
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
        {...other}
      >
        <Typography sx={{
          color: color === 'error' ? (theme) => theme.palette.error.main : color === 'text' ? (theme) => theme.palette.text.primary : color,
          fontSize: '1.25rem',
          lineHeight: 1,
          letterSpacing: 0,
          textTransform: 'none',
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textAlign: 'right',
        }}
        >
          {items.length}
          {' '}
          {detailed ? label : null}
        </Typography>
        <ArrowDropDownIcon sx={{
          position: 'absolute',
          bottom: -2,
          right: -3,
          width: 18,
          height: 18,
          color: (theme) => theme.palette.text.primary,
        }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{captionLabel}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                hover
                key={row.id}
                sx={{ border: 0, cursor: 'pointer' }}
                onClick={() => openItemDialog(row)}
              >
                <TableCell>{row.model}</TableCell>
                <TableCell>{row.regNum}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Menu>
      <DetailsDialog type={type} item={selectedItem} closeItemDialog={closeItemDialog} />
    </>
  );
}

NumberMenuBtn.propTypes = {
  color: PropTypes.string,
  detailed: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    regNum: PropTypes.string,
  })),
  label: PropTypes.string.isRequired,
  captionLabel: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['harvester', 'vehicle']).isRequired,
};

NumberMenuBtn.defaultProps = {
  color: 'inherit',
  detailed: false,
  items: undefined,
};
