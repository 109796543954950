import { createApi } from '@reduxjs/toolkit/query/react';
import config from './config';
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../entities/baseQueryWithReAuth/baseQueryWithReAuth";

const apiSlice = createApi({
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}`),
  endpoints: () => ({})
});

export default apiSlice;
