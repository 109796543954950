import { Box, IconButton } from '@mui/material';
import React, {
  Children, cloneElement, useCallback, useState
} from 'react';
import * as olSource from "ol/source";
import PropTypes from 'prop-types';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import NearMeIcon from '@mui/icons-material/NearMe';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GeoMap from '../../shared/ui/GeoMap/GeoMap';
import TileLayer from '../../shared/ui/GeoMap/TileLayer';
import style from './GeoMapWithTiles.module.css';
import GeoMapControl from './GeoMapControl';
import { useAppDispatch, useAppSelector } from "../../app/store";
import { followModeActions, FollowModeEnum } from "../../entities/map/followMode/redux/followMode.slice";
import reducerPath from "../../app/reducerPath";
import { fullScreenMapSlice, mapNames } from '../../entities/map/fullScreenMap/redux/fullScreenMap.slice';
import { DEFAULT_CENTER, DEFAULT_EXTENT, DEFAULT_ZOOM } from './GeoMap.constants';

// Слои подложки карты
const underLayers = [
  {
    title: 'OSM',
    source: new olSource.OSM(),
  },
  {
    title: 'OSM 2',
    source: new olSource.XYZ({
      url: 'https://c.tile.thunderforest.com/transport/{z}/{x}/{y}@2x.png?apikey=6170aad10dfd42a38d4d8c709a536f38',
    }),
  },
];

// Слои поверх подложки карты
const overLayersList = [
  {
    title: 'Вибронагрузка',
    source: new olSource.XYZ({
      url: '/api/v1/geostorage/tms/vibro/{z}/{x}/{-y}@2x.png?apikey=6170aad10dfd42a38d4d8c709a536f38',
    }),
    isShow: false,
  },
  {
    title: 'Дорожная сеть',
    source: new olSource.XYZ({
      url: '/api/v1/geostorage/tms/roads/{z}/{x}/{-y}@2x.png?apikey=6170aad10dfd42a38d4d8c709a536f38',
    }),
    isShow: false,
  },
].map((overLayer, id) => ({ ...overLayer, id }));

// Отображает карту со слоями и контролом управления слоями
function GeoMapWithTiles({
  children,
  center,
  zoom,
  styles,
  disableFullScreen,
  extent,
  isOffButtonFollowMode,
  setPointCurrent,
  isZoomFocused,
  isSniperMode,
  name,
  ...other
}) {
  const isFullscreenStore = useAppSelector((state) => state[`${reducerPath.fullScreenMap}`])[name];

  // Выбранный слой подложки
  const [underLayer, setUnderLayer] = useState(underLayers[0].title);
  // Якорь создания меню контрола слоями
  const [anchorControl, setAnchorControl] = useState(null);
  // Состояние фулскрин карты
  const [isFullscreen, setIsFullscreen] = useState(false);
  // Мод для выбора точки на карте с 1 кликом
  const [isSniperModeState, setIsSniperModeState] = useState(false);
  const dispatch = useAppDispatch();
  const onClickControl = (event) => {
    setAnchorControl(event.currentTarget);
  };
  const closeControl = () => {
    setAnchorControl(null);
  };
  const changeUnderLayer = (event) => {
    setUnderLayer(event.target.value);
  };
  const changeIsFullscreen = () => {
    if (name !== mapNames.default) {
      dispatch(fullScreenMapSlice.actions.setFull({ name, value: !isFullscreenStore }));
    } else {
      setIsFullscreen((prev) => !prev);
    }
  };

  const changeFollowMe = useCallback(() => {
    dispatch(followModeActions.changeMode({ [FollowModeEnum.isActive]: !isFullscreen }));
  }, [isFullscreen, dispatch]);

  const isFollowMode = useAppSelector((state) => state[`${reducerPath.followMode}/counter`]).isActive;

  const [overLayers, setOverLayers] = useState(overLayersList);

  const handleChangeCheckbox = (id) => {
    setOverLayers((prev) => {
      const index = prev.findIndex((item) => item.id === id);
      const newOverLayers = prev.slice();
      newOverLayers[index] = {
        ...prev[index],
        isShow: !prev[index].isShow,
      };
      return newOverLayers;
    });
  };

  return (
    <Box
      className={!isFullscreen ? style.geoMapWithTiles : style.geoMapWithTilesMax}
      sx={{ cursor: isSniperModeState ? "cell" : "auto" }}
    >
      <GeoMapControl
        underLayers={underLayers}
        anchorControl={anchorControl}
        closeControl={closeControl}
        changeUnderLayer={changeUnderLayer}
        underLayer={underLayer}
        handleChangeCheckbox={handleChangeCheckbox}
        overLayers={overLayers}
      />
      <Box className={style.geoMapButtons}>
        <IconButton onClick={onClickControl}>
          <LayersRoundedIcon />
        </IconButton>
        {isOffButtonFollowMode ? null : (
          <IconButton color={isFollowMode ? "primary" : "default"} onClick={changeFollowMe}>
            <NearMeIcon />
          </IconButton>
        )}
        {isSniperMode && (
        <IconButton
          color={isSniperModeState ? "primary" : "default"}
          onClick={() => setIsSniperModeState(((prev) => !prev))}
        >
          <GpsNotFixedIcon />
        </IconButton>
        )}
      </Box>
      {disableFullScreen
        ? null
        : (
          <Box className={style.geoMapButtonMaxContent}>
            {isFullscreen || isFullscreenStore ? (
              <IconButton onClick={changeIsFullscreen}>
                <FullscreenExitIcon />
              </IconButton>
            ) : (
              <IconButton onClick={changeIsFullscreen}>
                <FullscreenIcon />
              </IconButton>
            )}
          </Box>
        )}
      <GeoMap
        styles={!isFullscreen ? styles : style.geoMapMaxContentMap}
        center={center}
        zoom={zoom}
        isZoomFocused={isZoomFocused}
        onRefresh={isFullscreen || isFullscreenStore}
        extent={extent}
        setPointCurrent={setPointCurrent}
        isSniperMode={isSniperModeState}
        setIsSniperModeState={setIsSniperModeState}
        {...other}
      >
        {underLayers.map((mapLayer) => (
          <TileLayer
            key={mapLayer.title}
            source={mapLayer.source}
            isShowLayer={mapLayer.title === underLayer}
          />
        ))}
        {overLayers.map((mapLayer) => (
          <TileLayer
            opacity={0.5}
            zIndex={2}
            key={mapLayer.title}
            source={mapLayer.source}
            isShowLayer={mapLayer.isShow}
          />
        ))}
        {Children.toArray(children).map((child) => cloneElement(child, {}))}
      </GeoMap>
    </Box>
  );
}

GeoMapWithTiles.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  styles: PropTypes.string,
  disableFullScreen: PropTypes.bool,
  extent: PropTypes.arrayOf(PropTypes.number),
  isOffButtonFollowMode: PropTypes.bool,
  setPointCurrent: PropTypes.func,
  isZoomFocused: PropTypes.bool,
  isSniperMode: PropTypes.bool,
  name: PropTypes.string,
};

GeoMapWithTiles.defaultProps = {
  center: DEFAULT_CENTER,
  extent: DEFAULT_EXTENT,
  zoom: DEFAULT_ZOOM,
  styles: 'ol-map',
  disableFullScreen: false,
  isOffButtonFollowMode: false,
  setPointCurrent: null,
  isZoomFocused: false,
  isSniperMode: false,
  name: mapNames.default,
};

export default GeoMapWithTiles;
