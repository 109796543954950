import {
  Autocomplete, Box, Button, TextField
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import {
  getCompanies, getDivisions, getVehicleModels, postVehicle, putVehicle
} from '../../../shared/api/api';
import style from './VehicleFormWidget.module.css';
import useDebounce from '../../../shared/hooks/useDebounce';

const initialVehicle = {
  name: '',
  company: null,
  division: null,
  model: null,
  num: '',
};

// Отображает форму редактирования с инфо о ТС
function VehicleFormWidget(props) {
  const {
    selectedVehicle, company, readOnly, updateTree, setIsFormChanged, isFormChanged
  } = props;

  const [vehicle, setVehicle] = useState({ ...initialVehicle, ...selectedVehicle });
  const [companies, setCompanies] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState({ ...initialVehicle });
  const [searchModel, setSearchModel] = useState('');

  const changeTextField = (event) => {
    setVehicle((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    if (errors[event.target.id]) {
      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
    }
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  const resetForm = useCallback((vehicleInfo) => {
    setSearchModel('');
    const newModels = vehicleInfo?.model ? [{ ...vehicleInfo?.model }] : [];
    setModels(newModels);
    setIsFormChanged(false);
    setErrors({ ...initialVehicle });
    setVehicle({ ...initialVehicle, ...vehicleInfo });
  }, [setIsFormChanged]);

  const confirm = useConfirm();
  const handleResetForm = (vehicleInfo) => {
    confirm({
      title: 'Отменить изменения?',
      confirmationText: 'Да',
      cancellationText: 'Нет',
      description: `Вы уверены, что хотите отменить изменения в форме?`
    })
      .then(() => {
        resetForm(vehicleInfo);
      });
  };

  const saveForm = useCallback(() => {
    let isError = false;
    Object.keys(initialVehicle).forEach((key) => {
      if (!isError) {
        isError = !(vehicle[key]);
      }
      setErrors((prev) => ({ ...prev, [key]: !(vehicle[key]) }));
    });
    if (!isError) {
      const body = {
        name: vehicle.name,
        company: vehicle.company,
        division: vehicle.division,
        model: vehicle.model,
        num: vehicle.num,
      };
      if (selectedVehicle.vehicleId) {
        putVehicle({ ...body, vehicleId: selectedVehicle.vehicleId }).then((res) => {
          updateTree(selectedVehicle.key, vehicle);
          resetForm(res);
        }).catch((error) => { throw error; });
      } else {
        postVehicle(body).then((res) => {
          updateTree(selectedVehicle.key, { ...res, ...vehicle }, true);
          resetForm(res);
        }).catch((error) => { throw error; });
      }
    }
  }, [vehicle, resetForm, updateTree, selectedVehicle]);

  useEffect(() => {
    resetForm(selectedVehicle);
    getCompanies({ companyId: company.companyId }).then((data) => {
      setCompanies(data);
    }).catch((error) => { throw error; });
    getDivisions({ companyId: company.companyId }).then((data) => {
      setDivisions(data);
    }).catch((error) => { throw error; });
  }, [company, selectedVehicle, resetForm]);

  const debouncedSearch = useDebounce((value) => {
    if (value.length >= 3) {
      getVehicleModels({ "name.contains": value }).then((data) => {
        setModels(data);
      }).catch((error) => { throw error; });
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(searchModel);
  }, [searchModel, debouncedSearch]);

  return (
    <Box className={style.vehicleFormWidget}>
      <TextField
        fullWidth
        id="name"
        label="Наименование"
        value={vehicle.name}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.name)}
        helperText={errors.name ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <TextField
        fullWidth
        id="num"
        label="Номер"
        value={vehicle.num || ''}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.num)}
        helperText={errors.num ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <Autocomplete
        readOnly={readOnly}
        disablePortal
        id="company"
        options={companies}
        value={vehicle.company}
        onChange={(event, newValue) => {
          // isChangedUser.current = true;
          if (errors.company) {
            setErrors((prev) => ({ ...prev, company: newValue === null }));
          }
          setVehicle((prev) => ({ ...prev, company: newValue }));
          if (!isFormChanged) {
            setIsFormChanged(true);
          }
        }}
        isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors.company)}
            helperText={errors.company ? "Поле не может быть пустым" : null}
            label="Компания"
          />
        )}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      {divisions.length && divisions[0].company?.companyId === company?.companyId
        ? (
          <Autocomplete
            readOnly={readOnly}
            disablePortal
            id="division"
            options={divisions}
            value={vehicle.division}
            onChange={(event, newValue) => {
              // isChangedUser.current = true;
              if (errors.division) {
                setErrors((prev) => ({ ...prev, division: newValue === null }));
              }
              setVehicle((prev) => ({ ...prev, division: newValue }));
              if (!isFormChanged) {
                setIsFormChanged(true);
              }
            }}
            isOptionEqualToValue={(option, value) => option.divisionId === value.divisionId}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.division)}
                helperText={errors.division ? "Поле не может быть пустым" : null}
                label="Подразделение"
              />
            )}
            onBlur={(event) => {
              setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
            }}
          />
        )
        : null}
      <Autocomplete
        readOnly={readOnly}
        id="model"
        name="model"
        options={models}
        value={vehicle.model}
        onChange={(event, newValue) => {
          if (errors.model) {
            setErrors((prev) => ({ ...prev, model: newValue === null }));
          }
          setVehicle((prev) => ({ ...prev, model: newValue }));
          if (!isFormChanged) {
            setIsFormChanged(true);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (errors.model) {
            setErrors((prev) => ({ ...prev, model: false }));
          }
          setSearchModel(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.vehicleModelId === value.vehicleModelId}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors.model)}
            helperText={errors.model ? "Поле не может быть пустым" : null}
            label="Модель"
          />
        )}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      {readOnly
        ? null
        : (
          <div className={style.buttonts}>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => handleResetForm(selectedVehicle)}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => saveForm()}
              variant="contained"
            >
              Сохранить
            </Button>
          </div>
        )}
    </Box>
  );
}

VehicleFormWidget.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  selectedVehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  readOnly: PropTypes.bool,
  updateTree: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
};

VehicleFormWidget.defaultProps = {
  selectedVehicle: null,
  readOnly: true,
  company: null,
};

export default VehicleFormWidget;
