// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { platoonRequestsResourceEnum } from "../redux/platoonRequestsResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const platoonGroupsResourceDtoType = {
  [platoonRequestsResourceEnum.platoonReqId]: PropTypes.number,
  [platoonRequestsResourceEnum.vehicleId]: PropTypes.number,
  [platoonRequestsResourceEnum.companyId]: PropTypes.number,
  [platoonRequestsResourceEnum.routeId]: PropTypes.number,
  [platoonRequestsResourceEnum.role]: PropTypes.string,
  [platoonRequestsResourceEnum.status]: PropTypes.string,
  [platoonRequestsResourceEnum.startDateFrom]: PropTypes.string,
  [platoonRequestsResourceEnum.startDateTo]: PropTypes.string,
  [platoonRequestsResourceEnum.requiredTokens]: PropTypes.string,
  [platoonRequestsResourceEnum.adviseTokens]: PropTypes.string,
  [platoonRequestsResourceEnum.createdDate]: PropTypes.string,
  [platoonRequestsResourceEnum.modifiedDate]: PropTypes.string,
};

export default platoonGroupsResourceDtoType;
