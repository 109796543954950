import React, {
  Children, cloneElement,
  useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import useAuth from "../../shared/lib/hooks/useAuth";
import { getFeadbackCauses, postFeedback } from "../../shared/api/api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function FeedbackBll({
  children, openSnackbar,
}) {
  const [reasons, setReasons] = useState([]);
  const location = useLocation();
  const { user } = useAuth();
  const send = (reason, text, clearInputs) => {
    const body = {
      date: new Date().toISOString(),
      user: user.login,
      url: location.pathname,
      cause: reason,
      info: text,
    };
    postFeedback(body).then(() => {
      openSnackbar('Обращение отправлено');
      clearInputs();
    }).catch(() => {
      openSnackbar('Не удалось отправить обращение');
    });
  };

  const props = {
    send, reasons
  };

  useEffect(() => {
    getFeadbackCauses()
      .then((res) => setReasons(res))
      .catch((error) => { throw error; });
  }, []);

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

FeedbackBll.propTypes = {
  children: PropTypes.element.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default FeedbackBll;
