// eslint-disable-next-line boundaries/no-unknown-files
import PropTypes from "prop-types";
import { appUsersResourceEnum, appUsersResourceUSEREnum } from "../redux/appUsersResource.slice";

// тут описываем тип то что приходит с бека в сыром виде
const appUsersResourceDtoType = {
  [appUsersResourceEnum.appUserId]: PropTypes.number,
  [appUsersResourceEnum.firstName]: PropTypes.string,
  [appUsersResourceEnum.lastName]: PropTypes.string,
  [appUsersResourceEnum.company]: PropTypes.string,
  [appUsersResourceEnum.user]: PropTypes.shape({
    [appUsersResourceUSEREnum.id]: PropTypes.number,
    [appUsersResourceUSEREnum.login]: PropTypes.string
  }),
};

export default appUsersResourceDtoType;
