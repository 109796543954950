import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import {
  useGetAppUserSettingsResourceQuery,
  usePatchAppUserSettingsResourceMutation,
  usePostAppUserSettingsResourceMutation,
} from "../redux/appUserSettingsResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AppUserSettingsResourceBll({ children, idUser }) {
  const [data, setData] = useState();

  const { data: dataAppUserSettingsResourceId } = useGetAppUserSettingsResourceQuery({
    params: { "userId.equals": idUser }
  });

  useEffect(() => {
    setData({
      dataAppUserSettingsResourceId
    });
  }, [dataAppUserSettingsResourceId]);

  const [patchAppUserSettingsResource] = usePatchAppUserSettingsResourceMutation();

  const [postAppUserSettingsResource] = usePostAppUserSettingsResourceMutation();

  const patchAppUserSettingsResourceParams = useCallback(
    ({ id, body }) => patchAppUserSettingsResource({
      id,
      body
    }),
    []
  );

  const postAppUserSettingsResourceParams = useCallback(
    ({ body }) => postAppUserSettingsResource({
      body
    }),
    []
  );

  const props = {
    data,
    patchAppUserSettingsResourceParams,
    postAppUserSettingsResourceParams
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data ? Children
        .toArray(children).map((child) => cloneElement(child, props)) : <CircularProgress />}
    </>
  );
}

AppUserSettingsResourceBll.propTypes = {
  idUser: PropTypes.number,
  children: PropTypes.element.isRequired,
};

AppUserSettingsResourceBll.defaultProps = {
  idUser: null,
};

export default AppUserSettingsResourceBll;
