import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  useAuthorizeByVkMutation,
} from "../../../entities/vk/vkResource/redux/vkResource.api";
import { getAccount, getAppUsers } from "../../../shared/api/api";
// eslint-disable-next-line import/no-unresolved
import { AUTH_FIELDS, authActions } from "../../../entities/auth/tokenResource/redux/tokenResource";
import { useAppDispatch } from "../../../app/store";
import useAuth from "../../../shared/lib/hooks/useAuth";
import { vkRedirectGetMyTokenPage } from "../../../entities/baseQueryWithReAuth/baseQueryWithReAuth";

// функция после проверки токена
export const authorization = (token, dispatch, signin, navigate, setErrorText) => {
  // eslint-disable-next-line no-undef
  sessionStorage.setItem("isAuthVk", "true");
  // eslint-disable-next-line no-undef
  localStorage.setItem("isAuthVk", "true");
  // eslint-disable-next-line no-undef
  sessionStorage.setItem("token", token);
  // eslint-disable-next-line no-undef
  localStorage.setItem("token", token);
  dispatch(authActions.login({ [AUTH_FIELDS.TOKEN_ACCESS]: token }));
  getAccount()
    .then((user) => {
      if (user.login) {
        getAppUsers({ userId: user.id }).then((appUser) => {
          const currentUser = appUser[0];
          const userInfo = {
            appUserId: currentUser.appUserId,
            ...user,
          };
          let redirectUrl = `/companies`;
          const visitVistarM = window.localStorage.getItem('visitVistarM');
          if (!visitVistarM) {
            redirectUrl = "/quick-start";
            window.localStorage.setItem('visitVistarM', true);
          }
          signin(userInfo, () => {
            window.location.replace(redirectUrl);
          });
        });
      }
      if (!token) setErrorText("Неправильный токен авторизации");
    })
    .catch(() => {
      setErrorText("Ошибка при авторизации аккаунта");
    });
};

export function parseVKPayloadFromURL(url) {
  const urlParams = new URLSearchParams(url.split('?')[1]);

  if (!urlParams.has('payload')) {
    // eslint-disable-next-line no-console
    console.error('Параметр payload не найден в URL');
    return null;
  }

  const payload = urlParams.get('payload');

  try {
    const decodedPayload = decodeURIComponent(payload);
    return JSON.parse(decodedPayload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Ошибка при разборе данных:', error);
    return null;
  }
}

function VkAuthPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { signin } = useAuth();
  const [authorizeByVK, {
    isError: isErrorAuthorizeByVK,
    isLoading: isLoadingAuthorizeByVK,
    isSuccess: isSuccessAuthorizeByVK,
    data: dataAuthorizeByVK
  }] = useAuthorizeByVkMutation();

  // eslint-disable-next-line no-undef
  const paramsFromUrl = parseVKPayloadFromURL(window.location.href);

  const params = {
    token: paramsFromUrl.token,
    uuid: paramsFromUrl.uuid,
    firstName: paramsFromUrl?.user?.first_name,
    lastName: paramsFromUrl?.user?.last_name
  };
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    authorizeByVK(params);
  }, []);

  // проверка после useAuthorizeByVkMutation
  useEffect(() => {
    if (isErrorAuthorizeByVK) {
      setErrorText("Авторизоваться через ВК не удалось, попробуйте:");
    }

    if (isSuccessAuthorizeByVK) {
      authorization(dataAuthorizeByVK.id_token, dispatch, signin, navigate, setErrorText);
    }
  }, [isErrorAuthorizeByVK, isSuccessAuthorizeByVK, dataAuthorizeByVK]);

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    }}
    >
      <div>
        {errorText || (
        <>
          {isLoadingAuthorizeByVK ? "Авторизация в системе." : null}
          {(!isLoadingAuthorizeByVK) && <CircularProgress />}
        </>
        )}
        {errorText ? (
          <div style={{
            width: "max-content",
            margin: "auto",
            paddingTop: "30px",
          }}
          >
            <Button
              variant="contained"
              onClick={() => {
                vkRedirectGetMyTokenPage(window.location.href, true);
              }}
            >
              Зарегистрироваться через ВК
            </Button>
            <Link
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "30px"
              }}
              to="/"
            >
              <Button
                variant="contained"
              >
                Перейти на авторизацию
              </Button>
            </Link>
          </div>
        ) : null}
      </div>

    </div>
  );
}

export default VkAuthPage;
