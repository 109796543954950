import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const alertsSettingsResourceEnum = {
};

// Инициализация стейта в сторе
const alertsSettingsResourceInitialState = {
};
// Типизация стора
export const alertsChannelsResourceType = {

};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const alertsChannelsResourceSlice = createSlice({
  name: `${reducerPath.alertsChannelsResource}/counter`,
  initialState: alertsSettingsResourceInitialState,
  reducers: {}
});

export const alertsChannelsResourceReducer = alertsChannelsResourceSlice.reducer;
