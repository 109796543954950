import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";
import {
  usePostTariffPlanResourceMutation,
} from "../redux/tariffPlansResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function TariffPlansResourceCreateBll({ children }) {
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const [postTariffPlanResource] = usePostTariffPlanResourceMutation();

  const postTariffPlanResourceParams = useCallback(
    ({ id, body }) => postTariffPlanResource({
      authToken: token,
      id,
      body
    }),
    []
  );

  const props = {
    postTariffPlanResourceParams,
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

TariffPlansResourceCreateBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TariffPlansResourceCreateBll;
