// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import formatFromTimeISO8601 from "../../../../../shared/utils/formatFromTimeISO8601";
import platoonGroupsResourceDtoType from "../../types/platoonGroupsResource-dto.type";

export function platoonGroupsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const newData = JSON.parse(JSON.stringify(dto));
  newData.intervalDurationNoParsed = dto.intervalDuration;
  newData.intervalDuration = formatFromTimeISO8601(dto.intervalDuration, true);

  return { data: newData, status };
}

platoonGroupsResourceFromDtoService.propTypes = {
  dto: platoonGroupsResourceDtoType,
};

export default platoonGroupsResourceFromDtoService;
