import React, {
  useRef, useEffect,
  useState
} from "react";
import PropTypes from 'prop-types';
import "./GeoMap.css";
import * as ol from "ol";
import GeoMapContext from "../../lib/geo-map/GeoMapContext";
import style from './GeoMap.module.css';
import { useAppDispatch, useAppSelector } from "../../../app/store";
// eslint-disable-next-line boundaries/element-types
import { followModeActions, FollowModeEnum } from "../../../entities/map/followMode/redux/followMode.slice";
// eslint-disable-next-line boundaries/element-types
import {
  zoomControllerActions,
  ZoomControllerEnum
} from "../../../entities/map/zoomController/redux/zoomController.slice";
import reducerPath from "../../../app/reducerPath";

const initMap = (params) => {
  // Создание объекта карты
  const options = {
    view: new ol.View(params),
    layers: [],
    overlays: []
  };
  return new ol.Map(options);
};

// Создает объект карты openlayers и пробрасывает в дочерние компоненты
function GeoMap({
  children,
  zoom,
  center,
  styles,
  onRefresh,
  extent,
  setPointCurrent,
  isSniperMode,
  setIsSniperModeState,
  isAnimateOnExtent,
  moveMapHandler,
  zoomHandler,
}) {
  const mapRef = useRef();
  //  тут делать на false в случае отвязки
  const dispatch = useAppDispatch();

  const zoomController = useAppSelector((state) => state[`${reducerPath.zoomController}/counter`]).zoom;
  const [map, setMap] = useState();
  useEffect(() => {
    setMap(initMap());
  }, []);
  useEffect(() => {
    const mapParams = {
      center,
      zoom: (!zoom || zoom === Infinity) ? zoomController : zoom,
    };
    if (!map) {
      setMap(initMap(mapParams));
      return;
    }
    map.setTarget(mapRef.current);
    map.getView().setZoom((!zoom || zoom === Infinity) ? zoomController : zoom);
    if (!center) return;
    map.getView().setCenter(center);
  }, [center, zoom, map]);

  const changeResolution = () => {
    // Игнорируем данные ивента, сохраняем реальный zoom из компонента, так как они не совпадают
    dispatch(zoomControllerActions.changeMode(
      {
        [ZoomControllerEnum.zoom]: map?.getView().getZoom(),
      }
    ));
    zoomHandler?.(map);
  };

  const changeGeneral = () => {
    dispatch(followModeActions.changeMode({ [FollowModeEnum.isActive]: false }));
  };

  // Обработчик перемещения точки на карте
  const handlePointMove = (event) => {
    if (setPointCurrent) {
      const coords = event.coordinate;
      setPointCurrent([coords[0], coords[1]]);
      setIsSniperModeState(false);
    }
  };

  // Обновление карты
  useEffect(() => {
    if (!map) {
      setMap(initMap());
    }
    if (extent && map) {
      map.setTarget(mapRef.current);
      const view = map.getView();
      if (isAnimateOnExtent && map) {
        // вычисляем ширина и высота extent
        const width = extent[2] - extent[0];
        const height = extent[3] - extent[1];

        // вычисляем разрешение для extent
        const resolutionX = width / map.getSize()[0];
        const resolutionY = height / map.getSize()[1];
        const resolution = Math.max(resolutionX, resolutionY);

        // отступ
        const pad = 0.6;
        // задаем оптимальный zoom для extent
        const newZoom = view.getZoomForResolution(resolution) - pad;
        // запускаем анимированное перемещение к новому extent
        view.animate({
          center: [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2],
          zoom: newZoom,
          duration: 200, // длительность анимации в миллисекундах
        });
      } else {
        view?.fit(extent, { padding: [60, 60, 60, 60] });
      }
    }

    // Добавление слушателя на событие изменения обзора карты
    map?.getView().on('change', changeGeneral);
    // Добавление слушателя на событие изменения зума
    map?.getView().on('change:resolution', changeResolution);
    // Слушатель на событие клика на карту
    map?.on('dblclick', handlePointMove);
    if (isSniperMode) {
      map?.on('click', handlePointMove);
    }
    if (moveMapHandler) {
      // Добавление слушателя на событие перемещения карты
      map?.on('moveend', moveMapHandler);
    }
    return () => {
      map?.setTarget(undefined);
      map?.getView().un('change', changeGeneral);
      map?.getView().un('change:resolution', changeResolution);
      if (moveMapHandler) {
        map?.un('moveend', moveMapHandler);
      }
    };
  }, [map, onRefresh, extent, isSniperMode, isAnimateOnExtent]);

  const tooltipRef = useRef(null);
  let tooltip;
  let currentFeature;

  useEffect(() => {
    tooltip = tooltipRef.current;

    const displayFeatureInfo = (pixel, target) => {
      const feature = target.closest('.ol-control')
        ? undefined
        : map?.forEachFeatureAtPixel(pixel, (feature1) => feature1);

      if (feature) {
        tooltip.style.left = `${pixel[0]}px`;
        tooltip.style.top = `${pixel[1]}px`;
        if (feature !== currentFeature) {
          const text = feature.get('tooltipText');
          if (text) {
            tooltip.innerText = text;
            tooltip.style.visibility = 'visible';
          } else {
            tooltip.style.visibility = 'hidden';
          }
        }
      } else {
        tooltip.style.visibility = 'hidden';
      }
      currentFeature = feature;
    };

    map?.on('pointermove', (evt) => {
      if (evt.dragging) {
        tooltip.style.visibility = 'hidden';
        currentFeature = undefined;
        return;
      }
      const pixel = map?.getEventPixel(evt.originalEvent);
      displayFeatureInfo(pixel, evt.originalEvent.target);
    });

    map?.getTargetElement().addEventListener('pointerleave', () => {
      currentFeature = undefined;
      tooltip.style.visibility = 'hidden';
    });
  }, []);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GeoMapContext.Provider value={{ map }}>
      <div style={{ background: "white" }} ref={mapRef} className={styles}>
        <div
          ref={tooltipRef}
          className={style.tooltip}
        />
        {children}
      </div>
    </GeoMapContext.Provider>
  );
}

GeoMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  styles: PropTypes.string,
  onRefresh: PropTypes.bool,
  extent: PropTypes.arrayOf(PropTypes.number),
  setPointCurrent: PropTypes.func,
  isSniperMode: PropTypes.bool,
  setIsSniperModeState: PropTypes.func,
  isAnimateOnExtent: PropTypes.bool,
  moveMapHandler: PropTypes.func,
  zoomHandler: PropTypes.func,
};

GeoMap.defaultProps = {
  center: [0, 0],
  zoom: 3,
  styles: 'ol-map',
  onRefresh: null,
  extent: null,
  setPointCurrent: null,
  isSniperMode: false,
  isAnimateOnExtent: false,
  setIsSniperModeState: null,
  moveMapHandler: null,
  zoomHandler: null,
};

export default GeoMap;
