function formatPrice(number) {
  // Преобразуем число в строку и разделяем его на группы по 3 цифры с конца
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Объединяем целую и десятичную части, добавляем пробелы
  return parts.join('.');
}

export default formatPrice;
