import { createApi } from "@reduxjs/toolkit/query/react";
import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
import reducerPath from "../../../../app/reducerPath";
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";
// eslint-disable-next-line import/no-named-as-default
import adaptersResourceFromDtoService from "../services/data/adaptersResource-from-dto.service";

const TAG = 'logistic-tasks';
const url = `/${TAG}`;

// Описываем методы обращения к беку
export const logisticTasksApi = createApi({
  reducerPath: `${reducerPath.logisticsResource}/api`,
  tagTypes: [TAG],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.logisticUrl}`),
  endpoints: (builder) => ({
    postLogisticsResource: builder.mutation({
      query: ({
        body,
      }) => ({
        url: `${url}/import/shortpath`,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
      transformResponse: adaptersResourceFromDtoService
    }),
    postLogisticsResourceVrp: builder.mutation({
      query: ({
        body
      }) => ({
        url: `${url}/import/vrp`,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
      transformResponse: adaptersResourceFromDtoService
    }),
    postLogisticsResourceImport: builder.mutation({
      query: ({
        body, taskType
      }) => ({
        url: `${url}/import/${taskType}`,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
    }),
    getLogisticTaskId: builder.query({
      query: ({
        id
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.GET,
        validateStatus: checkStatus,
      }),
      providesTags: [TAG],
    }),
    getLogisticTaskStatus: builder.query({
      query: ({
        id
      }) => ({
        url: `${url}/${id}/status`,
        method: HttpMethods.GET,
        validateStatus: checkStatus,
      }),
      providesTags: [TAG],
    }),
    patchLogisticTask: builder.mutation({
      query: ({
        id,
        body
      }) => ({
        url: `${url}/${id}`,
        method: HttpMethods.PATCH,
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: [TAG],
    }),
    stopLogisticTask: builder.mutation({
      query: ({
        id
      }) => ({
        url: `${url}/${id}/stop`,
        method: HttpMethods.PATCH,
        validateStatus: checkStatus,
      }),
      invalidatesTags: [TAG],
    }),
    reorderLogisticTask: builder.mutation({
      query: ({
        body
      }) => ({
        url: `${url}/reorder`,
        method: HttpMethods.POST,
        body,
        validateStatus: checkStatus,
      }),
    }),
    getLogisticTaskSolution: builder.mutation({
      query: ({
        body
      }) => ({
        url: `${url}`,
        method: HttpMethods.GET,
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: [TAG],
    }),
    patchLogisticTaskSolve: builder.mutation({
      query: ({
        id,
      }) => ({
        url: `${url}/${id}/solve`,
        method: HttpMethods.POST,
        validateStatus: checkStatus,
      }),
      invalidatesTags: [TAG],
    }),
    patchLogisticTaskSolveDelivery: builder.mutation({
      query: ({
        id,
      }) => ({
        url: `${url}/resolve/${id}`,
        method: HttpMethods.POST,
        validateStatus: checkStatus,
      }),
    }),
    createVehicleRoutes: builder.mutation({
      query: ({
        body,
        rePlanDate,
        routeNameTemplate,
        taskName,
      }) => ({
        url: `${url}/create-vehicle-routes`,
        method: HttpMethods.POST,
        body,
        params: {
          routeNameTemplate,
          rePlanDate: rePlanDate || undefined,
          taskName: taskName || undefined,
        },
        validateStatus: checkStatus,
      }),
    }),
    getLogisticTasks: builder.query({
      query: ({ params }) => ({
        url: `${url}`,
        method: HttpMethods.GET,
        params,
        validateStatus: checkStatus,
      }),
      providesTags: [TAG],
    }),
    pauseLogisticTasks: builder.mutation({
      query: ({ id }) => ({
        url: `${url}/pause`,
        method: HttpMethods.PATCH,
        validateStatus: checkStatus,
        params: { id }
      }),
      providesTags: [TAG],
    }),
    stopLogisticTasks: builder.mutation({
      query: ({ id }) => ({
        url: `${url}/stop`,
        method: HttpMethods.PATCH,
        validateStatus: checkStatus,
        params: { id }
      }),
      providesTags: [TAG],
    }),
    startLogisticTasks: builder.mutation({
      query: ({ id }) => ({
        url: `${url}/start`,
        method: HttpMethods.PATCH,
        validateStatus: checkStatus,
        params: { id }
      }),
      providesTags: [TAG],
    }),
    deleteLogisticTasks: builder.mutation({
      query: (id) => ({
        url: `${url}/${id}`,
        method: HttpMethods.DELETE,
        validateStatus: checkStatus,
      }),
      providesTags: [TAG],
    }),
  }),
});

export const {
  useGetLogisticTaskIdQuery,
  useGetLogisticTaskStatusQuery,
  usePatchLogisticTaskMutation,
  useStopLogisticTaskMutation,
  useReorderLogisticTaskMutation,
  useGetLogisticTaskSolutionMutation,
  usePostLogisticsResourceMutation,
  usePostLogisticsResourceVrpMutation,
  usePostLogisticsResourceImportMutation,
  usePatchLogisticTaskSolveMutation,
  usePatchLogisticTaskSolveDeliveryMutation,
  useGetLogisticTasksQuery,
  useLazyGetLogisticTasksQuery,
  usePauseLogisticTasksMutation,
  useStartLogisticTasksMutation,
  useLazyDeleteLogisticTasksQuery,
  useDeleteLogisticTasksMutation,
  useStopLogisticTasksMutation,
  useCreateVehicleRoutesMutation,
} = logisticTasksApi;
