import { useContext, useEffect, useMemo } from "react";
import OLTileLayer from "ol/layer/Tile";
import PropTypes from 'prop-types';
import * as olSource from "ol/source";
import GeoMapContext from "../../lib/geo-map/GeoMapContext";

// Создает/удаляет тайловый слой для карты
function TileLayer({
  source, zIndex, opacity, isShowLayer
}) {
  const { map } = useContext(GeoMapContext);

  const tileLayer = useMemo(() => new OLTileLayer({
    source,
    zIndex,
    opacity,
  }), [source, zIndex, opacity]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!map) return undefined;
    // const pp = map.getLayers();

    if (isShowLayer) {
      map.addLayer(tileLayer);
    } else {
      map.removeLayer(tileLayer);
    }
  }, [map, isShowLayer, tileLayer]);

  return null;
}

TileLayer.propTypes = {
  source: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ])),
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  isShowLayer: PropTypes.bool.isRequired,
};

TileLayer.defaultProps = {
  source: new olSource.OSM(),
  zIndex: 0,
  opacity: 1,
};

export default TileLayer;
