// eslint-disable-next-line boundaries/no-unknown-files
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const LANGUAGES = {
  en: { lang: 'English', short: 'en' },
  ru: { lang: 'Русский', short: 'ru' },
};

const languageDetector = new LanguageDetector();
const supportedLngs = Object.keys(LANGUAGES);

i18n
  // Подключение бэкенда i18next
  .use(Backend)
  // Автоматическое определение языка
  .use(LanguageDetector)
  // модуль инициализации
  .use(initReactI18next)
  .init({
    supportedLngs,
    // Стандартный язык
    fallbackLng: languageDetector.detect() || supportedLngs[0],
    debug: true,
    // Распознавание и кэширование языковых кук
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie']
    },
    interpolation: {
      escapeValue: false
    },
    ns: ['translations', 'errorCodes'],
  });

export default i18n;
