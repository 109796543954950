import React, {
  Children, cloneElement, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useGetTrackPointsResourceSocketQuery } from "../redux/trackPointsResource.api";

// Дата 24 ч назад
const date = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString();

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function TrackPointsResourceBll({
  children, vehicleId
}) {
  const [data, setData] = useState(null);
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const { data: dataTrackPointsResource } = useGetTrackPointsResourceSocketQuery(
    {
      authToken: token,
      params: {
        "vehicleId.equals": vehicleId,
        "date.greaterThan": date,
      }
    },
  );

  useEffect(() => {
    const newData = {};
    newData.dataTrackPointsResource = dataTrackPointsResource;
    setData(newData);
  }, [
    dataTrackPointsResource,
  ]);

  const props = {
    data,
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

TrackPointsResourceBll.propTypes = {
  children: PropTypes.element.isRequired,
  vehicleId: PropTypes.number,
};

TrackPointsResourceBll.defaultProps = {
  vehicleId: NaN,
};

export default TrackPointsResourceBll;
