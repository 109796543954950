import { Box } from '@mui/material';
import React from 'react';

// Отображается в случает отсутствия доступа юзера к приватному view
function NoAccess() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      Нет доступа
    </Box>
  );
}

export default NoAccess;
