import {
  Box, Stack, Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import style from './PlatoonGroup.module.css';
import CarIcon from '../../../shared/ui/CarIcon/CarIcon';
import convertFormatDate from "../../../shared/utils/convertFormatDate";
import processGroupData from '../../../shared/utils/platoonGroupVehicles';

const VEHICLE_ITEM_HEIGNT = 96;
const VEHICLE_ITEM_GAP = 10;
const LINE_SIZE = 1;

const linePosition = Math.floor(VEHICLE_ITEM_HEIGNT / 2);
const lineBottomPosition = linePosition + LINE_SIZE;
const vehicleBgSize = VEHICLE_ITEM_HEIGNT + VEHICLE_ITEM_GAP;

const wrappingGroupStyle = {
  background: `repeating-linear-gradient(
    transparent,
    transparent ${linePosition}px,
    rgb(170, 170, 170) ${linePosition}px,
    rgb(170, 170, 170) ${lineBottomPosition}px,
    transparent ${lineBottomPosition}px,
    transparent ${vehicleBgSize}px
  )`,
  gap: `${VEHICLE_ITEM_GAP}px`,
};
const wrappingVehicleStyle = { height: `${VEHICLE_ITEM_HEIGNT}px` };

// Показ Группы ТС
function PlatoonGroup({ platoonGroup, buttons }) {
  const [hover, setHover] = useState(false);
  const points = useMemo(() => {
    if (!platoonGroup) {
      return [];
    }
    return processGroupData(platoonGroup.noFinishVehicles);
  }, [platoonGroup]);

  return (
    <Box
      className={style.platoonGroup}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {platoonGroup?.name
        ? (
          <Box className={style.headerGroup}>
            <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
              {platoonGroup.name}
              {' '}
              {`(с ${convertFormatDate(platoonGroup.startDateFrom).toFormat.slice(0, -3)}, каждые ${platoonGroup.intervalDuration})`}
            </Typography>
            <Box className={hover ? style.buttonsVisible : style.buttonsHidden}>
              {/* Взаимодействие с группой */}
              {buttons}
            </Box>
          </Box>
        )
        : null}
      <Box className={style.platoonGroupCard}>
        <Box className={style.groupVehiclesContainer}>
          <Box
            className={style.groupVehicles}
          >
            <Box className={style.groupPointsContainer}>
              <Box
                className={style.points}
                sx={wrappingGroupStyle}
              >
                {points.map((point) => (
                  <Box key={point.id} className={style.point} sx={wrappingVehicleStyle}>
                    <Box className={style.labelTop}>
                      {(point.hasNoData || point.simplePoint || point.isOlderThan5min)
                        ? null : (
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {point.doubleDecelerationIcon && <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '24px', color: 'error.dark' }} />}
                            {point.decelerationIcon && <KeyboardArrowLeftIcon sx={{ fontSize: '24px', color: 'error.dark' }} />}
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                component="div"
                                align="center"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  lineHeight: 1,
                                  color: point.intervalNext ? point.nextColor : 'text.secondary',
                                }}
                              >
                                {point.intervalNext || '---'}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="div"
                                align="center"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  lineHeight: 1,
                                  color: point.intervalPrev ? point.prevColor : 'text.secondary',
                                }}
                              >
                                {point.intervalPrev || '---'}
                              </Typography>
                            </Stack>
                            {point.accelerationIcon && <KeyboardArrowRightIcon sx={{ fontSize: '24px', color: '#0B8108' }} />}
                            {point.doubleAccelerationIcon && <KeyboardDoubleArrowRightIcon sx={{ fontSize: '24px', color: '#0B8108' }} />}
                          </Box>
                        )}
                    </Box>
                    {point.simplePoint
                      ? <Brightness1Icon color="primary" />
                      : (
                        <Box sx={{ position: 'relative' }}>
                          { point.isOlderThan2min && (
                          <Box className={style.alarmBadge} sx={{ backgroundColor: '#ffffffee' }}>
                            <SignalWifiBadIcon fontSize="12px" className={style.alarm} sx={{ color: 'error.dark' }} />
                          </Box>
                          ) }
                          <CarIcon className={[style.iconPoint, ((point.isOlderThan5min || point.hasNoData) && !point.simplePoint) ? style.expired : ''].join(' ')} />
                        </Box>
                      )}
                    <Box className={style.labelBottom}>
                      <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                        {point.num}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={style.finishVehicles}>
              {platoonGroup?.finishVehicles?.map((point) => (
                <Box key={point.id} className={style.point}>
                  <CarIcon className={style.iconPoint} />
                  <Box className={style.labelBottom}>
                    <Typography variant="subtitle2" component="div" sx={{ color: '#e33371' }}>
                      {point.num}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PlatoonGroup.propTypes = {
  platoonGroup: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  buttons: PropTypes.element.isRequired,
};

export default PlatoonGroup;
