import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

// Кнопка отмены с иконкой и подписью
function CancelButton({
  name, ...other
}) {
  return (
    <Box>
      <Tooltip title={`Отменить ${name}`.trim()}>
        <span>
          <IconButton
            color="primary"
            {...other}
          >
            <CancelIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}

CancelButton.propTypes = {
  name: PropTypes.string,
};

CancelButton.defaultProps = {
  name: '',
};

export default CancelButton;
