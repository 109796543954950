import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";
import { usePostPaymentResourceMutation } from "../redux/paymentResource.api";
// eslint-disable-next-line boundaries/element-types
import {
  useGetCompanyAccountResourceQuery
} from "../../../account/companyAccountResource/redux/companyAccountResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function PaymentResourceCreateBll({ children }) {
  const [postPaymentResource] = usePostPaymentResourceMutation();

  const { data: dataCompanyAccount } = useGetCompanyAccountResourceQuery({});

  const postPaymentResourceParams = useCallback(
    ({ body }) => postPaymentResource({
      body,
    }),
    []
  );

  const props = {
    postPaymentResourceParams,
    dataCompanyAccount
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

PaymentResourceCreateBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PaymentResourceCreateBll;
