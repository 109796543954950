export const ROUTE_STATUSES = {
  VROUTE_STATUS_ACTIVE: "VROUTE_STATUS_ACTIVE",
  VROUTE_STATUS_PLANNED: "VROUTE_STATUS_PLANNED",
  VROUTE_STATUS_CLOSED: "VROUTE_STATUS_CLOSED",
};

export const statuses = [
  { name: 'Активный', value: ROUTE_STATUSES.VROUTE_STATUS_ACTIVE, color: '#1bdd0d' },
  { name: 'Планируемый', value: ROUTE_STATUSES.VROUTE_STATUS_PLANNED, color: '#1976d3' },
  { name: 'Завершенный', value: ROUTE_STATUSES.VROUTE_STATUS_CLOSED, color: '#c5bdbb' },
];

export const getStatus = (routeStatus) => {
  if (!routeStatus) {
    return null;
  }
  return statuses.find((item) => item.value === routeStatus);
};

export default { statuses, getStatus };
