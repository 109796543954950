import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const DivisionsResourceEnum = {
  divisionId: "divisionId",
  name: "name",
  company: "company",
  parent: "parent"
};

const CompanyEnum = {
  companyId: "companyId",
  name: "name",
  site: "site",
  address: "address",
};
// Инициализация стейта в сторе
const divisionsResourceInitialState = {
  [DivisionsResourceEnum.divisionId]: NaN,
  [DivisionsResourceEnum.name]: null,
  [DivisionsResourceEnum.parent]: null,
  [DivisionsResourceEnum.company]: {
    [CompanyEnum.companyId]: NaN,
    [CompanyEnum.name]: null,
    [CompanyEnum.site]: null,
    [CompanyEnum.address]: null,
  }
};
// Типизация стора
export const divisionsResourceType = {
  [DivisionsResourceEnum.divisionId]: PropTypes.number,
  [DivisionsResourceEnum.name]: PropTypes.string,
  [DivisionsResourceEnum.parent]: PropTypes.string,
  [DivisionsResourceEnum.company]: PropTypes.shape({
    [CompanyEnum.companyId]: PropTypes.number,
    [CompanyEnum.name]: PropTypes.string,
    [CompanyEnum.site]: PropTypes.string,
    [CompanyEnum.address]: PropTypes.string,
  })
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const divisionsResourceSlice = createSlice({
  name: `${reducerPath.divisionsResource}/counter`,
  initialState: divisionsResourceInitialState,
  reducers: {}
});

export const divisionsResourceReducer = divisionsResourceSlice.reducer;
