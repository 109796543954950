// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import platoonGroupVehiclesResourceFromDtoService from "../services/data/platoonGroupVehiclesResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const platoonGroupVehiclesResourceApi = createApi({
  reducerPath: `${reducerPath.platoonGroupVehiclesResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.platoonGroupsResourceUrl}`),
  endpoints: (builder) => ({
    getPlatoonGroupVehiclesResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: `/platoon-group-vehicles`, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      transformResponse: platoonGroupVehiclesResourceFromDtoService
    }),
    patchPlatoonGroupVehiclesResource: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/platoon-group-vehicles/${id}`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    })
  }),
});

export const {
  useLazyGetPlatoonGroupVehiclesResourceQuery,
  useGetPlatoonGroupVehiclesResourceQuery,
  usePatchPlatoonGroupVehiclesResourceMutation
} = platoonGroupVehiclesResourceApi;
