import {
  Alert, AlertTitle,
  Autocomplete,
  Box, Button, FormControl, InputLabel,
  Menu, MenuItem, Paper, Select, TextField, Typography
} from '@mui/material';
import Switch from '@mui/material/Switch';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import CloseIcon from '@mui/icons-material/Close';
import ControlledTable from "../../../shared/ui/ControlledTable/ControlledTable";
import './PlatoonGroupsPanel.css';
import {
  convertStatus,
  statuses,
  statusesRu
} from "../../../entities/platoons/platoonRequestsResource/services/data/platoonRequestsResource-enums";
import addTimeIntervalISO8601 from "../../../shared/utils/addTimeIntervalISO8601";
import {
  platoonGroupsResourceType
} from "../../../entities/platoons/platoonGroupsResource/redux/platoonGroupsResource.slice";
import {
  platoonRequestsResourceInitialStateType
} from "../../../entities/platoons/platoonRequestsResource/redux/platoonRequestsResource.slice";
import {
  platoonGroupVehiclesResourceType
} from "../../../entities/platoons/platoonGroupVehiclesResource/redux/platoonGroupVehiclesResource.slice";
import {
  platoonGroupParamsResourceType
} from "../../../entities/platoons/platoonGroupParamsResource/redux/platoonGroupParamsResource.slice";
import { vehicleType } from "../../../entities/vehicles/vehicle/redux/vehicle.slice";
import { vehicleRoutesType } from "../../../entities/vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.slice";
import {
  vehicleRoutePointsResourceType
} from "../../../entities/vehicles/VehiclesRoute/vehiclesRoutePointsResource/redux/vehiclesRoutePointsResource.slice";
// import RouteVariantConrol from "../../../features/vehicles/RouteVariantConrol";
import RouteInputControl from "../../../features/vehicles/RouteInputControl";
import checkFormatTime from "../../../shared/utils/checkFormatTime";
import {
  useGetVehicleRoutePointsResourceQuery,
  useLazyGetVehicleRoutePointsResourceQuery
} from "../../../entities/vehicles/VehiclesRoute/vehiclesRoutePointsResource/redux/vehiclesRoutePointsResource.api";
import processGroupData from '../../../shared/utils/platoonGroupVehicles';

const headCells = [
  {
    id: 'vehicleId',
    numeric: false,
    disablePadding: false,
    label: 'Транспортное средство',
  },
  {
    id: 'modifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Получен запрос',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'routeId',
    numeric: false,
    disablePadding: false,
    label: 'Маршрут',
  },
];

const headCellsPlatoonGroupVehicles = [
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: '',
    disableSort: true,
  },
  {
    id: 'vehicleId',
    numeric: false,
    disablePadding: false,
    label: 'Транспортное средство',
    disableSort: true,
  },
  {
    id: 'modifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Обновление данных',
    disableSort: true,
  },
  {
    id: 'coming',
    numeric: false,
    disablePadding: false,
    label: 'Прибытие',
    disableSort: true,
  },
  {
    id: 'intervalPrev',
    numeric: false,
    disablePadding: false,
    label: 'Пред',
    disableSort: true,
  },
  {
    id: 'intervalNext',
    numeric: false,
    disablePadding: false,
    label: 'След',
    disableSort: true,
  },
];

const headCellsPlatoonGroupActivitiesResource = [
  {
    id: 'msg',
    numeric: false,
    disablePadding: false,
    label: 'Сообщение',
  },
  {
    id: 'modifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Дата',
  },
  {
    id: 'vehicleId',
    numeric: false,
    disablePadding: false,
    label: 'ТС',
  },

];

const displayedCellsPlatoonGroupActivitiesResource = [
  'msg',
  'modifiedDate',
  'vehicleId',
];

const displayedCellsPlatoonGroupVehicles = [
  'action',
  'vehicleId',
  'modifiedDate',
  'coming',
  'intervalPrev',
  'intervalNext',
];

const displayedCells = [
  'vehicleId',
  'modifiedDate',
  'status',
  'routeId',
];

const roles = [
  { type: 'BOTH', name: 'Без ограничений' },
  { type: 'LEADER', name: 'Только ведущий' },
  { type: 'SATELLITE', name: 'Только ведомый' },
];

function arraysAreEqual(arr1, arr2) {
  if (arr1 === arr2) {
    return true; // Если массивы идентичны по ссылке, они равны
  }

  if (arr1.length !== arr2.length) {
    return false; // Если длины массивов разные, они не равны
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr1.length; i++) {
    const val1 = arr1[i];
    const val2 = arr2[i];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      // Если оба элемента массивы, рекурсивно сравниваем их
      if (!arraysAreEqual(val1, val2)) {
        return false; // Если массивы не равны, возвращаем false
      }
    } else if (val1 !== val2) {
      return false; // Если элементы не равны, возвращаем false
    }
  }

  return true; // Если все элементы равны, массивы равны
}

function ErrorMessage({
  dataVehicleRoutePointsResourceState,
  e,
  dataVehicleRoutePointsAll,
  selectedRoute
}) {
  const value = dataVehicleRoutePointsResourceState
    .find((item) => item.vroutePointId === Number(e.value));
  const oldPoint = dataVehicleRoutePointsAll?.data
    .find((item) => item.vroutePointId === Number(e.value));

  const isError = e.value && !value;
  return (isError) && (
  <Alert sx={{ width: "100%" }} severity="error">
    <AlertTitle>Ошибка</AlertTitle>
    Точка
    {' '}
    <strong>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      "
      {oldPoint?.geoZone?.name || oldPoint?.geoName?.name }
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      "
    </strong>
    {' '}
    нет в машруте
    {' '}
    <strong>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      "
      {selectedRoute?.name}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      "
    </strong>
  </Alert>
  );
}

function PlatoonGroupsPanel({
  data,
  patchPlatoonGroupsResourceParams,
  postPlatoonGroupsResourceParams,
  patchPlatoonRequestsResourceParams,
  patchPlatoonGroupVehiclesResourceParams,
  postPlatoonRequestsResourceParams,
  platoonGroupId,
  companyId,
  toPlatoons,
  getVehiclesRoutes,
  vehiclesRoutes,
  postPlatoonGroupParamsResourceParamsRoute,
  restartPolling,
}) {
  const [dataPlatoonGroupsState, setDataPlatoonGroupsState] = useState({});
  const [dataPlatoonRequestsResourceState, setDataPlatoonRequestsResourceState] = useState([]);
  const [selectedDataPlatoonRequestsResourceState,
    setSelectedDataPlatoonRequestsResourceState] = useState([]);
  const [selectedDataPlatoonGroupVehiclesResourceState,
    setSelectedDataPlatoonGroupVehiclesResourceState] = useState([]);

  const [isCorrect, setIsCorrect
  ] = useState(false);
  const [isErr, setIsErr
  ] = useState(false);

  const [isWindowPlatoonGroupParams,
    setIsWindowPlatoonGroupParams] = useState(null);

  const [dataPlatoonGroupVehiclesResourceState, setDataPlatoonGroupVehiclesResourceState
  ] = useState([]);

  const [dataPlatoonGroupParamsResourceState, setDataPlatoonGroupParamsResourceState
  ] = useState([]);

  const isHasChangesParams = useMemo(() => {
    if (data?.dataPlatoonGroupParamsResource?.data?.length
        && dataPlatoonGroupParamsResourceState?.length) {
      return arraysAreEqual(
        dataPlatoonGroupParamsResourceState,
        data?.dataPlatoonGroupParamsResource?.data
      );
    }
    return false;
  }, [dataPlatoonGroupParamsResourceState, data]);

  const [dataPlatoonGroupActivitiesResource, setDataPlatoonGroupActivitiesResource
  ] = useState([]);

  const [dataVehiclesResourceState, setDataVehiclesResourceState
  ] = useState([]);

  const [dataVehiclesRouteResourceState, setDataVehiclesRouteResourceState
  ] = useState([]);

  // const [dataVehicleRoutePointsResourceState, setDataVehicleRoutePointsResourceState
  // ] = useState([]);

  const [selectedRoute, setSelectedRoute] = useState(null);

  const [nameRoute, setNameRoute] = useState(null);

  const [addedVehicle, setAddedVehicle] = useState(null);
  const [selectedRole, setSelectedRole] = useState(roles[0].type);
  const confirm = useConfirm();

  useEffect(() => {
    if (data?.dataPlatoonGroups) {
      setDataPlatoonGroupsState(data.dataPlatoonGroups.data);
    }

    if (data?.dataPlatoonGroups && data?.dataVehiclesRouteResource) {
      const vehiclesRouteCurrent = data?.dataVehiclesRouteResource?.data
        ?.filter((e) => e.vehicleRouteId === data.dataPlatoonGroups.data.routeId);
      setSelectedRoute({
        routeId: data.dataPlatoonGroups.data.routeId,
        name: vehiclesRouteCurrent.length ? vehiclesRouteCurrent[0].name : ''
      });
    }
    if (data?.dataPlatoonRequestsResource) {
      setDataPlatoonRequestsResourceState(data.dataPlatoonRequestsResource.data);
    }
    if (data?.dataPlatoonGroupVehiclesResource?.data) {
      const vehiclesData = processGroupData(data.dataPlatoonGroupVehiclesResource.data)
        .reverse()
        .map((e) => {
          const newObject = { ...e };
          const newVehicleId = data?.dataVehiclesResource?.data
            ?.filter((el) => el.vehicleId === e.vehicleId)[0];
          newObject.vehicleId = newVehicleId ? newVehicleId.name : `неизвестный автомобиль ID:${e.vehicleId}`;
          // newObject.graph = e.graph?.replaceAll("S", "с ")
          //  .replaceAll("H", "ч ").replaceAll("M", "м ");
          return newObject;
        });
      setDataPlatoonGroupVehiclesResourceState(vehiclesData);
    }
    if (data?.dataPlatoonGroupActivitiesResource) {
      setDataPlatoonGroupActivitiesResource(data?.dataPlatoonGroupActivitiesResource.data);
    }
    if (data?.dataPlatoonGroupParamsResource) {
      setDataPlatoonGroupParamsResourceState(data?.dataPlatoonGroupParamsResource?.data);

      const t = data?.dataPlatoonGroupParamsResource.data.find((e) => e.name === 'route_id');

      setNameRoute((data?.dataVehiclesRouteResource?.data)
        ? data?.dataVehiclesRouteResource?.data.find((e) => e.vehicleRouteId === Number(t?.value)) : '');
    }
    if (data?.dataVehiclesResource) {
      setDataVehiclesResourceState(data?.dataVehiclesResource.data);
    }
    if (data?.dataVehiclesRouteResource) {
      setDataVehiclesRouteResourceState(data?.dataVehiclesRouteResource.data);
    }
    // if (data?.dataVehicleRoutePointsResource) {
    //   console.log(111, );
    //
    //   setDataVehicleRoutePointsResourceState(data?.dataVehicleRoutePointsResource.data);
    // }
    setSelectedDataPlatoonRequestsResourceState([]);
  }, [data]);

  const
    { data: dataVehicleRoutePointsAll } = useGetVehicleRoutePointsResourceQuery({
      params: {
        "companyId.equals": companyId,
      }
    });

  const [getVehicleRoutePointsResource,
    { data: dataVehicleRoutePoints }] = useLazyGetVehicleRoutePointsResourceQuery();

  const dataVehicleRoutePointsResourceState = useMemo(
    () => dataVehicleRoutePoints?.data || [],
    [dataVehicleRoutePoints]
  );

  useEffect(() => {
    if (data?.dataPlatoonGroups?.data?.routeId && companyId && selectedRoute?.routeId) {
      getVehicleRoutePointsResource({
        params: {
          "companyId.equals": companyId,
          "routeId.equals": selectedRoute?.routeId
        }
      });
    }
  }, [data?.dataPlatoonGroups?.data?.routeId, companyId, selectedRoute?.routeId]);

  const handleSaveInfo = () => {
    if (checkFormatTime(dataPlatoonGroupsState.intervalDuration || '')) {
      const newData = JSON.parse(JSON.stringify(data)).dataPlatoonGroups.data;
      newData.name = dataPlatoonGroupsState.name;
      newData.routeId = Number(dataPlatoonGroupsState.routeId);
      newData.startDateFrom = dataPlatoonGroupsState.startDateFrom;
      newData.startDateTo = dataPlatoonGroupsState.startDateTo;
      newData.status = dataPlatoonGroupsState.status;
      newData.intervalDuration = dataPlatoonGroupsState.intervalDuration;
      patchPlatoonGroupsResourceParams({ id: newData.platoonGroupId, body: newData }).then((e) => {
        if (e?.error) {
          setIsErr(true);
        } else {
          setIsCorrect(true);
          setIsErr(false);
          restartPolling?.();
        }
      });
    }
  };

  const handleCreateInfo = () => {
    if (checkFormatTime(dataPlatoonGroupsState.intervalDuration || '')) {
      const newData = JSON.parse(JSON.stringify(data))?.dataPlatoonGroups?.data || {};
      newData.name = dataPlatoonGroupsState.name;
      newData.startDateFrom = dataPlatoonGroupsState.startDateFrom;
      newData.startDateTo = dataPlatoonGroupsState.startDateTo;
      newData.status = dataPlatoonGroupsState.status;
      newData.intervalDuration = dataPlatoonGroupsState.intervalDuration;
      newData.companyId = companyId;
      newData.status = "CREATED";
      newData.routeId = Number(dataPlatoonGroupsState.routeId);
      postPlatoonGroupsResourceParams({ body: newData }).then((e) => {
        if (e?.data?.data?.platoonGroupId) {
          toPlatoons();
        }
        if (e?.error) {
          setIsErr(true);
        } else {
          setTimeout(toPlatoons, 500);
          setIsCorrect(true);
          setIsErr(false);
        }
      });
    }
  };

  const handleChangeStatusRequests = (param) => {
    dataPlatoonRequestsResourceState.forEach((e, index) => {
      if (selectedDataPlatoonRequestsResourceState.includes(index)) {
        const newData = JSON.parse(JSON.stringify(e));
        newData.status = param ? statuses.REQUEST_ACK : statuses.ABORTED;
        if (convertStatus(newData.status) !== e.status) {
          patchPlatoonRequestsResourceParams({ id: e.platoonReqId, body: newData });
        }
      }
    });
  };

  const handleChangeIntervalGroup = (interval) => {
    const body = {
      platoonGroupId,
      intervalDuration:
        addTimeIntervalISO8601(dataPlatoonGroupsState.intervalDurationNoParsed, interval),
    };
    patchPlatoonGroupsResourceParams({ id: platoonGroupId, body }).then((e) => {
      if (!e?.error) {
        restartPolling?.();
      }
    });
  };

  const addVehicleToGroup = (vehicle) => {
    const body = {
      vehicleId: vehicle?.vehicleId,
      routeId: Number(data.dataPlatoonGroups.data.routeId),
      status: 'ACTIVE',
      companyId,
      role: selectedRole,
      startDateFrom: dataPlatoonGroupsState.startDateFrom,
      startDateTo: dataPlatoonGroupsState.startDateTo,
      createdDate: new Date(),
      modifiedDate: new Date(),
      group: { platoonGroupId },
    };
    postPlatoonRequestsResourceParams({ body });
    setAddedVehicle(null);
    setSelectedRole(roles[0].type);
  };

  const removeVehicleFromGroup = () => {
    selectedDataPlatoonGroupVehiclesResourceState.forEach((index) => {
      patchPlatoonGroupVehiclesResourceParams({
        id: dataPlatoonGroupVehiclesResourceState[index].platoonGrpVehicleId,
        body: {
          status: 'CANCEL',
          platoonGrpVehicleId: dataPlatoonGroupVehiclesResourceState[index].platoonGrpVehicleId
        }
      });
    });
    setSelectedDataPlatoonGroupVehiclesResourceState([]);
  };

  const [paramsIsSuccess, setParamsIsSuccess] = useState(null);
  const [paramsIsError, setParamsIsError] = useState(null);

  const clearAlertParams = useCallback(() => {
    setParamsIsSuccess(null);
    setParamsIsError(null);
  }, [setParamsIsSuccess, setParamsIsError]);

  const handleChangePlatoonGroupParamsResource = useCallback(async () => {
    // const deletePromises = data?.dataPlatoonGroupParamsResource?.data?.map((e) => {
    //   const t = dataPlatoonGroupParamsResourceState
    //     .filter((item) => item.name === e.name && !item.isNew).length;
    //   if (!t) {
    //     return deletePlatoonGroupParamsResourceParams({ id: e.plGrParamId });
    //   }
    //   return new Promise((resolve) => { resolve(); });
    // });
    const editOrCreatePromises = postPlatoonGroupParamsResourceParamsRoute({
      id: platoonGroupId,
      body: {
        params: dataPlatoonGroupParamsResourceState.map((e) => ({
          group: e.group,
          name: e.name,
          // plGrParamId:plGrParamId;
          section: e.section,
          type: e.type,
          value: e.value,
        }))
      }
    });

    Promise.all([...editOrCreatePromises]).then((values) => {
      const errors = values.filter((errorCurrent) => errorCurrent?.error?.data?.status);

      if (errors?.length) {
        setParamsIsError(true);
      } else {
        setParamsIsSuccess(true);
      }
    });
    setSelectedDataPlatoonGroupVehiclesResourceState([]);
  }, [dataPlatoonGroupParamsResourceState, platoonGroupId]);

  useEffect(() => {
    if (selectedRoute) {
      const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
      newData.name = selectedRoute.name;
      newData.routeId = selectedRoute.routeId;
      setDataPlatoonGroupsState(newData);
    }
  }, [selectedRoute]);

  const optionsVehicles = useMemo(() => dataVehiclesResourceState.filter(
    (vehicle) => !dataPlatoonRequestsResourceState.some(
      (platoonReq) => vehicle.vehicleId === platoonReq.vehicleId
    )
  ), [dataPlatoonRequestsResourceState, dataVehiclesResourceState]);

  useEffect(() => {
    setIsCorrect(false);
    setIsErr(false);
  }, [dataPlatoonGroupsState]);

  const isActiveReserveRoute = useMemo(() => dataPlatoonGroupParamsResourceState.find((e) => e.name === "route_id"), [dataPlatoonGroupParamsResourceState]);

  const isCorrectChangeRoute = useMemo(
    () => (() => {
      const e = dataPlatoonGroupParamsResourceState?.find((el) => (el.name === 'geo_from'));
      if (e) {
        const value = dataVehicleRoutePointsResourceState
          .find((item) => item.vroutePointId === Number(e.value));
        return !!(e?.value && !value);
      }
      return false;
    })() || (() => {
      const e = dataPlatoonGroupParamsResourceState?.find((el) => (el.name === 'geo_to'));
      if (e) {
        const value = dataVehicleRoutePointsResourceState
          .find((item) => item.vroutePointId === Number(e.value));
        return !!(e?.value && !value);
      }
      return false;
    })(),
    [dataPlatoonGroupParamsResourceState, dataVehicleRoutePointsResourceState]
  );

  const onRouteNameChange = useCallback((name) => {
    if (companyId) {
      const params = {
        'companyId.equals': companyId,
        'name.contains': name,
        page: 0,
        size: 50,
        sort: 'name,asc',
      };
      getVehiclesRoutes({ params });
    }
  }, [companyId, getVehiclesRoutes]);

  return (
    <Box>
      <Box className="vehicleFuelEfficiencyPanel">
        <Typography sx={{ paddingBottom: "25px", paddingTop: "25px", color: "rgba(0, 0, 0, 0.54)" }}>
          Основная информация
        </Typography>
        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
        }}
        >
          <Accordion sx={{ p: 1, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Основная информация</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{
                display: "flex", alignItems: "center", gap: "10px", position: "rreletive "
              }}
              >
                <RouteInputControl
                  selectedRoute={selectedRoute}
                  selectRoute={(e) => setSelectedRoute({
                    routeId: e?.vehicleRouteId,
                    name: e?.name || ''
                  })}
                  onRouteNameChange={onRouteNameChange}
                  loading={vehiclesRoutes?.status === 'pending'}
                  vehicleRoutes={vehiclesRoutes?.data?.data}
                />
              </Box>

              <Typography sx={{
                fontWeight: " 500",
                fontSize: "18px",
                lineHeight: "24px",
                color: "rgba(0, 0, 0, 0.54)",
                paddingTop: "40px",
                paddingBottom: "10px"
              }}
              >
                Интервал допустимого старта группы
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box sx={{ width: "231px" }}>
                  <DateTimePicker
                    label="C"
                    value={new Date(dataPlatoonGroupsState.startDateFrom)}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onChange={(e) => {
                      // eslint-disable-next-line no-restricted-globals
                      if (!isNaN(e) && e) {
                        const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
                        newData.startDateFrom = e.toISOString();
                        setDataPlatoonGroupsState(newData);
                      } else {
                        const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
                        newData.startDateFrom = null;
                        setDataPlatoonGroupsState(newData);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
                <Box sx={{ width: "231px" }}>
                  <DateTimePicker
                    label="По"
                    value={new Date(dataPlatoonGroupsState.startDateTo)}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onChange={(e) => {
                      // eslint-disable-next-line no-restricted-globals
                      if (!isNaN(e) && e) {
                        const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
                        newData.startDateTo = e.toISOString();
                        setDataPlatoonGroupsState(newData);
                      } else {
                        const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
                        newData.startDateTo = null;
                        setDataPlatoonGroupsState(newData);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
              </Box>
              <Typography sx={{
                fontWeight: " 500",
                fontSize: "18px",
                lineHeight: "24px",
                color: "rgba(0, 0, 0, 0.54)",
                paddingTop: "40px",
                paddingBottom: "10px"
              }}
              >
                График движения
              </Typography>
              <TextField
                sx={{ width: '231px' }}
                value={dataPlatoonGroupsState.intervalDuration || ''}
                error={!checkFormatTime(dataPlatoonGroupsState.intervalDuration || '')}
                onChange={(e) => {
                  const newData = JSON.parse(JSON.stringify(dataPlatoonGroupsState));
                  newData.intervalDuration = e.target.value;
                  setDataPlatoonGroupsState(newData);
                }}
                label="Интервал движения (время)"
                variant="standard"
                helperText={!checkFormatTime(dataPlatoonGroupsState.intervalDuration || '') && "Корректный формат: 0ч 0мин 0сек"}
              />
              <Box sx={{ marginTop: "30px" }}>
                {!isErr && isCorrect
                    && (
                    <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="success">
                      <AlertTitle>Успешно</AlertTitle>
                      Данные успешно сохранены
                    </Alert>
                    )}
                {isErr
                    && (
                    <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="error">
                      <AlertTitle>Ошибка</AlertTitle>
                      Произошла ошибка
                    </Alert>
                    )}
              </Box>

              {isActiveReserveRoute
                  && (selectedRoute?.routeId !== data?.dataPlatoonGroups?.data?.routeId) && (
                  <Alert sx={{ width: "100%" }} severity="warning">
                    <AlertTitle>Предупреждение!</AlertTitle>
                    При редактировании
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong>"Маршрута"</strong>
                    {' '}
                    в группе ТС, необходимо
                    изменить
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong>"После точки маршрута"</strong>
                    {' '}
                    и
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong>"До точки маршрута"</strong>
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    в блоке
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong>"Параметры"</strong>
                    {' '}
                    —
                    {' '}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong>"Резервный маршрут"</strong>
                    {' '}
                    на
                    соответствующие точки, которые имеются в маршруте, который вы выбрали.
                  </Alert>
              )}
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "max-content",
                marginLeft: "auto"
              }}

              >
                <Button
                  onClick={() => {
                    const vehiclesRouteCurrent = data?.dataVehiclesRouteResource?.data
                      ?.filter((e) => e.vehicleRouteId === data?.dataPlatoonGroups?.data?.routeId);

                    if (vehiclesRouteCurrent) {
                      setSelectedRoute({
                        routeId: data.dataPlatoonGroups?.data?.routeId,
                        name: vehiclesRouteCurrent.length ? vehiclesRouteCurrent[0].name
                          : setSelectedRoute(null)
                      });
                    }
                    if (data?.dataPlatoonGroups?.data) {
                      setDataPlatoonGroupsState(data.dataPlatoonGroups.data);
                    }
                  }}
                  variant="contained"
                >
                  ОТМЕНА
                </Button>
                {data?.dataPlatoonGroups
                  ? (
                    <Button
                      onClick={handleSaveInfo}
                      disabled={!(
                        checkFormatTime(dataPlatoonGroupsState.intervalDuration)
                        && dataPlatoonGroupsState.startDateFrom
                          && dataPlatoonGroupsState.startDateTo
                      && selectedRoute)
                          || (isCorrectChangeRoute ? (isActiveReserveRoute
                              && selectedRoute?.routeId !== data?.dataPlatoonGroups?.data?.routeId)
                            : false)}
                      variant="contained"
                    >
                      СОХРАНИТЬ
                    </Button>
                  )
                  : (
                    <Button
                      onClick={handleCreateInfo}
                      disabled={!(
                        checkFormatTime(dataPlatoonGroupsState.intervalDuration)
                          && dataPlatoonGroupsState.startDateFrom
                          && dataPlatoonGroupsState.startDateTo
                          && selectedRoute)}
                      variant="contained"
                    >
                      СОЗДАТЬ
                    </Button>
                  )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!platoonGroupId} sx={{ p: 1, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Заявки</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  {/* <EnhancedTableToolbar /> */}
                  <ControlledTable
                    rows={dataPlatoonRequestsResourceState.map((e) => {
                      const newObject = JSON.parse(JSON.stringify(e));
                      const newVehicleId = dataVehiclesResourceState
                        .filter((el) => el.vehicleId === e.vehicleId)[0];
                      newObject.vehicleId = newVehicleId ? newVehicleId.name : `неизвестный автомобиль ID:${e.vehicleId}`;

                      const newRouteId = dataVehiclesRouteResourceState
                        .filter((el) => el.vehicleRouteId === e.routeId)[0];
                      newObject.routeId = newRouteId ? newRouteId.name : `неизвестный маршрут ID:${e.vehicleRouteId}`;
                      return newObject;
                    })}
                    headCells={headCells}
                    displayedCells={displayedCells}
                    countRowsPerPage={5}
                    selected={selectedDataPlatoonRequestsResourceState}
                    setSelected={(e) => {
                      if (e.length === 1 || !e.length) {
                        dataPlatoonRequestsResourceState.forEach((el) => {
                          if ((Number(el.id) === Number(e)
                            && (el.status === statusesRu.ACTIVE
                              || el.status === statusesRu.REQUEST_ACK
                              || el.status === statusesRu.CREATED))
                            || !e.length) {
                            setSelectedDataPlatoonRequestsResourceState(e);
                          }
                        });
                      } else {
                        const active = [];
                        e.forEach((item) => {
                          dataPlatoonRequestsResourceState.forEach((el) => {
                            if (Number(el.id) === Number(item)
                              && (el.status === statusesRu.ACTIVE
                                || el.status === statusesRu.REQUEST_ACK
                                || el.status === statusesRu.CREATED)) {
                              active.push(item);
                            }
                          });
                        });
                        setSelectedDataPlatoonRequestsResourceState(active);
                      }
                    }}
                  />
                  {/* <TablePagination /> */}
                </Paper>
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "max-content",
                marginLeft: "auto"
              }}
              >
                <Button onClick={() => handleChangeStatusRequests(false)} variant="contained">ОТКЛОНИТЬ</Button>
                <Button
                  disabled={dataPlatoonRequestsResourceState.filter(
                    (_, i) => selectedDataPlatoonRequestsResourceState.includes(i)
                  ).some((e) => e.status === statusesRu[statuses.CANCELED])}
                  onClick={() => handleChangeStatusRequests(true)}
                  variant="contained"
                >
                  ПОДТВЕРДИТЬ
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!platoonGroupId} sx={{ p: 1, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>График движения ТС</Typography>
            </AccordionSummary>
            <AccordionDetails>
              { dataPlatoonGroupVehiclesResourceState[0]?.averageIntervalTxt
                && (
                  <Typography mb={1} sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    Cредний интервал в группе
                    {' '}
                    {dataPlatoonGroupVehiclesResourceState[0]?.averageIntervalTxt}
                  </Typography>
                )}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    width: '100%',
                  }}
                >
                  <ControlledTable
                    rows={dataPlatoonGroupVehiclesResourceState}
                    headCells={headCellsPlatoonGroupVehicles}
                    displayedCells={displayedCellsPlatoonGroupVehicles}
                    countRowsPerPage={5}
                    selected={selectedDataPlatoonGroupVehiclesResourceState}
                    setSelected={(e) => {
                      setSelectedDataPlatoonGroupVehiclesResourceState(e);
                    }}
                  />
                  {dataPlatoonGroupVehiclesResourceState.length
                    ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'flex-end',
                          gap: "10px",
                          width: "100%",
                          padding: '20px',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => {
                            confirm({
                              title: 'Вывести из группы?',
                              confirmationText: 'Да',
                              cancellationText: 'Отмена',
                              description: `Вы действительно хотите вывести из группы выбранные ТС?`
                            })
                              .then(() => removeVehicleFromGroup(e))
                              .catch(() => { });
                          }}
                          disabled={selectedDataPlatoonGroupVehiclesResourceState.length === 0}
                        >
                          Вывести из группы
                        </Button>
                      </Box>
                    )
                    : null}
                </Paper>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  <Button onClick={() => handleChangeIntervalGroup('PT5M')} variant="contained">ИНТЕРВАЛ +5мин</Button>
                  <Button onClick={() => handleChangeIntervalGroup('PT15M')} variant="contained">ИНТЕРВАЛ +15мин</Button>
                  <Button onClick={() => handleChangeIntervalGroup('PT-5M')} variant="contained">ИНТЕРВАЛ -5мин</Button>
                  <Button onClick={() => handleChangeIntervalGroup('PT-15M')} variant="contained">ИНТЕРВАЛ -15мин</Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  <Autocomplete
                    sx={{
                      width: '200px'
                    }}
                    size="small"
                    value={addedVehicle}
                    onChange={(event, newValue) => setAddedVehicle(newValue)}
                    id="vehicle"
                    options={optionsVehicles}
                    isOptionEqualToValue={(option, value) => option.vehicleId === value.vehicleId}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="ТС" />}
                  />
                  <FormControl
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Роль</InputLabel>
                    <Select
                      name="role"
                      id="role"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      label="Роль"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.type} value={role.type}>{role.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    disabled={!addedVehicle}
                    onClick={() => addVehicleToGroup(addedVehicle)}
                  >
                    Добавить ТС
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!platoonGroupId} sx={{ p: 1, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Параметры</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!!dataPlatoonGroupParamsResourceState.filter((e) => (e.name === 'route_id')).length && (
                <Box sx={{ border: ' 2px dashed #1976d2', padding: "20px 5px" }}>
                  <Typography sx={{
                    fontWeight: " 500",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "25px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "20px"
                  }}
                  >
                    Резервный маршрут
                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        const newData = dataPlatoonGroupParamsResourceState.filter((e) => e.name !== 'route_id'
                          && e.name !== 'route_id'
                          && e.name !== 'min_front_vehicle'
                          && e.name !== 'min_exit_vehicle'
                          && e.name !== 'geo_from'
                          && e.name !== 'geo_to');
                        setDataPlatoonGroupParamsResourceState(newData);
                      }}
                    />
                  </Typography>
                  {dataPlatoonGroupParamsResourceState.map((e, index) => (e.name === 'route_id' ? (
                    <Box
                      key={`dataPlatoonGroupParamsResourceStateMin_route_id-${e.name}-${index + 1}`}
                      sx={{ width: '431px', marginBottom: "25px" }}
                    >
                      <Autocomplete
                        value={nameRoute}
                        onChange={(event, newValue) => {
                          setNameRoute(newValue);
                          const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                            const newObject = JSON.parse(JSON.stringify(el));
                            if (newObject.name === 'route_id') {
                              newObject.value = newValue?.vehicleRouteId;
                            }
                            return newObject;
                          });
                          setDataPlatoonGroupParamsResourceState(newData);
                          clearAlertParams();
                        }}
                        options={dataVehiclesRouteResourceState}
                        getOptionLabel={(option) => (option ? option.name : '')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Название маршрута"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                      />
                    </Box>
                  ) : null))}
                  <Box sx={{
                    marginBottom: "25px",
                    display: "flex",
                    gap: "25px"
                  }}
                  >
                    {dataPlatoonGroupParamsResourceState.map((e, i) => (e.name === 'min_front_vehicle' ? (
                      <TextField
                        key={`dataPlatoonGroupParamsResourceStateMin_front_vehicle-${e.name}-${i + 1}`}
                        sx={{ width: '431px' }}
                        value={e.value}
                        onChange={(event) => {
                          const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                            const newObject = JSON.parse(JSON.stringify(el));
                            if (newObject.name === 'min_front_vehicle') {
                              newObject.value = event.target.value;
                            }
                            return newObject;
                          });
                          setDataPlatoonGroupParamsResourceState(newData);
                          clearAlertParams();
                        }}
                        label="Впереди ТС, не менее"
                        variant="standard"
                      />
                    ) : null))}
                    {dataPlatoonGroupParamsResourceState.map((e, i) => (e.name === 'min_exit_vehicle' ? (
                      <TextField
                        key={`dataPlatoonGroupParamsResourceStateMin_exit_vehicle-${e.name}-${i + 1}`}
                        sx={{ width: '431px' }}
                        value={e.value}
                        onChange={(event) => {
                          const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                            const newObject = JSON.parse(JSON.stringify(el));
                            if (newObject.name === 'min_exit_vehicle') {
                              newObject.value = event.target.value;
                            }
                            return newObject;
                          });
                          setDataPlatoonGroupParamsResourceState(newData);
                          clearAlertParams();
                        }}
                        label="Из них на выходе с маршрута ТС, не менее"
                        variant="standard"
                      />
                    ) : null))}
                  </Box>
                  <Box sx={{
                    display: "flex",
                    gap: "25px"
                  }}
                  >
                    {dataPlatoonGroupParamsResourceState.map((e, i) => (e.name === 'geo_from' ? (
                      <FormControl
                        sx={{ width: '431px' }}
                        key={`dataPlatoonGroupParamsResourceStateGeo_from-${e.name}-${e.value}-${i + 1}`} // Изменил ключ
                        fullWidth
                      >
                        <Autocomplete
                          autoComplete
                          disablePortal
                          value={(() => dataVehicleRoutePointsResourceState
                            .find((item) => item.vroutePointId === Number(e.value)))() || null}
                          onChange={(_, newValue) => {
                            const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                              const newObject = JSON.parse(JSON.stringify(el));
                              if (newObject.name === 'geo_from') {
                                newObject.value = newValue?.vroutePointId;
                              }
                              return newObject;
                            });
                            setDataPlatoonGroupParamsResourceState(newData);
                            clearAlertParams();
                          }}
                          options={dataVehicleRoutePointsResourceState}
                          getOptionLabel={(option) => ((option.geoName?.name && `${option.geoName?.name}-${option.vroutePointId}`) || (option.geoZone?.name && `${option.geoZone?.name}-${option.vroutePointId}`) || `Без названия ID:${option.vroutePointId}`)}
                          renderInput={(params) => <TextField {...params} label="После точки маршрута" />}
                        />
                        <ErrorMessage
                          dataVehicleRoutePointsResourceState={dataVehicleRoutePointsResourceState}
                          e={e}
                          dataVehicleRoutePointsAll={dataVehicleRoutePointsAll}
                          selectedRoute={selectedRoute}
                        />
                      </FormControl>
                    ) : null))}
                    {dataPlatoonGroupParamsResourceState.map((e, i) => (e.name === 'geo_to' ? (
                      <FormControl sx={{ width: '431px' }} key={`dataPlatoonGroupParamsResourceStateGeo_to-${e.name}-${i + 1}`} fullWidth>
                        <Autocomplete
                          autoComplete
                          disablePortal
                          value={(() => dataVehicleRoutePointsResourceState
                            .find((item) => item.vroutePointId === Number(e.value)))() || null}
                          onChange={(_, newValue) => {
                            const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                              const newObject = JSON.parse(JSON.stringify(el));
                              if (newObject.name === 'geo_to') {
                                newObject.value = newValue?.vroutePointId;
                              }
                              return newObject;
                            });
                            setDataPlatoonGroupParamsResourceState(newData);
                            clearAlertParams();
                          }}
                          options={dataVehicleRoutePointsResourceState}
                          getOptionLabel={(option) => ((option.geoName?.name && `${option.geoName?.name}-${option.vroutePointId}`) || (option.geoZone?.name && `${option.geoZone?.name}-${option.vroutePointId}`) || `Без названия ID:${option.vroutePointId}`)}
                          renderInput={(params) => <TextField {...params} label="До точки маршрута" />}
                        />
                        <ErrorMessage
                          dataVehicleRoutePointsResourceState={dataVehicleRoutePointsResourceState}
                          e={e}
                          dataVehicleRoutePointsAll={dataVehicleRoutePointsAll}
                          selectedRoute={selectedRoute}
                        />
                      </FormControl>
                    ) : null))}
                  </Box>
                </Box>
              )}

              {dataPlatoonGroupParamsResourceState.map((e, i) => (e.name === 'geo_to_include' ? (
                <Box
                  key={`dataPlatoonGroupParamsResourceStateGeo_to_include-${e.name}-${i + 1}`}
                  sx={{ marginTop: "30px", border: ' 2px dashed #1976d2', padding: "20px 5px" }}
                >
                  <Typography sx={{
                    fontWeight: " 500",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "20px"
                  }}
                  >
                    Выход с маршрута
                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        const newData = dataPlatoonGroupParamsResourceState.filter((el) => el.name !== 'geo_to_include');
                        setDataPlatoonGroupParamsResourceState(newData);
                        clearAlertParams();
                      }}
                    />
                  </Typography>
                  <Box>
                    <Switch
                      checked={e.value !== "0"}
                      onClick={() => {
                        const newData = dataPlatoonGroupParamsResourceState.map((el) => {
                          const newObject = JSON.parse(JSON.stringify(el));
                          if (newObject.name === 'geo_to_include') {
                            newObject.value = e.value === "0" ? "1" : "0";
                          }
                          return newObject;
                        });
                        setDataPlatoonGroupParamsResourceState(newData);
                      }}
                    />
                    Ручной выход ТС с маршрута
                  </Box>
                </Box>
              ) : null))}

              <Box className="wrapperAlerts">
                {!paramsIsError && paramsIsSuccess
                    && (
                    <Alert sx={{ width: "100%" }} severity="success" className="wrapperAlert">
                      <AlertTitle className="wrapperTittleAlert">
                        Успешно
                        <Box
                          className="closeIconAlert"
                          onClick={() => setParamsIsSuccess(null)}
                        >
                          <CloseIcon />
                        </Box>
                      </AlertTitle>
                      Все параметры были успешно сохранены
                    </Alert>
                    )}
                {paramsIsError
                    && (
                    <Alert sx={{ width: "100%" }} severity="error" className="wrapperAlert">
                      <AlertTitle className="wrapperTittleAlert">
                        Ошибка
                        <Box
                          className="closeIconAlert"
                          onClick={() => setParamsIsError(null)}
                        >
                          <CloseIcon />
                        </Box>
                      </AlertTitle>
                      При сохранении произошла ошибка
                    </Alert>
                    )}
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "max-content",
                marginLeft: "auto",
                position: 'relative',
                marginTop: "30px"
              }}
              >
                <Button
                  sx={{ position: "relative" }}
                  onClick={(event) => {
                    setIsWindowPlatoonGroupParams(event.currentTarget);
                  }}
                  variant="contained"
                >
                  ДОБАВИТЬ
                </Button>
                <Menu
                  anchorEl={isWindowPlatoonGroupParams}
                  open={Boolean(isWindowPlatoonGroupParams)}
                  onClose={() => {
                    setIsWindowPlatoonGroupParams(null);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  {[{ id: 0, value: 'Резервный маршрут', key: "route_id" }, { id: 1, value: 'Выход с маршрута', key: "geo_to_include" }].map((e) => (
                    <MenuItem
                      key={`List-PlatoonGroupParams${e.id}`}
                      disabled={
                            !!dataPlatoonGroupParamsResourceState
                              .find((item) => item.name === e.key)
                          }
                      onClick={() => {
                        // eslint-disable-next-line no-undef
                        if (e.id === 1 && dataPlatoonGroupParamsResourceState?.filter((item) => item.name === 'geo_to_include').length === 0) {
                          const newData = JSON.parse(JSON.stringify(
                            dataPlatoonGroupParamsResourceState
                          ));
                          newData.push(
                            {
                              isNew: true,
                              section: "backup_route",
                              name: "geo_to_include",
                              value: "0",
                              type: "TYPE_INTEGER",
                              group: {
                                platoonGroupId,
                                name: null,
                                status: null,
                                companyId: null,
                                routeId: null,
                                startDateFrom: null,
                                startDateTo: null,
                                intervalDuration: null,
                                requiredTokens: null,
                                adviseTokens: null,
                                createdDate: null,
                                modifiedDate: null
                              }
                            }
                          );
                          setDataPlatoonGroupParamsResourceState(newData);
                        }
                        if (e.id === 0 && dataPlatoonGroupParamsResourceState?.filter((item) => item.name === 'route_id').length === 0) {
                          const newData = JSON.parse(JSON.stringify(
                            dataPlatoonGroupParamsResourceState
                          ));
                          newData.push({
                            isNew: true,
                            section: "backup_route",
                            name: "route_id",
                            value: "",
                            type: "TYPE_INTEGER",
                            group: {
                              platoonGroupId,
                              name: null,
                              status: null,
                              companyId: null,
                              routeId: null,
                              startDateFrom: null,
                              startDateTo: null,
                              intervalDuration: null,
                              requiredTokens: null,
                              adviseTokens: null,
                              createdDate: null,
                              modifiedDate: null
                            }
                          });
                          newData.push({
                            isNew: true,
                            section: "backup_route",
                            name: "min_front_vehicle",
                            value: "",
                            type: "TYPE_INTEGER",
                            group: {
                              platoonGroupId,
                              name: null,
                              status: null,
                              companyId: null,
                              routeId: null,
                              startDateFrom: null,
                              startDateTo: null,
                              intervalDuration: null,
                              requiredTokens: null,
                              adviseTokens: null,
                              createdDate: null,
                              modifiedDate: null
                            }
                          });
                          newData.push({
                            isNew: true,
                            section: "backup_route",
                            name: "min_exit_vehicle",
                            value: "",
                            type: "TYPE_INTEGER",
                            group: {
                              platoonGroupId,
                              name: null,
                              status: null,
                              companyId: null,
                              routeId: null,
                              startDateFrom: null,
                              startDateTo: null,
                              intervalDuration: null,
                              requiredTokens: null,
                              adviseTokens: null,
                              createdDate: null,
                              modifiedDate: null
                            }
                          });
                          newData.push({
                            isNew: true,
                            section: "backup_route",
                            name: "geo_to",
                            value: "",
                            type: "TYPE_INTEGER",
                            group: {
                              platoonGroupId,
                              name: null,
                              status: null,
                              companyId: null,
                              routeId: null,
                              startDateFrom: null,
                              startDateTo: null,
                              intervalDuration: null,
                              requiredTokens: null,
                              adviseTokens: null,
                              createdDate: null,
                              modifiedDate: null
                            }
                          });
                          newData.push({
                            isNew: true,
                            section: "backup_route",
                            name: "geo_from",
                            value: "",
                            type: "TYPE_INTEGER",
                            group: {
                              platoonGroupId,
                              name: null,
                              status: null,
                              companyId: null,
                              routeId: null,
                              startDateFrom: null,
                              startDateTo: null,
                              intervalDuration: null,
                              requiredTokens: null,
                              adviseTokens: null,
                              createdDate: null,
                              modifiedDate: null
                            }
                          });
                          setDataPlatoonGroupParamsResourceState(newData);
                        }
                        setIsWindowPlatoonGroupParams(false);
                      }}
                      value={e.value}
                    >
                      {e.value}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  disabled={!!isCorrectChangeRoute || isHasChangesParams}
                  onClick={handleChangePlatoonGroupParamsResource}
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={() => {
                    setDataPlatoonGroupParamsResourceState(
                      data?.dataPlatoonGroupParamsResource.data
                    );
                  }}
                  variant="contained"
                  disabled={isHasChangesParams}
                >
                  Отменить
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!platoonGroupId} sx={{ p: 1, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>История</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  {/* <EnhancedTableToolbar /> */}
                  <ControlledTable
                    rows={(dataPlatoonGroupActivitiesResource.map((e) => {
                      const newObject = JSON.parse(JSON.stringify(e));
                      const newVehicleId = dataVehiclesResourceState
                        .find((el) => el.vehicleId === e.vehicleId);
                      newObject.vehicleId = newVehicleId ? newVehicleId.name : '';
                      return newObject;
                    }))}
                    headCells={headCellsPlatoonGroupActivitiesResource}
                    displayedCells={displayedCellsPlatoonGroupActivitiesResource}
                    countRowsPerPage={5}
                    selected={[]}
                    setSelected={() => { }}
                  />
                  {/* <TablePagination /> */}
                </Paper>
              </Box>
            </AccordionDetails>

          </Accordion>
        </Box>
      </Box>
      <Box sx={{ width: "max-content", marginLeft: "auto" }}>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={toPlatoons}
        >
          Назад
        </Button>
      </Box>
    </Box>
  );
}

ErrorMessage.propTypes = {
  dataVehicleRoutePointsResourceState: PropTypes.arrayOf(
    PropTypes.shape(vehicleRoutePointsResourceType)
  ).isRequired,
  e: PropTypes.shape({
    value: PropTypes.string
  }).isRequired,
  dataVehicleRoutePointsAll: PropTypes.arrayOf(
    PropTypes.shape(vehicleRoutePointsResourceType)
  ).isRequired,
  selectedRoute: PropTypes.func.isRequired
};

PlatoonGroupsPanel.propTypes = {
  data: PropTypes.shape({
    dataPlatoonGroups: PropTypes.shape({
      data: PropTypes.shape(platoonGroupsResourceType)
    }),
    dataPlatoonRequestsResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(platoonRequestsResourceInitialStateType)
      )
    }),
    dataPlatoonGroupVehiclesResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(platoonGroupVehiclesResourceType)
      )
    }),
    dataPlatoonGroupActivitiesResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(platoonGroupsResourceType)
      )
    }),
    dataPlatoonGroupParamsResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(platoonGroupParamsResourceType)
      )
    }),
    dataVehiclesResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(vehicleType)
      )
    }),
    dataVehiclesRouteResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(vehicleRoutesType)
      )
    }),
    dataVehicleRoutePointsResource: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape(vehicleRoutePointsResourceType)
      )
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  vehiclesRoutes: PropTypes.object,
  platoonGroupId: PropTypes.number,
  patchPlatoonGroupsResourceParams: PropTypes.func,
  postPlatoonGroupsResourceParams: PropTypes.func,
  patchPlatoonRequestsResourceParams: PropTypes.func,
  patchPlatoonGroupVehiclesResourceParams: PropTypes.func,
  postPlatoonRequestsResourceParams: PropTypes.func,
  patchPlatoonGroupParamsResourceParams: PropTypes.func,
  deletePlatoonGroupParamsResourceParams: PropTypes.func,
  postPlatoonGroupParamsResourceParams: PropTypes.func,
  getVehiclesRoutes: PropTypes.func,
  companyId: PropTypes.number,
  toPlatoons: PropTypes.func,
  postPlatoonGroupParamsResourceParamsRoute: PropTypes.func,
  restartPolling: PropTypes.func,
};

PlatoonGroupsPanel.defaultProps = {
  data: null,
  platoonGroupId: null,
  patchPlatoonGroupsResourceParams: null,
  postPlatoonGroupsResourceParams: null,
  patchPlatoonRequestsResourceParams: null,
  patchPlatoonGroupVehiclesResourceParams: null,
  postPlatoonRequestsResourceParams: null,
  patchPlatoonGroupParamsResourceParams: null,
  postPlatoonGroupParamsResourceParams: null,
  deletePlatoonGroupParamsResourceParams: null,
  companyId: null,
  toPlatoons: null,
  getVehiclesRoutes: null,
  vehiclesRoutes: null,
  postPlatoonGroupParamsResourceParamsRoute: null,
  restartPolling: null,
};

export default PlatoonGroupsPanel;
