import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.vehicleFuelEfficiency/api`
export const vehicleRouteEnum = {
  vehicleRouteId: "vehicleRouteId",
  name: "name",
  status: "status",
  isAutostarted: "isAutostarted",
  isAutoShifted: "isAutoShifted",
  startTime: "startTime",
  startTimeUntil: "startTimeUntil",
  finishTime: "finishTime",
  finishTimeUntil: "finishTimeUntil",
  isRoundTrip: "isRoundTrip",
  company: "company",
  vehicle: "vehicle",
  routePoints: "routePoints"
};
// Инициализация стейта в сторе
const vehicleRoutesInitialState = {
  [vehicleRouteEnum.vehicleRouteId]: NaN,
  [vehicleRouteEnum.name]: null,
  [vehicleRouteEnum.status]: null,
  [vehicleRouteEnum.isAutostarted]: null,
  [vehicleRouteEnum.isAutoShifted]: null,
  [vehicleRouteEnum.startTime]: null,
  [vehicleRouteEnum.startTimeUntil]: null,
  [vehicleRouteEnum.finishTime]: null,
  [vehicleRouteEnum.finishTimeUntil]: null,
  [vehicleRouteEnum.isRoundTrip]: null,
  [vehicleRouteEnum.company]: {},
  [vehicleRouteEnum.vehicle]: {},
  [vehicleRouteEnum.routePoints]: {}
};
// Типизация стора
export const vehicleRoutesType = {
  [vehicleRouteEnum.vehicleRouteId]: PropTypes.number,
  [vehicleRouteEnum.name]: PropTypes.string,
  [vehicleRouteEnum.status]: PropTypes.string,
  [vehicleRouteEnum.isAutostarted]: PropTypes.bool,
  [vehicleRouteEnum.isAutoShifted]: PropTypes.bool,
  [vehicleRouteEnum.startTime]: PropTypes.string,
  [vehicleRouteEnum.startTimeUntil]: PropTypes.string,
  [vehicleRouteEnum.finishTime]: PropTypes.string,
  [vehicleRouteEnum.finishTimeUntil]: PropTypes.string,
  [vehicleRouteEnum.isRoundTrip]: PropTypes.bool,
  [vehicleRouteEnum.company]: PropTypes.object,
  [vehicleRouteEnum.vehicle]: PropTypes.object,
  [vehicleRouteEnum.routePoints]: PropTypes.arrayOf(PropTypes.object)
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const vehiclesRoutesSlice = createSlice({
  name: `${reducerPath.vehicleRoutes}/counter`,
  initialState: vehicleRoutesInitialState,
  reducers: {}
});

export const vehicleRoutesReducer = vehiclesRoutesSlice.reducer;
