/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";
import { reportsResourceApi } from "./reportsResource.api";

// Инициализация стейта в сторе
const initialState = {
  groups: [],
  reports: [],
  report: null,
  isLoadingList: true,
  isLast: false,
};

// Создание слайса тут пишем инициализированный стейт и редюсеры
export const reportsResourceSlice = createSlice({
  name: `${reducerPath.reportsResource}`,
  initialState,
  reducers: {
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setIsLoadingList(state, action) {
      state.isLoadingList = action.payload;
    },
    setIsLast(state, action) {
      state.isLast = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(reportsResourceApi.endpoints.getReportsGroups.matchFulfilled, (state, action) => {
        const newGroups = action.payload
          ?.map((group) => ({ ...group, id: group.reportGroupId }));
        state.groups = [...state.groups, ...newGroups];
      })
      .addMatcher(reportsResourceApi.endpoints.getReports.matchFulfilled, (state, action) => {
        const newItems = action.payload
          ?.map((item) => ({ ...item, id: item.reportId }));
        state.reports = [...state.reports, ...newItems];
      })
      .addMatcher(reportsResourceApi.endpoints.getReportsGroups.matchPending
        || reportsResourceApi.endpoints.getReports.matchPending, (state) => {
        state.isLoadingList = true;
      })
      .addMatcher(reportsResourceApi.endpoints.getReportsGroups.matchFulfilled
        && reportsResourceApi.endpoints.getReports.matchFulfilled, (state) => {
        state.isLoadingList = false;
      })
      .addMatcher(reportsResourceApi.endpoints.getReport.matchFulfilled, (state, action) => {
        state.report = action.payload;
      });
  }
});

export const reportsResourceActions = reportsResourceSlice.actions;
export const reportsResourceReducer = reportsResourceSlice.reducer;
