// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import platoonGroupParamsResourceFromDtoService from "../services/data/platoonGroupParamsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const platoonGroupParamsResourceApi = createApi({
  reducerPath: `${reducerPath.platoonGroupParamsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.platoonGroupsResourceUrl}/platoon-group-params`),
  endpoints: (builder) => ({
    getPlatoonGroupParamsResource: builder.query({
      query: ({
        params,
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
      transformResponse: platoonGroupParamsResourceFromDtoService
    }),
    patchPlatoonGroupParamsResource: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PATCH
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    postPlatoonGroupParamsResourceRoute: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/batch/${id}`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    deletePlatoonGroupParamsResource: builder.mutation({
      query: ({
        id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.DELETE
        }),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
    postPlatoonGroupParamsResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    })
  }),
});

export const {
  useLazyGetPlatoonGroupParamsResourceQuery,
  useGetPlatoonGroupParamsResourceQuery,
  usePatchPlatoonGroupParamsResourceMutation,
  usePostPlatoonGroupParamsResourceMutation,
  useDeletePlatoonGroupParamsResourceMutation,
  usePostPlatoonGroupParamsResourceRouteMutation

} = platoonGroupParamsResourceApi;
