// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import platoonRequestsResourceFromDtoService from "../services/data/platoonRequestsResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line import/no-named-as-default
import platoonRequestsResourceToDtoService from "../services/data/platoonRequestsResource-to-dto.service";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const platoonRequestsResourceApi = createApi({
  reducerPath: `${reducerPath.platoonRequestsResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.platoonGroupsResourceUrl}/platoon-requests`),
  endpoints: (builder) => ({
    getPlatoonRequestsResource: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        params,
        validateStatus: checkStatus,
      }),
      providesTags: ['test'],
      transformResponse: platoonRequestsResourceFromDtoService
    }),
    patchPlatoonRequestsResource: builder.mutation({
      query: ({
        body, id
      }) => ({
        ...createHeadRequest({
          url: `/${id}`, method: HttpMethods.PATCH
        }),
        body: platoonRequestsResourceToDtoService(body),
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['test'],
    }),
    postPlatoonRequestsResource: builder.mutation({
      query: ({
        body
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      invalidatesTags: ['TAG'],
    }),
  }),
});

export const {
  useGetPlatoonRequestsResourceQuery,
  useLazyGetPlatoonRequestsResourceQuery,
  usePatchPlatoonRequestsResourceMutation,
  usePostPlatoonRequestsResourceMutation,
} = platoonRequestsResourceApi;
