function addTimeIntervalISO8601(time1, time2) {
  if (time1 && !time2) {
    return time1;
  }
  if (!time1 && time2) {
    return time2;
  }

  const regex = /PT(-?\d+H)?(-?\d+M)?(-?\d+(?:\.\d+)?S)?/;
  const [, hours1 = '0H', minutes1 = '0M', seconds1 = '0S'] = regex.exec(time1);
  const [, hours2 = '0H', minutes2 = '0M', seconds2 = '0S'] = regex.exec(time2);

  const totalHours = parseInt(hours1, 10) + parseInt(hours2, 10);
  const totalMinutes = parseInt(minutes1, 10) + parseInt(minutes2, 10);
  const totalSeconds = parseFloat(seconds1.replace('S', '')) + parseFloat(seconds2.replace('S', ''));

  let formattedResult = 'PT';

  if (totalHours !== 0) {
    formattedResult += `${totalHours}H`;
  }

  if (totalMinutes !== 0) {
    formattedResult += `${totalMinutes}M`;
  }

  if (totalSeconds !== 0) {
    formattedResult += `${totalSeconds.toFixed(4)}S`;
  }

  return formattedResult;
}

export default addTimeIntervalISO8601;
