function createDescription(tariffPlan) {
  const descriptionFields = {
    funcRouteMonitor: 'funcRouteMonitor',
    funcGroupMvmnt: 'funcGroupMvmnt',
    publicRouteCount: 'publicRouteCount',
    privateRouteCount: 'privateRouteCount',
    vehicleCount: 'vehicleCount',
    vehicleActiveCount: 'vehicleActiveCount',
    payPerDay: 'payPerDay',
    payPerDayVehicle: 'payPerDayVehicle',
    payPerDayActiveVehicle: 'payPerDayActiveVehicle',
    payPerMonth: 'payPerMonth',
    payPerMonthVehicle: 'payPerMonthVehicle',
    historyDays: 'historyDays',
    funcFuelCoef: 'funcFuelCoef',
    cargoPerMonth: 'cargoPerMonth',
    cargoPerDay: 'cargoPerDay',
  };
  const descriptionFieldsRu = {
    [descriptionFields.funcRouteMonitor]: 'мониторинг движения',
    [descriptionFields.funcGroupMvmnt]: 'движение в группах',
    [descriptionFields.publicRouteCount]: 'публичных маршрутов',
    [descriptionFields.privateRouteCount]: 'приватных маршрутов',
    [descriptionFields.vehicleCount]: 'ТС',
    [descriptionFields.vehicleActiveCount]: 'активных ТС',
    [descriptionFields.payPerDay]: 'руб в день',
    [descriptionFields.payPerDayVehicle]: 'руб в день за 1 ТС',
    [descriptionFields.payPerDayActiveVehicle]: 'руб в день за активное ТС',
    [descriptionFields.payPerMonth]: 'руб в мес',
    [descriptionFields.payPerMonthVehicle]: 'руб в мес за 1 ТС',
    [descriptionFields.historyDays]: 'дн - история движения',
    [descriptionFields.funcFuelCoef]: 'топливная эффективность',
    [descriptionFields.cargoPerMonth]: 'руб в месяц за геопозиционирование',
    [descriptionFields.cargoPerDay]: 'руб день за геопозиционирование',
  };
  let resultDescription = '';
  const descriptionArr = [];
  Object.keys(descriptionFields).forEach((key) => {
    if (typeof tariffPlan[key] === 'boolean') {
      if (tariffPlan[key]) {
        descriptionArr.push(descriptionFieldsRu[key]);
      }
    } else {
      const descriptionValue = tariffPlan[key];
      if (descriptionValue) {
        descriptionArr.push(`${descriptionValue}\u00A0${descriptionFieldsRu[key]}`);
      }
    }
  });
  resultDescription = descriptionArr.join(', ');
  return resultDescription.charAt(0).toUpperCase() + resultDescription.slice(1);
}

export default createDescription;
