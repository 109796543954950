import React from 'react';
import { useParams } from 'react-router-dom';
import LogisticTaskBll from '../../../entities/logistic/logisticTasks/LogisticTaskBll/LogisticTaskBll';
import PowerBalancingMainWidget from '../../../widgets/logistics/PowerBalancingMainWidget/PowerBalancingMainWidget';

function PowerBalancingMainPanel() {
  const params = useParams();
  const logisticTaskId = parseInt(params.logisticTaskId, 10);

  return (
    <LogisticTaskBll
      logisticTaskId={logisticTaskId}
    >
      <PowerBalancingMainWidget />
    </LogisticTaskBll>
  );
}

export default PowerBalancingMainPanel;
