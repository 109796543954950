function convertSecondsToTimeString(totalSeconds, showSign = true) {
  const sign = showSign ? totalSeconds < 0 ? '-' : '+' : '';
  const totalSecondsAbs = Math.abs(totalSeconds);

  const hours = Math.floor(totalSecondsAbs / 3600);
  const minutes = Math.floor((totalSecondsAbs % 3600) / 60);
  const seconds = totalSecondsAbs % 60;

  const result = [];
  if (hours !== 0) {
    result.push(`${hours}ч`);
  }
  if (minutes !== 0) {
    result.push(`${minutes}м`);
  }
  if (seconds !== 0 || !result.length) {
    result.push(`${seconds}с`);
  }
  return `${sign}${result.join(' ')}`;
}

export default convertSecondsToTimeString;
