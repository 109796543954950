// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line boundaries/element-types
import config from "../../../../shared/api/config";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";

// Описываем методы обращения к беку
export const vkResourceApi = createApi({
  reducerPath: `${reducerPath.vkResource}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}`),
  endpoints: (builder) => ({
    registerByVk: builder.mutation({
      query: (body) => ({
        ...createHeadRequest({
          url: `/app-users/registerByVK`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
    }),
    authorizeByVk: builder.mutation({
      query: (body) => ({
        ...createHeadRequest({
          url: `/authorizeByVK`, method: HttpMethods.POST
        }),
        body,
        validateStatus: checkStatus,
      }),
      providesTags: ['TAG'],
    }),
  }),
});

export const {
  useRegisterByVkMutation,
  useAuthorizeByVkMutation,
} = vkResourceApi;
