import React, {
  useEffect,
  useState
} from 'react';
import {
  Alert, AlertTitle, Box, Button, FormControlLabel, FormLabel, Switch, TextField
} from '@mui/material';
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import style from './UserEditPanel.module.css';
import { appUsersResourceType } from "../../../entities/account/appUsersResource/redux/appUsersResource.slice";

// Отображает панель изменение пользователя
function UserEditPanel({
  data,
  putAppUserResourceParams,
}) {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [editUsers, setEditUsers] = useState(false);
  const [workWithBill, setWrkWithBill] = useState(false);
  const [changeTariff, setChangeTariff] = useState(false);
  const [editCompany, setEditCompany] = useState(false);

  const [isErr, setIsErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    if (data?.dataUserResourceId?.data) {
      const currentData = data?.dataUserResourceId?.data;
      // eslint-disable-next-line no-unused-expressions
      currentData?.firstName !== null && setFirstName(currentData.firstName);
      // eslint-disable-next-line no-unused-expressions
      currentData?.lastName !== null && setLastName(currentData.lastName);
      // eslint-disable-next-line no-unused-expressions
      currentData?.user?.email !== null && setEmail(currentData?.user?.email);
      // eslint-disable-next-line no-unused-expressions
      currentData?.isUserAdmin !== null && setEditUsers(currentData.isUserAdmin);
      // eslint-disable-next-line no-unused-expressions
      currentData?.isBillAdmin !== null && setWrkWithBill(currentData.isBillAdmin);
      // eslint-disable-next-line no-unused-expressions
      currentData?.isPlanAdmin !== null && setChangeTariff(currentData.isPlanAdmin);
      // eslint-disable-next-line no-unused-expressions
      currentData?.phone !== null && setPhone(currentData.phone);
      // eslint-disable-next-line no-unused-expressions
      currentData?.isCompanyAdmin !== null && setEditCompany(currentData.isCompanyAdmin);
      // eslint-disable-next-line no-unused-expressions
    }
  }, [data]);

  const handleSaveChanges = () => {
    const newData = JSON.parse(JSON.stringify(data?.dataUserResourceId?.data));
    newData.firstName = firstName;
    newData.lastName = lastName;
    newData.user.email = email;
    newData.isUserAdmin = editUsers;
    newData.isBillAdmin = workWithBill;
    newData.isPlanAdmin = changeTariff;
    newData.isCompanyAdmin = editCompany;
    newData.phone = phone;
    putAppUserResourceParams({
      id: newData.appUserId,
      body: newData
    }).then((e) => {
      if (e.error) {
        setIsErr(true);
      } else {
        setIsCorrect(true);
        setTimeout(() => {
          navigate(`/users/info`);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setIsErr(false);
  }, [firstName, lastName, middleName]);

  return (
    <Box className={style.UsersInfoPanel}>
      <TextField
        value={firstName || ''}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Имя"
        variant="standard"
      />
      <TextField
        value={lastName || ''}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Фамилия"
        variant="standard"
      />
      <TextField
        disabled
        value={middleName || ''}
        onChange={(e) => {
          setMiddleName(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Отчество"
        variant="standard"
      />
      <TextField
        value={email || ''}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Почта"
        variant="standard"
      />
      <TextField
        value={phone || ''}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Телефон"
        variant="standard"
      />
      <Box>
        <FormLabel component="legend">Создание и редактирование компаний</FormLabel>
        <FormControlLabel control={<Switch checked={editCompany} onClick={() => { setEditCompany(!editCompany); }} />} label="" />
      </Box>
      <Box>
        <FormLabel component="legend">Создание и редактирование пользователей</FormLabel>
        <FormControlLabel control={<Switch checked={editUsers} onClick={() => { setEditUsers(!editUsers); }} />} label="" />
      </Box>
      <Box>
        <FormLabel component="legend">Создание и отправка счетов</FormLabel>
        <FormControlLabel control={<Switch checked={workWithBill} onClick={() => { setWrkWithBill(!workWithBill); }} />} label="" />
      </Box>
      <Box>
        <FormLabel component="legend">Смена тарифного плана</FormLabel>
        <FormControlLabel control={<Switch checked={changeTariff} onClick={() => { setChangeTariff(!changeTariff); }} />} label="" />
      </Box>

      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Изменения успешно сохранены
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}

      <Box className={style.buttonsPanel}>
        <Button onClick={handleSaveChanges} variant="contained">Сохранить</Button>
        <Link to="/users/">
          <Button variant="contained">
            {!isErr && isCorrect ? 'Назад' : 'Отмена'}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

UserEditPanel.propTypes = {
  data: PropTypes.shape({
    dataUserResourceId: PropTypes.shape({
      data: PropTypes.shape(appUsersResourceType)
    }),
  }),
  putAppUserResourceParams: PropTypes.func,
};

UserEditPanel.defaultProps = {
  data: null,
  putAppUserResourceParams: null
};

export default UserEditPanel;
