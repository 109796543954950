import React from 'react';
import PropTypes from "prop-types";
import GasPump from '../../../../../../assets/icons/GasPump';

const constants = {
  positionSvgY: 18,
  positionSvgX: 8,
  svgOptions: {
    width: 20,
    height: 20,
    viewBox: "0 0 23 29",
  },
  fill: {
    blue: "#1976D2",
    red: "#B00020"
  },
  xmlns: "http://www.w3.org/2000/svg"
};
// Отображение на графике запраки
function CustomizedDot({ cx, payload, height }) {
  if (payload?.fuelDiff > 0) {
    return (
      <GasPump
        sx={{ color: constants.fill.blue }}
        x={cx - constants.positionSvgX}
        y={height - constants.positionSvgY}
        width={constants.svgOptions.width}
        height={constants.svgOptions.height}
        viewBox={constants.svgOptions.viewBox}
        xmlns={constants.xmlns}
      />
    );
  } if (payload?.fuelDiff < 0 || payload?.fuelDiffMinus < 0) {
    return (
      <GasPump
        sx={{ color: constants.fill.red }}
        x={cx - constants.positionSvgX}
        y={height - constants.positionSvgY}
        width={constants.svgOptions.width}
        height={constants.svgOptions.height}
        viewBox={constants.svgOptions.viewBox}
        xmlns={constants.xmlns}
      />
    );
  }
}

CustomizedDot.propTypes = {
  cx: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.shape({
    fuelDiff: PropTypes.number,
    fuelDiffMinus: PropTypes.number,
  })
};

CustomizedDot.defaultProps = {
  cx: 0,
  height: 318,
  payload: undefined
};

export default CustomizedDot;
