/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import reducerPath from "../../../../app/reducerPath";

export const mapNames = {
  logisticDelivery: 'logisticDelivery',
  default: 'default',
};

const initialState = {
  [mapNames.logisticDelivery]: false,
  [mapNames.default]: false,
};

export const fullScreenMapSlice = createSlice({
  name: `${reducerPath.fullScreenMap}`,
  initialState,
  reducers: {
    setFull(state, action) {
      state[action.payload.name] = action.payload.value;
    },
  }
});

export const fullScreenMapSliceActions = fullScreenMapSlice.actions;
export const fullScreenMapSliceReducer = fullScreenMapSlice.reducer;
