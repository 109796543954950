import {
  Box, Container, IconButton
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Tree from "rc-tree";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
// import SettingsIcon from '@mui/icons-material/Settings';
import {
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import TabsPanels from "../../../shared/ui/TabsPanels/TabsPanels";
import IconTree from "../../../shared/ui/IconTree/IconTree";
import AccountMainPanel from "../../../panels/account/AccountMainPanel/AccountMainPanel";
import AlertsPanel from "../../../panels/account/AlertsPanel/AlertsPanel";
import AppUserSettingsResourceBll
  from "../../../entities/account/appUserSettingsResource/AppUserSettingsResourceBll/AppUserSettingsResourceBll";
import ChangePasswordPanel from "../../../panels/account/ChangePasswordPanel/ChangePasswordPanel";
import AccountResourceBll from "../../../entities/account/accountResource/AccountResourceBll/AccountResourceBll";
import AccountMainBll from "../../../entities/account/AccountMain/AccountMain";
import useAuth from "../../../shared/lib/hooks/useAuth";
import useDebounce from '../../../shared/hooks/useDebounce';
import {
  COMPACT_MENU_WIDTH,
  SIDEBAR_RESIZE_BREAKPOINT,
  TOOLBAR_HEIGHT_LG
} from '../../../shared/constants/layoutSettings';

const minTreeWidth = 284;

const switchKeyRoute = (e) => {
  switch (e) {
    case '/account/info':
      return '0';
    case '/account/alerts':
      return '1';
    // case '/account/settings':
    //   return '2';
    default:
      return '0';
  }
};

function AccountView() {
  const { user: userCurrent } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [compactMode, setCompactMode] = useState(false);

  const tabsAccount = useMemo(() => ([{
    id: 1,
    name: 'Информация',
    component: (
      <AccountMainBll idUser={userCurrent.appUserId} login={userCurrent.login}>
        <AccountMainPanel />
      </AccountMainBll>
    )
  },
  {
    id: 2,
    name: 'Изменить пароль',
    component: (
      <AccountResourceBll>
        <ChangePasswordPanel />
      </AccountResourceBll>
    )
  }]), []);
  const tabsAlerts = useMemo(() => ([{
    id: 2,
    name: 'Telegram',
    component: (
      <AppUserSettingsResourceBll idUser={userCurrent.appUserId}>
        <AlertsPanel idUser={userCurrent.appUserId} />
      </AppUserSettingsResourceBll>
    )
  }]), []);
  // Пока не нужен
  // const tabsSettings = useMemo(() => ([{
  //   id: 3,
  //   name: 'настройки',
  //   component: (
  //     <div>настройки</div>
  //   )
  // }]), []);

  useEffect(() => {
    setSelectedKeys(switchKeyRoute(location.pathname));
  });

  const [sidebarHeight, setSidebarHeight] = useState(`calc(100vh - ${TOOLBAR_HEIGHT_LG}px)`);
  const [headerVisibleHeight, setHeaderVisibleHeight] = useState(0);

  const processResize = (ignoreCompactMode = false) => {
    const toolbar = document.querySelector('header.MuiAppBar-root');
    const { clientHeight } = document.documentElement;
    const visiblePartOfHeader = (toolbar?.offsetHeight || 64) - window.scrollY;
    const delta = visiblePartOfHeader > 0 ? visiblePartOfHeader : 0;

    setHeaderVisibleHeight(delta);
    if (!ignoreCompactMode) {
      setCompactMode(document.body.offsetWidth < SIDEBAR_RESIZE_BREAKPOINT);
    }
    setSidebarHeight(clientHeight - delta);
  };

  const debouncedResize = useDebounce(() => {
    processResize();
  }, 10);

  const debouncedScroll = useDebounce(() => {
    processResize(true);
  }, 10);

  useEffect(() => {
    if (setCompactMode) {
      debouncedResize();
      window.addEventListener('resize', debouncedResize);
      window.addEventListener('scroll', debouncedScroll);
    }
    return () => {
      window.removeEventListener('resize', debouncedResize);
      window.removeEventListener('scroll', debouncedScroll);
    };
  }, [setCompactMode]);

  return (
    <Container
      component="main"
      maxWidth="xl"
    >
      <Box sx={{ display: 'flex', width: '100%', gap: '20px' }}>
        <Box sx={{
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingRight: '10px',
          paddingLeft: { xs: '24px', lg: 0 },
          width: compactMode ? COMPACT_MENU_WIDTH : minTreeWidth,
          minWidth: compactMode ? COMPACT_MENU_WIDTH : minTreeWidth,
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: 300,
          height: sidebarHeight,
          position: { xs: 'fixed', lg: 'sticky' },
          top: { xs: `${headerVisibleHeight}px`, lg: 0 },
          left: 0,
          background: 'white',
          borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
          transition: 'width 0.2s ease-in, min-width 0.2s ease-in',
          boxShadow: compactMode ? 'none' : { xs: '16px 0px 16px 0 rgba(0, 0, 0, 0.05)', lg: 'none' },
        }}
        >
          { compactMode && (
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setCompactMode(false);
              }}
              sx={{ marginLeft: '-8px' }}
            >
              <AccountTreeOutlinedIcon fontSize="small" sx={{ display: { xs: 'block', lg: 'none' } }} />
            </IconButton>
          )}
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setCompactMode(true);
            }}
            sx={{
              display: compactMode ? 'none' : { xs: 'block', lg: 'none' },
              position: 'absolute',
              left: `${minTreeWidth - 50}px`,
            }}
          >
            <KeyboardTabIcon
              fontSize="small"
              sx={{ transform: 'scale(-1, 1)' }}
            />
          </IconButton>
          <Box sx={{ visibility: compactMode ? 'hidden' : 'visible', marginTop: '20px' }}>
            <Tree
              showLine
              onSelect={(e, k) => { navigate(k.node.id); }}
              selectedKeys={selectedKeys}
              treeData={[{
                id: 'info', key: '0', title: "Моя учётная запись", nodeType: "ACCOUNT", icon: <IconTree icon={AccountCircleIcon} />
              },
              {
                id: 'alerts', key: '1', title: "Оповещения", nodeType: "Alerts", icon: <IconTree icon={SmartToyIcon} />
              },
              /*
              {
                id: 'settings',
                key: '2',
                title: "Настройки",
                nodeType: "SETTINGS",
                icon: <IconTree icon={SettingsIcon} />
              }
              */
              ]}
            />
          </Box>
        </Box>
        <Box sx={{ paddingLeft: compactMode ? '60px' : { xs: '60px', lg: '20px' } }}>
          <Routes>
            <Route>
              <Route path="info" element={<TabsPanels tabs={tabsAccount} />} />
              <Route path="alerts" element={<TabsPanels tabs={tabsAlerts} />} />
              {/* <Route path="settings" element={<TabsPanels tabs={tabsSettings} />} /> */}
            </Route>
          </Routes>
        </Box>

      </Box>
    </Container>
  );
}

export default AccountView;
