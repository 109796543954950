// eslint-disable-next-line boundaries/no-unknown-files

// тут описываем тип то что приходит с бека в сыром виде
import PropTypes from "prop-types";
import { trackPointsResourceEnum } from "../redux/trackPointsResource.slice";

const trackPointsResourceDtoType = {
  [trackPointsResourceEnum.trackPointId]: PropTypes.number,
  [trackPointsResourceEnum.vehicleId]: PropTypes.number,
  [trackPointsResourceEnum.latitude]: PropTypes.number,
  [trackPointsResourceEnum.longitude]: PropTypes.number,
  [trackPointsResourceEnum.altitude]: PropTypes.number,
  [trackPointsResourceEnum.yawRate]: PropTypes.number,
  [trackPointsResourceEnum.curvature]: PropTypes.number,
  [trackPointsResourceEnum.date]: PropTypes.string,
};

export default trackPointsResourceDtoType;
