import {
  Box, Button, IconButton, Slider, TextField, Typography
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded
} from "@mui/icons-material";
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useConfirm } from "material-ui-confirm";
import * as Extent from "ol/extent";
import MarkerVectorLayer from '../../../shared/ui/GeoMap/MarkerVectorLayer';
import RouteVectorLayer from '../../../shared/ui/GeoMap/RouteVectorLayer';
import GeoMapWithTiles from '../../../features/geo-map/GeoMapWithTiles';
import style from './VehicleMapPanel.module.css';
import {
  platoonGroupsResourceType
} from "../../../entities/vehicles/trackPointsResource/redux/trackPointsResource.slice";
import ActionBarDateTimePicker from "../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker";
import convertFormatDate from "../../../shared/utils/convertFormatDate";
import calculateDistance from "../../../shared/utils/calculateDistance";
import calculateSpeed from "../../../shared/utils/calculateSpeed";
import convertDataToGMTZero from "../../../shared/utils/convertDataToGMTZero";
import splitRoutes from "../../../shared/utils/splitRoutes";
import config from "../../../shared/api/config";
import { followModeActions, FollowModeEnum } from "../../../entities/map/followMode/redux/followMode.slice";
import { useAppDispatch } from "../../../app/store";
import PREDEFINED_INTERVALS from '../../../shared/constants/predefinedIntervals';
// Начальный масштаб
const zoom = 15;

export const transformCoords = (lon, lat) => fromLonLat([lon / 10000000, lat / 10000000]);

function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}

// Отображает вкладку "Карта" с картой и маршутом, пройденным ТС
function VehicleMapPanel({
  vehicle, data, getTrackPointsResourceParams, vehicleId
}) {
  const [coordsState, setCoordsState] = useState([]);
  const [intervalOption, setIntervalOption] = useState(PREDEFINED_INTERVALS.TODAY);

  const [runRec, setRunRec] = useState(false);
  const [markers, setMarkers] = useState([]);

  const [isSubsequence, setIsSubsequence] = useState(false);
  const [isShowStops, setIsShowStops] = useState(false);
  const [positionHistory, setPositionHistory] = useState();
  const [speedRec, setSpeedRec] = useState(1);
  const timerIdRef = useRef(null);
  let timerId;

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(followModeActions.changeMode({ [FollowModeEnum.isActive]: true }));
  }, []);
  const [dateStart, setDateStart] = useState();
  const [dateFinish, setDateFinish] = useState();
  const [disableExternalExtent, setDisableExternalExtent] = useState(false);
  const confirm = useConfirm();
  const getFile = useCallback(() => {
    // TODO: переписать token на стор
    // eslint-disable-next-line no-undef
    const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

    const params = {
      access_token: token,
      vehicleId:
          String(vehicleId),
      startTime: String(new Date(dateStart).toISOString()),
      finishTime: String(new Date(dateFinish).toISOString())
    };

    const paramsString = new URLSearchParams(params).toString();

    if (token && String(vehicleId)) {
      // eslint-disable-next-line no-undef
      window.open(`${config.vstorageUrl}/track-points/export?${paramsString}`, '_blank');
    } else {
      confirm({
        title: 'Ошибка',
        confirmationText: 'Отмена',
        cancellationText: null,
        description: `Произошла ошибка при загрузке файла, попробуйте позже.`
      });
    }
  }, [vehicleId, dateFinish, dateStart]);

  const coords = useMemo(() => data?.dataTrackPointsResource?.data, [data]);

  const [extent, setExtent] = useState(coords?.length ? Extent.boundingExtent(coords.map((c) => (
    transformCoords(c.longitude, c.latitude)
  ))) : undefined);

  const handleStart = () => {
    if (!disableExternalExtent) {
      // Выбираем первую точку, чтобы карта перемещалась сразу куда надо
      setCoordsState([coords[0]]);
    }
    setDisableExternalExtent(true);
    setRunRec(true);
  };

  const handleStop = () => {
    clearInterval(timerId);
    setRunRec(false);
  };

  useEffect(() => {
    if (runRec) {
      timerIdRef.current = setInterval(() => {
        if (coords.length !== coordsState.length) {
          setCoordsState((prevTime) => JSON.parse(
            JSON.stringify(coords.slice(0, prevTime.length + 1))
          ));
        } else {
          handleStop();
        }
      }, 1000 / speedRec);
    }

    return () => {
      clearInterval(timerIdRef.current);
    };
  }, [runRec, speedRec, coordsState, coords]);

  useEffect(() => {
    if (positionHistory) {
      setCoordsState(() => JSON.parse(
        JSON.stringify(coords.slice(0, positionHistory))
      ));
    }
  }, [positionHistory, coords]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    switch (intervalOption) {
      case PREDEFINED_INTERVALS.TODAY: {
        const dateStartCurrent = convertDataToGMTZero(new Date());
        dateStartCurrent.setHours(0, 0, 0, 0);

        const dateFinishCurrent = convertDataToGMTZero(new Date());
        dateFinishCurrent.setHours(23, 59, 59, 999);
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });
        break;
      }
      case PREDEFINED_INTERVALS.YESTERDAY: {
        const dateStartCurrent = convertDataToGMTZero(new Date());
        dateStartCurrent.setDate(dateStartCurrent.getDate() - 1);
        dateStartCurrent.setHours(0, 0, 0);

        const dateFinishCurrent = convertDataToGMTZero(new Date());
        dateFinishCurrent.setDate(dateFinishCurrent.getDate() - 1);
        dateFinishCurrent.setHours(23, 59, 59);
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });
        break;
      }
      case PREDEFINED_INTERVALS.DAY: {
        const dateStartCurrent = convertDataToGMTZero(new Date());
        dateStartCurrent.setHours(dateStartCurrent.getHours() - 24);

        const dateFinishCurrent = convertDataToGMTZero(new Date());
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });

        break;
      }
      case PREDEFINED_INTERVALS.WEEK: {
        const dateStartCurrent = convertDataToGMTZero(new Date());
        dateStartCurrent.setHours(dateStartCurrent.getHours() - 24 * 7);

        const dateFinishCurrent = convertDataToGMTZero(new Date());
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });

        break;
      }

      case PREDEFINED_INTERVALS.CURRENT_MONTH: {
        const today = convertDataToGMTZero(new Date());

        const dateStartCurrent = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);

        const dateFinishCurrent = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 0);
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });
        break;
      }

      case PREDEFINED_INTERVALS.LAST_MONTH: {
        const today = convertDataToGMTZero(new Date());

        const dateStartCurrent = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);

        const dateFinishCurrent = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 0);
        // получаем смещение часового пояса в миллисекундах
        const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

        const isoDateStartCurrent = new Date(
          dateStartCurrent - localTimeZoneOffset
        ).toISOString();
        const isoDateFinishCurrent = new Date(
          dateFinishCurrent - localTimeZoneOffset
        ).toISOString();

        getTrackPointsResourceParams({
          params: {
            "date.greaterThanOrEqual": isoDateStartCurrent,
            "date.lessThan": isoDateFinishCurrent,
          }
        });
        break;
      }

      default:
        break;
    }
  }, [intervalOption, vehicleId]);

  const coordinates = useMemo(() => ((runRec || coordsState.length) ? coordsState
    : coords && coords.reverse()), [runRec, coordsState, coords]);

  useEffect(() => {
    if (coords?.length && !disableExternalExtent) {
      const ext = Extent.boundingExtent(coords.map((c) => (
        transformCoords(c.longitude, c.latitude)
      )));
      setExtent(ext);
    }
  }, [coords, disableExternalExtent]);

  return (
    <Box className={style.vehicleMapPanel}>
      {coords?.length ? (
        <Box sx={{ width: "100%" }}>
          <GeoMapWithTiles
            styles={style.geoMap}
            zoom={zoom}
            extent={extent}
          >
            <RouteVectorLayer
              coordinates={
                      coordinates?.length && splitRoutes(coordinates)
                        .map((currentCoordinates) => currentCoordinates.map(
                          (point) => transformCoords(point.longitude, point.latitude)
                        ))
                  }
              vehicleId={coordsState?.[0]?.vehicleId}
              isFollowReverseMode
              isManyRoutesMode
            />
            <MarkerVectorLayer
              text={vehicle.num}
              coordinates={(runRec || coordsState.length) ? coordsState.map((point) => (
                transformCoords(point.longitude, point.latitude)
              ))[coordsState.length - 1]
                : coords && coords.map((point) => (
                  transformCoords(point.longitude, point.latitude)
                )).reverse()[0]}
            />

            {markers.map((e, index) => (
              <MarkerVectorLayer
                key={`marker-${e}-${index + 1}`}
                text={convertFormatDate(data?.dataTrackPointsResource?.data[e]?.date).toFormat}
                coordinates={coordsState.map((point) => (
                  transformCoords(point.longitude, point.latitude)
                ))[e]}
              />
            ))}
          </GeoMapWithTiles>
        </Box>
      ) : <Box className={style.mapBlock}>Нет данных для отображения.</Box> }
      <Box sx={{
        width: "298px", paddingLeft: '20px', flex: 'none', paddingTop: "20px"
      }}
      >
        <Box>
          <FormControl sx={{ marginBottom: "10px" }} fullWidth>
            <InputLabel id="demo-simple-select-label">Интервал</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={intervalOption}
              label="Интервал"
              onChange={(e) => {
                setDisableExternalExtent(false);
                setIntervalOption(e.target.value);
              }}
            >
              <MenuItem value={PREDEFINED_INTERVALS.TODAY}>Сегодня</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.YESTERDAY}>Вчера</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.DAY}>Сутки</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.WEEK}>Неделя</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.CURRENT_MONTH}>Текущий месяц</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.LAST_MONTH}>Прошедший месяц</MenuItem>
              <MenuItem value={PREDEFINED_INTERVALS.CUSTOM}>Выбранный период</MenuItem>
            </Select>
          </FormControl>

          {intervalOption === PREDEFINED_INTERVALS.CUSTOM && (
            <Box sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
              flexWrap: "wrap",
            }}
            >
              <DateTimePicker
                label="C"
                value={dateStart || ''}
                onError={() => {}}
                onChange={(e) => {
                  setDateStart(e);
                }}
                error={false}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    size="small"
                    fullWidth
                    id={1}
                    error={false}
                    {...params}
                  />
                )}
                components={{
                  ActionBar: ActionBarDateTimePicker,
                }}
                validationError={false}
              />
              <DateTimePicker
                label="По"
                value={dateFinish || ''}
                minDateTime=""
                onChange={(e) => { setDateFinish(e); }}
                error={false}
                components={{
                  ActionBar: ActionBarDateTimePicker,
                }}
                validationError={false}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    size="small"
                    fullWidth
                    error={false}
                    id={2}
                    {...params}
                  />
                )}
              />
            </Box>
          )}

          <Box sx={{
            display: "flex", gap: "10px", marginBottom: '10px', flexWrap: "wrap"
          }}
          >
            {intervalOption === PREDEFINED_INTERVALS.CUSTOM && (
              <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between"
              }}
              >
                <Button
                  color={(isValidDate(dateStart) && isValidDate(dateFinish)) ? 'primary' : 'error'}
                  variant="contained"
                  onClick={() => {
                    if (isValidDate(dateStart) && isValidDate(dateFinish)) {
                      getTrackPointsResourceParams({
                        params: {
                          "date.greaterThanOrEqual": new Date(dateStart).toISOString(),
                          "date.lessThan": new Date(dateFinish).toISOString(),
                        }
                      });
                    }
                  }}
                >
                  Показать историю
                </Button>
                <Button
                  variant="contained"
                  disabled={!(isValidDate(dateStart) && isValidDate(dateFinish))}
                  onClick={() => {
                    if (isValidDate(dateStart) && isValidDate(dateFinish)) {
                      getFile();
                    }
                  }}
                >
                  <GetAppIcon />
                </Button>
              </div>
            )}
            <Button
              variant="contained"
              onClick={() => {
                setIntervalOption(1);
                handleStop();
                setCoordsState([]);
              }}
            >
              Текущее положение
            </Button>
          </Box>
          <Box sx={{ marginBottom: '10px' }}>
            <Typography id="non-linear-slider" gutterBottom>
              Пробег:
              {' '}
              {(coordsState?.length ? coordsState.length > 1
                    && calculateDistance(coordsState.map((point) => (
                      [point.latitude / 10000000, point.longitude / 10000000]
                    )))
                : coords?.length > 1 && calculateDistance(coords.map((point) => (
                  [point.latitude / 10000000, point.longitude / 10000000]
                )))) || 0}
              {' '}
              км.
            </Typography>

            <Typography id="non-linear-slider" gutterBottom>
              Расход:
              {' '}
              -
            </Typography>

            <Typography id="non-linear-slider" gutterBottom>
              На 100 км:
              {' '}
              -
            </Typography>

          </Box>

          <Box sx={{ marginBottom: '10px' }}>
            <Button
              disabled
              sx={{
                width: '100%',
                padding: '5px 11px',
                fontSize: '10px'
              }}
              variant={isSubsequence ? "contained" : 'outlined'}
              onClick={() => setIsSubsequence(!isSubsequence)}
            >
              Показывать последовательность
            </Button>
          </Box>

          <Typography
            sx={{
              width: 'max-content',
              margin: 'auto'
            }}
            id="non-linear-slider"
            gutterBottom
          >
            {speedRec}
            x
            {' '}
            {(coordsState?.length ? (!!coords?.length
                  && convertFormatDate(
                    data?.dataTrackPointsResource?.data[coordsState.length - 1]?.date
                  ).toFormat) : (data?.dataTrackPointsResource?.data?.length && convertFormatDate(
              // eslint-disable-next-line no-unsafe-optional-chaining
              data.dataTrackPointsResource
                // eslint-disable-next-line no-unsafe-optional-chaining
                ?.data[data?.dataTrackPointsResource?.data.length - 1]?.date
            ).toFormat)) || ''}
          </Typography>
          <Box sx={{
            width: 'max-content',
            marginLeft: 'auto'
          }}
          >
            <IconButton onClick={() => speedRec !== 1 && setSpeedRec((prev) => prev / 2)} aria-label="previous song">
              <FastRewindRounded fontSize="large" />
            </IconButton>
            <IconButton
              aria-label="play"
            >
              {runRec ? <PauseRounded onClick={handleStop} sx={{ fontSize: '3rem' }} /> : (
                <PlayArrowRounded
                  onClick={handleStart}
                  sx={{ fontSize: '3rem' }}
                />
              )}
            </IconButton>
            <IconButton
              onClick={() => speedRec <= 15 && setSpeedRec((prev) => (prev === 1 ? 2 : prev * 2))}
              aria-label="next song"
            >
              <FastForwardRounded fontSize="large" />
            </IconButton>
            <IconButton onClick={() => {
              setMarkers([...markers, coordsState.map((point) => (
                transformCoords(point.longitude, point.latitude)
              )).length - 1]);
            }}
            >
              <AssistantPhotoIcon fontSize="large" />
            </IconButton>
          </Box>

          <Box>
            <Typography id="non-linear-slider" gutterBottom>
              Скорость:
              {' '}
              {coordsState.length > 1 ? (() => {
                const calculatedSpeed = calculateSpeed(
                  coordsState[coordsState.length - 2]?.latitude,
                  coordsState[coordsState.length - 2]?.longitude,
                  coordsState[coordsState.length - 2]?.date
                    || coordsState[coordsState.length - 2]?.dateTime,
                  coordsState[coordsState.length - 1]?.latitude,
                  coordsState[coordsState.length - 1]?.longitude,
                  coordsState[coordsState.length - 1]?.date
                    || coordsState[coordsState.length - 1]?.dateTime,
                );
                return calculatedSpeed !== Infinity ? calculatedSpeed : '-';
              })() : 0}
              {' '}
              км/ч.
            </Typography>
            <Slider
              value={coordsState.length}
              min={1}
              step={1}
              max={coords?.length || 0}
              getAriaValueText={() => {}}
              valueLabelFormat={coordsState?.length ? (!!coords?.length
                      && convertFormatDate(
                        data?.dataTrackPointsResource?.data[coordsState.length - 1]?.date
                      ).toFormat)
                : (data?.dataTrackPointsResource?.data?.length && convertFormatDate(
                  data?.dataTrackPointsResource
                  // eslint-disable-next-line no-unsafe-optional-chaining
                    ?.data[data?.dataTrackPointsResource?.data.length - 1]?.date
                ).toFormat)}
              onChange={(e) => {
                setPositionHistory(e.target.value);
              }}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>

          <Box>
            <Button
              disabled
              sx={{
                width: '100%',
                padding: '5px 11px',
                fontSize: '10px'
              }}
              variant={isShowStops ? "contained" : 'outlined'}
              onClick={() => setIsShowStops(!isShowStops)}
            >
              Показывать стоянки
            </Button>
          </Box>

        </Box>

      </Box>
    </Box>
  );
}

VehicleMapPanel.propTypes = {
  vehicle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  data: PropTypes.shape({
    dataTrackPointsResource: PropTypes.arrayOf(PropTypes.shape(platoonGroupsResourceType))
  }),
  getTrackPointsResourceParams: PropTypes.func,
  vehicleId: PropTypes.number
};

VehicleMapPanel.defaultProps = {
  vehicle: null,
  data: null,
  getTrackPointsResourceParams: null,
  vehicleId: PropTypes.number
};

export default VehicleMapPanel;
