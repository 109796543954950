import React, { useLayoutEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../shared/lib/hooks/useAuth';
// eslint-disable-next-line boundaries/element-types
import { AUTH_FIELDS, authActions } from "../../entities/auth/tokenResource/redux/tokenResource";
import { useAppDispatch } from "../store";

// eslint-disable-next-line react/prop-types
function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (auth.user) {
      // eslint-disable-next-line no-undef
      const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
      dispatch(authActions.login({ [AUTH_FIELDS.TOKEN_ACCESS]: token }));
    }
  }, [auth]);

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
