import * as React from 'react';
import Box from '@mui/material/Box';
import { IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  COMMON_PADDING,
  WAREHOUSE_HEIGHT,
  WAREHOUSE_ICO_COLOR
} from './constants';
import DetailsDialog from './DetailsDialog';

export default function WarehouseItem(props) {
  const {
    warehouse: {
      name, vehicles = null, vehicles: { unloading, waiting } = {}
    }, bgColor, warehouse, ...other
  } = props;

  const [warehouseItemDetails, setWarehouseItemDetails] = React.useState(null);

  const openWarehouseDetailsDialog = () => {
    setWarehouseItemDetails(warehouse);
  };

  const closeWarehouseDetailsDialog = () => {
    setWarehouseItemDetails(null);
  };

  return (
    <Box
      sx={{
        borderRadius: '4px',
        height: `${WAREHOUSE_HEIGHT}px`,
        display: 'flex',
        background: bgColor,
        gap: '12px',
        alignItems: 'center',
        overflow: 'hidden',
      }}
      {...other}
      pl="12px"
    >
      <Box sx={{
        lineHeight: 1, minWidth: '30%', flexGrow: 1, color: WAREHOUSE_ICO_COLOR, overflow: 'hidden', textOverflow: 'ellipsis'
      }}
      >
        {name}
      </Box>
      <Box sx={{ lineHeight: 1, flexGrow: 1, color: WAREHOUSE_ICO_COLOR }}>
        {
          vehicles && (
          <Stack
            direction="row"
            divider={<span>|</span>}
            spacing={0}
          >
            <Typography title="На разгрузке" sx={{ color: (theme) => (unloading === 0 ? theme.palette.error.main : WAREHOUSE_ICO_COLOR) }} variant="span" px={`${COMMON_PADDING}px`}>{unloading}</Typography>
            <Typography title="В очереди" sx={{ color: (theme) => (waiting > 10 ? theme.palette.error.main : WAREHOUSE_ICO_COLOR) }} variant="span" px={`${COMMON_PADDING}px`}>{waiting}</Typography>
          </Stack>
          )
        }
      </Box>
      <IconButton
        sx={{ margin: '0 3px' }}
        title="Свойства склада"
        id="basic-button"
        aria-controls={warehouseItemDetails ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={warehouseItemDetails ? 'true' : undefined}
        onClick={openWarehouseDetailsDialog}
      >
        <SettingsIcon sx={{ color: WAREHOUSE_ICO_COLOR }} />
      </IconButton>
      <DetailsDialog type="warehouse" item={warehouseItemDetails} closeItemDialog={closeWarehouseDetailsDialog} />
    </Box>
  );
}

WarehouseItem.propTypes = {
  bgColor: PropTypes.string,
  warehouse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vehicles: PropTypes.shape({
      unloading: PropTypes.number,
      waiting: PropTypes.number,
    }),
  }),
};

WarehouseItem.defaultProps = {
  bgColor: '#ebe4a7',
  warehouse: {
    vehicles: {
      unloading: 0,
      waiting: 0,
    },
  }
};
