// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import trackPointsResourceDtoType from "../../types/trackPointsResource-dto.type";

export function trackPointsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const data = dto.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });
  return { data, status };
}

trackPointsResourceFromDtoService.propTypes = {
  dto: trackPointsResourceDtoType,
};

export default trackPointsResourceFromDtoService;
