import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const platoonGroupVehiclesResourceEnum = {
  platoonGroupId: "platoonGroupId",
};

// Инициализация стейта в сторе
const platoonGroupsResourceInitialState = {
  [platoonGroupVehiclesResourceEnum.platoonGroupId]: NaN,
};
// Типизация стора
export const platoonGroupVehiclesResourceType = {
  [platoonGroupVehiclesResourceEnum.platoonGroupId]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const platoonGroupVehiclesResourceSlice = createSlice({
  name: `${reducerPath.platoonGroupVehiclesResource}/counter`,
  initialState: platoonGroupsResourceInitialState,
  reducers: {}
});

export const platoonGroupVehiclesResourceReducer = platoonGroupVehiclesResourceSlice.reducer;
