import PropTypes from "prop-types";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const CompanyAccountResourceEnum = {
  name: "name",

};

// Типизация стора
export const CompanyAccountResourceType = {
  [CompanyAccountResourceEnum.name]: PropTypes.string,
};
