import { useContext, useEffect, useRef } from "react";
import OLVectorLayer from "ol/layer/Vector";
import {
  Stroke, Style, RegularShape, Fill
} from "ol/style";
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import VectorSource from 'ol/source/Vector';
import * as olInteraction from 'ol/interaction';
import { Draw } from 'ol/interaction.js';
import PropTypes from 'prop-types';
import GeoMapContext from "../../lib/geo-map/GeoMapContext";

// Задание стилей для векторного слоя
const vectorStyle = (props = {}) => new Style({
  stroke: new Stroke({
    color: props?.color || '#0e97fa',
    width: props?.width || 4,
    lineDash: props.lineDash
  }),
  image: new RegularShape({
    fill: new Fill({
      color: props?.color || '#0e97fa',
    }),
    points: 100,
    radius: 5,
    radius2: 5,
    stroke: new Stroke({
      color: 'white',
      width: 1,
    }),
  }),
});

let modify = null;
let draw = null;

const getCurrentVectorLayer = (map, id) => {
  const layers = map?.getLayers()?.getArray();
  const currentVector = layers?.find((layer) => layer instanceof OLVectorLayer && layer.get('id') === id);
  return currentVector;
};

// Создает и отображает линию на карте
function LineVectorLayerV2(props) {
  const {
    zIndex,
    initialCoords,
    isSelectable,
    isChangeable,
    isStartDraw,
    color,
    isSelected,
    stopDraw,
    id,
    onSelect,
    isDelete,
    lineDash,
    width,
    isCreated,
    isCanceledDraw,
    isCanceledModify,
    onDeselect,
    updateCoords,
  } = props;
  // текущая карта
  const { map } = useContext(GeoMapContext);

  const select = useRef(null);
  const selectedCoords = useRef(null);

  useEffect(() => {
    if (!map) return () => {};
    let currentVector = getCurrentVectorLayer(map, id);

    if (!currentVector) {
      const source = new VectorSource();

      currentVector = new OLVectorLayer({
        source,
        id
      });
      map.addLayer(currentVector);
    }

    if (isDelete) {
      updateCoords(id, []);
      map.removeLayer(currentVector);
    }

    if (currentVector) {
      currentVector.setStyle(vectorStyle({ color, lineDash, width }));
    }

    if (isStartDraw) {
      draw = new Draw({
        source: currentVector?.getSource(),
        type: 'LineString',
        style: vectorStyle({ color }),
      });
      map.addInteraction(draw);

      draw.on('drawstart', (e) => {
        const { feature } = e;
        const geometry = feature?.getGeometry();

        const onGeometryChange = () => {
          const newCoords = geometry?.getCoordinates();
          if (newCoords?.length === 3) {
            updateCoords(id, newCoords.slice(0, -1));
            geometry.un('change', onGeometryChange);
          }
        };
        geometry.on('change', onGeometryChange);
      });

      draw.on('drawend', (e) => {
        const { feature } = e;
        const newCoords = feature?.getGeometry()?.getCoordinates();
        feature?.setId(id);
        stopDraw(id, newCoords);
        map.once('rendercomplete', () => {
          map.removeInteraction(draw);
        });
      });
    }

    let feature = currentVector?.getSource()?.getFeatureById(id);

    if (initialCoords?.length) {
      if (!feature) {
        feature = new Feature(new LineString(initialCoords));
        feature?.setId(id);
        currentVector?.getSource()?.addFeature(feature);
      }
    } else if (feature) {
      currentVector?.getSource()?.removeFeature(feature);
    }

    if (isStartDraw) {
      // выбор полигона
      select.current = new olInteraction.Select({
        layers: [currentVector],
        // style: vectorStyle({ color }),
      });

      if (isSelectable) {
        map.addInteraction(select.current);
        select.current.on('select', () => {
          selectedCoords.current = currentVector
            ?.getSource()?.getFeatureById(id)?.getGeometry()?.getCoordinates();
          const count = select.current.getFeatures().getLength();
          if (count > 0) {
            onSelect(id);
          } else {
            onDeselect(id);
          }
        });
      }

      if (isChangeable) {
        // редактирование линии
        modify = new olInteraction.Modify({
          features: select.current.getFeatures()
        });
        map.addInteraction(modify);

        modify.on('modifyend', () => {
          const newCoords = select.current
            .getFeatures().getArray()[0].getGeometry().getCoordinates();
          updateCoords(id, newCoords);
        });
      }
    }
    if (isSelected) {
      // Очистка текущего состояния выбора
      select.current.getFeatures().clear();

      // Поиск объекта по его идентификатору
      const selectedFeature = currentVector.getSource().getFeatureById(id);

      // Если объект найден, добавим его в выбор
      if (selectedFeature) {
        selectedCoords.current = selectedFeature.getGeometry()?.getCoordinates();
        select.current.getFeatures().push(selectedFeature);
      }
    } else {
      select.current?.getFeatures()?.clear();
    }

    if (isCreated) {
      draw?.finishDrawing();
    }

    if (isCanceledModify) {
      const line = currentVector?.getSource()?.getFeatureById(id)?.getGeometry();
      line?.setCoordinates(selectedCoords.current);
      updateCoords(id, selectedCoords.current);
    }

    if (isCanceledDraw) {
      draw.abortDrawing();
      map.removeInteraction(draw);
      map.removeLayer(currentVector);
      updateCoords(id, []);
    }

    return () => {
      map.removeLayer(currentVector);
    };
  }, [
    map,
    initialCoords,
    zIndex,
    isStartDraw,
    color,
    isSelectable,
    isChangeable,
    isSelected,
    stopDraw,
    id,
    onSelect,
    isDelete,
    lineDash,
    width,
    isCreated,
    isCanceledDraw,
    isCanceledModify,
    onDeselect,
    updateCoords
  ]);

  return null;
}

LineVectorLayerV2.propTypes = {
  zIndex: PropTypes.number,
  initialCoords: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  lineDash: PropTypes.arrayOf(PropTypes.number),
  isSelectable: PropTypes.bool,
  isChangeable: PropTypes.bool,
  isStartDraw: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDelete: PropTypes.bool,
  isCreated: PropTypes.bool,
  isCanceledDraw: PropTypes.bool,
  isCanceledModify: PropTypes.bool,
  stopDraw: PropTypes.func,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  updateCoords: PropTypes.func,
  color: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.number,
};

LineVectorLayerV2.defaultProps = {
  zIndex: 4,
  initialCoords: [],
  lineDash: null,
  width: 4,
  id: crypto.randomUUID(),
  color: '',
  stopDraw: () => {},
  onSelect: () => {},
  onDeselect: () => {},
  updateCoords: () => {},
  isSelectable: false,
  isChangeable: false,
  isStartDraw: false,
  isSelected: false,
  isDelete: false,
  isCreated: false,
  isCanceledDraw: false,
  isCanceledModify: false,
};

export default LineVectorLayerV2;
