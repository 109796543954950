import React, { useEffect, useState } from 'react';
import {
  Alert, AlertTitle, Box, Button, TextField
} from '@mui/material';
import PropTypes, { shape } from "prop-types";
import style from './AlertsPanel.module.css';
import {
  appUserSettingsResourceType
} from "../../../entities/account/appUserSettingsResource/redux/appUserSettingsResource.slice";

function generateToken() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters.charAt(randomIndex);
  }

  return token;
}

// Отображает панель "Моя учётная запись"
function AlertsPanel({
  data,
  patchAppUserSettingsResourceParams,
  postAppUserSettingsResourceParams,
  idUser
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [dataAppUserSettingsResourceId, setDataAppUserSettingsResourceId] = useState(null);
  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const userSettings = data?.dataAppUserSettingsResourceId?.data[0];
    if (userSettings) {
      setDataAppUserSettingsResourceId(userSettings);
    } else {
      setDataAppUserSettingsResourceId({});
    }
  }, [data]);

  const handleAppUserSettings = () => {
    const newData = JSON.parse(JSON.stringify(dataAppUserSettingsResourceId));
    newData.value = generateToken();

    if (dataAppUserSettingsResourceId?.value) {
      patchAppUserSettingsResourceParams({
        id: newData.appUserSettingId,
        body: { ...newData, name: "tg.sec_token" }
      }).then((e) => {
        if (e?.error) {
          setErr(true);
        } else {
          setIsCorrect(true);
          setIsEdit(false);
        }
      });
    } else {
      const postData = {
        group: "tg",
        name: "tg.sec_token",
        value: newData.value,
        valuelist: "",
        orderNum: 2,
        type: {
          settingId: 3,
          name: "string"
        },
        user: {
          appUserId: idUser,
          firstName: null,
          lastName: null,
          phone: null,
          isBot: null,
          isCompanyAdmin: null,
          isBillAdmin: null,
          isUserAdmin: null,
          isPlanAdmin: null,
          company: null,
          user: null
        }
      };
      postAppUserSettingsResourceParams({
        body: postData
      }).then((e) => {
        if (e?.error) {
          setErr(true);
        } else {
          setIsCorrect(true);
          setIsEdit(false);
        }
      });
    }
  };

  return (
    <Box className={style.AlertsPanel}>
      <TextField
        disabled
        value={dataAppUserSettingsResourceId?.value || ''}
        sx={{ width: "100%" }}
        label="токен Telegram бота"
        variant="standard"
      />
      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Токен telegram успешно сохранен
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}

      <Box className={style.buttonsPanel}>
        {isEdit && (
          <Button
            onClick={handleAppUserSettings}
            variant="contained"
          >
            Сгенерировать новый токен
          </Button>
        )}
        {!isEdit && <Button onClick={() => { setIsEdit(true); }} variant="contained">Редактировать</Button>}
        {isEdit && <Button onClick={() => { setIsEdit(false); }} variant="contained">Отмена</Button>}
      </Box>
    </Box>
  );
}

AlertsPanel.propTypes = {
  data: PropTypes.shape({
    dataAppUserSettingsResourceId: shape({
      data: PropTypes.shape(appUserSettingsResourceType)
    })
  }),
  patchAppUserSettingsResourceParams: PropTypes.func.isRequired,
  postAppUserSettingsResourceParams: PropTypes.func.isRequired,
  idUser: PropTypes.number.isRequired
};

AlertsPanel.defaultProps = {
  data: null,
};

export default AlertsPanel;
