// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import platoonGroupParamsResourceDtoType from "../../types/platoonGroupParamsResource-dto.type";

export function platoonGroupParamsResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const newData = [];
  const routeId = dto.filter((e) => e.name === "route_id");
  if (routeId.length) {
    newData.push(...routeId);
    const minFrontVehicle = dto.filter((e) => e.name === "min_front_vehicle");
    if (minFrontVehicle.length) {
      newData.push(...minFrontVehicle);
    } else {
      newData.push({
        isNew: true,
        section: "backup_route",
        name: "min_front_vehicle",
        value: "",
        type: "TYPE_INTEGER",
        group: {
          platoonGroupId: 1051,
          name: null,
          status: null,
          companyId: null,
          routeId: null,
          startDateFrom: null,
          startDateTo: null,
          intervalDuration: null,
          requiredTokens: null,
          adviseTokens: null,
          createdDate: null,
          modifiedDate: null
        }
      });
    }
    const minExitVehicle = dto.filter((e) => e.name === "min_exit_vehicle");
    if (minExitVehicle.length) {
      newData.push(...minExitVehicle);
    } else {
      newData.push({
        isNew: true,
        section: "backup_route",
        name: "min_exit_vehicle",
        value: "",
        type: "TYPE_INTEGER",
        group: {
          platoonGroupId: 1051,
          name: null,
          status: null,
          companyId: null,
          routeId: null,
          startDateFrom: null,
          startDateTo: null,
          intervalDuration: null,
          requiredTokens: null,
          adviseTokens: null,
          createdDate: null,
          modifiedDate: null
        }
      });
    }
    const geoTo = dto.filter((e) => e.name === "geo_to");
    if (geoTo.length) {
      newData.push(...geoTo);
    } else {
      newData.push({
        isNew: true,
        section: "backup_route",
        name: "geo_to",
        value: "",
        type: "TYPE_INTEGER",
        group: {
          platoonGroupId: 1051,
          name: null,
          status: null,
          companyId: null,
          routeId: null,
          startDateFrom: null,
          startDateTo: null,
          intervalDuration: null,
          requiredTokens: null,
          adviseTokens: null,
          createdDate: null,
          modifiedDate: null
        }
      });
    }

    const geoFrom = dto.filter((e) => e.name === "geo_from");
    if (geoFrom.length) {
      newData.push(...geoFrom);
    } else {
      newData.push({
        isNew: true,
        section: "backup_route",
        name: "geo_from",
        value: "",
        type: "TYPE_INTEGER",
        group: {
          platoonGroupId: 1051,
          name: null,
          status: null,
          companyId: null,
          routeId: null,
          startDateFrom: null,
          startDateTo: null,
          intervalDuration: null,
          requiredTokens: null,
          adviseTokens: null,
          createdDate: null,
          modifiedDate: null
        }
      });
    }
  }

  const geoToInclude = dto.filter((e) => e.name === "geo_to_include");
  if (geoToInclude.length) {
    newData.push(...geoToInclude);
  }

  return { data: newData, status };
}

platoonGroupParamsResourceFromDtoService.propTypes = {
  dto: platoonGroupParamsResourceDtoType,
};

export default platoonGroupParamsResourceFromDtoService;
