import * as React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";

const ITEM_CONFIG = {
  warehouse: {
    title: 'Свойства склада',
    fields: [
      {
        name: 'name',
        label: 'Название',
        type: PropTypes.string,
      },
      {
        name: 'geozoneWait',
        label: 'Геозона очереди ожидания',
        type: PropTypes.string,
      },
      {
        name: 'geozoneUnload',
        label: 'Геозона разгрузки',
        type: PropTypes.string,
      },
      {
        name: 'queueLength',
        label: 'Кол-во мест в очереди ожидания',
        type: PropTypes.number,
      },
      {
        name: 'unloadSpeed',
        label: 'Скорость разгрузки, т/ч',
        type: PropTypes.number,
      },
      {
        name: 'dayCropCapacity',
        label: 'Производительность приема общая,т/д',
        type: PropTypes.number,
      },
      {
        name: 'hourCropCapacity',
        label: 'Производительность приема общая,т/час',
        type: PropTypes.number,
      },
      {
        name: 'minUnloadTime',
        label: 'Минимальное время разгрузки, мин',
        type: PropTypes.number,
      },
      {
        name: 'unloadStart',
        label: 'Начало разгрузки',
        isDate: true,
        type: PropTypes.string,
      },
      {
        name: 'unloadFinish',
        label: 'Завершение разгрузки',
        isDate: true,
        type: PropTypes.string,
      },
      {
        name: 'workdayStart',
        label: 'Начало дня',
        type: PropTypes.string,
      },
      {
        name: 'workdayFinish',
        label: 'Завершение дня',
        type: PropTypes.string,
      },
      {
        name: 'cropTypes',
        label: 'Культуры',
        isList: true,
        type: PropTypes.string,
      },
      {
        name: 'vehicleTypes',
        label: 'Типы автомобилей',
        type: PropTypes.string,
      },
    ]
  },
  field: {
    title: 'Свойства поля',
    fields: [
      {
        name: 'fieldName',
        label: 'Название',
        type: PropTypes.string,
      },
      {
        name: 'cropType',
        label: 'Культура',
        type: PropTypes.string,
      },
      {
        name: 'square',
        label: 'Площадь, га',
        type: PropTypes.number,
      },
      {
        name: 'geozoneName',
        label: 'Имя геозоны',
        type: PropTypes.string,
      },
      {
        name: 'cropPlanCapacity',
        label: 'Плановая урожайность, т',
        type: PropTypes.number,
      },
      {
        name: 'cropDensity',
        label: 'Насыпная плотность, л/т',
        type: PropTypes.number,
      },
      {
        name: 'harvestingStart',
        label: 'Начало сбора',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'harvestingFinish',
        label: 'Завершение сбора',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'workdayStart',
        label: 'Начало рабочего дня',
        type: PropTypes.string,
      },
      {
        name: 'workdayFinish',
        label: 'Завершение рабочего дня',
        type: PropTypes.string,
      },
    ]
  },
  vehicle: {
    title: 'Транспортное средство',
    fields: [
      {
        name: 'model',
        label: 'Марка',
        type: PropTypes.string,
      },
      {
        name: 'regNum',
        label: 'Госномер',
        type: PropTypes.string,
      },
      {
        name: 'bodyVolume',
        label: 'Объем кузова, литр',
        type: PropTypes.number,
      },
      {
        name: 'trailerVolume',
        label: 'Объем прицепа, литр',
        type: PropTypes.number,
      },
      {
        name: 'availabilityStart',
        label: 'Начало доступности',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'availabilityFinish',
        label: 'Завершение доступности',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'workdayStart',
        label: 'Начало дня',
        type: PropTypes.string,
      },
      {
        name: 'workdayFinish',
        label: 'Завершение дня',
        type: PropTypes.string,
      },
      {
        name: 'geozoneName',
        label: 'Расположение',
        type: PropTypes.string,
      },
      {
        name: 'cropTypes',
        label: 'Разрешенные культуры',
        isList: true,
        type: PropTypes.string,
      },
      {
        name: 'bodyType',
        label: 'Особенности разгрузки (тип ТС)',
        type: PropTypes.string,
      },
    ],
  },
  harvester: {
    title: 'Уборочная единица',
    fields: [
      {
        name: 'model',
        label: 'Марка',
        type: PropTypes.string,
      },
      {
        name: 'regNum',
        label: 'Госномер',
        type: PropTypes.string,
      },
      {
        name: 'volume',
        label: 'Объем бункера, литр',
        type: PropTypes.number,
      },
      {
        name: 'productivity',
        label: 'Производительность, т/ч',
        editable: false,
        type: PropTypes.number,
      },
      {
        name: 'availabilityStart',
        label: 'Начало доступности',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'availabilityFinish',
        label: 'Завершение доступности',
        type: PropTypes.string,
        isDate: true,
      },
      {
        name: 'workdayStart',
        label: 'Начало дня',
        type: PropTypes.string,
      },
      {
        name: 'workdayFinish',
        label: 'Завершение дня',
        type: PropTypes.string,
      },
      {
        name: 'geozoneName',
        label: 'Расположение',
        type: PropTypes.string,
      },
      {
        name: 'cropTypes',
        label: 'Разрешенные культуры',
        isList: true,
        type: PropTypes.string,
      },
    ],
  }
};

export default function DetailsDialog(props) {
  const {
    type, item, closeItemDialog, onOk, okLabel
  } = props;
  const [fields, setFields] = React.useState([]);
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {
    if (!!item && type) {
      const config = ITEM_CONFIG[type];
      setTitle(config.title);
      setFields(config.fields.map(({
        name, label, isList, isDate
      }) => (
        { value: isList ? item[name].join(', ') : (isDate ? format(new Date(item[name]), 'dd.MM.yyyy') : item[name]), label }
      )));
    }
  }, [type, item]);

  const handleOnOk = () => {
    onOk?.();
    closeItemDialog();
  };

  return (
    <Dialog
      maxWidth="md"
      open={!!item}
      onClose={closeItemDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}
          size="small"
        >
          <TableBody>
            {fields.map((fld, i) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                sx={{ border: 0 }}
                hover
              >
                <TableCell sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {fld.label}
                </TableCell>
                <TableCell>{fld.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeItemDialog}>Отмена</Button>
        {onOk && <Button onClick={handleOnOk} autoFocus>{okLabel}</Button>}
      </DialogActions>
    </Dialog>
  );
}

DetailsDialog.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.shape(ITEM_CONFIG.harvester.fields.reduce((acc, field) => ({
      ...acc,
      [field.name]: field.type,
    }), {})),
    PropTypes.shape(ITEM_CONFIG.vehicle.fields.reduce((acc, field) => ({
      ...acc,
      [field.name]: field.type,
    }), {})),
    PropTypes.shape(ITEM_CONFIG.field.fields.reduce((acc, field) => ({
      ...acc,
      [field.name]: field.type,
    }), {})),
    PropTypes.shape(ITEM_CONFIG.warehouse.fields.reduce((acc, field) => ({
      ...acc,
      [field.name]: field.type,
    }), {})),
  ]),
  closeItemDialog: PropTypes.func,
  onOk: PropTypes.func,
  okLabel: PropTypes.string,
  type: PropTypes.oneOf(['harvester', 'vehicle', 'field', 'warehouse']).isRequired,
};

DetailsDialog.defaultProps = {
  item: {},
  closeItemDialog: null,
  onOk: null,
  okLabel: 'Да',
};
