import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';

// Кнопка копирования с иконкой и подписью
function CopyButton({ onCopy, title }) {
  return (
    <Box>
      <Tooltip title={title}>
        <IconButton
          color="primary"
          onClick={onCopy}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

CopyButton.propTypes = {
  onCopy: PropTypes.func.isRequired,
  title: PropTypes.string,
};

CopyButton.defaultProps = {
  title: 'Копировать'
};

export default CopyButton;
