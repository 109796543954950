import { Box } from '@mui/material';
import React from 'react';
import style from './CarIcon.module.css';
import carImg from '../../../assets/icons/car2.png';

function CarIcon(props) {
  return (
    <Box className={style.carIcon}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <img className={style.carImg} src={carImg} alt="" {...props} />
    </Box>
  );
}

export default CarIcon;
