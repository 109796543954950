import {
  Box, IconButton, Menu,
  MenuItem, TextField, Tooltip
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RouteIcon from '../../shared/ui/RouteIcon/RouteIcon';
import SelectRouteDlg from '../../entities/vehicles/routes/SelectRouteDlg';
import style from './RouteInputControl.module.css';

const routeVariants = [
  { type: 'history', name: 'Из истории' },
  { type: 'typical', name: 'Типовой маршрут' },
  { type: 'track', name: 'Загрузить трек' },
];

function RouteInputControl(props) {
  const {
    selectRoute,
    selectedRoute,
    vehicleRoutes,
    onRouteNameChange,
    loading,
  } = props;
  // Вариант маршрута, выбранныый при нажатии на кнопку "выбрать"
  const [selectedRouteVariant, setSelectedRouteVariant] = useState(null);
  // Состояние диалогового окна выбора маршрута
  const [isOpenRouteDlg, setIsOpenRouteDlg] = useState(false);
  // Якорь всплывающего меню выбора варианта маршурта
  const [anchorControl, setAnchorControl] = useState(null);
  // Имя, отображаемое в текстовом поле
  const [routeName, setRouteName] = useState('');

  // Открытие диалогового окна выбора маршрута
  const clickRouteVariant = (variant) => {
    setSelectedRouteVariant(variant);
    setIsOpenRouteDlg(true);
    setAnchorControl(null);
  };

  // Смена имени маршрута в форме при смене выбранного маршрута
  useEffect(() => {
    setRouteName(selectedRoute?.name || '');
  }, [selectedRoute]);

  const vehicleRoutesTemplates = useMemo(
    () => vehicleRoutes.filter((e) => e.isTemplate),
    [vehicleRoutes]
  );
  const vehicleRoutesNoTemplates = useMemo(
    () => vehicleRoutes.filter((e) => !e.isTemplate),
    [vehicleRoutes]
  );

  return (
    <Box className={style.RouteInputControl}>
      <TextField
        sx={{ width: 410 }}
        id="routeName"
        label="Название маршрута"
        value={routeName}
        required
        InputProps={{
          readOnly: true
        }}
        // error={Boolean(errors.routeName)}
        // helperText={errors.routeName ? "Поле не может быть пустым" : null}
        variant="standard"
      />
      <Tooltip title="Выбрать">
        <span>
          <IconButton
            color="primary"
            onClick={(event) => setAnchorControl(event.currentTarget)}
          >
            <RouteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorControl}
        open={Boolean(anchorControl)}
        onClose={() => setAnchorControl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {routeVariants.map((routeVariant) => (
          <MenuItem
            disabled={routeVariant.type === 'track'}
            onClick={() => clickRouteVariant(routeVariant)}
            key={routeVariant.type}
            value={routeVariant.name}
          >
            {routeVariant.name}
          </MenuItem>
        ))}
      </Menu>
      <SelectRouteDlg
        isOpenRouteDlg={isOpenRouteDlg}
        onRouteNameChange={onRouteNameChange}
        setIsOpenRouteDlg={setIsOpenRouteDlg}
        selectedRouteVariant={selectedRouteVariant}
        vehicleRoutes={selectedRouteVariant?.name === routeVariants[1].name
          ? vehicleRoutesTemplates
          : vehicleRoutesNoTemplates}
        selectRoute={selectRoute}
        loading={loading}
      />
    </Box>
  );
}

RouteInputControl.propTypes = {
  selectRoute: PropTypes.func.isRequired,
  selectedRoute: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  vehicleRoutes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]))),
  onRouteNameChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

RouteInputControl.defaultProps = {
  selectedRoute: null,
  vehicleRoutes: [],
};

export default RouteInputControl;
