import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { shape } from "prop-types";
import style from "../../panels/account/ChangePasswordPanel/ChangePasswordPanel.module.css";
// eslint-disable-next-line boundaries/element-types
import convertToISOTime from "../../shared/utils/convertToISOTime";
import {
  typeMessages,
  typeSeverity
} from "../../entities/alerts/alertsSettingsResource/services/data/alertsSettingsResource-from-dto.service";
import {
  alertsChannelsResourceType
} from "../../entities/alerts/alertsChannelsResource/redux/alertsChannelsResource.slice";
import checkFormatTime from "../../shared/utils/checkFormatTime";
import { alertsTypesResourceType } from "../../entities/alerts/alertsTypesResource/redux/alertsTypesResource.slice";
import { divisionsResourceType } from "../../entities/divisions/divisionsResource/redux/divisionsResource.slice";
import { statuses } from "../../entities/alerts/alertsResource/services/data/alertsResource-enums";

// функция для получения уникальных категорий
export function getUniqueCategories(arr) {
  const uniqueCategories = [];

  arr.forEach((item) => {
    if (!uniqueCategories.includes(item.category)) {
      uniqueCategories.push(item.category);
    }
  });

  return uniqueCategories;
}

export const getRuCategory = (value) => {
  switch (value) {
    case "VEHICLE":
      return "ТС";
    case "VEHICLE_ROUTE":
      return "Маршрут";
    case "DENM":
      return "Дорожная ситуация";
    default:
      return 'Все';
  }
};

// Виджет создания "настройки сообщений "
function CreateAlertsSettingsWidget({
  goBack, data, postAlertsSettingsResourceParams, companyId
}) {
  const [severity, setSeverity] = useState({}); // Серьезность
  const [dataAlertsTypesResource, setDataAlertsTypesResource] = useState([]); // Категория сообщений
  const [repeatDuration, setRepeatDuration] = useState('0ч 0мин 0сек');// Интервал повторения
  const [category, setCategory] = useState('');// Категория сообщений
  const [code, setCode] = useState('');// Тип сообщения
  const [channel, setChannel] = useState({});// Информационный канал
  const [dataAlertsChannels, setDataAlertsChannels] = useState(null);// Информационный канал
  const [dataDivisionsResource, setDataDivisionsResource] = useState([]);// Дивизионы
  const [divisionId, setDivisionId] = useState(null);// Дивизион для отправки

  const [isErr, setErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const dataAlertsChannelsCurrent = data?.dataAlertsChannels?.data;
    const dataAlertsTypesResourceCurrent = data?.dataAlertsTypesResource?.data;
    const dataDivisionsResourceCurrent = data?.dataDivisionsResource?.data;
    if (dataAlertsChannelsCurrent) {
      setDataAlertsChannels(dataAlertsChannelsCurrent);
    }
    if (dataAlertsTypesResourceCurrent) {
      const all = {
        alertTypeId: statuses.ALL,
        category: statuses.ALL,
        code: statuses.ALL
      };
      setDataAlertsTypesResource([all, ...dataAlertsTypesResourceCurrent]);
    }
    if (dataDivisionsResourceCurrent) {
      setDataDivisionsResource(dataDivisionsResourceCurrent);
    }
  }, [data]);

  const handleSave = useCallback(() => {
    if (checkFormatTime(repeatDuration)) {
      const newObject = { company: {}, division: {} };
      const time = repeatDuration;
      newObject.repeatDuration = convertToISOTime(time);
      newObject.channel = channel;
      newObject.company.companyId = companyId;
      if (divisionId?.divisionId) {
        newObject.division.divisionId = divisionId.divisionId;
      } else {
        newObject.division = null;
      }
      if (code?.id) {
        newObject.code = code.id;
      }
      if (category !== statuses.ALL) {
        newObject.category = category;
      } else {
        delete newObject.category;
      }
      if (severity.id && (severity.id !== statuses.ALL)) {
        newObject.severity = severity.id;
      } else {
        delete newObject.severity;
      }
      postAlertsSettingsResourceParams(
        { body: newObject }
      ).then((e) => {
        if (e?.error) {
          setErr(true);
        } else {
          setIsCorrect(true);
          setErr(false);
        }
      });
    }
  }, [severity, repeatDuration, category, code, channel]);

  return (
    <Box
      sx={{
        display: "flex", marginBottom: "20px", marginTop: '20px', gap: "20px", flexDirection: 'column'
      }}
    >
      <Typography variant="h6" gutterBottom component="div" sx={{ color: 'text.secondary' }}>
        Создание правила отправки сообщения
      </Typography>
      <Box sx={{
        display: "flex", gap: "30px", flexWrap: "wrap", maxWidth: "350px", marginBottom: "30px"
      }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="typeMessage-label">Серьезность</InputLabel>
              <Select
                labelId="typeMessage-label"
                id="typeMessage-label"
                value={severity?.id || 0}
                label="Серьезность"
                onChange={((e) => {
                  setSeverity(typeSeverity
                    .filter((item) => e.target.value === item.id)[0]);
                })}
              >
                {typeSeverity?.map((typeSeverityItem) => (
                  <MenuItem
                    key={`alertChannelId-select-item${typeSeverityItem.id}`}
                    value={typeSeverityItem.id}
                  >
                    {typeSeverityItem.ruName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            fullWidth
            size="small"
            autoComplete
            value={divisionId?.name}
            onChange={(event, newValue) => {
              setDivisionId(newValue);
            }}
            id="divisionId"
            options={dataDivisionsResource}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderInput={(params) => <TextField {...params} label="Подразделение" />}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <TextField
            sx={{ width: "100%" }}
            value={repeatDuration || ''}
            error={!checkFormatTime(repeatDuration || '')}
            onChange={(e) => {
              setRepeatDuration(e.target.value);
            }}
            label="Интервал повторения"
            variant="standard"
            helperText={!checkFormatTime(repeatDuration || '') && "Корректный формат: 0ч 0мин 0сек"}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="category-label">Категория сообщений</InputLabel>
            <Select
              labelId="category-label"
              id="category-label"
              value={category || 0}
              label="Категория сообщений"
              onChange={(e) => {
                setCategory(e.target.value);
                setCode('');
              }}
            >
              {getUniqueCategories(dataAlertsTypesResource)?.map((alertsType, i) => (
                <MenuItem
                  key={`alertsType-select-item${alertsType}-${i + 1}`}
                  value={alertsType}
                >
                  {getRuCategory(alertsType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {category && (category !== statuses.ALL) && (
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="typeMessage-label">Тип сообщения</InputLabel>
            <Select
              labelId="typeMessage-label"
              id="typeMessage-label"
              value={code.id || 0}
              label="Тип сообщения"
              onChange={((e) => {
                const newCode = typeMessages.find((item) => e.target.value === item.id);
                const unknownCode = { id: e.target.value, ruName: e.target.value };
                setCode(newCode || unknownCode);
              })}
            >
              {dataAlertsTypesResource
                ?.filter((e) => e.category === category).map((typeMessage, i) => (
                  <MenuItem
                    key={`typeMessage-select-item${typeMessage.code}-${i + 1}`}
                    value={typeMessage.code}
                  >
                    {typeMessages.find((item) => item.id === typeMessage.code)?.ruName
                      || typeMessage.code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        )}

        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="typeMessage-label">Информационный канал</InputLabel>
            <Select
              labelId="typeMessage-label"
              id="typeMessage-label"
              value={channel?.alertChannelId || 0}
              label="Информационный канал"
              onChange={((e) => {
                setChannel(dataAlertsChannels
                  .filter((item) => e.target.value === item.alertChannelId)[0]);
              })}
            >
              {dataAlertsChannels?.map((adapterType) => (
                <MenuItem
                  key={`alertChannelId-select-item${adapterType.alertChannelId}`}
                  value={adapterType.alertChannelId}
                >
                  {adapterType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {!isErr && isCorrect
                && (
                <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="success">
                  <AlertTitle>Успешно</AlertTitle>
                  Данные успешно сохранены
                </Alert>
                )}
      {isErr
                && (
                <Alert sx={{ width: "100%", marginBottom: "30px" }} severity="error">
                  <AlertTitle>Ошибка</AlertTitle>
                  Произошла ошибка
                </Alert>
                )}
      <Box className={style.buttonsPanel}>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!(
            checkFormatTime(repeatDuration)
              && channel?.alertChannelId)}
        >
          Создать
        </Button>
        <Button
          onClick={goBack}
          variant="contained"
        >
          Назад
        </Button>
      </Box>
    </Box>
  );
}

CreateAlertsSettingsWidget.propTypes = {
  data: PropTypes.shape({
    dataAlertsChannels: shape({
      data: PropTypes.shape(alertsChannelsResourceType)
    }),
    dataAlertsTypesResource: shape({
      data: PropTypes.arrayOf(PropTypes.shape(alertsTypesResourceType))
    }),
    dataDivisionsResource: shape({
      data: PropTypes.arrayOf(PropTypes.shape(divisionsResourceType))
    }),
  }),
  goBack: PropTypes.func,
  postAlertsSettingsResourceParams: PropTypes.func,
  companyId: PropTypes.number
};

CreateAlertsSettingsWidget.defaultProps = {
  goBack: null,
  postAlertsSettingsResourceParams: null,
  data: null,
  companyId: null
};

export default CreateAlertsSettingsWidget;
