// eslint-disable-next-line no-unused-vars
import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  useGetTariffPlanResourceIdQuery,
  usePutTariffPlanResourceMutation,
} from "../redux/tariffPlansResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function TariffPlansResourceEditBll({ children }) {
  const { tarifPlanId } = useParams();

  const [data, setData] = useState();

  const dataTariffPlansResourceId = useGetTariffPlanResourceIdQuery({
    id: tarifPlanId
  });

  const [putTariffPlanResource] = usePutTariffPlanResourceMutation();

  const putTariffPlanResourceParams = useCallback(
    ({ id, body }) => putTariffPlanResource({
      id,
      body
    }),
    [putTariffPlanResource]
  );

  useEffect(() => {
    setData({
      dataTariffPlansResourceId
    });
  }, [dataTariffPlansResourceId]);

  const props = {
    data,
    putTariffPlanResourceParams
  };

  return data
    ? Children.toArray(children).map((child) => cloneElement(child, props))
    : null;
}

TariffPlansResourceEditBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TariffPlansResourceEditBll;
