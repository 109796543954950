import React, {
  useEffect, useMemo,
  useState
} from 'react';
import {
  Alert, AlertTitle, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import style from './PaymentCreatePanel.module.css';
import { typesPay } from "../PaymentInfoPanel/PaymentInfoPanel";
import { getCompanies } from "../../../shared/api/api";

// Отображает панель изменение пользователя
function PaymentCreatePanel({
  postPaymentResourceParams,
  dataCompanyAccount
}) {
  const navigate = useNavigate();
  const [payDate, setPayDate] = useState(new Date());
  const [sum, setSum] = useState();
  const [companyAccount, setCompanyAccount] = useState();
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const [isErr, setIsErr] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);

  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies().then((res) => {
      setCompanies(res);
    }).catch((error) => { throw error; });
  }, []);

  const handleSaveChanges = () => {
    const newData = {};
    newData.companyAccount = dataCompanyAccount?.data.find(
      (e) => e.companyAccountId === companyAccount
    );
    newData.payDate = payDate.toISOString();
    newData.sum = Number(sum);
    newData.type = status;
    newData.comment = comment;
    newData.company = {
      companyId: company?.companyId, name: null, site: null, address: null
    };

    postPaymentResourceParams({
      body: newData
    }).then((e) => {
      if (e.error) {
        if (e.error.data.status === 400) {
          setIsErr("Внимательно проверьте еще раз правильность введенных Вами данных.");
        }
        if (e.error.data.status === 403) {
          setIsErr("У вас нет доступа к данному функционалу ");
        }
        if (e.error.data.status === 500) {
          setIsErr("Повторите попытку через несколько минут.");
        }
      } else {
        setIsCorrect(true);
        setTimeout(() => {
          navigate(`/finances/payments`);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setIsErr("");
  }, [payDate, sum, companyAccount]);

  const companiesList = useMemo(() => {
    const idAccount = dataCompanyAccount?.data
      ?.find((e) => e.companyAccountId === companyAccount)?.company.companyId;

    if (companyAccount) {
      return companies.filter((e) => e.id === idAccount);
    }
    return companies;
  }, [dataCompanyAccount, companyAccount, companies]);

  const companyAccountList = useMemo(() => {
    if (company?.id) {
      return dataCompanyAccount?.data?.filter((e) => e.company.companyId === company.id);
    }
    return dataCompanyAccount?.data || null;
  }, [dataCompanyAccount, company]);

  return (
    <Box className={style.UsersInfoPanel}>

      <Autocomplete
        fullWidth
        size="medium"
        autoComplete
        value={company}
        onChange={(event, newValue) => {
          setCompany(newValue);
        }}
        id="route"
        options={companiesList}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        renderInput={(params) => <TextField {...params} label="Компания" />}
      />

      <DateTimePicker
        label="Дата оплаты"
        value={new Date(payDate)}
        inputFormat="dd/MM/yyyy HH:mm"
        onChange={(e) => {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(e) && e) {
            setPayDate(e.toISOString());
          } else {
            setPayDate(null);
          }
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
      <FormControl fullWidth>
        <InputLabel id="category-label">Тип операции</InputLabel>
        <Select
          labelId="category-label"
          id="category-label"
          value={status || 0}
          label="Категория сообщений"
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          {typesPay.map((typesPayCurrent, i) => (
            <MenuItem
              key={`alertsType-select-item${typesPayCurrent.id}-${i + 1}`}
              value={typesPayCurrent.id}
            >
              {typesPayCurrent.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={sum || ''}
        type="number"
        onChange={(e) => {
          setSum(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Сумма"
        variant="outlined"
      />

      <TextField
        value={comment || ''}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        sx={{ width: "100%" }}
        label="Коментарий"
        variant="outlined"
        multiline
        rows={4}
        maxRows={Infinity}
      />

      <FormControl fullWidth>
        <InputLabel id="category-label">Аккаунт</InputLabel>
        <Select
          labelId="category-label"
          id="category-label"
          value={companyAccount || 0}
          label="Категория сообщений"
          onChange={(e) => {
            setCompanyAccount(e.target.value);
          }}
        >
          {companyAccountList?.length ? companyAccountList.map((companyAccountCurrent, i) => (
            <MenuItem
              key={`alertsType-select-item${companyAccountCurrent.companyAccountId}-${i + 1}`}
              value={companyAccountCurrent.companyAccountId}
            >
              {companyAccountCurrent.companyAccountId}
            </MenuItem>
          )) : dataCompanyAccount?.data && (
            <MenuItem
              key="alertsType-select-item-01"
              value=""
              disabled
            >
              У данной компании нет аккаунта
            </MenuItem>
          )}
        </Select>
      </FormControl>

      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Платеж успешно создан
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%" }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
                {isErr}
            </Alert>
            )}
      <Box className={style.buttonsPanel}>
        <Button disabled={!company || !payDate || !status || !sum || !companyAccount} onClick={handleSaveChanges} variant="contained">Создать</Button>
        <Button variant="contained" onClick={() => navigate(`/finances/payments`)}>
          {!isErr && isCorrect ? 'Назад' : 'Отмена'}
        </Button>
      </Box>
    </Box>
  );
}

PaymentCreatePanel.propTypes = {
  postPaymentResourceParams: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataCompanyAccount: PropTypes.any
};

PaymentCreatePanel.defaultProps = {
  dataCompanyAccount: null,
};

export default PaymentCreatePanel;
