// eslint-disable-next-line
import ERROR_CODES from "./ERROR_CODES";

function getErrorCode(status, error = {}) {
  switch (status) {
    case 400:
      return ERROR_CODES[error?.type];
    case 403:
      return ERROR_CODES.FORBIDDEN;
    case 404:
      return ERROR_CODES.NOT_FOUND;
    default:
      return ERROR_CODES.UNKNOWN_ERROR;
  }
}

export default getErrorCode;
