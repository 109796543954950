export const BLOCK_MIN_WIDTH = 46;
export const WAREHOUSE_HEIGHT = 46;
export const COMMON_PADDING = 6;
export const FLD_BLOCK_HEIGHT = 238;
export const ROUTE_BLOCK_HEIGHT = 228;
export const PANEL_COLOR = '#f2f2f2';
export const FIELD_TXT_COLOR = '#41611c';
export const FIELD_ICO_COLOR = '#6c973a';
export const ROUTE_ICO_COLOR = '#47607c';
export const WAREHOUSE_ICO_COLOR = '#3f3915';
export const WARNING_SOLUTION = {
  SOLVE: 'SOLVE',
  MAKE_OK: 'MAKE_OK',
  IGNORE: 'IGNORE',
};

// TODO Удалить моковые данные!

const generateRandomVehicles = (num) => (
  new Array(num).fill(0).map((_, i) => ({
    id: i,
    name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  }))
);

const generateRandomHarvesters = (num) => (
  new Array(num).fill(0).map((_, i) => ({
    id: i,
    name: ['TORUM 785', 'RSM 161', 'ACROS 595 Plus'][Math.floor(Math.random() * 2.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  }))
);

export const fieldsData = [
  {
    id: 1,
    bgColor: '#bbdf93',
    name: 'Поле А-23/Е',
    area: '150 га',
    crop: 'пшеница',
    harvesters: generateRandomHarvesters(2),
    routes: [
      {
        id: 1,
        vehicles: generateRandomVehicles(3),
        toField: { onWay: 2, inQueue: 1 },
        toWarehouse: { onWay: 2, inQueue: 0 },
        warehouseindex: 0,
      },
      { id: 2, vehicles: generateRandomVehicles(3), warehouseindex: 1 }
    ]
  },
  {
    id: 2,
    bgColor: '#bbdf93',
    name: 'Поле А-23/Е',
    area: '150 га',
    crop: 'пшеница',
    harvesters: [],
    routes: [
      {
        id: 3,
        warehouseindex: 1,
        vehicles: generateRandomVehicles(231),
        toField: { onWay: 3, inQueue: 2 },
        toWarehouse: { onWay: 222, inQueue: 4 }
      }
    ]
  },
  {
    id: 3,
    bgColor: '#bbdf93',
    name: 'Поле А-23/Е',
    area: '150 га',
    crop: 'пшеница',
    harvesters: [],
    routes: [{
      id: 4,
      warehouseindex: 2,
      vehicles: generateRandomVehicles(12)
    },
    {
      id: 5,
      warehouseindex: 0,
      vehicles: generateRandomVehicles(3)
    },
    {
      id: 51,
      warehouseindex: 1,
      vehicles: generateRandomVehicles(8)
    }]
  },
  {
    id: 4, name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: generateRandomHarvesters(6)
  },
  {
    id: 5,
    bgColor: '#bbdf93',
    name: 'Поле А-24/Е',
    area: '150 га',
    crop: 'пшеница',
    harvesters: generateRandomHarvesters(12),
    routes: [
      { id: 6, warehouseindex: 0 },
      { id: 7, warehouseindex: 1, vehicles: generateRandomVehicles(3) }
    ]
  },
  {
    id: 6, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 7, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '50 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 8, bgColor: '#a5d995', name: 'А-23/Е', area: '150 га', crop: 'картофель', harvesters: []
  },
  {
    id: 9, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 10, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 11, bgColor: '#c6df93', name: 'Поле А-23/Е', area: '250 га', crop: 'овёс', harvesters: []
  },
  {
    id: 12, bgColor: '#c6df93', name: 'Поле А-23/Е', area: '150 га', crop: 'овёс', harvesters: []
  },
  {
    id: 13, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 14, bgColor: '#a5d995', name: 'Б-23/У', area: '150 га', crop: 'картофель', harvesters: []
  },
  {
    id: 15, bgColor: '#bbdf93', name: 'Поле А-23/Е', area: '150 га', crop: 'пшеница', harvesters: []
  },
  {
    id: 16, bgColor: '#a5d995', name: 'Поле А-23/Е', area: '150 га', crop: 'картофель', harvesters: []
  },
];

export const warehousesData = [
  {
    id: 1, name: 'Толмачевский-1', vehicles: { unloading: 8, waiting: 12 }
  },
  { id: 2, name: 'Толмачевский-2', vehicles: { unloading: 1, waiting: 0 } },
  {
    id: 3, name: 'Кутузовский', vehicles: { unloading: 3, waiting: 2 }, bgColor: '#dcd38f'
  },
];

export const warehouseQueueData = new Array(14).fill(0).map((_, i) => ({
  id: i,
  name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
  regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
}));
export const warehouseUnloadingData = new Array(12).fill(0).map((_, i) => ({
  id: i,
  name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
  regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
}));

export const harvestersData = {
  total: new Array(19).fill(0).map((_, i) => ({
    id: i,
    name: ['TORUM 785', 'RSM 161', 'ACROS 595 Plus'][Math.floor(Math.random() * 2.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  work: new Array(16).fill(0).map((_, i) => ({
    id: i,
    name: ['TORUM 785', 'RSM 161', 'ACROS 595 Plus'][Math.floor(Math.random() * 2.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  maintenance: new Array(1).fill(0).map((_, i) => ({
    id: i,
    name: ['TORUM 785', 'RSM 161', 'ACROS 595 Plus'][Math.floor(Math.random() * 2.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  available: new Array(2).fill(0).map((_, i) => ({
    id: i,
    name: ['TORUM 785', 'RSM 161', 'ACROS 595 Plus'][Math.floor(Math.random() * 2.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
};

export const vehiclesData = {
  total: new Array(190).fill(0).map((_, i) => ({
    id: i,
    name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  work: new Array(160).fill(0).map((_, i) => ({
    id: i,
    name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  maintenance: new Array(12).fill(0).map((_, i) => ({
    id: i,
    name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
  available: new Array(18).fill(0).map((_, i) => ({
    id: i,
    name: ['КамАЗ-65115', 'МАЗ 631228'][Math.floor(Math.random() * 1.99)],
    regNum: `A${Math.floor(Math.random() * 999)}EУ 36`,
  })),
};
