// eslint-disable-next-line boundaries/no-unknown-files
import { createApi } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line boundaries/element-types
import reducerPath from "../../../../app/reducerPath";
// eslint-disable-next-line boundaries/element-types
import createHeadRequest from "../../../../shared/http/createHeadRequest";
// eslint-disable-next-line boundaries/element-types
import checkStatus from "../../../../shared/http/checkStatus";
// eslint-disable-next-line boundaries/element-types
import HttpMethods from "../../../../shared/api/HttpMethods";
// eslint-disable-next-line import/no-named-as-default
import adapterResourceTypeFromDtoService from "../services/data/adapterResourceType-from-dto.service";
// eslint-disable-next-line boundaries/element-types
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";
import config from '../../../../shared/api/config';

// Описываем методы обращения к беку
export const adapterResourceTypeApi = createApi({
  reducerPath: `${reducerPath.adapterResourceType}/api`,
  tagTypes: ['TAG'],
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/adapter-types`),
  endpoints: (builder) => ({
    getAdapterTypes: builder.query({
      query: () => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
      }),
      transformResponse: adapterResourceTypeFromDtoService
    }),
  }),
});

export const { useGetAdapterTypesQuery } = adapterResourceTypeApi;
