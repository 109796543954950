// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
import convertFormatDate from "../../../../../shared/utils/convertFormatDate";
// import formatFromTimeISO8601 from "../../../../../shared/utils/formatFromTimeISO8601";
import platoonGroupVehiclesResourceDtoType from "../../types/platoonGroupVehiclesResource-dto.type";
// import convertDurationToSeconds from "../../../../../shared/utils/convertDurationToSeconds";
// import convertSecondsToISO8601 from "../../../../../shared/utils/convertSecondsToISO8601";

function addTimeInterval(interval) {
  const now = new Date();
  if (interval) {
    const intervalParts = interval.match(/P(\d+Y)?(\d+M)?(\d+D)?T?(\d+H)?(\d+M)?(\d+S)?/i);
    const year = parseInt(intervalParts[1], 10) || 0;
    const month = parseInt(intervalParts[2], 10) || 0;
    const day = parseInt(intervalParts[3], 10) || 0;
    const hour = parseInt(intervalParts[4], 10) || 0;
    const minute = parseInt(intervalParts[5], 10) || 0;
    const second = parseInt(intervalParts[6], 10) || 0;

    return new Date(
      now.getFullYear() + year,
      now.getMonth() + month,
      now.getDate() + day,
      now.getHours() + hour,
      now.getMinutes() + minute,
      now.getSeconds() + second
    );
  }
  return "Ошибка";
}

export const errorNullText = "нет данных";

export function platoonGroupVehiclesResourceFromDtoService(dto, meta) {
  const { status } = meta.response;
  const newData = dto.map((e, index) => {
    e.id = index;
    const {
      day, month, year, hours, minutes
    } = convertFormatDate(addTimeInterval(e.intervalLeader));
    e.coming = e.intervalLeader ? `${day}.${month}.${year} ${hours}:${minutes}` : errorNullText;
    // e.graph = (e.intervalNext && e.group.intervalDuration)
    //   ? formatFromTimeISO8601(convertSecondsToISO8601(
    //     convertDurationToSeconds(e.intervalNext)
    //     - convertDurationToSeconds(e.group.intervalDuration)
    //   )) : errorNullText;

    const {
      day: dayModifiedDate,
      month: monthModifiedDate,
      year: yearModifiedDate,
      hours: hoursModifiedDate,
      minutes: minutesModifiedDate
    } = convertFormatDate(e.modifiedDate);
    e.modifiedDate = e.modifiedDate ? `${dayModifiedDate}.${monthModifiedDate}.${yearModifiedDate} ${hoursModifiedDate}:${minutesModifiedDate}` : errorNullText;
    return e;
  });
  return { data: newData, status };
}

platoonGroupVehiclesResourceFromDtoService.propTypes = {
  dto: platoonGroupVehiclesResourceDtoType,
};

export default platoonGroupVehiclesResourceFromDtoService;
