import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// eslint-disable-next-line boundaries/element-types
import {
  useGetAppUserResourceIdQuery,
  usePatchAppUserResourceMutation
} from "../../../account/appUsersResource/redux/appUsersResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AppUserIEditBll({ children }) {
  const params = useParams();
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const [data, setData] = useState();

  const { data: dataUserResourceId } = useGetAppUserResourceIdQuery({
    id: params.userId
  });

  useEffect(() => {
    setData({
      dataUserResourceId
    });
  }, [dataUserResourceId]);

  const [putAppUserResource] = usePatchAppUserResourceMutation();

  const putAppUserResourceParams = useCallback(
    ({ body, id }) => putAppUserResource({
      authToken: token,
      body,
      id
    }),
    []
  );

  const props = {
    data,
    putAppUserResourceParams,
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

AppUserIEditBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppUserIEditBll;
