// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт

import usersResourceDtoType from "../../types/usersResource-dto.type";

export function usersResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  return { data: dto, status };
}

usersResourceFromDtoService.propTypes = {
  dto: usersResourceDtoType,
};

export default usersResourceFromDtoService;
