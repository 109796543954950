import React, {
  Children, cloneElement, useCallback
} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line boundaries/element-types
import { usePostAppUserResourceMutation } from "../../../account/appUsersResource/redux/appUsersResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function AppUserICreateBll({ children }) {
  const [postAppUserResource] = usePostAppUserResourceMutation();

  const postAppUserResourceParams = useCallback(
    ({ body }) => postAppUserResource({
      body,
    }),
    []
  );

  const props = {
    postAppUserResourceParams,
  };

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        Children
          .toArray(children).map((child) => cloneElement(child, props))
}
    </>
  );
}

AppUserICreateBll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppUserICreateBll;
